<template>
  <div class="desktop">
    <div class="group">
      <template v-for="(item, idx) in paginated">
        <base-block
          :id="item['name']"
          :title="item['name']"
          :rating="item['rating']"
          :n-rating="item['n_rating']"
          @action="action(idx)"
          @dell="dell(idx)"
          @edit="edit(idx)"
          @watch="watch(idx)"
          @select="select($event, item['name'])"
          @subclick="submenuClick($event, idx)"
          :watchButton="watchButton"
          :checkButton="checkButton"
          :editButton="editButton"
          :dellButton="dellButton"
          :actionButton="actionButton"
          :actionName="actionName"
          :with-pin="withPin"
          :base-value="checkValues[item['name']]"
          :sub-menu="subMenu"
        >
          <template v-slot:content>
            <experience-block-confirmation
              v-if="item.hasOwnProperty('confirmation')"
              :item="item"
            ></experience-block-confirmation>
            <template
              v-if="workContent(item)"
              v-for="i in Object.keys(workContent(item))"
            >
              <p style="color: #a0a0a0">{{ i }}</p>
              {{ workContent(item)[i] }}
            </template>
          </template>
          <template v-slot:footer>
            {{ footer(idx) }}
          </template>
        </base-block>
      </template>
    </div>
    <v-pagination
      v-if="items.length > perPage"
      v-model="currentPage"
      :length="length"
    ></v-pagination>
  </div>

  <div class="mobile">
    <swiper
      :slidesPerView="'auto'"
      :spaceBetween="0"
      :modules="modules"
      :pagination="{
        dynamicBullets: true,
      }"
      style="--swiper-pagination-color: #090f207f"
      :style="{ 'padding-bottom': items.length < 2 ? '0px' : '30px' }"
    >
      <swiper-slide v-for="(item, idx) in items" :key="idx">
        <base-block
          :id="item['name']"
          class="carousel__item"
          :title="item['name']"
          :rating="item['rating']"
          :n-rating="item['n_rating']"
          @action="action(idx)"
          @dell="dell(idx)"
          @edit="edit(idx)"
          @watch="watch(idx)"
          @select="select($event, item['name'])"
          @subclick="submenuClick($event, idx)"
          :watchButton="watchButton"
          :checkButton="checkButton"
          :editButton="editButton"
          :dellButton="dellButton"
          :actionButton="actionButton"
          :actionName="actionName"
          :with-pin="withPin"
          :base-value="checkValues[item['name']]"
          :sub-menu="subMenu"
        >
          <template v-slot:content>
            <experience-block-confirmation
              v-if="item.hasOwnProperty('confirmation')"
              :item="item"
              style="font-size: 15px !important"
            ></experience-block-confirmation>
            <template v-for="i in Object.keys(workContent(item))">
              <p style="color: #a0a0a0; margin-top: 6px">{{ i }}</p>
              {{ workContent(item)[i] }}
            </template></template
          >
          <template v-slot:footer>
            <svg-icon
              :width="1 - mobile ? 15 : 18"
              :height="1 - mobile ? 15 : 18"
              name="pin"
              v-if="footer(idx)?.length"
            />
            {{ footer(idx) }}
          </template>
        </base-block>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import BaseBlock from "@/components/base/BaseBlock";
import SvgIcon from "@/components/base/SvgIcon";
import ExperienceBlockConfirmation from "@/components/ui/ExperienceBlockConfirmation";
import { declOfNum } from "@/utils/helper";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

export default {
  name: "BlockList",
  components: {
    BaseBlock,
    SvgIcon,
    Swiper,
    SwiperSlide,
    Pagination,
    ExperienceBlockConfirmation,
  },

  props: {
    items: {
      type: Array,
      default: [],
    },
    actionName: {
      type: String,
      default: "Рекомендация",
    },
    actionButton: {
      type: Boolean,
      default: false,
    },
    dellButton: {
      type: Boolean,
      default: false,
    },
    editButton: {
      type: Boolean,
      default: false,
    },
    checkButton: {
      type: Boolean,
      default: false,
    },
    watchButton: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 2,
    },
    subMenu: {
      type: Object,
      default: {},
    },
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  data() {
    return {
      isFullWidth: false,
      currentPage: 1,
      checkValues: {},
      length: Math.ceil(this.items.length / this.perPage),
      newItems: [],
    };
  },
  watch: {
    items: {
      handler(newItems) {
        this.length = Math.ceil(newItems.length / this.perPage);
        if (this.currentPage > this.length) this.currentPage = this.length || 1;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    paginated() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      let spec = this.items.slice(startIndex, endIndex);
      return spec;
    },
    mobile() {
      return 1 - (this.$vuetify.display.sm || this.$vuetify.display.xs);
    },
    trueIdx() {
      return (this.currentPage - 1) * this.perPage * this.mobile;
    },
  },
  created() {
    for (let i = 0; i < this.items.length; i++) {
      this.checkValues[this.items[i]["name"]] = false;
    }
  },
  methods: {
    action(idx) {
      this.$emit("action", { idx: this.trueIdx + idx });
    },
    dell(idx) {
      this.$emit("dell", { idx: this.trueIdx + idx });
    },
    edit(idx) {
      this.$emit("edit", { idx: this.trueIdx + idx });
    },
    watch(idx) {
      this.$emit("watch", { idx: this.trueIdx + idx });
    },
    select(e, name) {
      if (name) {
        this.checkValues[name] = e.select;
        let idx = this.items.findIndex((item) => item.name === name);
        this.$emit("check", { idx: idx, select: e.select });
      }
    },
    submenuClick(e, idx) {
      this.$emit("menu", { idx: idx, val: e });
    },
    caseFooterText(item) {
      let res = [];
      if (item.attachments != null) {
        let n_attachment = item.attachments.length;
        if (n_attachment) {
          let str = declOfNum(n_attachment, ["файл", "файла", "файлов"]);
          res.push(`${n_attachment} ${str}`);
        }
      }

      if (item.links != null && item.links != "") {
        const linkPattern = /https?:\/\/[^\s]+|www\.[^\s]+/g;
        let n_links = (item.links.match(linkPattern) || []).length;
        if (!n_links && item.links.length) {
          n_links = 1;
        }
        if (n_links) {
          let str2 = declOfNum(n_links, ["ссылка", "ссылки", "ссылок"]);
          res.push(`${n_links} ${str2}`);
        }
      }
      return res.toString().replace(",", ", ");
    },

    workContent(item) {
      if (
        ("roles" in this.items[0]) &
        ("years" in this.items[0]) &
        ("tribe" in this.items[0])
      ) {
        let content = {
          Должность: item["roles"].toString().replace(",", ", "),
          "Опыт работы":
            item["years"] +
            " " +
            declOfNum(item["years"], ["год", "года", "лет"]) +
            ": " +
            item["companies"].toString().replace(",", ", "),
          Индустрии: item["tribe"].toString().replace(",", ", "),
        };
        return content;
      }
      return {};
    },

    footer(idx) {
      if (("attachments" in this.items[0]) & ("links" in this.items[0]))
        return this.caseFooterText(this.items[idx]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.group {
  max-width: 680px;
  display: grid;
  gap: 12;
  grid-template-columns: 1fr 1fr;
  @include sm {
    max-width: 540px;
  }
}
.mobile {
  display: none;
  @include sm {
    display: grid;
    position: relative;
    width: 100%;
  }
}

.desktop {
  display: block;
  @include sm {
    display: none;
  }
}

.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 0px;
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex;
}

.swiper-slide {
  // max-width: 294px;
  width: 274px;
  //max-width: 264px;
}

.swiper-slide:nth-child(2n) {
  width: 274px;
}

.swiper-slide:nth-child(3n) {
  width: 274px;
}

/* target the active bullet */
</style>
