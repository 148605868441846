<template>
  <div
    v-if="isShow"
    class="round-button"
    :class="{ open: isOpen }"
    ref="menuButton"
    @click="toggleMenu"
  >
    <svg-icon v-if="!isOpen" name="question-mark-icon" :width="40" :height="40" />
    <svg-icon v-if="isOpen" name="question-mark-icon-close" :width="40" :height="40" />
    <div class="social-buttons" v-if="isOpen" @click.self="closeMenu">
      <a class="social-button" @click="openContactModal"
        ><img :src="$store.getters['auth/assets'] + 'img/tel_logo.png'" alt="Calback"
      /></a>
      <a target="_blank" href="https://t.me/GigooSupport" class="social-button"
        ><img
          :src="$store.getters['auth/assets'] + 'img/telegram_logo.png'"
          alt="Telegram"
      /></a>
      <a target="_blank" href="https://wa.me/message/VQHGMBS6XHPAI1" class="social-button"
        ><img
          :src="$store.getters['auth/assets'] + 'img/whatsapp_chat.png'"
          alt="Whatsapp"
      /></a>
    </div>
  </div>
  <contact-support-modal-help ref="supportModal" />
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import ContactSupportModalHelp from "@/components/modals/ContactSupportModalHelp";
export default {
  name: "HelpButton",
  components: { SvgIcon, ContactSupportModalHelp },
  data() {
    return {
      isOpen: false,
      isShow: true,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    closeMenu() {
      this.isOpen = false;
    },
    openContactModal() {
      this.$refs.supportModal.showModal();
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.round-button {
  position: fixed;
  bottom: 60px;
  right: 60px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #7fc666;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 10;
  @include sm {
    bottom: 100px;
    right: 10px;
  }
}

.round-button.open {
  bottom: 70px;
  background-color: #f6f6f6;
}

.telephone-icon {
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
}

.social-buttons {
  position: absolute;
  bottom: 60px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0);
}

.social-buttons img {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #007bff00;
  margin: 0px 0;
}

.social-button img {
  width: 50px;
  height: 50px;

  @include sm {
    width: 40px;
    height: 40px;
  }
}
</style>
