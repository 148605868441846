<template>
  <div class="alert-block">
    <div class="alert">
      <div class="icon-center">
        <svg-icon
            :name='type'
            :height='48'
            :width='48'
        />
      </div>
      <h2 class="alert__header" :class="{ 'mb-24': !subtitle }">{{ header }}</h2>
      <div v-if="subtitle !== ''" class="subtitle">
        <span> {{ subtitle }}</span>
      </div>
      <div class="action" :class="{
        'action-grid':grid
      }">
        <base-button v-if="nameButton" :style="{backgroundColor: color}" @click="successModal">
          <template v-if="!nameButton">
            <slot/>
          </template>
          <template v-else>
            {{ nameButton }}
          </template>
        </base-button>
        <slot name="button"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseInput from "@/components/base/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";

export default {
  name: "BaseAlertNoModal",
  components: {
    BaseButton,
    BaseInput,
    BaseModal,
    SvgIcon
  },
  props: {
    grid: Boolean,
    size: {
      type: [String, Number],
      default: 440
    },
    header: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    nameButton: {
      type: String,
      default: ''
    },
    callback: {
      type: Function,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'success'
    }
  },
  computed: {
    currentWidth() {
      return this.size ? `${this.size}px`: '440px';
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
.alert {
  padding: 30px;
  background-color: $white;
  border-radius: 24px;

  &__header {
    @include desktop_h1;
  }

  &-block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 120px 12px;

    @include sm {
      padding: 30px 12px;
    }
  }

  width: v-bind(currentWidth);
  max-width: v-bind(currentWidth);
}
.action {
  display: flex;
}
.action-grid {
  display: grid;
}
.icon-center {
  text-align: center;
  margin-bottom: 24px;
}
h2 {
  margin-bottom: 12px;
  text-align: center;
}
span {
  text-align: center;
  font-size: 18px;
  word-break: break-word;
}
.subtitle {
  text-align: center;
  margin-bottom: 24px;
  line-height: 22px;

  span {
    font-size: 18px;
    line-height: 22px;
    word-break: break-word;
  }
}
</style>
