import { createRouter, createWebHistory } from 'vue-router'
import AuthPage from "@/views/AuthPage";
import RegistrationPage from "@/views/RegistrationPage";
import ResetPassword from "@/views/ResetPassword";
import ProfilePage from "@/views/ProfilePage";
import auth from "@/router/middleware/auth";
import ProfileInformationPage from "@/views/profile/ProfileInformationPage";
import ProfileInformationSomeonePage from "@/views/profile/ProfileInformationSomeonePage";
import ProfileProfilePage from "@/views/profile/ProfileProfilePage";
import ProfileRecommendationPage from "@/views/profile/ProfileRecommendationPage";
import ProfileRecommendationRequestPage from "@/views/profile/ProfileRecommendationRequestPage";
import ProfileSettingsPage from "@/views/profile/ProfileSettingsPage";
import ServicesPage from "@/views/ServicesPage";
import ServiceAddPage from "@/views/ServiceAddPage";
import CommunityPage from "@/views/CommunityPage";
import CommunityIndustryPage from "@/views/community/CommunityIndustryPage";
import CommunityFunctionsPage from "@/views/community/CommunityFunctionsPage";
import CommunityAlumniPage from "@/views/community/CommunityAlumniPage";
import RequestAddPage from "@/views/RequestAddPage";
import ResponseRequestPage from "@/views/ResponseRequestPage";
import ProjectsPage from "@/views/ProjectsPage";
import ProjectsRequestPage from "@/views/projects/ProjectsRequestPage";
import ProjectsResponsePage from "@/views/projects/ProjectsResponsePage";
import RequestListPage from "@/views/RequestListPage";
import StartPage from "@/views/StartPage";
import InvitePage from '@/views/InvitePage';
import RatePage from "@/views/RatePage";
import CompleteProjectPage from "@/views/completeProject/CompleteProjectPage";
import CompleteProjectRatePage from "@/views/completeProject/CompleteProjectRatePage";
import MultiRatePage from "@/views/MultiRatePage";
import ImpersonatePage from "@/views/ImpersonatePage";
import MockPage from "@/views/MockPage";
import VerifyMock from "@/views/VerifyMock";
import FaqPage from "@/views/FaqPage";

const routes = [
    // {
    //   path: '/service-list',
    //   name: 'ServiceList',
    //   component: ServiceList
    // },
    {
        path: '/mock',
        name: 'mock',
        component: MockPage
    },
    {
        path: '/faq',
        name: 'faq',
        component: FaqPage
    },
    {
        path: '/verify',
        name: 'VerifyMock',
        component: VerifyMock
    },
    {
        path: '/',
        name: 'StartPage',
        component: StartPage
    },
    {
        path: '/projects',
        name: 'ProjectsPage',
        beforeEnter: [auth],
    },
    {
        path: '/community',
        name: 'CommunityPage',
        beforeEnter: [auth]
    },
    {
        path: '/auth',
        name: 'AuthPage',
        component: AuthPage
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/sign-up',
        name: 'RegistrationPage',
        component: RegistrationPage
    },
    {
        path: '/impersonate/:id',
        name: 'ImpersonatePage',
        component: ImpersonatePage
    },
    {
        path: '/profile',
        name: 'ProfilePage',
        component: ProfilePage,
        beforeEnter: [auth],
        redirect: to => {
            return '/profile/information'
        },
        children: [{
            path: 'information',
            component: ProfileInformationPage
        },
        {
            path: 'expert',
            component: ProfileInformationSomeonePage
        },
        {
            path: 'profile',
            component: ProfileProfilePage
        },
        {
            path: 'recommendation',
            component: ProfileRecommendationPage
        },
        {
            path: 'recommendation-request',
            component: ProfileRecommendationRequestPage
        },
        {
            path: 'settings',
            component: ProfileSettingsPage
        }
        ]
    },
    {
        path: '/profile/:id',
        name: 'UserProfile',
        component: ProfilePage,
        beforeEnter: [auth],
        children: [{
            path: 'information',
            component: ProfileInformationPage
        },
        {
            path: 'expert',
            component: ProfileInformationSomeonePage
        },]
    },
    {
        path: '/services',
        name: 'ServicesPage',
        component: ServicesPage,
        beforeEnter: [auth],
    },
    {
        path: '/community',
        name: 'CommunityPage',
        component: CommunityPage,
        beforeEnter: [auth],
        redirect: to => {
            return '/community/industry-functions'
        },
        children: [{
            path: 'industry-functions',
            component: CommunityIndustryPage
        },
        {
            path: 'functions',
            component: CommunityFunctionsPage
        },
        {
            path: 'alumni',
            component: CommunityAlumniPage
        },
        ]
    },
    {
        path: '/service-add',
        name: 'ServiceAddPage',
        component: ServiceAddPage,
        beforeEnter: [auth],
        children: [{
            path: ':id',
            component: ServiceAddPage,
            props: true,
        },
        {
            path: 'quick-view/:id',
            component: ServiceAddPage,
            props: true
        }
        ],
    },
    {
        path: '/request-add',
        name: 'RequestAddPage',
        component: RequestAddPage,
        beforeEnter: [auth],
    },
    {
        path: '/response-request/:id',
        name: 'ResponseRequestPage',
        component: ResponseRequestPage,
    },
    {
        path: '/projects',
        name: 'ProjectsPage',
        component: ProjectsPage,
        beforeEnter: [auth],
        redirect: to => {
            return '/projects/request'
        },
        children: [{
            path: 'request',
            component: ProjectsRequestPage
        },
        {
            path: 'response',
            component: ProjectsResponsePage
        }
        ]
    },
    {
        path: '/request-list/:id',
        name: 'RequestListPage',
        component: RequestListPage,
    },
    {
        path: '/invite',
        name: 'InvitePage',
        component: InvitePage,
        beforeEnter: [auth],
        redirect: to => {
            return '/invite/expert'
        },
        children: [{
            path: 'expert',
            name: 'InvitePageExpert',
            component: InvitePage,
        },
        {
            path: 'company',
            name: 'InvitePageCompany',
            component: InvitePage,
        },
        ]
    },
    {
        path: '/rate-user',
        name: 'RatePage',
        component: RatePage,
        beforeEnter: [auth],
    },
    {
        path: '/multi-rate-user',
        name: 'MultiRatePage',
        component: MultiRatePage,
        beforeEnter: [auth],
    },
    {
        path: '/complete-project/:id',
        name: 'CompleteProjectPage',
        component: CompleteProjectPage,
        children: [{
            path: 'respond/:respondId',
            component: CompleteProjectPage,
        }]
    },
    {
        path: '/complete-project/:id/service/:serviceId/user/:userId/respond/:respondId',
        name: 'CompleteProjectRatePage',
        component: CompleteProjectRatePage,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.NODE_ENV === 'development' ? '' : ''),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
             return { el: to.hash, behavior: "smooth" };
        } else {
            window.scrollTo(0, 0);
        }
    },
    routes
})



export default router