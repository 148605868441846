<template>
  <div class="no-modal-block">
    <div class="no-modal">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseNoModal',
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';

.no-modal {
  width: 456px;
  height: max-content;
  padding: 30px 40px;
  background-color: $white;
  border-radius: 24px;

  &-block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 12px;
  }

  @include sm {
    width: 100%;
    padding: 30px;
  }
}
</style>