<template>
  <div class="blocks">
    <task-card-expert
      v-for="(c, idx) in mobile ? cards.slice(0, 3) : cards.slice(0, 6)"
      :key="idx"
      :name="c.name"
      :task="c.task"
      :solution="c.solution"
      :description="c.description"
      :period="c.period"
      :payment="c.payment"
      :photo="c.photo"
      :chips="c.chips"
      class="block"
    ></task-card-expert>
  </div>
  <div class="blocks is-no-mobile" v-if="!isToggle">
    <base-button @click="toggle()" light> Показать еще </base-button>
  </div>
  <div v-if="isToggle & mobile" class="blocks">
    <task-card-expert
      v-for="(c, idx) in cards.slice(3, 6)"
      :key="idx"
      :name="c.name"
      :task="c.task"
      :solution="c.solution"
      :description="c.description"
      :period="c.period"
      :payment="c.payment"
      :photo="c.photo"
      :chips="c.chips"
      class="block"
    ></task-card-expert>
  </div>
  <div class="blocks is-no-mobile" v-if="isToggle">
    <base-button @click="toggle()" light> Скрыть </base-button>
  </div>
</template>
<script>
import TaskCardExpert from "@/views/startpage/cards/TaskCardExpert";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "TaskBlock",
  components: { TaskCardExpert, BaseButton },
  props: {
    cards: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      isToggle: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
  methods: {
    toggle() {
      this.isToggle = !this.isToggle;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.blocks {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  width: 100%;
  @media (max-width: 1300px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @include sm {
    gap: 16px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.but {
  margin-bottom: 30px;
}
.block {
  width: 100%;
  border-radius: 24px;
  display: flex;
  color: #000000;

  @include md {
    width: 100%;
    margin: 0px;
    flex-direction: column;
    text-align: left;
  }
  @include sm {
    width: 100%;
    flex-direction: column;
    text-align: left;
  }
}

.is-no-mobile {
  display: none;

  @include sm {
    width: 100%;
    display: block;
    align-items: center;
    margin-bottom: 0px;
    padding: 1px;
  }
}
</style>
