<template>
  <div class="profile-col" :class="{ secure: !phone }">
    <div v-if="phone" class="phone-field base-input">
      <div class="contacts__title">Номер телефона</div>
      <div class="contacts__info">{{ phone }}</div>
    </div>
    <div v-if="email" class="base-input">
      <div class="contacts__title">Почта</div>
      <div class="contacts__info">{{ email }}</div>
    </div>
    <div v-if="corp_email" class="base-input">
      <div class="contacts__title">Корпоративная почта</div>
      <div class="contacts__info">{{ corp_email }}</div>
    </div>
    <div v-if="socials.length" class="base-input">
      <div class="contacts__title">Социальные сети</div>
      <base-button
        v-for="social in socials"
        :key="social.name"
        class="information__social-btn"
        @click="openLink(social.link)"
        type="button"
        icon-postfix-size="20"
        :iconPrefix="socialList[social.name].icon"
        outlined
        simpleLink
        social
      >
        <div class="information__content">
          <p class="information__send">{{ socialList[social.name].name }}</p>
        </div>
      </base-button>
    </div>
    <div class="secure-block">Контакты становятся доступны после оплаты</div>
  </div>
  <alert-modal ref="alert"> ок </alert-modal>
</template>
<script>
import { mapGetters } from "vuex";
import AlertModal from "@/components/modals/AlertModal";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "ExpertContacts",
  computed: {
    ...mapGetters({
      requestContacts: "request/requestContacts",
    }),
  },
  components: { AlertModal, BaseButton },

  data() {
    return {
      phone: "",
      email: "",
      corp_email: "",
      socials: [],
      secure: true,
      socialList: {
        vk: {
          name: "Вконтакте",
          icon: "socials/vkontakte-social",
        },
        linkedIn: {
          name: "LinkedIn",
          icon: "socials/linkedIn-social",
        },
      },
    };
  },
  async mounted() {
    await this.getRequestInfoContats();
    const v = this.requestContacts[this.$route.params.id];
    this.phone = v.phone | "";
    this.email = v.email | "";
    this.corp_email = v.corp_email | "";
    this.socials = v.socials | [];
  },
  methods: {
    async getRequestInfoContats() {
      try {
        await await this.$store.dispatch(
          "request/getRequestInfoContats",
          this.$route.hash.slice(1)
        );
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.contacts {
  &__title {
    @include desktop_h2;
    margin-bottom: 10px !important;
  }
  &__info {
    @extend %text-standart;
  }
}

.phone-field {
  grid-column: span 2;

  @include sm() {
    grid-column: span 1;
  }
}
.information {
  &__content {
    display: grid;
  }

  &__social-btn {
    margin-bottom: 12px;
    width: 330px;

    @include md {
      width: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    @include desktop_h1;
  }
}
.profile-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  row-gap: 0;

  @include sm {
    grid-template-columns: 1fr;
  }

  .base-input {
    margin-bottom: 24px;
  }

  .secure-block {
    display: none;
  }

  &.secure {
    position: relative;
    border-radius: 10px;
    border: 1px solid $line-light-gray;
    height: 200px;
    overflow: hidden;

    .secure-block {
      @include desktop_standart;
      display: flex;
      position: absolute;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 30px;
      background-color: #f2f2f266;
      backdrop-filter: blur(10px);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
