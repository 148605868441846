<template>
  <base-modal ref="modal" size="550" :is-open="isOpen" @close="closeModal">
    <div class="request-recommendation">
      <h2 class="request-recommendation__header">Запрос оценки услуги</h2>
      <div class="request-recommendation__body">
        <div class="request-recommendation__body-title">Услуга</div>
        <div class="request-recommendation__body-service">{{ title }}</div>
      </div>
    </div>
    <template v-slot:body1>
      <perfect-scrollbar>
        <template v-if="newUser">
          <perfect-scrollbar>
            <div class="add-people-team__people">
              <base-input
                  class="request-recommendation__base-input"
                  v-model="user.email"
                  description="Электронная почта"
                  :class="{ error: v$.user.email.$error }"
                  :is-error="v$.user.email.$error"
                  :error="[
                  {
                    text: $t('pages.register.email-validate'),
                    status: v$.user.email.required.$invalid,
                  }, {
                    text: $t('validate.email'),
                    status: v$.user.email.email.$invalid,
                  }
                ]"
              />
              <base-input
                  class="request-recommendation__base-input"
                  v-model="user.lastName"
                  description="Фамилия"
                  :is-error="v$.user.lastName.$error"
                  :class="{ error: v$.user.lastName.$error }"
                  :error="[
                  {
                    text: $t('validate.required-2', {name: $t('name.last-name-u')}),
                    status: v$.user.lastName.required.$invalid,
                  }, {
                    text: 'Максимальная длина - 30 символов',
                    status: v$.user.lastName.maxValue.$invalid,
                  }, {
                    text: $t('validate.valid-name'),
                    status: v$.user.lastName.validName.$invalid,
                  }
                ]"
              />
              <base-input
                  class="request-recommendation__base-input"
                  v-model="user.name"
                  description="Имя"
                  :class="{ error: v$.user.name.$error }"
                  :is-error="v$.user.name.$error"
                  :error="[
                  {
                    text: $t('pages.register.validate.name.required'),
                    status: v$.user.name.required.$invalid,
                  }, {
                    text: 'Максимальная длина - 30 символов',
                    status: v$.user.name.maxValue.$invalid,
                  }, {
                    text: $t('validate.valid-name'),
                    status: v$.user.name.validName.$invalid,
                  }
                ]"
              />
              <base-input
                  class="request-recommendation__base-input"
                  v-model="user.patronymic"
                  description="Отчество"
                  :is-error="v$.user.patronymic.$error"
                  :class="{ error: v$.user.patronymic.$error }"
                  :error="[
                  {
                    text: 'Максимальная длина - 30 символов',
                      status: v$.user.patronymic.maxValue.$invalid,
                  }, {
                    text: $t('validate.valid-name'),
                    status: v$.user.patronymic.validName.$invalid,
                  }
                ]"
              />
              <base-input
                  class="request-recommendation__base-input"
                  v-model="user.phone"
                  type="phone"
                  description="Номер телефона"
                  @validPhone="validPhoneEvent"
                  :class="{ error: v$.user.phone.$error }"
                  :is-error="v$.user.phone.$error"
                  :error="[
                    {
                      text: $t('pages.register.validate.phone.required'),
                      status: v$.user.phone.required.$invalid,
                    },
                    {
                      text: $t('pages.register.validate.phone.valid-phone'),
                      status: v$.user.phone.validPhone.$invalid,
                    }
                  ]"
              />
            </div>
          </perfect-scrollbar>
        </template>
        <template v-else>
          <div class="request-recommendation_multy">
          <people-search
              v-model="userList"
              :searth="searchText"
              @checkNewUser="checkNewUser"
              @searchTextEvent="searchTextEvent"
          />
        </div>
        </template>
      </perfect-scrollbar>
      <div class="request-recommendation__action-btn">
        <base-button v-if="newUser" icon icon-prefix="back-btn" class="button--light request-recommendation__back-btn" @click="backUserList"></base-button>
        <base-button :disabled="disableBtn()" @click="submit">{{ btnName }}</base-button>
      </div>
    </template>
    <alert-modal ref="alert">Ок</alert-modal>
  </base-modal>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";
import BaseListInfo from "@/components/base/BaseListInfo";
import PeopleSearch from "@/components/component/PeopleSearch";
import {email, maxLength, maxValue, required} from "@vuelidate/validators";
import BaseInput from "@/components/base/BaseInput";
import BaseDropdown from "@/components/base/BaseDropdown";
import VueSingleSelectRows from "@/components/ui/VueSingleSelectRows";
import {mapGetters} from "vuex";
import {debounce} from "@/utils/helper";
import AlertModal from "@/components/modals/AlertModal";

export default  {
  name: "RequestEstimateModal",
  components: {
    AlertModal,
    VueSingleSelectRows,
    BaseDropdown,
    BaseInput,
    PeopleSearch,
    BaseListInfo,
    BaseButton,
    BaseModal
  },
  props: {
    rolesProps: [], // Роль
    companiesProps: [], // Компании
    expertise: {}, // Компетенция
    industryId: null,
    optionId: null,
    title: String,
    isOpen: {
      type: Boolean,
      default: false,
    },
    infoArray: {
      type: Array,
      default() {
        return []
      }
    },
    serviceId: null,
    type: {
      type: String,
      default: 'industry',  // industry - Опыт работы | expertise - Компетенции | service - Услуга
    }
  },
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      searchText: '',
      open: false,
      vCheckNewUser: true,
      newUser: false,
      inputValidPhone: false,
      works: [],
      companies: [],
      searchWorkTypeDebounce: null,
      searchCompanyDebounce: null,
      userList: [],
      user: {
        name: '',
        lastName: '',
        patronymic: '',
        email: '',
        phone: '',
      }
    }
  },
  mounted() {
    this.getCompany();
    this.getWork();
  },
  created() {
    this.searchCompanyDebounce = debounce(this.getCompany, 400)
    this.searchWorkTypeDebounce = debounce(this.getWork, 400)
  },
  computed: {
    ...mapGetters({
      workTypeList: "work/workType",
      companiesList: "company/companies",
      userInvite: 'auth/userInvite',
    }),
    btnName() {
      if (this.newUser)
        return 'Отправить';
      else if (this.vCheckNewUser)
        return 'Добавить рекомендателя'
      else
        return 'Сохранить'
    },
    getValueUser() {
      let user = null
      if (this.newUser) {
        user = this.userInvite.id;
        this.$emit('showModalLink', this.userInvite.email);
        this.$store.commit('auth/SET_USER_INVITE', null);
      } else if (this.userList.length > 0) {
        let selectUser = this.userList.filter(v => v.select);
        if (selectUser.length > 0)
          user = selectUser[0].id;
      }
      console.log(this.newUser);
      console.log(this.userList);
      console.log(this.userInvite);
      return user;
    },
    getValueListExpertise() {
      return {
        confirm_type: "recommendation",
        expertise_id: this.expertise.expertise_id,
        rater_user_id: this.getValueUser
      }
    },
    getValueListIndustry() {
      let companies = null

      if (this.companies) {
      if (this.companies.length > 0)
        companies = {company_id: this.companies[0].id};
      }
      let work = null
      if (this.works)
      if (this.works.length > 0)
        work = {work_role_id: this.works[0].id};

      if (this.newUser) {
        return {
          type: 'service',
          invite_rate_user: this.userInvite.id,
          service_id: this.serviceId,
          is_invited: true,
        }
      }

      return {
        type: 'service',
        rate_user: this.getValueUser,   // ID пользователя, который оценивает опыт пользователя
        service_id: this.serviceId,
        option_id: this.optionId,       // ID функции опыта
        ...work,                        // ID роли опыта
        ...companies                    // ID компании опыта
      };
    }
  },
  methods: {
    searchTextEvent(v) {
      this.searchText = v;
    },
    searchEventWorkType(v) {
      this.$store.dispatch('work/getWorkType', v);
    },
    searchEventCompany(v) {
      this.$store.dispatch('company/getCompany', v);
    },
    getWork() {
      this.$store.dispatch('work/getWorkType', null)
    },
    async getCompany(val) {
      await this.$store.dispatch('company/getCompany', val);
    },
    updateWorkTypeSearch(e) {
      this.searchWorkTypeDebounce(e)
    },
    updateCompanySearch(e) {
      this.searchCompanyDebounce(e)
    },
    backUserList() {
      this.newUser = false;
      this.$refs.modal.updateSize();
      this.v$.$reset();
    },
    validPhoneEvent(v) {
      this.inputValidPhone = v;
    },
    validName(value) {
      if (value === "")
        return true;
      return /^[а-яёА-ЯЁa-zA-Z\-]+$/.test(value)
    },
    validPhone(value) {
      if (value === "")
        return true;
      return this.inputValidPhone;
    },
    checkNewUser(v) {
      this.vCheckNewUser = v;
    },
    closeModal() {
      this.newUser = false;
      this.getValueUser = null;
      this.works = null;
      this.companies = null;
      this.searchText = '';
      this.userList = [];
      this.v$.$reset();
      this.$emit('close')
    },
    async request() {
      if (this.type === 'industry' || this.type === 'service') {
        await this.$store.dispatch('profile/postProfileWorksInviteRate', this.getValueListIndustry)
        return 0;
      }
      if (this.type === 'expertise') {
        await this.$store.dispatch('profile/postProfileExpertiseConfirm', this.getValueListExpertise);
        return 0;
      }
      else
        this.$refs.alert.show(
            'Ошибка в файле [RequestRecommendationModal]',
            'Не верный тип industry - Опыт работы |expertise - Компетенции [' + this.type + ']',
            {
              type: 'error'
            }
        )
    },
    disableBtn() {
      if (this.newUser) {
        return false;
      } else {
        if (this.vCheckNewUser)
          return false;
        else
          return this.getValueUser == null
      }
    },
    async submit() {
      if (this.newUser) {
        const result = await this.v$.$validate();
        if (!result) {
          console.log(this.v$);
          return;
        }
        try {
          await this.$store.dispatch('auth/postUserInvite', this.user)
          this.user = {
            name: '',
            lastName: '',
            patronymic: '',
            email: '',
            phone: '',
          };
          this.v$.$reset();
          await this.request();
          this.closeModal();
        } catch (e) {
          this.$refs.alert.show(
              this.$t('alert.error'),
              e,
              {
                type: 'error',
                nameButton: 'Ок'
              }
          );
        }
        await this.request();
        this.closeModal();
      } else if (this.vCheckNewUser) {
        this.newUser = true;
        this.user.email = this.searchText;
        this.$refs.modal.updateSize();
      } else {
        if (this.getValueUser == null) {
          this.$refs.alert.show(
              'Ошибка',
              'Выберите пользователя',
              {
                type: 'error'
              }
          )
          return 0;
        }
        await this.request();
        this.closeModal();
      }
    }
  },
  watch: {
    isOpen(newValue, oldValue) {
      this.open = newValue;
    },
  },
  validations() {
    return {
      user: {
        name: {
          required,
          validName: this.validName,
          maxValue: maxLength(30),
        },
        patronymic: {
          validName: this.validName,
          maxValue: maxLength(30),
        },
        lastName: {
          required,
          validName: this.validName,
          maxValue: maxLength(30),
        },
        email: {
          required,
          email
        },
        phone: {
          required,
          validPhone: this.validPhone
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
.request-recommendation {
  &__base-input {
    margin-bottom: 23px;
  }
  &__header {
    @include desktop_h1;
    text-align: left;
    margin-bottom: 24px;
  }
  &__action-btn {
    display: flex;
    grid-template-columns: 1fr;
  }
  &__back-btn {
    width: auto;
    margin-right: 8px;
  }
  &__body {
    max-height: 52vh;

    &-title {
      padding-top: 24px;
      @include desktop_description;
      color: $text-gray;
      border-top: $gray-line;
      margin-bottom: 12px;
    }

    &-service {
      @include desktop_standart_bold;
    }
  }
  &__list-info-group {
    //gap: 4px;
    //display: grid;
  }
  &__roles-select-rows {
    margin-bottom: 22px;
  }
  &__companies-select-rows {
    margin-bottom: -12px;
  }
  &__list-info {
  }
  &__hr {
    margin: 24px 0;
    border-top: 1px solid $dark-gray;
  }
  .ps {
    max-height: 50vh;
  }
  .request-recommendation_multy {
  .ps {
    max-height: 50vh;
  }
}
}

</style>
