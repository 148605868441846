<template>
  <a :href="link" target="_blank" class="publications-item">
    <svg-icon
      v-if="info.type === 'link'"
      :width="mobile ? 33 : 53"
      :height="mobile ? 21 : 31"
      name="recommend-link"
    />
    <svg-icon
      v-else
      :width="mobile ? 33 : 53"
      :height="mobile ? 19 : 39"
      name="info-box-icon"
    />
    <div class="publications-item__body">
      <p class="publications-item__title">
        {{ info.type === "file" ? "Рекомендация — вложение" : "Рекомендация — ссылка" }}
      </p>
      <span class="publications-item__description">{{ info.description }}</span>
    </div>
    <span class="publications-item__remove" v-if="remove">
      <svg-icon
        :width="20"
        :height="20"
        name="service-dell"
        @click.prevent="delPublications"
      ></svg-icon>
    </span>
  </a>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "PublicationsItem",
  components: { SvgIcon },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    remove: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    delPublications() {
      this.$emit("delItem");
    },
  },
  computed: {
    link() {
      if (this.info.type === "link") {
        return this.info.link;
      }
      if (this.info.type === "file") {
        return this.info.filepath;
      }
    },
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.publications-item {
  display: flex;
  position: relative;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 24px;
  gap: 16px;
  cursor: pointer;

  @include sm() {
    padding: 20px;
    padding-right: 30px;
    font-weight: 200 !important;
  }

  svg {
    flex-shrink: 0;
  }

  &__remove {
    position: absolute;
    right: 20px;
    top: 20px;
    @include md {
      opacity: 1;
    }

    @include sm() {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  &:hover {
    border-color: #a0a0a0;

    .publications-item {
      &__title {
        color: #4b4c57;
      }

      &__remove {
        opacity: 1;
      }
    }
  }

  &__body {
    @include desktop_h2;
    flex-grow: 1;

    @include sm() {
    }
  }
}

.big-info-block {
  .publications-item {
    &__title {
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 11px;

      @include sm() {
        margin-bottom: 5px;
        font-size: 16px !important;
      }
    }

    &__description {
      display: block;
      @include desktop_description;
      color: #a0a0a0;
      word-break: break-word;
      font-weight: 200 !important;
      @include sm() {
        line-height: 21px;
        overflow: hidden;
        max-height: 86px;
      }
    }
  }
}
</style>
