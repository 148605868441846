import ApiUtil from '@/utils/api'

export default {
    getWorkType: () =>
        ApiUtil({
            url: '/api/work_type',
            method: 'get',
            requiresAuth: true,
        },),
    getOptions: () =>
        ApiUtil({
            url: '/api/options',
            method: 'get',
            requiresAuth: true,
        },),
    addOptions: (data) =>
        ApiUtil({
            url: '/api/options',
            method: 'post',
            data,
            requiresAuth: true,
        },),
    getIndustries: () =>
        ApiUtil({
            url: `/api/industries`,
            method: 'get',
            requiresAuth: true,
        },),
    getIndustriesById: (id) =>
        ApiUtil({
            url: `/api/industries/${id}`,
            method: 'get',
            requiresAuth: true,
        },),
    sendRegistrationData: (data) =>
        ApiUtil({
            url: '/api/register',
            method: 'post',
            data,
            headers: { "Content-Type": "multipart/form-data" },
        },),
    sendRegistrationDataSocial: (data) =>
        ApiUtil({
            url: '/api/completeSocialReg',
            method: 'post',
            data,
            headers: { "Content-Type": "multipart/form-data" },
        },),
    sendVerifyCode: (data) =>
        ApiUtil({
            url: '/api/email/verify',
            method: 'post',
            data,
            requiresAuth: true,
        },),
    sendUserProfile: (data) =>
        ApiUtil({
            url: '/api/user/profile',
            method: 'post',
            data,
            requiresAuth: true,
        },),
    sendUserResume: (data) =>
        ApiUtil({
            url: '/api/user/resume',
            method: 'post',
            data,
            requiresAuth: true,
            headers: { "Content-Type": "multipart/form-data" },
        },),
    processUserResume: (data) =>
        ApiUtil({
            url: '/api/user/resume/process',
            method: 'post',
            data,
            requiresAuth: true,
        },),
    sendUserIndustries: (data) =>
        ApiUtil({
            url: '/api/user/profile/industries',
            method: 'post',
            data,
            requiresAuth: true,
        },),
    sendUserOptions: (data) =>
        ApiUtil({
            url: '/api/user/profile/options',
            method: 'post',
            data,
            requiresAuth: true,
        },),
    dellUserResume: () =>
        ApiUtil({
            url: '/api/user/resume',
            method: 'delete',
            requiresAuth: true,
        },),
    sendUserStatus: (data) =>
        ApiUtil({
            url: '/api/user/status',
            method: 'post',
            data,
            requiresAuth: true,
        },),
    sendUserProfileFile: (data) =>
        ApiUtil({
            url: '/api/user/profile',
            method: 'post',
            data,
            requiresAuth: true,
            headers: { "Content-Type": "multipart/form-data" },
        },),
    getCodeAgain: (data) =>
        ApiUtil({
            url: '/api/email/verification-notification',
            method: 'get',
            data,
            requiresAuth: true,
        },),
}