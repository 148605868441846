<template>
  <div>
    <base-modal size="550" :is-open="open" @close="closeModal">
      <div class="add-people-team">
        <h2>Добавление сотрудника</h2>
        <div v-if="subtitle !== ''" class="subtitle">
          <span> {{ subtitle }}</span>
        </div>
        <div class="body">
          <v-tabs v-model="tab" class="add-people-team__nav">
            <v-tab value="listPeople" class="tab">Из текущих</v-tab>
            <v-tab value="newPeople" class="tab">Новый сотрудник</v-tab>
          </v-tabs>
          <div class="body__list">
            <v-window v-model="tab">
              <v-window-item value="listPeople">
                <base-input
                    placeholder="Введите имя или почту"
                    class="search mb-30 mt-30"
                    v-model="searchText"
                    @update:model-value="updateSearch"
                    search
                />
                <perfect-scrollbar>
                  <div class="item-group">
                    <item-people
                        v-for="(option, idx) in [...getListServer(true), ...listAll()]"
                        class="item"
                        :name="peopleTeamFormat(option).name"
                        :company="peopleTeamFormat(option).company"
                        :rating="peopleTeamFormat(option).rating"
                        :photo="peopleTeamFormat(option).photo"
                        :model-value="option"
                        @update:model-value="(v) => select([option])"
                        name-value="value"
                        checkbox-action
                    />
                    <div class="note-search" v-if="listAll().length <= 0">
                      <div class="icon">
                        <svg-icon name="info"></svg-icon>
                      </div>
                      <div class="text">
                        Ничего не найдено. <br>
                        Попробуйте изменить запрос
                      </div>
                    </div>
                  </div>
                </perfect-scrollbar>
              </v-window-item>
              <v-window-item value="newPeople">
                <perfect-scrollbar>
                  <div class="add-people-team__people">
                    <base-input
                      v-model="user.lastName"
                      description="Фамилия"
                      :is-error="v$.user.lastName.$error"
                      :class="{ error: v$.user.lastName.$error }"
                      :error="[
                        {
                          text: $t('validate.required-2', {
                            name: $t('name.last-name-u'),
                          }),
                          status: v$.user.lastName.required.$invalid,
                        },
                        {
                          text: 'Максимальная длина - 30 символов',
                          status: v$.user.lastName.maxLength.$invalid,
                        },
                        {
                          text: $t('validate.valid-name'),
                          status: v$.user.lastName.validName.$invalid,
                        },
                      ]"
                    />
                    <base-input
                      v-model="user.name"
                      description="Имя"
                      :class="{ error: v$.user.name.$error }"
                      :is-error="v$.user.name.$error"
                      :error="[
                        {
                          text: $t('pages.register.validate.name.required'),
                          status: v$.user.name.required.$invalid,
                        },
                        {
                          text: 'Максимальная длина - 30 символов',
                          status: v$.user.name.maxLength.$invalid,
                        },
                        {
                          text: $t('validate.valid-name'),
                          status: v$.user.name.validName.$invalid,
                        },
                      ]"
                    />
                    <base-input
                      v-model="user.patronymic"
                      description="Отчество"
                      :is-error="v$.user.patronymic.$error"
                      :class="{ error: v$.user.patronymic.$error }"
                      :error="[
                        {
                          text: $t('validate.valid-name'),
                          status: v$.user.patronymic.validName.$invalid,
                        },
                        {
                          text: 'Максимальная длина - 30 символов',
                          status: v$.user.patronymic.maxLength.$invalid,
                        },
                      ]"
                    />
                    <base-input
                      v-model="user.email"
                      description="Электронная почта"
                      :class="{ error: v$.user.email.$error }"
                      :is-error="v$.user.email.$error"
                      :error="[
                        {
                          text: $t('pages.register.email-validate'),
                          status: v$.user.email.required.$invalid,
                        },
                        {
                          text: $t('validate.email'),
                          status: v$.user.email.email.$invalid,
                        }
                      ]"
                    />
                    <base-input
                      v-model="user.phone"
                      type="phone"
                      description="Номер телефона"
                      @validPhone="validPhoneEvent"
                      :class="{ error: v$.user.phone.$error }"
                      :is-error="v$.user.phone.$error"
                      :error="[
                        {
                          text: $t('pages.register.validate.phone.required'),
                          status: v$.user.phone.required.$invalid,
                        },
                        {
                          text: $t('pages.register.validate.phone.valid-phone'),
                          status: v$.user.phone.validPhone.$invalid,
                        }
                  ]"
                    />
                    <div class="test"></div>
                    <div class="add-people-team__coll">
                      <base-input
                        v-model="user.direction_years"
                        class="base-input"
                        not-search
                        description="Количество лет в области"
                        :is-error="v$.user.direction_years.$error"
                        :error="[
                          {
                            text: 'Максимальное количество лет в области 50',
                            status: v$.user.direction_years.maxValue.$invalid,
                          },
                          {
                            text: 'Минимальное количество лет в области 1',
                            status: v$.user.direction_years.minValue.$invalid,
                          }
                        ]"
                      />
                      <base-input
                        v-model="user.projects"
                        class="base-input"
                        description="Количество проектов"
                        not-search
                        :is-error="v$.user.projects.$error"
                        :error="[
                          {
                            text: 'Максимальное количество проектов 100',
                            status: v$.user.projects.maxValue.$invalid,
                          },
                          {
                            text: 'Минимальное количество проектов 1',
                            status: v$.user.projects.minValue.$invalid,
                          }
                        ]"
                      />
                    </div>
                  </div>
                </perfect-scrollbar>
              </v-window-item>
            </v-window>
          </div>
        </div>
        <base-button :disabled="disabledType()" @click="submit">{{ isSelectValue() ? 'Добавить' : $t('btn.add') }}</base-button>
      </div>
    </base-modal>
    <alert-modal ref="alert"/>
    <worker-added-modal
      header="Сотрудник добавлен"
      subtitle="Чтобы повысить рейтинг услуги,
сотрудник должен зарегистрироваться
и подтвердить компетенции"
      :is-open="isOpenModalDesiredClients"
      @close="isOpenModalDesiredClients = false"
      :link="userInviteLink"
      class="worked-add-modal"
      btn-text="Вернуться к команде"
    >
    </worker-added-modal>
  </div>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseInput from "@/components/base/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import ItemPeople from "@/components/ui/ItemPeople";
import {mapGetters} from "vuex";
import useVuelidate from "@vuelidate/core";
import {email, required, minValue, maxValue, maxLength} from "@vuelidate/validators";
import AlertModal from "@/components/modals/AlertModal";
import WorkerAddedModal from "@/components/modals/WorkerAddedModal";
import SvgIcon from "@/components/base/SvgIcon";
import BaseDropdown from "@/components/base/BaseDropdown";
import useSelectController from "@/hooks/useSelectController";
/**
 * @property {string} text - Текст с поля для ввода
 */
class EventVerificationModalData {
  text = '';
}

export default {
  name: "AddPeopleTeamModal",
  components: {
    BaseDropdown,
    SvgIcon,
    WorkerAddedModal,
    AlertModal,
    ItemPeople,
    BaseButton,
    BaseInput,
    BaseModal,
  },
  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    /**
     * Дополнительный контент, отображаемый под заголовком
     */
    subtitle: {
      type: String,
      default: ''
    },
    /**
     * Текст заголовка
     */
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      }
    },
    searchEvent: {
      type: Function,
      default() {
        return () => {};
      }
    },
  },
  setup(props) {
    const unic = (vFilter, v) => {
      return vFilter.id === v.id && vFilter.type === v.type;
    }

    const {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      addSearchEvent,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect,
      getListServer
    } = useSelectController({
      selectOne: false,
      newValue: false,
      localSearch: false,
      checkUnic: unic
    });
    addSearchEvent(props.searchEvent)
    return {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect,
      getListServer,
      v$: useVuelidate()
    }
  },
  created() {
    this.$store.dispatch("auth/getUserSearch", null);
  },
  data() {
    return {
      inputValidPhone: false,
      searchText: null,
      tab: 'one',
      open: this.isOpen,
      nameButton: '',
      verificationCode: '',
      dataOptions: [],
      isOpenModalDesiredClients: false,
      user: {
        name: '',
        lastName: '',
        patronymic: '',
        email: '',
        phone: '',
        direction_years: '',
        projects: '',
      }
    }
  },
  methods: {
    mergeListValue(value) {
      return [...this.modelValue, ...value].reduce((acc, item) => {
        if(!acc.find(other => item.id === other.id && item.type === other.type))
          acc.push(item);
        return acc;
      }, []);
    },
    updateSearch(e) {
      console.log('e', e);
      this.searchSelect(e);
    },
    disabledType() {
      if (this.tab === 'listPeople') {
        return !this.isSelectValue()
      }
      return false;
    },
    validPhoneEvent(v) {
      this.inputValidPhone = v;
    },
    peopleTeamFormat({id, type, name, rating, company, photo, need_approve}) {
      return {
        name: name != null ? name : 'Имя ОШИБКА!!!', //TODO: Заменить данные
        company: company != null ? company.name :  "",
        rating: rating != null ? rating : 4.8, //TODO: Заменить данные
        id: id,
        type: type,
        photo: photo,
        need_approve: need_approve
      }
    },
    closeModal(e) {
      // this.$store.dispatch('auth/getUserSearch', null)
      this.searchText = '';
      this.searchSelect(this.searchText)
      this.$emit('close')
    },
    async submit() {
      if (this.disabledType())
        return
      if (this.tab === 'listPeople') {
        let submitSelect = this.submitSelect(true);
        if (submitSelect != -1) {
          this.$emit('update:modelValue', this.mergeListValue(submitSelect))
          this.closeModal();
        }
        // this.$emit('submit', this.dataOptions.filter(v => v.select === true))
        this.closeModal()
      }
      if (this.tab === 'newPeople') {
        const result = await this.v$.$validate();
        if (!result) {
          return;
        }

        try {
          await this.$store.dispatch('auth/postUserInvite', this.user)
          this.selectValue([{...this.userInvite, ...{value: false, newValue: true}}])
          let submitSelect = this.getListServer();
          if (submitSelect != -1) {
            this.$emit('update:modelValue', submitSelect)
          }
          this.closeModal();
          this.user = {
            name: '',
            lastName: '',
            patronymic: '',
            email: '',
            phone: '',
            direction_years: '',
            projects: '',
          };
          this.v$.$reset();
          this.isOpenModalDesiredClients = true;
        } catch (e) {
          this.$refs.alert.show(
              this.$t('alert.error'),
              e,
              {
                type: 'error',
                nameButton: 'Вернуться к команде'
              }
          );
        }
      }
    },
    validName(value) {
      if (value === "")
        return true;
      return /^[а-яёА-ЯЁa-zA-Z\-]+$/.test(value)
    },
    validPhone(value) {
      if (value === "")
        return true;
      return this.inputValidPhone;
    },
    select(v) {
      this.selectValue(v);
    },
    deleteItem(item) {
      this.removeSelect(item);
      let submitSelect = this.getListServer();
      if (submitSelect != -1) {
        this.$emit('update:modelValue', submitSelect)
      }
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null)
          if (newValue.length > 0) {
            this.selectValue(newValue, true);
          }
      }
    },
    items: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          this.addListSelect(JSON.parse(JSON.stringify(newValue)));
        }
      }
    },
    isOpen(newValue, oldValue) {
      this.open = newValue;
    },
  },
  computed: {
    ...mapGetters({
      userInvite: 'auth/userInvite',
      error: 'auth/error',
      userInviteLink: 'auth/userInviteLink'
    }),
    matchingOptions() {
      return this.dataOptions;
    },
  },
  validations() {
    return {
      user: {
        name: {
          required,
          validName: this.validName,
          maxLength: maxLength(30),
        },
        patronymic: {
          validName: this.validName,
          maxLength: maxLength(30),
        },
        lastName: {
          required,
          validName: this.validName,
          maxLength: maxLength(30),
        },
        email: {
          required,
          email
        },
        phone: {
          required,
          validPhone: this.validPhone
        },
        direction_years: {
          minValue: minValue(1),
          maxValue: maxValue(50),
        },
        projects: {
          minValue: minValue(1),
          maxValue: maxValue(100),
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
/* example */
.add-people-team {

  &::v-deep .v-btn:hover>.v-btn__overlay {
    opacity: 0;
  }

  &::v-deep .v-btn--active:hover>.v-btn__overlay {
    opacity: calc(0.12 * var(--v-theme-overlay-multiplier)); 
  }

  &__coll {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
    @include md {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
  .base-input {
    margin-bottom: 0;
    @include md {
      margin-bottom: 24px;
    }
    &:last-child {
      @include md {
        margin-bottom: 0;
      }
    }
  }
  &__people {
    margin: 30px 0 30px 0;
    padding-right: 18px;
    @include md {
      margin: 24px 0 24px 0;
    }
  }
}
.note-search {
  display: flex;
  .icon {
    background: $line-light-gray;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  .text {
    @extend %desktop_h2;
    color: $text-gray;
  }
}
.container-modal {
  padding: 40px;
}
.search {

}
.tab {
  @extend %desktop_standart;
  padding: 0 0 10px 0;
  margin-right: 22px;
}

h2 {
  text-align: left;
  margin-bottom: 30px;
}
.subtitle {
  text-align: left;
  margin-bottom: 16px;
  font-size: 18px;
  color: $accent;
}
.ps {
  max-height: 50vh;
}
.item-group {
  gap: 16px;
  display: grid;
  margin-bottom: 30px;
  padding-right: 18px;
}

.body {
  .v-btn {
    &:last-child {
      margin-right: 0;
    }

    @include sm() {
      padding: 0;
    }
  }

  .item-people {
    @include sm() {
      max-width: 290px;
    }
  }

  &__list {
    margin: 0 -18px 0 -18px;
    padding-left: 18px;

    .input-group {
      width: calc(100% - 18px);
    }

  }
  &__nav {
    margin-bottom: 30px;
  }
  &__body {
    margin-bottom: 30px;
  }
  &__item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.worked-add-modal {
  ::v-deep .dialog-content {
    max-width: 440px;

    .button.button--default.mb-4.button--light.btn__link {
      .button--slot {
        justify-content: center;

        .text-btn {
          display: flex;
          align-items: center;
          white-space: nowrap;

          @include md {
            justify-content: center;
          }

          @include sm {
            white-space: normal;
          }
        }
      }
    }

    .copy-link__svg {
      margin-left: 10px;
    }
  }
}
</style>
