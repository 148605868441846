<template>
  <div ref="errorText" class="error-text">
    <div v-if="errorString.length > 0" class="input-error">{{ errorString }}</div>
  </div>
</template>

<script>
export default {
  name: "BaseErrorText",
  props: {
    error: {
      type: Array,
      default() {
        return [{
          status: false,
          text: '',
        }]
      },
    },
  },
  computed: {
    errorString() {
      return this.error
          .filter((error) => error.status)
          .map((error) => error.text)
          .join(", ")
    }
  },
  methods: {
    focus() {
      let element = this.$refs.errorText;
      let top = element.offsetTop;
      window.scrollTo(0, top - 50);
    },
    handleInput(e) {
      this.$emit("update:modelValue", e.target.value);
    }
  }
}
</script>

<style lang="scss" scoped>
.error-text {
  margin-bottom: 15px;
  &.error .input-error {
    display: block;
  }
  .input-error {
    display: none;
    color: $error;
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }
}

</style>
