<template>
  <checkbox
      v-for="option in dataOptions"
      v-model="option.value"
      :fieldId="option.name"
      :title="option.name"
      :key="option"
  />
</template>

<script>
import Checkbox from "@/components/base/VueTelInput/Checkbox";
export default {
  name: "BaseMultiCheckbox",
  components: {Checkbox},
  data() {
    return {
      dataOptions: []
    }
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null)
          this.dataOptions = newValue.map(v => Object.assign({}, v));
        else this.dataOptions = [];
      }
    },
    dataOptions: {
      deep: true,
      handler(newValue, oldValue) {
        let updatedValue = newValue.map(v => Object.assign({}, {id: v.id, value: v.value == null ? false : v.value}));
        this.$emit("update:modelValue", updatedValue);
        this.$emit('change');
      }
    },
  },
  props: {
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        const hasNameKey = value.every((option) =>
            Object.keys(option).includes("name")
        );
        const hasIdKey = value.every((option) =>
            Object.keys(option).includes("id")
        );
        return hasNameKey && hasIdKey;
      },
    },
    modelValue: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
