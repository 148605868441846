import ApiUtil from '@/utils/api'

export default {
  getFooter: () =>
    ApiUtil(
      {
        url: '/api/content/footer',
        method: 'get',
        requiresAuth: false,
      },
    ),
}
