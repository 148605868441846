<template>
  <div class="specialization-modal">
    <base-modal size="550" :is-open="isOpen" @close="closeModal">
      <h2 v-if="title" class="information__header">{{ title }}</h2>
      <div v-if="withIndustry">
        <base-dropdown
          title="Индустрия"
          placeholder="Выберите индустрию"
          :list="industriesList"
          v-model="currentIndustry"
          class="select-function__select"
          :is-error="v$.currentIndustry.$error"
          :error="[
            {
              text: 'Выбор индустрии обязателен',
              status: v$.currentIndustry.required.$invalid,
            },
            {
              text: 'Выбор индустрии обязателен',
              status: v$.currentIndustry.checkEmpty.$invalid,
            },
          ]"
        />
      </div>
      <p class="information__caption">Функции</p>
      <select-function-search
        v-model="currentSpecialization"
        :is-error="v$.currentSpecialization.$error"
        :error="[
          {
            text: 'Выберите как минимум 1 функцию',
            status: v$.currentSpecialization.checkLen.$invalid,
          },
        ]"
      />
      <base-button v-if="btnName" class="specialization-modal__add" @click="updateData">{{
        btnName
      }}</base-button>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseDropdown from "@/components/base/BaseDropdown";
import SelectFunctionDropdown from "@/components/ui/SelectFunctionDropdown";
import SelectFunctionSearch from "@/components/ui/SelectFunctionSearch";
import BaseButton from "@/components/base/BaseButton";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapGetters } from "vuex";
export default {
  name: "AddSpecializationModalWork",
  components: {
    BaseButton,
    SelectFunctionDropdown,
    SelectFunctionSearch,
    BaseDropdown,
    BaseModal,
  },
  props: {
    functionsList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    btnName: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    withIndustry: {
      type: Boolean,
      default: true,
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  data() {
    return {
      isOpen: false,
      modalId: null,
      currentSpecialization: null,
      currentIndustry: null,
    };
  },
  computed: {
    ...mapGetters({
      industriesList: "registration/industriesList",
      optionsList: "registration/optionsList",
    }),
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    getName(v) {
      if (v.hasOwnProperty("name")) {
        return v.name;
      }
      const option_dict = {};
      this.optionsList.forEach((elem, i) => {
        option_dict[elem.id] = elem.name;
      });
      return option_dict[v.options];
    },
    async updateData() {
      this.currentSpecialization = this.currentSpecialization.filter((item) =>
        item.hasOwnProperty("id")
      );
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      let title = this.currentSpecialization
        .map(function (e) {
          return e.name;
        })
        .join(", ");

      const payload = {
        industries: this.currentIndustry,
        industry_name: this.withIndustry
          ? this.industriesList.filter((value) => value.id === this.currentIndustry)[0]
              .name
          : -1,
        options: this.currentSpecialization,
        title: title,
        modalId: this.modalId,
      };
      this.$emit("updateData", payload);
    },
    showModal(params) {
      if (params) {
        this.modalId = params[0].id;
        this.currentIndustry = this.withIndustry ? params[0].industries : -1;
        this.currentSpecialization = params.map(function (e) {
          return e.options;
        });
      }
      this.v$.$reset();
      this.isOpen = true;
    },
    hideModal() {
      const body = document.querySelector("html");
      body.classList.remove("no-scroll");
      this.isOpen = false;
    },
    checkSpecialization(e) {
      if (e.every((i) => i.hasOwnProperty("id"))) {
        return true;
      }
      return false;
    },
    checkOptions(e) {
      if (e.every((i) => i.hasOwnProperty("options"))) {
        return true;
      }
      return false;
    },
    checkEmpty(e) {
      if (e !== -1) {
        return true;
      }
      return false;
    },
    checkLen(e) {
      if (e.length > 0) {
        return true;
      }
      return false;
    },
  },
  validations() {
    let v = {
      currentSpecialization: {
        checkLen: this.checkLen,
      },
    };
    if (this.withIndustry) {
      v.currentIndustry = {
        required: required,
        checkEmpty: this.checkEmpty,
      };
    }
    return v;
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.information {
  &__description {
    @include desktop_additional;
    margin-top: -6px;
    margin-bottom: 18px;
  }

  &__caption {
    @extend %text-standart;
    margin-bottom: 18px;
    font-weight: 500;
    @include sm {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 106.667% */
    }
  }

  &__header {
    margin-bottom: 10px;
    @include sm {
      font-size: 24px;
    }
  }
}

.specialization-modal {
  &__add {
    margin-top: 8px;
  }
}

.select-function {
  &__label {
    font-size: 18px;
  }

  &__select {
    margin-bottom: 21px;
  }
}
</style>
