<template>
  <base-modal :size="size" :is-open="isOpen" @close="closeModal">
    <h2>{{ titleType }} поиск</h2>
    <!--<base-dropdown
      v-model="countDays"
      :list="days"
      :title="description()"
    ></base-dropdown>-->
    <base-button @click="submit">{{ titleType }}</base-button>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseDropdown from "@/components/base/BaseDropdown";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "ExtendTermModal",
  components: { BaseButton, BaseDropdown, BaseModal },
  data() {
    return {
      days: [
        { name: "1 день", id: 1 },
        { name: "2 дня", id: 2 },
        { name: "3 дня", id: 3 },
        { name: "4 дня", id: 4 },
        { name: "5 дней", id: 5 },
        { name: "6 дней", id: 6 },
        { name: "7 дней", id: 7 },
        { name: "8 дней", id: 8 },
        { name: "9 дней", id: 9 },
        { name: "10 дней", id: 10 },
        { name: "11 дней", id: 11 },
        { name: "12 дней", id: 12 },
        { name: "13 дней", id: 13 },
        { name: "14 дней", id: 14 },
        { name: "15 дней", id: 15 },
        { name: "16 дней", id: 16 },
        { name: "17 дней", id: 17 },
        { name: "18 дней", id: 18 },
        { name: "19 дней", id: 19 },
        { name: "21 день", id: 21 },
        { name: "22 дня", id: 22 },
        { name: "23 дня", id: 23 },
        { name: "24 дня", id: 24 },
        { name: "25 дней", id: 25 },
        { name: "26 дней", id: 26 },
        { name: "27 дней", id: 27 },
        { name: "28 дней", id: 28 },
        { name: "29 дней", id: 29 },
        { name: "30 дней", id: 30 },
      ],
      countDays: 10,
    };
  },
  props: {
    size: {
      type: [String, Number],
      default: 550,
    },
    isOpen: {
      type: Boolean,
    },
    demandId: {
      type: Number,
      default: null,
    },
    titleType: {
      type: String,
      default: "Продлить",
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    description() {
      return this.titleType + " поиск на";
    },
    async submit() {
      const payload = {};
      if (this.demandId) {
        payload.id = this.demandId;
      } else {
        payload.id = this.$route.params.id;
      }

      try {
        await this.$store.dispatch("request/sendRestart", payload);
        this.$emit("closeMess", "success");
      } catch (e) {
        this.$emit("closeMess", e);
        console.log("error");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

h2 {
  @include desktop_h1;
  margin-bottom: 24px;
}

.button {
  margin-top: 24px;
}
</style>
