import {ref} from "vue";

export default function useIndustryAndFunctions() {
  const industryValidate = (industryArray) => {
    if (industryArray == null) {
      return false;
    } else if (industryArray.length <= 0) {
      return false;
    } else {
      let oneItem = false;
      industryArray.forEach(v => {
        if (v.id != null && !oneItem)
          oneItem = true;
      })
      return oneItem;
    }
  }
  return {
    industryValidate
  }
}