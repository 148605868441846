<template>
  <div class="community-page">
    <div class="community-page__header">
      <h1 class="community-page__h1">Мои проекты</h1>
      <div class="community-page__menu">
        <div class="scrolling-wrapper profile-tabs__control">
          <router-link class="profile-tabs__control-item card" to="/projects/request">
            <div class="title">
              <base-signal-chip class="new_mobile" v-if="anyCheckCustomer" />
              <div>В роли Заказчика</div>
              <base-signal-chip class="new" v-if="anyCheckCustomer" />
            </div>
          </router-link>
          <router-link class="profile-tabs__control-item card" to="/projects/response">
            <div class="title">
              <base-signal-chip class="new_mobile" v-if="anyCheckExecutor" />
              <div>В роли Исполнителя</div>
              <base-signal-chip class="new" v-if="anyCheckExecutor" />
            </div>
          </router-link>
          <!-- <info-block-small class="info-block-sm-inv"></info-block-small> -->
        </div>
      </div>
    </div>

    <div class="profile-tabs__content white-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import BaseSignalChip from "@/components/base/BaseSignalChip";
import { mapGetters } from "vuex";
import InfoBlockSmall from "@/components/ui/InfoBlockSmall";
export default {
  name: "ProjectsPage",
  components: {
    BaseSignalChip,
    InfoBlockSmall,
  },
  computed: {
    ...mapGetters({
      responseStatus: "status/responseStatus",
    }),
    anyCheckExecutor() {
      return Object.values(this.responseStatus.executor)
        .map((el) => el.type)
        .some((x) => x.length);
    },
    anyCheckCustomer() {
      return Object.values(this.responseStatus.customer)
        .map((el) => el.type)
        .some((x) => x.length);
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.info-block-sm-inv {
  display: none;
  @include sm {
    display: block;
  }
}

.community-page {
  a:hover {
    color: #000000;
  }

  &_padding {
    padding-left: 20px;
    @include sm {
      padding-left: 0;
      text-align: center;
    }
  }

  &__header {
    max-width: 1320px;
    margin: 40px auto 0 auto;
    @include lg {
      padding: 0 60px;
    }
    @include md {
      margin-top: 30px;
      padding: 0 24px;
    }
    @include sm {
      margin-top: 40px;
      margin-bottom: 20px;
      padding: 0 12px;
    }
    &__h1 {
      @include mobile_h1;
    }
  }

  &__menu {
    padding-top: 40px;
    @include md {
      padding-top: 30px;
    }
    @include sm {
      padding-top: 40px;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.006em;
    color: #000000;
  }

  .custom-height {
    height: 90px;
    padding-top: 20px;
  }

  .container .card .nav {
    max-width: 277px;
    width: 100%;
  }

  .info-box__description {
    color: #a0a0a0;
  }

  .svg-shrink {
    flex-shrink: 0;
  }
  .title {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;
  }
  .community-item {
    .info-box {
      &__title {
        margin-bottom: 8px;
      }
    }
    &.disabled {
      .info-box {
        &__title {
          color: $text-gray;
        }

        svg {
          opacity: 0.6;
        }
      }
    }
  }
}

.new_mobile {
  display: none;
  @include md {
    display: inline;
  }
}
.new {
  @include md {
    display: none;
  }
}
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: left;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  @include md {
    justify-content: center;
  }
  & .card {
    &:first-child {
      margin-left: 0;
    }
    display: inline-block;
  }
}
.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  .card {
    flex: 0 0 auto;
    margin-right: 3px;
  }
}
.scrolling-wrapper,
.scrolling-wrapper-flexbox {
  //flex: 1 1 auto;
  //-webkit-overflow-scrolling: touch;
  width: 100%;
  @include sm {
    width: auto;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.profile-tabs {
  @include desktop_standart;
  &__control {
    padding-top: 10px;
    &-item {
      padding-bottom: 11px;
      border-radius: 0;
      border-bottom: 2px solid transparent;
      color: $text-gray;
      padding-left: 20px;
      padding-right: 40px;
      overflow-y: show;
      overflow-x: show;
      &:hover {
        color: $text-gray;
      }
      &.router-link-active {
        border-color: $accent;
        color: $accent;
        @include md {
          padding: 10px 20px;
          border-radius: 24px;
          border-color: initial;
          border-bottom: none;
          background: $white;
        }
      }
    }
  }
}
</style>
