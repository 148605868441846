
import { errorFormat } from "@/utils/helper";
import LsUtil from '@/utils/ls'
import statusService from '@/services/statusService'
import { LS_STATUS, LS_REFRESH_TOKEN } from '@/utils/ls/keys'
export default {
    namespaced: true,
    state: {
        responseStatus: {
            customer: {
                search: { title: "", type: "" }, //success,primary
                active: { title: "", type: "" },
                completed: { title: "", type: "" },
                expired: { title: "", type: "" },
            },
            executor: {
                invite: { title: "", type: "" },
                sent: { title: "", type: "" },
                active: { title: "", type: "" },
                completed: { title: "", type: "" },
                rejected: { title: "", type: "" },
            },
        },
        visitTime: {
            customer: {
                search: 0, //success,primary
                active: 0,
                completed: 0,
                expired: 0,
            },
            executor: {
                invite: 0,
                sent: 0,
                active: 0,
                completed: 0,
                rejected: 0,
            },
            tgpopup: {
                action: 0,
                close: 0,
            },
            parse: {
                close: 0,
            },   
        }
    },
    actions: {
        async initStatusResponse({ commit }, data) {
            try {
                commit('SET_STATUS_RESPONSE', data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async dumpEvent({ commit }, data) {
            try {
                await statusService.dumpEvent(data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },

        async getVisitTimes({ commit }) {
            if (LsUtil.getItem(LS_REFRESH_TOKEN)) {
                try {
                    const response = await statusService.getTimes();
                    commit('SET_VISIT_TIME', response.data);
                } catch (e) {
                    throw errorFormat('initVisitTimes');
                }
            }
        },

        async initVisitTimes({ commit }) {
            commit('INIT_VISIT_TIME');
            if (LsUtil.getItem(LS_REFRESH_TOKEN)) {
                try {
                    const response = await statusService.getTimes();
                    if (response.data.length) commit('SET_VISIT_TIME', response.data);
                } catch (e) {
                    throw errorFormat('initVisitTimes');
                }
            }

        },
        async updateVisitTimes({ commit }, key) {
            let v = LsUtil.getItem(LS_STATUS)
            key = key.split('_')
            if (!(key[0] in v))
                v[key[0]] = {}
            v[key[0]][key[1]] = Date.now();
            commit('UPD_VISIT_TIME', v);
            if (LsUtil.getItem(LS_REFRESH_TOKEN)) {
                try {
                    await statusService.setTimes({ 'data': v });
                } catch (e) {
                    throw errorFormat(e.response.data);
                }
            }

        },
    },
    mutations: {
        SET_STATUS_RESPONSE(state, value) {
            state.responseStatus = value
        },
        INIT_VISIT_TIME(state) {
            let current = LsUtil.getItem(LS_STATUS)
            if (current === null) {
                LsUtil.setItem(LS_STATUS, state.visitTime)
                current = state.visitTime
            }
            else if (JSON.stringify(Object.keys(current)) !== JSON.stringify(Object.keys(state.visitTime))) {
                current = Object.assign(state.visitTime, current);
            }

            state.visitTime = current
            LsUtil.setItem(LS_STATUS, current)
        },
        SET_VISIT_TIME(state, value) {
            state.visitTime = value
            LsUtil.setItem(LS_STATUS, value)
        },
        UPD_VISIT_TIME(state, value) {
            state.visitTime = value
            LsUtil.setItem(LS_STATUS, value)
        },

    },
    getters: {
        responseStatus(state) {
            return state.responseStatus
        },
        visitTime(state) {
            return state.visitTime
        },
    },
}