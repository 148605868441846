<template>
  <div class="multi-select-rows" :class="{ error: isError }">
    <p v-if="description" class="description">{{ description }}</p>
    <ul class="flex list-reset flex-wrap text-black">
      <li v-for="(option, idx) in selectedOptions" :key="idx"
          class="flex justify-between content-center list-item"
      >
        <slot name="pill" v-bind="{option,idx}">
          <span class="pill">
            <span class="text-sm" v-text="getOptionDescription(option)"></span>
            <span class="pl-2 text-grey-darker mt-px icons"
                  @click.stop="removeOption(dataOptions.indexOf(option))">
              <svg class="text-sm w-3 h-3 fill-current" width="28" height="28"
                   viewBox="0 0 28 28"
                   fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="21.4258" y="4.09961" width="3.50036" height="24.5025" rx="1.75018"
                      transform="rotate(45 21.4258 4.09961)" fill="#4B4C57"/>
                <rect x="23.8984" y="21.4258" width="3.50036" height="24.5025" rx="1.75018"
                      transform="rotate(135 23.8984 21.4258)" fill="#4B4C57"/>
              </svg>
            </span>
          </span>
        </slot>
      </li>
      <li class="flex-1">
        <div class="input-container">
          <div
            type="text"
            class="box-size w-full inline h-full outline-none border-none leading-tight placeholder"
            @click="isOpenModalDesiredClients = true"
          >
            {{ placeholder }}
          </div>
          <div class="btn">
            <svg-icon width="22" height="22" name="arrow-down"/>
          </div>
        </div>
      </li>
    </ul>
    <desired-clients-modal
        header="Желаемые клиенты"
        subtitle="Выберите или найдите клиентов, с которыми хотелось бы сотрудничать. Если клиента нет в списке — добавьте нового."
        :is-open="isOpenModalDesiredClients"
        @close="isOpenModalDesiredClients = false"
        ref="desiredClientsModalModalModal"
        v-model="dataOptions"
    />
    <div v-if="isError" class="input-error">{{ errorString }}</div>
    <div v-if="subDescription && !isError" class="sub-description">{{ subDescription }}</div>
  </div>
</template>

<script>
import Checkbox from "@/components/base/VueTelInput/Checkbox";
import BaseInput from "@/components/base/BaseInput";
import SvgIcon from "@/components/base/SvgIcon";
import BaseButton from "@/components/base/BaseButton";
import DesiredClientsModal from "@/components/modals/DesiredClientsModal";

export default {
  name: "VueMultiSelectRows",
  data() {
    return {
      searchText: "",
      isOpenModalDesiredClients: false,
      autoIncrement: 1,
    }
  },
  components: {DesiredClientsModal, Checkbox, BaseInput, SvgIcon,BaseButton},
  props: {
    modelValue: {
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    newValue: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: () => ""
    },
    description: {
      type: String,
      default: ''
    },
    subDescription: {
      type: String
    },
    isError: {
      type: Boolean,
      default: false
    },
    error: {
      type: Array,
      default() {
        return [{
          status: false,
          text: '',
        }]
      },
    },
    notValueToFullSelect: {
      type: Boolean,
      default: false
    },
    getOptionDescription: {
      type: Function,
      default(option) {
        return option['name'];
      }
    },
  },
  methods: {
    removeOption(index) {
      let dataOptions = this.dataOptions;
      dataOptions[index].value = false;
      this.dataOptions = dataOptions;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          this.dataOptions = newValue;
        }
      }
    },
    searchText(curr, prev) {
      if (curr === prev) {
        return;
      }
      this.pointer = -1;
    },
  },
  computed: {
    dataOptions: {
      get() {
        let newValueFormat = [];
        let modelValue = [];
        if (this.items != null) {
          newValueFormat = this.items.map(v => {
            return Object.assign({
              value: false,
              newValue: false,
            }, v)
          });
        }
        if (this.modelValue != null)
          modelValue = this.modelValue;
        return [...modelValue, ...newValueFormat].reduce((acc, item) => {
          if (!acc.find(other => item.id === other.id))
            acc.push(item);
          return acc;
        }, []);
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    errorString() {
      return this.error
          .filter((error) => error.status)
          .map((error) => error.text)
          .join(". ")
    },
    selectedOptions() {
      return this.dataOptions
          .filter((option, index) => option.value)
    },
    isRequired() {
      if (!this.required) {
        return "";
      }

      if (this.selectedOptions.length) {
        return "";
      }

      return "required";
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
.multi-select-rows {
  .list-item {
    padding: 4px 0 4px 4px;
  }
  .description {
    @extend %text-standart;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 500;
    color: $input-label;
  }

  .sub-description {
    @extend %text-small;
    color: $text-gray;
    margin-top: 10px;
  }
  .placeholder {
    @extend %desktop_standart;
    color: $text-gray;
    white-space: nowrap;
  }

  &.error {
    .list-reset {
      border: 1px solid #EF693F;
    }

    .input-error {
      color: #EF693F;
      font-size: 16px;
      line-height: 20px;
      margin-top: 8px;
    }
  }
}
.dialog-checkbox {
  width: 100%;
  background: white;
  padding: 32px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  position: absolute;
  margin-top: 12px;
  max-height: 500px;
  overflow-y: scroll;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    & {
      width: 64%;
    }
  }
  &__item-dialog {
    margin-top: 22px;
  }
  &__button {
    margin-top: 30px;
  }
  &__item-add-custom {
    span {
      color: $green
    }
  }
}
.industry-full {
  .dialog-checkbox {
    width: 100%;
  }
}
.input-container {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 100%;
  padding: 16px 20px;
  .btn {
    transform: rotate(-90deg);
  }
}


.list-reset {
  list-style: none;
  padding: 0;
}
.overflow-auto {
  overflow: auto;
}
.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.text-black {
  color: #1A1A1A;
  background: #F6F6F6;
  border-radius: 12px;
}
.text-grey-darker {
  color: #606f7b;
}
.leading-tight {
  line-height: 1.25;
}
.text-sm {
  @include mobile_text;
}
.w-full {
  width: 100%;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-between {
  justify-content: space-between;
}
.content-center {
  align-content: center;
}
.border-none {
  border: none;
}
.pin-r {
  right: 0;
}
.pin-y {
  top: 0;
  bottom: 0;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.items-center {
  align-items: center;
}
.p-1 {
  padding: 0.25em;
}
.pl-2 {
  padding-left: 0.5em;
}
.pr-1 {
  padding-right: 0.25em;
}

.px-2 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.m-1 {
  margin: 0.25em;
}
.mt-1 {
  margin-top: 0.25em;
}
.ml-1 {
  margin-left: 0.25em;
}
.mt-px {
  margin-top: 1px;
}
.mb-0 {
  margin-bottom: 0;
}
.leading-tight {
  line-height: 1.25;
}
.text-left {
  text-align: left;
}
.w-full {
  width: 100%;
}
.shadow {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.w-3 {
  width: 0.75em;
}
.h-3 {
  height: 0.75em;
}
.fill-current {
  fill: currentColor;
}
.hover\:no-underline:hover {
  text-decoration: none;
}
.outline-none {
  outline: 0;
}
.hover\:outline-none {
  outline: 0;
}
.hover\:bg-grey-lighter:hover {
  background-color: #dae1e7;
}
.shadow-md {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.focus\:shadow-outline:focus {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
  box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
}
.rounded {
  border-radius: 0.25em;
}
.icons svg {
  width: 0.75em;
  height: 0.75em;
}
.multi-select-wrapper {
}
.required {
  _color: #721c24;
  _background-color: #f8d7da;
  border-color: #f5c6cb;
}
.cursor-pointer {
  cursor: pointer;
}
.dropdown {
  border-radius: 12px;
  line-height: 1.25;
  text-align: left;
  display: inline;
  width: 99.8%;
  & > li {
    @extend %text-standart;
    background-color: $bg-light-gray;
    border-top: 1px solid $white;
    cursor: pointer;
    padding: 13px 20px;
  }
}
.active {
  background-color: #dae1e7;
}
.hidden {
  display: none;
}
.appearance-none {
  appearance: none;
}
input {
  overflow: visible;
}
.select-wrapper,
.box-size {
  box-sizing: border-box;
}
.pill {
  padding: 11px 12px 11px 15px;
  cursor: pointer;
  border-radius: 8px;
  line-height: 1.5;
  letter-spacing: -0.05em;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
}
.pill:hover {
  background-color: #f1f5f8;
}

.vue-multi-select-rows__input {
  height: 42px;
  width: 100%;
}

input::placeholder {
  //background: url(../assets/svg/star.svg) no-repeat;
}
</style>
