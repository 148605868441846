<template>
  <base-button position-left outlined class="add-button">
    <slot />
    <template v-slot:postfix>
      <span class="add-button__icon">
        <svg-icon
          class="icon-postfix"
          width="20"
          height="20"
          name="postfix-without"
        ></svg-icon>
      </span>
    </template>
  </base-button>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "AddButton",
  components: { SvgIcon, BaseButton },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.add-button {
  padding: 0 5px 0 20px;
  height: 56px;
  @include sm {
    height: 56px;
  }
  &.left {
    justify-content: space-between;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    margin-left: 20px;
    background-color: #f6f6f6;
    border-radius: 7px;
    @include sm {
      width: 44px;
      height: 44px;
    }
  }

  &:hover {
    background-color: $bg-light-gray;
    border-color: $line-light-gray;
    transition: 0.3s;
  }

  &:active {
    background-color: $dark-gray;
    transition: 0.3s;

    .add-button__icon {
      background-color: #eeeeee;
    }
  }
}
.icon-postfix {
  flex-shrink: 0;
}
</style>
