<template>
  <div>
    <div class="competence-item" :class="classes" @click="changeChecked">
      <div class="competence-item__icon">
        <svg-icon
          v-if="currentState.children && Object.keys(currentState.children).length"
          name="arrow-down"
        />
      </div>
      <p class="competence-item__title">{{ currentState.name }}</p>

      <p class="competence-item__status">{{ status }}</p>

      <template v-if="!currentState.children || !currentState.children.length">
        <div class="check">
          <base-radio type="checkbox" :model-value="currentState.checked" />
        </div>
      </template>
    </div>
    <div
      v-show="currentState.checked"
      class="competence-item__sub"
      v-if="currentState.children && Object.keys(currentState.children).length > 0"
    >
      <competence-item
        v-for="item in currentState.children"
        :key="item.id"
        :info="item"
        @update="changeInfo"
      />
    </div>
  </div>
</template>

<script>
import BaseRadio from "@/components/base/BaseRadio";
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "CompetenceItem",
  components: { SvgIcon, BaseRadio },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentState: JSON.parse(JSON.stringify(this.info)),
    };
  },
  computed: {
    status() {
      switch (this.currentState.status) {
        case "moderation":
          return "На модерации";
        case "active":
          return "";
        default:
          this.currentState.status;
      }
    },
    classes() {
      return {
        "no-children": !this.currentState.children || !this.currentState.children.length,
        disabled: this.currentState.disabled,
        active: this.currentState.checked,
      };
    },
  },
  methods: {
    changeChecked(info) {
      if (!this.currentState.disabled) {
        this.currentState.checked = !this.currentState.checked;

        this.$emit("update", this.currentState);
      }
    },
    changeInfo(info) {
      this.$emit("update", info);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.competence-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  cursor: pointer;
  border-bottom: 1px solid $line-light-gray;
  position: relative;
  flex-direction: row;
  @include sm() {
    padding: 10px 0;
  }
  &__icon {
    transition: 0.3s;
  }

  &.disabled {
    pointer-events: none;

    .competence-item__title {
      color: $text-gray;
    }

    .input-radio {
      pointer-events: none;
      opacity: 0.8;
      flex-shrink: 0;
    }
  }

  &.active {
    .competence-item__icon {
      transform: rotate(180deg);
      transition: 0.3s;
    }
  }

  &.no-children {
    .competence-item {
      &__title {
        @include desktop_standart;
      }
    }
  }

  &__title {
    @include desktop_h2;
  }

  &__status {
    margin-left: auto;
    margin-right: 16px;
    font-size: 16px;
    line-height: 20px;
    flex-shrink: 0;
    color: #a0a0a0;
  }

  .input-radio {
    padding: 0;
    margin: 0;
    border-radius: 5px;
  }

  &__sub {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;

    .competence-item {
      &:after {
        display: block;
        position: absolute;
        content: "";
        border-bottom: 1px solid $line-light-gray;
        left: -20px;
        right: 0;
        bottom: -1px;
      }

      &__title {
        font-size: 18px;
        line-height: 20px;
        width: 90%;
        font-family: SFProDisplay, Helvetica, Arial, sans-serif;
      }
    }
  }
}

.competence-item__sub .competence-item__sub {
  .competence-item:after {
    left: -40px;
  }
}

.search-list {
  .competence-item {
    padding: 15px 0;

    &__title {
      font-size: 18px;
      line-height: 20px;
      font-family: SFProDisplay, Helvetica, Arial, sans-serif;
    }
  }

  &:last-child .competence-item {
    border-bottom: none;
  }
}
</style>
