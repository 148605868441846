import {Store} from 'vuex'
import modules from './modules'
import { createStore } from 'vuex-extensions'
const store = new createStore(Store, {
  actions: {},
  mutations: {},
  state: {},
  getters: {},
  modules,
  strict: process.env.NODE_ENV !== 'production',
})

export default store
