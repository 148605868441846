<template>
  <div class="specialization-modal">
    <base-modal size="550" :is-open="isOpen" @close="closeModal">
      <h2 v-if="title" class="information__header">{{ title }}</h2>
      <p v-if="description" class="information__description">{{ description }}</p>
      <p class="mb-2 select-function__label">Выберите индустрию</p>
      <base-dropdown
          placeholder="Индустрия"
          :list="industriesList"
          v-model="currentIndustry"
          class="select-function__select"
          :is-error="v$.currentIndustry.$error"
          :error="[
              {
              text: 'Выбор индустрии обязателен',
              status: v$.currentIndustry.required.$invalid,
            }
          ]"
      />
      <select-function-dropdown
          v-model="currentSpecialization"
          :functions-list="functionsList"
          :is-error="v$.currentSpecialization.$error && v$.currentSpecialization.checkSpecialization.$invalid"
          :is-error-two="v$.currentSpecialization.$error && v$.currentSpecialization.checkOptions.$invalid"
          :error="[
            {
              text: 'Выбор функции обязателен',
              status: v$.currentSpecialization.checkSpecialization.$invalid,
            }
          ]"
          :error-two="[
              {
              text: 'Выбор подфункции обязателен',
              status: v$.currentSpecialization.checkOptions.$invalid,
            }
          ]"
      />

        <base-button v-if="btnName" class="specialization-modal__add" @click="updateData">{{ btnName }}</base-button>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseDropdown from "@/components/base/BaseDropdown";
import SelectFunctionDropdown from "@/components/ui/SelectFunctionDropdown";
import BaseButton from "@/components/base/BaseButton";
import {required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "AddSpecializationModal",
  components: {BaseButton, SelectFunctionDropdown, BaseDropdown, BaseModal},
  props: {
    industriesList: {
      type: Array,
      default: () => {
      }
    },
    functionsList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    btnName: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
  },
  setup: () => ({v$: useVuelidate({$scope: false})}),
  data() {
    return {
      isOpen: false,
      modalId: null,
      currentSpecialization: null,
      currentIndustry: null
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    async updateData() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      const industry = this.industriesList.filter(item => item.id === this.currentIndustry);
      const specialization = this.functionsList.filter(item => item.id === this.currentSpecialization.options);

      const payload = {
        industries: this.currentIndustry,
        options: this.currentSpecialization,
        industryFull: industry,
        specializationFull: specialization,
        modalId: this.modalId
      }


      this.$emit('updateData', payload)
    },
    showModal(params) {
      if (params) {
        const param = JSON.parse(JSON.stringify(params))
        this.modalId = param[0].id;

        this.currentSpecialization = params[0].options;
        this.currentIndustry = params[0].industries;
      }

      this.v$.$reset();
      this.isOpen = true;
    },
    hideModal() {
      this.isOpen = false;
    },
    checkSpecialization() {
      if (this.currentSpecialization && this.currentSpecialization.id) {
        return true
      }
      return false
    },
    checkOptions() {
      if (this.currentSpecialization && this.currentSpecialization.options) {
        return true
      }
      return false
    }
  },
  validations() {
    return {
      currentIndustry: {
        required: required
      },
      currentSpecialization: {
        checkSpecialization: this.checkSpecialization,
        checkOptions: this.checkOptions
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';

.information {
  &__description {
    @include desktop_additional;
    margin-top: -6px;
    margin-bottom: 18px;
  }

  &__header {
    margin-bottom: 18px;
  }
}


.specialization-modal {
  &__add {
    margin-top: 32px;
  }
}

.select-function {
  &__label {
    font-size: 18px;
  }
  &__select {
    margin-bottom: 21px;
  }
}
</style>
