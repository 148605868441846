<template>
  <base-modal size="550" :is-open="isOpen" @close="closeModal">
    <div class="add-people-team">
      <h2 class="add-people-team__header">Добавить специализации</h2>
      <div class="subtitle">
        <span
          >Выберите актуальный опыт из списка ниже. Если список пуст — добавьте опыт в ваш
          профиль и вернитесь к заполнению услуги</span
        >
      </div>

      <div class="body">
        <perfect-scrollbar>
          <div class="list">
            <service-task
              v-for="(option, idx) in listAll()"
              :key="`test_${idx}`"
              class="item"
              :rating="option.rating"
              :industry="option.tribe"
              :user-counter="option?.users?.length"
              :like="option.like"
              :model-value="option.value"
              @update:model-value="(v) => select([option])"
              checkbox-action
              small
            />
          </div>
        </perfect-scrollbar>
      </div>
      <base-button outlined @click.native="openWorkModal" class="add"
        >Добавить опыт работы</base-button
      >
      <base-button v-if="listAll().length" @click="submit">{{
        nameButton !== "" ? nameButton : "Сохранить"
      }}</base-button>
      <base-button v-else :disabled="listAll().length == 0" @click="addSpecialisation"
        >Добавить</base-button
      >
    </div>
  </base-modal>
  <add-work-modal ref="work-modal" @close="hideAddWorkModal" @add-work="addWorkItem" />
</template>

<script>
import useVuelidate from "@vuelidate/core";
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";
import ServiceTask from "@/components/ui/ServiceTask";
import useSelectController from "@/hooks/useSelectController";
import ExperienceBlock from "@/components/ui/ExperienceBlock";
import AddWorkModal from "@/components/modals/AddWorkModal";
/**
 * @property {string} text - Текст с поля для ввода
 */
class EventVerificationModalData {
  text = "";
}

export default {
  name: "AddCompetenceChoiceModal",
  components: {
    ExperienceBlock,
    ServiceTask,
    BaseButton,
    BaseModal,
    AddWorkModal,
  },
  setup(props) {
    const {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect,
    } = useSelectController({ selectOne: false, newValue: false });
    return {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect,
    };
  },
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      inputValidPhone: false,
      nameButton: "",
      verificationCode: "",
      dataOptions: [],
      isOpenModalDesiredClients: false,
    };
  },
  created() {
    this.getWorksList();
    this.getSpecialization();
    this.getWorkRoles();
  },
  methods: {
    select(v) {
      this.selectValue(v);
      let submitSelect = this.submitSelect();

      if (submitSelect != -1) {
        this.$emit("update:modelValue", submitSelect);
      }
    },
    closeModal() {
      this.$emit("close");
    },
    submit() {
      this.closeModal();
    },
    addSpecialisation() {
      this.closeModal();
      this.$router.push("/profile/information#experience");
    },
    delItem(item) {
      this.removeSelect(item);
      let submitSelect = this.submitSelect();
      if (submitSelect != -1) {
        this.$emit("update:modelValue", submitSelect);
      }
    },
    openWorkModal(e) {
      this.$refs["work-modal"].showModal(e);
    },
    hideAddWorkModal() {
      this.$refs["work-modal"].hideModal();
    },
    async getSpecialization() {
      try {
        await this.$store.dispatch("service/getSpecialization");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getWorkRoles() {
      try {
        await this.$store.dispatch("service/getWorkRoles");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getWorksList() {
      try {
        await this.$store.dispatch("service/getWorksList");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async addWorkItem(e) {
      await this.$store.dispatch("profile/sendCompetence", { works: [e] });
      await this.getSpecialization();
      this.$refs["work-modal"].hideModal();
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          if (newValue.length > 0) {
            this.selectValue(newValue, true);
          }
        }
      },
    },
    items: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          this.addListSelect(JSON.parse(JSON.stringify(newValue)));
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.add-people-team {
  &__header {
    @include desktop_h2;
    text-align: left;
    margin-bottom: 16px;
  }
  .body {
    margin-bottom: 30px;
    max-height: 400px;
    margin: 24px -18px 16px -18px;
    padding-left: 18px;
    .ps {
      max-height: 400px;
    }
    .list {
      padding-right: 18px;
    }
    .item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.subtitle {
  @include desktop_additional;
  text-align: left;
  margin-bottom: 16px;
}

.add {
  margin-bottom: 16px;
}
</style>
