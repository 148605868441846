<template>
  <div class="information-block" :class="{ 'mobile-special-form': mobileSpecialForm }">
    <svg-icon
      v-if="icon"
      style="margin-bottom: 15px"
      :name="icon"
      :width="25"
      :height="25"
    />
    <p :class="{ 'special-text': specialForm }">{{ text }}</p>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "InformationBlock",
  components: { SvgIcon },
  props: {
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    specialForm: {
      type: Boolean,
      default: false,
    },
    mobileSpecialForm: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.information-block {
  text-align: left;
  padding: 20px;
  border: 1px solid $line-light-gray;
  border-radius: 12px;

  &.mobile-special-form {
    display: none;
    margin-bottom: 36px;

    svg {
      min-width: 27px;
    }

    p {
      @include mobile-text;
    }

    @include md {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }

  &.padding-equal {
    padding: 22px 22px 24px 14px;

    svg {
      margin-bottom: 16px;
    }
  }

  p {
    color: #4b4c57;
    /* Desktop/Standart */
    font-family: SFProDisplay;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
    letter-spacing: 0.108px;

    &.special-text {
      color: $accent;
    }
  }

  svg {
    margin-bottom: 22px;
  }

  &.light-color {
    padding: 19px 32px 19px 19px;

    p {
      color: $accent;
      @include desktop_standart;
    }

    svg {
      margin-bottom: 15px;
    }
  }
}
</style>
