<template>
  <div :style="{width: width + 'px', height: height + 'px'}">
  </div>
</template>

<script>
export default {
  name: "SizedBox",
  props: {
    /**
     * Ширирна
     */
    width: {
      type: [Number, String],
      default: 0
    },
    /**
     * Высота
     */
    height: {
      type: [Number, String],
      default: 0
    },
  }
}
</script>

<style scoped>

</style>