<template>
  <div class="tasks">
    <div class="blocks">
      <task-card
        :name="cards[0].name"
        :task="cards[0].task"
        :solution="cards[0].solution"
        :description="cards[0].description"
        :photo="cards[0].photo"
        class="block"
      ></task-card>
      <task-card
        :name="cards[1].name"
        :task="cards[1].task"
        :solution="cards[1].solution"
        :description="cards[1].description"
        :photo="cards[1].photo"
        class="block"
      ></task-card>
      <task-card
        :name="cards[2].name"
        :task="cards[2].task"
        :solution="cards[2].solution"
        :description="cards[2].description"
        :photo="cards[2].photo"
        class="block"
      ></task-card>
    </div>
    <div class="blocks is-no-mobile" v-if="!isToggle">
      <base-button class="but" @click="toggle()" light> Показать еще </base-button>
    </div>
    <div v-if="isToggle | !mobile" class="blocks">
      <task-card
        :name="cards[3].name"
        :task="cards[3].task"
        :solution="cards[3].solution"
        :description="cards[3].description"
        :photo="cards[3].photo"
        class="block"
      ></task-card>
      <task-card
        :name="cards[4].name"
        :task="cards[4].task"
        :solution="cards[4].solution"
        :description="cards[4].description"
        :photo="cards[4].photo"
        class="block"
      ></task-card>
      <task-card class="is-mobile block main" :is-main="false"></task-card>
    </div>
    <div class="blocks is-no-mobile" v-if="isToggle">
      <base-button class="but" @click="toggle()" light> Скрыть </base-button>
    </div>
  </div>
</template>
<script>
import TaskCard from "@/views/startpage/cards/TaskCard";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "TaskBlock",
  components: { TaskCard, BaseButton },
  props: {
    cards: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      isToggle: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
  methods: {
    toggle() {
      this.isToggle = !this.isToggle;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.blocks {
  width: 100%;
  display: flex;

  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;

  @include md {
    gap: 16px;
    justify-content: left;
  }

  @include sm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.tasks {
  @include sm {
    width: 100%;
    flex-direction: column;
    padding-top: 30px;
  }
}
.but {
  margin-bottom: 30px;
}
.block {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  width: 50%;
  color: #000000;

  &.main {
    background: linear-gradient(90deg, #6fb057 0%, #86cd6d 68.92%);
    color: #ffffff;
    border-radius: 24px;
    display: flex;
    flex-direction: column;

    &.is-mobile {
      display: flex;

      @include sm {
        display: none;
      }
    }
  }
  @include md {
    width: 100%;
    margin: 0px;
    flex-direction: column;
    text-align: left;
  }
  @include sm {
    width: 100%;
    flex-direction: column;
    text-align: left;
  }
}

.is-no-mobile {
  display: none;

  @include sm {
    width: 100%;
    display: block;
    align-items: center;
    margin-bottom: 0px;
    padding: 1px;
  }
}
</style>
