<template>
  <div class="block-main">
    <h1 class="title">
      Пока недоступно...
      <h1 class="subtitle">Для продолжения работы необходимо подтвердить почту!</h1>
    </h1>

    <base-button class="but" @click="this.$router.push({ path: '/' })"
      >Вернуться на главную</base-button
    >
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "VerifyMock",
  components: { BaseButton },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.block-main {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding: 10% 30%;

  @include md {
    padding: 100px 30%;
  }
}

.title {
  color: var(--text, #000);
  text-align: center;
  /* Muller 50px */
  font-family: Muller;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: -1px;
}

.subtitle {
  padding-top: 30px;
  color: var(--text, #000);
  text-align: center;
  /* Desktop/Standart */
  font-family: SFProDisplay;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.108px;
}

.but {
  width: 300px;
  margin-top: 30px;
}
</style>
