<template>
  <div class="member-block">
    <div class="member-block__header" @click="toggle">{{ counterString }}
      <svg-icon width="12" :class="{ rotate: isToggle }" name="confirmation-down-white"></svg-icon>
    </div>
    <div v-if="isToggle" class="member-block__list">
      <div
          v-for="item in list"
          :key="`member_${item.id}`"
          class="member-block__item"
      >
        <div class="member-block__item-name">{{ item.name }}</div>
        <div class="member-block__item-years">{{ yearsString(item.work_years) }}</div>
        <div class="member-block__item-company">
          <span
              v-for="(company, idx) in item.company"
              :key="`company-${company.id}`"
              :class="{ disabled: company.hide }"
          >{{ company.hide ? 'Компания на модерации' : company.name }}{{
              idx < item.company.length - 1 ? ', ' : ''
            }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {declOfNum} from "@/utils/helper";
import SvgIcon from "@/components/base/SvgIcon";

export default {
  name: "MemberList",
  components: {SvgIcon},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isToggle: false,
    }
  },
  computed: {
    counterString() {
      if (this.list.length) {
        return `${this.list.length} ${declOfNum(this.list.length, ['участник', 'участника', 'участников'])}`
      } else {
        return ''
      }
    }
  },
  methods: {
    toggle() {
      this.isToggle = !this.isToggle;
    },
    yearsString(years) {
      let str = declOfNum(years, ['год', 'года', 'лет'])
      return `${years} ${str}`
    },
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';

.member-block {
  margin-top: 8px;

  &__header {
    color: $text-gray;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 8px;

    @include sm {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 2px;
    }

    svg {
      margin-left: 14px;
      transform: rotate(0deg);
      transition: 0.3s;

      &.rotate {
        transform: rotate(-180deg);
        transition: 0.3s;
      }
    }
  }

  &__item {
    @include desktop_standart;
    display: grid;
    grid-template-columns: 130px 90px 1fr;
    gap: 20px;
    padding: 14px 0;
    border-bottom: 1px solid $line-light-gray;

    @include sm {
      grid-template-columns: 100%;
      gap: 12px;
      padding: 20px 0 12px;
    }

    &:last-child {
      border-bottom: none;
    }

    &-company {
      span {
        &.disabled {
          color: $text-gray;
        }
      }
    }
  }
}
</style>
