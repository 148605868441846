import ApiUtil from '@/utils/api'
import { debounce } from "@/utils/helper";

export default {
    sendCompetence: (data) =>
        ApiUtil({
            url: '/api/user/profile',
            method: 'post',
            requiresAuth: true,
            data
        },),
    sendCase: (data) =>
        ApiUtil({
            url: '/api/user/cases',
            method: 'post',
            requiresAuth: true,
            data,
            headers: { "Content-Type": "multipart/form-data" },
        },),
    getItemWork: (id) =>
        ApiUtil({
            url: `/api/user/profile/works/${id}`,
            method: 'get',
            requiresAuth: true,
        },),
    getItemCase: (id) =>
        ApiUtil({
            url: `/api/user/cases/${id}`,
            method: 'get',
            requiresAuth: true,
        },),
    getUserExpertise: () =>
        ApiUtil({
            url: `api/user/profile/expertises`,
            method: 'get',
            requiresAuth: true,
        },),
    postProfileExpertiseConfirm: (data) =>
        ApiUtil({
            url: '/api/user/profile/expertises/confirm',
            method: 'post',
            requiresAuth: true,
            data
        },),
    deleteExpertisesItem: (id) =>
        ApiUtil({
            url: `/api/user/profile/expertises/${id}`,
            method: 'delete',
            requiresAuth: true,
        },),
    postProfileWorksInviteRate: (data) =>
        ApiUtil({
            url: '/api/user/invite-rate',
            method: 'post',
            requiresAuth: true,
            data
        },),
    postProfileWorksInviteRates: (data) =>
        ApiUtil({
            url: '/api/user/invite-rates',
            method: 'post',
            requiresAuth: true,
            data
        },),
    getProfileWorksInviteRate: (data) =>
        ApiUtil({
            url: '/api/user/invite-rates',
            method: 'get',
            requiresAuth: true
        }),
    getInstitutions: (data) =>
        ApiUtil({
            url: `/api/institutions${data != null ? '?name=' + data : ''}`,
            method: 'get',
            requiresAuth: true
        },),
    getPublications: () =>
        ApiUtil({
            url: `/api/user/profile/publications`,
            method: 'get',
            requiresAuth: true
        },),
    sendPublications: (data) =>
        ApiUtil({
            url: `/api/user/profile/publications`,
            method: 'post',
            data,
            requiresAuth: true,
            headers: { "Content-Type": "multipart/form-data" },
        },),
    deletePublications: (id) =>
        ApiUtil({
            url: `/api/user/profile/publications/${id}`,
            method: 'delete',
            requiresAuth: true,
        },),
    updatePassword: (data) =>
        ApiUtil({
            url: `/api/reset-password-profile`,
            method: 'post',
            data,
            requiresAuth: true,
        },),
    getSupportInfo: () =>
        ApiUtil({
            url: `/api/content/support`,
            method: 'get',
            requiresAuth: true,
        },),
    getCompetenceFlat: () =>
        ApiUtil({
            url: `/api/expertises`,
            method: 'get',
            requiresAuth: true,
        },),
    getTaxes: () =>
        ApiUtil({
            url: `/api/taxes`,
            method: 'get',
            requiresAuth: true,
        },),
    delInstitution: (id) =>
        ApiUtil({
            url: `/api/user/profile/institutions/${id}`,
            method: 'delete',
            requiresAuth: true,
        },),
    getUserRecommendation: () =>
        ApiUtil({
            url: `/api/user/profile/recommendations`,
            method: 'get',
            requiresAuth: true,
        },),
    getUserRecommendationFromOthers: () =>
        ApiUtil({
            url: `/api/user/profile/recommendations/from-others`,
            method: 'get',
            requiresAuth: true,
        },),
    remindRecommendation: (data) =>
        ApiUtil({
            url: `/api/user/remind-to-rate`,
            method: 'post',
            requiresAuth: true,
            data
        },),
    dropIsNewFlagRecommendationFromOthers: (ids) =>
        ApiUtil({
            url: `/api/view-flags`,
            data: { "model": "users_recommendations", "ids": ids },
            method: 'post',
            requiresAuth: true,
        },),
    declineRecommendationFromOthers: (id) =>
        ApiUtil({
            url: `/api/user/profile/recommendations/from-others/${id}/decline`,
            method: 'post',
            requiresAuth: true,
        },),
    getExpertCouncilList: () =>
        ApiUtil({
            url: `/api/user/profile/expert`,
            method: 'get',
            requiresAuth: true,
        },),
    deleteItemCouncil: (id) =>
        ApiUtil({
            url: `/api/user/profile/expert/${id}`,
            method: 'delete',
            requiresAuth: true,
        },),
    sendCouncilRequest: (data) =>
        ApiUtil({
            url: `/api/user/profile/expert`,
            method: 'post',
            data,
            requiresAuth: true,
        },),
    postRateUser: (data) =>
        ApiUtil({
            url: '/api/user/rate',
            method: 'post',
            requiresAuth: true,
            data
        },),
    getSomeUserInfo: (id) =>
        ApiUtil({
            url: `/api/user/${id}`,
            method: 'get',
            requiresAuth: true,
        },),
}