<template>
  <v-row :class="{ 'industry-full': full }" class="select-function">
    <v-col cols="12" class="align-end pa-3 sub-function">
      <div class="competence-modal">
        <base-input
          :is-error="isError"
          :error="error"
          placeholder="Найти функцию"
          class="desired-clients__input"
          v-model="searchText"
          search
        />
        <div class="competence-scroll">
          <div class="" v-if="!groupFunctionsByParents.length && searchText">
            <base-button outlined @click="addNewItem"
              >Добавить новую функцию «{{ this.searchText }}»</base-button
            >
          </div>
          <DynamicScroller
            :items="groupFunctionsByParents"
            :min-item-size="54"
            class="scroller"
          >
            <template v-slot="{ item, index, active }">
              <DynamicScrollerItem
                :item="item"
                :active="active"
                :size-dependencies="[item.message]"
                :data-index="index"
              >
                <div :key="item.id">
                  <competence-item
                    :key="item.id + searchText"
                    :info="item"
                    @update="updateInfo"
                  />
                </div>
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import SvgIcon from "@/components/base/SvgIcon";
import BaseDropdown from "@/components/base/BaseDropdown";
import VueMultiSelect from "@/components/ui/VueMultiSelect";
import SizedBox from "@/components/ui/SizedBox";
import VueMultiSelectCheckBox from "@/components/ui/VueMultiSelectCheckBox";
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/BaseInput";
import CompetenceItem from "@/components/ui/CompetenceItem";
export default {
  name: "SelectFunctionDropdown",
  components: {
    BaseInput,
    BaseButton,
    VueMultiSelectCheckBox,
    SvgIcon,
    BaseDropdown,
    VueMultiSelect,
    SizedBox,
    CompetenceItem,
  },
  props: {
    full: Boolean,
    modelValue: {
      type: Array,
      default: () => [],
    },
    descriptionFunction: String,
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchText: "",
      searchList: [],
      value: [],
      counter: -10,
    };
  },

  computed: {
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
    ...mapGetters({
      newOptionsList: "registration/newOptionsList",
      optionsList: "registration/optionsList",
    }),
    groupFunctionsByParents() {
      return this.searchList.reduce((outputList, item) => {
        if (item.parent_id === null) {
          const children = this.searchList
            .filter((child) => child.parent_id === item.id)
            .map((child) => ({
              parent_id: child.parent_id,
              id: child.id,
              name: child.name,
              checked: this.modelValue.some((i) => i.id === child.id) || false,
              disabled: false,
            }));
          outputList.push({
            disabled: false,
            checked: children.some((i) => i.checked) || item.id === -10 || false,
            parent_id: null,
            id: item.id,
            name: item.name,
            children,
          });
        }
        return outputList;
      }, []);
    },
  },
  created() {
    this.searchList = this.optionsList;
  },
  methods: {
    updateInfo(val) {
      let m = this.modelValue;

      if (val.checked && val.parent_id != null && val.id != -1) {
        let modelData = {
          id: val.id,
          name: val.name,
          parent_id: val.parent_id,
        };
        m.push(modelData);
      } else {
        m = m.filter((el) => el.id != val.id);
      }
      this.$emit("update:modelValue", m);
    },
    addNewItem() {
      if (!this.newOptionsList.length) {
        this.$store.commit("registration/SET_NEW_OPTIONS", {
          id: -10,
          name: "Другое (на модерацию)",
          parent_id: null,
        });
        this.counter--;
      }
      this.$store.commit("registration/SET_NEW_OPTIONS", {
        id: this.counter,
        name: this.searchText,
        parent_id: -10,
      });
      this.counter--;
      this.searchText = "";
    },
    findObjects(objects, searchText) {
      const word = searchText.toLowerCase();
      const parentMap = new Map();
      const childrenMap = new Map();
      const resultSet = new Set();

      for (let object of objects) {
        const nameWords = object.name.toLowerCase().split(" ");
        if (nameWords.some((nameWord) => nameWord.startsWith(word))) {
          resultSet.add(object);
        }

        // Collecting parents
        if (object.parent_id === null) {
          parentMap.set(object.id, object);
        } else {
          // Collecting children
          if (!childrenMap.has(object.parent_id)) {
            childrenMap.set(object.parent_id, []);
          }
          childrenMap.get(object.parent_id).push(object);
        }
      }

      // Adding parents for children with searchText
      for (let result of resultSet) {
        if (result.parent_id !== null) {
          const parent = parentMap.get(result.parent_id);
          if (parent) {
            resultSet.add(parent);
          }
        }
      }

      // Adding children for parents with searchText
      const resultSetArray = Array.from(resultSet);
      for (let result of resultSetArray) {
        if (result.parent_id === null && childrenMap.has(result.id)) {
          const children = childrenMap.get(result.id);
          for (let child of children) {
            resultSet.add(child);
          }
        }
      }

      return Array.from(resultSet);
    },
  },
  watch: {
    searchText(val) {
      const searchText = val.toLowerCase();
      this.searchList = this.findObjects(this.optionsList, searchText);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.error {
  border: 1px solid $error;
}

.input-error {
  color: $error;
  margin-top: 8px;
}

.sub-function {
  margin-top: -10px;
}

.competence-modal {
  padding-bottom: 10px;

  &::v-deep .vue-recycle-scroller::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::v-deep .vue-recycle-scroller::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::v-deep .vue-recycle-scroller::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999;
    padding-left: 4px;
  }

  &::v-deep .vue-recycle-scroller__item-view {
    padding-right: 20px;
    @include sm {
      padding-right: 5px;
    }
  }
}

.scroller {
  height: 100%;
}

.competence-scroll {
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  height: 50vh;
  overflow-y: auto;
}

.ps {
  max-height: 50vh;
  width: calc(100% + 18px);
  padding-right: 18px;
}

.information__header {
  margin-bottom: 24px;
}

.competence-modal__add-new {
  box-shadow: 10px;
  font-size: 16px;
}

.select-function {
  &__label {
    font-size: 18px;
  }

  &__card {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }

  &__select {
    flex-basis: 0;
    flex-shrink: 1;
  }

  &__btn-dell {
    padding-bottom: 16.5px;
    padding-top: 15px;
    flex-basis: 0;
    flex-shrink: 0;
  }

  &__input-error {
    color: $error;
    margin-top: 8px;
  }
}
</style>
