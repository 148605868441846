import {ref} from "vue";

export default function oneRequest() {
  const sleep = ref(false);
  let timer;
  const requestFn = async (fn, {load = 3000, one = false}) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      sleep.value = false
    }, load)
    if (sleep.value)
      return;
    if (fn == null)
      console.error('Функция не передана')
    try {
      sleep.value = true;
      await fn();
      if (!one) {
        setTimeout(() => {
          sleep.value = false
        }, 600);
      }
    } catch (e) {
      setTimeout(() => {
        sleep.value = false
      }, 300);
      throw e;
    }
  }
  return {
    requestFn
  }
}
