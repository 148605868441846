import ApiUtil from '@/utils/api'

export default {
    sendContactRequest: (data) =>
        ApiUtil({
            url: '/api/contact',
            method: 'post',
            requiresAuth: false,
            data
        }, ),
}