<template>
  <div class="">
    <div class="info-box" @click="action">
      <div class="body">
        <div class="text">
          <p class="info-box__title">
            <slot name="title" />
          </p>
          <div class="necessity-small"><slot name="necessity-small" /></div>
          <span>
            <slot name="description" />
          </span>
        </div>
        <svg-icon v-if="icon" width="26" height="27" :name="icon"></svg-icon>
      </div>
      <div class="action">
        <div><slot name="action" /></div>
        <div class="necessity"><slot name="necessity" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import AlertModal from "@/components/modals/AlertModal";
export default {
  name: "InfoBoxAdd",
  components: { SvgIcon },
  props: {
    icon: {
      type: [Number, String],
      default: null,
    },
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.info-box {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 20px;
  @include sm {
    background: #f6f6f6;
    border: 0px;
  }
  span {
    color: $accent;
  }
}
.necessity-small {
  display: none;
  @include sm {
    display: block;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 12px;
    color: #a0a0a0;
    font-family: Muller;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%;
    letter-spacing: 0.3px;
  }
}
.temp-width {
  .body {
    .text {
      max-width: 540px;
    }

    .svg-icon {
      margin-left: auto;
    }
  }
}

.action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
.necessity {
  @include desktop_h2;
  color: #a0a0a0;
  @include sm {
    display: none;
  }
}
.body {
  display: flex;
  gap: 16px;
  p {
    display: flex;
    @include desktop_h2;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 500;
    color: $input-label;
    @include sm {
      margin-bottom: 0;
      color: #42434e;
      font-family: Muller;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 124%; /* 18.6px */
      letter-spacing: 0.3px;
    }
  }
  span {
    @include desktop_standart;
    @include sm() {
    }
  }
  .action {
    @include sm() {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  .text {
    margin-bottom: 20px;
    width: 100%;
  }
}
</style>
