import { companyService } from "@/services";
import { errorFormat } from "@/utils/helper";

export default {
  namespaced: true,
  state: {
    companies: null,
  },
  actions: {
    async getCompany({ commit }, data) {
      try {
        const response = await companyService.getCompany(data);
        commit('SET_COMPANIES', response.data.data);
      } catch (e) {
        if (e.response != null)
          throw errorFormat(e.response.data);
        console.warn(e);
      }
    },
    async addCompany({ commit }, data) {
      try {
        const response = await companyService.addCompany(data);
        return response.data.data
      } catch (e) {
        if (e.response != null)
          throw errorFormat(e.response.data);
        console.warn(e);
      }
    },
  },
  mutations: {
    SET_COMPANIES(state, value) {
      state.companies = value
    },
  },
  getters: {
    companies(state) {
      return state.companies
    },
  },
}
