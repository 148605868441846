<template>
  <img
    class="sm22"
    :class="className"
    :style="style"
    :src="this.$store.getters['auth/assets'] + 'svg/' + this.name + '.svg'"
    loading="lazy"
  />
</template>

<script>
export default {
  name: "svg-icon",
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
    custom: {
      type: Object,
      default: function () {
        return {};
      },
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconPath() {
      try {
        let icon = require(`@/assets/svg/${this.name}.svg`);
        if (Object.prototype.hasOwnProperty.call(icon, "default")) {
          icon = icon.default;
        }

        return icon.url;
      } catch (ex) {
        console.warn(ex);
      }
    },

    className() {
      return "svg-icon svg-icon--" + this.name;
    },

    style() {
      let width = this.width ? `width: ${this.width}px;` : "";
      let height = this.height ? `height: ${this.height}px;` : "";
      return width + height;
    },
  },
};
</script>

<style>
.svg-icon {
  fill: currentColor;
  height: 24px;
  width: 24px;
}
svg path {
  fill: inherit;
}

.svg-icon--customer-3 {
  width: 100%;
  max-height: 327px;
  height: 327px;
  z-index: 0;
  flex: 1;
  /* scale: 1.35; */
  position: absolute !important;
  bottom: -8px !important;
  left: 0px !important;
}

.svg-icon--customer-1 {
  width: 100%;
  max-height: 327px;
  height: 327px;
  z-index: 0;
  flex: 1;
  /* scale: 1.35; */
  position: absolute !important;
  bottom: -96px !important;
  left: 1px !important;
  scale: 1.1 !important;
}

.svg-icon--customer-2 {
  width: 100%;
  max-height: 327px;
  height: 327px;
  z-index: 0;
  flex: 1;
  /* scale: 1.35; */
  position: absolute !important;
  bottom: -26px !important;
  left: -12px !important;
  scale: 0.8 !important;
}

.svg-icon--executor-1 {
  position: absolute !important;
  bottom: -80px !important;
  left: 5px !important;
  scale: 1.3 !important;
}

.svg-icon--executor-2 {
  position: absolute !important;
  bottom: -15px !important;
  left: -10px !important;
}

.svg-icon--executor-3 {
  position: absolute !important;
  bottom: -44px !important;
  left: -5px !important;
  scale: 0.9 !important;
}

@media (max-width: 991.98px) {
  .svg-icon--customer-2 {
    bottom: -108px !important;
    left: -5px !important;
  }
  .svg-icon--customer-1 {
    bottom: -138px !important;
    scale: 0.8 !important;
  }
  .svg-icon--customer-3 {
    scale: 0.8 !important;
    bottom: -97px !important;
    left: -12px !important;
  }
  .svg-icon--executor-2 {
    bottom: -108px !important;
    left: -5px !important;
  }
  .svg-icon--executor-1 {
    bottom: -119px !important;
    scale: 0.8 !important;
  }
  .svg-icon--executor-3 {
    scale: 0.8 !important;
    bottom: -97px !important;
    left: -12px !important;
  }
}
</style>
