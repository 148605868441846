<template>
  <div class="not-item">
    <div class="not-item__content">
      <div class="not-item__header">
        <div class="not-item__header_text">{{ title }}</div>
      </div>
      <div class="not-item__description">{{ description }}</div>
      <div class="not-item__action" v-if="btnName">
        <base-button @click="click">{{ btnName }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "notItem",
  components: {BaseButton},
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    btnName: {
      type: String,
      default: ""
    },
  },
  methods: {
    click() {
      this.$emit('btnEvent')
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
  .not-item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 520px;
    background: #FBFBFB;
    border: 2px solid #EEEEEE;
    border-radius: 16px;

    &.height-100 {
      height: 100%;
    }

    @include md {
      min-height: auto;
    }

    &.no-style {
      border: none;
      background-color: transparent;
    }

    &__content {
      width: 573px;
      @include md {
        padding: 30px 0;
      }
      @include sm {
        width: auto;
        padding: 30px 20px;
      }
    }


    &__header {
      &_text {
        text-align: center;
        @include desktop_h1;
        @include sm {
          font-size: 20px;
          line-height: 22px;
          @include desktop_h2;
        }
      }
    }
    &__description {
      @extend %desktop_standart;
      margin-top: 12px;
      margin-bottom: 12px;
      padding: 0 20px;
      text-align: center;
      @include md {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      @include sm {
        @include desktop_additional;
        margin-bottom: 40px;
        padding: 0;
      }
    }
    &__action {
      display: flex;
      justify-content: center;
      * {
        width: 270px;
        @include sm {
          width: 100%;
        }
      }
    }
  }
</style>
