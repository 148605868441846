<template>
  <div class="add-work-modal">
    <base-modal size="550" :is-open="isOpen" @close="closeModal">
      <div class="first-block" ref="v-modal">
        <h2 class="information__header">Место работы</h2>
        <vue-single-select-rows
          :new-value="true"
          header="Выбрать компанию"
          subtitle="Выберите компанию, в которой работали, или добавьте новую, если не найдете свою в списке. Она отправится на модерацию."
          description="Компания"
          placeholder="Найти компанию"
          class="desired-clients"
          :items="companiesList"
          v-model="companies"
          is-scroll="true"
          :searchEvent="searchEvent"
          @updateSearch="updateSearch"
          :is-error="v$.companies.$error"
          :error="[
            {
              text: `Выберите компанию`,
              status: v$.companies.required.$invalid,
            },
          ]"
          searchEmptyText="Добавить новую компанию"
        ></vue-single-select-rows>

        <vue-single-select-rows
          header="Выбрать роль"
          subtitle="Выберите роль, которую вы исполняли в компании"
          description="Роль"
          placeholder="Выбрать роль"
          class="desired-clients"
          :items="workRoles"
          v-model="relevantRole"
          :searchEvent="searchWorkRole"
          @updateSearch="updateWorkRoleSearch"
          :new-value="false"
          searchEmptyText="Ничего не найдено. Измените условия поиска"
          :is-error="v$.relevantRole.$error"
          :error="[
            {
              text: 'Роль обязателена для заполнения',
              status: v$.relevantRole.required.$invalid,
            },
          ]"
        ></vue-single-select-rows>
        <base-month-select
          class="select-date"
          title="Начало работы"
          v-model="dateStart"
          :is-error="v$.dateStart.$error"
          :error="[
            {
              text: `Поле обязательно для заполнения`,
              status: v$.dateStart.dateRequired.$invalid,
            },
            {
              text: `Введите год от 1950 до ${this.curYear}`,
              status: v$.dateStart.dateValidation.$invalid,
            },
            {
              text: `Введите прошедший месяц`,
              status: v$.dateStart.normalMonth.$invalid,
            },
          ]"
        />
        <base-radio
          v-model="isToday"
          @click="changeSwitch"
          is-switch
          type="checkbox"
          title="Работаю сейчас"
        />
        <base-month-select
          v-if="!isToday"
          title="Окончание работы"
          v-model="dateFinish"
          :is-error="isErrorFinish"
          :error="errorFinish"
        />
      </div>

      <template #body1>
        <h2 class="information__header">Выберите, чем вы занимались</h2>
        <div class="role-block__body">
          <perfect-scrollbar ref="role-list">
            <div class="role-block__list">
              <div
                class="role-block"
                v-for="(item, idx) in workareasListGrouped"
                :key="`item_${item.id}`"
              >
                <base-call-modal
                  header=""
                  :title="item.title"
                  placeholder="Выберите из списка"
                  @event-click="openSpecialization(item.industries)"
                  @on-remove="removeItemSpecialization(item.industries)"
                  :order="idx"
                  remove
                  :is-error="v$.workareasList.$error"
                  :error="[
                    {
                      text: `Выберите, чем вы занимались`,
                      status: v$.workareasList.checkRole.$invalid,
                    },
                    {
                      text: `Выберите, чем вы занимались`,
                      status: v$.workareasList.checkLength.$invalid,
                    },
                  ]"
                />
              </div>
            </div>
          </perfect-scrollbar>
        </div>

        <add-button class="button--big-left" @click="addItemSpecialization"
          >Добавить
        </add-button>
        <base-button class="add-work-modal__submit" :one-click-event="onSubmit"
          >Сохранить</base-button
        >
      </template>

      <add-specialization-modal-work
        ref="add-specialization"
        :is-open="specializationModal"
        :functions-list="optionsList"
        @update-data="addSpecialization"
        @close="closeSpecializationModal"
        title="Выберите специализации"
        :v-model="currentSpec"
        btn-name="Добавить"
      />
    </base-modal>
  </div>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseRadio from "@/components/base/BaseRadio";
import BaseMonthSelect from "@/components/base/BaseMonthSelect";
import BaseButton from "@/components/base/BaseButton";
import useVuelidate from "@vuelidate/core";
import VueSingleSelectRows from "@/components/ui/VueSingleSelectRows";
import { mapGetters } from "vuex";
import AddButton from "@/components/ui/button/AddButton";
import { debounce } from "@/utils/helper";
import BaseCallModal from "@/components/base/BaseCallModal";
import BaseInput from "@/components/base/BaseInput";
import AddSpecializationModal from "@/components/modals/AddSpecializationModal";
import AddSpecializationModalWork from "@/components/modals/AddSpecializationModalWork";
import SvgIcon from "@/components/base/SvgIcon";
import { required, minLength } from "@vuelidate/validators";

export default {
  name: "AddWorkModal",
  components: {
    SvgIcon,
    AddSpecializationModal,
    AddSpecializationModalWork,
    BaseInput,
    BaseCallModal,
    AddButton,
    VueSingleSelectRows,
    BaseButton,
    BaseMonthSelect,
    BaseRadio,
    BaseModal,
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  data() {
    return {
      isOpen: false,
      dateStart: "",
      dateFinish: "",
      relevantRole: [],
      currentWorkareas: [],
      companyIndustry: null,
      isToday: false,
      curMonth: new Date().getMonth(),
      curYear: new Date().getFullYear(),
      companies: [],
      totalYears: 0,
      searchDebounce: null,
      specializationModal: false,
      autoIncrement: 1,
      workareasList: [
        {
          years: null,
          id: this.autoIncrement || 1,
          title: "",
          industries: null,
          options: null,
          works: null,
        },
      ],
      searchWorkTypeDebounce: null,
      works: [],
      modalId: null,
      specTitle: "",
      currentSpec: null,
    };
  },
  computed: {
    ...mapGetters({
      companiesList: "company/companies",
      industriesList: "registration/industriesList",
      optionsList: "registration/optionsList",
      workTypeList: "work/workType",
      workRoles: "service/workRoles",
      worksArray: "service/works",
    }),
    workareasListGrouped() {
      const info = JSON.parse(JSON.stringify(this.workareasList));
      return info.length ? this.groupByIndustries(info) : [];
    },
    errorFinish() {
      if (!this.isToday) {
        return [
          {
            text: `Поле обязательно для заполнения`,
            status: this.v$.dateFinish.dateRequired.$invalid,
          },
          {
            text: `Введите год от 1950 до ${new Date().getFullYear()}`,
            status: this.v$.dateFinish.dateValidation.$invalid,
          },
          {
            text: `Окончание работы должно быть позже даты начала работы`,
            status: this.v$.dateFinish.moreThan.$invalid,
          },
        ];
      }
      return null;
    },
    isErrorFinish() {
      if (!this.isToday) {
        return this.v$.dateFinish.$error;
      }
      return null;
    },
  },
  mounted() {
    this.getIndustries();
    this.getOptions();
    this.getWorkRoles();
  },
  created() {
    this.searchDebounce = debounce(this.getCompany, 400);
    this.searchWorkTypeDebounce = debounce(this.getWork, 400);
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    hideModal() {
      this.isOpen = false;
    },
    showModal(data) {
      this.v$.$reset();
      this.isToday = false;
      if (data && data.work) {
        const info = JSON.parse(JSON.stringify(data));
        this.modalId = info.work.id;
        if (info.work) {
          this.dateStart = info.work.work_start.split("-").join(".");
          if (info.work.work_finish) {
            this.dateFinish = info.work.work_finish.split("-").join(".");
            this.isToday = false;
          } else {
            this.isToday = true;
          }
        }

        if (info.company) {
          this.companies = [info.company];
          this.companies[0].value = true;
          this.companies[0].newValue = false;
        }
        if (info.workareas) {
          this.workareasList = [];
          this.relevantRole = [
            { ...info.workareas[0].role, ...{ value: true, newValue: false } },
          ];
          info.workareas.forEach((item) => {
            let payload = {
              workarea_id: item.id,
              id: this.autoIncrement || 1,
              industries: item.industry.id,
              industry_name: item.industry.name,
              option_name: item.option.name,
              options: {
                id: item.option.id,
                parent_id: item.option.parent_id,
                name: item.option.name,
              },
              works: this.relevantRole,
            };
            if (item.work_years) {
              payload.years = item.work_years;
            }
            this.workareasList.push(payload);
            this.autoIncrement++;
          });
        }
      } else {
        this.modalId = null;
        this.dateStart = "";
        this.dateFinish = "";
        this.totalYears = 0;
        this.relevantRole = [];
        this.companies = [];
        this.workareasList = [
          {
            years: null,
            id: this.autoIncrement || 1,
            industries: -1,
            options: { id: -1, parent_id: -1, name: "" },
            works: null,
          },
        ];
      }
      this.isOpen = true;
    },
    groupByIndustries(data) {
      const groupedByIndustry = {};

      for (const item of data) {
        const industryName = item.industry_name;

        if (!groupedByIndustry[industryName]) {
          groupedByIndustry[industryName] = {
            industries: item.industries,
            years: item.years,
            options: [],
          };
        }

        groupedByIndustry[industryName].options.push({
          id: item.options.id,
          name: item.options.name,
        });
      }
      for (const industry in groupedByIndustry) {
        const options = groupedByIndustry[industry].options.map((option) => option.name);
        groupedByIndustry[industry].title = options.filter((item) => item).length
          ? industry + " - " + options.join(", ")
          : ""; //ТУТ
      }
      return Object.values(groupedByIndustry);
    },
    searchEvent(v) {
      this.$store.dispatch("company/getCompany", v);
    },
    dateValidation(e) {
      let date = e.split(".");
      let year = date[0];

      if ((year >= 1950 && year <= this.curYear) || !year) {
        return true;
      }

      return false;
    },
    dateRequired(e) {
      let date = e.split(".");
      let year = date[0];
      let month = date[1];
      if (year && month !== "undefined") {
        return true;
      }

      return false;
    },
    normalMonth(e) {
      let date = e.split(".");
      let year = date[0];
      let month = date[1];

      if (year >= this.curYear && month > this.curMonth) {
        return false;
      }
      return true;
    },
    changeSwitch(e) {
      if (this.isToday) {
        this.dateFinish = "";
      } else this.dateFinish = null;
    },
    async getCompany(val) {
      await this.$store.dispatch("company/getCompany", val);
    },
    moreThan() {
      if (this.dateStart.length === 10 && this.dateFinish.length === 10) {
        let start = new Date(this.dateStart.split(".").join("-"));
        let current = new Date(this.dateFinish.split(".").join("-"));
        if (start <= current) {
          return true;
        }
        return false;
      }
      return false;
    },
    updateSearch(e) {
      this.searchDebounce(e);
    },
    async addOption(name) {
      let response = await this.$store.dispatch("registration/addOptions", {
        name: name,
      });
      return response.id;
    },
    async onSubmit() {
      const result = await this.v$.$validate();
      if (!result) {
        this.$refs[`v-modal`].scrollIntoView({ behavior: "smooth" });
        return;
      } else {
        let specList = [];
        let dateStartParsed = new Date(Date.parse(this.dateStart.split(".").join("-")));
        let dateFinishedParsed = new Date();
        if (this.dateFinish) {
          dateFinishedParsed = new Date(Date.parse(this.dateFinish.split(".").join("-")));
        }
        this.totalYears = Math.round(
          Math.abs(dateFinishedParsed - dateStartParsed) / (1000 * 60 * 60 * 24 * 365)
        );
        for (let i = 0; i < this.workareasList.length; i++) {
          let item = this.workareasList[i];
          let option_id_tmp = item.options.id;
          if (option_id_tmp != -1) {
            if (option_id_tmp <= -10) {
              option_id_tmp = await this.addOption(item.options.name);
            }
            let newEl = {
              industry_id: item.industries,
              option_id: option_id_tmp,
            };
            newEl.work_years = 0;
            if (this.totalYears) {
              newEl.work_years = this.totalYears;
            }

            if (this.relevantRole) {
              newEl.role_id = this.relevantRole[0].id;
            }
            specList.push(newEl);
          }
        }

        let dateStart = this.dateStart.split(".").join("-");
        const payload = {
          work_start: dateStart,
          workareas: specList,
        };

        if (this.modalId) {
          payload["work_id"] = this.modalId;
        }

        if (this.dateFinish && this.dateFinish.length < 11) {
          payload.work_finish = this.dateFinish.split(".").join("-");
        }

        if (this.isToday) {
          payload.work_finish = null;
        }

        if (this.companies && this.companies[0].newValue) {
          payload.new_company = this.companies[0].name;
        } else {
          payload.company_id = this.companies[0].id;
        }
        this.v$.$reset();
        this.$emit("addWork", payload);
        this.$store.commit("registration/DROP_NEW_OPTIONS");
        this.isToday = false;
      }
    },
    openSpecialization(industries_id) {
      let item = this.workareasList.filter((item) => item.industries === industries_id);
      this.$refs["add-specialization"].showModal(item);
    },
    closeSpecializationModal() {
      this.$refs["add-specialization"].hideModal();
    },
    async getWorkRoles() {
      try {
        await this.$store.dispatch("service/getWorkRoles");
      } catch (e) {
        console.log(e);
      }
    },
    async getIndustries() {
      await this.$store.dispatch("registration/getIndustries");
    },
    async getOptions() {
      await this.$store.dispatch("registration/getOptions");
    },
    addSpecialization(e) {
      this.workareasList = this.workareasList.filter((item) => item.industries !== -1);
      e.options.forEach((item) => {
        if ((item.id !== null) & (item.id !== -1)) {
          let payload = {
            workarea_id: null,
            id: this.autoIncrement || 1,
            industries: e.industries,
            industry_name: e.industry_name,
            options: {
              parent_id: item.parent_id,
              name: item.name,
              id: item.id,
            },
          };

          this.workareasList.push(payload);
          this.autoIncrement++;
        }
      });
      let obj = this.workareasList;
      obj = obj.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.industries === value.industries && t.options.id === value.options.id
          )
      );
      this.workareasList = obj;
      this.$refs["add-specialization"].hideModal();
    },
    addItemSpecialization() {
      this.autoIncrement++;
      this.workareasList.push({
        id: this.autoIncrement,
        industries: -1,
        options: { parent_id: -1, id: -1, name: "" },
      });

      this.$nextTick(() => {
        const container = this.$el.querySelector(".ps");
        container.scrollTop = container.scrollHeight;
      });
    },
    removeItemSpecialization(industries_id) {
      this.workareasList = this.workareasList.filter(
        (item) => item.industries !== industries_id
      );
      if (!this.workareasList.length) {
        this.addItemSpecialization();
      }
    },
    searchEventWorkType(v) {
      //TODO Нужно переделать
      // this.$store.dispatch('work/getWorkType', v);
      return this.workRoles.filter((item) => {
        item.toString().toLowerCase().includes(v.toString().toLowerCase());
      });
    },
    updateWorkTypeSearch(e) {
      this.searchWorkTypeDebounce(e);
    },
    getWork() {
      this.$store.dispatch("work/getWorkType", null);
    },
    checkRole(e) {
      if (e && e[0].options.options != -1) {
        return true;
      }
      return false;
    },
    checkLength(e) {
      if (e && e[0].industries != -1) {
        return true;
      }
      return false;
    },
    clearStart(e) {
      this.dateStart = "";
    },
    clearFinish(e) {
      this.dateFinish = "";
    },
  },
  validations() {
    if (this.isToday) {
      return {
        dateStart: {
          dateRequired: this.dateRequired,
          dateValidation: this.dateValidation,
          normalMonth: this.normalMonth,
        },
        relevantRole: {
          required,
        },
        companies: {
          required,
        },
        workareasList: {
          checkRole: this.checkRole,
          checkLength: this.checkLength,
        },
        isToday: {},
      };
    }
    return {
      dateStart: {
        dateRequired: this.dateRequired,
        dateValidation: this.dateValidation,
        normalMonth: this.normalMonth,
      },
      dateFinish: {
        dateRequired: this.dateRequired,
        dateValidation: this.dateValidation,
        normalMonth: this.normalMonth,
        moreThan: this.moreThan,
      },
      companies: {
        required,
      },
      relevantRole: {
        required,
      },
      workareasList: {
        checkRole: this.checkRole,
        checkLength: this.checkLength,
      },
    };
  },
};
</script>

<style lang="scss">
.add-work-modal .dialog-content {
  max-height: none;
}
</style>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.add-work-modal {
  &__submit {
    margin-top: 32px;

    @include sm {
      margin-top: 30px;
    }
  }
}

.information__header {
  @include desktop_h2_norm;
  margin-bottom: 10px;
}

.select-month {
  margin-bottom: 24px;

  &.error {
    margin-bottom: 18px;
  }
}

.dialog-content:first-child {
  padding-bottom: 10px;

  .input-radio.switch {
    margin-top: -14px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.error-text {
  color: red;
}

.sphere-roles {
  &__description {
    @extend %text-standart;
    max-width: 315px;
    padding-bottom: 8px;
  }

  .multi-select-rows {
    margin-bottom: 12px;
  }

  &__header {
    position: relative;

    .information__header {
      margin-right: 10px;
      margin-bottom: 0;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }

    &-tooltip {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-left: auto;
      cursor: pointer;
      top: 3px;
      right: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #e6e6e6;
    }
  }
}

.select-function {
  &__label {
    font-size: 18px;
  }

  &__select {
    margin-bottom: 21px;
  }
}

.select-date {
  margin-bottom: 0px;
}

.select-industry {
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 4px;

    .input-group {
      margin-bottom: 0;
    }
  }

  &__error {
    grid-column: span 2;

    .error-text {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.role-block {
  display: grid;
  padding: 24px 0 9px;
  border-bottom: 1px solid $line-light-gray;

  @include md {
    margin: 0;
  }

  &:first-child {
    border-top: none;
  }

  .input-container__block {
    margin-bottom: 24px;
  }

  &__body {
    max-height: 50vh;
    margin-bottom: 24px;
    width: auto;
    margin: 0 -18px 32px -18px;
    padding-left: 18px;

    @include sm {
      padding-bottom: 2px;
    }

    .ps {
      max-height: 50vh;
    }
  }

  &__list {
    padding-right: 18px;
  }
}
</style>
