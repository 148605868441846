<template>
  <div class="select-month" :class="{ disabled: disabled, error: isError }">
    <p class="select-month__title" v-if="title">{{ title }}</p>
    <div class="select-month__body">
      <input type="hidden" :value="formatResult" />
      <base-dropdown
        v-if="isDay"
        placeholder="День"
        :list="day"
        @update:model-value="updateMonthValue"
        :model-value="activeDay"
      />
      <base-dropdown
        placeholder="Месяц"
        :list="month"
        @update:model-value="updateMonthValue"
        :model-value="activeMonth"
      />
      <base-input
        type="number"
        placeholder="Год"
        @clearClick="clearAll"
        @update:model-value="updateYear"
        :model-value="year"
      />
      <div class="select-month__error">
        <base-error-text v-if="isError" :error="error" :class="{ error: isError }" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseDropdown from "@/components/base/BaseDropdown";
import BaseInput from "@/components/base/BaseInput";
import BaseErrorText from "@/components/base/BaseErrorText";
export default {
  name: "BaseMonthSelect",
  components: { BaseErrorText, BaseInput, BaseDropdown },
  props: {
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isDay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeMonth: null,
      year: null,
      activeDay: null,
      month: [
        {
          id: "01",
          name: "Январь",
        },
        {
          id: "02",
          name: "Февраль",
        },
        {
          id: "03",
          name: "Март",
        },
        {
          id: "04",
          name: "Апрель",
        },
        {
          id: "05",
          name: "Май",
        },
        {
          id: "06",
          name: "Июнь",
        },
        {
          id: "07",
          name: "Июль",
        },
        {
          id: "08",
          name: "Август",
        },
        {
          id: "09",
          name: "Сентябрь",
        },
        {
          id: "10",
          name: "Октябрь",
        },
        {
          id: "11",
          name: "Ноябрь",
        },
        {
          id: "12",
          name: "Декабрь",
        },
      ],
      day: [
        {
          id: "01",
          name: "1",
        },
        {
          id: "02",
          name: "2",
        },
      ],
    };
  },
  computed: {
    formatResult: {
      get() {
        // YYYY-MM-DD
        if (this.modelValue != null) {
          let arr = this.modelValue.split(".");
          this.activeMonth = arr[1];
          this.year = arr[0];
        }
        return this.modelValue;
      },
      set(v) {
        return this.$emit("update:modelValue", v);
      },
    },
  },
  methods: {
    updateMonthValue(e) {
      this.activeMonth = e;
      this.updateFormat();
    },
    updateFormat(year) {
      if (year) {
        this.formatResult = `${year}.${this.activeMonth}.01`;
      } else {
        this.formatResult = `${this.year}.${this.activeMonth}.01`;
      }
    },
    updateYear(e) {
      this.year = e;
      this.updateFormat(e);
    },
    clearAll(e) {
      this.activeMonth = null;
      this.$emit("update:modelValue", null);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.select-month {
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 4px;

    .input-group {
      margin-bottom: 0;
    }
  }

  &__error {
    grid-column: span 2;

    .error-text {
      margin-bottom: 0;
    }
  }

  &__title {
    display: flex;
    @extend %text-standart;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 500;
    color: $input-label;
    @include sm {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 106.667% */
    }
  }

  &.disabled {
    position: relative;
    pointer-events: none;

    &:after {
      position: absolute;
      content: "";
      top: 20px;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
