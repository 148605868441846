<template>
  <div class="select-industry" :class="{'industry-full': full}">
    <p class="mb-2 select-industry__label">{{ $t('component.industry.select')}}</p>
    <v-row class="select-industry__selector" style="margin: 0">
      <v-col class="flex-grow-1 flex-shrink-1 pa-0">
        <base-dropdown
            class="registration-form__double"
            :placeholder="this.$t('input.select-layer')"
            :list="industryList"
            v-model="selectIndustry"
        />
      </v-col>
      <v-col class="flex-grow-0 flex-shrink-0 pa-0 slot-wrapper">
        <div class="select-industry__dell-industry">
          <slot/>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <template v-if="selectIndustry != null || isOneFunction">
          <div>
            <template v-for="(itemFunction, index) in functionList" :key="itemFunction.id">
<!--              <div class="select-industry__hr-line" ></div>-->
              <select-function
                class="select-industry__function"
                :full="full" v-model="functionList[index]"
                :functions-list="functionsList"
                :description-function="$t('component.industry.select-sub-function-description')"
              >
                <base-button
                    @click="dellIndustry(itemFunction)"
                    class="select-function__btn-dell button--light"
                    icon icon-prefix="dell-item"
                    type="button"
                ></base-button>
              </select-function>
            </template>
          </div>
          <add-button
            v-if="!isOneFunction"
            class="select-industry__add"
            @click="addFunction"
          >
            {{ $t('component.industry.add-function')}}
          </add-button>
        </template>
      </v-col>
    </v-row>
    <div v-if="subDescription" class="select-industry__sub-description">{{ subDescription }}</div>
  </div>
</template>

<script>
import BaseDropdown from "@/components/base/BaseDropdown";
import SvgIcon from "@/components/base/SvgIcon";
import SizedBox from "@/components/ui/SizedBox";
import VueMultiSelect from "@/components/ui/VueMultiSelect";
import SelectFunction from "@/components/ui/SelectFunction";
import {computed} from "vue";
import AddButton from "@/components/ui/button/AddButton";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "SelectIndustry",
  components: {BaseButton, AddButton, SelectFunction, SvgIcon, BaseDropdown, VueMultiSelect, SizedBox},
  props: {
    full: Boolean,
    industryList: {
      type: Array,
      default: () => []
    },
    functionsList: {
      type: Array,
      default: () => []
    },
    indexGlobal: {
      type: Number,
      default: null
    },
    modelValue: {
      type: Object,
      default: () => ({
        work_types: [],
        industries: [
          {
            id: null,
            options: []
          }
        ]
      }),
    },
    subDescription: {
      type: String
    },
    isOneFunction: {
      type: Boolean,
      default: false
    },
    clearOnDelete: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      fruit: '',
      selectIndustry: null, // this.modelValue.work_types.length > 0 ? this.modelValue.work_types[0] : null,
      functionList: [], //this.modelValue.industries,
      selectMainFunction: '',
    }
  },
  created() {
    if (this.isOneFunction) {
      this.addFunction();
    }
  },
  watch: {
    selectIndustry() {
      this.setModelData();
      this.$emit('change');
    },
    functionList() {
      this.setModelData();
      this.$emit('change');
    }
  },
  methods: {
    dellIndustry(value) {
      if (!this.clearOnDelete) {
        this.functionList = this.functionList.filter(item => item !== value);
      } else {
        this.functionList = [{ id: null, options: [] }];
      }
    },
    setModelData() {
      let modelData = {
        id: this.selectIndustry,
        options: this.functionList
      };
      this.$emit('update:modelValue', {...this.modelValue, ...modelData})
    },
    addFunction() {
      this.functionList.push({
        id: null,
        options: []
      })

      this.$emit('addFunction');
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';
.select-industry {
  &__function {
    margin-top: 12px;
  }
  &__sub-description {
    @extend %text-small;
    color: $text-gray;
    margin-top: 10px;
  }
  position: relative;
  padding-bottom: 24px;
  border-bottom: 1px solid $line-light-gray;
  &:last-child {
    border-bottom: none;
  }
  &__label {
    font-size: 18px;
  }
  &__hr-line {
    width: 100%;
    border-bottom: 1px solid $line-light-gray;
    margin: 32px 0;
  }

  &__add {
    margin-top: 20px;
  }
  &__dell-industry {
    margin-left: 20px;
    cursor: pointer;
    //position: absolute;
    //right: -30px;
    //top: 49px;
  }

  &.no-btn {
    .slot-wrapper {
      display: none;
    }
  }
}
.registration-form__function {
  gap: 24px 20px;

  .registration-form__double {
    margin-bottom: 22px;
  }

  &-add {
    display: flex;
    align-items: center;
    background-color: $bg-light-gray;
    padding: 17px 20px;
    border-radius: 12px;
    cursor: pointer;

    p {
      @extend %text-standart;
      flex-grow: 1;
    }
  }
  &-plus {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: $accent;
  }
}
.industry-full {
  .select-industry {
    &__selector {
      width: 100%;
    }
    &__selector {
      width: 100%;
    }
    &__add {
      width: 100%;
    }
  }
}
</style>
