<template>
  <div
    v-click-outside="handleFocusOut"
    class="base-dropdown__wrapper"
    @click.stop="checkOpen"
    :class="{
      error: isError,
      'base-dropdown__disable': this.disabled,
    }"
  >
    <p
      class="base-dropdown__caption"
      :class="{ 'base-dropdown__caption_request-add': requestAddTheme }"
      v-if="title"
    >
      {{ title }}
    </p>
    <div class="base-dropdown">
      <div class="base-dropdown__block" :class="[isWhite ? 'white' : 'grey']">
        <input
          ref="selectInput"
          :disabled="disabled"
          v-if="!notSearch"
          type="text"
          @input="filterList"
          v-model="searchText"
          :placeholder="placeholder"
          class="base-dropdown__field"
        />
        <div
          v-else
          :class="'base-dropdown__field' + (searchText == '' ? '-placeholder' : '')"
        >
          {{ searchText == "" ? placeholder : searchText }}
        </div>
        <div
          class="base-dropdown__btn"
          @click.stop="checkCloseOpen"
          :class="{ rotate: isDropdown }"
        >
          <svg-icon name="arrow-down" />
        </div>
      </div>
      <div v-if="isDropdown" class="base-dropdown__list" @click.stop>
        <perfect-scrollbar>
          <div
            class="base-dropdown__item"
            v-for="item in filtered"
            :key="`dropdown_${item.id}`"
            @click="changeActive(item)"
          >
            {{ item.name }}
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div v-if="isError" class="base-dropdown__input-error">{{ errorString }}</div>
    <div v-if="subDescription && !isError" class="base-dropdown__sub-description">
      {{ subDescription }}
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";

export default {
  name: "BaseDropdown",
  components: { SvgIcon },
  props: {
    disabled: Boolean,
    subDescription: {
      type: String,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number, null],
      default: null,
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    /** Запретить поиск */
    notSearch: Boolean,
    isError: {
      type: Boolean,
      default: false,
    },
    requestAddTheme: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: String,
      default: null,
    },
  },
  created() {
    if (this.modelValue != null && this.defaultValue === null) {
      let selectItem = this.list.filter((value) => value.id === this.modelValue);
      if (selectItem.length > 0) this.searchText = selectItem[0].name;
    } else if (this.defaultValue !== null) {
      let selectItem = this.list.filter((value) => value.id === this.defaultValue);
      if (selectItem.length > 0) {
        this.searchText = selectItem[0].name;
        this.changeActive(selectItem[0]);
      }
    }
  },
  data() {
    return {
      filtered: this.list,
      searchText: "",
      isDropdown: false,
    };
  },
  watch: {
    list: function (val) {
      this.filtered = val;
    },
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        this.setTextAndValue(newValue);
      },
    },
  },
  methods: {
    focus() {
      this.$refs.selectInput.focus();
    },
    handleFocusOut() {
      this.isDropdown = false;
    },
    setTextAndValue(value) {
      if (value != null && value != "null") {
        let selectItem = this.list.filter((listItem) => listItem.id === value);
        if (selectItem.length > 0) this.searchText = selectItem[0].name;
      } else this.searchText = "";
    },
    filterList() {
      if (this.searchText.length) {
        let text = this.searchText.toLowerCase();
        this.filtered = this.list.filter((item) => {
          let resText = item.name.toLowerCase();
          return resText.indexOf(text) !== -1;
        });
      } else {
        this.filtered = this.list;
      }
    },
    changeActive(val) {
      this.$emit("update:modelValue", val.id);
      if (this.defaultValue === null) this.setTextAndValue(this.modelValue);
      this.isDropdown = false;
    },
    checkOpen() {
      if (!this.isDropdown && !this.disabled) {
        this.isDropdown = true;
      }
    },
    checkCloseOpen() {
      if (!this.disabled) this.isDropdown = !this.isDropdown;
    },
  },
  computed: {
    checksStatus() {
      return this.error.filter((error) => error.status).length > 0;
    },
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.grey {
  background: $bg-light-gray;
}

.white {
  background: white;
}

.base-dropdown__wrapper {
  &.error .base-dropdown__block {
    border: 1px solid $error;
  }
  .base-dropdown {
    position: relative;
    &__input-error {
      color: $error;
      margin-top: 8px;
    }
    &__sub-description {
      @extend %text-small;
      color: $text-gray;
      margin-top: 10px;
    }
    &__list {
      position: absolute;
      width: 100%;
      top: 55px;
      z-index: 5;
      border-radius: 12px;
      overflow: inherit;
      overflow-y: auto;
      box-shadow: 7px 8px 15px #a7a7a761;
      .ps {
        max-height: 245px;
      }
    }
    &__btn {
      transform: rotate(0);
      transition: 0.3s;
      &.rotate {
        transform: rotate(-180deg);
        transition: 0.3s;
      }
    }

    &__field {
      @extend %text-standart;
      background-color: transparent;
      flex-grow: 1;
      padding: 0;
      width: 100%;
      @include sm() {
        font-family: SFProDisplay;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 123.077% */
        letter-spacing: 0.26px;
      }
      &::placeholder {
        @extend %desktop_standart;
        color: $text-gray;
        @include sm() {
          color: #a0a0a0;
          font-family: SFProDisplay;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 123.077% */
          letter-spacing: 0.26px;
        }
      }
    }
    &__field-placeholder {
      @include desktop_standart;
      flex-grow: 1;
      padding: 0;
      color: $text-gray;
      display: flex;
      align-items: center;
    }
    &__block {
      display: flex;
      cursor: pointer;
      padding: 15px 20px 16px;
      border-radius: 10px;
    }

    &__item {
      @extend %text-standart;
      background-color: $bg-light-gray;
      border-top: 1px solid $white;
      cursor: pointer;
      padding: 13px 20px;
      @include sm {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 106.667% */
      }
    }

    &__caption {
      display: flex;
      @extend %text-standart;
      text-align: left;
      margin-bottom: 10px;
      font-weight: 500;
      color: $input-label;
      @include sm {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 106.667% */
      }

      &_request-add {
        color: $input-label;
      }
    }
  }

  &.small-mb {
    .base-dropdown__caption {
      margin-bottom: 10px;
    }
  }
}
.base-dropdown {
  &__disable &__field::placeholder {
    @extend %desktop_standart;
    color: $text-gray;
    @include sm() {
      color: #a0a0a0;
      font-family: SFProDisplay;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
      letter-spacing: 0.26px;
    }
  }
}
</style>
