<template>
  <div class="pass-validation">
    <p class="pass-validation__title">{{ $t("component.validation-pass.title") }}</p>
    <p
      v-if="length.isShow || required.isShow"
      class="pass-validation__item"
      :class="{ error: length.status || required.status }"
    >
      {{ $t("component.validation-pass.length") }}
    </p>
    <p
      v-if="isUpperCase.isShow"
      class="pass-validation__item"
      :class="{ error: isUpperCase.status }"
    >
      {{ $t("component.validation-pass.is-upper-case") }}
    </p>
    <p v-if="latin.isShow" class="pass-validation__item" :class="{ error: latin.status }">
      {{ $t("component.validation-pass.latin") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ValidationPassBlock",
  props: {
    length: {
      type: Object,
      default: function () {
        return {
          status: true,
          isShow: false,
        };
      },
    },
    required: {
      type: Object,
      default: function () {
        return {
          status: true,
          isShow: false,
        };
      },
    },
    isUpperCase: {
      type: Object,
      default: function () {
        return {
          status: true,
          isShow: false,
        };
      },
    },
    latin: {
      type: Object,
      default: function () {
        return {
          status: true,
          isShow: false,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pass-validation {
  border: 1px solid $line-light-gray;
  border-radius: 12px;
  padding: 12px 22px;

  p {
    @extend %text-small;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.pass-validation__title {
      padding-left: 0px;
    }
  }

  &__item {
    color: $green;
    position: relative;

    &.error {
      color: $error;
    }

    &:before {
      content: "•";
      margin-right: 7px;
    }
  }
}
</style>
