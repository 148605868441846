<template>
  <div class="work-experience">
    <div v-if="works.length || specialization.length || !isSomeOne" class="title">
      <h2 class="information__header">
        {{ title }}
        <p v-if="isError" class="input-error">{{ errorString }}</p>
      </h2>
      <add-button
        v-if="(works.length || specialization.length) && !isSomeOne"
        class="desktop button--big-left"
        @click.native="openWorkModal"
        >Добавить</add-button
      >
    </div>
    <div class="body">
      <info-box-add
        v-if="!works.length && !specialization.length && !isSomeOne"
        icon="aim"
        class="btn-width"
      >
        <template v-slot:title> Добавьте опыт работы и укажите специализацию </template>
        <template v-if="isCheck" v-slot:necessity-small> (необязательно) </template>
        <template v-slot:description>
          Специализация и опыт работы характеризуют вас как профессионала и являются
          важными критериями выбора для заказчика
        </template>
        <template v-slot:action>
          <base-button class="action-info" @click="openWorkModal"
            >Добавить опыт работы</base-button
          >
        </template>
        <template v-if="isCheck" v-slot:necessity>необязательно</template>
      </info-box-add>

      <div class="work-experience__list" v-if="works.length">
        <work-experience-item
          v-for="item in works"
          :key="item.company"
          :title="item.company"
          :date-from="item.work_start"
          :status="item.status"
          :date-to="item.work_finish"
        >
          <template v-slot:action v-if="!isSomeOne">
            <svg-icon
              width="18"
              @click.stop="changeWork(item)"
              name="mm-project-d"
            ></svg-icon>
            <svg-icon
              width="18"
              @click.stop="dell(item)"
              name="close-mobile-menu"
            ></svg-icon>
          </template>
        </work-experience-item>
      </div>
      <block-list
        :items="paginatedSpecialization"
        @action="openRecommendationFirst"
        :action-button="isAction"
        :check-button="isCheck"
        @check="getChecked"
      ></block-list>

      <add-button
        v-if="(works.length || specialization.length) && !isSomeOne"
        class="mobile button--big-left"
        @click.native="openWorkModal"
        >Добавить</add-button
      >

      <alert-modal ref="dell">
        <template v-slot:button>
          <base-button
            @click="() => $refs.dell.closeModal()"
            class="button--light modal__btn"
            >Отмена</base-button
          >
        </template>
      </alert-modal>
      <alert-modal ref="alert"></alert-modal>
      <add-work-modal
        ref="work-modal"
        @close="hideAddWorkModal"
        @add-work="addWorkItem"
      />
    </div>
  </div>
</template>

<script>
import InfoBoxAdd from "@/components/ui/InfoBoxAdd";
import BaseButton from "@/components/base/BaseButton";
import AddButton from "@/components/ui/button/AddButton";
import WorkExperienceItem from "@/components/ui/WorkExperienceItem";
import SvgIcon from "@/components/base/SvgIcon";
import AlertModal from "@/components/modals/AlertModal";
import { mapGetters } from "vuex";
import ExperienceBlock from "@/components/ui/ExperienceBlock";
import WorkBlock from "@/components/ui/WorkBlock";
import AddWorkModal from "@/components/modals/AddWorkModal";
import RequestRecommendationFirst from "../modals/RequestRecommendationFirst";
import BlockList from "@/components/component/BlockList";
export default {
  name: "WorkExperienceList",
  components: {
    AddWorkModal,
    ExperienceBlock,
    WorkBlock,
    AlertModal,
    SvgIcon,
    AddButton,
    BaseButton,
    InfoBoxAdd,
    WorkExperienceItem,
    RequestRecommendationFirst,
    BlockList,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    works: {
      type: Array,
      default: [],
    },
    specialization: {
      type: Array,
      default: [],
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOptions: [],
      addSpecializationList: [],
      checked_ids: [],
      isCheck: false,
      isAction: false,
    };
  },

  mounted() {
    if (this.$route.name == "ResponseRequestPage") {
      this.isCheck = true;
    }
    if (this.$route.path == "/profile/information") {
      this.isAction = true;
    }
  },
  computed: {
    ...mapGetters({
      isAuth: "auth/isAuth",
      workItem: "profile/workChangeItem",
    }),
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
    paginatedSpecialization() {
      let spec = this.groupByTribeSecondElement(this.specialization);
      spec = this.sortItemsByWilsonScore(spec);
      return spec;
    },
    isSomeOne() {
      return this.$route.path.endsWith("expert") || this.$route.name == "RequestListPage";
    },
    errorString() {
      console.log("this.error");
      console.log(this.error);

      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(", ");
    },
  },
  methods: {
    openRecommendationFirst(v) {
      this.$emit("openRecommendationFirst", {
        idx: v["idx"],
      });
    },
    removeDuplicates(array) {
      return Array.from(new Set(array));
    },
    getChecked(v) {
      if (this.paginatedSpecialization) {
        let id = this.paginatedSpecialization[v["idx"]].option_id[0];
        this.checked_ids = this.checked_ids.filter((el) => el != id);
        if (v["select"]) this.checked_ids.push(id);
        this.$emit("update:modelValue", this.checked_ids);
      }
    },
    groupByTribeSecondElement(objects) {
      console.log(objects);
      //ВОТ ЭТО НАДО УНЕСТИ НА БЭК
      let counter = 0;
      const groupedObj = objects.reduce((acc, obj) => {
        const [, secondPart] = obj.tribe.split(" - ");
        if (!acc[secondPart]) {
          acc[secondPart] = {
            name: secondPart,
            tribe: [],
            //  users: [],
            companies: [],
            roles: [],
            years: [],
            rating_count: 0,
            r_counter: 0,
            rating: 0,
            industry_id: [],
            option_id: [],
            history: [],
            base_indeces: [], //Индексы исходного массива для рекомендаций
          };
        }
        const group = acc[secondPart];
        group.tribe.push(obj.tribe.split(" - ")[0]);
        //group.users.push(...obj.users);
        group.companies.push(...obj.companies);
        group.roles.push(...obj.roles.filter((role) => role));
        group.years.push(obj.years);
        group.r_counter += obj.rating ? 1 : 0;
        group.rating += obj.rating;
        group.rating_count += obj.rating_count;
        group.industry_id.push(obj.industry_id);
        group.option_id.push(obj.option_id);
        group.history.push(...obj.history);
        group.base_indeces.push(counter);
        counter += 1;
        return acc;
      }, {});

      let result = Object.values(groupedObj).map((group) => ({
        ...group,
        tribe: this.removeDuplicates(group.tribe),
        users: this.removeDuplicates(group.users),
        companies: this.removeDuplicates(group.companies.map((item) => item.name)),
        roles: this.removeDuplicates(group.roles.map((item) => item.name)),
        years: group.years.reduce((a, b) => a + b, 0) | 0,
        rating: group.rating ? Math.round(group.rating / group.r_counter) : group.rating,
        n_rating: group.rating_count,
        industry_id: this.removeDuplicates(group.industry_id),
        option_id: this.removeDuplicates(group.option_id),
        history: this.removeDuplicates(group.history),
      }));
      return result;
    },
    wilsonScore(meanRating, numOfRatings, z = 1.96) {
      if (numOfRatings === 0) {
        return 0;
      }
      const phat = meanRating / 5;
      const denominator = 1 + (z * z) / numOfRatings;
      const score =
        (phat +
          (z * z) / (2 * numOfRatings) -
          z *
            Math.sqrt(
              (phat * (1 - phat) + (z * z) / (4 * numOfRatings)) / numOfRatings
            )) /
        denominator;

      return score;
    },
    sortItemsByWilsonScore(items) {
      return items.sort((a, b) => {
        const aScore = this.wilsonScore(a.rating, a.n_rating);
        const bScore = this.wilsonScore(b.rating, b.n_rating);
        return bScore - aScore;
      });
    },

    dell(item) {
      this.$refs.dell.show(
        "Удалить место работы",
        `Вы уверены, что хотите удалить  место работы (${item.company})?`,
        {
          type: "dell",
          nameButton: "Удалить",
          error: true,
        },
        () => {
          this.dellWorkItem(item.id);
          this.$emit("update");
        }
      );
    },
    async getSpecialization() {
      try {
        await this.$store.dispatch("service/getSpecialization");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async dellWorkItem(id) {
      try {
        await this.$store.dispatch("service/dellWorkItem", id);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },

    openWorkModal(e) {
      this.$refs["work-modal"].showModal(e);
    },
    hideAddWorkModal() {
      this.$refs["work-modal"].hideModal();
    },
    async addWorkItem(e) {
      this.$refs["work-modal"].hideModal();
      await this.$store.dispatch("profile/sendCompetence", { works: [e] });
      await this.getSpecialization();
      this.$emit("update");
      this.currentWorkPage = 1;
    },

    async changeWork(info) {
      try {
        await this.$store.dispatch("profile/getItemWork", info.id);

        this.$refs["work-modal"].showModal(this.workItem);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.information__header {
  @include desktop_h2_norm;
  @include sm {
    width: 90%;
    margin-bottom: 0px;
  }
}
.work-experience {
  &__list {
    margin-top: 0px;
    margin-bottom: 44px;

    @include sm() {
      margin-bottom: 16px;
    }
  }
}

.btn-width {
  .button.button--default {
    width: 270px;

    @include md() {
      width: auto;
    }
  }
}

.button--light:hover {
  background-color: $bg-light-gray;
}

.button--light {
  background-color: transparent;
}

.modal__btn {
  margin-left: 16px;
}

.action-info {
  width: auto;

  @include sm {
    min-width: 256px;
    max-width: 256px;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  @include sm {
    display: grid;
  }
}
.description {
  @extend %desktop_additional;
  text-align: left;
  margin-bottom: 30px;
  width: 50%;

  @include md {
    margin-bottom: 24px;
  }

  color: $input-label;

  @include sm {
    margin-bottom: 30px;
    width: 100%;
  }
}

.body {
  display: grid;
  grid-template-columns: 1fr;
}

.desktop {
  display: block;
  width: auto;
  @include sm {
    display: none;
  }
}

.mobile {
  display: none;
  @include sm {
    margin-top: 24px;
    display: block;
  }
}
.input-error {
  color: #ef693f;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}
</style>
