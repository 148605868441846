<template>
  <div class="information">
    <container>
      <template v-slot:nav>
        <v-tabs direction="vertical" v-model="tabs">
          <base-button
            light
            small
            round
            width-auto
            
            class="mb-3"
            v-if="!tablet"
            @click="$router.push(`/request-list/${this.request_id}`)"
            ><svg-icon
          class = 'arrow-left-near'
            name='arrow-big-right'
            :height='15'
            :width='15'
        />Вернуться назад</base-button
          >
          <v-tab
            v-for="(item, idx) in tabsList"
            :key="`tabs_${idx}`"
            :href="`#${item.value}`"
            :value="item.value"
            >{{ item.title }}</v-tab
          >
        </v-tabs>
      </template>
      <template v-slot:body>
        <base-button
          light
          small
          round
          width-auto
          icon-postfix="arrow-left-icon"
          icon-postfix-size="15"
          class="mb-3"
          v-if="tablet"
          @click="$router.push(`/request-list/${this.request_id}`)"
          >Вернуться назад</base-button
        >
        <div
          v-if="industries_proj?.length | options_proj?.length"
          id="projects"
          v-intersect="{
            handler: selectProjects,
            options: {
              threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            },
          }"
        >
          <project-expertise-list
            :is-some-one="isSomeOne"
            :industries="industries_proj"
            :options="options_proj"
            @update:industries="(v) => upd_ind(v)"
            @update:options="(v) => upd_opt(v)"
          />
        </div>
        <div
          id="case"
          class="mb-6"
          v-intersect="{
            handler: selectCases,
            options: {
              threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            },
          }"
        >
          <case-list title="Кейсы" :cases="someOneCases" />
        </div>
        <div
          id="experience"
          class="mb-6"
          v-intersect="{
            handler: selectExperience,
            options: {
              threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            },
          }"
        >
          <work-experience-list
            ref="workExperienceList"
            title="Опыт работы и специализация"
            :works="someUser.work"
            :specialization="someUser.abilities"
          />
        </div>

        <div
          id="expertise"
          class="mb-6"
          v-intersect="{
            handler: selectExpertise,
            options: {
              threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            },
          }"
        >
          <competence-list
            ref="competenceList"
            title="Компетенции"
            :competence="userRatedExpertises"
          />
        </div>

        <div
          id="publication"
          v-intersect="{
            handler: selectPublication,
            options: {
              threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            },
          }"
        >
          <publication-block
            v-if="!isSomeOne"
            :is-some-one="isSomeOne"
            ref="publicationBlock"
          />
        </div>
      </template>
    </container>
    <alert-modal ref="alert"> ок </alert-modal>
  </div>
</template>

<script>
import BaseInput from "@/components/base/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import { mapGetters } from "vuex";
import AlertModal from "@/components/modals/AlertModal";
import VerificationModal from "@/components/modals/VerificationModal";
import Container from "@/components/layout/Container";
import useVuelidate from "@vuelidate/core";
import { email, required, maxLength } from "@vuelidate/validators";
import SvgIcon from "@/components/base/SvgIcon";
import WorkExperienceList from "@/components/component/WorkExperienceList";
import CompetenceList from "@/components/component/CompetenceList";
import CaseList from "@/components/component/CaseList";
import ProjectExpertiseList from "@/components/component/ProjectExpertiseList";
import AddButton from "@/components/ui/button/AddButton";
import InfoBox from "@/components/ui/InfoBox";
import PublicationBlock from "@/components/ui/PublicationBlock";
import ValidateCompetenceModal from "@/components/modals/ValidateCompetenceModal";
import WorkerAddedModal from "@/components/modals/WorkerAddedModal";
import ExpertCouncil from "@/components/component/ExpertCouncil";
import RequestRecommendationArrayModal from "@/components/modals/RequestRecommendationArrayModal";
import ExpertContacts from "@/components/component/ExpertContacts";
import RequestRecommendationFirst from "@/components/modals/RequestRecommendationFirst";
import BaseFile from "@/components/base/BaseFile";
import { typeFile } from "@/utils/helper";

export default {
  name: "ProfileInformationSomeonePage",
  components: {
    RequestRecommendationFirst,
    RequestRecommendationArrayModal,
    ExpertCouncil,
    WorkerAddedModal,
    ValidateCompetenceModal,
    PublicationBlock,
    InfoBox,
    AddButton,
    CompetenceList,
    CaseList,
    WorkExperienceList,
    SvgIcon,
    Container,
    VerificationModal,
    AlertModal,
    BaseButton,
    BaseInput,
    BaseFile,
    ExpertContacts,
    ProjectExpertiseList,
  },
  setup: (props) => {
    const v$ = useVuelidate({ $scope: false });
    return {
      v$,
    };
  },
  data() {
    return {
      addSpecializationList: [],
      userInviteEmail: "",
      isOpenModalDesiredClients: false,
      expertise: null,
      validateCompetenceConfirmType: null,
      validateCompetence: false,
      headerRecommendation: [],
      openRecommendation: false,
      inputValidPhone: false,
      socialName: "",
      socialKey: "",
      isSendSocial: false,
      project_industries: [],
      project_options: [],
      file: null,
      resume: "",
      tabsList: [],
      tabs: null,
      userData: {
        industries_proj: [],
        options_proj: [],
      },
      socialList: [
        {
          name: "Подключить Вконтакте",
          key: "vk",
          icon: "socials/vkontakte-social",
        },
        {
          name: "Подключить LinkedIn",
          key: "linkedIn",
          icon: "socials/linkedIn-social",
        },
      ],
      tabsContact: false,
      tabsExperience: false,
      tabsProjects: false,
      tabsCases: false,
      tabsExpertise: false,
      tabsPublication: false,
      tabsExpert: false,
      isOpenRecommendationFirst: false,
      recommendationType: null,
      recommendationId: null,
      recommendationObject: null,
      request_id: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      userInviteLink: "auth/userInviteLink",
      someUser: "profile/someUser",
      services: "service/service",
      expertises: "service/expertises",
      specialization: "service/specialization",
      isMail: "auth/isMail",
      someOneCases: "service/someOneCases",
    }),
    isSomeOne() {
      return true;
    },
    userRatedExpertises() {
      let merged = this.someUser.expertises.expertises.map((itm) => ({
        ...this.expertises.find((item) => item.id === itm.expertise_id && item),
        ...itm,
      }));
      merged = merged.map((v) => ({
        ...v,
        ...{ n_rating: v.rating_count },
        ...{ name: v.expertise_name },
      }));
      console.log("this.expertises");
      console.log(this.expertises);
      return {
        expertises: merged || [],
        institutions: [],
      };
    },
    tablet() {
      return (
        this.$vuetify.display.md || this.$vuetify.display.sm || this.$vuetify.display.xs
      );
    },
  },
  created() {
    this.request_id = +this.$route.hash.substring(1);
    this.getSomeUserInfo();
    this.getServicesExpertises();
    this.getSomeoneCasesList();
    this.tabsList = [
      {
        title: "Опыт работы",
        value: "experience",
      },
      {
        title: "Компетенции",
        value: "expertise",
      },
    ];
  },
  mounted() {
    this.$nextTick(() => {
      this.industries_proj = this.someUser.industries;
      this.options_proj = this.someUser.options;
    });
  },
  methods: {
    validateCompetenceModal(v) {
      this.expertise = v.item;
      this.validateCompetenceConfirmType = v.type;
      this.validateCompetence = true;
    },
    upd_ind(v) {
      this.userData.industries_proj = v;
    },
    upd_opt(v) {
      this.userData.options_proj = v;
    },
    getPolicy(href) {
      return config.VUE_APP_API_UR.slice(0, -1) + href;
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    openRecommendationFirst(v, type, index) {
      this.getServicesExpertises();
      this.isOpenRecommendationFirst = true;
      this.recommendationType = type;
      this.recommendationId = index;
      this.recommendationObject = v;
    },
    downloadResume(path) {
      this.$router.push({ path: path });
    },

    async getSomeUserInfo() {
      try {
        let r = await this.$store.dispatch(
          "profile/getSomeUserInfo",
          this.$route.params.id
        );
        this.$emit("update:industries_proj", r.industries);
        this.$emit("update:options_proj", r.options);
        if (this.someUser.industries.length || this.someUser.options.length) {
          this.tabsList = [
            {
              title: "Проектная экспертиза",
              value: "projects",
            },
            ...this.tabsList,
          ];
        }
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getSomeoneCasesList() {
      try {
        await this.$store.dispatch("service/getSomeoneCasesList", this.$route.params.id);
        if (this.someOneCases.length) {
          this.tabsList = [
            {
              title: "Кейсы",
              value: "case",
            },
            ...this.tabsList,
          ];
        }
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getServicesExpertises() {
      try {
        await this.$store.dispatch("service/getServicesExpertises", { user: this.user });
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    showModalLink(email) {
      this.userInviteEmail = email;
      this.isOpenModalDesiredClients = true;
    },
    openModalRecommendationModal(v) {
      this.headerRecommendation = v.headerRecommendation;
      this.openRecommendation = true;
    },
    validPhone(value) {
      if (value === "") return true;
      return this.inputValidPhone;
    },
    validPhoneEvent(v) {
      this.inputValidPhone = v;
    },
    getCheckPacked(value) {
      if (this.user.socials != null)
        return this.user.socials.filter((v) => v.name === value).length > 0;
      return false;
    },
    socialBody(socialItem) {
      if (this.user.socials == null) return socialItem.name;
      let socials = this.user.socials.filter((v) => v.name === socialItem.key);
      if (socials.length === 0) return socialItem.name;
      else return socials[0].link;
    },
    async getUserData() {
      await this.$store.dispatch("auth/getUserData");
      if (!this.isMail) this.$router.replace({ name: "VerifyMock" });
    },
    showSocialModal(value) {
      this.socialName = value.label;
      this.socialKey = value.name;
      let socials = this.user.socials.filter((v) => v.name === value.name);
      let link = "";
      if (socials.length > 0) link = socials[0].link;
      this.$refs.verification.show({
        nameButton: this.$t("btn.save"),
        value: link,
      });
      this.isSendSocial = true;
    },
    async addOption(name) {
      let response = await this.$store.dispatch("registration/addOptions", {
        name: name,
      });
      return response.id;
    },

    checkType(e) {
      if (e !== null) {
        return typeFile.includes(e.type);
      } else {
        return true;
      }
    },
    checkSize(e) {
      if (e !== null) {
        return e.size < 50 * 1024 * 1024;
      } else {
        return true;
      }
    },
    selectContact(e) {
      if (e) {
        this.tabs = "contact";
      }
      this.tabsContact = e;
    },
    selectExperience(e) {
      if (e && !this.tabsContact) {
        this.tabs = "experience";
      }
      this.tabsExperience = e;
    },

    selectProjects(e) {
      if (e) {
        this.tabs = "projects";
      }
      this.tabsProjects = e;
    },

    selectCases(e) {
      if (e) {
        this.tabs = "case";
      }
      this.tabsCases = e;
    },
    selectExpertise(e) {
      if (e && !this.tabsExperience) {
        this.tabs = "expertise";
      }
      this.tabsExpertise = e;
    },
    selectPublication(e) {
      if (e && !this.tabsExpertise) {
        this.tabs = "publication";
      }
      this.tabsPublication = e;
    },
    selectExpert(e) {
      if (e && !this.tabsPublication) {
        this.tabs = "expert";
      }
      this.tabsExpert = e;
    },
  },
  watch: {
    someUser: {
      deep: true,
      immediate: true,
      handler(val, oldValue) {
        if (val.resume != null) this.resume = val.resume;
        if (val.phone != null) this.userData.phone = val.phone;
        if (val.email != null) this.userData.email = val.email;
        if (val.corp_email != null) this.userData.corp_email = val.corp_email;
        if (val.industries != null) this.industries_proj = val.industries;
        if (val.options != null) this.options_proj = val.options;
      },
    },
  },
  validations() {
    return {
      userData: {
        corp_email: {
          email,
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
          validPhone: this.validPhone,
        },
      },
      file: {
        checkType: this.checkType,
        checkSize: this.checkSize,
        maxLength: maxLength(1),
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.arrow-left-near {
  transform: rotate(-180deg);
  margin-bottom: 5px;
  margin-right: 5px;
}

#fileInput {
  display: none;
}
.resume_label {
  margin-bottom: 0px;
}
.phone-field {
  grid-column: span 2;

  @include sm() {
    grid-column: span 1;
  }
}

.information {
  &__content {
    display: grid;
  }

  &__success {
    min-width: 20px;
    min-height: 20px;
    margin-left: 20px;
  }

  &__send {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__save {
    margin-top: 50px;
  }

  &__resume-btn {
    width: 50%;
    margin-bottom: 12px;
    @include md {
      width: 100%;
    }
  }
  &__social-btn {
    margin-bottom: 12px;
    width: 330px;

    @include md {
      width: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    @include desktop_h1;
  }
}

.profile-col {
  display: flex;
  flex-direction: row;
  gap: 24px;
  row-gap: 0;

  @include sm {
    display: grid;
    grid-template-columns: 1fr;
  }

  .base-input {
    margin-bottom: 24px;
  }

  .secure-block {
    display: none;
  }

  &.secure {
    position: relative;
    border-radius: 10px;
    border: 1px solid $line-light-gray;
    overflow: hidden;

    .secure-block {
      @include desktop_standart;
      display: flex;
      position: absolute;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 30px;
      background-color: #f2f2f266;
      backdrop-filter: blur(10px);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.base-input-md {
  @include md {
    display: none;
  }
}

.small-title {
  @extend %text-standart;
  text-align: left;
  font-weight: 500;
  color: $input-label;
}

h2 {
  margin-bottom: 30px;

  @include md {
    margin-bottom: 24px;
  }
}
.resume {
  @extend %text-standart;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    transition: 0.3s all ease-in;
    color: #4b4c57;
  }
}
.guide {
  display: flex;
  color: $black;
  font-size: 18px;
  margin: 32px 0;
  position: relative;
  padding: 0;
  justify-content: center;
}
.back {
  color: $black;
  font-size: 18px;
  margin-bottom: 20px;
}
.attachment {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;

  &-item {
    background-color: $bg-light-gray;
    padding: 10px 10px;
    color: #000;
    @include mobile_text;
  }
}
</style>
