import ApiUtil from '@/utils/api'

export default {
    createRequest: (data) =>
        ApiUtil({
            url: '/api/demand',
            method: 'post',
            data,
            requiresAuth: true,
            headers: { "Content-Type": "multipart/form-data" },
        },),
    getRequestList: () =>
        ApiUtil({
            url: '/api/demand',
            method: 'get',
            requiresAuth: true,
        },),
    getRequestInfoShort: (id) =>
        ApiUtil({
            url: `/api/demand/short/${id}`,
            method: 'get',
            requiresAuth: true,
        },),
    getRequestInfoContats: (id) =>
        ApiUtil({
            url: `/api/demand/${id}/contacts`,
            method: 'get',
            requiresAuth: true,
        },),
    sendResponse: (data) =>
        ApiUtil({
            url: `/api/respond`,
            method: 'post',
            requiresAuth: true,
            data,
            headers: { "Content-Type": "multipart/form-data" },
        },),
    rejectResponse: (data) =>
        ApiUtil({
            url: `/api/reject-respond`,
            method: 'post',
            requiresAuth: true,
            data,
        },),
    viewRequest: (data) =>
        ApiUtil({
            url: `/api/view-flags`,
            method: 'post',
            requiresAuth: true,
            data,
        },),
    getAllRespond: () =>
        ApiUtil({
            url: `/api/respond`,
            method: 'get',
            requiresAuth: true,
        },),
    getAllDemandsServices: () =>
        ApiUtil({
            url: `/api/demands-services`,
            method: 'get',
            requiresAuth: true,
        },),
    dropIsNewFlagDemandsServices: (id) =>
        ApiUtil({
            url: `/api/view-flags`,
            data: { "model": "demands_services", "ids": [id] },
            method: 'post',
            requiresAuth: true,
        },),
    getRequestManage: (id) =>
        ApiUtil({
            url: `/api/demand/${id}/manage`,
            method: 'get',
            requiresAuth: true,
        },),
    sendRequest: (data) =>
        ApiUtil({
            url: `/api/demand/shortlist`,
            method: 'post',
            requiresAuth: true,
            data
        },),
    sendRestart: (data) =>
        ApiUtil({
            url: `/api/demand/restart`,
            method: 'post',
            requiresAuth: true,
            data
        },),
    sendStop: (data) =>
        ApiUtil({
            url: `/api/demand/stop`,
            method: 'post',
            requiresAuth: true,
            data
        },),
    deleteItem: (id) =>
        ApiUtil({
            url: `/api/demand/${id}`,
            method: 'delete',
            requiresAuth: true,
        },),
    requestPayment: (data) =>
        ApiUtil({
            url: `/api/demand/request-payment`,
            method: 'post',
            requiresAuth: true,
            data
        },),
    getRequestFinishingData: (id) =>
        ApiUtil({
            url: `/api/demand/${id}/finishing`,
            method: 'get',
            requiresAuth: true,
        },),
    rateRequestServiceOrUser: (data) =>
        ApiUtil({
            url: `/api/demand/finishing`,
            method: 'post',
            requiresAuth: true,
            data
        },),
    deleteRequestRating: (data) =>
        ApiUtil({
            url: '/api/rating',
            method: 'delete',
            requiresAuth: true,
            data
        },),
    completeRequest: (data) =>
        ApiUtil({
            url: `/api/demand/complete`,
            method: 'post',
            requiresAuth: true,
            data
        },),
    toFavorite: (data) =>
        ApiUtil({
            url: '/api/demand/favorites',
            method: 'post',
            data,
            requiresAuth: true
        },),
}