<template>
  <label class="checkbox" :class="classes" :style="{
    '--input-radio-text-size': textSize
  }">
    <div class="checkbox__group" :class="{'column-reverse': columnReverse}">
      <input
          @input="(event) => $emit('update:modelValue', event.target.checked)"
          type="checkbox"
          :checked="modelValue"
          :id="fieldId"
          class="checkbox__input"
      />
      <span class="checkbox__decor"></span>
      <div class="checkbox__title_container">
        <p class="checkbox__title">
          <template v-if="title !== ''">
            {{ title }}
          </template>
          <slot v-else/>
        </p>
        <slot name="postfix"></slot>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  computed: {
    classes () {
      return {
        'checkbox__text': this.text,
        'checkbox__full': this.full,
      }
    },
  },
  props: {
    /**
     * Для вывода текста
     */
    text: Boolean,
    /** Во всю длину */
    full: Boolean,
    textSize: {
      type: [String, Number],
      default: 18
    },
    modelValue: false,
    title: {
      type: String,
      default: ''
    },
    fieldId: {
      type: String,
    },
    columnReverse: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: $bg-light-gray;
  padding: 15px;
  border-radius: 10px;
  &__title_container {
    display: flex;
    align-items: center;
    padding-left: 16px;
  }
  &__text {
    background-color: initial;
    padding: 0;
  }
  &__decor {
    border: 1px solid $line-light-gray;
    background: $white;
  }
  &__title {
    @include desktop_standart;
  }
  input {
    display: none;
  }
  &.error {
    .checkbox__decor,
    .input-radio__decor {
      border: 1px solid $error;
    }
  }
  .column-reverse {
    flex-direction: row-reverse;
  }
  &__decor {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    flex-shrink: 0;
  }
  &__group {
    display: flex;
  }
  & + &__decor {
    background-color: #fff;
  }
  input:checked + &__decor {
    background: $green 60% 55% no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.6799 0.561953C13.1897 0.071797 12.395 0.071797 11.9049 0.561953L5.87016 6.59665L2.14263 2.86911C1.65247 2.37895 0.857772 2.37895 0.367616 2.86911C-0.122539 3.35927 -0.122539 4.15396 0.367617 4.64412L4.97897 9.25548C5.06485 9.34135 5.16007 9.41218 5.26136 9.46797C5.73892 9.73368 6.3531 9.66373 6.75873 9.25811L13.6799 2.33696C14.17 1.84681 14.17 1.05211 13.6799 0.561953Z' fill='white'/%3E%3C/svg%3E");
  }
  &__full &__title_container {
    width: 100%;
    justify-content: space-between;
    padding-left: 0;
  }
  &__full &__group {
    width: 100%;
  }
}
</style>
