import ApiUtil from '@/utils/api'
export default {
  dumpEvent: (data) =>
    ApiUtil(
      {
        url: '/api/client/event',
        method: 'post',
        data,
        requiresAuth: true,
      }
    ),
  getTimes: (data) =>
    ApiUtil(
      {
        url: '/api/client/storage',
        method: 'get',
        requiresAuth: true,
      }
    ),
  setTimes: (data) =>
    ApiUtil(
      {
        url: '/api/client/storage',
        method: 'post',
        data,
        requiresAuth: true,
      }
    )
}
