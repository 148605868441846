<template>
  <p class="base-calendar__title" v-if="title">
    {{ title }}
  </p>

  <div class="base-calendar__block" :class="{ error: isError }">
    <vue-date-picker
      class="dp__theme_light"
      v-model="value"
      :enable-time-picker="false"
      :auto-apply="true"
      :allow-prevent-default="false"
      :format="format"
      locale="ruLocale"
      text-input
      :text-input-options="textInputOptions"
      :min-date="new Date()"
      :placeholder="this.format(new Date())"
    >
      <template #clear-icon="{ clear }">
        <svg-icon name="cross" class="clear-input" @click="clear"></svg-icon>
      </template>
    </vue-date-picker>
  </div>
  <div v-if="isError" class="input-error">{{ errorString }}</div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";
import { format } from "date-fns";

export default {
  name: "BaseCalendar",
  components: { SvgIcon, BaseButton },
  setup: () => ({
    textInputOptions: {
      format: "dd.MM.yyyy",
    },
  }),

  props: {
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  watch: {
    modelValue(v) {
      this.value = v;
    },
    value() {
      this.$emit("update:modelValue", this.format(this.value));
    },
  },
  computed: {
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
  },
  methods: {
    format(date) {
      return format(new Date(date), "dd.MM.yyyy");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

::v-deep .dp__theme_light {
  --dp-primary-color: #7fc666;
}

.dp__theme_light {
  --dp-background-color: $bg-light-gray;
  --dp-font-family: SFProDisplay, Helvetica, Arial, sans-serif;
  --dp-border-color: 0px;
  --dp-border-color-hover: 0px;
  --dp-font-size: 18px;
  --dp-border-radius: 12px;
  --dp-input-padding: 15px 20px 16px 16px;
  --dp-letter-spacing: 0.006em;
  border-radius: 12px;
  width: 100%;
  ::placeholder {
    @extend %desktop_standart;
    color: $text-gray;
    @include sm() {
      color: #a0a0a0;
      font-family: SFProDisplay;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
      letter-spacing: 0.26px;
    }
  }
  @include sm() {
    --dp-font-size: 15px;
  }
}

.clear-input {
  margin-right: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.input-error {
  color: #ef693f;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}

.base-calendar {
  border: 2px solid #eeeeee;
  border-radius: 10px;
  transition: 0.3s;
  --dp-primary-color: #d21957 !important;

  &__block {
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    background-color: $bg-light-gray;

    &.error {
      border: 1px solid #ef693f;
    }
  }

  &__title {
    display: flex;
    @extend %text-standart;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 500;
    color: $input-label;

    @include sm {
      max-width: 200px;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 106.667% */
    }
  }

  &__title-span {
    @include desktop_description;
    margin: 10px;
  }

  &__input {
    display: none;
  }

  &__btn {
    @include mobile_h3;
    color: $accent;

    &-icon {
      flex-shrink: 0;
      margin-left: auto;
    }
  }

  &.is-calendar {
    border-color: transparent;

    .base-calendar {
      &__label {
        padding: 6px 0 6px 0;
      }
    }
  }
}
</style>
