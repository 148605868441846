<template>
  <two-cols-grig is-equal ref="progressSection" class="top">
    <template v-slot:title>
      <div class="title">
        {{ title }}
        <div class="subtitle">{{ subTitle }}</div>
      </div>
      <base-button
        @click="this.$router.push({ name: 'RegistrationPage' })"
        class="button--success but is-mobile"
      >
        Начать работу</base-button
      >
    </template>
    <template v-slot:body>
      <div class="block">
        <div class="point">
          <div class="opacity num" ref="num1">
            1
            <div class="num progress is-mobile">
              <div class="progress-value" ref="progr1"></div>
            </div>
          </div>
          <div class="title opacity" ref="text1">
            <div>{{ points[0].title }}</div>
            <div class="point-text">
              {{ points[0].subtitle }}
            </div>
            <div class="line"></div>
          </div>
        </div>

        <div class="point">
          <div class="num opacity" ref="num2">
            2
            <div class="num progress is-mobile">
              <div class="progress-value" ref="progr2"></div>
            </div>
          </div>
          <div class="title opacity" ref="text2">
            <div>{{ points[1].title }}</div>
            <div class="point-text">
              {{ points[1].subtitle }}
            </div>
            <div class="line"></div>
          </div>
        </div>

        <div class="point" :class="{ last: points.length == 3 }">
          <div class="num opacity" ref="num3">
            3
            <div v-if="points.length == 4" class="num progress is-mobile">
              <div class="progress-value" ref="progr3"></div>
            </div>
          </div>
          <div class="title opacity" ref="text3">
            <div>{{ points[2].title }}</div>
            <div class="point-text">
              {{ points[2].subtitle }}
            </div>
            <div v-if="points.length == 4" class="line"></div>
          </div>
        </div>

        <div
          v-if="points.length == 4"
          class="point"
          :class="{ last: points.length == 4 }"
        >
          <div class="num opacity" ref="num4">{{ points.length }}</div>
          <div class="title opacity" ref="text4">
            <div>{{ points[points.length - 1].title }}</div>
            <div class="point-text">
              {{ points[points.length - 1].subtitle }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </two-cols-grig>
</template>
<script>
import TwoColsGrig from "@/views/startpage/components/TwoColsGrig";
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "ProgressBlock",
  components: {
    TwoColsGrig,
    BaseButton,
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    points: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      top: 0,
      bottom: 0,
      delta: 0,
      scrollPosition: null,
      section_percent: 0,
    };
  },
  mounted() {
    const userComponent = this.$refs.progressSection.$el;
    const rect = userComponent.getBoundingClientRect();
    this.top = rect.top;
    this.bottom = rect.bottom;
    this.delta = this.bottom - this.top;
  },
  created() {
    window.addEventListener("scroll", this.updateScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  computed: {
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
  methods: {
    updateScroll() {
      const num1 = this.$refs.num1;
      const num2 = this.$refs.num2;
      const num3 = this.$refs.num3;

      const text1 = this.$refs.text1;
      const text2 = this.$refs.text2;
      const text3 = this.$refs.text3;

      const progr1 = this.$refs.progr1;
      const progr2 = this.$refs.progr2;

      this.scrollPosition = window.scrollY - this.top + this.delta;
      if ((this.scrollPosition > 0) & (this.scrollPosition < this.delta)) {
        this.section_percent = Math.round((100 * this.scrollPosition) / this.delta);
        if (
          ((this.section_percent >= 70) & this.mobile) |
          ((this.section_percent >= 20) & !this.mobile)
        ) {
          num1.classList.add("num-animation");
          num2.classList.add("num-animation", "second");
          num3.classList.add("num-animation", "third");

          text1.classList.add("text-animation");
          text2.classList.add("text-animation", "second");
          text3.classList.add("text-animation", "third");

          progr1.classList.add("progr-animation");
          progr2.classList.add("progr-animation", "second");

          if (this.points.length == 4) {
            const num4 = this.$refs.num4;
            const text4 = this.$refs.text4;
            const progr3 = this.$refs.progr3;
            num4.classList.add("num-animation", "fourth");
            text4.classList.add("text-animation", "fourth");
            progr3.classList.add("progr-animation", "third");
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.is-mobile {
  display: block;

  @include sm {
    display: none;
  }

  @include md {
    display: none;
  }
}

.top {
  margin-top: 60px;
  @include md {
    margin-top: 30px;
  }
}
.title {
  // padding-top: 60px;
  font-size: 40px;
  font-family: Muller;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: -0.8px;

  color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @include md {
    color: var(--background, #fff);
    /* Desktop/H2 */
    font-family: Muller;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 110% */
  }

  @include md {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
  }
}
.opacity {
  opacity: 0.2;
}

.subtitle {
  font-family: "Muller", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 100%;
  color: #6fb057;
  margin-bottom: 80px;
  @include md {
    /* Desktop/H2 */
    font-family: Muller;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 110% */
    margin-bottom: 0px;
  }
}

.num {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 20%;
  height: 100%;
  font-size: 70px;
  font-family: Muller;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: #e6e6e6;
  z-index: 0;
  @include md {
    font-family: Muller;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 100% */
    width: fit-content;
    padding-right: 15px;
  }
  &.progress {
    width: 3px;
    // margin-bottom: -30px;
    height: 100%;
    margin-bottom: 15px;
    overflow: hidden;
    background-color: #e6e6e6;
    position: relative;
    z-index: 3;
  }
}

.progress-value {
  width: 3px;
  height: 0%;
  background-color: #6fb057;
  position: relative;
  z-index: 3;
}

.but {
  max-width: 285px;
}

.block {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include md {
    padding-bottom: 0px;
    justify-content: start;
  }
}

.point-text {
  font-size: 18px;
  font-family: SFProDisplay, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.18px;
  color: #a0a0a0;
  padding: 10px 0px;
  width: 90%;
  padding-bottom: 30px;
  @include md {
    color: var(--text-gray, #a0a0a0);
    /* Desktop/Additional */
    font-family: SFProDisplay;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    width: 100%;
    padding-bottom: 30px;
    padding-top: 5px;
  }
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 30px;
  @include md {
    border-bottom: 0px solid #ffffff;
    margin-bottom: 0px;
  }
}
.point {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  &.last {
    border-bottom: 0px solid #e6e6e62c;
  }
  @include md {
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: center;
    padding-top: 30px;
    border-bottom: 1px solid #e6e6e62c;
  }
}

@keyframes col {
  from {
    color: #e6e6e6;
    opacity: 0.2;
  }

  to {
    color: #6fb057;
    opacity: 1;
  }
}

@keyframes txt {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@keyframes progr {
  from {
    height: 0%;
  }

  to {
    height: 100%;
  }
}

.num-animation {
  animation-name: col;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0s;

  &.second {
    animation-delay: 1s;
  }

  &.third {
    animation-delay: 2s;
  }

  &.fourth {
    animation-delay: 3s;
  }
}

.text-animation {
  animation-name: txt;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0s;

  &.second {
    animation-delay: 1s;
  }

  &.third {
    animation-delay: 2s;
  }
  &.fourth {
    animation-delay: 3s;
  }
}

.progr-animation {
  animation-name: progr;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0s;

  &.second {
    animation-delay: 1s;
  }
  &.third {
    animation-delay: 2s;
  }
}
</style>
