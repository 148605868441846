<template>
  <container :full-body="isFullBody" :no-nav="isFullBody">
    <template v-slot:nav>
      <div>
        <information-block
          icon="info-box-icon"
          class="light-color"
          text="Присоединятесь к чатам
в Telegram, находите коллег,
и обменивайтесь опытом"
        />
      </div>
    </template>
    <template v-slot:body class="body">
      <template v-if="!isFullBody">
        <h1 class="community-header">
          {{ title }}
          <span v-if="subtitle" class="community-header__subtitle">{{ subtitle }}</span>
        </h1>
        <base-input
          placeholder="Поиск по чатам"
          class="search mb-30"
          v-model="searchText"
          @update:model-value="updateSearch"
          search
        />
        <div>
          <div v-if="communities.data">
            <a
              :href="community.chat_link"
              target="_blank"
              v-for="community in communities.data"
              :key="`community-${community.name}`"
              class="community-item"
              :class="{ disabled: !community['chat_link'] }"
            >
              <info-box
                class="mt-4"
                :class="{ disabled: !community['chat_link'] }"
                :custom-height="true"
                :title="community.name"
                :description="
                  community.chat_name ? community.chat_name : 'Сообщество еще не создано'
                "
                :viewModal="false"
              >
                <template v-slot:icon>
                  <svg-icon name="telegram-icon"></svg-icon>
                </template>
              </info-box>
            </a>
          </div>
          <div v-else>
            <div class="people-search__note-search">
              <div class="icon">
                <svg-icon name="info"></svg-icon>
              </div>
              <div class="text">Ничего не найдено. Попробуйте изменить запрос</div>
            </div>
          </div>
        </div>

        <div v-if="communities.last_page > 1" class="community-pagination">
          <v-pagination v-model="page" :length="communities.last_page"></v-pagination>
        </div>
      </template>
      <template v-else>
        <not-item
          title="Сообщества не найдены"
          description="Для того, чтобы мы подобрали для вас релевантные сообщества, вам необходимо добавить области специализации в профиль"
          btn-name="Перейти в профиль"
          @btnEvent="toProfile"
        ></not-item>
      </template>
    </template>
  </container>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import InfoBox from "@/components/ui/InfoBox";
import BaseInput from "@/components/base/BaseInput";
import { mapGetters } from "vuex";
import Container from "@/components/layout/Container";
import InformationBlock from "@/components/ui/InformationBlock";
import { debounce } from "@/utils/helper";
import NotItem from "@/components/ui/notItem";

export default {
  name: "CommunityBlock",
  components: { NotItem, InformationBlock, Container, BaseInput, InfoBox, SvgIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    searchText: "",
    searchDebounce: null,
    page: 1,
    isCommunities: false,
  }),
  created() {
    this.getCommunities();
    this.searchDebounce = debounce(this.getSearch, 400);
  },
  computed: {
    ...mapGetters({
      communities: "auth/communities",
    }),

    isFullBody() {
      if (
        this.communities?.data == null &&
        !this.isCommunities &&
        this.isCommunities === false
      )
        return true;
      if (
        this.type === "alumni" &&
        this.communities?.data?.length < 1 &&
        this.isCommunities === false
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    page() {
      this.getCommunities();
    },
  },
  methods: {
    async getSearch(e) {
      await this.searchCommunities(this.searchText.toLowerCase());
    },
    updateSearch(e) {
      this.searchDebounce(e);
    },
    async getCommunities(search) {
      try {
        this.$store.dispatch("content/isLoading", true);
        await this.$store.dispatch("auth/communities", {
          type: this.type,
          page: this.page,
          per_page: 5,
          search: search,
        });
        this.$store.dispatch("content/isLoading", false);
        if (this.communities.data.length > 0) {
          this.isCommunities = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async searchCommunities(search) {
      try {
        await this.$store.dispatch("auth/communities", {
          type: this.type,
          page: 1,
          per_page: 5,
          search: search,
        });
      } catch (e) {
        console.log(e);
      }
    },
    toProfile() {
      this.$router.push({ path: "/profile/information" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.community-header {
  margin: 0 0 30px 0;

  &__subtitle {
    color: $text-gray;
  }
}

.community-pagination {
  margin: 60px 0 5px;

  @include md {
    margin-top: 30px;
  }
}

.community-item {
  &.disabled {
    cursor: default;

    svg {
      opacity: 0.6;
    }
  }
}

.info-box {
  svg {
    flex-shrink: 0;
  }
}

.people-search {
  &__note-search {
    display: flex;
    align-items: center;

    @include sm() {
      align-items: flex-start;
    }

    .icon {
      background: $line-light-gray;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      flex-shrink: 0;
    }

    .text {
      @include desktop_h2;
      color: $text-gray;
    }
  }
}
</style>
