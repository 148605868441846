<template>
  <div class="block">
    <div class="chips-group">
      <div v-for="(item, index) in chips" :key="item + index" class="chip">
        {{ item }}
      </div>
    </div>
    <div class="title">{{ task }}</div>

    <div class="action">
      <div class="pair">
        <div class="subtitle">Сроки</div>
        <div>{{ period }}</div>
      </div>
      <div class="pair">
        <div class="subtitle">Бюджет</div>
        <div>{{ payment }}</div>
      </div>
    </div>

    <div class="line">
      <user :name="name" :description="description" :photo="photo" />
    </div>
  </div>
</template>
<script>
import Avatar from "@/components/ui/Avatar";
import BaseButton from "@/components/base/BaseButton";
import User from "@/views/startpage/components/User";
export default {
  name: "TaskCardExpert",
  components: {
    Avatar,
    BaseButton,
    User,
  },
  computed: {},
  props: {
    task: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    isMain: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "",
    },
    period: {
      type: String,
      default: "",
    },
    payment: {
      type: String,
      default: "",
    },
    chips: {
      type: Array,
      default: [],
    },
    photo: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.block {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  padding: 20px;
  background: #f6f6f6;
  border: 1px solid #eee;
  box-shadow: 10px 5px 5px #eee;
  border-radius: 20px;
  flex-direction: column;
  row-gap: 20px;
  position: relative;
  align-content: bottom;

  @include md {
    box-shadow: 0px 0px 0px #eee;
  }
}
.action {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  gap: 1em;
  height: 50px;

  @media (max-width: 1300px) {
    height: 10px;
  }

  @include md {
    gap: 1em;
    font-family: SFProDisplay;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    padding: 10px 0px;
  }

  @include sm {
    display: block;
    flex-direction: column;
    align-items:flex-start;
    min-height:40px;
  }
}

.pair {
  position: relative;
  display: flex;
  gap: 5px;
}

.line {
  position: relative;
  border-top: 0.697px solid #e6e6e6;
}
.title {
  position: relative;
  display: flex;
  height: fit-content;
  font-size: 26px;
  font-family: Muller;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.52px;
  height: 100px;
  @media (max-width: 1300px) {
    height: 90px;
  }

  @include md {
    margin-bottom: 0px;
    font-family: Muller;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    height: 50px;
  }

  @include sm {
    font-size: 16px;
    height: auto;
  }
}
.subtitle {
  color: $text-gray;
}
.chips-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  height: 50px;
  @media screen and (max-width: 1400px) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}

.chip {
  @extend %desktop_additional;
  display: flex;
  font-family: SFProDisplay;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  max-height: 30px;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border-radius: 30px;
  background-color: #ffffff;
  color: #4b4c57;
  flex-wrap: wrap;
  line-height: 10px;
  white-space: nowrap;
}
</style>
