<template>
  <div class="experts">
    <img
      v-for="(img, idx) in images"
      :key="`img-${img}`"
      :class="'avatar' + (!idx ? ' first' : '')"
      :src="$store.getters['auth/assets'] + 'img/' + img"
      alt=""
      loading="lazy"
    />
    <div class="avatar last">
      <table>
        <tbody>
          <tr>
            <td>+1К</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="experts-title">Более 1000 профессионалов</div>
    <div class="experts-subtitle">по 70 направлениям бизнеса</div>
  </div>
</template>
<script>
export default {
  name: "ExpertsShow",
  props: {
    images: {
      type: Array,
      default: [],
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.experts-title {
  padding-top: 16px;
  font-size: 24px;
  font-family: Muller;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: $black;
}

.experts-subtitle {
  @include desktop_standart;
  color: #a0a0a0;
}

.experts {
  text-align: left;
  vertical-align: middle;
  @include md {
    text-align: center;
  }

  @include sm {
    text-align: center;
  }
}

.avatar {
  position: relative;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: -20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: none;
  border: 3px solid #eeeeee;
  object-fit: cover;
  object-position: 0% 0%;

  &.first {
    margin-left: 0px;

    @include sm {
      margin-left: -20px;
    }
  }

  &.last {
    position: absolute;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    background-color: #6fb057;
    color: white;
  }

  table {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-spacing: 0;
  }

  table td {
    text-align: center;
    vertical-align: middle;
  }

  img {
    width: 100%;
    overflow: hidden;
  }
}
</style>
