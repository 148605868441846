<template>
  <div class="top-block">
    <h1 class="top-block__title">Приглашение</h1>
    <div class="top-block__tabs__control">
      <!-- <router-link 
        class="top-block__tabs__control-item card" 
        to="/invite/expert" 
        @click="() => !isExpert && resetForm()"
      >
        Эксперта
      </router-link>
      <router-link 
        class="top-block__tabs__control-item card" 
        to="/invite/company"
        @click="() => !isCompany && resetForm()"
      >
        Компанию
      </router-link> -->
    </div>
  </div>
  <container>
    <template v-slot:nav>
      <div class="info">
        <svg-icon name="invite-info" width="48" height="48" />
        <p class="info__text">
          Приглашайте экспертов
          <br />и компании развивать <br />бизнес вместе с нами
        </p>
      </div>
    </template>
    <template v-slot:body>
      <h1 v-if="isExpert" class="invite-form-title">Пригласить эксперта</h1>
      <h1 v-if="isCompany" class="invite-form-title">Пригласить компанию</h1>
      <form class="invite-form" @submit.prevent="submit">
        <base-input
          v-model="email"
          :class="{ 'mb-24': !expertEmailError }"
          description="Электронная почта"
          :placeholder="$t('pages.register.email-placeholder')"
          :is-error="v$.email.$error"
          :error="[
            {
              text: $t('pages.register.email-validate'),
              status: v$.email.required.$invalid,
            },
            {
              text: $t('validate.email'),
              status: v$.email.email.$invalid,
            },
          ]"
          @update:model-value="updateSearchUser"
        />
        <div
          v-if="!isExpertEmailChecked && !!expertEmailError"
          class="invite-form__expert-email-error mb-24"
        >
          Почта привязана к существующему аккаунту — {{ expertEmailError }}
        </div>
        <div v-if="isExpertEmailChecked || isCompany">
          <base-input
            v-model="phone"
            class="mb-24"
            description="Номер телефона"
            @validPhone="validPhoneEvent"
            type="phone"
            :is-error="v$.phone.$error"
            :error="[
              {
                text: $t('pages.register.validate.phone.valid-phone'),
                status: v$.phone.validPhone.$invalid,
              },
            ]"
          />
          <vue-single-select-rows
            v-if="isCompany"
            :new-value="true"
            header="Выбрать компанию"
            subtitle="Выберите компанию, в которой работали, или добавьте новую, если не найдете свою в списке. Она отправится на модерацию."
            description="Компания"
            placeholder="Найти компанию"
            class="mb-24"
            :items="companiesList"
            v-model="companies"
            :searchEvent="searchCompany"
            @updateSearch="updateSearchCompany"
            searchEmptyText="Добавить новую компанию"
            :is-error="v$.companies.$error"
            :error="[
              {
                text: 'Выберите компанию',
                status: v$.companies.required.$invalid,
              },
            ]"
          />
          <base-input
            v-model="lastName"
            class="mb-24"
            description="Фамилия"
            placeholder="Укажите фамилию"
            :is-error="v$.lastName.$error"
            :error="[
              {
                text: $t('validate.required-2', { name: $t('name.last-name-u') }),
                status: v$.lastName.required.$invalid,
              },
              {
                text: $t('validate.valid-name'),
                status: v$.lastName.validName.$invalid,
              },
            ]"
          />
          <base-input
            v-model="name"
            class="mb-24"
            description="Имя"
            placeholder="Укажите имя"
            :is-error="v$.name.$error"
            :error="[
              {
                text: $t('pages.register.validate.name.required'),
                status: v$.name.required.$invalid,
              },
              {
                text: $t('validate.valid-name'),
                status: v$.name.validName.$invalid,
              },
            ]"
          />
          <base-input
            v-model="patronymic"
            class="mb-24"
            description="Отчество"
            placeholder="Укажите отчество"
            :is-error="v$.patronymic.$error"
            :error="[
              {
                text: $t('validate.valid-name'),
                status: v$.patronymic.validName.$invalid,
              },
            ]"
          />
        </div>
        <base-button
          class="invite-form__submit"
          type="submit"
          :disabled="!isExpertEmailChecked && !!expertEmailError"
        >
          {{ isExpertEmailChecked || isCompany ? "Отправить приглашение" : "Далее" }}
        </base-button>
      </form>
    </template>
  </container>
  <alert-modal ref="alert">
    <div @click="goHome">Вернуться на главную</div>
  </alert-modal>
  <worker-added-modal
    header="Приглашение отправлено"
    :subtitle="`Мы успешно отправили ссылку для регистрации на почту ${userInviteEmail}.`"
    :is-open="isUserInviteSuccess"
    @close="isUserInviteSuccess = false"
    :link="userInviteLink"
    class="worked-add-modal"
    btn-text="Хорошо"
  ></worker-added-modal>
</template>

<script>
import Container from "@/components/layout/Container";
import BaseInput from "@/components/base/BaseInput";
import BaseDropdown from "@/components/base/BaseDropdown";
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";
import VueSingleSelectRows from "@/components/ui/VueSingleSelectRows";
import AlertModal from "@/components/modals/AlertModal";
import WorkerAddedModal from "@/components/modals/WorkerAddedModal";
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { email, required, maxLength } from "@vuelidate/validators";
import { debounce } from "@/utils/helper";

export default {
  name: "InvitePage",
  components: {
    Container,
    BaseInput,
    BaseDropdown,
    BaseButton,
    SvgIcon,
    VueSingleSelectRows,
    AlertModal,
    WorkerAddedModal,
  },
  data() {
    return {
      isExpertEmailChecked: false,
      inputValidPhone: false,
      email: "",
      phone: "",
      lastName: "",
      name: "",
      patronymic: "",
      searchDebounceCompany: null,
      searchDebounceUser: null,
      companies: [],
      expertEmailError: "",
      isUserInviteSuccess: false,
      userInviteEmail: "",
    };
  },
  created() {
    this.isExpertEmailChecked = false;
    this.expertEmailError = "";
    this.getDirectoryLists();
    this.searchDebounceCompany = debounce(this.getCompany, 400);
    this.searchDebounceUser = debounce(this.searchUserEmail, 400);
  },
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    ...mapGetters({
      isAuth: "auth/isAuth",
      companiesList: "company/companies",
      userSearch: "auth/userSearch",
      user: "auth/user",
      userInviteLink: "auth/userInviteLink",
    }),
    isExpert() {
      return this.$route.name === "InvitePageExpert";
    },
    isCompany() {
      return this.$route.name === "InvitePageCompany";
    },
  },
  watch: {
    userSearch(val) {
      if (val?.length) {
        this.expertEmailError = val[0].name;
      } else if (this.email === this.user.email) {
        this.expertEmailError = `${this.user.name} ${this.user.lastName[0]}.`;
      } else {
        this.expertEmailError = "";
      }
    },
  },
  methods: {
    getFormData() {
      const formData = {
        expert_type: this.isExpert ? "individual" : "company",
        name: this.name,
        lastName: this.lastName,
        email: this.email,
      };

      if (this.phone.length > 2) {
        formData.phone = this.phone;
      }

      if (this.patronymic.length > 2) {
        formData.patronymic = this.patronymic;
      }

      if (this.isCompany && this.companies?.length) {
        if (!this.companies[0]?.newValue) {
          formData.company_id = this.companies[0].id;
        } else {
          formData.new_companies = [{ name: this.companies[0].name }];
        }
      }

      return formData;
    },
    async submit() {
      if (!this.isExpertEmailChecked && !this.isCompany) {
        const result = await this.v$.$validate();
        if (!result) {
          return;
        }

        this.isExpertEmailChecked = true;
      } else {
        const result = await this.v$.$validate();
        if (!result) {
          return;
        }
        try {
          await this.$store.dispatch("auth/postUserInvite", this.getFormData());

          this.userInviteEmail = this.email;
          this.resetForm();
          window.scrollTo({ top: 0 });

          this.isUserInviteSuccess = true;
        } catch (e) {
          this.$refs.alert.show(this.$t("alert.error"), e, {
            type: "error",
            nameButton: "Ок",
          });
        }
      }
    },
    async getIndustries() {
      await this.$store.dispatch("registration/getIndustries");
    },
    async getOptions() {
      await this.$store.dispatch("registration/getOptions");
    },
    async getCompany(val) {
      await this.$store.dispatch("company/getCompany", val);
    },
    async searchUserEmail(val) {
      await this.$store.dispatch("auth/getUserSearchEmail", val);
    },
    async searchCompany(val) {
      await this.$store.dispatch("company/getCompany", val);
    },
    getDirectoryLists() {
      if (this.isAuth) {
        this.getIndustries();
        this.getOptions();
        this.getCompany();
      }
    },
    updateSearchUser(e) {
      if (e.length > 1 && !this.isExpertEmailChecked && this.isExpert) {
        this.searchDebounceUser(e);
      }
    },
    updateSearchCompany(e) {
      this.searchDebounceCompany(e);
    },
    resetForm() {
      this.v$.$reset();

      this.isExpertEmailChecked = false;
      this.email = "";
      this.phone = "";
      this.lastName = "";
      this.name = "";
      this.patronymic = "";
      this.expertEmailError = "";
      this.companies = [];
    },
    goHome() {
      this.$router.push({ name: "StartPage" });
    },
    validPhoneEvent(v) {
      this.inputValidPhone = v;
    },
    validName(value) {
      if (value === "") return true;
      return /^[а-яёА-ЯЁa-zA-Z\-]+$/.test(value);
    },
    validPhone(value) {
      if (value === "") return true;
      return this.inputValidPhone;
    },
  },
  validations() {
    if (!this.isExpertEmailChecked && this.isExpert) {
      return {
        email: {
          required,
          email,
        },
      };
    } else if (this.isExpert) {
      return {
        email: {
          required,
          email,
        },
        phone: {
          required,
          validPhone: this.validPhone,
        },
        lastName: {
          required,
          validName: this.validName,
          maxLength: maxLength(30),
        },
        name: {
          required,
          validName: this.validName,
          maxLength: maxLength(30),
        },
        patronymic: {
          validName: this.validName,
          maxLength: maxLength(30),
        },
      };
    } else if (this.isCompany) {
      return {
        email: {
          required,
          email,
        },
        phone: {
          required,
          validPhone: this.validPhone,
        },
        lastName: {
          required,
          validName: this.validName,
          maxLength: maxLength(30),
        },
        name: {
          required,
          validName: this.validName,
          maxLength: maxLength(30),
        },
        patronymic: {
          validName: this.validName,
          maxLength: maxLength(30),
        },
        companies: {
          required,
        },
      };
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.invite-form {
  &__submit {
    max-width: 328px;
    margin-bottom: 20px;
    margin-top: 16px;

    @include md {
      margin-bottom: 0px;
    }
  }

  &__expert-email-error {
    color: $error;
  }
}

.invite-form-title {
  margin-bottom: 30px;

  @include sm {
    margin-bottom: 24px;
  }
}

.info {
  width: 100%;
  height: 169px;
  border: 1px solid $line-light-gray;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  &__text {
    color: $accent;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.006em;
  }
}

.top-block {
  max-width: 1400px;
  padding-left: 40px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;

  @include md {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include sm {
    margin-bottom: 20px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.top-block__title {
  margin-bottom: 40px;

  @include md {
    margin-left: 16px;
  }

  @include sm {
    text-align: center;
  }
}

.top-block__tabs {
  &__control {
    &-item {
      @extend %text-standart;
      padding-bottom: 11px;
      margin-right: 24px;
      border-radius: 0;
      border-bottom: 2px solid transparent;
      color: $text-gray;
      &:hover {
        color: $text-gray;
      }
      &.router-link-active {
        border-color: $accent;
        color: $black;
        @include md {
          @include desktop_standart;
          padding: 10px 20px;
          margin-right: 20px;
          border-radius: 24px;
          border-color: initial;
          border-bottom: none;
          background: $white;
        }
      }
    }
  }
}
</style>
