import ApiUtil from '@/utils/api'

export default {
  getQuestions: () =>
    ApiUtil(
      {
        url: '/api/questions',
        method: 'get',
      },
    ),
}