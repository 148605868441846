<template>
  <base-modal :is-open="isOpen" @close="closeModal" ref="modal" size="550">
    <div class="desired-clients">
      <h2 class="desired-clients__header">{{ header }}</h2>
      <div v-if="subtitle !== ''" class="subtitle">
        <span> {{ subtitle }}</span>
      </div>
      <div class="body">
        <base-input :placeholder="placeholder" class="desired-clients__input" v-model="searchText"
          @update:model-value="updateSearch" search />
        <div class="desired-clients__scroll" v-if="!isScroll">
          <perfect-scrollbar>
            <div class="list">
              <base-radio v-for="(option) in listAll()" :model-value="option.value"
                @update:model-value="(v) => select(option)" :title="option.name" name="company" :status="option.status"
                :type="multi ? 'checkbox' : 'radio'" />

            </div>
          </perfect-scrollbar>
        </div>
        <div class="desired-clients__scroll" v-else>
          <DynamicScroller :items="listAll()" :min-item-size="44" class="scroller" key-field="name">
            <template v-slot="{ item, index, active }">
              <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[
                item.message,
              ]" :data-index="index" class="dynamic-item" :key="item.id">
                <base-radio :model-value="item.value" @update:model-value="(v) => select(item)" :title="item.name"
                  name="company" :status="item.status" :type="multi ? 'checkbox' : 'radio'" :is-service="isService" />
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
        </div>
      </div>
      <div v-if="isNewValue()" class="item-add-custom">
        <base-button v-if="newValue" small class="invite-button button--light" type="link" @click="addValueSubmit()">{{ searchEmptyText }}
          «{{ this.searchText }}»</base-button>
          <p v-if="!newValue" >{{ searchEmptyText }}</p>
      </div>
      <base-button type="button" :disabled="isNotValue()" @click="submit()">{{ getNameBtn() }}</base-button>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseInput from "@/components/base/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import VueMultiSelectRows from "@/components/ui/VueMultiSelectRows";
import Checkbox from "@/components/base/VueTelInput/Checkbox";
import BaseRadioGroup from "@/components/base/BaseRadioGroup";
import BaseRadio from "@/components/base/BaseRadio";
import useSelectController from "@/hooks/useSelectController";

export default {
  name: "DesiredSingleClientsModal",
  components: {
    BaseRadio,
    BaseRadioGroup,
    Checkbox,
    BaseButton,
    BaseInput,
    BaseModal,
    VueMultiSelectRows
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    isService: {
      type: Boolean,
      default: false
    },
    searchEvent: {
      type: Function,
      default() {
        return () => { };
      }
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      }
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Дополнительный контент, отображаемый под заголовком
     */
    subtitle: {
      type: String,
      default: ''
    },
    /**
     * Создать новую запись
     */
    newValue: {
      type: Boolean,
      default: false
    },
    /**
     * Текст заголовка
     */
    header: {
      type: String,
      default: ''
    },
    isScroll: {
      type: Boolean,
      default: false,
    },
    /**
     * Текст заголовка
     */
    placeholder: {
      type: String,
      default: ''
    },
    getOptionDescription: {
      type: Function,
      default(option) {
        return option['name'];
      }
    },
    searchEmptyText: {
      type: String,
      default: ''
    },
    multi: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      addSearchEvent,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect
    } = useSelectController({ selectOne: !props.multi, newValue: props.newValue });
    addSearchEvent(props.searchEvent)
    return {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect
    }
  },
  data() {
    return {
      autoIncrement: 1,
      nameButton: '',
      verificationCode: '',
      searchText: '',
      radioItem: false,
      currentValue: null,
    }
  },
  methods: {
    getNameBtn() {
      if (!this.newValue)
        return 'Сохранить'
      return !this.isSelectValue() && this.isNewValue() ? 'Добавить' : 'Сохранить'
    },
    isNotValue() {
      if (this.listAll().filter(item => item.name.toLowerCase() == this.searchText.trim().toLowerCase()).length && !this.isSelectValue()) {
        return true
      }
      if (this.newValue)
        return (this.searchText == null || this.searchText === '') && !this.isSelectValue();
      return !this.isSelectValue();
    },
    select(v) {
      if (this.isService) {
        console.log(this.modelValue, v);
        if (v.value) {
          this.removeItem([v])
        }
        else {
          this.selectValue([v])
          this.$emit('update:modelValue', [v])
        }
      }
      else {
        this.multi ? this.selectValue([v]) : this.selectValue(v)
        this.$emit('update:modelValue', [v])
      }
    },
    addValueSubmit() {
      if (this.isNotValue())
        return;
      let submitSelect = this.submitSelect();
      if (submitSelect != -1) {
        this.$emit('update:modelValue', submitSelect)
        this.closeModal();
      }
    },
    submit() {
      if (this.isNotValue())
        return;
      if (!this.isSelectValue()) {
        let submitSelect = this.submitSelect();
        if (submitSelect != -1) {
          this.$emit('update:modelValue', submitSelect)
          this.closeModal();
        }
      }
      else {
        if (!this.multi) {
          this.closeModal();
        }
        else {
          this.searchSelect('');
          let submitSelect = this.submitSelect();
          this.$emit('update:modelValue', submitSelect)
          this.closeModal();
        }
      }
    },
    closeModal() {
      this.$emit('close')
      this.searchText = '';
      this.searchSelect('');
    },
    updateSearch(e) {
      this.$refs.modal.updateSize();
      this.searchSelect(e);
    },
    removeItem(item) {
      this.removeSelect(item)
      let submitSelect = this.submitSelect();
      console.log(submitSelect);
      if (submitSelect != -1) {
        this.$emit('update:modelValue', submitSelect)
      }
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          if (newValue.length > 0)
            this.selectValue(this.multi ? newValue : newValue[0], true);
        }
      }
    },
    items: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          this.addListSelect(JSON.parse(JSON.stringify(newValue)));
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';

.desired-clients {
  &__header {
    @include desktop_h1;
    text-align: left;
  }

  &__input {
    margin-bottom: 24px;
  }

  &__scroll {
    margin: 0 -18px 0 -18px;
    padding-left: 18px;
    height: 50vh;
    overflow-y: auto;
  }

  .dynamic-item {
    padding-bottom: 24px;
  }

  &::v-deep .vue-recycle-scroller::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  &::v-deep .vue-recycle-scroller::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }

  &::v-deep .vue-recycle-scroller::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999;
    padding-left: 4px;
  }

  &::v-deep .vue-recycle-scroller__item-wrapper {
    row-gap: 14px;
    padding-right: 4px;
  }

  &::v-deep .vue-recycle-scroller__item-view {
    padding-right: 4px;
  }
}

.item-dialog {
  width: 100%;

  &__postfix {
    color: $text-gray;
    margin-right: 16px;
  }
}

.item-add-custom {
  margin-top: -14px;
  margin-bottom: 8px;
  color: $text-gray;
}

.list {
  row-gap: 14px;
  padding-right: 18px;
  display: grid;
}

.body {
  margin-top: 24px;
  margin-bottom: 16px;

  .subtitle {
    @include desktop_additional;
    margin-top: 12px;
  }

  &__nav {
    margin-bottom: 30px;
  }

  &__body {
    margin-bottom: 30px;
  }

  &__item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ps {
    max-height: 50vh;
  }
}

.scroller {
  height: 100%;
}

.competence-scroll {
  height: 50vh;
  overflow-y: auto;
}
</style>
