<template>
  <div :class="[classes.wrapper, { 'industry-full': full }]" ref="vuesingleselect">
    <p ref="select" v-if="description" class="description">{{ description }}</p>
    <select multiple class="hidden" :name="name">
      <option
        v-for="(option, idx) in selectedOptions"
        :key="idx"
        :value="getOptionValue(option)"
      >
        {{ getOptionValue(option) }}
      </option>
    </select>
    <div class="relative text-left" :class="[classes.searchWrapper]">
      <div
        class="rounded hover:border-blue bg-white"
        :class="[isRequired]"
        style="border-radius: 12px !important"
      >
        <ul
          class="flex list-reset flex-wrap py-px pb-1 pr-1 m-0 text-black"
          :class="[
            {
              'input-wrapper__error': isError,
            },
            isWhite ? 'white' : 'grey',
          ]"
        >
          <li
            v-for="(option, idx) in selectedOptions"
            :key="idx"
            @click="seedSearchText"
            class="mt-1 ml-1 mb-0 flex justify-between content-center"
          >
            <slot name="pill" v-bind="{ option, idx }">
              <span :class="[classes.pill]">
                <span class="text-sm" v-text="getOptionDescription(option)"></span>
                <span
                  class="pl-2 text-grey-darker mt-px icons"
                  @click.stop="removeOption(option)"
                >
                  <svg
                    class="text-sm w-3 h-3 fill-current"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="21.4258"
                      y="4.09961"
                      width="3.50036"
                      height="24.5025"
                      rx="1.75018"
                      transform="rotate(45 21.4258 4.09961)"
                      fill="#4B4C57"
                    />
                    <rect
                      x="23.8984"
                      y="21.4258"
                      width="3.50036"
                      height="24.5025"
                      rx="1.75018"
                      transform="rotate(135 23.8984 21.4258)"
                      fill="#4B4C57"
                    />
                  </svg>
                </span>
              </span>
            </slot>
          </li>
          <li class="mt-1 ml-1 mb-0 flex-1">
            <div class="registration-form__function" @click="seedSearchText">
              <div
                type="text"
                ref="search"
                style="min-width: 100px"
                class="box-size w-full p-1 inline mr-1 h-full outline-none border-none leading-tight"
                :class="[classes.searchInput]"
              >
                <p v-show="selectedOptions.length <= 0" class="placeholder">
                  {{ placeholder }}
                </p>
              </div>
              <div class="registration-form__function-plus">
                <svg-icon name="plus" />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-click-outside="handleClickOutside"
        style="z-index: 100"
        class="dialog-checkbox"
        v-show="matchingOptions"
      >
        <base-input
          @keyup.enter="setPossibleOption"
          @keyup.down="movePointerDown"
          @keydown.tab.stop="closeOut"
          @keydown.esc.stop="searchText = null"
          @keyup.up="movePointerUp"
          @keyup.delete="popSelectedOption"
          autocomplete="off"
          :placeholder="placeholder"
          :required="required"
          v-model="searchText"
        ></base-input>
        <div v-if="newValue" class="dialog-checkbox__item-add-custom">
          <p>
            {{ $t("component.industry.add-sub-function-text") }}
            <span>{{ $t("component.industry.add-sub-function-new") }}</span>
          </p>
        </div>
        <div class="dialog-checkbox__scroll">
          <perfect-scrollbar>
            <div class="dialog-checkbox__list">
              <checkbox
                text
                class="dialog-checkbox__item-dialog"
                v-for="(option, idx) in matchingOptions"
                :key="option.autoIncrement"
                @update:model-value="(v) => setCheckbox(v, option)"
                :model-value="option.value"
              >
                <slot name="option" v-bind="{ option, idx }">
                  {{ getOptionDescription(option) }}
                </slot>
              </checkbox>
            </div>
          </perfect-scrollbar>
        </div>
        <base-button
          v-if="newValue"
          v-show="checkCountPossibleOption"
          class="dialog-checkbox__button"
          @click="addNewItem"
          >{{ $t("btn.add") }}</base-button
        >
      </div>
    </div>
    <div v-if="isError" class="input-error">{{ errorString }}</div>
    <div v-if="subDescription && !isError" class="sub-description">
      {{ subDescription }}
    </div>
  </div>
</template>
<script>
import SvgIcon from "@/components/base/SvgIcon";
import BaseMultiCheckbox from "@/components/base/VueTelInput/BaseMultiCheckbox";
import BaseInput from "@/components/base/BaseInput";
import Checkbox from "@/components/base/VueTelInput/Checkbox";
import BaseButton from "@/components/base/BaseButton";
export default {
  components: { BaseButton, Checkbox, BaseInput, BaseMultiCheckbox, SvgIcon },
  mounted() {
    document.addEventListener("keyup", this.handleClickOutside);
    this.searchText = this.initial;
  },
  destroyed() {
    document.removeEventListener("keyup", this.handleClickOutside);
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          this.dataOptions = newValue.map((v) => {
            return Object.assign(
              {
                autoIncrement: this.autoIncrement++,
                value: false,
                newValue: false,
              },
              v
            );
          });
        } else this.dataOptions = [];
      },
    },
    searchText(curr, prev) {
      if (curr === prev) {
        return;
      }
      this.pointer = -1;
    },
    selectedOptions: {
      handler: function (val, oldVal) {},
      deep: true,
      immediate: true,
    },
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          newValue.forEach((v) => {
            let index = this.dataOptions.findIndex((dataValue) => dataValue.id === v.id);
            if (index !== -1) {
              this.dataOptions[index].value = true;
            }
          });
        }
      },
    },
  },
  data() {
    return {
      autoIncrement: 1,
      selectList: [],
      searchText: null,
      selectedOption: null,
      dropdownOpen: false,
      closed: false,
      dataOptions: [],
    };
  },
  props: {
    modelValue: {},
    isError: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    description: {
      type: String,
      default: "",
    },
    subDescription: {
      type: String,
    },
    full: Boolean,
    // Use classes to override the look and feel
    // Provide these 7 classes.
    classes: {
      type: Object,
      required: false,
      default: () => {
        return {
          active: "active",
          wrapper: "multi-select-wrapper",
          searchWrapper: "search-wrapper",
          searchInput: "search-input",
          pill: "pill",
          required: "required",
          dropdown: "dropdown",
        };
      },
    },
    // Give your input a name
    // Good for posting forms
    name: {
      type: String,
      required: false,
      default: () => "",
    },
    // Your list of things for the select
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    /**
     * Добавления нового значения если не удалось найти в поиске
     */
    newValue: {
      type: Boolean,
      default: false,
    },
    /**
     * Подставления по умалчанию если не обно значения не выбранно то выбранны все
     */
    notValueToFullSelect: {
      type: Boolean,
      default: false,
    },
    /**
     * Значения для Label
     */
    optionLabel: {
      type: String,
      required: false,
      default: () => null,
    },
    /**
     * Значения для ключа
     */
    optionKey: {
      type: String,
      required: false,
      default: () => null,
    },
    // Give your input an html element id
    placeholder: {
      type: String,
      required: false,
      default: () => "Search Here",
    },
    maxHeight: {
      type: String,
      default: () => "220px",
      required: false,
    },
    //Give the input an id
    inputId: {
      type: String,
      default: () => "multi-select",
      required: false,
    },
    // Seed search text with initial value
    initial: {
      type: String,
      required: false,
      default: () => null,
    },
    // Make it required
    required: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    // Max number of results to show.
    maxResults: {
      type: Number,
      required: false,
      default: () => 100,
    },
    //Meh
    tabindex: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
    // Remove previously selected options
    // via the delete key
    keyboardDelete: {
      type: Boolean,
      required: false,
      default: () => {
        return true;
      },
    },
    // Tell vue-single-select what to display
    // as the selected option
    getOptionDescription: {
      type: Function,
      default(option) {
        if (this.optionLabel) {
          return option[this.optionLabel];
        }
        if (this.optionKey) {
          return option[this.optionKey];
        }
        return option;
      },
    },
    // Use this to actually give vue-single-select
    // a value for doing a POST
    getOptionValue: {
      type: Function,
      default(option) {
        if (this.optionKey) {
          return option[this.optionKey];
        }

        if (this.optionLabel) {
          return option[this.optionLabel];
        }

        return option;
      },
    },
  },
  methods: {
    focus() {
      let element = this.$refs.select;
      let top = element.offsetTop;
      window.scrollTo(0, top - 20);
    },
    setCheckbox(v, option) {
      let list = this.dataOptions;
      list[list.indexOf(option)].value = v;
      this.matchingOptions = list;
    },
    setData() {
      let option = [];
      if (this.selectedOptions != null && this.selectedOptions.length > 0) {
        if (this.optionLabel && this.optionKey) {
          option = this.selectedOptions.map((v) => {
            return {
              [this.optionLabel]: v[this.optionLabel],
              [this.optionKey]: v[this.optionKey],
              newValue: v.newValue,
            };
          });
        } else if (this.optionKey) {
          option = this.selectedOptions.map((v) => {
            return { [this.optionKey]: v[this.optionKey], newValue: v.newValue };
          });
        }
      } else {
        if (this.notValueToFullSelect === true) {
          if (this.optionLabel && this.optionKey) {
            option = this.dataOptions.map((v) => {
              return {
                [this.optionLabel]: v[this.optionLabel],
                [this.optionKey]: v[this.optionKey],
                newValue: v.newValue,
              };
            });
          } else if (this.optionKey) {
            option = this.dataOptions.map((v) => {
              return { [this.optionKey]: v[this.optionKey], newValue: v.newValue };
            });
          }
        }
      }
      this.$emit("update:modelValue", option);
    },
    addNewItem() {
      this.dataOptions.push({
        [this.optionLabel]: this.searchText,
        autoIncrement: this.autoIncrement++,
        value: true,
        newValue: true,
      });
      this.searchText = "";
      this.setData();
    },
    popSelectedOption() {
      if (!this.keyboardDelete) {
        return;
      }

      if (this.searchText === null) {
        // this.selectedOptions.pop();
        return;
      }
    },
    seedSearchText() {
      if (this.searchText !== null) {
        return;
      }

      this.searchText = "";
    },
    setPossibleOption() {
      if (!this.matchingOptions || !this.matchingOptions.length) {
        return;
      }

      if (this.pointer === -1) {
        this.pointer = 0;
      }
    },
    removeOption(industry) {
      this.dataOptions[
        this.dataOptions.findIndex((v) => v.name === industry.name)
      ].value = false;
      this.matchingOptions = this.dataOptions;
    },
    closeOut() {
      this.searchText = null;
      this.closed = true;
    },
    movePointerDown() {
      if (!this.matchingOptions) {
        return;
      }
      if (this.pointer >= this.matchingOptions.length - 1) {
        return;
      }

      this.pointer++;
    },
    movePointerUp() {
      if (this.pointer > 0) {
        this.pointer--;
      }
    },
    handleClickOutside(e) {
      if (this.$el.contains(e.target)) {
        return;
      }

      this.closeOut();
    },
  },
  computed: {
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
    checkCountPossibleOption() {
      if (this.searchText === null) return false;
      if (this.searchText === "") return false;
      if (this.matchingOptions == null) return false;
      return (
        this.matchingOptions.filter((v) => v[this.optionLabel] === this.searchText)
          .length <= 0
      );
    },
    selectedOptions() {
      let list = this.dataOptions
        .filter((option, index) => option.value)
        .slice(0, this.maxResults);
      return list;
    },
    matchingOptions: {
      get: function () {
        if (this.searchText === null) {
          return null;
        }
        if (this.optionLabel) {
          return this.dataOptions
            .filter((option) =>
              option[this.optionLabel]
                .toString()
                .toLowerCase()
                .includes(this.searchText.toString().toLowerCase())
            )
            .slice(0, this.maxResults);
        }
        if (this.optionKey) {
          return this.dataOptions
            .filter((option) =>
              option[this.optionKey]
                .toString()
                .toLowerCase()
                .includes(this.searchText.toString().toLowerCase())
            )
            .slice(0, this.maxResults);
        }

        return this.dataOptions
          .filter((option) =>
            option
              .toString()
              .toLowerCase()
              .includes(this.searchText.toString().toLowerCase())
          )
          .slice(0, this.maxResults);
      },
      set: function (newValue) {
        this.dataOptions = newValue;
        this.setData();
      },
    },
    isRequired() {
      if (!this.required) {
        return "";
      }

      if (this.selectedOptions.length) {
        return "";
      }

      return "required";
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.sub-description {
  margin-top: 10px;
  @extend %desktop_standart;
  color: $text-gray;
  @include sm() {
    color: #a0a0a0;
    font-family: SFProDisplay;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.26px;
  }
}
.description {
  @include desktop_description;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 500;
  color: $input-label;
}
.dialog-checkbox {
  width: 100%;
  background: white;
  padding: 32px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  position: absolute;
  margin-top: 12px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    & {
      width: 64%;
    }
  }
  &__item-dialog {
    margin-top: 22px;
  }
  &__button {
    margin-top: 30px;
  }
  &__item-add-custom {
    color: $text-gray;
  }
  &__scroll {
    margin: 0 -18px 0 -18px;
    padding-left: 18px;
  }
  &__list {
    padding-right: 18px;
  }
  .ps {
    max-height: 500px;
    @include md {
      max-height: 300px;
    }
  }
}
.industry-full {
  .dialog-checkbox {
    width: 100%;
  }
}
.registration-form__function {
  display: flex;
  padding-right: 18px;
  cursor: pointer;
  align-items: center;
  height: 100%;
  &-plus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: $accent;
  }
}
.placeholder {
  opacity: 0.5;
}
p {
  @extend %desktop_standart;
  color: $text-gray;
  @include sm() {
    color: #a0a0a0;
    font-family: SFProDisplay;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.26px;
  }
}
.input-error {
  color: #ef693f;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}

.list-reset {
  list-style: none;
  padding: 0;
}
.overflow-auto {
  overflow: auto;
}
.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.text-black {
  color: #1a1a1a;
  border-radius: 12px;
}
.text-grey-darker {
  color: #606f7b;
}
.leading-tight {
  line-height: 1.25;
}
.text-sm {
  @include mobile_text;
}
.w-full {
  width: 100%;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-between {
  justify-content: space-between;
}
.content-center {
  align-content: center;
}
.border-none {
  border: none;
}
.pin-r {
  right: 0;
}
.pin-y {
  top: 0;
  bottom: 0;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.items-center {
  align-items: center;
}
.p-1 {
  padding: 0.25em;
}
.pb-1 {
  padding-bottom: 0.25em;
}
.pl-2 {
  padding-left: 0.5em;
}
.pr-1 {
  padding-right: 0.25em;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}
.px-2 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.pb-1 {
  padding-bottom: 0.25em;
}
.m-0 {
  margin: 0;
  margin-bottom: 0 !important;
}
.m-1 {
  margin: 0.25em;
}
.mt-1 {
  margin-top: 0.25em;
}
.mr-1 {
  margin-right: 0.25em;
}
.ml-1 {
  margin-left: 0.25em;
}
.mt-px {
  margin-top: 1px;
}
.mb-0 {
  margin-bottom: 0;
}
.leading-tight {
  line-height: 1.25;
}
.text-left {
  text-align: left;
}
.w-full {
  width: 100%;
}
.shadow {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.w-3 {
  width: 0.75em;
}
.h-3 {
  height: 0.75em;
}
.fill-current {
  fill: currentColor;
}
.hover\:no-underline:hover {
  text-decoration: none;
}
.outline-none {
  outline: 0;
}
.hover\:outline-none {
  outline: 0;
}
.hover\:bg-grey-lighter:hover {
  background-color: #0088ff;
}
.shadow-md {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.focus\:shadow-outline:focus {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
  box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
}
.rounded {
  border-radius: 0.25em;
}
.search-input {
  height: 46px;
}
.icons svg {
  width: 0.75em;
  height: 0.75em;
}

.required {
  _color: #721c24;
  _background-color: #f8d7da;
  border-color: #f5c6cb;
}
.input-wrapper__error {
  border-radius: 12px;
  border: 1px solid $error;
}
.cursor-pointer {
  cursor: pointer;
}
.dropdown {
  border-radius: 12px;
  line-height: 1.25;
  text-align: left;
  display: inline;
  width: 99.8%;
  & > li {
    @extend %text-standart;
    background-color: $bg-light-gray;
    border-top: 1px solid $white;
    cursor: pointer;
    padding: 13px 20px;
  }
}
.active {
  background-color: #dae1e7;
}
.hidden {
  display: none;
}
.appearance-none {
  appearance: none;
}
input {
  overflow: visible;
}
.search-input {
  font-size: 100%;
  margin: 0;
  display: flex;
  padding: 15px 20px;
  align-items: center;
}
.select-wrapper,
.box-size {
  box-sizing: border-box;
}
.pill {
  padding: 11px 12px 11px 15px;
  cursor: pointer;
  border-radius: 8px;
  line-height: 1.5;
  letter-spacing: -0.05em;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
}
.pill:hover {
  background-color: #f1f5f8;
}

.grey {
  background: $bg-light-gray;
}

.white {
  background: whitet;
}
</style>
