import { computed, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { maxLength, required } from "@vuelidate/validators";
import useCompany from "@/hooks/service/useCompany";

export default function useOtherOptions({ lendDescription = 1000 }) {
    const { isCompany, isPeople, teamAndPeople, vCompany$ } = useCompany();
    const getRangeHorse = computed(() => {
        if (workload_format.value === 'per_day') {
            return rangeHorse[0]
        } else if (workload_format.value === 'per_week') {
            return rangeHorse[1]
        } else if (workload_format.value === 'per_month') {
            return rangeHorse[2]
        } else {
            return rangeHorse[0]
        }
    });
    const getStringValue = computed(() => {
        if (workload_format.value === 'per_day') {
            return 'день'
        } else if (workload_format.value === 'per_week') {
            return 'неделю'
        } else if (workload_format.value === 'per_month') {
            return 'месяц'
        } else {
            return 'день'
        }
    });
    const getRangePrice = computed(() => {
        if (isPeople.value) {
            return pay_format.value === 'per_month' ? rangePrice[2] : rangePrice[1]
        } else if (isCompany.value) {
            return rangePrice[0]
        } else {
            return rangePrice[0]
        }
    });
    const validHours = (value) => {
        if (value === null || value === '') {
            return true;
        }
        return value >= getRangeHorse.value.min && value <= getRangeHorse.value.max;
    };
    const validPrice = (value) => {
        if (value === null || value === '') {
            return true;
        }
        return value >= getRangePrice.value.min && value <= getRangePrice.value.max;
    };
    const validLength = (value) => {
        if (value === null || value === '' || value.length <= 0) {
            return true;
        }
        return value.length <= 10
    };
    const rules = computed(() => {
        let people = isPeople.value ? {
            workload_format: {
                required
            },
            income: {
                required,
                validPrice: validPrice
            },
            hours: {
                required,
                validHours: validHours
            }
        } : {
            budget: {
                required,
                validPrice: validPrice
            },
        }
        return {
            description: {
                required,
                maxLength: maxLength(lendDescription)
            },
            companies: {
                validLength: validLength
            },
            userList: {
                validLength: validLength
            },
            expertisesList: {
                validLength: validLength
            },
            ...people
        }
    });
    const percent = ref(0);
    const description = ref('')
    const hours = ref(1);
    const budget = ref(1000);
    const income = ref(1000);
    const workload_format = ref('per_day');
    const pay_format = ref('per_month'); // Формат оплаты в час, в месяц
    const rangePrice = [{ // Для юр лица
            min: 1000, //Доход в месяц от
            max: 2000000000, //Доход в месяц до
            step: 500, //Доход в месяц шаг
        },
        { // Для физ лица доход в день
            min: 1000, //Доход в месяц от
            max: 2000000000, //Доход в месяц до
            step: 500, //Доход в месяц шаг
        },
        { // Для физ лица доход в месц
            min: 1000, //Доход в месяц от
            max: 2000000000, //Доход в месяц до
            step: 1000, //Доход в месяц шаг
        },
    ];
    const rangeHorse = [{
            min: 1,
            max: 24
        },
        {
            min: 1,
            max: 60
        },
        {
            min: 1,
            max: 720
        },
    ];
    const companies = ref([]);
    const userList = ref([]);
    const expertisesList = ref([]);
    const vOtherOptions$ = useVuelidate(rules, {
        workload_format,
        income,
        hours,
        description,
        budget,
        companies,
        userList,
        expertisesList
    }, { $scope: false });
    const isValidateCompany = () => {
        return vOtherOptions$.value.description.$invalid === false &&
            vOtherOptions$.value.budget.$invalid === false;
    }
    const isValidatePeople = () => {
        return vOtherOptions$.value.workload_format.$invalid === false &&
            vOtherOptions$.value.income.$invalid === false &&
            vOtherOptions$.value.hours.$invalid === false &&
            vOtherOptions$.value.description.$invalid === false;
    }
    const completeOtherOptions = computed(() => {
        return isPeople.value ? isValidatePeople() : isValidateCompany();
    });
    return {
        isCompany,
        isPeople,
        teamAndPeople,
        percent,
        description,
        hours,
        budget,
        income,
        workload_format,
        pay_format,
        rangePrice,
        rangeHorse,
        completeOtherOptions,
        getRangeHorse,
        getRangePrice,
        vOtherOptions$,
        vCompany$,
        companies,
        userList,
        expertisesList,
        getStringValue
    };
};