<template>
  <div class="info-block" v-if="isOpen">
    <svg
      class="desktop"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        stroke="#7FC666"
        stroke-width="1.6"
      />
      <path d="M12 7V14" stroke="#7FC666" stroke-width="2" stroke-linecap="round" />
      <path
        d="M11.5 17C11.5 16.7239 11.7239 16.5 12 16.5C12.2761 16.5 12.5 16.7239 12.5 17C12.5 17.2761 12.2761 17.5 12 17.5C11.7239 17.5 11.5 17.2761 11.5 17Z"
        fill="#7FC666"
        stroke="#7FC666"
        stroke-width="1"
      />
    </svg>
    <div class="title">
      <div class="title-text">
        <p>Вы внесли изменения</p>
      </div>
    </div>
    <p class="subtitle">Сохраните обновленную информацию</p>
    <div class="actions">
      <slot name="button" />
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "SaveBlock",
  components: { SvgIcon, BaseButton },
  props: {
    saved: Boolean,
    isOpen: Boolean,
  },
  data() {
    return {
      buttonName: "Сохранить",
      divColor: "#42434e",
    };
  },

  computed: {
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
  methods: {
    save() {
      this.buttonName = "Сохранено";
      this.divColor = "#7FC666";
      this.$emit("save", true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.info-block {
  z-index: 999;
  position: fixed;
  bottom: 60px;
  right: 60px;
  display: flex;
  width: 297px;
  padding: 24px 30px 30px 30px;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  background: #f6f6f6;

  @include sm {
    // display: none;
    height: auto;
    display: flex;
    position: fixed;
    bottom: 0px;
    right: 0px;
    min-height: 91px;
    padding: 20px;
    width: 100%;
    border-radius: 18px;
    background: #fff;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  font-family: Muller;
  margin-top: 48px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  width: 100%;

  @include sm {
    display: none;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include sm {
    padding-bottom: 0px;
  }
}

.title-text {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.subtitle {
  font-family: SFProDisplay;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  padding-bottom: 24px;
  @include sm() {
    display: none;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.button {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 56px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: #42434e;
  color: #fff;
  text-align: center;
  font-family: Muller;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;

  //line-height: 20px;
}

.arrow-mobile {
  display: none;

  @include sm() {
    cursor: pointer;
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background: #ffffff33;
    align-items: center;
    padding: 10px;
    padding-left: 7px;
  }
}

.desktop {
  display: block;

  @include sm() {
    display: none;
  }
}
</style>
