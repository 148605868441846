import store from '../../store/index'

export default function auth(to, from, next) {
  let check = !store.getters["auth/isAuth"];
  if (to.name !== 'AuthPage' && check) next({ name: 'AuthPage' })
  let not_verified = !store.getters["auth/isMail"];
  if (to.path !== '/profile/information' & to.name !== 'RequestAddPage') {
    if (not_verified) next({ name: 'VerifyMock' })
  }
  next()
}