<template>
  <div v-if="isFlat" class="item-people">
    <div>
      <img
        class="avatar"
        :src="$store.getters['auth/assets'] + 'img/' + photo"
        alt=""
        loading="lazy"
      />
    </div>
    <div>
      <div>{{ name }}</div>
      <div class="item-people status">{{ description }}</div>
    </div>
  </div>

  <div v-else class="item-people big" :class="{ active: isActive }">
    <div>
      <img
        :size="mobile ? 40 : 87"
        :src="$store.getters['auth/assets'] + 'img/' + photo"
        class="avatar big"
        :class="{ activeph: isActive }"
        loading="lazy"
      />
    </div>
    <div>
      <div>{{ name }}</div>
      <div class="status big" :class="{ active: isActive }">{{ description }}</div>
    </div>
  </div>
</template>
<script>
import Avatar from "@/components/ui/Avatar";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "TaskCard",
  components: {
    Avatar,
    BaseButton,
  },
  props: {
    photo: {
      type: String,
      default: "start-page-man_cp.png",
    },
    description: {
      type: String,
      default: "Эксперт Gigoo, Product Manager в Ozon",
    },
    name: {
      type: String,
      default: "Анна Д.",
    },
    isFlat: {
      type: Boolean,
      default: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mobile() {
      return (
        this.$vuetify.display.md || this.$vuetify.display.sm || this.$vuetify.display.xs
      );
    },
  },
  methods: {
    getPhoto() {
      if (this.fullPath) {
        return this.photo;
      } else {
        if (this.photo == null || this.photo === "") return null;
        return this.photo;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.item-people {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 13px;
  text-align: left;
  gap: 10px;
  font-size: 14px;

  &.big {
    cursor: pointer;
    gap: 15px;
    font-size: 20px;
    font-family: Muller;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    flex-wrap: nowrap;
    @include md {
      padding: 5px;
      gap: 5px;
      border-radius: 50px;
      background-color: #fff;
      position: relative;
    }
  }
}
.avatar {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-fit: cover;
  &.big {
    object-fit: fit;
    object-position: 0% 0%;
    width: 87px;
    height: 87px;
    @include md {
      width: 40px;
      height: 40px;
    }
  }
}
.status {
  color: #a0a0a0;
  padding-top: 0px;

  &.big {
    color: #a0a0a0;
    /* Desktop/Additional */
    font-size: 16px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: 400;
    padding-top: 10px;
    white-space: nowrap;

    @include md {
      display: flex;
    }
  }
}

.activeph {
  border: 5px solid #7fc666 !important;

  @include md {
    border: 0px solid #7fc666 !important;
  }
}

.active {
  @include md {
    background-color: #7fc666 !important;
    color: #fff !important;
  }
}
</style>
