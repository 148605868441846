import { profileService } from "@/services";
import { errorFormat, sortByKey } from "@/utils/helper";
import requestService from "@/services/requestService";
import LsUtil from '@/utils/ls'

/**
 @typedef Services
 */
export default {
    namespaced: true,
    state: {
        requestInfo: {},
        requestContacts: {},
        responseAll: {},
        requestManageInfo: {},
        requestManageResponds: [],
        requestList: {},
        requestFinishingData: {},
    },
    actions: {
        async createRequest({ commit }, payload) {
            try {
                await requestService.createRequest(payload);
            } catch (e) {
                console.log(e.response.data, e.response.content)
                throw errorFormat(e.response.data);
            }
        },
        async getRequestList({ commit }) {
            try {
                const response = await requestService.getRequestList();
                commit('GET_REQUEST_LIST', response.data.data)
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getRequestInfoShort({ commit }, id) {
            try {
                const response = await requestService.getRequestInfoShort(id);
                commit('SET_REQUEST_INFO', response.data.data)
            } catch (e) {
                if (e.response.status === 403) {
                    throw 403
                } else {
                    throw errorFormat(e.response.data);
                }
            }
        },
        async getRequestInfoContats({ commit }, id) {
            try {
                const response = await requestService.getRequestInfoContats(id);
                commit('SET_REQUEST_CONTACTS', response.data)
            } catch (e) {
                if (e.response.status === 403) {
                    throw 403
                } else {
                    throw errorFormat(e.response.data);
                }
            }
        },
        async sendResponse({ commit }, payload) {
            try {
                await requestService.sendResponse(payload);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async rejectResponse({ commit }, payload) {
            try {
                await requestService.rejectResponse(payload);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async viewRequest({ commit }, payload) {
            try {
                await requestService.viewRequest(payload);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getAllRespond({ commit }) {
            try {
                const response = await requestService.getAllRespond();
                const response_demand = await requestService.getAllDemandsServices();
                let res = response.data.data
                res.request = response_demand.data.data
                commit('SET_ALL_RESPONSE', res)
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },

        async dropIsNewFlagDemandsServices({ commit }, id) {
            try {
                await requestService.dropIsNewFlagDemandsServices(id);
            } catch (e) {
                console.log(errorFormat(e.response.data))
                throw errorFormat(e.response.data);
            }
        },
        async getRequestManage({ commit }, id) {
            try {
                const response = await requestService.getRequestManage(id);
                commit('SET_REQUEST_MANAGE', { value: response.data.data, id })
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getRequestManageReturn({ commit }, id) {
            try {
                const response = await requestService.getRequestManage(id);
                return response.data.data
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async sendRequest({ commit }, payload) {
            try {
                await requestService.sendRequest(payload);
            } catch (e) {
                console.log(errorFormat(e.response.data))
                throw errorFormat(e.response.data);
            }
        },
        async sendRestart({ commit }, payload) {
            try {
                await requestService.sendRestart(payload);
            } catch (e) {
                console.log(errorFormat(e.response.data))
                throw errorFormat(e.response.data);
            }
        },
        async sendStop({ commit }, payload) {
            try {
                await requestService.sendStop(payload);
            } catch (e) {
                console.log(errorFormat(e.response.data))
                throw errorFormat(e.response.data);
            }
        },
        async deleteItem({ commit }, id) {
            try {
                await requestService.deleteItem(id);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },

        async requestPayment({ commit }, id) {
            try {
                await requestService.requestPayment({ demand_id: id });
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },

        async getRequestFinishingData({ commit }, id) {
            try {
                const response = await requestService.getRequestFinishingData(id);
                commit('SET_REQUEST_FINISHING_DATA', response.data.data)
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async rateRequestServiceOrUser({ commit }, payload) {
            try {
                await requestService.rateRequestServiceOrUser(payload);
            } catch (e) {
                console.log(errorFormat(e.response.data))
                throw errorFormat(e.response.data);
            }
        },
        async deleteRequestRating({ commit }, payload) {
            try {
                await requestService.deleteRequestRating(payload);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async completeRequest({ commit }, payload) {
            try {
                await requestService.completeRequest(payload);
            } catch (e) {
                console.log(errorFormat(e.response.data))
                throw errorFormat(e.response.data);
            }
        },
        async toFavorite({ commit }, payload) {
            try {
                await requestService.toFavorite(payload);
            } catch (e) {
                console.log(errorFormat(e.response.data))
                throw errorFormat(e.response.data);
            }
        },
    },
    mutations: {
        SET_REQUEST_INFO(state, value) {
            state.requestInfo = value
        },
        SET_REQUEST_CONTACTS(state, value) {
            state.requestContacts = value
        },
        SET_ALL_RESPONSE(state, value) {
            state.responseAll = value
        },
        SET_REQUEST_FINISHING_DATA(state, value) {
            state.requestFinishingData = value
        },
        // prettier-ignore
        SET_REQUEST_MANAGE(state, { value, id }) {
            let local = LsUtil.getItem('request') || {};
            let temp = [];
            // ВОТ ТУТ БЫЛО КОРЯВО ПОСМОТРИ ЕСЛИ ЧТО ПРОШЛУЮ ВЕРСИЮ
            if (local[id] && Array.isArray(value) && Array.isArray(value.responds) && value.responds.length > 0) {
                value.responds.forEach(item => {
                    local[id].forEach(el => {
                        if (el.id === item.id) {
                            item.select = el.select;
                        }
                    });
                    item.dateRange = Date.now();
                    return item;
                });

                // удаление элементов которых нет в основном списке с сервера, но есть в localStorage
                local[id].forEach(item => {
                    let found = false;
                    for (let i = 0; i < value.responds.length; i++) {
                        if (value.responds[i].id === item.id) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        temp.push(item);
                    }
                });

                if (temp.length) {
                    for (let i = temp.length - 1; i >= 0; i--) {
                        local[id].splice(temp[i], 1);
                    }
                }

                LsUtil.setItem('request', local);
            }

            state.requestManageInfo = value.demandInfo
            state.requestManageResponds = value.responds || []
        },
        GET_REQUEST_LIST(state, value) {
            state.requestList = value
        },
    },
    getters: {
        requestInfo(state) {
            return state.requestInfo
        },
        requestContacts(state) {
            return state.requestContacts
        },
        responseAll(state) {
            return state.responseAll
        },
        requestManageInfo(state) {
            return state.requestManageInfo
        },
        requestManageResponds(state) {
            return state.requestManageResponds
        },
        requestList(state) {
            return state.requestList
        },
        requestFinishingData(state) {
            return state.requestFinishingData
        }
    }
}