<template>
  <div class="base-file__wrapper" :class="{ error: isError }">
    <div class="title">
      <p class="base-file__title" v-if="title">{{ title }}</p>
      <p class="base-file__description" v-if="isOptional">необязательно</p>
    </div>
    <div
      class="base-file"
      :class="[{ 'is-file': fileCounter }, isWhite ? 'white' : 'grey']"
    >
      <label class="base-file__label" :for="id">
        <input
          :id="id"
          :ref="ref"
          :accept="fileType"
          type="file"
          class="base-file__input"
          @change="checkFile"
        />
        <span v-if="fileCounter" class="base-file__title-span">{{
          splitFileName(fileName)
        }}</span>
        <template v-if="!fileCounter">
          <span class="base-file__btn">{{ placeholder }}</span>
          <svg-icon class="base-file__btn-icon" :width="45" :height="45" name="btn-add" />
        </template>
        <div v-else class="base-file__remove" @click="clearFile">
          <svg-icon :width="20" :height="20" name="close-mobile-menu" />
        </div>
      </label>
    </div>
    <div v-if="isError" class="input-error">{{ errorString }}</div>
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";

export default {
  name: "BaseFile",
  components: { SvgIcon, BaseButton },
  props: {
    fileType: {
      type: String,
      default: ".jpg,.jpeg,.png",
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Загрузите вложение",
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    ref: {
      type: String,
      default: "baseFile",
    },
    id: {
      type: String,
      default: "file",
    },
  },
  data() {
    return {
      fileCounter: "",
      fileName: "",
      file: "",
    };
  },
  computed: {
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
  },
  methods: {
    checkFile(event) {
      this.fileCounter = event.target.files.length;
      this.fileName = event.target.files[0].name;

      if (this.fileName) {
        this.$emit("file", this.$refs.baseFile.files[0]);
      } else {
        this.$emit("file", "");
      }
    },
    splitFileName(name) {
      console.log(name.split(name.length - 4, 4));
      return name.length > 35
        ? name.slice(0, 27) + "..." + name.slice(name.length - 8, name.length)
        : name;
    },
    clearFile(e) {
      e.preventDefault();
      this.$refs.baseFile.value = "";
      this.fileCounter = 0;
      this.fileName = "";

      this.$emit("file", null);
      this.fileName ? (this.pictureActive = true) : (this.pictureActive = false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grey {
  background: $bg-light-gray;
}

.white {
  background: white;
}

.base-file {
  border-radius: 12px;
  transition: 0.3s;

  &__label {
    display: flex;
    align-items: center;
    padding: 6px 6px 6px 18px;
    width: 100%;
    cursor: pointer;
    height: 52px;
    overflow: hidden;
    margin: 0;
  }

  &__plus {
    display: block;
    width: 44px;
    height: 44px;
    background-color: #f6f6f6;
  }

  &__title {
    @include desktop_description;
    margin-bottom: 10px;
    @include sm {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 106.667% */
    }
  }

  &__description {
    @include desktop_additional;
    margin-bottom: 10px;
    color: $text-gray;
    @include sm() {
      color: #a0a0a0;
      font-family: SFProDisplay;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
      letter-spacing: 0.26px;
    }
  }

  &__title-span {
    @include desktop_description;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 10px;
  }

  &__input {
    display: none;
  }

  &__btn {
    font-family: SFProDisplay, Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.006em;
    opacity: 0.2;
    @include sm() {
      font-family: SFProDisplay;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
      letter-spacing: 0.26px;
    }
    &-icon {
      flex-shrink: 0;
      margin-left: auto;
    }
  }

  &__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background-color: $bg-light-gray;
    border-radius: 7px;
    margin-right: 6px;
    margin-left: auto;
    cursor: pointer;
    flex-shrink: 0;
  }

  &.is-file {
    border-color: transparent;

    .base-file {
      &__label {
        padding: 6px 0 6px 0;
      }
    }
  }

  &__wrapper {
    &.error {
      .base-file {
        border: 1px solid #ef693f;
      }

      .input-error {
        color: #ef693f;
        font-size: 16px;
        line-height: 20px;
        margin-top: 8px;
      }
    }
  }
}
</style>
