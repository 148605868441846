<template>
  <div class="image-slider">
    <div class="image-slider__content">
      <img
        v-for="(img, idx) in full_images"
        :key="`img-${idx}`"
        class="home-scroll-img"
        :src="$store.getters['auth/assets'] + 'img/' + img"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyScroller",
  props: {
    images: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      full_images: [],
    };
  },
  created() {
    this.full_images = [];
    for (let i = 0; i < 10; i++) {
      this.full_images = this.full_images.concat(this.images);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.home-scroll-img {
  margin-right: 80px;
  /* Adjust the margin as needed */
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
  @include sm {
    margin-right: 40px;
  }
}

.image-slider {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.image-slider__content {
  /* Apply animation to this element */
  -moz-animation: scroll 60s linear infinite;
  -webkit-animation: scroll 60s linear infinite;
  animation: scroll 60s linear infinite;
  width: 100%;

  @include md {
    /* Apply animation to this element */
    -moz-animation: scroll 30s linear infinite;
    -webkit-animation: scroll 30s linear infinite;
    animation: scroll 30s linear infinite;
    width: 100%;
  }
}

@keyframes scroll {
  0% {
    -moz-transform: translateX(-500%);
    /* Firefox bug fix */
    -webkit-transform: translateX(-500%);
    transform: translateX(-500%);
  }

  100% {
    -moz-transform: translateX(0%);
    /* Firefox bug fix */
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
</style>
