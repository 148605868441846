<template>
  <div class="information">
    <verification-modal
      :header="socialName"
      subtitle="Вставьте ссылку на профиль"
      placeholder="Укажите социальную сеть"
      :is-open="isSendSocial"
      @close="isSendSocial = false"
      ref="verification"
      @submit="sendSocial"
    />
    <container :transparent-card="this.$vuetify.display.sm || this.$vuetify.display.xs">
      <template v-slot:nav>
        <v-tabs direction="vertical" v-model="tabs">
          <v-tab
            v-for="(item, idx) in tabsList"
            :key="`tabs_${idx}`"
            :href="`#${item.value}`"
            :value="item.value"
            >{{ item.title }}</v-tab
          >
        </v-tabs>
        <div class="guide" v-if="!isSomeOne">
          <a
            target="_blank"
            :href="
              getPolicy(
                '/docs/Gigoo%20%D0%B8%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F%20%D0%B4%D0%BB%D1%8F%20%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82%D0%B0.pdf'
              )
            "
            >Инструкция для эксперта</a
          >
        </div>
      </template>
      <template v-slot:body>
        <div
          id="contact"
          class="block"
          v-intersect="{
            handler: selectContact,
            options: {
              threshold: [1],
            },
          }"
        >
          <h2 class="information__header">Общая информация</h2>
          <expert-contacts v-if="isSomeOne"></expert-contacts>
          <div v-else>
            <base-input
              :description="!mobile ? 'Номер телефона' : ''"
              placeholder="Номер телефона"
              type="phone"
              class="base-input registration-form__double phone-field"
              v-model="userData.phone"
              @validPhone="validPhoneEvent"
              :is-error="v$.userData.phone.$error"
              :error="[
                {
                  text: $t('pages.register.validate.phone.required'),
                  status: v$.userData.phone.required.$invalid,
                },
                {
                  text: $t('pages.register.validate.phone.valid-phone'),
                  status: v$.userData.phone.validPhone.$invalid,
                },
              ]"
            />
            <div class="profile-col">
              <base-input
                class="base-input"
                :description="!mobile ? 'Почта' : ''"
                placeholder="usermail@gmail.com"
                type="email"
                v-model="userData.email"
                :is-error="v$.userData.email.$error"
                :error="[
                  {
                    text: $t('pages.register.email-validate'),
                    status: v$.userData.email.required.$invalid,
                  },
                  {
                    text: $t('validate.email'),
                    status: v$.userData.email.email.$invalid,
                  },
                ]"
              />
              <base-input
                v-if="!mobile"
                class="base-input"
                description="Корпоративная почта"
                placeholder="username@company.ru"
                type="email"
                v-model="userData.corp_email"
                :is-error="v$.userData.corp_email.$error"
                :error="[
                  {
                    text: $t('validate.email'),
                    status: v$.userData.corp_email.email.$invalid,
                  },
                ]"
              />
            </div>

            <p class="small-title mb-3">Подключите соцсети</p>
            <base-button
              v-for="socialItem in socialList"
              :key="socialItem.key"
              class="information__social-btn"
              @click="showSocialModal({ name: socialItem.key, label: socialItem.name })"
              type="button"
              icon-postfix-size="24"
              :iconPrefix="socialItem.icon"
              outlined
              simpleLink
            >
              <div class="information__content">
                <p class="information__send">{{ socialBody(socialItem) }}</p>
              </div>
              <template v-if="getCheckPacked(socialItem.key)" v-slot:postfix>
                <svg-icon
                  class="information__success"
                  :width="20"
                  :height="20"
                  name="input-check"
                ></svg-icon>
              </template>
            </base-button>

            <div class="attachment mt-6">
              <p class="small-title">Ваше резюме</p>
              <!-- <base-resume
                v-if="resume_processed != -1 && !(visitTime.parse.close > 0)"
                :active_step="resumeActiveStep"
                @save="saveResume"
              >
                <template v-slot:container>
                  <base-file
                    is-white
                    v-if="!resume"
                    @file="saveDataResume"
                    file-type=".pdf,.docx,.doc"
                    :placeholder="'Загрузить резюме'"
                    :is-error="v$.file.$error"
                    :error="[
                      {
                        text: 'Неверный тип файла',
                        status: v$.file.checkType.$invalid,
                      },
                      {
                        text: 'Максимальный размер файла - 50мб',
                        status: v$.file.checkSize.$invalid,
                      },
                    ]"
                  />

                  <a :href="resume" class="resumemock" target="_blank" v-else><div class="resumemock-resumeName">{{
                    this.resume_filename
                  }}</div></a>
                </template></base-resume
              > -->
              <div>
                <div v-if="!resume">
                  <base-file
                    @file="saveDataResume"
                    file-type=".pdf,.docx,.doc"
                    :placeholder="'Загрузить резюме'"
                    :is-error="v$.file.$error"
                    :error="[
                      {
                        text: 'Неверный тип файла',
                        status: v$.file.checkType.$invalid,
                      },
                      {
                        text: 'Максимальный размер файла - 50мб',
                        status: v$.file.checkSize.$invalid,
                      },
                    ]"
                  />
                </div>

                <base-button
                  v-if="resume"
                  class="information__resume-btn"
                  type="button"
                  outlined
                  social
                >
                  <div @click="openLink(resume)">
                    <p class="information__send">{{ this.resume_filename }}</p>
                  </div>

                  <template v-slot:postfix>
                    <svg-icon
                      class="ml-3"
                      width="20"
                      @click.stop="dellResume()"
                      name="close-mobile-menu"
                    >
                    </svg-icon>
                  </template>
                </base-button>
              </div>
            </div>
          </div>
        </div>
        <div
          id="projects"
          class="block"
          v-intersect="{
            handler: selectProjects,
            options: {
              threshold: [1],
            },
          }"
        >
          <project-expertise-list
            :is-some-one="isSomeOne"
            :industries="userData.industries_proj"
            :options="userData.options_proj"
            @update:industries="(v) => upd_ind(v)"
            @update:options="(v) => upd_opt(v)"
          />
        </div>
        <div
          id="case"
          class="block"
          v-intersect="{
            handler: selectCases,
            options: {
              threshold: [1],
            },
          }"
        >
          <case-list title="Кейсы" :cases="cases" ref="caseExperienceList" />
        </div>
        <div
          id="experience"
          class="block"
          v-intersect="{
            handler: selectExperience,
            options: {
              threshold: [1],
            },
          }"
        >
          <work-experience-list
            ref="workExperienceList"
            title="Опыт работы и специализация"
            :works="works"
            :specialization="specialization"
            @update="get_data()"
            @openRecommendationFirst="(v) => openRecommendationFirst(v, 'specialization')"
          />
        </div>
        <div
          id="expertise"
          class="block"
          v-intersect="{
            handler: selectExpertise,
            options: {
              threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            },
          }"
        >
          <competence-list
            ref="competenceList"
            title="Компетенции"
            @openModal="openModal"
            :competence="userRatedExpertises"
            @openRecommendationFirst="
              (v, index) => openRecommendationFirst(v, 'expertise')
            "
          />
        </div>
        <div
          id="publication"
          class="block"
          v-intersect="{
            handler: selectPublication,
            options: {
              threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            },
          }"
        >
          <publication-block
            v-if="!isSomeOne"
            :is-some-one="isSomeOne"
            ref="publicationBlock"
          />
        </div>
        <div
          id="expert"
          class="block expert"
          v-if="!isSomeOne"
          v-intersect="{
            handler: selectExpert,
            options: {
              threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            },
          }"
        >
          <!-- <expert-council /> -->
        </div>
      </template>
    </container>
    <save-block :isOpen="showSave">
      <template v-slot:button>
        <base-button :oneClickEvent="save" :class="'button' + saveButtonStyle">
          {{ saveButtonText }}
        </base-button>
      </template>
    </save-block>
    <alert-modal ref="alert"> ок </alert-modal>

    <request-recommendation-array-modal
      :expertise="expertise"
      :info-array="headerRecommendation"
      :is-open="openRecommendation"
      @close="openRecommendation = false"
      @showModalLink="showModalLink"
      @successRequest="successRequest"
    />
    <validate-competence-modal
      :expertise="expertise"
      :confirm-type="validateCompetenceConfirmType"
      :is-open="validateCompetence"
      @close="validateCompetence = false"
      @updateList="updateList"
    ></validate-competence-modal>
    <worker-added-modal
      header="Пользователь сможет оставить рекомендацию после регистрации"
      :subtitle="`Мы отправили письмо с приглашением пройти регистрацию на почту ${userInviteEmail}. Отправьте пользователю запрос на рекомендацию через форму запроса рекомендаций.`"
      :is-open="isOpenModalDesiredClients"
      @close="isOpenModalDesiredClients = false"
      :link="userInviteLink"
      class="worked-add-modal"
      btn-text="Хорошо"
    >
    </worker-added-modal>
    <request-recommendation-first
      ref="requets-recommendation-first"
      :is-open="isOpenRecommendationFirst"
      @close="() => (isOpenRecommendationFirst = false)"
      :specialization="specialization"
      :expertises="expertises"
      :prechecked-spec="precheckedSpec"
      :prechecked-exp="precheckedExp"
      @submitData="openModalRecommendationModal"
    />
  </div>
</template>

<script>
import BaseInput from "@/components/base/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import BaseResume from "@/components/base/BaseResume";
import { mapGetters } from "vuex";
import AlertModal from "@/components/modals/AlertModal";
import VerificationModal from "@/components/modals/VerificationModal";
import Container from "@/components/layout/Container";
import useVuelidate from "@vuelidate/core";
import { email, required, maxLength } from "@vuelidate/validators";
import SvgIcon from "@/components/base/SvgIcon";
import WorkExperienceList from "@/components/component/WorkExperienceList";
import CompetenceList from "@/components/component/CompetenceList";
import CaseList from "@/components/component/CaseList";
import ProjectExpertiseList from "@/components/component/ProjectExpertiseList";
import AddButton from "@/components/ui/button/AddButton";
import InfoBox from "@/components/ui/InfoBox";
import PublicationBlock from "@/components/ui/PublicationBlock";
import ValidateCompetenceModal from "@/components/modals/ValidateCompetenceModal";
import WorkerAddedModal from "@/components/modals/WorkerAddedModal";
import ExpertCouncil from "@/components/component/ExpertCouncil";
import RequestRecommendationArrayModal from "@/components/modals/RequestRecommendationArrayModal";
import ExpertContacts from "@/components/component/ExpertContacts";
import RequestRecommendationFirst from "@/components/modals/RequestRecommendationFirst";
import BaseFile from "@/components/base/BaseFile";
import SaveBlock from "@/components/ui/SaveBlock";
import { typeFile } from "@/utils/helper";

export default {
  name: "ProfileInformationPage",
  components: {
    RequestRecommendationFirst,
    RequestRecommendationArrayModal,
    ExpertCouncil,
    WorkerAddedModal,
    ValidateCompetenceModal,
    PublicationBlock,
    InfoBox,
    AddButton,
    CompetenceList,
    CaseList,
    WorkExperienceList,
    SvgIcon,
    Container,
    VerificationModal,
    AlertModal,
    BaseButton,
    BaseInput,
    BaseFile,
    ExpertContacts,
    ProjectExpertiseList,
    SaveBlock,
    BaseResume,
  },
  setup: (props) => {
    const v$ = useVuelidate({ $scope: false });
    return {
      v$,
    };
  },
  data() {
    return {
      addSpecializationList: [],
      userInviteEmail: "",
      isOpenModalDesiredClients: false,
      expertise: null,
      validateCompetenceConfirmType: null,
      validateCompetence: false,
      headerRecommendation: [],
      openRecommendation: false,
      inputValidPhone: false,
      socialName: "",
      socialKey: "",
      isSendSocial: false,
      project_industries: [],
      project_options: [],
      file: null,
      resume: "",
      resume_processed: null,
      resume_filename: null,
      tabsList: [
        {
          title: "Контактная информация",
          value: "contact",
        },
      ],
      tabs: null,
      userData: {
        phone: "",
        email: "",
        corp_email: "",
        industries_proj: [],
        options_proj: [],
      },
      socialList: [
        {
          name: "Подключить Вконтакте",
          key: "vk",
          icon: "socials/vkontakte-social_",
        },
        {
          name: "Подключить LinkedIn",
          key: "linkedIn",
          icon: "socials/linkedIn-social",
        },
      ],
      tabsContact: false,
      tabsExperience: false,
      tabsProjects: false,
      tabsCases: false,
      tabsExpertise: false,
      tabsPublication: false,
      tabsExpert: false,
      isOpenRecommendationFirst: false,
      precheckedSpec: [],
      precheckedExp: [],
      showSave: false,
      saveButtonText: "Сохранить",
      saveButtonStyle: "--default",
      resumeActiveStep: 1,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      cases: "service/cases",
      works: "service/works",
      specialization: "service/specialization",
      userExpertise: "profile/userExpertise",
      userInviteLink: "auth/userInviteLink",
      services: "service/service",
      expertises: "service/expertises",
      isMail: "auth/isMail",
      visitTime: "status/visitTime",
    }),
    isSomeOne() {
      return !!this.$route.params.id;
    },
    userRatedExpertises() {
      let merged = this.userExpertise.expertises.map((itm) => ({
        ...this.expertises.find((item) => item.id === itm.expertise_id && item),
        ...itm,
      }));
      merged = merged.map((v) => ({ ...v, ...{ n_rating: v.rating_count } }));
      return {
        expertises: merged || [],
        institutions: this.userExpertise.institutions,
      };
    },
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
  created() {
    this.tabsList = [
      ...this.tabsList,
      {
        title: "Проектная экспертиза",
        value: "projects",
      },
      {
        title: "Кейсы",
        value: "case",
      },
      {
        title: "Опыт работы",
        value: "experience",
      },
      {
        title: "Компетенции",
        value: "expertise",
      },
      {
        title: "Дополнительная информация",
        value: "publication",
      },
      // {
      //   title: "Экспертные советы",
      //   value: "expert",
      // },
    ];
    this.get_data();
  },

  mounted() {
    this.initTimes();
    this.$nextTick(() => {
      if (!this.isSomeOne) {
        if (this.user !== null) {
          if (this.user.phone != null) this.userData.phone = this.user.phone;
          if (this.user.email != null) this.userData.email = this.user.email;
          if (this.user.corp_email != null)
            this.userData.corp_email = this.user.corp_email;

          if (this.user.industries != null)
            this.userData.industries_proj = this.user.industries;
          if (this.user.options != null) this.userData.options_proj = this.user.options;
        }
      }
    });
  },
  methods: {
    async get_data() {
      await this.getUserData();
      await this.getWorksList();
      await this.getCasesList();
      await this.getSpecialization();
      await this.getUserExpertise();
      await this.getServicesExpertises();
    },
    async initTimes() {
      await this.$store.dispatch("status/initVisitTimes");
    },
    openModal(v) {
      this.expertise = v.item;
      this.validateCompetenceConfirmType = v.type;
      this.validateCompetence = true;
    },
    upd_ind(v) {
      this.userData.industries_proj = v;
    },
    upd_opt(v) {
      this.userData.options_proj = v;
    },
    getPolicy(href) {
      return (
        "https://" +
        config.VUE_APP_ASSETS_BUCKET +
        ".storage.yandexcloud.net/" +
        config.VUE_APP_ASSETS_DIR +
        href
      );
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    openRecommendationFirst(v, type) {
      this.isOpenRecommendationFirst = true;
      if (type == "specialization") this.precheckedSpec = [v["idx"]];
      else this.precheckedExp = [v["idx"]];
    },
    successRequest() {
      this.$refs.alert.show(
        "Рекомендации запрошены",
        "Мы успешно отправили запрос на рекомендации выбранным пользователям",
        {
          type: "success",
          nameButton: "Хорошо",
          redirectPath: "/profile",
        }
      );
    },
    downloadResume(path) {
      this.$router.push({ path: path });
    },

    async saveDataResume(e) {
      if (e == null) {
        this.resumeActiveStep = 1;
        return;
      }
      this.file = e;
      const result = await this.v$.file.$validate();
      if (!result) {
        return;
      }
      if (!(this.visitTime.parse.close > 0)) {
        this.showSave = true;
        this.saveButtonStyle = "--default";
        this.saveButtonText = "Сохранить";
      }
      this.resumeActiveStep = 2;
    },
    async saveResume(e) {
      if (e.type == -1) {
        this.resume_processed = -1;
        return;
      }
      const bodyFormData = new FormData();
      bodyFormData.append("process", e.type);
      if (this.file == null) {
        await this.$store.dispatch("profile/processUserResume", bodyFormData);
      } else {
        bodyFormData.append("resume", this.file);
        await this.$store.dispatch("profile/sendUserResume", bodyFormData);
      }

      this.$store.dispatch("auth/getUserData");
      if (e.type == 1) {
        this.resumeActiveStep = 3;
        return;
      }
      if (e.type == 0) {
        this.$refs.alert.show(this.$t("alert.notification"), "Резюме успешно добавлено", {
          type: "success",
          nameButton: "Ок",
        });
      }
    },
    async filesChange() {
      const file = this.$refs.baseFile.files[0];
      const bodyFormData = new FormData();
      bodyFormData.append("resume", file);
      await this.$store.dispatch("profile/sendUserResume", bodyFormData);
      this.$refs.alert.show(this.$t("alert.notification"), "Резюме успешно обновлено", {
        type: "success",
        nameButton: "Ок",
      });
    },
    async updateDataResume(e) {
      this.file = e;
      const result = await this.v$.file.$validate();
      if (!result) {
        return;
      }
    },
    async updateList() {
      await this.$store.dispatch("profile/getUserExpertise");
      await this.$nextTick(() => {
        //this.$refs.workExperienceList.methodUpdate();
        this.$refs.competenceList.methodUpdate();
        this.$refs.publicationBlock.methodUpdate();
      });
    },
    async getCasesList() {
      try {
        await this.$store.dispatch("service/getCasesList");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getServicesExpertises() {
      try {
        await this.$store.dispatch("service/getServicesExpertises", { user: this.user });
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getUserExpertise() {
      try {
        await this.$store.dispatch("profile/getUserExpertise");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    showModalLink(email) {
      this.userInviteEmail = email;
      this.isOpenModalDesiredClients = true;
    },
    openModalRecommendationModal(v) {
      this.headerRecommendation = v.headerRecommendation;
      this.openRecommendation = true;
    },
    validPhone(value) {
      if (value === "") return true;
      return this.inputValidPhone;
    },
    validPhoneEvent(v) {
      this.inputValidPhone = v;
    },
    getCheckPacked(value) {
      if (this.user.socials != null)
        return this.user.socials.filter((v) => v.name === value).length > 0;
      return false;
    },
    socialBody(socialItem) {
      if (this.user.socials == null) return socialItem.name;
      let socials = this.user.socials.filter((v) => v.name === socialItem.key);
      if (socials.length === 0) return socialItem.name;
      else return socials[0].link;
    },
    async getUserData() {
      await this.$store.dispatch("auth/getUserData");
      if (!this.isMail) {
        this.$router.replace({ name: "VerifyMock" });
        return;
      }
    },
    showSocialModal(value) {
      this.socialName = value.label;
      this.socialKey = value.name;
      let socials = this.user.socials.filter((v) => v.name === value.name);
      let link = "";
      if (socials.length > 0) link = socials[0].link;
      this.$refs.verification.show({
        nameButton: this.$t("btn.save"),
        value: link,
      });
      this.isSendSocial = true;
    },
    async getCasesList() {
      try {
        await this.$store.dispatch("service/getCasesList");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },

    async getWorksList() {
      try {
        await this.$store.dispatch("service/getWorksList");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getSpecialization() {
      try {
        await this.$store.dispatch("service/getSpecialization");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },

    async addOption(name) {
      let response = await this.$store.dispatch("registration/addOptions", {
        name: name,
      });
      return response.id;
    },
    async save() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      this.saveButtonText = "Сохранено";
      this.saveButtonStyle = "--success";
      if (this.file) {
        try {
          const bodyFormData = new FormData();
          bodyFormData.append("resume", this.file);
          await this.$store.dispatch("profile/sendUserResume", bodyFormData);
          await this.$store.dispatch("auth/getUserData");
        } catch (e) {
          this.$refs.alert.show(this.$t("alert.error"), e.response.data.message, {
            type: "error",
          });
        }
      }

      try {
        const industries = this.userData.industries_proj.map((obj) => obj.id);
        const functions = this.userData.options_proj;
        let options = [];
        for (let i = 0; i < functions.length; i++) {
          let value = functions[i];
          if (value.id <= -10) {
            let response = await this.addOption(value.name);
            options.push(response);
          } else {
            options.push(value.id);
          }
        }
        await this.$store.dispatch("profile/sendUserIndustries", { ids: industries });
        await this.$store.dispatch("profile/sendUserOptions", { ids: options });
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e.response.data.message, {
          type: "error",
        });
      }

      try {
        let response = await this.$store.dispatch(
          "registration/sendUserProfile",
          this.userData
        );

        this.getUserData();
        this.showSave = false;
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e.response.data.message, {
          type: "error",
        });
      }
    },
    async dellResume() {
      this.$refs.alert.show(
        this.$t("alert.notification"),
        "Вы точно хотите удалить текущее резюме?",
        {
          type: "error",
          nameButton: "Удалить",
        },
        () => {
          this.$store.dispatch("profile/dellUserResume");
          this.resume = "";
        }
      );
      await this.$store.dispatch("auth/getUserData");
    },
    async sendSocial(v) {
      try {
        this.isSendSocial = false;
        let socials = this.user.socials != null ? this.user.socials : [];
        let response = await this.$store.dispatch("registration/sendUserProfile", {
          socials: [
            ...socials.filter((v) => v.name !== this.socialKey),
            {
              name: this.socialKey,
              link: v.text,
            },
          ],
        });
        this.$refs.alert.show(this.$t("alert.notification"), response.data.message);
        this.getUserData();
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
        });
      }
    },
    checkType(e) {
      if (e !== null) {
        return typeFile.includes(e.type);
      } else {
        return true;
      }
    },
    checkSize(e) {
      if (e !== null) {
        return e.size < 50 * 1024 * 1024;
      } else {
        return true;
      }
    },
    selectContact(e) {
      if (e) {
        this.tabs = "contact";
      }
      this.tabsContact = e;
    },
    selectExperience(e) {
      if (e && !this.tabsContact) {
        this.tabs = "experience";
      }
      this.tabsExperience = e;
    },

    selectProjects(e) {
      if (e) {
        this.tabs = "projects";
      }
      this.tabsProjects = e;
    },

    selectCases(e) {
      if (e) {
        this.tabs = "case";
      }
      this.tabsCases = e;
    },
    selectExpertise(e) {
      if (e && !this.tabsExperience) {
        this.tabs = "expertise";
      }
      this.tabsExpertise = e;
    },
    selectPublication(e) {
      if (e && !this.tabsExpertise) {
        this.tabs = "publication";
      }
      this.tabsPublication = e;
    },
    selectExpert(e) {
      if (e && !this.tabsPublication) {
        this.tabs = "expert";
      }
      this.tabsExpert = e;
    },
    areObjectListsEqual(list1, list2) {
      // Check if both lists are defined and have the same length
      if (!list1 || !list2 || list1.length !== list2.length) {
        return false;
      }

      // Use a helper function to compare objects by their 'id' field
      function compareById(obj1, obj2) {
        return obj1.id === obj2.id;
      }

      // Use the every method to check if all objects in list1 are equal to their corresponding objects in list2
      return list1.every((obj1, index) => compareById(obj1, list2[index]));
    },
  },
  watch: {
    "userData.phone"(newVal, oldVal) {
      oldVal = oldVal.replace(/ /g, "");
      newVal = newVal.replace(/ /g, "");
      this.showSave =
        newVal !== oldVal &&
        !(oldVal == "+7" || newVal == "+7") &&
        this.user.phone !== newVal;
      this.saveButtonStyle = "--default";
      this.saveButtonText = "Сохранить";
    },
    "userData.email"(newVal, oldVal) {
      this.showSave = newVal !== oldVal && this.user.email !== newVal;
      this.saveButtonStyle = "--default";
      this.saveButtonText = "Сохранить";
    },
    "userData.corp_email"(newVal, oldVal) {
      this.showSave = (newVal !== oldVal && this.user.corp_email) || "" !== newVal;
      this.saveButtonStyle = "--default";
      this.saveButtonText = "Сохранить";
    },

    "userData.industries_proj"(newVal, oldVal) {
      this.showSave =
        newVal !== oldVal && !this.areObjectListsEqual(this.user.industries, newVal);
      this.saveButtonStyle = "--default";
      this.saveButtonText = "Сохранить";
    },
    "userData.options_proj"(newVal, oldVal) {
      this.showSave =
        newVal !== oldVal && !this.areObjectListsEqual(this.user.options, newVal);
      this.saveButtonStyle = "--default";
      this.saveButtonText = "Сохранить";
    },
    user: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue.phone == null) {
          this.$store.dispatch("content/isLoading", true);
        }
        if (newValue.phone != null) {
          this.$store.dispatch("content/isLoading", false);
        }
        if (newValue.resume != null) this.resume = newValue.resume;
        if (newValue.phone != null) this.userData.phone = newValue.phone;
        if (newValue.email != null) this.userData.email = newValue.email;
        if (newValue.corp_email != null) this.userData.corp_email = newValue.corp_email;
        if (newValue.industries != null)
          this.userData.industries_proj = newValue.industries;
        if (newValue.options != null) this.userData.options_proj = newValue.options;
        this.resume_processed = newValue.resume_processed;
        if (newValue.resume_filename != null)
          this.resume_filename = newValue.resume_filename;
        else this.resume_filename = "Мое резюме";
        if (this.resume_processed == null && this.resume_filename)
          this.resumeActiveStep = 2;
        if (this.resume_processed == 0) this.resumeActiveStep = 3;
        if (this.resume_processed == 1) this.resumeActiveStep = 4;
      },
    },
  },
  validations() {
    return {
      userData: {
        corp_email: {
          email,
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
          validPhone: this.validPhone,
        },
      },
      file: {
        checkType: this.checkType,
        checkSize: this.checkSize,
        maxLength: maxLength(1),
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

#fileInput {
  display: none;
}
.resume_label {
  margin-bottom: 0px;
}
.phone-field {
  grid-column: span 2;

  @include sm() {
    grid-column: span 1;
  }
}

.base-file__wrapper {
  width: 100% !important;
  max-width: 300px;
}

.resumemock {
  color: #000;
  text-align: center;
  font-family: Muller;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 118.75% */

  display: flex;
  height: 56px;
  padding: 15px 6px 15px 22px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;

  &-resumeName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 40ch;
    white-space: nowrap;
    @include sm {
      max-width: 20ch;
    }
  }
}
.information {
  &__content {
    display: grid;
  }

  &__success {
    min-width: 20px;
    min-height: 20px;
    margin-left: 20px;
  }

  &__send {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }

  &__save {
    margin-top: 50px;
    @include sm {
      margin: 24px 20px;
      max-width: 100%;
    }
  }

  &__resume-btn {
    width: 50%;
    margin-bottom: 12px;
    @include md {
      width: 100%;
    }
  }
  &__social-btn {
    margin-bottom: 12px;
    width: 330px;

    @include md {
      width: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    @include desktop_h2_norm;
    @include sm {
      margin-bottom: 24px;
    }
  }
}

.block {
  padding-bottom: 60px;
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    position: static;
  }
  @include md {
    padding: 24px 20px;
    margin-bottom: 20px;
    border-radius: 24px;
    background: #fff;
  }
}
.profile-col {
  display: flex;
  flex-direction: row;
  gap: 24px;
  row-gap: 0;

  @include sm {
    display: grid;
    grid-template-columns: 1fr;
  }

  .base-input {
    margin-bottom: 24px;
  }

  .secure-block {
    display: none;
  }

  &.secure {
    position: relative;
    border-radius: 10px;
    border: 1px solid $line-light-gray;
    overflow: hidden;

    .secure-block {
      @include desktop_standart;
      display: flex;
      position: absolute;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 30px;
      background-color: #f2f2f266;
      backdrop-filter: blur(10px);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.base-input-md {
  @include md {
    display: none;
  }
}

.small-title {
  @extend %text-standart;
  text-align: left;
  font-weight: 500;
  color: $input-label;
  @include sm {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 106.667% */
  }
}
.expert {
  @include sm {
    display: none;
  }
}

h2 {
  margin-bottom: 30px;

  @include md {
    margin-bottom: 24px;
  }
}
.resume {
  @extend %text-standart;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    transition: 0.3s all ease-in;
    color: #4b4c57;
  }
}
.guide {
  display: flex;
  color: $black;
  font-size: 18px;
  margin: 32px 0;
  position: relative;
  padding: 0;
  justify-content: center;
}
.attachment {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;

  &-item {
    background-color: $bg-light-gray;
    padding: 10px 10px;
    color: #000;
    @include mobile_text;
  }
}
</style>
