// Styles

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/lib/styles/main.css'
import './main.scss'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
    components,
    directives,
    display: {
      thresholds: {
        xs: 375,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1440,
      },
    },
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
