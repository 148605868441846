<template>
  <div class="expert-council">
    <div class="header">
      <div class="title">
        <h2 class="information__header">Экспертные советы</h2>
        <add-button
          class="add button--big-left"
          v-if="isList"
          @click.native="openCompetenceModal"
          >Добавить</add-button
        >
      </div>
      <p v-if="isList" class="header__description">
        В течение 2024 года мы планируем запустить экспертные советы, которые войдут
        лучшие эксперты в своих индустриях и функциях, для решения задач, требующих
        коллегиального решения по самым высоким часовым ставкам
      </p>
    </div>
    <div class="body">
      <div v-if="!isList">
        <info-box-add icon="crown" class="temp-width btn-width">
          <template v-slot:title> Войдите в экспертный совет </template>
          <template v-slot:description>
            В течение 2024 года мы планируем запустить экспертные советы, <br />
            в которые войдут лучшие эксперты в своих индустриях <br />
            и функциях, для решения задач, требующих коллегиального<br />
            решения по самым высоким часовым ставкам<br />
          </template>
          <template v-slot:action>
            <base-button class="action-info btn_fix-w" @click="openCompetenceModal"
              >Подать заявку</base-button
            >
          </template>
        </info-box-add>
      </div>

      <div v-if="isList" class="expert-council__list">
        <expert-council-item
          v-for="item in expertCouncil"
          :key="`council_${item.id}`"
          :status="item.status"
          :industry="item.industry"
          :option="item.option"
          remove
          @delItem="deleteItem(item.id)"
        />
      </div>

      <add-specialization-modal
        ref="add-specialization"
        :is-open="specializationModal"
        :industries-list="industriesList"
        :functions-list="optionsList"
        @update-data="addSpecialization"
        @close="closeSpecializationModal"
        title="Подать заявку в экспертный совет"
        description="Укажите индустрии и функции для вступления в соответствующий экспертный совет"
        btn-name="Подать заявку"
      />
    </div>
    <alert-modal ref="alert"></alert-modal>
  </div>
</template>

<script>
import AddButton from "@/components/ui/button/AddButton";
import InfoBoxAdd from "@/components/ui/InfoBoxAdd";
import BaseButton from "@/components/base/BaseButton";
import PublicationsItem from "@/components/component/PublicationsItem";
import ExpertCouncilItem from "@/components/ui/ExpertCouncilItem";
import AddSpecializationModal from "@/components/modals/AddSpecializationModal";
import { mapGetters } from "vuex";
import AlertModal from "@/components/modals/AlertModal";
export default {
  name: "ExpertCouncil",
  components: {
    AlertModal,
    AddSpecializationModal,
    ExpertCouncilItem,
    PublicationsItem,
    BaseButton,
    InfoBoxAdd,
    AddButton,
  },
  data() {
    return {
      specializationModal: false,
      councilItem: null,
    };
  },
  computed: {
    ...mapGetters({
      industriesList: "registration/industriesList",
      optionsList: "registration/optionsList",
      expertCouncil: "profile/expertCouncil",
    }),
    isList() {
      if (this.expertCouncil?.length) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.getExpertCouncilList();
  },
  methods: {
    closeSpecializationModal() {
      this.$refs["add-specialization"].hideModal();
    },
    openSpecialization(id) {
      const item = this.specializationList.filter((item) => item.id === id);
      this.$refs["add-specialization"].showModal(item);
    },
    addSpecialization(e) {
      console.log(e);

      this.councilItem = {
        industry_id: e.industries,
        option_id: e.options.options,
      };

      console.log(this.councilItem);
      this.sendCouncilRequest(this.councilItem);
      this.$refs["add-specialization"].hideModal();
    },
    openCompetenceModal() {
      this.$refs["add-specialization"].showModal();
      this.isList = true;
    },
    async sendCouncilRequest(payload) {
      try {
        await this.$store.dispatch("profile/sendCouncilRequest", payload);

        this.$refs.alert.show("Заявка успешно подана", "", {
          type: "success",
          nameButton: "Закрыть",
        });

        this.getExpertCouncilList();
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getExpertCouncilList() {
      try {
        await this.$store.dispatch("profile/getExpertCouncilList");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async deleteItem(id) {
      try {
        await this.$store.dispatch("profile/deleteItemCouncil", id);

        this.$refs.alert.show("Заявка успешно удалена", "", {
          type: "success",
          nameButton: "Закрыть",
        });
        this.getExpertCouncilList();
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.information__header {
  @include desktop_h2_norm;
  @include sm {
    margin-bottom: 0px;
  }
}
.expert-council {
  .header {
    margin-bottom: 20px;

    .add {
      width: auto;
      @include sm {
        margin: 25px 0 7px 0;
      }
    }

    &__description {
      @include desktop_standart;
      color: $accent;
      max-width: 500px;

      @include md() {
        display: none;
      }
    }
  }

  &__list {
    margin-top: 30px;
  }
}

.btn_fix-w {
  min-width: 256px;
  max-width: 256px;
}

.title {
  min-height: 54px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include sm {
    display: grid;
  }
}

.btn-width {
  .button.button--default {
    width: 270px;

    @include md() {
      width: auto;
    }
  }
}

.action-info {
  width: auto;
  @include sm {
    width: 100%;
  }
}
</style>
