<template>
  <swiper
    :slidesPerView="'auto'"
    :spaceBetween="30"
    :modules="modules"
    :pagination="{
      dynamicBullets: true,
    }"
    style="--swiper-pagination-color: #090f207f"
  >
    <swiper-slide v-for="(item, idx) in cards" :key="item.expertName">
      <template v-if="isTask">
        <task-card-expert
          :name="item.name"
          :task="item.task"
          :solution="item.solution"
          :description="item.description"
          :period="item.period"
          :payment="item.payment"
          :data-pos="item.position"
          :photo="item.photo"
          :chips="item.chips"
          class="task-card"
        >
        </task-card-expert>
      </template>
      <template v-else>
        <expert-card
          :expert-name="item.expertName"
          :expert-position="item.expertPosition"
          :description="item.description"
          :photo="item.photo"
          :chips="item.chips"
          :lazy="idx > 1"
          class="carousel__item"
        />
      </template>
    </swiper-slide>
  </swiper>
</template>

<script>
import { defineComponent } from "vue";
import ExpertCard from "@/views/startpage/cards/ExpertCard";
import TaskCardExpert from "@/views/startpage/cards/TaskCardExpert";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

export default defineComponent({
  name: "Basic",
  components: {
    Swiper,
    SwiperSlide,
    Pagination,
    ExpertCard,
    TaskCardExpert,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  props: {
    cards: {
      type: Array,
      default: [],
    },
    isTask: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style>
.task-card {
  position: relative;
  display: flex;
  text-align: left;
}
.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex;
}

.swiper-slide {
  width: 350px;
}

.swiper-slide:nth-child(2n) {
  width: 350px;
}

.swiper-slide:nth-child(3n) {
  width: 350px;
}
</style>
