<template>
  <div ref="responseSection">
    <div v-if="load" class="request-response">
      <div v-if="isFinish">
        <base-alert-no-modal
          header="Заказчик завершил поиск, отклики больше не принимаются"
          :size="470"
          type="error"
        >
          <template #button>
            <base-button @click="goHome">Вернуться на главную</base-button>
          </template>
        </base-alert-no-modal>
      </div>
      <div v-else-if="respond">
        <container-splitted class="nav-show">
          <template #nav>
            <customer-block
              hide-description
              :info="filterDict(requestInfo, ['user_name', 'user_company', 'user_photo'])"
            />
          </template>
          <template #nav1>
            <div class="pretty">{{ requestInfo.description }}</div>
          </template>
          <template #nav2>
            <customer-block
              :showUser="false"
              :showLine="true"
              :info="
                filterDict(requestInfo, [
                  'execution_period',
                  'industries',
                  'options',
                  'sub_options',
                  'service_type',
                  'files',
                  'budget',
                  'duration_id',
                ])
              "
            />
          </template>
          <template #body>
            <div class="request-response__block request-response__block--form">
              <h2 class="mt-0 block-header">
                {{ requestInfo.name }}
              </h2>
              <div class="request-response__form">
                <base-input
                  ref="v-comment"
                  type="textarea"
                  v-model="comment"
                  description="Расскажите, почему заказчик должен выбрать вас"
                  placeholder="Опишите в 2-3 тезисах:
- ключевой релевантный опыт (компания, роль, чем занимались)
- перечень кейсов или проектов (не забудьте рассказать про результат)
- дополнительная информация (например, какие инструменты вы используете, или как меняется ваша часовая ставка)"
                  tooltip=" Расскажите в 2-3 тезисах, почему заказчику стоит выбрать именно вас:
- релевантный опыт
- перечень кейсов/проектов
- инструменты/особенности работы"
                  :is-error="v$.comment.$error"
                  :maxTextarea="2000"
                  :error="[
                    {
                      text: 'Поле обязательно для заполнения',
                      status: v$.comment.required.$invalid,
                    },
                    {
                      text: 'Превышен лимит символов',
                      status: v$.comment.maxLength.$invalid,
                    },
                  ]"
                />
                <vue-single-select-rows
                  ref="v-companies"
                  header="Выберите компании"
                  description="Для каких компаний вы уже оказывали подобные услуги?"
                  placeholder="Выберите из списка"
                  class="desired-clients"
                  :items="companiesList"
                  v-model="companies"
                  :new-value="true"
                  :is-scroll="true"
                  :is-error="v$.companies.$error"
                  multi
                  isService
                  searchEmptyText="Добавить новую компанию"
                  :error="[
                    {
                      text: 'Максимальное количество компаний 10',
                      status: v$.companies.maxLength.$invalid,
                    },
                  ]"
                ></vue-single-select-rows>
                <base-multi-file
                  id="12"
                  tmp="3"
                  title="Вложения / резюме"
                  description="Добавьте не более 5 файлов весом до 50мб"
                  @file="updateData"
                  :is-error="v$.file.$error"
                  file-type="*"
                  :error="[
                    {
                      text: 'Неверный тип файла',
                      status: v$.file.checkType.$invalid,
                    },
                    {
                      text: 'Максимальный размер файла - 50мб',
                      status: v$.file.checkSize.$invalid,
                    },
                    {
                      text: 'Добавьте не более 5 файлов',
                      status: v$.file.maxLength.$invalid,
                    },
                  ]"
                />
                <v-row class="mb-3">
                  <v-col md="6" cols="12">
                    <base-input
                      ref="v-cost"
                      description="Ваша часовая ставка"
                      placeholder="Введите сумму"
                      v-model="formattedCost"
                      :is-error="v$.cost.$error"
                      :error="[
                        {
                          text: 'Поле обязательно для заполнения',
                          status: v$.cost.required.$invalid,
                        },
                        {
                          text: 'Некорректная сумма (0 - 2 000 000 000)',
                          status:
                            v$.cost.minValue.$invalid ||
                            v$.cost.maxValue.$invalid ||
                            v$.cost.numeric.$invalid,
                        },
                      ]"
                    />
                  </v-col>
                  <v-col md="6" cols="12">
                    <base-dropdown
                      v-model="tax"
                      title="Налоговый режим"
                      :subDescription="
                        taxName != 'Физ. лицо' ? 'Оплата налога на стороне эксперта' : ''
                      "
                      :list="taxList"
                    />
                  </v-col>
                  <v-col md="6" cols="12">
                    <p class="description">
                      Ставка, которую увидит заказчик
                      <span class="sphere-roles__header-tooltip">
                        <svg-icon name="question" :width="9" :height="14" />
                        <v-tooltip max-width="300" activator="parent" location="end">
                          <p style="white-space: pre-line">
                            Данная ставка включает в себя налоги и сборы, соответствующие
                            вашему налоговому режиму. Учитывайте это при обсуждении
                            финальной ставки с заказчиком
                          </p>
                        </v-tooltip>
                      </span>
                    </p>
                    <p>{{ price }}</p>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="request-response__block">
              <case-list
                class="mb-6"
                ref="caseExperienceList"
                title="Кейсы"
                :cases="cases"
                @update:model-value="(v) => getCheckedСases(v)"
                :is-error="v$.caseList.$error"
                :error="[
                  {
                    text: 'Поле обязательно для заполнения',
                    status: v$.caseList.required.$invalid,
                  },
                ]"
              />

              <work-experience-list
                class="mb-6"
                title="Выберите релевантный для проекта опыт"
                @update:model-value="(v) => getCheckedWorks(v)"
                @update="getSpecialization()"
                :works="[]"
                :specialization="specialization"
                :is-error="emptyWork"
                :error="[
                  {
                    text: 'Поле обязательно для заполнения',
                    status: emptyWork,
                  },
                ]"
              />

              <competence-list
                class="mb-6"
                ref="competenceList"
                title="Выберите релевантные компетенции"
                :competence="userRatedExpertises"
                @update:model-value="(v) => getCheckedExpertise(v)"
                :is-error="emptyExpertise"
                :error="[
                  {
                    text: 'Поле обязательно для заполнения',
                    status: emptyExpertise,
                  },
                ]"
              />

              <team-list
                v-model="userList"
                @update:model-value="(v) => getCheckedUsers(v)"
                title="Работаете в команде?"
                :is-error="v$.userList.$error"
                :error="[
                  {
                    text: 'Максимальное количество сотрудников 20',
                    status: v$.userList.maxLength.$invalid,
                  },
                ]"
              />
            </div>
            <div class="block-header">
              <base-button @click="submit">Откликнуться</base-button>
            </div>
          </template>
        </container-splitted>
      </div>
      <div class="center-block" v-else>
        <customer-block
          v-if="!isFinish"
          special
          :info="requestInfo"
          @respond="changeStatus"
        />
      </div>
      <alert-modal ref="alert" />
    </div>
  </div>
</template>

<script>
import ContainerSplitted from "@/components/layout/ContainerSplitted";
import BaseInput from "@/components/base/BaseInput";
import CustomerBlock from "@/components/component/CustomerBlock";
import BaseButton from "@/components/base/BaseButton";
import BaseFile from "@/components/base/BaseFile";
import AlertModal from "@/components/modals/AlertModal";
import { mapGetters } from "vuex";
import ServicesList from "@/components/component/ServicesList";
import BaseMultiFile from "@/components/base/BaseMultiFile";
import useVuelidate from "@vuelidate/core";
import BaseAlertNoModal from "@/components/base/BaseAlertNoModal";
import InformationBlock from "@/components/ui/InformationBlock";
import BaseDropdown from "@/components/base/BaseDropdown";
import VueSingleSelectRows from "@/components/ui/VueSingleSelectRows";
import useOtherOptions from "@/hooks/service/useOtherOptions";
import TeamList from "@/components/component/TeamList";
import AddButton from "@/components/ui/button/AddButton";
import CaseList from "@/components/component/CaseList";
import ServiceTask from "@/components/ui/ServiceTask";
import AddCompetenceModal from "@/components/modals/AddCompetenceModal";
import AddWorkModal from "@/components/modals/AddWorkModal";
import WorkBlock from "@/components/ui/WorkBlock";
import SvgIcon from "@/components/base/SvgIcon";
import BlockList from "@/components/component/BlockList";
import CompetenceList from "@/components/component/CompetenceList";
import WorkExperienceList from "@/components/component/WorkExperienceList";
import {
  maxLength,
  minLength,
  minValue,
  maxValue,
  required,
  numeric,
} from "@vuelidate/validators";
import { typeFile } from "@/utils/helper";
import { declOfNum } from "@/utils/helper";
import ContactSupportModalHelp from "@/components/modals/ContactSupportModalHelp.vue";
export default {
  name: "ResponseRequestPage",
  components: {
    InformationBlock,
    ServiceTask,
    AddButton,
    BaseAlertNoModal,
    AddWorkModal,
    BaseMultiFile,
    ServicesList,
    AlertModal,
    BaseFile,
    BaseButton,
    CustomerBlock,
    BaseInput,
    ContainerSplitted,
    BaseDropdown,
    VueSingleSelectRows,
    AddCompetenceModal,
    TeamList,
    CaseList,
    WorkBlock,
    SvgIcon,
    BlockList,
    CompetenceList,
    WorkExperienceList,
  },
  setup: (props) => {
    const v$ = useVuelidate({ $scope: false });
    const otherOptions = useOtherOptions({});
    return {
      v$,
      ...otherOptions,
    };
  },
  data() {
    return {
      cost: "",
      formattedCost: "",
      file: [],
      userList: [],
      newUserList: [],
      expertiseList: [],
      worksList: [],
      caseList: [],
      comment: "",
      load: false,
      isAddCompetenceModal: false,
      respond: false,
      isFinish: true,
      isCreate: false,
      current_params: null,
      top: 0,
      bottom: 0,
      delta: 0,
      scrollPosition: null,
      section_percent: 0,
      ut: null,
      exp: [],
      works: [],
      tax: "1",
      taxList: [],
      currentExpPage: 1,
      expPerPage: 6,
      expLength: 0,
      addSpecializationList: [],
      currentWorkPage: 1,
      worksPerPage: 3,
      worksLength: 0,
      emptyWork: false,
      emptyExpertise: false,
    };
  },
  watch: {
    formattedCost(val) {
      this.formattedCost = this.numberWithSpaces(val);
      this.cost = Number(this.formattedCost.replace(/\s/g, ""));
    },
    specialization(newVal, oldVal) {
      const maxPage = Math.ceil(newVal.length / this.currentWorkPage);
      if (this.currentWorkPage > maxPage) {
        this.currentWorkPage = maxPage;
      }
    },
  },
  computed: {
    ...mapGetters({
      cases: "service/cases",
      specialization: "service/specialization",
      requestManageInfo: "request/requestManageInfo",
      requestInfo: "request/requestInfo",
      companiesList: "company/companies",
      expertises: "service/expertises",
      taxes: "profile/taxes",
      user: "auth/user",
      userExpertise: "profile/userExpertise",
    }),
    price() {
      if (this.cost)
        return (
          this.numberWithSpaces(
            Math.round(
              this.taxList.filter((el) => el.id == this.tax)[0].percent * this.cost
            )
          ) + " ₽"
        );
      return "0 ₽";
    },

    userRatedExpertises() {
      let merged = this.userExpertise.expertises.map((itm) => ({
        ...this.expertises.find((item) => item.id === itm.expertise_id && item),
        ...itm,
      }));
      merged = merged.map((v) => ({ ...v, ...{ n_rating: v.rating_count } }));
      return {
        expertises: merged || [],
        institutions: this.userExpertise.institutions,
      };
    },
    taxName() {
      return this.taxList.filter((el) => el.id == this.tax)[0]["name"];
    },
  },
  async created() {
    this.isRespond();
    this.getRequestInfo();
    this.getServiceTypes();
    this.getCompany();
    this.getCompetenceList();
    this.getTaxes();
    this.getCasesList();
    this.getUserExpertise();
    await this.getSpecialization();
    await this.getServicesExpertises();
    this.exp = JSON.parse(JSON.stringify(this.expertises));
    this.works = JSON.parse(JSON.stringify(this.specialization));
  },
  mounted() {},
  unmounted() {},

  methods: {
    async getServiceTypes() {
      await this.$store.dispatch("service/getServiceTypes");
    },
    filterDict(raw, allowed) {
      return Object.keys(raw)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {});
    },
    removeDuplicates(array) {
      return Array.from(new Set(array));
    },

    async getSpecialization() {
      try {
        await this.$store.dispatch("service/getSpecialization");
        this.works = JSON.parse(JSON.stringify(this.specialization));
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getServicesExpertises() {
      try {
        await this.$store.dispatch("service/getServicesExpertises", { user: this.user });
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    counterLick(count) {
      if (count != 0) {
        return `${count == null ? 0 : count} ${declOfNum(count, [
          "оценка",
          "оценки",
          "оценок",
        ])}`;
      }
      return false;
    },
    getCheckedСases(v) {
      this.caseList = v;
    },
    getCheckedUsers(v) {
      this.userList = v.filter((el) => !el.newValue).map((el) => el.id);
      this.newUserList = v.filter((el) => el.newValue).map((el) => el.id);
    },
    getCheckedExpertise(v) {
      this.expertiseList = v;
    },
    getCheckedWorks(v) {
      this.worksList = v;
    },
    async getUserExpertise() {
      await this.$store.dispatch("profile/getUserExpertise");
    },
    numberWithSpaces(x) {
      return x
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    autoFocus(validate) {
      if (validate.$invalid) {
        for (let key in Object.keys(validate)) {
          const input = Object.keys(validate)[key];
          if (!input.includes("$")) {
            if (validate[input].$error) {
              try {
                this.$refs[`v-${input}`].focus();
                return true;
              } catch (e) {
                console.log(`v-${input}`);
                throw e;
              }
            }
          }
        }
      }
      return false;
    },
    async getRequestInfo() {
      try {
        await this.$store.dispatch("request/getRequestInfoShort", this.$route.params.id);
        this.isFinish = false;
      } catch (e) {
        if (e === 403) {
          this.isFinish = true;
        } else {
          this.$refs.alert.show(this.$t("alert.error"), e, {
            type: "error",
            nameButton: "Ок",
          });
        }
      } finally {
        this.load = true;
      }
    },
    goHome() {
      this.$router.push({ name: "StartPage" });
    },
    async updateData(e) {
      this.file = e;
      const result = await this.v$.file.$validate();
      if (!result) {
        this.autoFocus(this.v$);
        return;
      }
    },
    async submit() {
      const result = await this.v$.$validate();
      this.emptyExpertise = this.expertiseList.length == 0;
      const resultCheck = this.emptyWork | this.emptyExpertise;
      if (!result | resultCheck) {
        return;
      }
      const formData = new FormData();

      let companies = this.companies
        .filter((v) => v.newValue === false && v.value === true)
        .map((value) => value.id);
      let new_companies = this.companies
        .filter((v) => v.newValue === true && v.value === true)
        .map((value) => {
          return {
            name: value.name,
          };
        });
      let new_companies_ids = [];
      for (let i = 0; i < new_companies.length; i++) {
        let response = await this.addCompany(new_companies[i]);
        new_companies_ids.push(response);
      }
      const payload = {
        demand_id: +this.$route.params.id,
        companies: [...companies, ...new_companies_ids],
        salary: this.cost,
        description: this.comment,
        cases: this.caseList,
        users: this.userList,
        invite_users: this.newUserList,
        expertises: this.expertiseList,
        options: this.worksList,
        tax_id: this.tax,
      };
      if (this.file) {
        payload.attachments = [...this.file];
      }
      for (let key in payload) {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((item, idx) => {
            formData.append(`${key}[${idx}]`, item);
          });
        } else {
          formData.append(`${key}`, payload[key]);
        }
      }
      try {
        await this.$store.dispatch("request/sendResponse", formData);
        this.isCreate = true;
        window.scrollTo(0, 0);
        await this.getAllRespond();
        this.$refs.alert.show(
          "Благодарим за отклик!",
          "Мы уже передали его клиенту. Процесс выбора длится от 3 до 7 дней. Если заказчик решит подробнее обсудить с вами детали проекта, он свяжется с вами самостоятельно.",
          {
            type: "success",
            nameButton: "Закрыть",
            redirectPath: "/projects/response",
          }
        );
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    isPreviouslyCheckedWork(item) {
      item = item.option_id[0];
      return this.worksList.filter((el) => el == item).length > 0;
    },
    isPreviouslyCheckedExpertise(option) {
      option = option.id;
      return this.expertiseList.filter((el) => el == option).length > 0;
    },
    async getAllRespond() {
      try {
        await this.$store.dispatch("request/getAllRespond");
      } catch (e) {
        console.log("getAllRespond ERROR");
      }
    },
    changeStatus() {
      this.respond = true;
    },
    isRespond() {
      this.respond = this.$route.query.respond === "true";
    },
    checkType(e) {
      if (e.length) {
        let counter = 0;
        e.forEach((item) => {
          if (typeFile.includes(item.type)) counter++;
        });
        return e.length == counter;
      } else {
        return true;
      }
    },
    checkSize(e) {
      if (e.length) {
        let counter = 0;
        e.forEach((item) => {
          if (item.size < 50 * 1024 * 1024) {
            counter++;
          }
        });
        return e.length == counter;
      } else {
        return true;
      }
    },
    async getCompetenceList() {
      try {
        await this.$store.dispatch("profile/getCompetenceFlat");
        this.exp = await JSON.parse(JSON.stringify(this.expertises));
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getTaxes() {
      try {
        await this.$store.dispatch("profile/getTaxes");
        this.taxList = await JSON.parse(JSON.stringify(this.taxes));
        this.tax = 1;
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async addCompany(data) {
      let response = await this.$store.dispatch("company/addCompany", data);
      return response.id;
    },
    async getCompany() {
      await this.$store.dispatch("company/getCompany", null);
    },
    async getCasesList() {
      try {
        await this.$store.dispatch("service/getCasesList");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getUserExpertise() {
      try {
        await this.$store.dispatch("profile/getUserExpertise");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
  },
  validations() {
    return {
      cost: {
        required,
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(2000000000),
      },
      caseList: {
        required,
      },
      comment: {
        required,
        maxLength: maxLength(2000),
      },
      userList: {
        maxLength: maxLength(20),
      },
      companies: {
        maxLength: maxLength(10),
      },
      file: {
        checkType: this.checkType,
        checkSize: this.checkSize,
        maxLength: maxLength(5),
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.center-block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 12px;
}

.block-header {
  @include desktop_h1;
  margin-bottom: 30px;
  margin-top: 45px;

  @include sm() {
    margin-bottom: 24px;
    margin-top: 48px;
  }
}
.description {
  display: flex;
  @include desktop_description;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 500;
  color: $input-label;
}
.custom-h1 {
  font-size: 32px !important;
}
.add {
  width: auto;

  @include sm {
    margin: 25px 0 7px 0;
  }
}
.request-response {
  &__form {
    display: grid;
    gap: 9px;
  }
}

.request-response__block {
  &--form {
    @include md {
      padding-top: 20px;
    }

    .block-header {
      @include md {
        margin-bottom: 24px;
      }
    }
  }
}
.input-error {
  color: #ef693f;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}
.base-file__wrapper {
  margin-bottom: 15px;
}
.title {
  padding-bottom: 20px;
  min-height: 54px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0 0 0;

  @include md {
    margin: 42px 0 0 0;
  }

  @include sm {
    display: grid;
    margin: 48px 0 0 0;
  }
}
.pretty {
  white-space: pre-wrap;
  overflow-y: scroll;
  max-height: 300px;
  @include desktop_standart;
}
.service {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include md {
    grid-template-columns: 1fr;
  }
}
.cont {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
}
.customer-block {
  margin-bottom: 16px;
}

.sphere-roles {
  &__description {
    @extend %text-standart;
    max-width: 315px;
    padding-bottom: 10px;
  }

  .multi-select-rows {
    margin-bottom: 12px;
  }

  &__header {
    position: relative;
    margin-bottom: 20px;

    .information__header {
      margin-right: 10px;
      margin-bottom: 20px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &-tooltip {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-left: auto;
      margin-bottom: 10px;
      cursor: pointer;
      top: 3px;
      right: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #e6e6e6;
    }
  }
}
</style>
