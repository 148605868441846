<template>
  <div
    class="grid-block"
    :class="{ main: isEqualMain, equal: isEqual, reverse: isReverse, first: isFirst }"
  >
    <div class="title">
      <slot name="title" />
    </div>
    <div class="body">
      <slot name="body" />
    </div>
  </div>
</template>
<script>
export default {
  name: "TwoColsGrig",
  props: {
    isEqual: {
      type: Boolean,
      default: false,
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isEqualMain: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.grid-block {
  max-width: 1440px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  align-items: top;
  width: 100%;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 30px;
  padding-right: 30px;
  @include md {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &.equal {
    grid-template-columns: 1fr 1fr;
  }

  &.main {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0px;
    @media screen and (max-width: 1199px) {
      grid-template-columns: 0.5fr 1fr;
    }
  }

  &.first {
    margin-top: 0px;
  }

  &.reverse {
    grid-template-columns: 2fr 1fr;
  }
  @include md {
    padding-right: 0px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  @include sm {
    align-items: center;
  }
}
.body {
  width: 100%;
  align-items: center;
  flex-direction: column;

  @include md {
    display: flex;
    align-items: center;
  }
}
.title {
  margin-bottom: 60px;
  height: fit-content;
  @include md {
    width: 100%;
    margin-bottom: 0px;
    padding-top: 0px;
    align-items: left !important;
  }
}
</style>
