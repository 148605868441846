<template>
  <div class="avatar-cropper">
    <base-modal class="avatar-cropper-overlay"
                :class="{ 'avatar-cropper-overlay-inline': inline }" :is-open="dataUrl != null" @close="cancel">
      <div class="avatar-cropper-container">
        <div class="avatar-cropper-image-container">
          <img
              ref="img"
              :src="dataUrl"
              alt
              @load.stop="createCropper"
              @error="onImgElementError"
          />
          <div class="avatar-cropper-rotate">
            <svg-icon @click="rotate" name="profile-photo-rotate"/>
          </div>
        </div>
        <sized-box height="20"></sized-box>
        <div class="avatar-cropper-footer">
          <base-button @click.stop.prevent="submit" class="avatar-cropper-btn">{{ $t('btn.save') }}</base-button>
        </div>
      </div>
    </base-modal>
    <input
        v-if="!file"
        :accept="cleanedMimes"
        :capture="capture"
        class="avatar-cropper-img-input"
        ref="input"
        type="file"
        @change="onFileInputChange"
    />
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import mime from 'mime/lite'
import { defineComponent } from 'vue'
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";
import SizedBox from "@/components/ui/SizedBox";
import SvgIcon from "@/components/base/SvgIcon";

export default defineComponent({
  name: 'AvatarCropperModal',
  components: {SvgIcon, SizedBox, BaseButton, BaseModal},
  emits: [
    'update:modelValue',
    'submit',
    'error',
    'cancel',
    'changed',
    'uploading',
    'completed',
    'uploaded',
  ],

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    file: {
      type: File,
    },

    uploadHandler: {
      type: Function,
    },

    uploadUrl: {
      type: String,
    },

    requestOptions: {
      type: Object,
      default() {
        return {
          method: 'POST',
        }
      },
    },

    uploadFileField: {
      type: String,
      default: 'file',
    },

    uploadFileName: {
      type: [String, Function],
    },

    uploadFormData: {
      type: FormData,
      default() {
        return new FormData()
      },
    },

    cropperOptions: {
      type: Object,
      default() {
        return {
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false,
        }
      },
    },

    outputOptions: {
      type: Object,
    },

    outputMime: {
      type: String,
      default: null,
    },

    outputQuality: {
      type: Number,
      default: 0.9,
    },

    mimes: {
      type: String,
      default: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon',
    },

    capture: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cropper: undefined,
      dataUrl: undefined,
      fileName: undefined,
      mimeType: undefined,
    }
  },

  computed: {
    cleanedMimes() {
      if (!this.mimes)
        throw new Error('vue-avatar-cropper: mimes prop cannot be empty')

      return this.mimes.trim().toLowerCase()
    },
  },

  watch: {
    modelValue(value) {
      if (!value) return

      if (this.file) {
        this.onFileChange(this.file)
      } else {
        this.pickImage()
      }

      this.$emit('update:modelValue', false)
    },
  },

  mounted() {
    this.$emit('update:modelValue', false)
  },

  methods: {
    rotate() {
      this.cropper.rotate(-90);
    },
    destroy() {
      if (this.cropper) this.cropper.destroy()

      if (this.$refs.input) this.$refs.input.value = ''

      this.dataUrl = undefined
    },
    getRoundedCanvas(sourceCanvas) {
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      let width = sourceCanvas.width;
      let height = sourceCanvas.height;
      let need = 1920; // размер до которого уменьшаем изображение

      // определяем большую сторону и уменьшаем до нужного размера
      if (width > need && height > need) {
        let coeff;
        if (width >= height) {
          coeff = width / need;
        } else {
          coeff = height / need;
        }

        coeff = +coeff.toFixed(2);
        height = Math.floor(height / coeff);
        width = Math.floor(width / coeff);

        console.log(width)
        console.log(height)
      }

      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = 'destination-in';
      context.beginPath();
      context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
      context.fill();
      return canvas;
    },
    submit() {
      this.$emit('submit')
      if (this.uploadUrl) {
        this.uploadImage()
      } else if (this.uploadHandler) {
        this.uploadHandler(this.getRoundedCanvas(this.cropper.getCroppedCanvas()))
      } else {
        this.$emit('error', {
          type: 'user',
          message: 'No upload handler found',
        })
      }

      this.destroy()
    },

    cancel() {
      this.$emit('cancel')
      this.destroy()
    },

    onImgElementError() {
      this.$emit('error', {
        type: 'load',
        message: 'File loading failed',
      })
      this.destroy()
    },

    pickImage() {
      if (this.$refs.input) this.$refs.input.click()
    },

    onFileChange(file) {
      if (file.size > 20971520) {
        console.log('ошибка')

        this.$emit('error', {
          type: 'user',
          message: 'Слишком большой файл',
        })

        this.$refs.input.value = ''
        return
      }

      if (this.cleanedMimes === 'image/*') {
        if (file.type.split('/')[0] !== 'image') {
          this.$emit('error', {
            type: 'user',
            message: 'File type not correct',
          })
          return
        }
      } else if (this.cleanedMimes) {
        const correctType = this.cleanedMimes
            .split(', ')
            .find((mime) => mime === file.type)

        if (!correctType) {
          this.$emit('error', {
            type: 'user',
            message: 'File type not correct',
          })
          return
        }
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        this.dataUrl = e.target.result
      }

      reader.readAsDataURL(file)

      this.fileName = file.name || 'unknown'
      this.mimeType = file.type

      this.$emit('changed', {
        file,
        reader,
      })
    },

    onFileInputChange(e) {
      console.log("event", e.target.files[0])
      if (!e.target.files || !e.target.files[0]) return

      this.onFileChange(e.target.files[0])
    },

    createCropper() {
      this.cropper = new Cropper(this.$refs.img, this.cropperOptions)
    },

    getFilename(blob) {
      const extension = mime.getExtension(blob.type)

      // Default logic
      if (!this.uploadFileName) {
        let actualFilename = this.fileName

        const filenameParts = this.fileName.split('.')
        if (filenameParts.length > 1)
          actualFilename = filenameParts.slice(0, -1).join('.')

        return `${actualFilename}.${extension}`
      }

      // User provided filename
      if (typeof this.uploadFileName === 'string') return this.uploadFileName

      if (typeof this.uploadFileName === 'function')
        return this.uploadFileName({
          filename: this.fileName,
          mime: blob.type,
          extension,
        })

      return `unknown.${extension}`
    },

    uploadImage() {
      this.cropper.getCroppedCanvas(this.outputOptions).toBlob(
          async (blob) => {
            const form = new FormData()

            for (const [key, value] of this.uploadFormData.entries()) {
              form.append(key, value)
            }

            form.append(this.uploadFileField, blob, this.getFilename(blob))

            const requestOptions = Object.assign(
                {
                  body: form,
                },
                this.requestOptions,
            )

            const request = new Request(this.uploadUrl, requestOptions)

            const reqPromise = fetch(request)

            this.$emit('uploading', {
              form,
              request,
              response: reqPromise,
            })

            const response = await reqPromise

            this.$emit('completed', {
              form,
              request,
              response,
            })

            if (response.ok) {
              this.$emit('uploaded', {
                form,
                request,
                response,
              })
            } else {
              this.$emit('error', {
                type: 'upload',
                message: 'Image upload fail',
                context: {
                  request,
                  response,
                },
              })
            }
          },
          this.outputMime || this.mimeType,
          this.outputQuality,
      )
    },
  },
})
</script>

<style lang="scss">
.cropper {
  &-bg {
    background-repeat: repeat;
  }
  &-container {
    overflow: hidden;
    border-radius: 12px;
  }
  &-modal {
    background-color: #EEEEEE;
    opacity: 0.8;
  }
  &-crop-box, &-view-box {
    border-radius: 50%;
  }
  &-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
  }
  &-face {
    background-color:inherit !important;
  }

  &-dashed, &-line {
    display:none !important;
  }
  &-view-box {
    outline:inherit !important;
  }

  &-point.point-se {
    top: calc(85% + 1px);
    right: 14%;
  }
  &-point.point-sw {
    top: calc(85% + 1px);
    left: 14%;
  }
  &-point.point-nw {
    top: calc(15% - 5px);
    left: 14%;
  }
  &-point.point-ne {
    top: calc(15% - 5px);
    right: 14%;
  }
}
.avatar-cropper {
  &-rotate {
    position: absolute;
    bottom: 12px;
    right: 12px;
    cursor: pointer;
  }
  .avatar-cropper-overlay {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }

  .avatar-cropper-overlay-inline {
    position: initial;
  }

  .avatar-cropper-img-input {
    display: none;
  }

  .avatar-cropper-close {
    float: right;
    padding: 20px;
    font-size: 3rem;
    color: #fff;
    font-weight: 100;
    text-shadow: 0 1px rgba(40, 40, 40, 0.3);
  }

  .avatar-cropper-container {
    .avatar-cropper-image-container {
      position: relative;
      max-width: 400px;
      height: 300px;
    }

    img {
      max-width: 100%;
      height: 100%;
    }

    .avatar-cropper-footer {
      display: flex;
      align-items: stretch;
      align-content: stretch;
      justify-content: space-between;
    }
  }
}
</style>
