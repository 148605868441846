<template>
 <div>
   <community-block title="Образование и карьера" subtitle="(Alumni)" type="alumni" />
 </div>
</template>

<script>
import CommunityBlock from "@/components/component/CommunityBlock";
export default {
  name: "CommunityAlumniPage",
  components: {CommunityBlock},
}
</script>

<style scoped lang="scss">

</style>
