<template>
  <div class="multi-select-rows" :class="{ error: isError }">
    <p v-if="description" class="description">
      {{ description }}
      <span v-if="descriptionStatus" class="multi-select-rows__description-status">{{
        descriptionStatus
      }}</span>
    </p>
    <ul v-if="multi" class="flex list-reset flex-wrap text-black">
      <li
        v-for="(option, idx) in selectedOptions"
        :key="idx"
        class="flex justify-between content-center list-item"
      >
        <slot name="pill" v-bind="{ option, idx }">
          <span class="pill">
            <span class="text-sm" v-text="getOptionDescription(option)"></span>
            <span
              class="pl-2 text-grey-darker mt-px icons"
              @click.stop="deleteItem(option)"
            >
              <svg
                class="text-sm w-3 h-3 fill-current"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="21.4258"
                  y="4.09961"
                  width="3.50036"
                  height="24.5025"
                  rx="1.75018"
                  transform="rotate(45 21.4258 4.09961)"
                  fill="#4B4C57"
                />
                <rect
                  x="23.8984"
                  y="21.4258"
                  width="3.50036"
                  height="24.5025"
                  rx="1.75018"
                  transform="rotate(135 23.8984 21.4258)"
                  fill="#4B4C57"
                />
              </svg>
            </span>
          </span>
        </slot>
      </li>
      <li class="flex-1">
        <div class="input-container">
          <div
            type="text"
            class="box-size w-full inline h-full outline-none border-none leading-tight placeholder"
            @click="isOpenModalDesiredClients = true"
          >
            {{ placeholder }}
          </div>
          <div class="btn">
            <svg-icon width="22" height="22" name="arrow-down" />
          </div>
        </div>
      </li>
    </ul>
    <ul v-else class="list-reset flex-wrap text-black">
      <li class="flex-1" @click="isOpenModalDesiredClients = true">
        <div class="input-container">
          <div
            type="text"
            class="box-size w-full inline h-full outline-none border-none leading-tight multi-select-rows__value"
            :class="{ placeholder: !selectedOptions.length }"
          >
            {{ selectedOptions.length ? selectedOptions[0].name : placeholder }}
          </div>
          <div class="btn">
            <svg-icon width="22" height="22" name="arrow-down" />
          </div>
        </div>
      </li>
    </ul>
    <desired-single-clients-modal
      ref="desired-single"
      :new-value="newValue"
      :header="header"
      :multi="multi"
      :placeholder="placeholder"
      :subtitle="subtitle"
      :is-open="isOpenModalDesiredClients"
      :is-scroll="isScroll"
      @close="isOpenModalDesiredClients = false"
      v-model="dataOptions"
      :items="items"
      :searchEvent="searchEvent"
      @update:model-value="updateDataOptions"
      :search-empty-text="searchEmptyText"
      is-service
    />
    <div v-if="isError" class="input-error">{{ errorString }}</div>
    <div v-if="subDescription && !isError" class="sub-description">
      {{ subDescription }}
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/base/VueTelInput/Checkbox";
import BaseInput from "@/components/base/BaseInput";
import SvgIcon from "@/components/base/SvgIcon";
import BaseButton from "@/components/base/BaseButton";
import DesiredSingleClientsModal from "@/components/modals/DesiredSingleClientsModal";

export default {
  name: "VueSingleSelectRows",
  data() {
    return {
      searchText: "",
      isOpenModalDesiredClients: false,
      autoIncrement: 1,
    };
  },
  components: { DesiredSingleClientsModal, Checkbox, BaseInput, SvgIcon, BaseButton },
  props: {
    descriptionStatus: {
      type: String,
    },
    modelValue: {},
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    searchEvent: {
      type: Function,
      default() {
        return () => {};
      },
    },
    /**
     * Дополнительный контент, отображаемый под заголовком
     */
    subtitle: {
      type: String,
      default: "",
    },
    /**
     * Текст заголовка
     */
    header: {
      type: String,
      default: "",
    },
    /**
     * Включает виртуальный скролл
     */
    isScroll: {
      default: false,
    },
    /**
     * Создать новую запись
     */
    newValue: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => "",
    },
    description: {
      type: String,
      default: "",
    },
    subDescription: {
      type: String,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    notValueToFullSelect: {
      type: Boolean,
      default: false,
    },
    getOptionDescription: {
      type: Function,
      default(option) {
        return option["name"];
      },
    },
    searchEmptyText: {
      type: String,
      default: "",
    },
    multi: {
      type: Boolean,
      default: false,
    },
    isService: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateDataOptions(e) {
      console.log(e, this.dataOptions);
      this.dataOptions = e;
    },
    deleteItem(item) {
      console.log("item", item);
      this.$refs["desired-single"].removeItem([item]);
    },
  },
  watch: {
    searchText(curr, prev) {
      if (curr === prev) {
        return;
      }
      this.pointer = -1;
    },
  },
  computed: {
    dataOptions: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
    selectedOptions() {
      if (this.dataOptions != null)
        return this.dataOptions.filter((option, index) => option.value);
      else return [];
    },
    isRequired() {
      if (!this.required) {
        return "";
      }

      if (this.selectedOptions.length) {
        return "";
      }

      return "required";
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.multi-select-rows {
  margin-bottom: 18px;

  .list-item {
    padding: 4px 0 4px 4px;
  }
  .list-reset {
    margin-bottom: 12px;
  }
  .description {
    @include desktop_description;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 500;
    color: $input-label;
    display: flex;
  }

  .sub-description {
    @extend %text-small;
    color: $text-gray;
    margin-top: 10px;
  }

  .multi-select-rows__value {
    color: #000;
    @include desktop_standart;
    white-space: nowrap;
  }
  .placeholder {
    @include desktop_standart;
    color: $text-gray;
    opacity: 0.5;
  }
  &.error {
    .list-reset {
      border: 1px solid #ef693f;
    }

    .input-error {
      color: #ef693f;
      font-size: 16px;
      line-height: 20px;
      margin-top: 8px;
    }
  }
  &__description-status {
    @include desktop_additional;
    margin-left: auto;
    color: $text-gray;
  }
}
.dialog-checkbox {
  width: 100%;
  background: white;
  padding: 32px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  position: absolute;
  margin-top: 12px;
  max-height: 500px;
  overflow-y: scroll;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    & {
      width: 64%;
    }
  }
  &__item-dialog {
    margin-top: 22px;
  }
  &__button {
    margin-top: 30px;
  }
  &__item-add-custom {
    span {
      color: $green;
    }
  }
}
.industry-full {
  .dialog-checkbox {
    width: 100%;
  }
}
.input-container {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 6px 20px;
  height: 56px;

  .box-size {
    overflow: hidden;
    max-height: 100%;
    white-space: normal;
    text-overflow: ellipsis;
  }

  .btn {
    transform: rotate(-90deg);
  }
}

.list-reset {
  list-style: none;
  padding: 0;
}
.overflow-auto {
  overflow: auto;
}
.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.text-black {
  color: #1a1a1a;
  background: #f6f6f6;
  border-radius: 12px;
}
.text-grey-darker {
  color: #606f7b;
}
.leading-tight {
  line-height: 1.25;
}
.text-sm {
  @include mobile_text;
}
.w-full {
  width: 100%;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-between {
  justify-content: space-between;
}
.content-center {
  align-content: center;
}
.border-none {
  border: none;
}
.pin-r {
  right: 0;
}
.pin-y {
  top: 0;
  bottom: 0;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.items-center {
  align-items: center;
}
.p-1 {
  padding: 0.25em;
}
.pl-2 {
  padding-left: 0.5em;
}
.pr-1 {
  padding-right: 0.25em;
}

.px-2 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.m-1 {
  margin: 0.25em;
}
.mt-1 {
  margin-top: 0.25em;
}
.ml-1 {
  margin-left: 0.25em;
}
.mt-px {
  margin-top: 1px;
}
.mb-0 {
  margin-bottom: 0;
}
.leading-tight {
  line-height: 1.25;
}
.text-left {
  text-align: left;
}
.w-full {
  width: 100%;
}
.shadow {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.w-3 {
  width: 0.75em;
}
.h-3 {
  height: 0.75em;
}
.fill-current {
  fill: currentColor;
}
.hover\:no-underline:hover {
  text-decoration: none;
}
.outline-none {
  outline: 0;
}
.hover\:outline-none {
  outline: 0;
}
.hover\:bg-grey-lighter:hover {
  background-color: #dae1e7;
}
.shadow-md {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.focus\:shadow-outline:focus {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
  box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
}
.rounded {
  border-radius: 0.25em;
}
.icons svg {
  width: 0.75em;
  height: 0.75em;
}
.multi-select-wrapper {
}
.required {
  _color: #721c24;
  _background-color: #f8d7da;
  border-color: #f5c6cb;
}
.cursor-pointer {
  cursor: pointer;
}
.dropdown {
  border-radius: 12px;
  line-height: 1.25;
  text-align: left;
  display: inline;
  width: 99.8%;
  & > li {
    @extend %text-standart;
    background-color: $bg-light-gray;
    border-top: 1px solid $white;
    cursor: pointer;
    padding: 13px 20px;
  }
}
.active {
  background-color: #dae1e7;
}
.hidden {
  display: none;
}
.appearance-none {
  appearance: none;
}
input {
  overflow: visible;
}
.select-wrapper,
.box-size {
  box-sizing: border-box;
}
.pill {
  padding: 11px 12px 11px 15px;
  cursor: pointer;
  border-radius: 8px;
  line-height: 1.5;
  letter-spacing: -0.05em;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
}
.pill:hover {
  background-color: #f1f5f8;
}

.vue-multi-select-rows__input {
  height: 42px;
  width: 100%;
}

input::placeholder {
  //background: url(../assets/svg/star.svg) no-repeat;
}
</style>
