<template>
  <div>
    <div class="info-box">
      <slot name="icon" />
      <div class="body">
        <p class="info-box__title">{{ title }}</p>
        <span class="info-box__description" v-if="!parseStr">{{ description }}</span>
        <span
          class="info-box__description"
          v-else
          v-for="(item, index) in parseDescription"
          :key="index"
          >{{ item }}<br
        /></span>
      </div>
      <span class="info-box__remove" v-if="remove">
        <svg-icon :width="25" :height="25" name="service-dell"></svg-icon>
      </span>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import AlertModal from "@/components/modals/AlertModal";
export default {
  name: "InfoBox",
  components: { AlertModal, SvgIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    remove: {
      type: Boolean,
      default: false,
    },
    viewModal: {
      type: Boolean,
      default: true,
    },
    customHeight: {
      type: Boolean,
      default: false,
    },
    parseStr: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parseDescription() {
      if (this.parseStr && this.description) {
        return this.description.split("\n");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.info-box {
  display: flex;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 15px 19px;
  gap: 16px;
  cursor: pointer;

  .custom-height {
    height: 90px;
  }

  &__remove {
    align-self: center;
    opacity: 0;
  }

  &:hover {
    .info-box__remove {
      opacity: 1;
    }
  }

  svg {
    flex-shrink: 0;
  }
}
.body {
  margin-top: -4px;
  p {
    font-weight: 500;
    font-size: 18px;
  }
  span {
    @extend %desktop_additional;
  }
}

.disabled {
  .info-box {
    cursor: default;
    &__description,
    &__title {
      color: $text-gray;
    }
  }

  .svg-icon {
    opacity: 0.6;
  }
}

.big-info-block {
  .info-box {
    &__title {
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &__description {
      @extend %text-standart;
      color: #a0a0a0;
    }
  }
}
</style>
