<template>
    <div :class="[classes.wrapper]" ref="vuesingleselect">
        <select multiple class="hidden" :name="name">
            <option v-for="(option, idx) in selectedOptions" :key="idx" :value="getOptionValue(option)">
                {{getOptionValue(option)}}
            </option>
        </select>
        <div class="relative text-left" :class="[classes.searchWrapper]">
            <div class="rounded bordered border-grey hover:border-blue bg-white" :class="[isRequired]">
                <ul class="flex list-reset flex-wrap py-px pb-1 pr-1 m-0 text-black">
                    <li v-for="(option, idx) in selectedOptions" :key="idx"
                        @click="seedSearchText"
                               class="mt-1 ml-1 mb-0 flex justify-between content-center"
                    >
                        <slot name="pill" v-bind="{option,idx}">
                            <span :class="[classes.pill]">
                                <span class="text-sm" v-text="getOptionDescription(option)"></span>
                                <span class="pl-2 text-grey-darker mt-px icons" @click.stop="removeOption(idx)">
                                  <svg class="text-sm w-3 h-3 fill-current"  width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="21.4258" y="4.09961" width="3.50036" height="24.5025" rx="1.75018" transform="rotate(45 21.4258 4.09961)" fill="#4B4C57"/>
                                    <rect x="23.8984" y="21.4258" width="3.50036" height="24.5025" rx="1.75018" transform="rotate(135 23.8984 21.4258)" fill="#4B4C57"/>
                                  </svg>
                                </span>
                            </span>
                        </slot>
                    </li>
                    <li class="mt-1 ml-1 mb-0 flex-1">
                      <div class="registration-form__function" @click="seedSearchText">
                        <input  type="text" ref="search"
                               style="min-width: 100px;"
                               class="box-size w-full p-1 inline mr-1 h-full outline-none border-none leading-tight"
                               :class="[classes.searchInput]"
                               @keyup.enter="setPossibleOption"
                               @keyup.down="movePointerDown"
                               @keydown.tab.stop="closeOut"
                               @keydown.esc.stop="searchText = null"
                               @keyup.up="movePointerUp"
                               @keyup.delete="popSelectedOption"
                               autocomplete="off"
                               :placeholder="placeholder"
                               :required="required"
                               v-model="searchText"
                        >
                        <div class="registration-form__function-plus">
                          <svg-icon name="plus"/>
                        </div>
                      </div>
                    </li>
                </ul>
            </div>
            <ul tabindex="-1" ref="options" v-show="matchingOptions"
                :style="{'max-height': maxHeight}" style="z-index: 100;"
                :class=[classes.dropdown]
                class="absolute w-full overflow-auto appearance-none mt-px text-left list-reset"
            >
                <li tabindex="-1"
                    v-for="(option, idx) in matchingOptions" :key="idx"
                    :class="idx === pointer ? classes.active : ''"
                    class="cursor-pointer outline-none"
                    @blur="handleClickOutside($event)"
                    @mouseover="setPointerIdx(idx)"
                    @keyup.enter="setOption(option)"
                    @keyup.up="movePointerUp()"
                    @keyup.down="movePointerDown()"
                    @click.prevent="setOption(option)"
                >
                    <slot name="option" v-bind="{option,idx}">
                        {{ getOptionDescription(option) }}
                    </slot>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import SvgIcon from "@/components/base/SvgIcon";
export default {
  components: {SvgIcon},
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("keyup", this.handleClickOutside);
    this.searchText = this.initial;
  },
  created() {
    console.log(this.modelValue);
  },
  destroyed() {
    document.removeEventListener("keyup", this.handleClickOutside);
    document.removeEventListener("click", this.handleClickOutside);
  },
  watch: {
    searchText(curr, prev) {
      if (curr === prev) {
        return;
      }

      this.pointer = -1;
    },
    selectedOptions: {
      handler: function (val, oldVal) {
        let option = [];
        if (val != null && val.length > 0) {
          if (this.optionKey) {
            option = val.map(v => {
              return v[this.optionKey];
            });
          } else if (this.optionLabel) {
            option = val.map(v => {
              return v[this.optionLabel];
            });
          }
          this.$emit('update:modelValue', option);
        }
      },
      deep: true
    }
  },
  data() {
    return {
      selectedOptions: [],
      searchText: null,
      selectedOption: null,
      dropdownOpen: false,
      closed: false
    };
  },
  props: {
    // Use classes to override the look and feel
    // Provide these 7 classes.
    classes: {
      type: Object,
      required: false,
      default: () => {
        return {
          active: "active",
          wrapper: "multi-select-wrapper",
          searchWrapper: "search-wrapper",
          searchInput: "search-input",
          pill: "pill",
          required: "required",
          dropdown: "dropdown"
        };
      }
    },
    // Give your input a name
    // Good for posting forms
    name: {
      type: String,
      required: false,
      default: () => ""
    },
    // Your list of things for the select
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Значения для Label
     */
    optionLabel: {
      type: String,
      required: false,
      default: () => null
    },
    /**
     * Значения для ключа
     */
    optionKey: {
      type: String,
      required: false,
      default: () => null
    },
    // Give your input an html element id
    placeholder: {
      type: String,
      required: false,
      default: () => "Search Here"
    },
    maxHeight: {
      type: String,
      default: () => "220px",
      required: false
    },
    //Give the input an id
    inputId: {
      type: String,
      default: () => "multi-select",
      required: false
    },
    // Seed search text with initial value
    initial: {
      type: String,
      required: false,
      default: () => null
    },
    // Make it required
    required: {
      type: Boolean,
      required: false,
      default: () => false
    },
    // Max number of results to show.
    maxResults: {
      type: Number,
      required: false,
      default: () => 30
    },
    //Meh
    tabindex: {
      type: String,
      required: false,
      default: () => {
        return "";
      }
    },
    // Remove previously selected options
    // via the delete key
    keyboardDelete: {
      type: Boolean,
      required: false,
      default: () => {
        return true;
      }
    },
    // Tell vue-single-select what to display
    // as the selected option
    getOptionDescription: {
      type: Function,
      default(option) {
        if (this.optionLabel) {
          return option[this.optionLabel];
        }
        if (this.optionKey) {
          return option[this.optionKey];
        }
        return option;
      }
    },
    // Use this to actually give vue-single-select
    // a value for doing a POST
    getOptionValue: {
      type: Function,
      default(option) {
        if (this.optionKey) {
          return option[this.optionKey];
        }

        if (this.optionLabel) {
          return option[this.optionLabel];
        }

        return option;
      }
    }
  },
  methods: {
    popSelectedOption() {
      if (!this.keyboardDelete) {
        return;
      }

      if (this.searchText === null) {
        this.selectedOptions.pop();
        return;
      }

      if (this.searchText === "") {
        this.searchText = null;
      }
    },
    seedSearchText() {
      if (this.searchText !== null) {
        return;
      }

      this.searchText = "";
    },
    setPossibleOption() {
      if (!this.matchingOptions || !this.matchingOptions.length) {
        return;
      }

      if (this.pointer === -1) {
        this.pointer = 0;
      }

      this.setOption(this.matchingOptions[this.pointer]);
    },
    setOption(option) {
      this.selectedOption = option;
      this.selectedOptions.push(option);
      this.searchText = null;
      if(this.selectedOptions.length < 1) {
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      }
    },
    removeOption(idx) {
      this.selectedOptions.splice(idx, 1);
      this.$nextTick(() => {
        this.$refs.search.focus();
      });
    },
    setPointerIdx(idx) {
      this.pointer = idx;
    },
    closeOut() {
      this.searchText = null;
      this.closed = true;
    },
    movePointerDown() {
      if (!this.matchingOptions) {
        return;
      }
      if (this.pointer >= this.matchingOptions.length - 1) {
        return;
      }

      this.pointer++;
    },
    movePointerUp() {
      if (this.pointer > 0) {
        this.pointer--;
      }
    },
    handleClickOutside(e) {
      if (this.$el.contains(e.target)) {
        return;
      }

      this.closeOut();
    }
  },
  computed: {
    matchingOptions() {
      if (this.searchText === null) {
        return null;
      }

      if (this.optionLabel && this.optionKey) {
        return this.options
          .filter(
            option =>
              this.selectedOptions.findIndex(
                selected => selected[this.optionKey] === option[this.optionKey]
              ) < 0
          )
          .filter(option => {
            return (
              option[this.optionLabel]
                .toString()
                .toLowerCase()
                .includes(this.searchText.toString().toLowerCase()) ||
              this.searchText
                .toString()
                .toLowerCase()
                .includes(option[this.optionKey].toString().toLowerCase())
            );
          })
          .slice(0, this.maxResults);
      }

      if (this.optionLabel) {
        return this.options
          .filter(
            option =>
              this.selectedOptions.findIndex(
                selected =>
                  selected[this.optionLabel] === option[this.optionLabel]
              ) < 0
          )
          .filter(option =>
            option[this.optionLabel]
              .toString()
              .toLowerCase()
              .includes(this.searchText.toString().toLowerCase())
          )
          .slice(0, this.maxResults);
      }

      if (this.optionKey) {
        return this.options
          .filter(
            option =>
              this.selectedOptions.findIndex(
                selected => selected[this.optionKey] === option[this.optionKey]
              ) < 0
          )
          .filter(option =>
            option[this.optionKey]
              .toString()
              .toLowerCase()
              .includes(this.searchText.toString().toLowerCase())
          )
          .slice(0, this.maxResults);
      }

      return this.options
        .filter(
          option =>
            this.selectedOptions.findIndex(selected => selected === option) < 0
        )
        .filter(option =>
          option
            .toString()
            .toLowerCase()
            .includes(this.searchText.toString().toLowerCase())
        )
        .slice(0, this.maxResults);
    },
    isRequired() {
      if (!this.required) {
        return "";
      }

      if (this.selectedOptions.length) {
        return "";
      }

      return "required";
    }
  }
};
</script>
<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';

.registration-form__function {
  display: flex;
  padding-right: 18px;
  cursor: pointer;
  align-items: center;
  height: 100%;
  &-plus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: $accent;
  }
}

.list-reset {
  list-style: none;
  padding: 0;
}
.overflow-auto {
  overflow: auto;
}
.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.text-black {
  color: #1A1A1A;
  background: #F6F6F6;
  border-radius: 12px;
}
.text-grey-darker {
  color: #606f7b;
}
.leading-tight {
  line-height: 1.25;
}
.text-sm {
  @include mobile_text;
}
.w-full {
  width: 100%;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-between {
  justify-content: space-between;
}
.content-center {
  align-content: center;
}
.bordered {
  border-width: 1px;
  border-style: solid;
}
.border-none {
  border: none;
}
.hover\:border-blue:hover {
  border-color: #3490dc;
}
.border-grey {
  border-color: #b8c2cc;
}
.bg-grey-lighter {
  background-color: #f1f5f8;
}
.bg-white {
  background-color: #fff;
}
.pin-r {
  right: 0;
}
.pin-y {
  top: 0;
  bottom: 0;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.items-center {
  align-items: center;
}
.p-1 {
  padding: 0.25em;
}
.pb-1 {
  padding-bottom: 0.25em;
}
.pl-2 {
  padding-left: 0.5em;
}
.pr-1 {
  padding-right: 0.25em;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}
.px-2 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.pb-1 {
  padding-bottom: 0.25em;
}
.m-0 {
  margin: 0;
  margin-bottom: 0 !important;
}
.m-1 {
  margin: 0.25em;
}
.mt-1 {
  margin-top: 0.25em;
}
.mr-1 {
  margin-right: 0.25em;
}
.ml-1 {
  margin-left: 0.25em;
}
.mt-px {
  margin-top: 1px;
}
.mb-0 {
  margin-bottom: 0;
}
.leading-tight {
  line-height: 1.25;
}
.text-left {
  text-align: left;
}
.w-full {
  width: 100%;
}
.shadow {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.w-3 {
  width: 0.75em;
}
.h-3 {
  height: 0.75em;
}
.fill-current {
  fill: currentColor;
}
.hover\:no-underline:hover {
  text-decoration: none;
}
.outline-none {
  outline: 0;
}
.hover\:outline-none {
  outline: 0;
}
.hover\:bg-grey-lighter:hover {
  background-color: #dae1e7;
}
.shadow-md {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.focus\:shadow-outline:focus {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
  box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
}
.rounded {
  border-radius: 0.25em;
}
.search-input {
  height: 46px;
}
.icons svg {
  width: 0.75em;
  height: 0.75em;
}
.multi-select-wrapper {
}
.required {
  _color: #721c24;
  _background-color: #f8d7da;
  border-color: #f5c6cb;
}
.cursor-pointer {
  cursor: pointer;
}
.dropdown {
  border-radius: 12px;
  line-height: 1.25;
  text-align: left;
  display: inline;
  width: 99.8%;
  & > li {
    @extend %text-standart;
    background-color: $bg-light-gray;
    border-top: 1px solid $white;
    cursor: pointer;
    padding: 13px 20px;
  }
}
.active {
  background-color: #dae1e7;
}
.hidden {
  display: none;
}
.appearance-none {
  appearance: none;
}
input {
  overflow: visible;
}
.search-input {
  font-size: 100%;
  margin: 0;
}
.select-wrapper,
.box-size {
  box-sizing: border-box;
}
.pill {
  padding: 11px 12px 11px 15px;
  cursor: pointer;
  border-radius: 8px;
  line-height: 1.5;
  letter-spacing: -0.05em;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
}
.pill:hover {
  background-color: #f1f5f8;
}
</style>
