<template>
  <div class="people-search">
    <base-input
      placeholder="Введите имя или почту"
      class="people-search__search"
      v-model="searchText"
      search
      @close="closeModal"
    />
    <perfect-scrollbar :class="{'multy-request': multyInput}">
      <div class="people-search__item-group" v-if="multyInput">
        <item-people
          v-for="(option, idx) in matchingOptions.map(v => {
            v.select = v.select === undefined ? false : v.select;
            return v;
          }).sort(sortPeople)"
          class="item"
          :name="option.name"
          :company="option.company"
          :rating="option.rating"
          :photo="option.photo"
          :model-value="matchingOptions[matchingOptions.indexOf(option)]"
          @update:model-value="
            (v) => clearNotChecked(v, option, matchingOptions.indexOf(option))
          "
          checkbox-action
          :checkbox-radio="!multyInput"
          @deactivateAll="() => deactivateAll(matchingOptions.indexOf(option))"
        />
        <div
          class="people-search__note-search"
          v-if="matchingOptions.length <= 0"
        >
          <div class="icon">
            <svg-icon name="info"></svg-icon>
          </div>
          <div class="text">Добавьте рекомендодателя <br />введя его e-mail</div>
        </div>
      </div>
      <div class="people-search__item-group" v-else>
        <item-people
          v-for="(option, idx) in matchingOptions"
          class="item"
          :name="option.name"
          :company="option.company"
          :rating="option.rating"
          :photo="option.photo"
          :model-value="matchingOptions[matchingOptions.indexOf(option)]"
          @update:model-value="
            (v) => updateModelValue(v, matchingOptions.indexOf(option))
          "
          checkbox-action
          checkbox-radio
          @deactivateAll="() => deactivateAll(matchingOptions.indexOf(option))"
        />
        <div
          class="people-search__note-search"
          v-if="matchingOptions.length <= 0"
        >
          <div class="icon">
            <svg-icon name="info"></svg-icon>
          </div>
          <div class="text">Добавьте рекомендодателя <br />введя его e-mail</div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import ItemPeople from "@/components/ui/ItemPeople";
import BaseInput from "@/components/base/BaseInput";
import SvgIcon from "@/components/base/SvgIcon";
import { mapGetters } from "vuex";
import { debounce } from "@/utils/helper";
export default {
  name: "PeopleSearch",
  components: {
    SvgIcon,
    BaseInput,
    ItemPeople,
  },
  props: {
    /**
     * Поле для данный по поиску
     */
    searth: {
      type: String,
      default: "",
    },
    multyInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchText: null,
      dataOptions: [],
      searchDebounce: null,
    };
  },
  created() {
    this.searchDebounce = debounce(this.searchEvent, 400);
    this.searchText = this.searth;
  },
  computed: {
    ...mapGetters({
      userSearch: "auth/userSearch",
    }),
    matchingOptions() {
      return this.dataOptions;
    },
  },
  methods: {
    sortPeople(x, y) {
      return Number(y.select) -
          Number(x.select);
    },
    updateModelValue(v, index) {
      console.log('upd', v, index);
      this.matchingOptions[index] = v;
      this.$emit("update:modelValue", this.matchingOptions);
    },
    clearNotChecked(v, option, index) {
      console.log(v, option, this.matchingOptions[index]);
      if (this.matchingOptions[index].select === true && this.searchText.length == 0) {
        this.matchingOptions.splice(index, 1)
        console.log('here');
        this.$emit("update:modelValue", this.matchingOptions);
      }
      else {
        this.matchingOptions[index] = v;
        this.$emit("update:modelValue", this.matchingOptions);
      }
    },
    deactivateAll(select) {
      console.log('deactivate');
      this.matchingOptions.forEach((element, index) => {
        if (index !== select) element.select = false;
      });
    },
    checkNewUser() {
      if (this.searchText.length > 2) {
        if (this.matchingOptions.length > 0) this.$emit("checkNewUser", false);
        else this.$emit("checkNewUser", true);
      } else this.$emit("checkNewUser", false);
    },
    closeModal() {
      this.v$.$reset();
      // this.$store.dispatch('auth/getUserSearch', null)
      // this.searchText = null;
      this.$emit("close");
    },
    peopleTeamFormat({ id, type, name, rating, company, photo, need_approve }) {
      return {
        name: name != null ? name : "",
        company: company != null ? company.name : "",
        rating: rating != null ? rating : 4.8, //TODO Заменить данные
        id: id,
        type: type,
        photo: photo,
        need_approve: need_approve,
      };
    },
    searchTextEvent() {
      this.$emit("searchTextEvent", this.searchText);
    },
    async searchEvent() {
      if (this.searchText != null) {
        if (this.searchText.length > 1) {
          await this.$store.dispatch(
            "auth/getUserSearchEmail",
            this.searchText
          );
        }
        if (this.searchText.length === 0) {
          this.$store.commit("auth/ADD_AUTO_INCREMENT");
        }
      }
      this.searchTextEvent();
      this.checkNewUser();
    },
  },
  watch: {
    async searchText() {
      console.log(this.searchText);
      await this.searchDebounce();
      if (this.searchText.length === 0) {
        console.log('here');
        this.dataOptions = this.dataOptions.filter(item => item.select === true)
        this.$emit("update:modelValue", this.matchingOptions);
      }
    },
    userSearch() {
      console.log('324')
      if (this.userSearch != null) {
        let selectList = this.dataOptions.filter((v) => v.select === true);
        this.dataOptions = [
          ...selectList,
          ...this.userSearch.map((v) => this.peopleTeamFormat(v)),
        ].reduce((acc, item) => {
          if (
            !acc.find(
              (other) => item.id === other.id && item.type === other.type
            )
          )
            acc.push(item);
          return acc;
        }, []);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.people-search {
  &__search {
    margin-bottom: 24px;
  }
  &__item-group {
    gap: 16px;
    display: grid;
    margin-bottom: 24px;
    @include md {
      margin-bottom: 30px;
    }
  }
  &__note-search {
    display: flex;
    .icon {
      background: $line-light-gray;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      @include sm {
        min-width: 40px;
      }
    }

    .text {
      @include desktop_h2;
      color: $text-gray;
    }

    @include sm {
      width: 100%;
      overflow: hidden;
    }
  }
}

.multy-request {
    max-height: 50vh;
    padding-right: 16px;
}
</style>
