<template>
  <base-modal :is-open="isOpen" @close="closeModal">
    <div class="validate-competence">
      <h2 class="validate-competence__header">{{ isInstitution ? 'Учебное заведение' : 'Публикация' }}</h2>
      <div class="validate-competence__subtitle">
        <span>
          {{ isInstitution ? 'Чтобы подтвердить компетенцию, укажите учебное заведение' : 'Чтобы подтвердить компетенцию, прикрепите публикацию' }}
        </span>
      </div>
      <div class="body">
        <div class="body__scroll">
          <perfect-scrollbar>
            <div class="item-group">
              <vue-single-select-rows
                  v-if="isInstitution"
                  :new-value="true"
                  header="Место обучения"
                  subtitle="Выбрать место обучения"
                  description="Место обучения"
                  placeholder="Введите место обучения"
                  :items="institutionsList"
                  v-model="institutions"
                  :searchEvent="searchInstitution"
                  @updateSearch="updateInstitution"
                  is-scroll="true"
                  :is-error="v$.institutions.$error"
                  :error="[
                {
                  text: 'Место обучения обязателено для заполнения',
                  status: v$.institutions.validInstitutions.$invalid,
                },
              ]"
              searchEmptyText="Такое место обучения не найдено. Добавить новое?"
            ></vue-single-select-rows>
              <base-input
                v-if="isInstitution"
                v-model="studyFinish"
                description="Год выпуска"
                placeholder="Введите год выпуска"
                :is-error="v$.studyFinish.$error"
                :error="[
                  {
                    text: 'Год выпуска обязателен для заполнения',
                    status: v$.studyFinish.required.$invalid,
                  },
                  {
                    text: `Ведите год в диапазоне он ${curYear - 50} до ${curYear + 5}`,
                    status: v$.studyFinish.validStudyFinish.$invalid,
                  }
                ]"
              ></base-input>
              <base-input
                v-if="isPublication"
                v-model="link"
                description="Вставьте ссылку"
                placeholder="Ваша ссылка"
                :is-error="v$.link.$error"
                :error="!file ? [
                  {
                    text: 'Поле обязательно для заполнения',
                    status: v$.link.required.$invalid,
                  },
                  {
                    text: 'Введите корректную ссылку',
                    status: v$.link.isLink.$invalid,
                  },
                ] : [
                  {
                    text: 'Введите корректную ссылку',
                    status: v$.link.isLink.$invalid,
                  },
                ]"
              ></base-input>
              <base-file
                  class="validate-competence__base-file"
                  v-if="isPublication"
                  @file="updateData"
                  file-type="*"
                  :is-error="v$.file.$error"
                  :error="[
                    {
                      text: 'Неверный тип файла',
                      status: v$.file.checkType.$invalid,
                    },
                    {
                      text: 'Максимальный размер файла - 50мб',
                      status: v$.file.checkSize.$invalid,
                    },
                    {
                      text: 'Добавьте не более 5 файлов',
                      status: v$.file.maxLength.$invalid,
                    }
                  ]"
              />
              <base-input
                  max-textarea="200"
                  v-model="description"
                  v-if="isPublication"
                  description="Название"
                  placeholder="Напишите название для отображения"
                  :is-error="v$.description.$error"
                  :error="!file ? [
                {
                   text: 'Поле обязательно для заполнения',
                   status: v$.description.required.$invalid,
                },
                {
                   text: 'Максимальная длина - 200 символов',
                   status: v$.description.maxLength.$invalid,
                },
              ] : [
                {
                   text: 'Максимальная длина - 200 символов',
                   status: v$.description.maxLength.$invalid,
                },
              ]"
              ></base-input>
            </div>
          </perfect-scrollbar>
        </div>
        <base-button @click="submit">Сохранить</base-button>
      </div>
    </div>
    <alert-modal ref="alert">ок</alert-modal>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseInput from "@/components/base/BaseInput";
import BaseDropdown from "@/components/base/BaseDropdown";
import BaseButton from "@/components/base/BaseButton";
import VueSingleSelectRows from "@/components/ui/VueSingleSelectRows";
import {mapGetters} from "vuex";
import useVuelidate from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";
import BaseFile from "@/components/base/BaseFile";
import helper, {debounce, typeFile, validateUrl,} from "@/utils/helper";
import AlertModal from "@/components/modals/AlertModal";

export default {
  name: "ValidateCompetenceModal",
  components: {
    AlertModal,
    BaseFile,
    VueSingleSelectRows,
    BaseButton,
    BaseDropdown,
    BaseInput,
    BaseModal
  },
  props: {
    expertise: {

    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    confirmType: null,
  },
  data() {
    return {
      file: null,
      studyFinish: null,
      institutions: null,
      link: null,
      description: null,
      curYear: new Date().getFullYear(),
    }
  },
  setup: () => ({v$: useVuelidate()}),
  created() {
    this.institutionDebounce = debounce(this.getInstitution, 400);
    this.institutionDebounce('');
  },
  computed: {
    ...mapGetters({
      institutionsList: 'profile/institutions'
    }),
    isInstitution() {
      return this.confirmType === 'institution';
    },
    isPublication() {
      return this.confirmType === 'publication';
    },
  },
  methods: {
    async getInstitution(val) {
      await this.$store.dispatch('profile/getInstitutions', val);
    },
    async searchInstitution(v) {
      await this.$store.dispatch('profile/getInstitutions', v);
    },
    async updateInstitution(e) {
      await this.institutionDebounce('profile/getInstitutions', e);
      
    },
    closeModal() {
      this.$emit('close')
      this.file = null;
      this.studyFinish = null;
      this.institutions = null;
      this.link = null;
      this.description = null;
      this.v$.$reset();
    },
    isLink(value) {
      if (value === "" || value == null)
        return true;
      return helper.validateUrl(value)
    },
    async updateData(e) {
      this.file = e;
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
    },
    async submit() {
      console.log(this.v$);
      let form = null,
          institution = null;
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      console.log(this.isInstitution);
      if (this.isInstitution) {
        if (this.institutions.length > 0) {
          let institutionSelect = this.institutions[0];
          console.log(institution);
          if (institutionSelect.newValue)
            institution = {new_institution: institutionSelect.name};
          else
            institution = {institution_id: institutionSelect.id};
        }
        form = {
          confirm_type: this.confirmType,
          expertise_id: this.expertise.expertise_id,
          description: this.description,
          study_finish: `${this.studyFinish}-01-01`,
          ...institution
        };
      } else {
        form = new FormData();
        if (this.file != null)
          form.append('publication_file', this.file);
        form.append('confirm_type', this.confirmType);
        form.append('expertise_id', this.expertise.expertise_id);
        if (this.description != null)
          form.append('description', this.description);
        if (this.link != null)
          form.append('link', this.link);
      }
      try {
        await this.$store.dispatch('profile/postProfileExpertiseConfirm', form);
        this.closeModal();
        this.$emit('updateList')
      } catch (e) {
        this.$refs.alert.show(
            this.$t('alert.error'),
            e,
            {
              type: 'error',
              nameButton: 'Ок'
            },
        );
        throw e;
      }
    },
    validInstitutions(value) {
      this.searchInstitution('')
      if (value === "")
        return true;
      return this.institutions.length > 0;
    },
    validStudyFinish(value) {
      if (value === "" || value == null)
        return true;
      if (!/^[0-9]+$/.test(value))
        return false
      return value >= (this.curYear - 50) && value <= (this.curYear + 5) || !value;
    },
    checkType(e) {
      console.log(e);
      if (e !== null) {
        return typeFile.includes(e.type)
      } else {
        return true
      }
    },
    checkSize(e) {
      if (e !== null) {
        return e.size < (50 * 1024 * 1024)
      } else {
        return true
      }
    },
  },
  validations() {
    let validateInstitution =  {
      institutions: {
        validInstitutions: this.validInstitutions
      },
      studyFinish: {
        required,
        validStudyFinish: this.validStudyFinish
      }
    }
    let validatePublication = null
    if (this.file == null) {
      validatePublication = {
        link: {
          required,
          isLink: this.isLink
        },
        description: {
          required,
          maxLength: maxLength(200)
        }
      }
    }
    else {
      validatePublication = {
        link: {
          isLink: this.isLink
        },
        description: {
          maxLength: maxLength(200)
        }
      }
    }
    return {
      ...(this.isPublication ? validatePublication : {}),
      ...(this.isInstitution ? validateInstitution : {}),
      ...{
        file: {
          checkType: this.checkType,
          checkSize: this.checkSize,
          maxLength: maxLength(5)
        },
        confirmType: {
          required
        }
      }
    };
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
.validate-competence {
  &__header {
    @include desktop_h1;
    text-align: left;
  }
  &__input {
    margin-bottom: 24px;
  }
  &__confirm-type {
    margin-bottom: 24px;
  }
  &__base-file {
    margin-top: 22px;
    margin-bottom: 24px;
  }
  &__subtitle {
    margin-top: 12px;
    @include desktop_additional;
  }

  .multi-select-rows {
    margin-bottom: 24px;
  }

  .input-group {
    margin-bottom: 8px;
  }
}
.body__scroll {
  overflow-x: hidden;
  margin: 24px -18px 16px -18px;
  padding-left: 18px;
}
.item-group {
  //gap: 16px;
  //display: grid;
  //margin-bottom: 30px;
  padding-right: 18px;
}

.ps {
  max-height: 52vh;
}
</style>
