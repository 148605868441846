import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import { loadFonts } from './plugins/webfontloader'
import vuetify from './plugins/vuetify'
import vClickOutside from "click-outside-vue3"
import { setupI18n } from './i18n'
import VueLazyload from 'vue-lazyload'

import "@/assets/css/reset.css";
import "@/assets/scss/base.scss";
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import '@vuepic/vue-datepicker/dist/main.css';

import veProgress from "vue-ellipse-progress";
import copyText from "@meforma/vue-copy-to-clipboard";
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import VueVirtualScroller from 'vue-virtual-scroller'
import VueDatePicker from '@vuepic/vue-datepicker';


import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const app = createApp(App)
const i18n = setupI18n({})

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["gigoo.ru"],
        }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT || 'local'
});
//loadFonts()

app.component('VueDatePicker', VueDatePicker);

app
    .use(vClickOutside)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(i18n)
    .use(PerfectScrollbar)
    .use(veProgress)
    .use(copyText)
    .use(VueLazyload)
    .use(VueVirtualScroller)
    .mount('#app')