<template>
  <v-container>
    <form class="auth-form" @submit.prevent="submit">
      <div class="auth-form__top">
        <h1>Восстановление доступа</h1>
      </div>
      <validation-pass-block
          :required="{
          status: v$.password.required.$invalid,
          isShow: true
        }"
          :length="{
          status: v$.password.minLength.$invalid,
          isShow: true
        }"
          :is-upper-case="{
          status: v$.password.containsUppercase.$invalid,
          isShow: true
        }"
          :latin="{
          status: v$.password.latin.$invalid,
          isShow: true
        }"
      />
      <SizedBox height="24"></SizedBox>
      <div>
        <base-input
          v-model="email"
          :class="{ error: v$.email.$error }"
          type="text"
          description="E-mail"
          :is-error="v$.email.$error"
          :error="[
            {
              text: 'E-mail обязателен',
              status: v$.email.required.$invalid,
            },
            {
              text: 'Некорректный email',
              status: v$.email.email.$invalid,
            }
          ]"
        />
        <base-input
          v-model="token"
          :class="{ error: v$.token.$error }"
          type="text"
          description="Код доступа"
          :is-error="v$.token.$error"
          :sub-description="`Письмо с кодом подтверждения отправлено на адрес ${email != null ? email : ''}`"
          :error="[
            {
              text: 'код доступа обязателен',
              status: v$.token.required.$invalid,
            },
          ]"
        />
        <base-input
          v-model="password"
          :class="{ error: v$.password.$error }"
          type="password"
          :is-error="v$.password.$error"
          description="Ваш пароль"
          :error="[
            {
              text: 'пароль обязателен',
              status: v$.password.required.$invalid,
            },
          ]"
        />
        <base-input
          v-model="repeatPassword"
          class="registration-form__pass"
          :class="{ error: v$.repeatPassword.$error }"
          type="password"
          :is-error="v$.repeatPassword.$error"
          description="Повторите пароль"
          :error="[
            {
              text: 'Пароли не совпадают',
              status: v$.repeatPassword.sameAs.$invalid,
            }
          ]"
        />
      </div>
      <div>
        <base-button type="submit">Сохранить</base-button>
      </div>
    </form>
  </v-container>
  <alert-modal ref="alert">Вернуться на главную</alert-modal>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/BaseInput";
import VerificationModal from "@/components/modals/VerificationModal";
import AlertModal from "@/components/modals/AlertModal";
import SizedBox from "@/components/ui/SizedBox";
import useVuelidate from "@vuelidate/core";
import {email, minLength, required, sameAs} from "@vuelidate/validators";
import ValidationPassBlock from "@/components/ui/ValidationPassBlock";
import {mapGetters} from "vuex";

export default {
  name: "ResetPassword",
  components: {ValidationPassBlock, SizedBox, AlertModal, BaseInput, BaseButton, VerificationModal},
  setup: () => ({v$: useVuelidate()}),
  computed: {
    ...mapGetters(
        {error: 'auth/error', isAuth: 'auth/isAuth'},
    )
  },
  created() {
    // if (this.isAuth)
    //   this.$router.replace({name: 'HomePage'});
  },
  data() {
    return {
      isResetPassword: false,
      isAlert: false,
      email: this.$route.query.email,
      password: '',
      repeatPassword: '',
      token: ''
    }
  },
  methods: {
    getNameBtn() {
      if (this.isAuth)
        return 'Вернуться на главную'
      else
        return 'Вернуться на авторизацию'
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      const formData = {
        token: this.token,
        email: this.email,
        password: this.password,
        password_confirmation: this.repeatPassword
      }
      await this.$store.dispatch('auth/resetPassword', formData)
      if (this.error != null)
        this.$refs.alert.show(
            this.$t('alert.error'),
            this.error.message,
            {
              type: 'error',
              nameButton: 'ок'
            }
        );
      else {
        this.$refs.alert.show(
            this.$t('alert.notification'),
            "Новый пароль установлен",
            {
              type: 'success',
              nameButton: this.getNameBtn()
            },
            () => {
              this.$router.replace({ name: 'AuthPage' });
            }
        );
      }
    },
    containsUppercase(value) {
      return value && /[A-Z]/.test(value)
    },
    latin(value) {
      return value && /^[a-zA-Z_*@!#0-9]+$/.test(value)
    },
  },
  validations() {
    return {
      email: {
        required,
        email
      },
      token: {
        required
      },
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: this.containsUppercase,
        latin: this.latin
      },
      repeatPassword: {
        required,
        sameAs: sameAs(this.password),
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-form {
  max-width: 395px;
  width: 100%;
  background-color: #fff;
  margin: 30px auto;
  border-radius: 24px;
  padding: 30px;

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 19px;

    .button {
      width: fit-content;
      margin-right: 8px;
    }
  }

  &__cant {
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    margin-left: auto;
    color: $accent;
  }
}
</style>
