<template>
  <base-modal class="worker-added-modal" :is-open="isOpen" @close="closeModal" size="440">
    <svg-icon class="position"
        name="success"
        width="48"
        height="48"
    />
    <h2 class="header">{{ header }}</h2>
    <div v-if="subtitle !== ''" class="subtitle">
      <span> {{ subtitle }}</span>
    </div>
    <base-button @click="copyLink" class="mb-4 button--light btn__link">
<!--      <div v-if="activate" class="text-link__content">-->
<!--        <p class="text-link">{{ link }}</p>-->
<!--      </div>-->
      <div class="worker-added-modal__text-btn">
        <svg-icon class="copy-link__svg" name="copy"/>
        <span>Скопировать ссылку</span>
      </div>
    </base-button>
    <base-button @click="closeModal()">{{ btnText }}</base-button>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseInput from "@/components/base/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";



class EventVerificationModalData {
  text = '';
}
export default {
  name: "WorkerAddedModal",
  components: {
    BaseButton,
    BaseInput,
    BaseModal,
    SvgIcon
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    /**
     * Дополнительный контент, отображаемый под заголовком
     */
    subtitle: {
      type: String,
      default: ''
    },
    /**
     * Текст заголовка
     */
    header: {
      type: String,
      default: ''
    },
    /**
     * Текст заголовка
     */
    placeholder: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activate: false,
      verificationCode: '',
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    sendVerify() {
      let eventData = new EventVerificationModalData();
      eventData.text = this.verificationCode;
      this.verificationCode = '';
      this.$emit('submit', eventData)
    },

    copyLink() {
      this.$copyText(this.link)
          .then(() => {
            this.activate = true;
            console.log("copied!");
          })
          .catch(() => {
            console.log(`can't copy`);
          });

    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
.worker-added-modal {
  &__text-btn {
    display: flex;
    align-items: center;
  }

  h2 {
    text-align: center;
  }
  .subtitle {
    margin-bottom: 24px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.006em;
    color: $black;
    white-space: pre-line;
    @include md {
      margin-bottom: 40px;
    }
  }
  .position {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }

  .copy-link__svg {
    margin-right: 20px;
    min-width: 24px;
  }
  .text-link__content {
    display: grid
  }
  .text-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .header {
    @include desktop_h1;
    margin-bottom: 12px;
  }
  .btn__link {
    @include md {
      padding: 20px;
    }
  }
  .text-btn {
    @include md {
      text-align: left;
    }
  }
}
</style>