<template>
  <div class="accordion">
    <div
        class="accordion-header"
        :style="headerStyle"
        :class="headerClass"
        @click="switchState"
    >
      <div>
        <slot name="header"></slot>
      </div>
      <div  :class="correctSymbol">
        <slot name="opened"></slot>
      </div>
    </div>

    <div
        class="accordion-content"
        :style="contentStyle"
        :class="contentClass"
        v-show="isOpened"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "accordion",
  props: {
    headerStyle: {
      type: [String, Object],
      default: "",
    },
    headerClass: [String, Object],
    symbolStyle: {
      type: [String, Object],
      default: "font-size: 1.5rem; font-weight: 700; vertical-align: middle;",
    },
    symbolClass: [String, Object],
    contentStyle: {
      type: [String, Object],
      default: "width: 100%;",
    },
    contentClass: [String, Object],
    symbolOpened: {
      type: [String, Object],
      default: "&or;",
    },
    symbolClosed: {
      type: [String, Object],
      default: "&gt;",
    },
    opened: Boolean,
  },
  data() {
    return {
      isOpened: this.opened,
    };
  },
  computed: {
    correctSymbol() {
      return this.isOpened ? 'over' :  'out';
    },
  },
  methods: {
    open() {

      this.isOpened = true;
      this.$emit("open");
    },
    switchState() {
      this.$emit("switch");
      if (!this.isOpened) this.open();
      else this.close();
    },
    close() {
      this.isOpened = false;
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="scss">
.over {
  transform: rotate( -180deg );
  transition: transform 150ms ease;
}

.out {
  transform: rotate( -360deg );
  transition: transform 150ms ease;
}
.accordion-header {
  cursor: pointer;
  display: flex;
  padding: 20px 0;
}

.accordion-header div:first-child {
  flex-grow: 1;
  align-self: center;
}

.accordion-symbol {
  flex-grow: 0;
  align-self: center;
  user-select: none;
}

.accordion-content {
  transition: 1.5s ease all;
  margin-top: -20px;
  margin-bottom: 16px;
}
</style>