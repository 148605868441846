<template>
  <base-modal size="550" :is-open="isOpen" @close="closeModal">
    <div class="support-modal">
      <div class="support-modal__header">
        <h2 class="information__header">Связаться с поддержкой</h2>
        <p class="information__subdescription">Напишите в поддержку любым удобным способом</p>
      </div>
      <div class="support-modal__body">
        <div class="support-modal__copy">
          <base-copy-block email :title="support.options.email" before-copy="Скопировать адрес" after-copy="Адрес скопирован" />
          <base-copy-block phone :title="support.options.tel" before-copy="Скопировать номер" after-copy="Номер скопирован" />
        </div>
        <div class="support-modal__link">
          <a v-for="item in support.socialServices" class="support-modal__link-item" target="_blank" :href="item.link"><svg-icon :width="24" :height="24" :name="`socials/${item.code}2`"></svg-icon>{{ item.name }}</a>
        </div>
        <base-button @click="closeModal">Закрыть</base-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseCopyBlock from "@/components/base/BaseCopyBlock";
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";
import {mapGetters} from "vuex";
export default {
  name: "ContactSupportModal",
  components: {SvgIcon, BaseButton, BaseCopyBlock, BaseModal},
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters({
      support: 'profile/support',
    }),
  },
  methods: {
    showModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.support-modal {
  &__header {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 20px;

    .information {
      &__header {
        margin-bottom: 10px;
      }

      &__subdescription {
        line-height: 22px;
      }
    }
  }

  &__body {
    padding-top: 25px;
  }

  &__copy {
    display: grid;
    gap: 26px;
  }

  &__link {
    display: grid;
    gap: 16px;
    margin: 30px 0;

    &-item {
      padding: 14px 20px;
      border: 2px solid #eeeeee;
      border-radius: 10px;
      @extend %text-standart;
      font-weight: 500;
      color: $accent;

      svg {
        margin-right: 22px;
      }
    }
  }
}
</style>
