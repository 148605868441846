<template>
 <div>
   <community-block title="Функции" type="option" />
 </div>
</template>

<script>
import CommunityBlock from "@/components/component/CommunityBlock";
export default {
  name: "CommunityFunctionsPage",
  components: {CommunityBlock},
}
</script>

<style scoped lang="scss">
</style>
