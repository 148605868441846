<template>
  <two-cols-grig>
    <template v-slot:title>
      <div v-if="title" class="title">{{ title }}</div>
    </template>
    <template v-slot:body>
      <div class="block">
        <div v-for="(item, index) in chips" :key="item + index" class="chip">
          {{ item }}
        </div>
        <div class="chip additional">{{ additional }}</div>
      </div>
    </template>
  </two-cols-grig>
</template>
<script>
import TwoColsGrig from "@/views/startpage/components/TwoColsGrig";

export default {
  name: "DemoTagsBlock",
  components: {
    TwoColsGrig,
  },

  props: {
    chips: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      default: "",
    },
    additional: {
      type: String,
      default: "и еще 300+ услуг",
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.title {
  font-family: "Muller", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 100%;
  color: $black;
  padding: 0px 0px 30px 10px;
  text-align: left;

  @include md {
    color: var(--text, #000);
    font-family: Muller;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 100% */
    letter-spacing: -0.52px;
    padding: 0px;
  }
}

.block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  width: 100%;

  &__equal {
    width: 50%;
  }

  &__reverse {
    width: 40%;
  }

  @include md {
    //max-width: 280px;
    width: 100%;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    padding: 0px;
  }

  @include sm {
    width: 100%;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
  }
}

.chip {
  @extend %text-standart;
  font-size: 16px;
  padding: 14px 18px;
  align-items: center;
  border-radius: 50px;
  background-color: #fbfbfb;

  @include md {
    flex-wrap: nowrap;
    font-size: 14px;
    padding: 7px 9px;
  }
  &.additional {
    @extend %text-standart;
    font-size: 16px;
    padding: 14px 18px;
    align-items: center;
    border-radius: 50px;
    background-color: #eeeeee;
    color: #a0a0a0;
    @include md {
      flex-wrap: nowrap;
      font-size: 14px;
      padding: 7px 9px;
    }
  }
}
</style>
