<template>
  <div
    class="information-block"
    :style="'border: 1px solid' + (isActive ? '#7fc666' : '#e6e6e6;')"
  >
    <div style="position: relative">
      <div class="label" v-if="isPremium">
        <svg-icon
          name="switch-premium-label"
          :width="13"
          :height="13"
          style="margin-right: 5px"
        />premium
      </div>
    </div>
    <div>
      <svg-icon
        v-if="icon"
        style="margin-bottom: 15px"
        :name="icon + (isActive ? '-active' : '')"
        :width="25"
        :height="25"
        @click="choose('premium')"
      />
      <p :class="{ 'special-text': specialForm }">{{ text }}</p>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "SwitchBlock",
  components: { SvgIcon },
  props: {
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    isPremium: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.label {
  text-align: center;
  align-items: center;
  padding: 5px 10px;
  background: #7fc666;
  color: #fff;
  display: flex;
  position: absolute;
  font-family: SFProDisplay;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 0.072px;
  width: 89px;
  height: 25px;
  top: -30px;
  left: 70%;
  border-radius: 18px;
  @include sm() {
    left: 50%;
  }
}

.information-block {
  cursor: pointer;
  text-align: left;
  padding: 20px;
  // padding-top: 0px;
  border: 1px solid $line-light-gray;
  border-radius: 12px;

  p {
    color: #4b4c57;
    /* Desktop/Standart */
    font-family: SFProDisplay;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
    letter-spacing: 0.108px;

    &.special-text {
      color: $accent;
    }
  }

  svg {
    margin-bottom: 22px;
  }

  &.light-color {
    padding: 19px 32px 19px 19px;

    p {
      color: $accent;
      @include desktop_standart;
    }

    svg {
      margin-bottom: 15px;
    }
  }
}
</style>
