<template>
  <div class="expert-council-item">
    <div class="expert-council-item__icon">
      <svg-icon :name="icon" />
    </div>
    <div class="expert-council-item__body">
      <div class="expert-council-item__title">{{ industry }} - {{ option }}</div>
      <div class="expert-council-item__description">{{ statusString }}</div>
    </div>
    <span class="expert-council-item__remove" v-if="remove">
      <svg-icon :width="25" :height="25" name="service-dell" @click.prevent="delItem"></svg-icon>
    </span>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "ExpertCouncilItem",
  components: {SvgIcon},
  props: {
    industry: {
      type: String,
      default: ''
    },
    option: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    remove: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      switch (this.status) {
        case 'active':
          return 'input-check'
        case 'moderation':
          return 'question-bg'
        case 'rejected':
          return 'cross-orange'
        default:
          return 'question-bg'
      }
    },
    statusString() {
      switch (this.status) {
        case 'active':
          return 'Принято'
        case 'moderation':
          return 'На рассмотрении'
        case 'rejected':
          return 'Отклонена'
        default:
          return 'На рассмотрении'
      }
    }
  },
  methods: {
    delItem() {
      this.$emit('delItem')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';

.expert-council-item {
  display: flex;
  position: relative;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 20px 16px;
  gap: 16px;
  cursor: pointer;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    flex-shrink: 0;
  }

  &__remove {
    align-self: center;
    opacity: 0;

    @include md {
      opacity: 1;
    }

    @include sm() {
      position: absolute;
      right: 17px;
      top: 14px;

      svg {
        width: 23px!important;
        height: 23px!important;
      }
    }
  }

  &__title {
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 11px;

    @include sm() {
      margin-bottom: 10px;
      margin-right: 24px;
    }
  }

  &__description {
    display: block;
    @extend %text-standart;
    color: #A0A0A0;

    @include sm() {
      line-height: 21px;
      overflow: hidden;
      max-height: 86px;
    }
  }

  &:hover {
    border-color: #A0A0A0;

    .expert-council-item {
      &__title {
        color: #4b4c57;
      }

      &__remove {
        opacity: 1;
      }
    }
  }

  &__body {
    flex-grow: 1;
    margin-top: -4px;

    p {
      font-weight: 500;
      font-size: 18px;
    }
    span {
      @extend %desktop_additional;
    }
  }
}
</style>
