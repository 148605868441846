<template>
  <div class="work-experience" :class="{ first: isSomeOne }">
    <div class="header">
      <h2 v-if="industries || options" class="information__header">
        {{ "Проектная экспертиза" }}
      </h2>
      <p v-if="!isSomeOne" class="header__description">
        На основе этих данных вы будете получать проекты, которые соответвуют вашей
        экспертизе
      </p>
    </div>
    <div class="body">
      <vue-multi-select-check-box-profile
        :only-show="isSomeOne"
        ref="v-industryArray"
        optionLabel="name"
        optionKey="id"
        placeholder="Выберите из списка"
        description="Индустрии"
        v-model="industryValue"
        :options="industriesList"
        :is-error="isIndustryValue"
        :error="[
          {
            text: 'Превышен лимит индустрий',
            status: isIndustryValue,
          },
        ]"
      ></vue-multi-select-check-box-profile>
      <vue-multi-select-check-box-profile
        :only-show="isSomeOne"
        is-function-choice
        ref="v-industryArray"
        optionLabel="name"
        optionKey="id"
        placeholder="Выберите из списка"
        description="Функции"
        v-model="optionValue"
        :options="optionsList"
        :is-error="isOptionValue"
        :error="[
          {
            text: 'Превышен лимит функций',
            status: isOptionValue,
          },
        ]"
      ></vue-multi-select-check-box-profile>
    </div>
  </div>
  <alert-modal ref="alert"> ок </alert-modal>
</template>

<script>
import AlertModal from "@/components/modals/AlertModal";
import { mapGetters } from "vuex";
import WorkBlock from "@/components/ui/WorkBlock";
import AddWorkModal from "@/components/modals/AddWorkModal";
import VueMultiSelectCheckBoxProfile from "@/components/ui/VueMultiSelectCheckBoxProfile";
export default {
  name: "ProjectExpertiseList",
  components: {
    AddWorkModal,
    WorkBlock,
    AlertModal,
    VueMultiSelectCheckBoxProfile,
  },
  props: {
    isSomeOne: {
      type: Boolean,
      default: false,
    },
    industries: {
      type: Array,
      default: [],
    },
    options: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      industryValue: [],
      optionValue: [],
    };
  },
  mounted() {
    this.industryValue = this.industries;
    this.optionValue = this.options;
  },
  computed: {
    ...mapGetters({
      industriesList: "registration/industriesList",
      optionsList: "registration/optionsList",
    }),
    isIndustryValue() {
      return this.industryValue.length > 100;
    },
    isOptionValue() {
      return this.optionValue.length > 100;
    },
  },
  watch: {
    optionValue: {
      // deep: true,
      //immediate: true,
      handler(val, oldValue) {
        this.$emit("update:options", val);
      },
    },
    industryValue: {
      // deep: true,
      //immediate: true,
      handler(val, oldValue) {
        this.$emit("update:industries", val);
      },
    },
    industries: {
      handler(val, oldValue) {
        this.industryValue = val;
      },
    },
    options: {
      handler(val, oldValue) {
        this.optionValue = val;
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.work-experience {
  &__list {
    margin-bottom: 44px;
    margin-top: -12px;

    @include sm() {
      margin-bottom: 48px;
    }
  }
  .info-box {
    margin-bottom: 0;
  }
}

.first {
  margin-top: 0px;
}
.information__header {
  @include desktop_h2_norm;
  @include sm {
    margin-bottom: 24px;
  }
}

.header {
  &__description {
    @include desktop_standart;
    color: $accent;
  }

  &__title {
    margin-bottom: 30px;

    @include sm() {
      margin-bottom: 25px;
    }
  }
}

.button--light:hover {
  background-color: $bg-light-gray;
}

.button--light {
  background-color: transparent;
}

.modal__btn {
  margin-left: 16px;
}

.action-info {
  width: auto;

  @include sm {
    width: 100%;
  }
}

.description {
  @extend %desktop_additional;
  text-align: left;
  margin-bottom: 30px;
  width: 50%;

  @include md {
    margin-bottom: 24px;
  }

  color: $input-label;

  @include sm {
    margin-bottom: 30px;
    width: 100%;
  }
}

.body {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 24px;
  gap: 24px;
}

.title {
  min-height: 54px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0 20px 0;

  @include md {
    margin: 42px 0 0 0;
  }

  @include sm {
    display: grid;
    margin: 48px 0 0 0;
  }
}

.add {
  width: auto;

  @include sm {
    margin: 25px 0 7px 0;
  }
}

.item:hover .dell {
  opacity: 1;
}

.dell {
  opacity: 0;

  @include md {
    opacity: 1;
  }

  transition: opacity 300ms;
  cursor: pointer;
}

.input-error {
  color: #ef693f;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}
</style>
