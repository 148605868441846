<template>
  <div class="profile">
    <div>
      <cropper-avatar-modal
        v-model="showCropper"
        ref="cropperAvatar"
        :uploadHandler="handleCompleted"
        @error="errorImageModal"
      ></cropper-avatar-modal>
      <industry-modal ref="industryModal"></industry-modal>
      <alert-modal ref="alert"> Ок </alert-modal>
      <div class="profile__user">
        <div
          class="profile__avatar-box"
          :style="{ '--profile-avatar-size': avatarSize + 'px' }"
        >
          <div class="profile__avatar" v-if="!isSomeOne" @click="showPhoto">
            <avatar
              :size="avatarSize"
              :image="getPhoto == null ? '' : getPhoto"
              :fullname="fullName"
              :short-name="shortName"
            />
            <svg-icon
              height="40"
              width="40"
              class="profile__avatar-select"
              name="profile-select-photo"
            />
            <div class="profile__avatar-brand">
              <img :src="$store.getters['auth/assets'] + 'img/brand.png'" alt="" />
            </div>
          </div>
          <div class="profile__avatar someone" v-else>
            <avatar
              :size="avatarSize"
              :image="getPhoto == null ? '' : getPhoto"
              :fullname="fullName"
              :short-name="shortName"
            />
          </div>
        </div>
        <div class="profile__body">
          <div class="profile__user-name">
            <h1>{{ fullName }}</h1>
            <sized-box width="13"></sized-box>
          </div>
          <div>
            <status-block :model-value="userStatus"></status-block>
            <info-block />
          </div>
        </div>
      </div>
      <div class="scrolling-wrapper profile-tabs__control" v-if="!isSomeOne">
        <router-link class="profile-tabs__control-item card" to="/profile/information">
          {{ $t("router.profile-information") }}
        </router-link>
        <router-link
          class="profile-tabs__control-item card"
          to="/profile/recommendation-request"
        >
          {{ $t("router.profile-recommendation-request") }}
        </router-link>
        <router-link class="profile-tabs__control-item card" to="/profile/recommendation">
          {{ $t("router.profile-recommendation") }}
        </router-link>
        <router-link class="profile-tabs__control-item card" to="/profile/settings">
          {{ $t("router.profile-setting") }}
        </router-link>
      </div>
    </div>
    <div class="profile-tabs__content white-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import StatusBlock from "@/components/component/StatusBlock";
import SizedBox from "@/components/ui/SizedBox";
import SvgIcon from "@/components/base/SvgIcon";
import Avatar from "@/components/ui/Avatar";
import { mapGetters } from "vuex";
import CropperAvatarModal from "@/components/modals/AvatarCropperModal";
import AlertModal from "@/components/modals/AlertModal";
import IndustryModal from "@/components/modals/IndustryModal";
import { useDisplay } from "vuetify";
import InfoBlock from "@/components/ui/InfoBlock";
import { ref } from "vue";

export default {
  name: "ProfilePage",
  components: {
    IndustryModal,
    AlertModal,
    CropperAvatarModal,
    Avatar,
    SvgIcon,
    SizedBox,
    BaseButton,
    StatusBlock,
    InfoBlock,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      error: "registration/error",
      someUser: "profile/someUser",
    }),
    fullName() {
      if (!this.isSomeOne) {
        let patronymic = this.user.patronymic == null ? "" : this.user.patronymic;
        let name = this.user.name == null ? "" : this.user.name;
        let lastName = this.user.lastName == null ? "" : this.user.lastName;
        return `${lastName} ${name} ${patronymic}`;
      } else {
        let patronymic = this.someUser.patronymic == null ? "" : this.someUser.patronymic;
        let name = this.someUser.name == null ? "" : this.someUser.name;
        let lastName = this.someUser.lastName == null ? "" : this.someUser.lastName;
        return `${lastName} ${name} ${patronymic}`;
      }
    },
    userStatus() {
      let status = this.user.status == null ? "" : this.user.status;
      return status;
    },
    shortName() {
      let name = this.user.name == null ? "" : this.user.name;
      let lastName = this.user.lastName == null ? "" : this.user.lastName;
      return `${name} ${lastName[0]}.`;
    },
    getPhoto() {
      if (!this.isSomeOne) {
        if (this.user == null) return null;
        if (this.user.photo === "") return null;
        return this.user.photo;
      } else {
        if (this.someUser == null) return null;
        if (this.someUser.photo === "") return null;
        return this.someUser.photo;
      }
    },
    isSomeOne() {
      return !!this.$route.params.id;
    },
  },
  data() {
    return {
      tab: null,
      showCropper: false,
      isAllTribe: false,
    };
  },
  setup() {
    const avatarSize = ref(190);
    const { mdAndDown } = useDisplay();
    if (mdAndDown.value) avatarSize.value = 140;
    return { avatarSize };
  },
  created() {
    if (this.isSomeOne) {
      this.getSomeUserInfo();
    }
  },
  methods: {
    async getSomeUserInfo() {
      try {
        await this.$store.dispatch("profile/getSomeUserInfo", this.$route.params.id);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    errorImageModal(e) {
      this.$refs.alert.show(this.$t("alert.error"), e.message, {
        type: "error",
        nameButton: "Ок",
      });
    },
    showIndustry() {
      this.$refs.industryModal.show();
    },
    handleCompleted(event) {
      console.log("EVENT", event);
      event.toBlob(
        (blob) => {
          // let url  = window.URL.createObjectURL(blob);
          // window.open(url,"_blank");
          console.log("BLOB", blob);
          let bodyFormData = new FormData();
          bodyFormData.append("photo", blob);
          console.log("file", bodyFormData.get("photo"));
          this.sendPhoto(bodyFormData);
        },
        "image/png",
        0.75
      );
    },
    showPhoto() {
      this.showCropper = true;
      // this.$refs.cropperAvatar.show();
    },
    async sendPhoto(bodyFormData) {
      console.log("bodyForm", bodyFormData);
      try {
        await this.$store.dispatch("registration/sendUserProfileFile", bodyFormData);
        this.$refs.alert.show(
          this.$t("alert.notification"),
          this.$t("pages.profile.success-photo"),
          {
            type: "success",
          }
        );
        this.$store.dispatch("auth/getUserData");
      } catch (e) {
        console.log("333");
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.user-tribes {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 40px;
  justify-content: left;
  align-items: center;

  @include sm {
    display: grid;
    justify-content: center;
    margin-bottom: 20px;
  }

  & .card {
    width: auto;

    @include sm {
      width: 240px;
    }

    .button {
      text-align: left;
    }

    &__tribe-dell {
      margin-left: 10px;
      min-width: 15px;
    }
  }

  &__show-more {
    color: $text-gray;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 4px;
    padding-bottom: 7px;
    width: fit-content;

    &-wrapper {
      display: block;
      width: 100%;
    }
  }
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: left;
  padding-left: 380px;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  scrollbar-width: none;

  @include md {
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: -4px;
  }

  @include sm {
    display: none;
    margin-bottom: 20px;
    padding-left: 12px;
    padding-right: 32px;
  }

  & .card {
    &:first-child {
      margin-left: 0;
    }

    display: inline-block;
  }
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .card {
    flex: 0 0 auto;
    margin-right: 3px;
  }
}

.scrolling-wrapper,
.scrolling-wrapper-flexbox {
  //flex: 1 1 auto;
  //-webkit-overflow-scrolling: touch;
  width: 100%;

  @include sm {
    width: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.add-industry-btn {
  .button--slot {
    font-size: 18px;
  }
}

.profile {
  &__description {
    text-align: left;

    @include sm {
      text-align: center;
    }
  }

  &__setting {
    cursor: pointer;
    top: 0;
    right: 16px;
    position: initial;

    @include sm {
      position: absolute;
      z-index: -10;
    }
  }

  &__user {
    display: flex;
    max-width: 1400px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 40px 0 40px;
    gap: 40px;

    @include md {
      padding: 40px 24px 0 24px;
      margin-bottom: 0;
    }

    @include sm {
      position: sticky;
      padding-bottom: 50px;
      display: grid;
      justify-items: center;
      z-index: 0;
      gap: 24px;
      background: linear-gradient(155deg, #7fc666 41.1%, #6fb157 92.06%);
      border-radius: 0px 0px 18px 18px;
    }
  }

  &__user-name {
    justify-content: left;

    @include md {
      justify-content: center;
    }
    @include sm {
      color: #fff;
      text-align: center;
      font-family: Muller;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
    h1 {
      text-align: left;
      font-size: 36px;

      @include sm {
        font-size: 32px;
        text-align: center;
      }
    }
  }

  &__tab-btn {
    font-size: 18px;
  }

  &__body {
    max-width: 755px;
    width: 100%;
    padding-top: 40px;

    @include md() {
      padding-top: 0;
    }
  }

  &__avatar-box {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 280px;
    overflow: inherit;
    max-height: var(--profile-avatar-size);

    @include md {
      max-width: 140px;
      justify-content: flex-start;
    }

    @include sm {
      border-radius: 50%;
      max-width: calc(var(--profile-avatar-size) + 40px);
      max-height: calc(var(--profile-avatar-size) + 40px);
      padding: 20px;
      justify-content: center;
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
  }

  &__avatar-select {
    opacity: 0;
    transition: all 0s;
    -webkit-transition: all 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
  }

  &__avatar {
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    max-height: var(--profile-avatar-size);
  }

  &__avatar-brand {
    display: none;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    position: absolute;
    background: $app-color;
    bottom: -8px;
    right: -8px;
    //display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    & img {
      width: 100%;
      padding: 4px;
    }
  }

  &__avatar:hover:after {
    opacity: 1;
  }

  &__avatar:hover > &__avatar-select {
    opacity: 1;
  }

  &__avatar.someone:hover:after,
  &__avatar.someone:hover > &__avatar-select {
    opacity: 0;
  }

  &__avatar:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0s;
    -webkit-transition: all 0.5s;
  }
}

.profile-tabs {
  &__control {
    &-item {
      @extend %text-standart;
      padding-bottom: 11px;
      margin-right: 24px;
      border-radius: 0;
      border-bottom: 2px solid transparent;
      color: $text-gray;

      &:hover {
        color: $text-gray;
      }

      &.router-link-active {
        border-color: $accent;
        color: $accent;

        @include md {
          @include desktop_standart;
          padding: 10px 20px;
          margin-right: 20px;
          border-radius: 24px;
          border-color: initial;
          border-bottom: none;
          background: $white;
        }
      }
    }
  }
}
.white-content {
  @include sm {
    position: relative;
    top: -50px;
  }
}
</style>
