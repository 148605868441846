<template>
  <div class="container">
    <div class="back-button" :class="{ 'mobile-show': showMobile }" @click="goBackMobile">
      <svg-icon name="back-btn" :height="24" :width="24" />
    </div>
    <h1 class="complete-project__header" :class="{ 'mobile-show': !showMobile }">
      {{ projectData?.demandInfo?.name }}
    </h1>
    <div class="complete-project__wrapper">
      <div
        class="complete-project__aside complete-project__col"
        :class="{ 'mobile-show': !showMobile }"
      >
        <div v-if="!isFinalState" class="complete-project__aside__info">
          <svg-icon name="message" :height="48" :width="48" />
          <div class="complete-project__aside__info__text">
            Оцени всех исполнителей, с которыми был опыт совместной работы
          </div>
        </div>
        <item-people
          v-if="projectData?.responds?.length"
          v-for="(user, index) in projectData.responds"
          :key="user.id + user.user_name + index"
          :id="user.id"
          :name="user.user_name"
          :company="user.user_company?.name_ru"
          :photo="user.photo"
          class="complete-project__aside__user"
          :class="{
            'complete-project__aside__user_active': user?.id === activeUserData?.id,
            'complete-project__aside__user_not-available':
              isFinalState && user?.id !== activeUserData?.id,
          }"
          @out-click="setActiveUser(user)"
          out-no-click
          full-path
        />
        <base-button class="complete-project__aside__button" @click="rateUserMobile">
          Оценить
        </base-button>
      </div>
      <div
        v-if="activeUserData"
        class="complete-project__content"
        :class="{ 'mobile-show': showMobile }"
      >
        <div class="complete-project__content__head">
          <avatar
            :fullname="activeUserData.user_name"
            :image="activeUserData.photo"
            size="140"
            class="complete-project__content__head__avatar"
          />
          <div>
            <h1>{{ activeUserData.user_name }}</h1>
          </div>
        </div>
        <div
          v-if="!activeUserData.services?.length"
          class="complete-project__content__no-services"
        >
          <h2>У данного исполнителя отсутствуют услуги</h2>
        </div>
        <div
          v-if="activeUserData.services?.length && !readyToComplete"
          class="complete-project__content__services"
        >
          <h1>Услуги</h1>
          <service-task
            v-for="service in activeUserData.services"
            :key="service.name + service.id"
            class="item"
            :rating="service.rating"
            :industry="service.name"
            :like="`${service.rating_count} рекомендации`"
            hover
          >
            <template v-slot:action>
              <base-button
                small
                round
                class="button--light service__company-success"
                icon-prefix="plus-round"
                icon-postfix-size="20"
                @click.stop
                @click="goRatePage(service.id, activeUserData.user_id, activeUserData.id)"
              >
                Оценка
              </base-button>
            </template>
          </service-task>
        </div>
        <div
          v-if="activeUserData.services?.length && readyToComplete"
          class="complete-project__content__services"
        >
          <h1 v-if="unratedServices?.length">Услуги без оценки</h1>
          <service-task
            v-for="service in unratedServices"
            :key="service.name + service.id"
            class="item"
            :rating="service.rating"
            :industry="service.name"
            :like="`${service.rating_count} рекомендации`"
            hover
          >
            <template v-slot:action>
              <base-button
                small
                round
                class="button--light service__company-success"
                icon-prefix="plus-round"
                icon-postfix-size="20"
                @click.stop
                @click="goRatePage(service.id, activeUserData.user_id, activeUserData.id)"
              >
                Оценка
              </base-button>
            </template>
          </service-task>
          <h1 :class="{ 'rated-services': unratedServices?.length }">Услуги с оценкой</h1>
          <service-task
            v-for="service in ratedServices"
            :key="service.name + service.id"
            class="item"
            :rating="service.rating"
            :industry="service.name"
            :like="`${service.rating_count} рекомендации`"
            hover
          >
            <template v-slot:action>
              <base-button
                small
                round
                class="button--light service__company-success"
                icon-prefix="plus-round"
                icon-postfix-size="20"
                @click.stop
                @click="goRatePage(service.id, activeUserData.user_id, activeUserData.id)"
              >
                Оценка
              </base-button>
            </template>
          </service-task>
          <base-button
            v-if="readyToComplete"
            class="complete-btn"
            @click="completeProject"
          >
            Завершить проект
          </base-button>
        </div>
      </div>
    </div>
  </div>
  <alert-modal ref="alert">
    <div @click="goProjects">К проектам</div>
  </alert-modal>
  <complete-project-warning-modal
    :is-open="isWarningModalOpen"
    @complete="completeAfterWarningModal"
    @close="closeWarningModal"
  />
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import Rating from "@/components/ui/Rating";
import ServiceTask from "@/components/ui/ServiceTask";
import BaseButton from "@/components/base/BaseButton";
import ItemPeople from "@/components/ui/ItemPeople";
import Avatar from "@/components/ui/Avatar";
import AlertModal from "@/components/modals/AlertModal";
import CompleteProjectWarningModal from "@/components/modals/CompleteProjectWarningModal";
import { mapGetters } from "vuex";

export default {
  name: "CompleteProjectPage",
  components: {
    SvgIcon,
    Rating,
    ServiceTask,
    BaseButton,
    ItemPeople,
    Avatar,
    AlertModal,
    CompleteProjectWarningModal,
  },
  data() {
    return {
      activeUserData: null,
      ratedServices: [],
      unratedServices: [],
      readyToComplete: false,
      isWarningModalOpen: false,
      completeAfterWarning: false,
      showMobile: false,
    };
  },
  computed: {
    ...mapGetters({
      projectData: "request/requestFinishingData",
    }),
    isFinalState() {
      const respondId = this.$route.params?.respondId;

      if (respondId) {
        this.showMobile = true;
      }
      return !!respondId;
    },
  },
  created() {
    this.getProjectData();
  },
  watch: {
    projectData(val) {
      if (val?.responds?.length) {
        if (this.isFinalState) {
          this.activeUserData = val.responds.find(
            (respond) => respond.id == this.$route.params?.respondId
          );
        } else {
          this.activeUserData = val.responds[0];
        }
      }
    },
    activeUserData(val) {
      if (val) {
        this.checkForRatedServices();
      }
    },
  },
  methods: {
    closeWarningModal() {
      this.isWarningModalOpen = false;
    },
    completeAfterWarningModal() {
      this.completeAfterWarning = true;
      this.completeProject();
      this.closeWarningModal();
    },
    goRatePage(serviceId, userId, respondId) {
      this.$router.push({
        path: `/complete-project/${this.$route.params.id}/service/${serviceId}/user/${userId}/respond/${respondId}`,
      });
    },
    goProjects() {
      this.$router.push({ path: "/projects" });
    },
    async getProjectData() {
      try {
        await this.$store.dispatch(
          "request/getRequestFinishingData",
          this.$route.params.id
        );

        if (this.projectData?.demandInfo?.is_completed) {
          this.$router.push("/");
        }
      } catch (err) {
        this.$refs.alert.show(this.$t("alert.error"), err, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    setActiveUser(val) {
      this.activeUserData = val;
    },
    checkForRatedServices() {
      const ratedServices = this.activeUserData.services.filter((item) => item?.is_rated);
      const unratedServices = this.activeUserData.services.filter(
        (item) => !item?.is_rated
      );

      if (ratedServices?.length) {
        this.ratedServices = ratedServices;
        this.unratedServices = unratedServices;
        this.readyToComplete = true;
      }
    },
    async completeProject() {
      try {
        if (this.unratedServices?.length && !this.completeAfterWarning) {
          this.isWarningModalOpen = true;
        } else {
          await this.$store.dispatch("request/completeRequest", {
            demand_id: this.$route.params.id,
          });

          this.$refs.alert.show(
            this.$t("alert.notification"),
            "Проект успешно завершен",
            {
              type: "success",
              fullCallback: true,
            },
            () => this.goProjects()
          );
        }
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    rateUserMobile() {
      this.showMobile = true;
    },
    goBackMobile() {
      this.showMobile = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto 10px;
  padding: 20px;
  @include lg {
    width: auto;
    min-width: auto;
  }
  @include md {
    padding: 0px 24px 24px 24px;
  }
  @include sm {
    padding: 0 12px 0 12px;
    margin-bottom: 30px;
  }
}

.complete-project {
  &__header {
    margin: 40px 0 30px;
    padding-left: 40px;

    @include md {
      display: none;
      padding-left: 16px;
      margin: 40px 0;
    }

    &.mobile-show {
      @include md {
        display: block;
      }
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 414px 1fr;
    gap: 16px;

    @include md {
      grid-template-columns: 100%;
      padding: 0;
    }
  }

  &__col {
    background-color: $white;
    border-radius: 24px;
    padding: 40px;

    @include md {
      display: none;
      padding: 30px 20px;
    }

    &.mobile-show {
      @include md {
        display: block;
      }
    }
  }

  &__aside {
    align-self: flex-start;

    &__info {
      width: 100%;
      height: max-content;
      display: flex;
      gap: 16px;
      margin-bottom: 30px;

      svg {
        min-width: 48px;
      }

      &__text {
        color: $accent;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.006em;
      }
    }

    &__user {
      padding: 18px 30px 18px 20px;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &_active {
        border-color: $text-gray;
      }

      &_not-available {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    &__button {
      display: none;

      @include md {
        display: block;
        margin-top: 40px;
      }
    }
  }

  &__content {
    background-color: $white;
    border-radius: 24px;

    &__head {
      width: 100%;
      padding: 30px 40px 40px 40px;
      border-bottom: 1px solid $dark-gray;
      display: flex;
      align-items: center;

      h1 {
        margin-bottom: 20px;
      }

      &__avatar {
        width: 140px;
        min-width: 140px;
        margin-right: 40px;
      }

      @include md {
        padding: 0;
      }

      @include sm {
        flex-direction: column;

        h1 {
          text-align: center;
          margin-bottom: 24px;
        }

        &__avatar {
          margin-right: 0;
          margin-bottom: 24px;
        }
      }
    }

    &__services {
      max-width: 530px;
      margin: 30px auto 60px auto;

      h1 {
        margin-bottom: 30px;
      }

      .item {
        margin-bottom: 16px;
      }

      @include md {
        max-width: unset;
        width: 100%;
        background-color: $white;
        border-radius: 24px;
        padding: 20px;
        padding-top: 30px;
      }
    }

    &__no-services {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;

      @include md {
        background-color: $white;
        border-radius: 24px;
        padding: 60px 20px;
        margin-top: 30px;
      }

      @include sm {
        text-align: center;
      }
    }

    @include md {
      display: none;
      background-color: transparent;
    }

    &.mobile-show {
      @include md {
        display: block;
      }
    }
  }
}

.tags-wrapper {
  display: flex;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
}

.tag {
  background: #f6f6f6;
  border-radius: 8px;
  padding: 10px 12px 10px 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 124%;
  max-width: 516px;

  @include md {
    background-color: $white;
  }
}

.rated-services {
  margin-top: 60px;
}

.complete-btn {
  margin-top: 40px;
}

.back-button {
  background-color: $white;
  border-radius: 7px;
  width: 56px;
  height: 56px;
  margin-top: 30px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;

  &.mobile-show {
    @include md {
      display: flex;
    }
  }
}
</style>
