<template>
  <div class="carousel">
    <ul class="carousel__list">
      <template v-if="isTask">
        <task-card-expert
          v-for="(item, idx) in carouselItems.slice(0, 5)"
          :class="[
            'task-card',
            'carousel__item',
            { carousel__item_active: item.isActive },
          ]"
          :key="idx"
          :name="item.name"
          :task="item.task"
          :solution="item.solution"
          :description="item.description"
          :period="item.period"
          :payment="item.payment"
          :photo="item.photo"
          :data-pos="item.position"
          :chips="item.chips"
          @click="updateC(idx)"
        >
        </task-card-expert>
      </template>
      <template v-else>
        <expert-card
          v-for="(item, idx) in carouselItems"
          :class="['carousel__item', { carousel__item_active: item.isActive }]"
          :key="item.expertName"
          :data-pos="item.position"
          @click="updateC(idx)"
          :expert-name="item.expertName"
          :expert-position="item.expertPosition"
          :description="item.description"
          :photo="item.photo"
          :chips="item.chips"
        >
        </expert-card>
      </template>
    </ul>
  </div>
</template>

<script>
import ExpertCard from "@/views/startpage/cards/ExpertCard";
import TaskCardExpert from "@/views/startpage/cards/TaskCardExpert";
export default {
  name: "CardSlider",
  components: { ExpertCard, TaskCardExpert },
  props: {
    cards: {
      type: Array,
      default: [],
    },
    isTask: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carouselItems: [],
      interval: null,
      idx: 0,
    };
  },
  created() {
    this.carouselItems = this.cards;
    this.interval = setInterval(() => {
      this.idx++;
      if (this.idx > this.carouselItems.length) this.idx = 0;
      this.carouselItems = this.changePosition(this.carouselItems, this.idx);
    }, 3500);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    changePosition(carouselItems, position) {
      const arr = carouselItems.map((item) => item.position);
      const index = arr.indexOf(position);
      const tt = [...arr.slice(index), ...arr.slice(0, index)];
      return carouselItems.map((item, index) => {
        const newItem = { ...item };
        newItem.position = tt.indexOf(index);
        return newItem;
      });
    },
    updateC(idx) {
      this.idx = idx;
      this.carouselItems = this.changePosition(this.carouselItems, idx);
      clearInterval(this.interval);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

html,
.task-card {
  height: 270px;
  width: 590px;
}
.carousel {
  position: relative;
  display: flex;
  align-items: center;
  font-family: Arial;

  &__list {
    display: flex;
    list-style: none;
    position: relative;
    justify-content: left;
  }

  &__item {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-shrink: 0;
    border-radius: 28px;
    position: absolute;
    transition: all 0.3s ease-in;

    &[data-pos="0"] {
      z-index: 4;
    }

    &[data-pos="1"] {
      transform: translateX(7%) scale(0.96);
      z-index: 3;
    }

    &[data-pos="2"] {
      transform: translateX(14%) scale(0.91);

      z-index: 2;
    }

    &[data-pos="3"] {
      z-index: 2;
    }

    &[data-pos="4"] {
      z-index: 2;
    }

    &[data-pos="5"] {
      z-index: 2;
    }
  }
}
</style>
