<template>
  <div class="container">
    <div class="header" :style="headerStyle">
      <div class="title">
        <h2 class="information__header">
          Услуги
        </h2>
        <add-button v-if="dataOptions.length > 0" @click="showAddModal" class="add action-info">Добавить</add-button>
      </div>
      <div v-if="$slots.description" class="description">
        <slot name="description"></slot>
      </div>
    </div>
    <div class="body">
      <service-task
          v-for="(option, idx) in dataOptions.filter(v => v.value)" :key="option.id"
          class="item"
          :rating="option.rating"
          :industry="option.name"
          :recommendation="option.rating_count"
          hover
      >
        <template v-slot:action>
          <SvgIcon name="service-dell" class="dell" @click="dell(option)"></SvgIcon>
        </template>
      </service-task>
      <info-box-add
          v-if="dataOptions.length <= 0"
          icon="star"
      >
        <template v-slot:title>
          Добавление услуг
        </template>
        <template v-slot:description>
          Прикрепите не более 3 услуг из вашего профиля
        </template>
        <template v-slot:action>
          <base-button class="action-info" @click="showAddModal">Добавить услуги</base-button>
        </template>
      </info-box-add>
    </div>

    <add-services-modal
        ref="addServicesModal"
        :items="services"
        :is-open="open"
        @close="open = false"
        v-model="dataOptions"
        :searchEvent="() => {}"
        @update:model-value="updateDataOptions"
    ></add-services-modal>

    <alert-modal :size="550" ref="services-alert"></alert-modal>

    <div v-if="isError" class="input-error">{{ errorString }}</div>
    <alert-modal ref="alert">
      ок
    </alert-modal>
  </div>
</template>

<script>

import AddButton from "@/components/ui/button/AddButton";
import {mapGetters} from "vuex";
import SvgIcon from "@/components/base/SvgIcon";
import ServiceTask from "@/components/ui/ServiceTask";
import InfoBoxAdd from "@/components/ui/InfoBoxAdd";
import BaseButton from "@/components/base/BaseButton";
import AlertModal from "@/components/modals/AlertModal";
import AddServicesModal from "@/components/modals/AddServicesModal";
export default {
  name: "ServicesList",
  components: {AddServicesModal, AlertModal, BaseButton, InfoBoxAdd, ServiceTask, SvgIcon, AddButton},
  props: {
    modelValue: {
      type: Array,
      default() {
        return [];
      }
    },
    userList: {},
    /**
     * Значения для ключа по умолчанию id
     */
    optionKey: {
      type: String,
      required: false,
      default: () => 'id'
    },
    headerStyle: null,
    isError: {
      type: Boolean,
      default: false
    },
    error: {
      type: Array,
      default() {
        return [{
          status: false,
          text: '',
        }]
      },
    },
  },
  data() {
    return {
      // dataOptions: [],
      open: false,
      load: false
    }
  },
  computed: {
    ...mapGetters({
      expertises: "service/expertises",
      services: "service/services",
      autoIncrement: "auth/autoIncrement",
      getServiceId: "service/service"
    }),
    dataOptions: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    errorString() {
      return this.error
          .filter((error) => error.status)
          .map((error) => error.text)
          .join(". ")
    },
  },
  methods: {
    updateValue() {
      this.$refs.addServicesModal.submit();
    },
    updateDataOptions(e) {
      this.dataOptions = e;
    },
    async getServicesExpertises(user) {
      try {
        await this.$store.dispatch('service/getServicesExpertises', {users: user, service: this.services})
      } catch (e) {
        this.$refs.alert.show(
            this.$t('alert.error'),
            e,
            {
              type: 'error',
              nameButton: 'Ок'
            }
        );
      }
    },
    dell (item) {
      this.$refs.addServicesModal.removeSelect([item])
      this.$refs.addServicesModal.submit();
    },
    showAddModal() {
      if (this.services?.length) {
        this.open = true;
      } else {
        this.$refs["services-alert"].show(
            'У вас нет активных услуг',
            'Создайте услугу от себя или от компании, и попробуйте откликнуться на заявку еще раз',
            {
              type: 'error',
              nameButton: 'Создать услугу'
            },
            () => {
              this.$router.push({path: '/services'})
            }
        );
      }
    },
    async getServiceList() {
      try {
        await this.$store.dispatch('service/getServices', true)
      } catch (e) {
        this.$refs.alert.show(
            this.$t('alert.error'),
            e,
            {
              type: 'error',
              nameButton: 'Ок'
            },
        );
      }
    }
  },
  created() {
    this.getServiceList();
  },
  watch: {
    userList: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        console.log(newValue, oldValue);
        if (newValue?.length !== oldValue?.length) {
          this.getServicesExpertises(newValue)
        }
        // if (this.load) {
        //   this.updateModel();
        // }
        // else
        //   this.load = true;
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
.action-info {
  width: 270px;
  max-width: 100%;

  @include sm {
    width: 100%;
  }
}
.description {
  @extend %desktop_additional;
  text-align: left;
  margin-bottom: 30px;
  width: 50%;
  @include md {
    margin-bottom: 24px;
  }
  color: $input-label;
  @include sm {
    margin-bottom: 30px;
    width: 100%;
  }
}
.body {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
}
.title {
  min-height: 54px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 46px 0 18px 0;
  @include md {
    margin: 24px 0 12px 0;
  }
  @include sm {
    display: grid;
    grid-template-columns: 100%;
  }
}
.add {
  width: auto;
  @include sm {
    margin: 25px 0 7px 0;
  }
}
.item:hover .dell {
  opacity: 1;
}
.dell {
  opacity: 0;
  @include md {
    opacity: 1;
  }
  transition: opacity 300ms;
  cursor: pointer;
}

.input-error {
  color: #EF693F;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}

.service-task.small {
  @include sm {
    padding: 20px;
  }
}
</style>
