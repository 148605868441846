import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import {useStore} from "vuex";

const state = useStore();

export const SUPPORT_LOCALES = ['en', 'ru']

export function setupI18n(options = { locale: state.getters["auth/lang"] }) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.locale = locale
  } else {
    i18n.locale.value = locale
  }
  /**
   * ПРИМЕЧАНИЕ:
   * Если вам нужно указать настройку языка для заголовков, например, API `fetch`, установите ее здесь.
   * Ниже приведен пример для axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
  //загружать сообщения локали с динамическим импортом
  const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
      )
  // установить локализацию и сообщение
  i18n.setLocaleMessage(locale, messages.default)
}