export default {
    _keyPrefix: 'pm_',

    _isValueStringify(value) {
        try {
            JSON.parse(value)
        } catch (erorr) {
            return false
        }

        return true
    },

    getKey(key) {
        return this._keyPrefix + key
    },

    getItem(key, type = 'local') {
        const value = window[`${type}Storage`].getItem(this.getKey(key))

        return value && this._isValueStringify(value) ? JSON.parse(value) : value
    },

    setItem(key, value, type = 'local') {
        window[`${type}Storage`].setItem(this.getKey(key), JSON.stringify(value))
    },

    removeItem(key, type = 'local') {
        window[`${type}Storage`].removeItem(this.getKey(key))
    },
}
