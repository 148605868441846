import ApiUtil from '@/utils/api'

export default {
  getServices: (activeOnly = false) =>
    ApiUtil(
      {
        url: `/api/service${activeOnly === true ? '?active-only=true' : ''}`,
        method: 'get',
        requiresAuth: true,
      },
    ),
  getServicesExpertises: (users) =>
    ApiUtil(
      {
        url: `/api/service/expertises${users != null ? '?' + users : ''}`,
        method: 'get',
        requiresAuth: true,
      },
    ),
  postService: (data) =>
    ApiUtil(
      {
        url: '/api/service',
        method: 'post',
        requiresAuth: true,
        data,
      },
    ),
  postServiceId: (id, data) =>
    ApiUtil(
      {
        url: `/api/service/${id}`,
        method: 'post',
        requiresAuth: true,
        data,
      },
    ),
  dellServices: (id) =>
    ApiUtil(
      {
        url: `/api/service/${id}`,
        method: 'delete',
        requiresAuth: true,
      },
    ),
  getServiceById: (id) =>
    ApiUtil(
      {
        url: `/api/service/${id}`,
        method: 'get',
        requiresAuth: true,
      },
    ),
  hideServices: (id) =>
    ApiUtil(
      {
        url: `/api/service/${id}/hide`,
        method: 'get',
        requiresAuth: true,
      },
    ),
  postServicesParticipate: (data) =>
    ApiUtil(
      {
        url: `/api/service/participate`,
        method: 'post',
        requiresAuth: true,
        data
      },
    ),
  getServiceTypes: () =>
    ApiUtil(
      {
        url: `/api/service-types`,
        method: 'get',
        requiresAuth: true,
      },
    ),
  getWorksList: () =>
    ApiUtil(
      {
        url: `/api/user/profile/works`,
        method: 'get',
        requiresAuth: true,
      },
    ),
  getCasesList: () =>
    ApiUtil(
      {
        url: `/api/user/cases`,
        method: 'get',
        requiresAuth: true,
      },
    ),
  getSomeoneCasesList: (id) =>
    ApiUtil(
      {
        url: `/api/user/cases/by-user/${id}`,
        method: 'get',
        requiresAuth: true,
      },
    ),
  getSpecialization: (payload) =>
    ApiUtil(
      {
        url: `/api/user/profile/abilities${payload}`,
        method: 'get',
        requiresAuth: true,
      },
    ),
  getWorkRoles: () =>
    ApiUtil(
      {
        url: `/api/work-roles`,
        method: 'get',
        requiresAuth: true,
      },
    ),
  deleteWorkItem: (id) =>
    ApiUtil(
      {
        url: `/api/user/profile/works/${id}`,
        method: 'delete',
        requiresAuth: true,
      },
    ),
  deleteCase: (id) =>
    ApiUtil(
      {
        url: `/api/user/cases/${id}`,
        method: 'delete',
        requiresAuth: true,
      },
    ),
  getSpecializationsList: (id) =>
    ApiUtil(
      {
        url: `/api/service/${id}/specializations`,
        method: 'get',
        requiresAuth: true,
      },
    ),
}
