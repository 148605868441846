<template>
  <div v-if="this.getNotMeState" class="alert-block">
    <div class="alert">
      <span
        >Просмотр от <b>{{ this.user.name }} {{ this.user.lastName }}</b></span
      >
      <a href="#" @click="signOut">Выйти</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BaseImpersonateModal",
  computed: {
    ...mapGetters({ getNotMeState: "auth/getNotMeState", user: "auth/user" }),
  },
  methods: {
    signOut() {
      this.$store.dispatch("auth/signOutImpersonate");
      this.$router.push({ name: "StartPage" }).then(() => {
        this.$router.go();
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.alert {
  padding: 2px 10px;
  display: flex;
  background-color: #fdc04b;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 12px 12px;
  top: 0px;
  position: fixed;
  z-index: 30;
  width: 40%;
  max-width: 40%;
  margin-left: 30%;

  @include sm {
    margin-left: 0%;
    width: 70%;
    max-width: 70%;
    gap: 10px;
  }

  &__header {
    @include desktop_h1;
  }

  &-block {
    display: block;
    align-items: center;
    justify-content: center;

    @include sm {
    }
  }
}

span {
  text-align: center;
  font-size: 16px;
  word-break: break-word;
}
</style>
