<template>
  <div class="container">
    <main-block
      :images="main_images"
      :cards="main_cards"
      :title="main_title"
      :button-title="button_title"
      :is-task="false"
    ></main-block>
  </div>

  <!--<div class="company">
    <company-scroller :images="company_logos" />
  </div>-->
  <div class="block-points" id="whoweare">
    <div class="container">
      <h1 class="title">Простое и удобное сотрудничество</h1>
      <points-block :points="points"></points-block>
    </div>
  </div>
  <div class="block-progress-background">
    <div class="block-progress">
      <div class="container">
        <progress-block
          :title="progress_title"
          :sub-title="progress_subTitle"
          :points="progress_points"
        ></progress-block>
      </div>
    </div>
  </div>
  <div class="tags">
    <tags-block :chips="chips" title="Популярные услуги"></tags-block>
  </div>
  <div class="container">
    <h1 class="title" :class="{ titleMobile: mobile }">
      Наши эксперты обладают лидирующим опытом в большинстве индустрий
    </h1>
    <solar-system v-if="!mobile" :items="solaritems" id="industries"></solar-system>
    <tags-block v-else :chips="solaritems" additional="100+ отраслей"></tags-block>
    <h1 class="title">Подберем эксперта или команду для развития вашего бизнеса</h1>
    <task-block :cards="taskcards" />
    <h1 class="title">Познакомьтесь с нашими экспертами</h1>
    <expert-block :cases="cases" :images="main_images" id="topthree"></expert-block>
  </div>
  <div class="block-green">
    <div class="container">
      <pre-footer-block></pre-footer-block>
    </div>
  </div>
</template>

<script>
import TagsBlock from "@/views/startpage/blocks/TagsBlock";
import TaskBlock from "@/views/startpage/blocks/TaskBlock";
import ProgressBlock from "@/views/startpage/blocks/ProgressBlock";
import PreFooterBlock from "@/views/startpage/blocks/PreFooterBlock";
import PointsBlock from "@/views/startpage/blocks/PointsBlock";
import ExpertBlock from "@/views/startpage/blocks/ExpertBlock";
import CompanyScroller from "@/views/startpage/components/CompanyScroller";
import MainBlock from "@/views/startpage/blocks/MainBlock";
import SolarSystem from "@/views/startpage/components/SolarSystem";

export default {
  name: "StartPage",
  components: {
    TagsBlock,
    TaskBlock,
    ProgressBlock,
    PointsBlock,
    CompanyScroller,
    SolarSystem,
    MainBlock,
    PreFooterBlock,
    ExpertBlock,
  },
  props: {
    main_images: {
      type: Array,
      default: [
        "personal/expert_stas_300.webp",
        "personal/expert_dmitri_300.webp",
        "personal/expert_olya_300.webp",
        "personal/expert_yuri_300.webp",
        "personal/expert_tanya_300.webp",
      ],
    },
    main_title: {
      type: String,
      default: "Привлекайте лучшую экспертизу для решения задач вашего бизнеса",
    },
    button_title: {
      type: String,
      default: "Подобрать эксперта",
    },
    main_cards: {
      type: Array,
      default: [
        {
          expertName: "Стас Горшенин",
          expertPosition: "ex-М.Видео",
          description:
            "Занимаюсь стратегическим консалтингом в ритейле: улучшением клиентского опыта и развитием OMNI-канальных продаж",
          photo: "personal/expert_stas.webp",
          chips: ["20 лет опыта", "Retail", "IT"],
          position: 0,
          isActive: true,
        },
        {
          expertName: "Дмитрий Михайлов",
          expertPosition: "ex-EY",
          description:
            "Занимаюсь управленческим консалтингом в области повышения операционной эффективности розничных компаний",
          photo: "personal/expert_dmitri.webp",
          chips: ["12 лет опыта", "Retail", " E-commerce"],
          position: 1,
          isActive: false,
        },
        {
          expertName: "Ольга Пушкина",
          expertPosition: "ex-Microsoft",
          description:
            "Помогаю ведущим компаниям разрабатывать маркетинговые стратегии, создавать продукты и реализовывать партнерские программы",
          photo: "personal/expert_olya.webp",
          chips: ["20 лет опыта", "IT", "Tech"],
          position: 2,
          isActive: false,
        },
        {
          expertName: "Юрий Макаров",
          expertPosition: "ex-BCG",
          description:
            "Помогаю компаниям в разработке стратегии и реализации программ трансформации и цифровизации",
          photo: "personal/expert_yuri.webp",
          chips: ["15 лет опыта", "Retail", "FMCG"],
          position: 3,
          isActive: false,
        },
        {
          expertName: "Татьяна Степанова",
          expertPosition: "ex-X5 Retail Group",
          description:
            "Занимаюсь стратегическим маркетингом и развитием ценностого предложения бизнеса",
          photo: "personal/expert_tanya.webp",
          chips: ["17 лет опыта", "Retail", "FMCG"],
          position: 4,
          isActive: false,
        },
        {
          expertName: "Андрей Флегонтов",
          expertPosition: "ex-Procter & Gamble",
          description:
            "Помогаю компаниям с ведением коммерческой деятельности и реализацией стратегий выхода на рынок",
          photo: "personal/expert_andrey.webp",
          chips: ["25 лет опыта", "FMCG", "Start-up"],
          position: 5,
          isActive: false,
        },
      ],
    },
    company_logos: {
      type: Array,
      default: [
        "companies/company_logo1.png",
        "companies/company_logo2.png",
        "companies/company_logo3.png",
        "companies/company_logo4.png",
        "companies/company_logo5.png",
        "companies/company_logo6.png",
        "companies/company_logo7.png",
        "companies/company_logo8.png",
        "companies/company_logo9.png",
        "companies/company_logo10.png",
        "companies/company_logo11.png",
        "companies/company_logo12.png",
        "companies/company_logo13.png",
        "companies/company_logo14.png",
        "companies/company_logo15.png",
      ],
    },
    points: {
      type: Array,
      default: [
        {
          task: "Опытные эксперты",
          solution:
            "Ведущие профессионалы из топовых компаний: от инновационных стартапов до крупных корпораций",
          img: "point1",
        },
        {
          task: "Быстрый поиск",
          solution: "98% запросов с внутренним поиском закрываются за неделю",
          img: "point2",
        },
        {
          task: "Единый договор",
          solution:
            "Не беспокойтесь о договорах и оплате: работайте в рамках одного договора для всей компании",
          img: "point3",
        },
        {
          task: "В разы дешевле консалтинга",
          solution:
            "Платите только за работу экспертов: не переплачивайте за работу с компаниями",
          img: "point4",
        },
      ],
    },
    progress_title: {
      type: String,
      default: "Подберем эксперта под вашу задачу",
    },
    progress_subTitle: {
      type: String,
      default: "за 3 простых шага",
    },
    progress_points: {
      type: Array,
      default: [
        {
          title: "Расскажите о проекте",
          subtitle:
            "Опишите задачи, определите сроки, бюджет, и какие компетенции вам потребуются",
        },
        {
          title: "Получите отклики экспертов",
          subtitle:
            "Рассмотрите предложения экспертов: опыт работы, подкрепленный кейсами, и условия сотрудничества",
        },
        {
          title: "Выберите подходящих исполнителей",
          subtitle:
            "Поговорите с понравившимиеся экспертами, обсудите детали и начинайте работать с лучшими",
        },
      ],
    },
    chips: {
      type: Array,
      default: [
        "Разработка стратегии",
        "Операционная эффективность",
        "Анализ рынка",
        "Управление персоналом",
        "Усиление ценностного предложения",
        "Клиентские исследования",
        "Финансовое моделирование",
        "Анализ лучших практик",
        "Коммерческая эффективность",
        "Планирование и отчетность",
        "Аудит",
        "Комплексная диагностика",
        "Оптимизация бизнес-процессов",
        "Эффективность цепочки поставок",
      ],
    },
    taskcards: {
      type: Array,
      default: [
        {
          task: "Разработать стратегию экспансии сети продуктовых магазинов на рынке СНГ",
          solution:
            "Оставьте заявку на подбор команды экспертов для реализации стратегии развития на международных рынках",
          description: "Клиент Gigoo",
          photo: "experts/exp1.jpg",
          name: "Даниил Т.",
          isMain: true,
        },
        {
          task:
            "Проанализировать проблемы пользователей при использовании мобильного приложения",
          solution:
            "Оставьте заявку на 5 часовых консультаций от ведущего СХ исследователя",
          description: "Клиент Gigoo",
          name: "Регина С.",
          photo: "experts/exp2.jpg",
          isMain: true,
        },
        {
          task: "Провести маркетинговое исследование рынка недвижимости ОАЭ",
          solution:
            "Оставьте заявку на подбор экспертной команды, указав необходимые сроки и бюджет",
          description: "Клиент Gigoo",
          name: "Анатолий Б.",
          photo: "experts/exp3.jpg",
          isMain: true,
        },
        {
          task: "Найти менеджера на консалтинговый проект в области цепочки поставок",
          solution: "Наймите PM’а с релевантным опытом работы в вашей отрасли",
          description: "Клиент Gigoo",
          name: "Артур П.",
          photo: "experts/exp4.jpg",
          isMain: true,
        },
        {
          task: "Провести диагностику CVP ведущего банка РФ",
          solution:
            "Оставьте заявку на подбор экспертной команды с релевантным опытом для вашего консалтингового проекта",
          description: "Клиент Gigoo",
          name: "Вячеслав С.",
          photo: "experts/exp5.jpg",
          isMain: true,
        },
      ],
    },
    solaritems: {
      type: Array,
      default: [
        "Финансы",
        "Инвестиции",
        "ИТ",
        "Медиа",
        "Медицина",
        "Недвижимость",
        "НКО",
        "Нефть и газ",
        "PR",
        "Retail",
        "Страхование",
        "Строительство",
        "Цепочки поставок",
        "Фармацевтика",
        "Энергетика",
      ],
    },
    cases: {
      type: Array,
      default: [
        {
          name: "Андрей Ф",
          description: "ex-Procter & Gamble",
          taskName: "8 лет на позиции коммерческого и исполнительного директора",
          solution:
            "Реализовал более 20 стратегических мультифункциональных проектов в компаниях сектора FMCG",
          photo: "personal/expert_andrey.webp",
          photo_tab: "personal/expert_andrey_300.webp",
          chips: ["17 лет опыта", "FMCG", "Retail"],
        },
        {
          name: "Стас Г",
          description: "ex-М.Видео",
          taskName: "10 лет в Tier 1 компании",
          solution:
            "Внедрил новые business-to-retail продукты: мобильное приложение, автоматизация поддержки, fintech-решения, экспресс-доставка, юнит-экономика",
          photo_tab: "personal/expert_stas_300.webp",
          photo: "personal/expert_stas.webp",
          chips: ["20 лет опыта", "Retail", "IT"],
        },
        {
          name: "Дмитрий М",
          description: "ex-EY",
          taskName: "20+ стратегических и операционных проектов в рознице",
          solution:
            "Разработал и внедрил новую методологию ценообразования для одного из крупнейших продуктовых ритейлеров",
          photo_tab: "personal/expert_dmitri_300.webp",
          photo: "personal/expert_dmitri.webp",
          chips: ["12 лет опыта", "Retail", "E-commerce"],
        },
        {
          name: "Ольга П",
          description: "ex-Microsoft",
          taskName: "20 лет опыта в передовых IT компаниях",
          solution:
            "Реализовала 50+ маркетинговых кампаний, в том числе по запуску новых продуктов и выходу на новые рынки",
          photo: "personal/expert_olya.webp",
          photo_tab: "personal/expert_olya_300.webp",
          chips: ["E-commerce", "FMCG", "20 лет опыта"],
        },
        {
          name: "Юрий М",
          description: "ex-BCG",
          taskName: "100+ стратегических и операционных проектов",
          solution:
            "Богатый опыт работы с компаниями из клиентоцентричных индустрий: retail, e-commerce, фарма, телеком, авто, маркетплейсы",
          photo: "personal/expert_yuri.webp",
          photo_tab: "personal/expert_yuri_300.webp",
          chips: ["15 лет опыта", "Retail", "FMCG"],
        },
        {
          name: "Татьяна C",
          description: "ex-X5 Retail Group",
          taskName: "17 лет опыта в Retail",
          solution:
            "Реализовала более 30 различных стратегических и операционных проектов в рознице. Например, реализация нового CVP и перезапуск бренда",
          photo: "personal/expert_tanya.webp",
          photo_tab: "personal/expert_tanya_300.webp",
          chips: ["17 лет опыта", "FMCG", "Retail"],
        },
      ],
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0px auto 0px;
  padding: 30px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include md {
    margin: 0px;
    width: 100%;
    padding: 0 30px;
    padding-bottom: 30px;
  }
}

.tags {
  max-width: 1440px;
  width: 100%;
  margin: 0px auto 0px;
  padding: 30px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include md {
    margin: 0px;
    width: 100%;
    padding: 30px;
  }
}

.company {
  width: 100%;
  position: relative;
  display: flex;
  padding-top: 60px;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: center;
  @include md {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.block-points {
  background-color: #ffffff;
  border-radius: 40px 40px 0px 0px;
}

.block-progress {
  background: #3d3e49;
  border-radius: 40px;
}

.block-green {
  background: linear-gradient(90deg, #6fb057 0%, #86cd6d 68.92%);
  border-radius: 40px;
}

.block-progress-background {
  background: #ffffff;
  border-radius: 0px 0px 40px 40px;
}

.title {
  font-family: Muller;
  color: var(--text, #000);
  font-weight: 500;
  font-size: 50px;
  text-align: center;
  margin: 30px 0px;
  margin-bottom: 60px;
  width: 80%;
  &.titleMobile {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  @include md {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-style: normal;
    line-height: 30px; /* 100% */
    letter-spacing: -0.6px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0px;
  }
}
</style>
