<template>
  <div class="container_m" ref="solarSection">
    <div class="blur top"></div>
    <div class="solar-system">
      <div id="sun">
        <img
          :src="$store.getters['auth/assets'] + 'img/gigoo_sun.png'"
          class="sun_gigoo"
          alt=""
        />
      </div>

      <div class="orbit mercury-orbit" ref="mercuryorbit">
        <div id="container" :style="current_params.mercury">
          <div class="item">{{ items[0] }}</div>
          <div class="item">{{ items[1] }}</div>
          <div class="item">{{ items[2] }}</div>
          <div class="item">{{ items[3] }}</div>
        </div>
      </div>

      <div class="orbit venus-orbit" ref="venusorbit">
        <div id="container" :style="current_params.venus">
          <div class="item">{{ items[4] }}</div>
          <div class="item">{{ items[5] }}</div>
          <div class="item">{{ items[6] }}</div>
          <div class="item">{{ items[7] }}</div>
        </div>
      </div>

      <div class="orbit earth-orbit" ref="earthorbit">
        <div id="container" :style="current_params.earth">
          <div class="item">{{ items[8] }}</div>
          <div class="item">{{ items[9] }}</div>
          <div class="item">{{ items[10] }}</div>
          <div class="item">{{ items[11] }}</div>
          <div class="item">{{ items[12] }}</div>
          <div class="item">{{ items[13] }}</div>
          <div class="item additional">100+ отраслей</div>
        </div>
      </div>
    </div>
    <div class="blur bot"></div>
    <div class="blur left"></div>
    <div class="blur right"></div>
  </div>
</template>

<script>
export default {
  name: "CompanyScroller",
  props: {
    items: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      orbit_params: {
        desktop: {
          mercury: "--n:4;--d:35s;--w:560px;--h:560px;",
          venus: "--n:4;--d:55s;--w:840px;--h:840px;",
          earth: "--n:6;--d:55s;--w:1180px;--h:1180px;",
        },
        mobile: {
          mercury: "--n:4;--d:25s;--w:280px;--h:280px;",
          venus: "--n:4;--d:35s;--w:420px;--h:420px;",
          earth: "--n:6;--d:55s;--w:590px;--h:590px;",
        },
      },
      planet_appears: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
      },
      current_params: null,
      top: 0,
      bottom: 0,
      delta: 0,
      scrollPosition: null,
      section_percent: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    const ut = this.$refs.solarSection;
    const rect = ut.getBoundingClientRect();
    this.top = rect.top;
    this.bottom = rect.bottom;
    this.delta = this.bottom - this.top;
  },
  created() {
    this.current_params = this.mobile
      ? this.orbit_params.mobile
      : this.orbit_params.desktop;
    window.addEventListener("scroll", this.updateScrollSolar);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("scroll", this.updateScrollSolar);
  },
  computed: {
    mobile() {
      return (
        this.$vuetify.display.md || this.$vuetify.display.sm || this.$vuetify.display.xs
      );
    },
  },
  methods: {
    onResize() {
      this.current_params = this.mobile
        ? this.orbit_params.mobile
        : this.orbit_params.desktop;
    },
    updateScrollSolar() {
      const mercuryorbit = this.$refs.mercuryorbit;
      const venusorbit = this.$refs.venusorbit;
      const earthorbit = this.$refs.earthorbit;

      this.scrollPosition = window.scrollY - this.top + this.delta;

      if ((this.scrollPosition > 0) & (this.scrollPosition < this.delta)) {
        this.section_percent = Math.round((100 * this.scrollPosition) / this.delta);

        if (this.section_percent >= 0) {
          mercuryorbit.classList.add("mercury-orbit-create");
          venusorbit.classList.add("venus-orbit-create");
          mercuryorbit.classList.add("mercury-orbit-create");
          earthorbit.classList.add("earth-orbit-create");

          setTimeout(() => {
            this.planet_appears[0] = true;
          }, 3000);
          setTimeout(() => {
            this.planet_appears[1] = true;
          }, 1000);
          setTimeout(() => {
            this.planet_appears[2] = true;
          }, 3000);
          setTimeout(() => {
            this.planet_appears[3] = true;
          }, 4000);

          setTimeout(() => {
            this.planet_appears[4] = true;
          }, 2000);
          setTimeout(() => {
            this.planet_appears[5] = true;
          }, 4000);
          setTimeout(() => {
            this.planet_appears[6] = true;
          }, 3000);
          setTimeout(() => {
            this.planet_appears[7] = true;
          }, 5000);

          setTimeout(() => {
            this.planet_appears[8] = true;
          }, 4000);
          setTimeout(() => {
            this.planet_appears[9] = true;
          }, 6000);
          setTimeout(() => {
            this.planet_appears[10] = true;
          }, 8000);
          setTimeout(() => {
            this.planet_appears[11] = true;
          }, 5000);
          setTimeout(() => {
            this.planet_appears[12] = true;
          }, 6000);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

html,
body {
  /* width: 100%;
  height: 100%; */
  background-color: #121215;
}

.container_m {
  position: relative;
  width: 100%;
  height: 900px;
  margin: auto;
  overflow: hidden;

  @include md {
    height: 700px;
  }
}

.solar-system {
  position: absolute;
  width: 100%;
  height: 800px;
  margin: auto;
  overflow: hidden;

  @include md {
    height: 700px;
  }
}

.blur {
  position: absolute;
  width: 100%;
  height: 40px;

  background-color: #eeeeee;
  filter: blur(5px);
  z-index: 10;

  &.top {
    margin-top: -15px;
  }
  &.left {
    width: 30px;
    height: 100%;
    left: -15px;
  }
  &.right {
    width: 30px;
    height: 100%;
    right: -15px;
  }
  &.bot {
    position: absolute;
    top: 790px;

    @include md {
      top: 670px;
    }
  }
}

.solar-system::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

/* Some positioning rules that are common for all objects */

#sun,
.orbit,
.orbit + div,
.orbit + div > div {
  position: absolute;
  border-radius: 50%;
}

#sun,
.orbit,
.orbit + div {
  top: 50%;
  left: 50%;
}

/* Individual objects rules. They are pretty much the same for 
all objects and basically only vary in orbit size 
and planet size. And colors :) */
.sun_gigoo {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  z-index: 10;
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 15px;

  @include md {
    padding: 5px;
  }
}

#sun {
  /* Positions the top-left corner of the image to be *
	/* in the middle of the box */
  position: relative;
  height: 200px;
  width: 200px;
  margin-top: -100px;
  margin-left: -100px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 0 30px rgb(0, 0, 0);
  z-index: 10;
  display: flex;

  @include md {
    height: 100px;
    width: 100px;
    margin-top: -50px;
    margin-left: -50px;
  }
}

.mercury-orbit {
  opacity: 0;
  width: 560px;
  height: 560px;
  margin-left: -280px;
  margin-top: -280px;
  border: 1px solid #a0a0a0;

  @include md {
    width: 280px;
    height: 280px;
    margin-left: -140px;
    margin-top: -140px;
  }
}

.venus-orbit {
  opacity: 0;
  width: 840px;
  height: 840px;
  margin-left: -420px;
  margin-top: -420px;
  border: 1px solid #a0a0a0;

  @include md {
    width: 420px;
    height: 420px;
    margin-left: -210px;
    margin-top: -210px;
  }
}

.earth-orbit {
  opacity: 0;
  width: 1180px;
  height: 1180px;
  margin-left: -590px;
  margin-top: -590px;
  border: 1px solid #a0a0a0;

  @include md {
    width: 590px;
    height: 590px;
    margin-left: -295px;
    margin-top: -295px;
  }
}

/* Orbit creation animations */

@keyframes create-orbit-earth {
  0% {
    transform: scale(0);
    opacity: 0;
    border: 1px solid rgba(160, 160, 160, 0);
  }

  50% {
    border: 1px solid rgba(160, 160, 160, 0.1);
  }

  100% {
    transform: scale(1);
    border: 1px solid rgba(160, 160, 160, 0.2);
    opacity: 1;
  }
}

@keyframes create-orbit-mercury {
  0% {
    opacity: 0;
    transform: scale(0);
    border: 1px solid rgba(160, 160, 160, 0);
  }

  100% {
    transform: scale(1);
    border: 1px solid rgba(160, 160, 160, 0.7);
    opacity: 1;
  }
}

@keyframes create-orbit-venus {
  0% {
    opacity: 0;
    transform: scale(0);
    border: 1px solid rgba(160, 160, 160, 0);
  }

  50% {
    border: 1px solid rgba(160, 160, 160, 0.25);
  }

  100% {
    transform: scale(1);
    border: 1px solid rgba(160, 160, 160, 0.5);
    opacity: 1;
  }
}

.earth-orbit-create {
  animation: create-orbit-earth 1s linear 0s forwards;
  transform-origin: center;
}

.venus-orbit-create {
  animation: create-orbit-venus 1s linear 0s forwards;
  transform-origin: center;
}

.mercury-orbit-create {
  animation: create-orbit-mercury 1s linear 0s forwards;
  transform-origin: center;
}

#container {
  --n: 7;
  /* number of item */
  --d: 12s;
  /* duration */
  --w: 200px;
  /* number of item */
  --h: 200px;
  /* duration */
  width: var(--w);
  height: var(--h);
  display: grid;
  grid-template-columns: -40px;
  grid-template-rows: 40px;
  place-content: center;

  @include md {
    grid-template-columns: 0px;
    grid-template-rows: 30px;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.test {
  opacity: 0;
  animation-name: appear;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: var(--lag);
}

.item {
  opacity: 1;
  position: relative;
  grid-area: 1/4;
  display: block;
  text-align: center;
  border-radius: 50px;
  background: #fff;
  padding: 8px 24px;
  animation: spin var(--d) linear 30s infinite;
  transform: rotate(0) translate(calc(var(--w) * 0.5)) rotate(0);

  color: #42434e;
  font-size: 20px;
  font-family: SFProDisplay;
  font-style: normal;

  @include md {
    display: block;
    font-size: 14px;
    justify-content: space-between;
    padding: 4px 8px;
  }

  &.additional {
    background: #6fb057;
    color: #ffffff;
  }

  &.lag3 {
    animation: create-planet 3s, spin var(--d) linear 30s infinite;
  }
}

@keyframes create-planet {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  100% {
    transform: rotate(1turn) translate(calc(var(--w) * 0.5)) rotate(-1turn);
  }
}

.item:nth-child(1) {
  animation-delay: calc(-0 * var(--d) / var(--n));
}

.item:nth-child(2) {
  animation-delay: calc(-1 * var(--d) / var(--n));
}

.item:nth-child(3) {
  animation-delay: calc(-2 * var(--d) / var(--n));
}

.item:nth-child(4) {
  animation-delay: calc(-3 * var(--d) / var(--n));
}

.item:nth-child(5) {
  animation-delay: calc(-4 * var(--d) / var(--n));
}

.item:nth-child(6) {
  animation-delay: calc(-5 * var(--d) / var(--n));
}

.item:nth-child(7) {
  animation-delay: calc(-6 * var(--d) / var(--n));
}

.item:nth-child(8) {
  animation-delay: calc(-7 * var(--d) / var(--n));
}

.item:nth-child(9) {
  animation-delay: calc(-8 * var(--d) / var(--n));
}
</style>
