<template>
  <base-modal size="550" ref="add-competence" :is-open="isOpen" @close="closeModal">
    <div class="competence-modal">
      <h2 class="information__header">Добавить компетенцию</h2>
      <base-input
        placeholder="Найти компетенцию"
        class="desired-clients__input"
        v-model="searchText"
        search
        @update:model-value="updateSearch"
      />

      <div class="competence-modal__add-new" v-if="!searchList.length && searchText">
        Таких компетенций не найдено. Добавить новую?
      </div>
      <div class="competence-scroll">
        <DynamicScroller
          v-if="!searchText.length"
          :items="newList"
          :min-item-size="54"
          class="scroller"
        >
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem
              :item="item"
              :active="active"
              :size-dependencies="[item.message]"
              :data-index="index"
            >
              <competence-item
                :key="item.id + searchText"
                :info="item"
                @update="updateInfo"
              />
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
        <DynamicScroller v-else :items="searchList" :min-item-size="54" class="scroller">
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem
              :item="item"
              :active="active"
              :size-dependencies="[item.message]"
              :data-index="index"
            >
              <div :key="item.id">
                <competence-item
                  :key="item.id + searchText"
                  :info="item"
                  @update="updateInfo"
                />
              </div>
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </div>
    </div>
    <div class="mt-24">
      <base-button
        v-if="!isShow"
        :disabled="!selectedList.length"
        @click="submitCompetence"
        >Сохранить</base-button
      >
      <base-button v-else :disabled="isEqual" @click="addCompetence"
        >Добавить</base-button
      >
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";
import { mapGetters } from "vuex";
import CompetenceItem from "@/components/ui/CompetenceItem";
import BaseInput from "@/components/base/BaseInput";

export default {
  name: "AddCompetenceModal",
  components: { BaseInput, CompetenceItem, BaseButton, BaseModal },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedList: [],
      searchText: "",
      searchList: [],
      currentState: [],
      createdCompetence: [],
      isEqual: false,
    };
  },
  methods: {
    async getServicesExpertises() {
      try {
        await this.$store.dispatch("service/getServicesExpertises", {});
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    closeModal() {
      this.$emit("close");
      this.selectedList = [];
      this.createdCompetence = [];
    },
    updateInfo(val) {
      this.changeEqual();

      this.$refs["add-competence"].updateSize();

      if ((val.children && !val.children.length) || !val.children) {
        const index = this.selectedList.findIndex((el) => el.id === val.id);
        if (index !== -1) {
          this.selectedList.splice(index, 1);
        } else {
          this.selectedList.push(val);
        }
      }
    },
    updateSearch(e) {
      this.changeEqual();
      let result = JSON.parse(JSON.stringify(this.competenceFlat));
      result = result.filter((el) => !result.find((item) => el.id === item.parent_id));
      let clearCreated = this.createdCompetence.map((item) => {
        if (item.checked === true) {
          item.checked = false;
        }
        return item;
      });
      result = [...result, ...clearCreated];

      result = result.filter((option) => {
        let item = option["name"]
          .toString()
          .toLowerCase()
          .includes(this.searchText.toString().toLowerCase());
        return item;
      });

      if (result && this.selectedList) {
        result = result.map((item) => {
          let index = this.selectedList.findIndex((el) => el.id === item.id);
          if (index !== -1) {
            item.checked = true;
          }

          return item;
        });
      }
      this.searchList = result;
    },
    addCompetence() {
      this.createdCompetence.push({
        name: this.searchText,
        parent_id: null,
        status: "На модерации",
        isNew: true,
        id: this.competenceFlat.length + 1,
      });

      this.updateSearch();
    },
    submitCompetence() {
      let payload = {
        items: [],
        new_items: [],
      };

      this.selectedList.forEach((item) => {
        if (item.isNew) {
          payload.new_items.push({ name: item.name });
        } else {
          payload.items.push({ expertise_id: item.id });
        }
      });

      this.$emit("addCompetence", payload);
      this.getServicesExpertises();
      this.selectedList = [];
      this.createdCompetence = [];
    },
    changeEqual() {
      let isCreate = this.createdCompetence.filter((v) =>
        v.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
      let isCompetence = this.competenceFlat.filter((v) =>
        v.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
      if (isCreate.length || isCompetence.length) {
        this.isEqual = true;
        return;
      }
      this.isEqual = false;
    },
  },
  computed: {
    ...mapGetters({
      competenceFlat: "profile/competenceFlat",
    }),
    newList() {
      if (this.competenceFlat) {
        this.currentState = JSON.parse(JSON.stringify(this.competenceFlat));
        let clearCreated = this.createdCompetence.map((item) => {
          if (item.checked === true) {
            item.checked = false;
          }
          return item;
        });

        this.currentState = [...this.currentState, ...clearCreated];
        if (this.selectedList.length) {
          this.selectedList.map((item) => {
            this.currentState = this.currentState.map((el) => {
              if (item.id === el.id) {
                el.checked = item.checked;
              }
              return el;
            });
            return item;
          });
        }
        return this.currentState;
      }
      return [];
    },
    isShow() {
      return !!this.searchText && !this.isEqual;
    },
  },
  watch: {
    isOpen() {
      this.searchText = "";
      this.newList;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.competence-modal {
  &::v-deep .vue-recycle-scroller::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::v-deep .vue-recycle-scroller::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::v-deep .vue-recycle-scroller::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999;
    padding-left: 4px;
  }

  &::v-deep .vue-recycle-scroller__item-view {
    padding-right: 4px;
  }
}

.scroller {
  height: 100%;
}

.competence-scroll {
  height: 50vh;
  overflow-y: auto;
}
.ps {
  max-height: 50vh;
  width: calc(100% + 18px);
  padding-right: 18px;
}

.information__header {
  @include desktop_h2_norm;
  margin-bottom: 24px;
}

.competence-modal__add-new {
  color: $text-gray;
  font-size: 16px;
}
</style>
