import { profileService } from "@/services";
import { errorFormat } from "@/utils/helper";
import registrationService from "@/services/registrationService";

/**
 @typedef Services
 */
export default {
    namespaced: true,
    state: {
        workChangeItem: [],
        caseChangeItem: [],
        userExpertise: {
            expertises: [],
            institutions: [],
        },
        institutions: [],
        publications: [],
        support: {},
        competenceFlat: [],
        userRecommendation: [],
        userRecommendationFromOthers: [],
        expertCouncil: [],
        taxes: [],
        someUser: []
    },
    actions: {
        async sendCompetence({ commit }, payload) {
            try {
                await registrationService.sendUserProfile(payload);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async sendCase({ commit }, payload) {
            try {
                await profileService.sendCase(payload);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async sendUserResume({ commit }, payload) {
            try {
                await registrationService.sendUserResume(payload);
            } catch (e) {
                throw errorFormat(e.response);
            }
        },
        async processUserResume({ commit }, payload) {
            try {
                await registrationService.processUserResume(payload);
            } catch (e) {
                throw errorFormat(e.response);
            }
        },
        async sendUserIndustries({ commit }, payload) {
            try {
                await registrationService.sendUserIndustries(payload);
            } catch (e) {
                throw errorFormat(e.response);
            }
        },
        async sendUserOptions({ commit }, payload) {
            try {
                await registrationService.sendUserOptions(payload);
            } catch (e) {
                throw errorFormat(e.response);
            }
        },
        async dellUserResume({ commit }) {
            try {
                await registrationService.dellUserResume();
            } catch (e) {
                throw errorFormat(e.response);
            }
        },
        async getItemWork({ commit }, id) {
            try {
                const response = await profileService.getItemWork(id);
                commit('SET_CHANGE_ITEM', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getItemCase({ commit }, id) {
            try {
                const response = await profileService.getItemCase(id);
                commit('SET_CHANGE_CASE_ITEM', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getUserExpertise({ commit }, id) {
            try {
                const response = await profileService.getUserExpertise(id);
                commit('SET_USER_EXPERTISE', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async postProfileExpertiseConfirm({ commit }, data) {
            try {
                const response = await profileService.postProfileExpertiseConfirm(data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async getInstitutions({ commit }, data) {
            try {
                const response = await profileService.getInstitutions(data);
                commit('SET_INSTITUTIONS', response.data.data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async postProfileWorksInviteRate({ commit }, data) {
            try {
                await profileService.postProfileWorksInviteRate(data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async postProfileWorksInviteRates({ commit }, data) {
            try {
                await profileService.postProfileWorksInviteRates(data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async getPublicationList({ commit }) {
            try {
                const response = await profileService.getPublications();
                commit('SET_PUBLICATIONS', response.data.data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async sendPublications({ commit }, data) {
            try {
                await profileService.sendPublications(data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async deletePublications({ commit }, id) {
            try {
                await profileService.deletePublications(id);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async deleteExpertisesItem({ commit }, id) {
            try {
                await profileService.deleteExpertisesItem(id);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async updatePassword({ commit }, data) {
            try {
                await profileService.updatePassword(data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async getSupportInfo({ commit }) {
            try {
                const response = await profileService.getSupportInfo();
                commit('SET_SUPPORT', response.data.data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async getCompetenceFlat({ commit }) {
            try {
                const response = await profileService.getCompetenceFlat();
                commit('SET_COMPETENCE_FLAT', Object.values(response.data.expertises_tree));
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getTaxes({ commit }) {
            try {
                const response = await profileService.getTaxes();
                commit('SET_TAXES', Object.values(response.data.data));
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        updateCompetence({ commit }) {
            commit('UPDATE_COMPETENCE');
        },
        async delInstitution({ commit }, id) {
            try {
                await profileService.delInstitution(id);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async getUserRecommendation({ commit }) {
            try {
                const response = await profileService.getUserRecommendation();
                commit('SET_USER_RECOMMENDATION', response.data.data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async getUserRecommendationFromOthers({ commit }) {
            try {
                const response = await profileService.getUserRecommendationFromOthers();
                commit('SET_USER_RECOMMENDATION_FROM_OTHERS', response.data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async remindRecommendation({ commit }, data) {
            try {
                await profileService.remindRecommendation(data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async dropIsNewFlagRecommendationFromOthers({ commit }, id) {
            try {
                await profileService.dropIsNewFlagRecommendationFromOthers(id);
            } catch (e) {
                console.log(errorFormat(e.response.data))
                throw errorFormat(e.response.data);
            }
        },
        async declineRecommendationFromOthers({ commit }, id) {
            try {
                await profileService.declineRecommendationFromOthers(id);
                const response = await profileService.getUserRecommendationFromOthers();
                commit('SET_USER_RECOMMENDATION_FROM_OTHERS', response.data);
            } catch (e) {
                console.log(errorFormat(e.response.data))
                throw errorFormat(e.response.data);
            }
        },
        async getExpertCouncilList({ commit }) {
            try {
                const response = await profileService.getExpertCouncilList();
                commit('SET_EXPERT_COUNCIL', response.data.data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async sendCouncilRequest({ commit }, payload) {
            try {
                await profileService.sendCouncilRequest(payload);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async deleteItemCouncil({ commit }, id) {
            try {
                await profileService.deleteItemCouncil(id);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async postRateUser({ commit }, data) {
            try {
                await profileService.postRateUser(data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
        async getSomeUserInfo({ commit }, id) {
            try {
                const response = await profileService.getSomeUserInfo(id);
                commit('SET_SOME_USER', response.data.data);
                return response.data.data
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
    },
    mutations: {
        SET_CHANGE_ITEM(state, value) {
            state.workChangeItem = value;
        },
        SET_CHANGE_CASE_ITEM(state, value) {
            state.caseChangeItem = value;
        },
        SET_USER_EXPERTISE(state, value) {
            state.userExpertise = value;
        },
        SET_INSTITUTIONS(state, value) {
            state.institutions = value;
        },
        SET_PUBLICATIONS(state, value) {
            state.publications = value;
        },
        SET_SUPPORT(state, value) {
            state.support = value;
        },
        SET_COMPETENCE_FLAT(state, value) {
            state.competenceFlat = value;
        },
        SET_TAXES(state, value) {
            state.taxes = value;
        },
        SET_EXPERT_COUNCIL(state, value) {
            state.expertCouncil = value;
        },
        UPDATE_COMPETENCE(state, value) {
            let list = state.competenceFlat;
            let userList = state.userExpertise.expertises;
            if (userList.length) {
                userList.forEach(item => {
                    list = Object.values(list).map(el => {
                        if (el.id === item.expertise_id) {
                            el.checked = true;
                            el.disabled = true;
                        }
                        return el;
                    })
                })
            }

            let newList = JSON.parse(JSON.stringify(list))

            const setParents = function (parentId, checked) {
                newList.forEach(i => {
                    if (i.id == parentId) {
                        i.checked = checked;
                        if (i.parent_id !== null) {
                            setParents(i.parent_id, checked);
                        }
                    }
                });
            };

            newList.forEach(i => {
                if (i.checked) {
                    setParents(i.parent_id, i.checked);
                }
            });
            // newList = newList.filter(item => {
            //   if (item.parent_id) {
            //     item.parent_id = null
            //   }
            //   return item
            // })
            state.competenceFlat = newList;
        },
        SET_USER_RECOMMENDATION(state, value) {
            state.userRecommendation = value;
        },
        SET_USER_RECOMMENDATION_FROM_OTHERS(state, value) {
            state.userRecommendationFromOthers = value;
        },
        SET_SOME_USER(state, value) {
            state.someUser = value;
        },
    },
    getters: {
        workChangeItem(state) {
            return state.workChangeItem
        },
        caseChangeItem(state) {
            return state.caseChangeItem
        },
        userExpertise(state) {
            return state.userExpertise
        },
        institutions(state) {
            return state.institutions;
        },
        taxes(state) {
            return state.taxes;
        },
        publications(state) {
            return state.publications;
        },
        support(state) {
            return state.support;
        },
        competenceFlat(state) {
            return state.competenceFlat
        },
        userRecommendation(state) {
            return state.userRecommendation
        },
        userRecommendationFromOthers(state) {
            return state.userRecommendationFromOthers
        },
        expertCouncil(state) {
            return state.expertCouncil;
        },
        someUser(state) {
            return state.someUser;
        }
    }
}