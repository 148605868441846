import {questionsService} from "@/services";

export default {
   namespaced: true,
  state: {
    questions: []
  },
   actions: {
    async getQuestions({ commit }, data) {
        const response = await questionsService.getQuestions(data);
        commit('SET_QUESTIONS', response.data);
    },
  },
  mutations: {
    SET_QUESTIONS(state, value) {
      state.questions = value
    },
  },
  getters: {
    questions(state) {
      return state.questions
    },
  },
}