<template>
  <div class="point" :class="{ big: isBig }">
    <div class="img-container">
      <svg-icon :width="mobile ? 51 : 175" :height="mobile ? 51 : 175" :name="img" />
    </div>
    <div class="header">
      <div class="title">{{ task }}</div>
      <div class="subtitle">{{ solution }}</div>
    </div>
  </div>
</template>
<script>
import Avatar from "@/components/ui/Avatar";
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "PointsCard",
  components: {
    Avatar,
    BaseButton,
    SvgIcon,
  },
  computed: {
    mobile() {
      return (
        this.$vuetify.display.md || this.$vuetify.display.sm || this.$vuetify.display.xs
      );
    },
  },
  props: {
    task: {
      type: String,
      default: "Опытные эксперты",
    },
    solution: {
      type: String,
      default: "Могут сделать что угодно в любой сфере",
    },
    img: {
      type: String,
      default: "point1",
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.point {
  display: grid;
  grid-template-rows: 220px 1fr;
  width: 305px;
  padding: 30px;
  border-radius: 20px;

  background: rgba(240, 240, 240, 0.8);
  &.big {
    width: 405px;
    @include md {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 20px;
      min-height: 0px;
    }
  }
  @include md {
    display: flex;
    flex-direction: row;
    align-items: middle;
    padding: 15px;
    width: 100%;
  }
}
.header {
  align-items: start;
  display: grid;
  grid-template-rows: 1fr 2fr;
  height: 100%;
  @include md {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-around;
  }
}
.img-container {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  @include md {
    align-content: start;
    padding-bottom: 0px;
    padding-right: 30px;
  }
}

.point_img {
  object-fit: contain;
  height: 175px;
  width: 175px;

  @include md {
    height: 51px;
    width: 51px;
    object-fit: contain;
    margin-right: 30px;
  }
}

.title {
  font-size: 26px;
  font-family: Muller;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.52px;
  display: flex;
  @include md {
    font-size: 18px;
    line-height: 16px;
  }
}

.subtitle {
  color: $text-gray;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.18px;
  padding-top: 10px;
  @include md {
    padding-top: 0px;
    line-height: 16x;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
