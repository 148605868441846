<template>

  <ve-progress
      :color="colorRange(range)"
      thickness="4"
      :size="getSize"
      vue-ellipse-progress
      :progress="getRange"
      class="range-container"
  >
    <span class="service-task__range">
      {{ getRange === 0 ? '—' : range }}
    </span>
  </ve-progress>
</template>

<script>
export default {
  name: "Rating",
  props: {
    rangeMax: {
      type: [Number, String],
      default: 5
    },
    rangeMin: {
      type: [Number, String],
      default: 0
    },
    range: {
      type: [Number, String],
      default: 0
    },
    rangeGrin: {
      type: [Number, String],
      default: 4
    },
    rangeYellow: {
      type: [Number, String],
      default: 3
    },
    rangeRed: {
      type: [Number, String],
      default: 0
    },
    small: Boolean,
    isDay: Boolean,
    inverse: Boolean,
    sizeXs: Boolean,
    noColor: Boolean
  },
  methods: {
    beginsWithFloat(val) {
      val = parseFloat(val);
      return !isNaN(val);
    },
    colorRange(value) {
      if (!this.noColor) {
        if (!this.isDay) {
          if (value >= 4) {
            return '#7FC666'
          } else if (value >= 3) {
            return '#FDC04B';
          } else if (value > 0) {
            return '#EF693F';
          } else {
            return '#ebedf3'
          }
        } else {
          if (value >= 7) {
            return '#7FC666'
          } else {
            return '#FDC04B'
          }
        }
      }
      return '#7FC666'
    },
  },
  computed: {
    getSize() {
      if (this.sizeXs) {
        return 54
      }
      return this.small === true ? 60 : 72
    },
    getRange() {
        let range = 0;
        if (this.range != null && (typeof this.range === 'number'))
          range = this.range;
        else if (this.range != null && this.beginsWithFloat(this.range)) {
          range = parseFloat(this.range);
        }
      if (!this.inverse) {
        return Math.round((100 / (this.rangeMax - this.rangeMin)) * (range - this.rangeMin));
      } else {
        return Math.round((100 / (this.rangeMax - this.rangeMin)) * (this.rangeMax - range));
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';

.range-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.service-task__range {
  @include desktop_h2;
  font-weight: 600;
  color: $accent;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-40%);
}
</style>
