<template>
  <container>
    <template v-slot:nav> </template>
    <template v-slot:body>
      <div>
        <h1 class="block-header" id="income">Оценить специалистов</h1>
        <div class="recommendation-list">
          <p v-if="!userRecommendationFromOthers.length" class="empty_text">
            У вас нет активных запросов рекомендаций
          </p>
          <response-recommendation
            v-else
            v-for="item in paginatedFilteredIncome"
            :key="`recommendation_${item.id}`"
            :title-composition="item.user"
            :description="item.user.email"
            :name="nameString(item.recommendations)"
            :is-unseen="!item.is_viewed"
            recommendation-type="request"
          >
            <template #btn>
              <div class="control-double">
                <base-button small @click="toRecommend(item, true)">Оценить</base-button>
                <base-button small light @click="toRecommend(item, false)"
                  >Не оценивать</base-button
                >
              </div>
            </template>
          </response-recommendation>
        </div>
        <v-pagination
          v-if="userRecommendationFromOthers.length > recommendationsPerPage"
          v-model="currentIncomePage"
          :length="
            Math.ceil(userRecommendationFromOthers.length / recommendationsPerPage)
          "
        ></v-pagination>
      </div>
    </template>
  </container>
  <alert-modal ref="alert"></alert-modal>
</template>

<script>
import Container from "@/components/layout/Container";
import { mapGetters } from "vuex";
import AlertModal from "@/components/modals/AlertModal";
import BaseButton from "@/components/base/BaseButton";
import ResponseRecommendation from "@/components/component/ResponseRecommendation";
export default {
  name: "ProfileRecommendationPage",
  components: { ResponseRecommendation, BaseButton, AlertModal, Container },
  data() {
    return {
      currentIncomePage: 1,
      recommendationsPerPage: 7,
    };
  },
  computed: {
    ...mapGetters({
      userRecommendationFromOthers: "profile/userRecommendationFromOthers",
    }),
    paginatedFilteredIncome() {
      let items_paginated = JSON.parse(JSON.stringify(this.userRecommendationFromOthers));
      items_paginated = items_paginated.sort((a, b) => a.is_viewed - b.is_viewed);
      if (items_paginated.length > this.recommendationsPerPage) {
        const startIndex = (this.currentIncomePage - 1) * this.recommendationsPerPage;
        const endIndex = startIndex + this.recommendationsPerPage;
        return items_paginated.slice(startIndex, endIndex);
      }
      return items_paginated;
    },
  },

  created() {
    this.getUserRecommendationFromOthers();
  },
  methods: {
    async getUserRecommendationFromOthers() {
      try {
        this.$store.dispatch("content/isLoading", true);
        await this.$store.dispatch("profile/getUserRecommendationFromOthers");
        this.$store.dispatch("content/isLoading", false);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    nameString(recommendations) {
      let name = recommendations.map((recommendation) => recommendation.title).join(", ");

      return name.length > 50 ? name.slice(0, 50) + "..." : name;
    },
    async dropFlag(ids) {
      await this.$store.dispatch("profile/dropIsNewFlagRecommendationFromOthers", ids);
    },

    async declineRecommendation(id) {
      await this.$store.dispatch("profile/declineRecommendationFromOthers", id);
    },

    toRecommend(item, confirm) {
      let ids = item.recommendations.map((recommendation) => recommendation.id);
      this.dropFlag(ids);
      if (confirm) {
        this.$router.push({ path: `/multi-rate-user`, query: { hash: item.hash } });
      } else {
        this.declineRecommendation(item.user.id);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.block-header {
  margin-bottom: 30px;
}

.empty_text {
  color: $text-gray;
  font-size: 18px;
}

.recommendation-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.control-double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @include sm {
    display: flex;
    flex-direction: column;
  }
}

.empty_text {
  color: $text-gray;
  font-size: 18px;
}
</style>
