<template>
  <div class="block">
    <div class="photo_expert">
      <div class="chips-group is-no-mobile">
        <div v-for="(item, index) in shortchips" :key="item + index" class="chip">
          {{ item }}
        </div>
      </div>
      <img
        class="img"
        :src="$store.getters['auth/assets'] + 'img/' + photo"
        alt=""
        loading="lazy"
      />
    </div>
    <div class="title">
      <div>
        <div class="chips-group is-mobile">
          <div v-for="(item, index) in chips" :key="item + index" class="chip">
            {{ item }}
          </div>
        </div>
        <h1 class="expert">{{ taskName }}</h1>
        <div class="description">{{ solution }}</div>
        <div class="is-mobile">
          <base-button
            @click="this.$router.push({ name: 'RegistrationPage' })"
            class="button--success main-button"
          >
            Начать работу</base-button
          >
          <experts-show class="expert-show" :images="images" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "@/components/ui/Avatar";
import BaseButton from "@/components/base/BaseButton";
import ExpertsShow from "@/views/startpage/components/ExpertsShow";

export default {
  name: "TaskCard",
  components: {
    Avatar,
    BaseButton,
    ExpertsShow,
  },
  computed: {
    shortchips() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs
        ? this.chips.slice(1, 3)
        : this.chips;
    },
  },
  props: {
    taskName: {
      type: String,
      default: "",
    },
    solution: {
      type: String,
      default: "",
    },
    photo: {
      type: String,
      default: "",
    },
    chips: {
      type: Array,
      default: [],
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: [],
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.block {
  min-height: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 20px;
  justify-content: flex-start;
  align-items: top;

  width: 100%;
  @include md {
    display: block;
    display: relative;
    justify-content: space-around;
    align-items: center;
  }
}
.title {
  display: flex;
  border-radius: 20px;
  padding-left: 30px;
  align-content: left;
  text-align: left;
  min-height: 376px;
  margin-bottom: 10px;
  max-height: 500px;
  @include md {
    padding-top: 30px;
    padding-left: 0px;
    color: #000;
    /* Muller 26px */
    font-size: 26px;
    font-family: Muller;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.52px;
    min-height: 0px;
  }
}
.expert-show {
  display: block;
  @media screen and (max-width: 1300px) {
    display: none;
  }
}
.img {
  width: 432px;
  object-fit: cover;
  border-radius: 60px;
  position: relative;
  border-radius: 20px;
  @include md {
    display: block;
    border-radius: 20px;
    object-position: 50% 0%;
    position: relative;
    max-width: 300px;
    height: 449px;
    vertical-align: bottom;
  }
}
.photo_expert {
  width: 432px;
  height: 659px;
  position: relative;
  display: flex;

  @include md {
    align-items: center;
    position: relative;
    display: flex;
    border-radius: 20px;
    height: 100%;
    max-width: 300px;
    //background-color: #0000001e;
  }
}

.expert {
  color: var(--text, #000);
  /* Muller 40px */
  font-size: 30px;
  font-family: Muller;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.8px;
  //width: 100%;

  @include md {
    position: relative;
    display: flex;
  }
}

.description {
  color: #a0a0a0;
  font-size: 18px;
  /* SF Pro Dicplay 20px */
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
  padding-top: 24px;

  &-demo {
    font-size: 16px;
  }

  @include md {
    padding-top: 0px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
  }

  @include sm {
    margin-top: 5px;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 124%;
  }
}

.main-button {
  width: 285px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.chips-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 30px;
  margin-top: 10px;
  gap: 10px;
  flex-wrap: wrap;
  @include md {
    position: absolute;

    top: 10px;
    left: 10px;
    z-index: 10;
  }

  @include sm {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
  }

  &.is-mobile {
    display: flex;

    @include sm {
      display: none;
    }

    @include md {
      display: none;
    }
  }

  &.is-no-mobile {
    display: none;

    @include sm {
      display: flex;
    }

    @include md {
      display: flex;
    }
  }
}

.is-mobile {
  display: block;

  @include sm {
    display: none;
  }

  @include md {
    display: none;
  }
}

.chip {
  @extend %desktop_additional;
  overflow: hidden;
  display: flex;
  padding: 15px;
  align-items: center;
  border-radius: 30px;
  background-color: #f6f6f6;
  color: #4b4c57;
  overflow: hidden;
  flex-wrap: wrap;
  line-height: 10px;
  white-space: nowrap;
  @include md {
    color: var(--accent, #42434e);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px; /* 157.143% */
    letter-spacing: -0.14px;
  }
}
</style>
