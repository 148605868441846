<template>
  <base-modal size="440" :is-open="isOpen" @close="closeModal">
    <div class="body">
      <h1 class="body__title">
        Завершить проект
      </h1>
      <p class="body__subtitle mb-24">
        Вы оценили не все услуги. Уверены, что хотите завершить проект?
      </p>
      <div class="body__buttons">
        <base-button :error="true" @click="submit">
          Завершить
        </base-button>
        <base-button :light="true" @click="closeModal">
          Вернуться
        </base-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";

export default {
  name: 'CompleteProjectWarningModal',
  components: { BaseModal, BaseButton },
  props: {
    isOpen: {
      type: Boolean
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    submit() {
      this.$emit('complete');
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';

.body {
  &__title {
    font-size: 32px;
    margin-bottom: 12px;
    line-height: 100%;
    text-align: center;
    width: 100%;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    width: 100%;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    @include sm {
      > button {
        max-width: 137px;
      }
    }
  }

  @include sm {
    max-width: 290px;
  }
}
</style>