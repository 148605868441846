<template>
  <div
    class="input-radio"
    :class="{
      radio: type === 'radio',
      checkbox: type === 'checkbox',
      star: type === 'star',
      switch: isSwitch,
      'switch-description': description,
      error: isError,
    }"
  >
    <input
      :type="type === 'star' ? 'checkbox' : type"
      :value="true"
      :name="name"
      v-model="model"
      :id="'checkbox' + uid"
    />

    <span class="input-radio__decor" @click="updateRadio" v-if="!isService"></span>
    <label class="input-radio__decor" :for="'checkbox' + uid" v-else></label>
    <label class="input-radio__container-label" :for="'checkbox' + uid">
      <template v-if="!description">
        <p class="input-radio__title">{{ title }}</p>
      </template>
      <div v-else class="input-radio__content">
        <p class="input-radio__title">{{ title }}</p>
        <p v-if="description" class="input-radio__description">{{ description }}</p>
      </div>
      <p v-if="status" class="input-radio__status">
        {{ status === "moderation" ? "На модерации" : "" }}
      </p>
    </label>
  </div>
  <div class="input-radio__error" v-if="isError">
    <base-error-text v-if="isError" :error="error" :class="{ error: isError }" />
  </div>
</template>

<script>
import BaseErrorText from "@/components/base/BaseErrorText";
import { getCurrentInstance } from "vue";

export default {
  name: "BaseRadio",
  components: { BaseErrorText },
  props: {
    type: {
      type: String,
      default: "radio",
    },
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Boolean],
      default: null,
    },
    value: {
      type: Array,
      default: () => [],
    },
    isSwitch: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isService: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uid = getCurrentInstance().uid;

    return {
      uid: uid,
    };
  },
  computed: {
    model: {
      get() {
        if (this.modelValue == null) return false;
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    updateRadio() {
      if (!this.isService) {
        this.$emit("update:modelValue", !this.model);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.input-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;

  & label {
    margin-bottom: 0;
    cursor: pointer;
  }

  &__error {
    grid-column: span 2;

    .error-text {
      margin-bottom: 0;
    }
  }
  &__container-label {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__title {
    @extend %text-standart;
  }

  input {
    display: none;
  }

  &.radio {
    .input-radio__decor {
      flex-shrink: 0;
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid $line-light-gray;
    }

    input {
      &:checked + .input-radio__decor {
        background: $green 50% 55% no-repeat;
        border: none;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.26863 1.16984C8.57548 0.863001 9.07296 0.863001 9.3798 1.16984L9.44514 1.23518C9.75198 1.54202 9.75198 2.0395 9.44514 2.34635L4.23678 7.5547L3.06028 6.3782L8.26863 1.16984Z' fill='white'/%3E%3Crect x='5.41016' y='6.37695' width='1.66383' height='5.98634' rx='0.831913' transform='rotate(135 5.41016 6.37695)' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &.star {
    padding: 15px 0;
    .input-radio__decor {
      display: flex;
      width: 48px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='22' viewBox='0 0 44 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.2373 2.38009C20.107 -0.259507 23.8938 -0.259511 24.7635 2.38009L27.8708 11.8112C28.2598 12.9917 29.3753 13.7909 30.6339 13.7909H40.6896C43.5039 13.7909 44.6741 17.3425 42.3972 18.9739L34.262 24.8026C33.2438 25.5322 32.8177 26.8254 33.2066 28.0058L36.314 37.437C37.1837 40.0766 34.1202 42.2716 31.8433 40.6402L23.7081 34.8115C22.6898 34.0819 21.311 34.0819 20.2927 34.8115L12.1575 40.6402C9.88063 42.2716 6.81709 40.0766 7.68678 37.437L10.7942 28.0058C11.1831 26.8254 10.757 25.5322 9.73876 24.8026L1.60355 18.9739C-0.67334 17.3425 0.496825 13.7909 3.31122 13.7909H13.3669C14.6255 13.7909 15.741 12.9917 16.13 11.8112L19.2373 2.38009Z' fill='%23A0A0A0'/%3E%3C/svg%3E%0A");
    }

    input {
      &:checked + .input-radio__decor {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='22' viewBox='0 0 44 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.2373 2.38009C20.107 -0.259507 23.8938 -0.259511 24.7635 2.38009L27.8708 11.8112C28.2598 12.9917 29.3753 13.7909 30.6339 13.7909H40.6896C43.5039 13.7909 44.6741 17.3425 42.3972 18.9739L34.262 24.8026C33.2438 25.5322 32.8177 26.8254 33.2066 28.0058L36.314 37.437C37.1837 40.0766 34.1202 42.2716 31.8433 40.6402L23.7081 34.8115C22.6898 34.0819 21.311 34.0819 20.2927 34.8115L12.1575 40.6402C9.88063 42.2716 6.81709 40.0766 7.68678 37.437L10.7942 28.0058C11.1831 26.8254 10.757 25.5322 9.73876 24.8026L1.60355 18.9739C-0.67334 17.3425 0.496825 13.7909 3.31122 13.7909H13.3669C14.6255 13.7909 15.741 12.9917 16.13 11.8112L19.2373 2.38009Z' fill='%23FDC04B'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &.checkbox,
  &.radio {
    padding: 0;

    input {
      & + .input-radio__decor {
        background-color: #fff;
        order: 1;
        flex-shrink: 0;
      }
    }

    .input-radio {
      &__title {
        @extend %text-standart;
        flex-grow: 1;
        overflow: hidden;
        margin-right: 10px;
        text-overflow: ellipsis;
      }

      &__status {
        flex-shrink: 0;
        color: #a0a0a0;
        font-size: 16px;
        line-height: 20px;
        margin-right: 16px;
      }
    }
  }

  &.checkbox {
    border-radius: 5px;

    .input-radio__decor {
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 5px;
      border: 1px solid $line-light-gray;
    }

    input {
      &:checked + .input-radio__decor {
        background: $green 50% 55% no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='-1 -0.5 15 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.6799 0.561953C13.1897 0.071797 12.395 0.071797 11.9049 0.561953L5.87016 6.59665L2.14263 2.86911C1.65247 2.37895 0.857772 2.37895 0.367616 2.86911C-0.122539 3.35927 -0.122539 4.15396 0.367617 4.64412L4.97897 9.25548C5.06485 9.34135 5.16007 9.41218 5.26136 9.46797C5.73892 9.73368 6.3531 9.66373 6.75873 9.25811L13.6799 2.33696C14.17 1.84681 14.17 1.05211 13.6799 0.561953Z' fill='white'/%3E%3C/svg%3E");
      }
    }
  }

  &.switch {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 15px 0;

    .input-radio__title {
      @include desktop_standart;
    }

    input {
      & + .input-radio__decor {
        position: relative;
        order: 1;
        flex-shrink: 0;
        background-color: #eeeeee;
        width: 56px;
        height: 32px;
        transition: 0.5s;
        border-radius: 8px;
        border: none;

        &:before {
          display: block;
          position: absolute;
          content: "";
          background-color: #fff;
          left: 4px;
          top: 3px;
          width: 25px;
          height: 26px;
          transition: 0.4s;
          border-radius: 6px;
        }
      }

      &:checked + .input-radio__decor {
        background: $green 50% 55% no-repeat;

        &:before {
          left: 27px;
          transition: 0.4s;
        }
      }
    }
  }

  &.switch-description {
    padding: 12px 0;

    .input-radio {
      &__title {
        margin-bottom: 9px;
      }

      &__content {
        @include sm() {
          padding-right: 30px;
        }
      }

      &__description {
        @include desktop_additional;
        color: $text-gray;
      }
    }
  }
}
</style>
