<template>
  <div class="container">
    <div class="header" :style="headerStyle">
      <div>
        <h2 ref="select" class="title">{{ title }}</h2>
        <add-button
          v-if="modelValue.length > 0 && !quickView"
          @click="showAddModal"
          class="add"
          >Добавить сотрудников</add-button
        >
      </div>
    </div>
    <div class="body">
      <item-people
        v-for="(option, idx) in modelValue"
        :key="option.autoIncrement"
        class="item"
        :name="option.name"
        :company="option.user_company != null ? option.user_company.name : null"
        :rating="option.rating"
        :photo="option.photo"
        :need-approve="checkNeedApprove(option)"
        :invate="option.type === 'invited'"
        can-disabled
      >
        <template v-slot:action>
          <SvgIcon
            name="service-dell"
            class="dell"
            @click="dell([option])"
            v-if="!quickView"
          ></SvgIcon>
        </template>
      </item-people>
    </div>
    <info-box-add v-if="modelValue.length <= 0" icon="crown">
      <template v-slot:title> Соберите свою команду </template>
      <template v-slot:description>
        Объедините компетенции и опыт с другими специалистами и повышайте
        привлекательность вашей услуги для заказчика
      </template>
      <template v-slot:action>
        <base-button class="action-info" @click="showAddModal" v-if="!quickView"
          >Добавить сотрудников</base-button
        >
      </template>
    </info-box-add>
    <add-people-team-modal
      ref="add-people-modal"
      :is-open="open"
      @close="open = false"
      :items="userSearch"
      :searchEvent="searchEventWorkTypeDebounce"
      @update:model-value="(v) => $emit('update:modelValue', v)"
      :model-value="modelValue"
    ></add-people-team-modal>

    <div v-if="isError" class="input-error">{{ errorString }}</div>
    <alert-modal ref="alert"></alert-modal>
  </div>
</template>

<script>
import ItemPeople from "@/components/ui/ItemPeople";
import AddButton from "@/components/ui/button/AddButton";
import AddPeopleTeamModal from "@/components/modals/AddPeopleTeamModal";
import { mapGetters } from "vuex";
import SvgIcon from "@/components/base/SvgIcon";
import InfoBoxAdd from "@/components/ui/InfoBoxAdd";
import BaseButton from "@/components/base/BaseButton";
import AlertModal from "@/components/modals/AlertModal";
import { debounce } from "@/utils/helper";
export default {
  name: "TeamList",
  components: {
    AlertModal,
    BaseButton,
    InfoBoxAdd,
    SvgIcon,
    AddPeopleTeamModal,
    AddButton,
    ItemPeople,
  },
  data() {
    return {
      open: false,
      searchEventWorkTypeDebounce: null,
    };
  },
  created() {
    this.searchEventWorkTypeDebounce = debounce(this.searchEventWorkType, 400);
    this.getUserSearch();
  },
  computed: {
    ...mapGetters({
      autoIncrement: "auth/autoIncrement",
      userSearch: "auth/userSearch",
      userAuth: "auth/user",
    }),
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
  },
  props: {
    modelValue: {},
    quickView: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Кто с вами в команде?",
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    /**
     * Значения для ключа по умолчанию id
     */
    optionKey: {
      type: String,
      required: false,
      default: () => "id",
    },
    headerStyle: null,
    isError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
  },
  methods: {
    checkNeedApprove(item) {
      if (item.id === this.userAuth.id) return false;
      else return item.need_approve;
    },
    focus() {
      let element = this.$refs.select;
      let top = element.offsetTop;
      window.scrollTo(0, top - 20);
    },
    searchEventWorkType(v) {
      this.$store.dispatch("auth/getUserSearch", v);
    },
    async getUserSearch() {
      try {
        await this.searchEventWorkTypeDebounce(null);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    dell(item) {
      this.$refs["add-people-modal"].deleteItem(item);
    },
    showAddModal() {
      this.open = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.action-info {
  width: auto;
  @include sm {
    width: 100%;
  }
}
.body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  row-gap: 16px;
  @include sm {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
.title {
  @include desktop_h2_norm;
  min-height: 54px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0 30px 0;
}
.add {
  width: auto;
  @include sm {
    margin: 24px 0 32px 0;
  }
}
.item:hover .dell {
  opacity: 1;
}
.dell {
  opacity: 0;
  @include md {
    opacity: 1;
  }
  transition: opacity 300ms;
  cursor: pointer;
}
.input-error {
  color: #ef693f;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}
</style>
