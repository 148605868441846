<template>
  <div class="container">
    <div class="header" :style="headerStyle">
      <div class="title">
        <h2 ref="select" class="information__header">
          Какие ваши компетенции релевантны в рамках данной услуги?
        </h2>
        <add-button v-if="dataOptions.length > 0 && !quickView" @click="showAddModal" class="add">Добавить компетенции</add-button>
      </div>
      <div v-if="$slots.description" class="description">
        <slot name="description"></slot>
      </div>
    </div>
    <div class="body">
      <service-task
          v-for="(option, idx) in dataOptions.filter(v => v.value)" :key="option.id"
          class="item"
          :rating="option.rating"
          :industry="option.name"
          :user-counter="lengthOfUsers(option.users)"
          :like="counterLick(option.rating_count)"
          small
          hover
      >
        <template v-slot:action>
          <SvgIcon name="service-dell" class="dell" @click="dell([option])" v-if="!quickView"></SvgIcon>
        </template>
      </service-task>
      <info-box-add
          v-if="dataOptions.length <= 0"
          icon="star"
      >
        <template v-slot:title>
          <div class="body__title">
            Выберите компетенции из тех, которыми обладаете вы или ваша команда
          </div>
        </template>
        <template v-slot:description>
          <div class="body__description">
            Релевантные компетенции с высоким рейтингом повышают ваш шанс, что заказчик выберет именно вас
          </div>
        </template>
        <template v-slot:action>
          <base-button class="action-info" @click="showAddModal" v-if="!quickView">Добавить компетенции</base-button>
        </template>
      </info-box-add>
    </div>

    <add-expertises-modal
      ref="addExpertisesModal"
      :items="expertisesMock(expertises)"
      :is-open="open"
      @close="open = false"
      v-model="dataOptions"
      :searchEvent="() => {}"
      @update:model-value="updateDataOptions"
    ></add-expertises-modal>

    <alert-modal ref="expertises-alert"></alert-modal>

    <div v-if="isError" class="input-error">{{ errorString }}</div>
    <alert-modal ref="alert">
      ок
    </alert-modal>
  </div>
</template>

<script>

import AddButton from "@/components/ui/button/AddButton";
import {mapGetters} from "vuex";
import SvgIcon from "@/components/base/SvgIcon";
import ServiceTask from "@/components/ui/ServiceTask";
import AddExpertisesModal from "@/components/modals/AddExpertisesModal";
import InfoBoxAdd from "@/components/ui/InfoBoxAdd";
import BaseButton from "@/components/base/BaseButton";
import AlertModal from "@/components/modals/AlertModal";
import {declOfNum} from "@/utils/helper";
export default {
  name: "ExpertisesList",
  components: {AlertModal, BaseButton, InfoBoxAdd, AddExpertisesModal, ServiceTask, SvgIcon, AddButton},
  props: {
    modelValue: {
      type: Array,
      default() {
        return [];
      }
    },
    quickView: {
      type: Boolean,
      default: false
    },
    userList: {},
    /**
     * Значения для ключа по умолчанию id
     */
    optionKey: {
      type: String,
      required: false,
      default: () => 'id'
    },
    headerStyle: null,
    isError: {
      type: Boolean,
      default: false
    },
    error: {
      type: Array,
      default() {
        return [{
          status: false,
          text: '',
        }]
      },
    },
  },
  data() {
    return {
      // dataOptions: [],
      open: false,
      load: false
    }
  },
  computed: {
    ...mapGetters({
      expertises: "service/expertises",
      services: "service/service",
      autoIncrement: "auth/autoIncrement"
    }),
    dataOptions: {
      get() {
        console.log(this.modelValue);
        return this.modelValue.map(v => {

          let itemList = this.expertises.filter(item => item.id === v.id)
          if (itemList.length > 0)
            return {...v, ...itemList[0]}
          else
            return {...v, ...{like: '--'}}
        })
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    errorString() {
      return this.error
          .filter((error) => error.status)
          .map((error) => error.text)
          .join(". ")
    },
  },
  mounted() {
    console.log(this.userList);
  },
  methods: {
    lengthOfUsers(arr) {
      if (arr) {
        return arr.length
      }
    },
    counterLick(count) {
      if (count != 0) {
        return `${count == null ? 0 : count} ${declOfNum(count, ['оценка','оценки','оценок'])}`
      }
      return false
    },
    focus() {
      let element = this.$refs.select;
      let top = element.offsetTop;
      window.scrollTo(0, top - 20);
    },
    updateValue() {
      this.$refs.addExpertisesModal.submit();
    },
    updateDataOptions(e) {
      this.dataOptions = e;
    },
    expertisesMock(array) {
      return array.map(v => {
        let itemList = this.expertises.filter(item => item.id === v.id)
        if (itemList.length > 0)
          return {...v, ...itemList[0]}
        else
          return {...v, ...{like: '--'}}
      })
    },
    async getServicesExpertises(user) {
      try {
        await this.$store.dispatch('service/getServicesExpertises', {users: user, service: this.services})
      } catch (e) {
        this.$refs.alert.show(
            this.$t('alert.error'),
            e,
            {
              type: 'error',
              nameButton: 'Ок'
            }
        );
      }
    },
    dell (item) {
      this.$refs.addExpertisesModal.deleteItem(item)
    },
    showAddModal() {
      this.open = true;
    },
  },
  watch: {
    userList: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        console.log(newValue, oldValue);
        if (newValue?.length !== oldValue?.length) {
          this.getServicesExpertises(newValue)
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
  @import 'src/assets/scss/vuetify_variables';
  .action-info {
    width: auto;
    @include sm {
      width: 100%;
    }
  }
  .description {
    @extend %desktop_additional;
    text-align: left;
    margin-bottom: 30px;
    width: 50%;
    @include md {
      margin-bottom: 24px;
    }
    color: $input-label;
    @include sm {
      margin-bottom: 30px;
      width: 100%;
    }
  }
  .body {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;

    &__title, &__description {
      max-width: 395px;
    }
  }
  .title {
    min-height: 54px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0 0 0;
    @include md {
      margin: 42px 0 0 0;
    }
    @include sm {
      display: grid;
      margin: 48px 0 0 0;
    }
  }
  .add {
    width: auto;
    @include sm {
      margin: 25px 0 7px 0;
    }
  }
  .item:hover .dell {
    opacity: 1;
  }
  .dell {
    opacity: 0;
    @include md {
      opacity: 1;
    }
    transition: opacity 300ms;
    cursor: pointer;
  }

  .input-error {
    color: #EF693F;
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }
</style>
