<template>
  <div :style="style" class="avatar">
    <svg-icon
      v-if="isChecked"
      class="avatar__checked"
      width="22"
      height="22"
      name="avatar-checked"
    />
    <table>
      <tbody>
        <tr>
          <td v-if="!hasImage">
            {{ initials }}
          </td>
        </tr>
      </tbody>
    </table>
    <!--    TODO: Пока решили убрать. Но оставил для дальнейшей до работки.-->
    <!--    <div v-if="rating != null" class="avatar__rating">-->
    <!--      {{ rating }}-->
    <!--    </div>-->
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";

export default {
  name: "Avatar",
  components: { SvgIcon },
  props: {
    fullname: { type: String, default: "" },
    size: { type: [Number, String], default: 48 },
    radius: {
      type: Number,
      default: 50,
      validator: (value) => value >= 0 && value <= 50,
    },
    color: { type: String, default: "" },
    image: { type: String, default: "" },
    rating: { type: [String, Number], default: null },
    isChecked: {
      type: Boolean,
      default: false,
    },
    shortName: {
      type: String,
      default: "",
    },
  },
  computed: {
    initials() {
      const words = this.fullname.split(/[\s-]+/);
      return words
        .map((word) => word.substr(0, 1))
        .join("")
        .substr(0, 3)
        .toUpperCase();
    },
    style() {
      const fontSize = this.initials.length > 2 ? this.size / 3 : this.size / 2;
      return {
        width: this.size + "px",
        height: this.size + "px",
        "border-radius": this.radius + "%",
        "font-size": fontSize + "px",
        "background-color":
          this.color === ""
            ? this.toColor(this.shortName ? this.shortName : this.fullname)
            : this.color,
        "background-image": this.hasImage ? "url(" + this.image + ")" : "none",
      };
    },
    hasImage() {
      return this.image !== "";
    },
  },
  methods: {
    toColor(str) {
      let hash = 0;
      if (!str || str.trim() === "undefined.") return "black";
      for (const char of str.split("")) {
        hash = (hash << (8 - hash)) + char.charCodeAt(0);
      }
      console.log(str);
      return "#" + Math.abs(hash).toString(13).substr(0, 6);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.avatar {
  position: relative;
  display: inline-block;
  background-color: black;
  color: white;
  width: 48px;
  height: 48px;
  font-size: 12px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: none;
  flex-shrink: 0;
  &__checked {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  &__rating {
    @extend %mini_bold;
    color: $accent;
    position: absolute;
    border-radius: 30px;
    padding: 2px 9px;
    background-color: $dark-gray;
    left: 50%;
    bottom: -8px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  table {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-spacing: 0;
  }
  table td {
    text-align: center;
    vertical-align: middle;
  }
  img {
    width: 100%;
    overflow: hidden;
  }
}
</style>
