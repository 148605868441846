<template>
  <v-container class="footer">
    <div class="footer-mobile">
      <div>
        <svg-icon :width="92" :height="42" name="Logo1" class="svg-home" />
      </div>
      <div class="footer-social">
        <a
          v-for="social in footer_alpha.socialServices"
          :href="social.link"
          class="footer-social__item"
          :title="social.name"
        >
          <svg-icon :name="'socials-footer/' + social.code" />
        </a>
      </div>

      <nav class="footer-menu" v-for="item in navigation" :key="item.id">
        <router-link to="/" class="footer-menu__item">{{ item.name }}</router-link>
        <a
          v-for="nav in item.parent"
          @click="toRout(nav)"
          class="footer-menu__item"
          :key="nav"
          >{{ nav.name }}
        </a>
      </nav>
      <div class="footer-menu">
        <a class="footer-menu__item"></a>
        <a
          target="_blank"
          :href="
            getPolicy(
              '/docs/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80-%D0%9E%D1%84%D0%B5%D1%80%D1%82%D0%B0.pdf'
            )
          "
          class="footer-menu__item"
          >{{ $t("router.site-terms-of-use") }}</a
        >
        <a
          target="_blank"
          :href="
            getPolicy(
              '/docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%B8%20%D0%B7%D0%B0%D1%89%D0%B8%D1%82%D1%8B%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf'
            )
          "
          class="footer-menu__item"
          >{{ $t("router.protection-of-personal-information") }}</a
        >
        <a
          target="_blank"
          :href="
            getPolicy(
              '/docs/%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5.pdf'
            )
          "
          class="footer-menu__item"
          >{{ $t("router.terms-of-use") }}</a
        >
      </div>
    </div>

    <div class="footer-top">
      <div class="logo">
        <div class="slogan">Всегда есть те,</div>
        <div class="slogan">кто вам нужен</div>
        <svg-icon :width="143" :height="65" name="Logo1" class="svg-home" />
      </div>

      <div>
        <div class="footer-contact">
          <div class="mb-8 d-flex">
            <nav class="footer-menu" v-for="item in navigation" :key="item.id">
              <router-link to="/" class="footer-menu__item">{{ item.name }}</router-link>
              <a
                v-for="nav in item.parent"
                @click="toRout(nav)"
                class="footer-menu__item"
                :key="nav"
                :href="nav.link"
                >{{ nav.name }}
              </a>
            </nav>
            <div>
              <p class="mobile-app__title">Социальные сети</p>
              <div class="footer-social">
                <a
                  v-for="social in footer_alpha.socialServices"
                  :href="social.link"
                  class="footer-social__item"
                  :title="social.name"
                >
                  <svg-icon :name="'socials-footer/' + social.code" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="madeby">
      <div class="footer-bottom">
        <div class="footer-bottom__menu">
          <div class="company">
            <div class="footer-bottom__menu-def">
              {{ $t("cooperate.company") }}
            </div>
          </div>
          <div class="footer-bottom__menu">
            <a
              target="_blank"
              :href="
                getPolicy(
                  '/docs/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80-%D0%9E%D1%84%D0%B5%D1%80%D1%82%D0%B0.pdf'
                )
              "
              class="footer-bottom__menu-item"
              >{{ $t("router.site-terms-of-use") }}</a
            >
            <a
              target="_blank"
              :href="
                getPolicy(
                  '/docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%B8%20%D0%B7%D0%B0%D1%89%D0%B8%D1%82%D1%8B%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf'
                )
              "
              class="footer-bottom__menu-item"
              >{{ $t("router.protection-of-personal-information") }}</a
            >
            <a
              target="_blank"
              :href="
                getPolicy(
                  '/docs/%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5.pdf'
                )
              "
              class="footer-bottom__menu-item"
              >{{ $t("router.terms-of-use") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </v-container>
  <verification-modal
    v-if="!isAuth"
    header="Войдите или зарегистрируйтесь"
    subtitle="Войдите или создайте новый аккаунт, чтобы получить полный доступ ко всем услугам"
    :is-open="isRegisterAndLogin"
    @close="isRegisterAndLogin = false"
    ref="registerAndLoginModal"
    @submit="toRegister"
  />
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import Accordion from "@/components/ui/Accordion";
import { mapGetters } from "vuex";
import SelectLanguage from "@/components/ui/SelectLanguage";
import VerificationModal from "@/components/modals/VerificationModal";
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "TheFooter",
  components: { BaseButton, VerificationModal, SelectLanguage, SvgIcon, Accordion },
  computed: {
    ...mapGetters({
      footer: "content/footer",
      isAuth: "auth/isAuth",
    }),
    navigation() {
      return [
        {
          id: 1,
          name: `${this.$t("router.group-name.customers")}`,
          parent: [
            { name: "Профиль", link: "/profile/information" },
            { name: "Создать заявку", link: "/request-add" },
            { name: "Отклики и проекты", link: "/projects/request" },
            // { name: this.$i18n.t("router.communities"), link: "/community" },
          ],
        },
        {
          id: 2,
          name: this.$i18n.t("router.group-name.executor"),
          parent: [
            { name: "Профиль", link: "/profile/information" },
            { name: "Запросы и отклики", link: "/projects/response" },
            // { name: this.$i18n.t("router.communities"), link: "/community" },
          ],
        },
        {
          id: 3,
          name: "О нас",
          parent: [
            { name: "Почему мы", link: "#whoweare" },
            { name: "Индустрии", link: "#industries" },
            { name: "Помощь", link: "/faq" },
          ],
        },
      ];
    },
  },
  data() {
    return {
      isRegisterAndLogin: false,
      footer_alpha: {},
    };
  },
  async created() {
    await this.getFooter();
  },
  methods: {
    toRegister() {
      this.$router.replace({ name: "RegistrationPage" });
      this.isRegisterAndLogin = false;
    },
    toLogin() {
      this.$router.replace({ name: "AuthPage" });
      this.isRegisterAndLogin = false;
    },
    async getFooter() {
      try {
        await this.$store.dispatch("content/getFooter");
        this.footer_alpha = this.footer;
      } catch (e) {
        console.log("e");
        console.log(e);
      }
    },
    toRout(to) {
      if (
        !this.isAuth &&
        (to.name.toString().toLowerCase().includes("отклики".toString().toLowerCase()) ||
          to.name.toString().toLowerCase().includes("сообществ".toString().toLowerCase()))
      )
        this.showRegisterAndLoginModal();
      else if (to.link.slice(0, 1) == "#") {
        this.$router.push({ path: "/", hash: to.link });
      } else {
        this.$router.push({ path: to.link });
      }
    },
    getPolicy(href) {
      return (
        "https://" +
        config.VUE_APP_ASSETS_BUCKET +
        ".storage.yandexcloud.net/" +
        config.VUE_APP_ASSETS_DIR +
        href
      );
    },
    showRegisterAndLoginModal() {
      this.$refs.registerAndLoginModal.show({
        nameButton: this.$t("btn.register"),
      });
      this.isRegisterAndLogin = true;
    },
    openInvitePage() {
      this.$router.replace({ name: "InvitePage" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

#innerExPan > * {
  padding: 0 0 0 50px;
}

.desktop-visible {
  display: block;
  @include md {
    display: none;
  }
}

.tablet-visible {
  display: none;
  @include md {
    display: block;
  }
}
.company {
  min-width: 300px;
}
.footer {
  display: block;
  margin-top: auto;
  overflow: hidden;
  padding-bottom: 60px;
  padding-top: 20px;
  margin-top: 20px;
  @media screen and (max-width: 1199px) {
    margin-bottom: 40px;
  }

  @include md {
    padding: 50px 24px 100px 24px;
    margin-bottom: 100px;
  }

  @include sm {
    padding: 20px 12px 100px 12px;
  }

  .footer-bottom__by-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @include sm {
      gap: 30px;
    }
  }

  &__nav-accordion {
    margin-top: 20px;
    margin-bottom: 32px;
  }

  &__nav-accordion-item {
    border-top: solid 1px $border-color-hr;
  }

  &__nav-accordion-text {
    font-size: 16px;
    font-family: "Muller", sans-serif;

    @include sm {
      font-weight: 500;
      font-size: 14px;
      line-height: 124%;
    }
  }

  &__expansion-panel {
    background-color: $dark-gray;
    font-weight: 400;
    font-size: 16px;
    color: $black;
  }

  &__expansion-panel-text {
    background-color: $dark-gray;
  }

  &__expansion-panel-item {
    width: 100%;
    padding: 5px 0;
    display: block;
    font-size: 14px;
    color: $accent;

    @include sm {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &_nowrap {
    display: flex;

    @include md {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 60px;
    }
  }
}

.footer-bottom {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  @include md {
    align-items: flex-start;

    .base-dropdown__arrow {
      display: unset !important;
    }
  }
  @include sm {
    display: none;
    //align-items: flex-start;

    .base-dropdown__arrow {
      display: unset !important;
    }
  }
  &__copyright {
    line-height: 22px;
    color: $text-gray;
    font-size: 18px;
    display: flex;

    &_big {
      @include md {
        display: none;
      }
    }

    &_md {
      display: none;

      @include md {
        display: flex;
      }
    }

    @include md {
      font-size: 14px;
    }

    @include sm {
      align-items: flex-end;
    }
  }

  &__menu {
    display: flex;
    align-content: center;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    justify-content: center;
    @include md {
      margin: 0px;
      flex-direction: row;
      gap: 8px;
    }

    @include sm {
      margin: 0 auto;
    }

    &-item-mobile {
      padding: 4px 12px;
      font-size: 14px;

      @include sm {
        line-height: 18px;
      }
    }
    &-def {
      font-weight: 400;
      font-size: 16px;
      line-height: 124%;
      color: $accent;
      transition: 0.3s;
      @include md {
        font-size: 14px;
      }
    }
    &-item {
      font-weight: 400;
      font-size: 16px;
      line-height: 124%;
      color: $accent;
      transition: 0.3s;

      &:hover {
        color: #7fc666;
      }

      @include md {
        font-size: 14px;
      }
    }
  }

  &__by {
    display: flex;
    min-width: fit-content;
    color: $text-gray;
    font-weight: 400;
    font-size: 16px;
    line-height: 124%;
    margin-bottom: 3px;
    flex-wrap: wrap;
    @include md {
      font-size: 14px;
    }

    &-wrapper {
      display: flex;

      @include md {
        margin-left: auto;
        gap: 8px;
      }
    }
  }
}

.footer-menu {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 150px;
  margin-right: 30px;

  &:last-child {
    margin-right: 100px;

    @include md {
      margin-right: 50px;
    }
  }

  @include md {
    margin-right: 0;
  }

  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 124%;
    margin-bottom: 20px;
    cursor: pointer;
    color: $accent;

    &:first-child {
      @include mobile_h1;
      font-size: 20px;
      font-weight: 500;
      color: #42434e;
    }

    @include md {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 8px;

      &:first-child {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
      }
    }
  }
}

.footer-top {
  display: none;
  justify-content: space-between;
  flex-wrap: wrap;

  @include md {
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    & {
      display: flex;
      grid-template-columns: 1fr 38px;
    }
  }
}

.footer-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    & {
      display: none;
    }
  }
}

.footer-contact {
  margin-bottom: 29px;
  display: flex;
  // width: 500px;
  flex-direction: column;

  @include md {
    margin-right: 0;
    margin-left: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  @include sm {
    flex-direction: column;
    align-items: unset;
    margin-bottom: 32px;
  }

  &__number {
    @include desktop_h1;
    color: $black;

    @include md() {
      @include desktop_h2;
    }
  }

  &__description {
    color: $text-gray;
    font-size: 18px;
    line-height: 22px;
    margin-right: 10px;
    margin-top: 10px;

    @include md() {
      font-size: 14px;
    }
  }

  .mobile-app__title {
    margin-bottom: 22px;

    @include md {
      margin-bottom: 0;
      margin-right: 20px;
    }

    @include sm {
      margin-bottom: 22px;
      margin-right: 0;
      width: 75%;
      font-weight: 500;
      font-size: 18px;
      line-height: 124%;
    }
  }
}

.footer-invite {
  max-width: 370px;

  .button {
    @include sm {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
    }
  }

  @include sm {
    max-width: 100%;
  }
}
.madeby {
  display: flex;
  justify-content: space-between;
}
.slogan {
  max-width: 350px;
  color: #42434e;
  font-family: Muller;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px; /* 104% */
  letter-spacing: -1px;
  @include md {
    font-size: 25px;
    font-weight: 300;
    line-height: 25px;
  }
}
.footer-social {
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bg-light-gray;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-right: 12px;
    background-color: #fff;

    svg {
      min-width: 30px;
      min-height: 30px;
      max-width: 30px;
      max-height: 30px;

      @include md {
        min-width: 23px;
        min-height: 23px;
        max-width: 23px;
        max-height: 23px;
      }
    }

    @include md {
      width: 36px;
      height: 36px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.mobile-app {
  display: flex;
  grid-template-columns: 1fr 135px;
  margin: 20px 0 24px;
  background-color: $bg-light-gray;
  border-radius: 12px;
  position: relative;
  padding: 0;
  justify-content: center;

  @include md {
    margin: 24px 0;
  }

  @include sm {
    margin: 24px 0 40px;
  }

  @include sm {
    //overflow: hidden
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 10px;
    color: #42434e;

    @include md {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
    }

    @include sm {
      font-weight: 500;
      font-size: 18px;
      line-height: 124%;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 22px;
    color: $accent;
    margin-bottom: 24px;

    @include md {
      max-width: 320px;
      margin-bottom: 20px;
      font-size: 16px;
    }

    @include sm {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__icon {
    display: flex;
    position: absolute;

    &-item:first-child {
      margin-right: 16px;
    }
  }

  &__info {
    text-align: left;
    padding: 30px 60px 30px 0;
    max-width: 620px;

    @include md {
      max-width: 420px;
      margin-left: -55px;
    }

    @include sm() {
      padding-bottom: 30px;
      padding-right: 0;
      margin-left: -35px;
      z-index: 2;
    }
  }

  &__img {
    display: flex;
    align-items: flex-end;
    margin-top: -35px;

    @include md {
      align-items: flex-start;
      margin-top: -28px;
    }

    @include sm {
      align-items: center;
      position: absolute;
      right: -62px;
      bottom: 0;
    }
  }
}
</style>
