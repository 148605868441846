<template>
  <v-container>
    <div class="head">
      <h1 class="main_title">
        {{ "Вопросы от " + (customer ? "экспертов" : "заказчиков") }}
      </h1>

      <p @click="toggle" class="pointer">
        {{ "Перейти к вопросам от " + (!customer ? "экспертов" : "заказчиков") }}
        <svg-icon width="12" height="12" name="arrow-big-right"></svg-icon>
      </p>

      <div class="change_helper">
        <h3>{{ "Вопросы от " + (!customer ? "экспертов" : "заказчиков") }}</h3>
        <div class="change_helper__container">
          <p>
            Вы можете совмещать роли и переключаться между ними в вашем личном профиле
          </p>
          <div>
            <div class="change_helper__arrow-container">
              <svg-icon
                @click="toggle"
                width="14"
                height="14"
                name="arrow-big-right-green"
              ></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-for="item in base">
      <div class="group">
        <div
          v-if="item.groupName.length"
          class="group__header"
          :class="customer ? 'dark' : 'light'"
        >
          <p class="group__title">{{ item.groupName }}</p>
        </div>
        <div
          class="group__body"
          :class="[customer ? 'dark2' : 'light2', item.groupName.length ? 'gr' : 'nogr']"
        >
          <template v-for="(d, idx) in item.questions">
            <div>
              <div
                class="group__qustion_container"
                @click="toggleQuestion(d)"
                :class="idx ? 'line' : ''"
              >
                <p class="group__question">{{ d.question }}</p>
                <div class="arrow-container" :class="customer ? 'dark' : 'light'">
                  <svg-icon
                    width="14"
                    height="14"
                    name="arrow-big-right"
                    class="arrow"
                    :class="{ clicked: d.isOpen }"
                  ></svg-icon>
                </div>
              </div>
              <div v-if="d.isOpen">
                <div class="group__answer" v-html="markdownToHtml(d.answer)"></div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <div class="helper">
      <p>Если ответа на ваш вопрос нет, вы можете связаться с нашей поддержкой</p>
      <base-button @click="openContactModal" style="max-width: 350px"
        >Связаться</base-button
      >
    </div>
    <div class="bottom">
      <p @click="toggle" class="pointer">
        {{ "Перейти к вопросам от " + (!customer ? "экспертов" : "заказчиков") }}
        <svg-icon width="12" height="12" name="arrow-big-right"></svg-icon>
      </p>
    </div>
  </v-container>
  <contact-support-modal ref="support" />
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import ContactSupportModal from "@/components/modals/ContactSupportModal";
import BaseButton from "@/components/base/BaseButton";
import { mapGetters } from "vuex";
import { marked } from "marked";
export default {
  name: "FaqPage",
  data() {
    return {
      customer: true,
      qa: [],
    };
  },
  components: { SvgIcon, BaseButton, ContactSupportModal },
  computed: {
    ...mapGetters({ userState: "auth/isUserState", questions: "questions/questions" }),
    backgroundStyles() {
      return {
        "state-on": this.customer,
        "state-off": !this.customer,
      };
    },
    indicatorStyles() {
      return {
        transform: this.customer ? "translateX(29px)" : "translateX(3px)",
        "background-color": this.customer ? "#4b4c57" : "#ffffff",
      };
    },
    base() {
      return this.qa[this.userState];
    },
  },
  watch: {
    customer(val) {
      val ? this.setExecutor() : this.setCustomer();
      val
        ? document.documentElement.style.setProperty("--main-color", "#FFF")
        : document.documentElement.style.setProperty("--main-color", "#EEE");
    },
  },
  unmounted() {
    document.documentElement.style.setProperty("--main-color", "#EEE");
  },
  mounted() {
    if (!this.userState) this.$store.commit("auth/SET_USERSTATE", "customer");
    this.customer = this.userState != "customer";
    this.customer
      ? document.documentElement.style.setProperty("--main-color", "#FFF")
      : document.documentElement.style.setProperty("--main-color", "#EEE");
  },
  created() {
    this.getSupportInfo();
    this.getQuestionsAnswers();
  },

  methods: {
    toggleQuestion(item) {
      item.isOpen = !item.isOpen;
    },
    openContactModal() {
      this.$refs.support.showModal();
    },
    setCustomer() {
      this.$store.commit("auth/SET_USERSTATE", "customer");
    },
    setExecutor() {
      this.$store.commit("auth/SET_USERSTATE", "executor");
    },
    toggle() {
      this.customer = !this.customer;
    },
    markdownToHtml(t) {
      return marked(t);
    },
    async getSupportInfo() {
      try {
        await this.$store.dispatch("profile/getSupportInfo");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getQuestionsAnswers() {
      try {
        await this.$store.dispatch("questions/getQuestions");
        this.qa = JSON.parse(JSON.stringify(this.questions));
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.container {
  @include desktop_standart;
  max-width: 1440px;
  width: 100%;
  margin: 60px auto 0px;
  padding: 0 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  @include md {
    margin: 0px auto 0px;
    padding: 30px;
    justify-content: center;
  }
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;

  @include sm {
    flex-direction: column;
    gap: 30px;
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: end;
}
.main_title {
  color: #42434e;
  font-family: Muller;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 100% */
  letter-spacing: -1px;

  @include md {
    font-size: 32px;
    line-height: 32px; /* 39.68px */
  }

  @include sm {
    font-size: 24px;
    line-height: 24px; /* 39.68px */
  }
}

.light {
  background-color: #f6f6f6;
}
.dark {
  background-color: #eee;
}

.light2 {
  background-color: #fff;
}
.dark2 {
  background-color: #f6f6f6;
}
.line {
  border-top: 1px solid $line-light-gray;
}
.gr {
  border-radius: 0px 0px 20px 20px !important;
}
.nogr {
  border-radius: 20px !important;
}
.helper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #42434e;
  padding: 24px;
  color: #42434e;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 19.84px */
  margin: 30px 0px;
  @include sm {
    flex-direction: column;
    gap: 30px;
    border: 0px solid #42434e;
    background-color: #f6f6f6;
  }
}

.change_helper {
  display: none;
  @include sm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    border-radius: 20px;
    border: 1px solid #42434e;
    padding: 24px;
    color: #fff;
    font-family: Muller;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%;
    margin-top: 30px 0px;
    border-radius: 24px;
    background: #42434e;
    &__arrow-container {
      min-width: 36px;
      min-height: 36px;
      border-radius: 6px;
      background: #51525e;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__container {
      cursor: pointer;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 12px;
      color: #bababa;
      font-family: SFProDisplay;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px; /* 118.182% */
      letter-spacing: 0.33px;
      gap: 20px;
    }
  }
}

.group {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  border-radius: 20px;

  margin-top: 30px;
  &__header {
    padding: 18px 24px;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }
  &__body {
    width: 100%;
    padding: 0px 0px 24px 0px;
    border-radius: 0px 0px 20px 20px;
  }
  &__title {
    color: #000;
    font-family: Muller;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 122.222% */
  }
  &__qustion_container {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;
  }
  &__question {
    padding: 18px 0px;
    color: #42434e;
    font-family: Muller;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 122.222% */
    @include sm {
      font-size: 16px;
    }
  }
  &__answer {
    color: #42434e;
    font-family: SFProDisplay;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: -0.224px;
    padding: 0px 24px;
    padding-bottom: 14px;
  }
}

.arrow-container {
  min-width: 36px;
  min-height: 36px;
  border-radius: 6px;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  @include sm {
    min-width: 30px;
    min-height: 30px;
  }
}

.arrow {
  transition: transform 0.3s ease-in-out;
  transform: rotate(90deg);
}

.clicked {
  transform: rotate(270deg);
}
.pointer {
  cursor: pointer;
  @include md {
    border-radius: 16px;
    background: #f6f6f6;
    margin: 5px;
    padding: 5px 10px;
    font-size: 12px;
  }
  @include sm {
    display: none;
  }
}
</style>
