<template>
  <header
    class="header"
    :style="
      path.startsWith('/profile/') && mobile
        ? 'background-color: #7fc666'
        : 'background-color: none'
    "
  >
    <v-container class="pt-0 pb-0">
      <div class="header__wrapper" :class="{ 'no-auth': !isAuth }">
        <router-link
          v-bind:id="[!isStartPage ? 'content' : null]"
          to="/"
          class="header__logo"
        >
          <svg-icon
            :name="path.startsWith('/profile/') && mobile ? 'Logo-white-o' : 'Logo1'"
            class="svg-home"
          />
        </router-link>
        <div
          v-if="!isStartPage & isAuth"
          class="header__navigation-pc header__navigation-z-index"
        >
          <base-status-pixel />
          <div class="header__navigation"></div>
          <!-- <div class="header__navigation" v-if="isCustomer"> нужно состояние -->
          <div class="header__navigation" v-if="getUserState === 'customer'">
            <router-link class="header__nav" to="/profile/information"
              >Профиль</router-link
            >
            <router-link class="header__nav" :to="{ name: 'RequestAddPage' }"
              >{{ $t("router.create-request") }}
            </router-link>
            <span @click="toRequest"
              >Отклики и проекты <base-signal-chip title="new" v-if="isNew"
            /></span>
            <!-- <span @click="toCommunity">{{ $t("router.communities") }}</span> -->
            <router-link class="header__nav" to="/faq">Помощь</router-link>
          </div>
          <div class="header__navigation" v-else>
            <router-link class="header__nav" to="/profile/information"
              >Профиль</router-link
            >

            <span @click="toResponse">
              Запросы и отклики <base-signal-chip title="new" v-if="isNew" />
            </span>
            <!-- <span @click="toCommunity">{{ $t("router.communities") }}</span> -->
            <router-link class="header__nav" to="/faq">Помощь</router-link>
          </div>
        </div>
        <div class="sub-logo" v-else>
          <div class="header__navigation">
            <a class="header__nav" href="/#whoweare">Почему мы </a>
            <a class="header__nav" href="/#industries">Индустрии</a>
            <a class="header__nav" href="/mock">Для компаний</a>
            <router-link class="header__nav" to="/faq">Помощь</router-link>
          </div>
        </div>
        <div class="header__navigation-mobile">
          <base-menu-mobile v-if="isAuth && !isStartPage" :userState="userState" />
          <base-menu-bottom
            @close="handleClose"
            v-if="isOpen || (isStartPage && !isAuth)"
          />
        </div>

        <div class="mobile-dropdown">
          <div class="burger" v-if="isAuth" @click="isOpen = !isOpen">
            <!-- <avatar
              :fullname="fullName"
              :short-name="shortName"
              :image="getPhoto == null ? '' : getPhoto"
              size="32"
            >
            </avatar>-->
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.8">
                <path
                  d="M1.5 1.66675H16.5"
                  stroke="#42434E"
                  stroke-width="1.8"
                  stroke-linecap="round"
                />
                <path
                  d="M1.5 7.00024H16.5"
                  stroke="#42434E"
                  stroke-width="1.8"
                  stroke-linecap="round"
                />
                <path
                  d="M1.5 12.3335L12.5 12.3335"
                  stroke="#42434E"
                  stroke-width="1.8"
                  stroke-linecap="round"
                />
              </g>
            </svg>
          </div>
          <div class="mobile-buttons" v-else>
            <a class="auth_button" href="/auth" auth>{{ $t("router.auth") }}</a>
          </div>
        </div>

        <div class="desktop-dropdown">
          <div class="desktop-dropdown__switcher" v-if="isHome && isAuth">
            <div
              @click="openCustomer"
              class="switcher"
              :class="{ switcher_picked: isCustomer }"
            >
              Заказчик
            </div>
            <div
              @click="openExecutor"
              class="switcher"
              :class="{ switcher_picked: isExecutor }"
            >
              Эксперт
            </div>
          </div>
          <div
            class="desktop-dropdown__switcher"
            v-if="!isHome && !isStartPage && isAuth"
          >
            <div
              @click="setCustomer"
              class="switcher"
              :class="{ switcher_picked: isLocalCustomer }"
            >
              Заказчик
            </div>
            <div
              @click="setExecutor"
              class="switcher"
              :class="{ switcher_picked: isLocalExecutor }"
            >
              Эксперт
            </div>
          </div>
          <base-dropdown-menu
            hid-mobile
            hideFullName
            v-if="isAuth"
            :home="fullName"
            :text-color="isHome ? '#000' : null"
            class="base-dropdown_auth"
          >
            <template v-slot:postfix>
              <avatar
                :fullname="fullName"
                :short-name="shortName"
                :image="getPhoto == null ? '' : getPhoto"
                size="32"
              >
              </avatar>
            </template>
            <router-link to="/profile">{{ $t("dropdown-menu.profile") }}</router-link>
            <router-link to="/profile/settings">{{
              $t("dropdown-menu.setting")
            }}</router-link>
            <a href="#" @click="signOut">{{ $t("dropdown-menu.sign-out") }}</a>
          </base-dropdown-menu>
          <div class="header__user-auth" v-else>
            <a class="auth_button" href="/auth" auth>{{ $t("router.auth") }}</a>
          </div>
        </div>
      </div>
    </v-container>

    <verification-modal
      v-if="!isAuth"
      header="Войдите или зарегистрируйтесь"
      subtitle="Войдите или создайте новый аккаунт, чтобы получить полный доступ ко всем услугам"
      :is-open="isRegisterAndLogin"
      @close="isRegisterAndLogin = false"
      ref="registerAndLoginModal"
      @submit="toRegister"
    >
      <template v-slot:input>
        <base-button @click="toLogin" class="mb-4 button--light">Войти</base-button>
      </template>
    </verification-modal>
  </header>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import { mapGetters, mapMutations } from "vuex";
import BaseDropdownMenu from "@/components/base/BaseDropdownMenu";
import BaseSignalChip from "@/components/base/BaseSignalChip";
import Avatar from "@/components/ui/Avatar";
import SizedBox from "@/components/ui/SizedBox";
import BaseButton from "@/components/base/BaseButton";
import BaseMenuMobile from "@/components/base/BaseMenuMobile";
import BaseMenuBottom from "@/components/base/BaseMenuBottom";
import BaseStatusPixel from "@/components/base/BaseStatusPixel";
import VerificationModal from "@/components/modals/VerificationModal";

export default {
  name: "TheHeader",
  data() {
    return {
      activeColor: "color-white",
      isRegisterAndLogin: false,
      path: "",
      isOpen: false,
    };
  },
  components: {
    BaseStatusPixel,
    VerificationModal,
    BaseSignalChip,
    BaseMenuMobile,
    BaseMenuBottom,
    BaseButton,
    SizedBox,
    BaseDropdownMenu,
    SvgIcon,
    Avatar,
  },
  mounted() {
    if (!this.userState) this.$store.commit("auth/SET_USERSTATE", "customer");
    if (this.isAuth) {
      this.$route.name;
      this.getUserData();
      this.path = this.$route.path;
    }
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
    },
  },
  computed: {
    ...mapGetters({
      isAuth: "auth/isAuth",
      user: "auth/user",
      userState: "auth/isUserState",
      getUserState: "auth/getUserState",
      responseStatus: "status/responseStatus",
    }),
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },

    isNew() {
      if (Object.values(this.responseStatus).length)
        return (
          Object.values(this.responseStatus?.executor)
            .map((el) => el.type)
            .some((x) => x.length) |
          Object.values(this.responseStatus?.customer)
            .map((el) => el.type)
            .some((x) => x.length)
        );
      return false;
    },

    getPhoto() {
      if (this.user == null) return null;
      if (this.user.photo === "") return null;
      if (this.user.photo === undefined) return null;
      return this.user.photo;
    },
    fullName() {
      let patronymic = this.user.patronymic == null ? "" : this.user.patronymic;
      let name = this.user.name == null ? "" : this.user.name;
      let lastName = this.user.lastName == null ? "" : this.user.lastName;
      return `${lastName} ${name} ${patronymic}`;
    },
    shortName() {
      let name = this.user.name == null ? "" : this.user.name;
      let lastName = this.user.lastName == null ? "" : this.user.lastName;
      return `${name} ${lastName[0]}.`;
    },
    isExecutor() {
      return this.$route.name === "ProfilePage";
    },
    isCustomer() {
      return this.$route.name === "RequestAddPage";
    },
    isHome() {
      return this.$route.name === "RequestAddPage" || this.$route.name === "ProfilePage";
    },
    isStartPage() {
      return this.$route.name === "StartPage";
    },
    // getUserState() {
    //   return this.userState
    // },
    isLocalExecutor() {
      return this.getUserState === "executor";
    },
    isLocalCustomer() {
      console.log(this.userState);
      return this.getUserState === "customer";
    },
  },
  methods: {
    toCommunity() {
      if (this.isAuth) {
        this.$router.replace({ name: "CommunityPage" });
      } else {
        this.$refs.registerAndLoginModal.show({
          nameButton: this.$t("btn.register"),
        });
        this.isRegisterAndLogin = true;
      }
    },
    handleClose(e) {
      this.isOpen = false;
    },
    toResponse() {
      if (this.isAuth) {
        this.$router.push({ path: "/projects/response" });
      } else {
        this.$refs.registerAndLoginModal.show({
          nameButton: this.$t("btn.register"),
        });
        this.isRegisterAndLogin = true;
      }
    },
    toRequest() {
      if (this.isAuth) {
        this.$router.replace({ name: "ProjectsPage" });
      } else {
        this.$refs.registerAndLoginModal.show({
          nameButton: this.$t("btn.register"),
        });
        this.isRegisterAndLogin = true;
      }
    },
    toRegister() {
      this.$router.replace({ name: "RegistrationPage" });
      this.isRegisterAndLogin = false;
    },
    toLogin() {
      this.$router.replace({ name: "AuthPage" });
      this.isRegisterAndLogin = false;
    },
    signOut() {
      this.$store.reset();
      this.$store.dispatch("auth/signOut");
    },
    openExecutor() {
      this.$store.commit("auth/SET_USERSTATE", "executor");
      this.$router.replace({ name: "ProfilePage" });
    },
    openCustomer() {
      this.$store.commit("auth/SET_USERSTATE", "customer");
      this.$router.replace({ name: "RequestAddPage" });
    },
    async getUserData() {
      await this.$store.dispatch("auth/getUserData");
    },
    toTop() {
      if (this.isLocalExecutor) {
        this.$store.commit("auth/SET_USERSTATE", "customer");
        this.$router.replace({ path: "xxx" });
      }
    },

    setCustomer() {
      this.$store.commit("auth/SET_USERSTATE", "customer");
      // this.$store.dispatch('auth/setUserState')
      // this.userState = 'customer'
    },
    setExecutor() {
      this.$store.commit("auth/SET_USERSTATE", "executor");
      // this.userState = 'executor'
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/vuetify_variables";

.auth_button {
  border-radius: 8px;

  text-align: center;
  font-family: Muller;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  width: 151px;
  height: 50px;
  padding: 17px 24px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  border-radius: 10px;
  color: #fff;
  background: #42434e;
  @include sm {
    background: #fff;
    color: #42434e;
    font-family: SFProDisplay;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    letter-spacing: 0.26px;
    display: flex;
    width: 66px;
    height: 32px;
    padding: 5px 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }
  &:hover {
    color: #fff;
    @include sm {
      color: #42434e;
    }
  }
}
.header-redesign {
  width: 100%;
  padding: 14px 60px;
  @include md {
    padding: 11px 24px;
  }

  @include sm {
    padding: 11px 12px;
  }
}

.base-dropdown_flex {
  flex: 1;
}

.desktop-dropdown {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 24px;

  .base-dropdown__block {
    gap: 16px;
  }

  .base-dropdown_auth {
    display: flex;
    align-items: center;
  }

  @include md {
    display: none;
  }

  &__switcher {
    display: flex;
    background: #ffffff;
    border-radius: 10px;
    align-self: center;
    padding: 3px;
    height: 42px;
  }

  .switcher {
    padding: 7px 16px;
    border-radius: 7px;
    color: #a0a0a0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 124%;

    &_picked {
      background: #4b4c57;
      color: #ffffff;
    }

    &:hover {
      cursor: pointer;
    }

    &:hover:not(.switcher_picked) {
      color: #4b4c57;
      transition: 0.3s all linear;
    }
  }
}

.mobile-dropdown {
  display: none;

  @include md {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    gap: 12px;
  }

  &__picker {
    background: #ffffff;
    border-radius: 10px;
    padding: 3.5px 16px;
    // height: 32px;
  }

  .mobile-buttons {
    display: flex;
    gap: 12px;
  }
}

.v-container {
  @include md {
    padding: 0 24px !important;
  }
}

.header {
  &__authorization {
    display: none;

    @include md {
      display: flex;
    }
  }

  &__wrapper {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 14.5px 0 14.5px 0;
    text-align: center;
    display: flex;
    align-items: center;

    gap: 60px;
    @media (max-width: 1130px) {
      gap: 30px;
    }
    @include md {
      padding: 11px 0 11px 0;
      align-items: center;
    }
  }

  .sub-logo {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #42434e;
    @include sm {
      display: none;
    }
  }

  &__nav {
    white-space: nowrap;
    font-weight: 400;

    // line-height: 124%;
  }

  .button {
    &_sign-up {
      background: #4b4c57;
      color: #ffffff;

      &:hover {
        background: #3a3b44;
        border: none;
      }
    }
  }

  &__user-auth {
    display: flex;
    z-index: 11;
    gap: 12px;
    justify-content: end;

    @include sm {
      display: none;
    }
  }

  &__navigation > * {
    margin-right: 28px;
    color: $header-text;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
    @media (max-width: 1130px) {
      font-size: 14px;
      margin-right: 10px;
    }
    &:hover {
      color: #7fc666;
    }
  }

  &__navigation-pc {
    display: inline;

    @include sm {
      display: none;
    }
  }

  &__navigation-mobile {
    display: none;

    @include sm {
      display: flex;
      position: fixed;
      z-index: 11;
      bottom: 0;
    }
  }

  &__logo {
    .svg-icon {
      width: 90px;
      height: 41px;

      @include md {
        width: 70px;
        height: 32px;
      }
    }
  }

  .header__navigation-z-index {
    z-index: 11;
  }

  .color-white > * {
    color: $white;

    &:hover {
      color: $green;
    }
  }
}
.burger {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: #fff;
  align-items: center;
  justify-content: center;
}
</style>
