<template>
  <base-alert-no-modal v-if="isSuccess" type="success" header="Спасибо за вашу оценку!">
    <template #button>
      <base-button @click="goHome">Вернуться на главную</base-button>
    </template>
  </base-alert-no-modal>
  <base-no-modal v-if="!isSuccess && (isCompetence || isSpecialization || isService)">
    <div
      class="top-block"
      :class="{
        'top-block_competence': isCompetence || isService,
        'top-block_specialization': isSpecialization,
      }"
    >
      <avatar
        class="top-block__avatar"
        :fullname="ratedUser.name"
        :image="ratedUser?.photo ? getPhoto(ratedUser.photo) : ''"
        size="114"
      />
      <h1 class="top-block__name">
        {{ ratedUser.name }} {{ ratedUser?.lastName || "" }}
      </h1>
    </div>
    <div v-if="isCompetence" class="description mb-24">
      <div class="description__item">
        <p class="description__item__name">Компетенция</p>
        <p class="description__item__desc">
          {{ getText(expertiseName) }}
        </p>
      </div>
    </div>
    <div v-if="isSpecialization" class="description">
      <div class="description__item mb-24">
        <p class="description__item__name">Область компетенции</p>
        <p class="description__item__desc">
          {{ getText(industryName) }}
        </p>
      </div>
      <div class="description__item mb-24">
        <p class="description__item__name">Компания</p>
        <p class="description__item__desc">
          {{ getText(companyName) }}
        </p>
      </div>
      <div class="description__item mb-24">
        <p class="description__item__name">Роль</p>
        <p class="description__item__desc">
          {{ getText(roleName) }}
        </p>
      </div>
    </div>
    <div v-if="isService" class="description mb-24">
      <div class="description__item">
        <p class="description__item__name">Услуга</p>
        <p class="description__item__desc">
          {{ getText(serviceName) }}
        </p>
      </div>
    </div>
    <div class="rate">
      <div class="rate__title">Ваша оценка</div>
      <div class="rate__body">
        <div
          class="rate__item"
          v-for="(item, index) of [1, 2, 3, 4, 5]"
          :key="item + index"
          :class="{ rate__item_active: rate === item }"
          @click="() => setRate(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <base-input
      v-model="comment"
      class="no-resize mb-24"
      description="Ваш комментарий"
      placeholder="Напишите комментарий к своей оценке"
      type="textarea"
      :is-error="v$.comment.$error"
      :error="[
        {
          text: 'Превышен лимит символов',
          status: v$.comment.maxLength.$invalid,
        },
      ]"
    />
    <base-button :disabled="!rate || v$.comment.$error" @click="submit"
      >Отправить</base-button
    >
    <div class="info-text">
      При повторном оценивании будет учитываться только последняя оценка
    </div>
  </base-no-modal>
  <alert-modal ref="alert"></alert-modal>
</template>

<script>
import BaseAlertNoModal from "@/components/base/BaseAlertNoModal";
import BaseButton from "@/components/base/BaseButton";
import BaseNoModal from "@/components/base/BaseNoModal";
import BaseInput from "@/components/base/BaseInput";
import AlertModal from "@/components/modals/AlertModal";
import Avatar from "@/components/ui/Avatar";
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { maxLength } from "@vuelidate/validators";

export default {
  name: "RatePage",
  components: {
    BaseAlertNoModal,
    BaseButton,
    BaseNoModal,
    BaseInput,
    AlertModal,
    Avatar,
  },
  data() {
    return {
      isSuccess: false,
      rate: null,
      params: null,
      comment: "",
      companyName: "",
      industryName: "",
      roleName: "",
      optionName: "",
      expertiseName: "",
      serviceName: "",
    };
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  mounted() {
    this.isSuccess = false;

    const hash = this.$route.query.hash;

    if (hash != null) {
      this.readHash(hash);
    }
  },
  watch: {
    params(val) {
      try {
        this.companyName = decodeURIComponent(val.company_name).replaceAll("+", " ");
        this.industryName = decodeURIComponent(val.industry_name).replaceAll("+", " ");
        this.optionName = decodeURIComponent(val.option_name).replaceAll("+", " ");
        this.roleName = decodeURIComponent(val.work_role_name).replaceAll("+", " ");
        this.expertiseName = decodeURIComponent(val.expertise_name).replaceAll("+", " ");
        this.serviceName = decodeURIComponent(val.service_name).replaceAll("+", " ");
        this.getUser();
      } catch (e) {
        this.showError(e);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      ratedUser: "auth/ratedUser",
    }),
    isCompetence() {
      return this.params?.type === "expertise";
    },
    isSpecialization() {
      return this.params?.type === "tribe";
    },
    isService() {
      return this.params?.type === "service";
    },
  },
  methods: {
    getText(value) {
      return value !== "undefined" ? value : "—";
    },
    setRate(value) {
      this.rate = value;
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      try {
        const formData = {
          user_id: this.params.user_id,
          type: this.params.type,
          industry_id: this.params.industry_id,
          option_id: this.params.option_id,
          work_role_id: this.params.work_role_id,
          company_id: this.params.company_id,
          rating: this.rate,
        };

        if (this.comment) {
          formData.comment = this.comment;
        }

        if (this.params.expertise_id) {
          formData.expertise_id = this.params.expertise_id;
        }

        if (this.params.service_id) {
          formData.service_id = this.params.service_id;
        }

        if (this.params.invite_rater_user_id !== null) {
          formData.invite_rater_user_id = this.params.invite_rater_user_id;
        }

        if (this.params.rater_user_id) {
          formData.rater_user_id = this.params.rater_user_id;
        }

        await this.$store.dispatch("profile/postRateUser", formData);

        this.v$.$reset();
        this.isSuccess = true;
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    goHome() {
      this.$router.push({ name: "StartPage" });
    },
    readHash(hash) {
      try {
        const encoded = decodeURIComponent(encodeURI(atob(hash)));

        const params = encoded.split("&").reduce(
          (prev, cur) => ({
            ...prev,
            [cur.split("=")[0]]: cur.split("=")[1],
          }),
          {}
        );
        if (params.expertise_id) {
          params.type = "expertise";
        }

        this.params = params;
        console.log(this.params);
      } catch (e) {
        this.showError(e);
      }
    },
    showError(e) {
      this.$refs.alert.show(this.$t("alert.error"), e, {
        type: "error",
        nameButton: "Ок",
      });
    },
    async getUser() {
      await this.$store.dispatch("auth/getUserData", this.params.user_id);
    },
    getPhoto(photo) {
      return photo;
    },
  },
  validations() {
    return {
      comment: {
        maxLength: maxLength(300),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.top-block {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  &__avatar {
    width: 114px;
    height: 114px;
    border-radius: 50%;
  }

  &__name {
    @include desktop_h1;
    font-weight: 500;
    text-align: center;
  }

  &_competence {
    margin-bottom: 40px;
  }

  &_specialization {
    margin-bottom: 32px;
  }
}

.description {
  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 24px;
    border-bottom: 1px solid $dark-gray;

    &__name {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: $text-gray;
    }

    &__desc {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.rate {
  margin-bottom: 38px;

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.01em;
    margin-bottom: 28px;
  }

  &__body {
    display: flex;
    gap: 16px;

    @include sm {
      gap: 10px;
    }
  }

  &__item {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    color: $accent;
    background-color: $bg-light-gray;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &_active {
      color: $white;
      background-color: $green;
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        width: 62px;
        height: 62px;
        position: absolute;
        border-radius: 50%;
        background-color: $green;
        opacity: 0.2;
        z-index: -1;
      }
    }
  }
}

.info-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 124%;
  color: $text-gray;
  margin-top: 12px;
}
</style>
