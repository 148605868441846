<template>
  <div class="customer-block" :class="{ special: special }">
    <h2 v-if="special" class="mt-0 block-header">{{ info.name }}</h2>
    <div v-if="showUser" class="customer-block__item customer">
      <h3 v-if="!special && !hideDescription" class="customer-block__title">Заказчик</h3>
      <div>
        <item-people
          class="item"
          :name="info.user_name"
          :company="info.user_company != null ? info.user_company.name : null"
          :photo="info.user_photo"
          is-info
        >
        </item-people>
      </div>
    </div>
    <div
      class="customer-block__item type"
      :class="Object.assign({ 'no-border': !info?.files?.length }, showLine)"
      v-if="!info.user_name"
    >
      <div class="customer-block__item type" :class="showLine" v-if="info.service_type">
        <h3 v-show="!hideDescription" class="customer-block__title">Формат работы</h3>
        <div class="customer-block__content">
          <p v-if="this.info.service_type === 'На проект'">Проект</p>
          <p v-if="this.info.service_type === 'На консультацию'">Консультация</p>
        </div>
      </div>
      <div class="customer-block__item type" :class="showLine" v-if="info.budget">
        <h3 class="customer-block__title" v-if="this.info.service_type === 'На проект'">
          Бюджет проекта
        </h3>
        <h3
          class="customer-block__title"
          v-if="this.info.service_type === 'На консультацию'"
        >
          Бюджет на 1 час консультации
        </h3>
        <div class="customer-block__content">
          <p v-if="!forExpert">
            {{ this.info?.budget?.toLocaleString() }}
            <span class="ruble" v-if="info.budget">е</span>
          </p>
          <p v-if="forExpert">
            {{ trueBudget }}
            <span class="ruble" v-if="info.budget">е</span>
          </p>
        </div>
      </div>
      <div class="customer-block__item type" :class="showLine" v-if="info.duration_id">
        <h3 class="customer-block__title" v-if="this.info.service_type === 'На проект'">
          Примерная длительность
        </h3>
        <h3
          class="customer-block__title"
          v-if="this.info.service_type === 'На консультацию'"
        >
          Количество консультаций
        </h3>
        <div class="customer-block__content">
          <p>{{ durationName }}</p>
        </div>
      </div>
      <div
        class="customer-block__item type"
        :class="showLine"
        v-if="info.hasOwnProperty('description')"
      >
        <h3 v-show="!hideDescription" class="customer-block__title">Описание задачи</h3>
        <div class="customer-block__content">
          <p class="pretty">{{ info.description.toString() }}</p>
        </div>
      </div>
      <div
        class="customer-block__item type"
        :class="showLine"
        v-if="info.execution_period"
      >
        <h3 v-show="!hideDescription" class="customer-block__title">
          Дедлайн поиска эксперта
        </h3>
        <div class="customer-block__content">
          <p>{{ date }}</p>
        </div>
      </div>
      <div
        class="customer-block__item type"
        :class="showLine"
        v-if="requestManageInfo.expert_type"
      >
        <h3 v-show="!hideDescription" class="customer-block__title">Уровень эксперта</h3>
        <div class="customer-block__content">
          <p v-if="requestManageInfo.expert_type === 'premium'">
            ТОП-менеджмент или владельцы бизнеса
          </p>
          <p v-if="requestManageInfo.expert_type === 'ordinary'">
            Cпециалист или средний менеджмент
          </p>
        </div>
      </div>
      <div class="customer-block__item type" :class="showLine" v-if="info.industries">
        <h3 v-show="!hideDescription" class="customer-block__title">Индустрии</h3>
        <div class="customer-block__content">
          <p>{{ divideData(info.industries) }}</p>
        </div>
      </div>
      <div class="customer-block__item type" :class="showLine" v-if="info.options">
        <h3 v-show="!hideDescription" class="customer-block__title">Функции</h3>
        <div class="customer-block__content">
          <p>{{ divideData(info.options) }}</p>
        </div>
      </div>
      <div class="customer-block__item type" :class="showLine" v-if="info.sub_options">
        <h3 v-show="!hideDescription" class="customer-block__title">Подфункции</h3>
        <div class="customer-block__content">
          <p>{{ divideData(info.sub_options) }}</p>
        </div>
      </div>
      <div class="customer-block__item files type" v-if="info?.files?.length">
        <h3 v-show="!hideDescription" class="customer-block__title">
          ТЗ или бриф проекта
        </h3>
        <div class="attachment">
          <a
            v-for="item in info.files"
            target="_blank"
            :href="item.filepath"
            class="attachment-item"
            :key="`attach_${item.id}`"
          >
            {{ item.name }}
          </a>
        </div>
      </div>
    </div>

    <div v-if="special" class="btn-block">
      <base-button @click="sendRespond" light>Откликнуться</base-button>
      <base-button @click="rejectRedirect">Отказаться</base-button>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/ui/Avatar";
import BaseButton from "@/components/base/BaseButton";
import ItemPeople from "@/components/ui/ItemPeople";
import { format } from "date-fns";
import ruLocale from "date-fns/locale/ru";
import { mapGetters } from "vuex";
import CompanyScroller from "@/views/startpage/components/CompanyScroller.vue";
import ContactSupportModalHelp from "../modals/ContactSupportModalHelp.vue";

export default {
  name: "CustomerBlock",
  components: { ItemPeople, BaseButton, Avatar },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    showUser: {
      type: Boolean,
      default: true,
    },
    hideDescription: {
      type: Boolean,
      default: false,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    special: {
      type: Boolean,
      default: false,
    },
    forExpert: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (!this.showUser) {
      this.removePaddingFromFirstNotEmptyItem();
    }
  },
  created() {
    this.getServiceTypes(); 
  },
  computed: {
    ...mapGetters({
      serviceTypes: "service/serviceTypes",
      requestManageInfo: "request/requestManageInfo",
    }),
    showLine() {
      return {
        "with-line": this.showLine,
      };
    },
    trueBudget() {
      //$expert_budget = $round_method($budget / (1 + $fee) / $base) * $base;
      let service = this.serviceTypes.find(
        (type) => type.name === this.info.service_type
      );
      const round_method = service.is_rounded_up ? Math.ceil : Math.floor;
      return (
        round_method(this.info.budget / (1 + service.fee) / service.round_base) *
        service.round_base
      );
    },
    durationName() {
      return this.serviceTypes
        .map((el) => el.durations)
        .flat()
        .filter((el) => el.id === this.info.duration_id)[0].name;
    },
    getPhoto() {
      if (this.info == null) return null;
      if (this.info.user_photo === "") return null;
      if (this.info.user_photo === "undefined") return null;
      return this.info.user_photo;
    },
    date() {
      const newDate = this.info.execution_period
        ? format(new Date(this.info.execution_period), "d MMMM yyyy", {
            locale: ruLocale,
          })
        : "";
      return newDate;
    },
  },
  methods: {
    removePaddingFromFirstNotEmptyItem() {
      const customerBlockItems = this.$el.querySelectorAll(".customer-block__item");
      for (const item of customerBlockItems) {
        if (item.innerHTML.trim() !== "") {
          item.style.paddingTop = "0";
          break;
        }
      }
    },
    async getServiceTypes() {
      await this.$store.dispatch("service/getServiceTypes");
    },
    sendRespond() {
      this.$emit("respond");
    },
    divideData(data) {
      if (data.length > 1) {
        return data
          .map((item) => {
            return item.name;
          })
          .join(", ");
      } else if (data.length === 1) return data[0].name;
      else return "-";
    },
    async rejectRedirect() {
      const formData = new FormData();
      formData.append("demand_id", +this.$route.params.id);
      await this.$store.dispatch("request/rejectResponse", formData);
      this.$router.push({ path: "/projects/response#invite" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.customer-block {
  display: flex;
  flex-wrap: wrap;

  .avatar {
    flex-shrink: 0;
  }

  &.special {
    display: flex;
    flex-wrap: wrap;
    background-color: $white;
    padding: 30px;
    border-radius: 24px;
    width: 440px;
    max-width: 100%;

    .customer-block__item {
      width: 100%;
    }

    @include md {
      width: 720px;
      padding: 30px 20px;
    }

    .block-header {
      @include desktop_h1;
      margin-bottom: 30px;
    }

    .customer {
      order: 1;
    }

    .budget {
      order: 3;

      @include md {
        width: 50%;
      }

      @include sm {
        width: 100%;
      }
    }

    .type {
      order: 2;

      @include md {
        width: 50%;
      }

      @include sm {
        width: 100%;
      }
    }

    .description {
      order: 4;
    }

    /* .files {
      order: 5;
    } */

    .btn-block {
      width: 100%;
      display: grid;
      padding-top: 16px;
      gap: 16px;
      order: 5;
    }

    .customer-block__budget {
      @include desktop_standart;
    }
  }

  &:not(.special) {
    .customer-block__item {
      @include md {
        padding: 12px 0;
      }

      &.type {
        @include md {
          width: 50%;
          order: 2;
        }

        @include sm {
          width: 100%;
        }
      }

      &.customer {
        @include md {
          order: 1;
        }
      }

      &.description {
        @include md {
          order: 4;
        }
      }

      &.files {
        @include md {
          order: 5;
        }
      }

      &.budget {
        @include md {
          width: 50%;
          order: 3;
        }

        @include sm {
          width: 100%;
        }
      }
    }
  }

  .person-card {
    display: flex;
    align-items: center;
    border: 1px solid $line-light-gray;
    padding: 24px;
    border-radius: 12px;

    &__company {
      @include desktop_h2;
      margin-bottom: 8px;
    }

    &__name {
      @include desktop_additional;
      color: $text-gray;
    }

    &__content {
      padding-left: 20px;
    }
  }

  &__item {
    width: 100%;
    padding: 15px 0;

    &.with-line {
      border-bottom: $gray-line;
    }

    &.customer {
      border-bottom: none;
      padding: 0 0 16px;
    }

    &.no-border {
      border-bottom: none;
    }
  }

  &__budget {
    @include desktop_h1;
  }

  &__title {
    @include desktop_additional;
    color: $text-gray;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &__content {
    p {
      @include desktop_standart;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.pretty {
  white-space: pre-wrap;
  overflow-y: scroll;
  max-height: 300px;
}
.attachment {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &-item {
    background-color: $bg-light-gray;
    padding: 10px 10px;
    color: #000;
    @include mobile_text;
  }
}
</style>
