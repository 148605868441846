<template>
  <div v-if="location & isOpen & isLocalOpen" class="info-block">
    <div class="title">
      <div class="title-text">
        <svg-icon name="tg_ad_block" :width="mobile ? 18 : 25" :height="mobile ? 18 : 25" />
        <p>Узнавайте первым!</p>
      </div>
      <div @click="close('tg_popup_close')">
        <svg-icon name="close" :width="16" :height="16" class="close" />
      </div>
    </div>
    <p class="subtitle">
      Подписывайтесь на наш канал и узнавайте об обновлениях платформы, горячих проектах и
      новостях из сфер бизнеса и HR
    <div class="arrow-mobile" @click="close('tg_popup_action')">
      <svg-icon name="arrow-big-right-white" :width="15" :height="15" class="mobile" />
    </div>
    </p>
    <div class="actions">
      <div class="button" @click="close('tg_popup_action')">Перейти в канал</div>
      <p class="channel-name">@{{ link.split("/").slice(-1)[0] }}</p>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import BaseButton from "@/components/base/BaseButton";
import { mapGetters } from "vuex";
export default {
  name: "InfoBlock",
  components: { SvgIcon, BaseButton },
  data() {
    return {
      isLocalOpen: true,
    };
  },
  computed: {
    ...mapGetters({
      footer: "content/footer",
      isAuth: "auth/isAuth",
      token: "auth/token",
      visitTime: "status/visitTime",
    }),
    location() {
      if (this.$route.path == "/profile/information" || this.$route.path == "/") {
        return true;
      }
      return false;
    },
    link() {
      if (this.footer) {
        return this.footer.tg_popup.url;
      }
      return "";
    },
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
    isOpen() {
      //console.log((Date.now() / 1000 - this.visitTime.tgpopup.close / 1000) / 60)
      return (((Date.now() / 1000 - this.visitTime.tgpopup.close / 1000) / 60) > config.VUE_TG_POPUP_EVERY_MIN) && 
      (this.visitTime.tgpopup.action == 0)
    },
  },
  mounted() {
    this.initTimes();
  },
  methods: {
    async close(evevt_name) {
      this.isLocalOpen = false
      let payload = {
        'evevt_name': evevt_name
      }
      const key = 'tgpopup_' + evevt_name.split('_')[2]
      this.$store.dispatch("status/updateVisitTimes", key);
      this.$store.dispatch("status/dumpEvent", { 'data': payload });
      if (evevt_name == "tg_popup_action") { window.open(config['VUE_APP_API_URL'] + '/api/redirect/tg', '_blank'); }
      await this.getTimes()
    },
    async getTimes() {
      await this.$store.dispatch("status/getVisitTimes");
    },
    async initTimes() {
      await this.$store.dispatch("status/initVisitTimes");
    },
  },


};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.info-block {
  z-index: 999;
  position: fixed;
  bottom: 60px;
  right: 60px;
  display: flex;
  padding: 20px;
  width: 430px;
  height: 207px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  background: linear-gradient(97deg, #548dd1 -4.7%, #3c81d2 101.25%);
  color: white;

  @include sm {
    // display: none;
    height: auto;
    display: flex;
    position: relative;
    bottom: 0px;
    right: 0px;
    margin-bottom: 12px;
    width: 100%;
    border-radius: 18px;
    background: linear-gradient(100deg, #538cd1 9.58%, #496387 35.71%, #42434e 70.38%);
  }
}

.title {
  display: flex;
  justify-content: space-between;
  font-family: Muller;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 124%;
  width: 100%;
  padding-bottom: 12px;

  @include sm {
    padding-bottom: 8px;
    font-size: 15px;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;

  @include sm {
    padding-bottom: 0px;
  }
}

.title-text {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.subtitle {
  font-family: SFProDisplay;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  padding-bottom: 20px;

  @include sm() {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    text-align: left !important;
    gap: 10px;
    font-size: 13px;
    padding-bottom: 0px;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.button {
  cursor: pointer;
  display: flex;
  width: 249px;
  height: 56px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  color: #42434e;
  text-align: center;
  font-family: Muller;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;

  //line-height: 20px;
  @include sm() {
    display: none;
  }
}

.channel-name {
  position: absolute;
  right: 20px;
  bottom: 20px;
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  opacity: 0.4;

  @include sm() {
    display: none;
  }
}

.arrow-mobile {
  display: none;

  @include sm() {
    cursor: pointer;
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background: #ffffff33;
    align-items: center;
    padding: 10px;
    padding-left: 7px;
  }
}

.mobile {
  display: none;

  @include sm() {
    display: block;
  }
}
</style>
