<template>
  <div class="work-experience-item">
    <div class="work-experience-item__info">
      <div class="work-experience-item__date">{{ date }}</div>
      <div
        class="work-experience-item__company"
        :class="{ unknown: status === 'moderation' }"
      >
        {{ status !== "moderation" ? title : "Компания на модерации" }}
      </div>
    </div>
    <div class="work-experience-item__control">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
export default {
  name: "WorkExperienceItem",
  props: {
    dateFrom: {
      type: String,
      default: "",
    },
    dateTo: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
  },
  computed: {
    date() {
      const from = this.dateFrom ? `${format(new Date(this.dateFrom), "yyyy")} -` : "";
      const to = this.dateTo !== null ? format(new Date(this.dateTo), "yyyy") : "н.в.";

      return `${from} ${to}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.work-experience-item {
  display: flex;
  border-bottom: 1px solid $line-light-gray;
  padding: 24px 0;

  @include sm {
    padding: 12px 0;
  }

  &:first-child {
    padding-top: 0px;
  }
  &:hover {
    .work-experience-item__control {
      opacity: 1;
    }
  }

  &__info {
    display: flex;
    align-items: center;

    @include sm {
      flex-wrap: wrap;
    }
  }

  &__date {
    @extend %text-standart;
    font-size: 16px;
    font-weight: 700;
    margin-right: 30px;
    width: 120px;
    flex-shrink: 0;
    @include sm {
      padding: 5px 0;
      margin-bottom: 5px;
    }
  }

  &.single {
    .work-experience-item {
      &__date {
        width: 45px;
      }

      &__info {
        @include sm() {
          flex-wrap: nowrap;
          align-items: flex-start;
        }
      }
    }
  }

  &__control {
    margin-left: auto;
    flex-shrink: 0;
    opacity: 1;
    @include md {
      opacity: 1;
    }
    :slotted(.svg-icon) {
      margin: 0 10px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__company {
    @extend %text-standart;
    font-size: 15px;

    &.unknown {
      color: $text-gray;
    }

    @include sm {
      padding: 5px 0;
      width: 100%;
    }
  }
}
</style>
