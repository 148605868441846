<template>
  <div class="add-work-modal">
    <base-modal size="650" :is-open="isOpen" @close="closeModal">
      <div class="first-block" ref="v-modal">
        <h2 v-if="!onlyShow" class="information__header">
          {{ this.modalId ? "Кейс" : "Новый кейс" }}
        </h2>
        <h2 v-else class="information__header">
          {{ name }}
        </h2>
        <p v-show="!this.modalId" class="subtitle">
          Новый кейс будет автоматически сохранен в профиле
        </p>
        <base-input
          v-if="!onlyShow"
          class="mb-6 mt-3"
          v-model="name"
          description="Название проекта"
          placeholder="Разработка финансовой модели для фэшн-ритейла"
          :is-error="v$.name.$error"
          :error="[
            {
              text: 'Название проекта обязательно для заполнения',
              status: v$.name.required.$invalid,
            },
            {
              text: 'Максимальное количество символов 200',
              status: v$.name.maxLength.$invalid,
            },
          ]"
        />
        <base-input
          v-if="!onlyShow"
          ref="vOtherOptions-description"
          class="mb-6"
          description="Какая стояла задача"
          placeholder="Определить применимость зарубежной модели монетизации в ритейле на российском рынке"
          type="textarea"
          :maxTextarea="2000"
          v-model="task"
          :is-error="v$.task.$error"
          :error="[
            {
              text: 'Задача обязательна для заполнения',
              status: v$.task.required.$invalid,
            },
            {
              text: 'Максимальное количество символов 2000',
              status: v$.task.maxLength.$invalid,
            },
          ]"
        ></base-input>
        <div v-else>
          <p class="title">Какая стояла задача</p>
          <p class="value">{{ task }}</p>
        </div>
        <base-input
          v-if="!onlyShow"
          ref="vOtherOptions-description"
          class="mb-6"
          description="Что вы сделали в рамках проекта"
          placeholder="1. Провел анализ крупнейших игроков зарубежного и российского рынков
2. Провел качественное исследование с ключевыми участниками
3. Синтезировал информацию и сформулировал перечень рекомендаций"
          type="textarea"
          :maxTextarea="2000"
          v-model="solution"
          :is-error="v$.solution.$error"
          :error="[
            {
              text: 'Обязательно для заполнения',
              status: v$.solution.required.$invalid,
            },
            {
              text: 'Максимальное количество символов 2000',
              status: v$.solution.maxLength.$invalid,
            },
          ]"
        ></base-input>
        <div v-else>
          <p class="title">Что сделано в рамках проекта</p>
          <p class="value">{{ solution }}</p>
        </div>
        <base-input
          v-if="!onlyShow"
          ref="vOtherOptions-description"
          class="mb-6"
          description="Каких результатов достигли"
          placeholder="Приняли стратегические решения по дальнейшему внедрению продукта на российском рынке"
          type="textarea"
          :maxTextarea="2000"
          v-model="results"
          :is-error="v$.results.$error"
          :error="[
            {
              text: 'Результаты обязательны для заполнения',
              status: v$.results.required.$invalid,
            },
            {
              text: 'Максимальное количество символов 2000',
              status: v$.results.maxLength.$invalid,
            },
          ]"
        ></base-input>
        <div v-else>
          <p class="title">Какие результаты достигнуты</p>
          <p class="value">{{ results }}</p>
        </div>
      </div>

      <template v-if="!onlyShow | caseFile.length | links.length" #body1>
        <div v-if="onlyShow">
          <div v-if="caseFile.length">
            <p class="title">Вложения</p>
            <div class="attachment">
              <a
                target="_blank"
                :href="item.url"
                class="attachment-item"
                v-for="item in caseFile"
                :key="`attach_${item.id}`"
                >{{ item.name }}</a
              >
            </div>
          </div>
          <div v-if="links.length">
            <p class="title">Cсылки</p>
            <p class="value">{{ links }}</p>
          </div>
        </div>
        <div v-else>
          <div class="role-block__body">
            <base-multi-file
              id="10"
              tmp="2"
              class="mb-6"
              title="Прикрепите ТЗ или бриф проекта"
              description="Добавьте не более 5 файлов весом до 50мб"
              @file="updateDataFile"
              :tmp-files="caseFile"
              caseFile-type=".jpg,.png,.pdf,.docx,.doc,.rtf,.pptx,.ppt,.msg"
              is-optional
              :is-error="v$.caseFile.$error"
              :error="[
                {
                  text: 'Неверный тип файла',
                  status: v$.caseFile.checkType.$invalid,
                },
                {
                  text: 'Максимальный размер файла - 50мб',
                  status: v$.caseFile.checkSize.$invalid,
                },
                {
                  text: 'Добавьте не более 5 файлов',
                  status: v$.caseFile.maxLength.$invalid,
                },
              ]"
            />
            <base-input
              is-optional
              v-model="links"
              description="Добавьте ссылку на статьи"
              placeholder="Ваша ссылка"
            />
            <base-button class="add-work-modal__submit" :one-click-event="onSubmit"
              >Сохранить</base-button
            >
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";
import useVuelidate from "@vuelidate/core";
import BaseMultiFile from "@/components/base/BaseMultiFile";
import { required, maxLength } from "@vuelidate/validators";
import BaseInput from "@/components/base/BaseInput";
import { typeFile } from "@/utils/helper";

export default {
  name: "AddCaseModal",
  components: {
    BaseMultiFile,
    BaseInput,
    BaseButton,
    BaseModal,
  },
  props: {
    onlyShow: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  data() {
    return {
      modalId: null,
      isOpen: false,
      task: "",
      solution: "",
      results: "",
      caseFile: [],
      links: "",
      name: "",
    };
  },
  validations() {
    return {
      task: {
        required,
        maxLength: maxLength(2000),
      },
      solution: {
        required,
        maxLength: maxLength(2000),
      },
      results: {
        required,
        maxLength: maxLength(2000),
      },
      name: {
        required,
        maxLength: maxLength(200),
      },
      caseFile: {
        checkType: this.checkType,
        checkSize: this.checkSize,
        maxLength: maxLength(5),
      },
    };
  },
  mounted() {},
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    hideModal() {
      this.isOpen = false;
    },
    showModal(data) {
      this.v$.$reset();
      if (data && data.name) {
        const info = JSON.parse(JSON.stringify(data));
        this.modalId = info.id;
        this.task = info.task;
        this.solution = info.solution;
        this.results = info.results;
        this.caseFile = info.attachments;
        this.links = info.links;
        this.name = info.name;
      } else {
        this.modalId = null;
        this.task = "";
        this.solution = "";
        this.results = "";
        this.caseFile = [];
        this.links = "";
        this.name = "";
      }
      this.isOpen = true;
    },

    async onSubmit() {
      const result = await this.v$.$validate();
      if (!result) {
        this.$refs[`v-modal`].scrollIntoView({ behavior: "smooth" });
        return;
      } else {
        const bodyFormData = new FormData();
        bodyFormData.append("task", this.task);
        bodyFormData.append("solution", this.solution);
        bodyFormData.append("results", this.results);
        bodyFormData.append("links", this.links);
        if (this.modalId) {
          bodyFormData.append("id", this.modalId);
        }
        let files = [];
        if (this.caseFile) {
          files = [...this.caseFile];
          files.forEach((fileObj, idx) => {
            fileObj.id
              ? bodyFormData.append(`attachments[${idx}][id]`, fileObj.id)
              : bodyFormData.append(`attachments[${idx}][file]`, fileObj);
          });
        }
        bodyFormData.append("name", this.name);
        this.$emit("addCase", bodyFormData);
        this.v$.$reset();
      }
    },
    async updateDataFile(e) {
      this.caseFile = e;
      const result = await this.v$.caseFile.$validate();
      if (!result) {
        return;
      }
    },
    checkType(e) {
      if (e.length) {
        let counter = 0;
        e.forEach((item) => {
          if (typeFile.includes(item.type) | item.id) counter++;
        });
        return e.length == counter;
      } else {
        return true;
      }
    },
    checkSize(e) {
      if (e.length) {
        let counter = 0;
        e.forEach((item) => {
          if ((item.size < 50 * 1024 * 1024) | item.id) {
            counter++;
          }
        });
        return e.length == counter;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss">
.add-work-modal .dialog-content {
  max-height: none;
}
</style>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.add-work-modal {
  &__submit {
    margin-top: 32px;

    @include sm {
      margin-top: 30px;
    }
  }
}

.information__header {
  margin-bottom: 10px;
  @include sm {
    font-size: 24px;
  }
}

.select-month {
  margin-bottom: 24px;

  &.error {
    margin-bottom: 18px;
  }
}

.dialog-content:first-child {
  padding-bottom: 10px;

  .input-radio.switch {
    margin-top: -14px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.error-text {
  color: red;
}
.subtitle {
  color: #a0a0a0;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 123.077% */
  letter-spacing: 0.26px;
  @include sm {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
  }
}
.sphere-roles {
  &__description {
    @extend %text-standart;
    max-width: 315px;
    padding-bottom: 8px;
  }

  .multi-select-rows {
    margin-bottom: 12px;
  }

  &__header {
    position: relative;

    .information__header {
      margin-right: 10px;
      margin-bottom: 0;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }

    &-tooltip {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-left: auto;
      cursor: pointer;
      top: 3px;
      right: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #e6e6e6;
    }
  }
}

.select-function {
  &__label {
    font-size: 18px;
  }

  &__select {
    margin-bottom: 21px;
  }
}

.select-date {
  margin-bottom: 0px;
}

.select-industry {
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 4px;

    .input-group {
      margin-bottom: 0;
    }
  }

  &__error {
    grid-column: span 2;

    .error-text {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}
.title {
  display: flex;
  @extend %text-standart;
  margin-bottom: 10px;
  color: #a0a0a0;
}
.value {
  display: flex;
  margin-bottom: 20px;
  @extend %text-standart;
}
.attachment {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;

  &-item {
    background-color: $bg-light-gray;
    padding: 14px 15px;
    color: #000;
    border-radius: 8px;
    cursor: pointer;
    @include mobile_text;
  }
}
.role-block {
  display: grid;
  padding: 24px 0 0px;
  border-bottom: 1px solid $line-light-gray;

  @include md {
    margin: 0;
  }

  &:first-child {
    border-top: none;
  }

  .input-container__block {
    margin-bottom: 24px;
  }

  &__body {
    display: block;
    position: relative;
    max-height: 50vh;
    width: auto;

    @include sm {
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    .ps {
      max-height: 50vh;
    }
  }

  &__list {
    padding-right: 18px;
  }
}
</style>
