<template>
  <div :class="classGeneral" :style="styleGeneral">
    <slot />
  </div>
</template>

<script>
// medium
export default {
  name: 'Hidden',
  props: {
    phone: Boolean,
    tablet: Boolean,
    tabletBig: Boolean,
    pc: Boolean,
    pcBig: Boolean
  },
  computed: {
    classGeneral() {
      let newClass = '';

      newClass += this.phone ? ' hiddenVGR-xs' : '';
      newClass += this.tablet ? ' hiddenVGR-sm' : '';
      newClass += this.tabletBig ? ' hiddenVGR-md' : '';
      newClass += this.pc ? ' hiddenVGR-lg' : '';
      newClass += this.pcBig ? ' hiddenVGR-xl' : '';

      return `${newClass} ${this.$props.class || ''}`;
    },
    styleGeneral() {
      return this.$props.style;
    }
  }
};
</script>

<style scoped>
@media (max-width: 575px) {
  .hiddenVGR-xs {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .hiddenVGR-sm {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hiddenVGR-md {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hiddenVGR-lg {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hiddenVGR-xl {
    display: none;
  }
}
</style>
