<template>
  <div v-if="loadLang">
    <base-impersonate-modal />
    <the-header />
    <router-view />
    <help-button />
    <info-block class="infoblock" />
    <the-footer />
  </div>
</template>

<script>
import TheHeader from "@/components/layout/TheHeader";
import TheFooter from "@/components/layout/TheFooter";
import HelpButton from "@/components/ui/button/HelpButton";
import InfoBlock from "@/components/ui/InfoBlock";
import BaseImpersonateModal from "@/components/base/BaseImpersonateModal";
import { useI18n } from "vue-i18n";
import { loadLocaleMessages, setI18nLanguage } from "./i18n";
import { mapGetters } from "vuex";

export default {
  components: { TheFooter, TheHeader, HelpButton, InfoBlock, BaseImpersonateModal },
  data() {
    return {
      loadLang: false,
    };
  },
  created() {
    this.startLang();
    document.documentElement.style.setProperty(
      "--scrollbar-width",
      window.innerWidth - document.documentElement.clientWidth + "px"
    );
  },
  computed: {
    ...mapGetters({
      lang: "auth/lang",
    }),
  },
  methods: {
    async startLang() {
      const i18n = useI18n();
      await loadLocaleMessages(i18n, this.lang);
      await setI18nLanguage(i18n, this.lang);
      this.loadLang = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.infoblock {
  @include sm() {
    display: none;
  }
}
</style>
