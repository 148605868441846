<template>
  <div :class="{ overlay: isOpen || isAuth }">
    <div class="main" v-click-outside="handleClose">
      <div
        v-if="isOpen || isAuth"
        class="menu-container"
        v-click-outside="handleFocusOut"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <div class="menu">
          <template v-for="item in menuType">
            <a class="nav" :href="item['link']">{{ item["title"] }}</a>
          </template>
          <a v-if="isAuth" class="nav" href="#" @click="signOut">{{
            $t("dropdown-menu.sign-out")
          }}</a>
        </div>
        <div style="padding: 10px 10px">
          <info-block />
        </div>
      </div>

      <div class="actions" v-if="!isAuth">
        <div class="menu-button" @click="toggleMenu">
          <svg-icon v-if="!isOpen" name="mobile-menu" :width="50" :height="50" />
          <svg-icon v-else name="mobile-menu-close" :width="50" :height="50" />
        </div>
        <base-button
          class="button--success"
          @click="this.$router.push({ name: 'RegistrationPage' })"
          >{{ isLocalExecutor ? "Предложить экспертизу" : "Начать работу" }}</base-button
        >
      </div>
      <div class="actions-auth" v-else>
        <div
          @click="setCustomer"
          class="switcher"
          :class="{ switcher_picked: isLocalCustomer }"
        >
          Заказчик
        </div>
        <div
          @click="setExecutor"
          class="switcher"
          :class="{ switcher_picked: isLocalExecutor }"
        >
          Эксперт
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import { mapGetters } from "vuex";
import BaseButton from "@/components/base/BaseButton";
import InfoBlock from "@/components/ui/InfoBlock";
export default {
  name: "BaseMenuBottom",
  components: { SvgIcon, BaseButton, InfoBlock },
  watch: {
    isOpen(v) {
      const body = document.querySelector("html");
      v ? body.classList.add("no-scroll") : body.classList.remove("no-scroll");
    },
  },
  computed: {
    ...mapGetters({ isAuth: "auth/isAuth", getUserState: "auth/getUserState" }),
    isLocalExecutor() {
      return this.getUserState === "executor";
    },
    isLocalCustomer() {
      return this.getUserState === "customer";
    },
    menuType() {
      if (!this.isAuth) return this.menuNoAuth;
      if (this.getUserState === "executor") return this.menuExecutor;
      if (this.getUserState !== "executor") return this.menuCustomer;
    },
  },
  data() {
    return {
      isOpen: false,
      menuNoAuth: [
        {
          title: "Почему мы",
          link: "/#whoweare",
        },
        {
          title: "Индустрии",
          link: "/#industries",
        },
        {
          title: "Для компаний",
          link: "/mock",
        },
        {
          title: "Помощь",
          link: "/faq",
        },
      ],
      menuExecutor: [
        {
          title: "Профиль",
          link: "/profile",
        },
        {
          title: "Запросы",
          link: "/projects/response",
        },
        // {
        //   title: "Сообщества",
        //   link: "/community",
        // },
        {
          title: "Помощь",
          link: "/faq",
        },
      ],
      menuCustomer: [
        {
          title: "Профиль",
          link: "/profile",
        },
        {
          title: "Новая заявка",
          link: "/request-add",
        },
        {
          title: "Мои проекты",
          link: "/projects/request",
        },
        // {
        //   title: "Сообщества",
        //   link: "/community",
        // },
      ],
    };
  },

  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    handleFocusOut() {
      this.isOpen = false;
    },
    handleClose() {
      this.$emit("close");
      this.isOpen = false;
    },
    toTop() {
      if (this.isLocalExecutor) {
        this.$store.commit("auth/SET_USERSTATE", "customer");
        this.$router.replace({ path: "StartPage" });
      }
    },
    handleTouchStart(e) {
      this.startY = e.touches[0].clientY;
    },
    handleTouchEnd(e) {
      this.endY = e.changedTouches[0].clientY;
      if (this.endY - this.startY > 50) {
        // Threshold for swipe down action
        this.isOpen = false;
      }
    },
    signOut() {
      this.isOpen = false;
      this.$store.reset();
      this.$store.dispatch("auth/signOut");
    },
    setCustomer() {
      this.$store.commit("auth/SET_USERSTATE", "customer");
    },
    setExecutor() {
      this.$store.commit("auth/SET_USERSTATE", "executor");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.main {
  position: fixed;
  display: flex;
  justify-content: center;
  gap: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background-color: #fff;
  border-radius: 0px;
  transition: all 0.3s ease;
  z-index: 19 !important;
}

.menu-container {
  position: fixed;
  height: fit-content;
  width: inherit;
  left: 0;
  right: 0;
  bottom: 70px;
  z-index: 19 !important;
  background-color: #ffffff;
  border-radius: 24px 24px 0px 0px;
}
.menu {
  display: flex;
  margin: 12px 12px;
  margin-bottom: 0px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f6f6f6;
  border-radius: 12px;
  gap: 16px;
}

.menu-button {
  cursor: pointer;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.4);
}

.nav {
  font-family: Muller;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  cursor: pointer;
  &:hover {
    color: $green;
  }
}
.actions {
  display: flex;
  justify-content: row;
  align-items: center;
  gap: 30px;
  width: 100% !important;
  // max-width: 320px;
}
.actions-auth {
  display: flex;
  justify-content: row;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  width: 100%;
  //max-width: 320px;
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 5px;
}
.switcher {
  display: flex;
  justify-content: center;
  font-family: SFProDisplay;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 18.6px */
  letter-spacing: 0.3px;
  width: 169px;
  height: 44px;
  padding: auto 16px;
  align-items: center;
  border-radius: 7px;
  color: #a0a0a0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 124%;

  &_picked {
    background: #4b4c57;
    color: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover:not(.switcher_picked) {
    color: #4b4c57;
    transition: 0.3s all linear;
  }
}
</style>
