<template>
  <div class="input-group">
    <div class="title">
      <p v-if="description" class="description">
        {{ description }}
      </p>
      <p class="optional" v-if="isOptional">необязательно</p>
    </div>
    <div class="grid">
      <switch-block
        :is-active="type == 'ordinary'"
        icon="switch-ordinary"
        text="Cпециалист или средний менеджмент"
        @click="choose('ordinary')"
      />
      <switch-block
        is-premium
        :is-active="type == 'premium'"
        icon="switch-premium"
        text="ТОП-менеджмент или владельцы бизнеса"
        @click="choose('premium')"
      />
    </div>
    <div v-if="isError" class="input-error">{{ errorString }}</div>
  </div>
</template>

<script>
import SwitchBlock from "@/components/component/SwitchBlock";
export default {
  name: "Switch",
  components: { SwitchBlock },
  props: {
    description: {
      type: String,
      default: "",
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
  },
  computed: {
    getMarginButton() {
      if (this.range) return 16;
      return 10;
    },
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
  },
  data() {
    return {
      type: "",
    };
  },
  methods: {
    choose(type) {
      this.type = type;
      this.$emit("update:modelValue", this.type);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

input::placeholder {
  @extend %desktop_standart;
  color: $text-gray;
  @include sm() {
    color: #a0a0a0;
    font-family: SFProDisplay;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.26px;
  }
}

textarea::placeholder {
  @extend %desktop_standart;
  color: $text-gray;
  @include sm() {
    color: #a0a0a0;
    font-family: SFProDisplay;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.26px;
  }
}

.clear-input {
  margin-right: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.special-range.input-group .description,
.monthly-income-from.input-group .description {
  margin-bottom: 12px;
}

.special-range.input-group {
  margin-top: 32px;

  @include sm() {
    margin-top: 16px;
  }
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
}
.input-group {
  margin-bottom: 12px;
  @include sm() {
    margin-bottom: 12px;
  }
  &__search {
    margin-left: 23px;
    flex-shrink: 0;
  }
  &__count {
    position: absolute;
    color: $text-gray;
    bottom: 10px;
    right: 20px;
    font-size: 12px;
    padding-top: 0px;
    border-radius: 3px;

    @include sm() {
      color: #a0a0a0;
      font-family: SFProDisplay;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
      letter-spacing: 0.26px;
    }
  }
  &__range {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    padding: 0 20px;
  }
  .description {
    display: flex;
    @extend %text-standart;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 500;
    color: $input-label;
    @include sm {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 106.667% */
    }
  }

  .description-status {
    margin-left: auto;
    font-size: 16px;
    color: $text-gray;
    font-weight: 400;
  }

  .sub-description {
    @extend %desktop_standart;
    color: $text-gray;
    @include sm() {
      color: #a0a0a0;
      font-family: SFProDisplay;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
      letter-spacing: 0.26px;
    }
    margin-top: var(--input-group--sub-description--margin-button);
  }
  .sub-prepend {
    @extend %text-standart;
    color: $text-gray;
    margin-top: var(--input-group--sub-description--margin-button);
    margin-left: 10px;
  }
  .input-wrapper__error {
    border: 1px solid $error;
  }
  .input-clear {
    padding-left: 20px;
  }
}
.input-error {
  color: #ef693f;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}
.grey {
  background: $bg-light-gray;
}

.white {
  background: white;
}
.input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 12px;
  position: relative;

  .input {
    @include desktop_standart;
    width: 100%;
    padding: 17px 20px 17px 20px;
    border-radius: 12px;
    &.special-input {
      height: 252px;
    }
    &.error {
      border-color: $error;
    }
  }
  .input-group__postfix {
    position: absolute;
    right: 8px;
  }
}

.no-resize textarea {
  resize: none;
}

.optional {
  @include desktop_additional;
  margin-bottom: 10px;
  color: $text-gray;
  @include sm() {
    color: #a0a0a0;
    font-family: SFProDisplay;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.26px;
  }
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sphere-roles {
  &__description {
    @extend %text-standart;
    max-width: 315px;
    padding-bottom: 10px;
  }

  .multi-select-rows {
    margin-bottom: 12px;
  }

  &__header {
    position: relative;
    margin-bottom: 20px;

    .information__header {
      margin-right: 10px;
      margin-bottom: 20px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &-tooltip {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-left: auto;
      margin-bottom: 10px;
      cursor: pointer;
      top: 3px;
      right: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #e6e6e6;
    }
  }
}
</style>
