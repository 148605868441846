<template>
  <div v-if="isLoad" class="container">
    <div class="card skeleton"></div>
  </div>
  <div v-else class="container">
    <div :class="classes">
      <div class="header" v-if="$slots.header">
        <slot name="header" />
      </div>
      <div class="card">
        <div class="nav">
          <div class="nav__body global-scroll-for-contend">
            <div class="block_customer">
              <h2 class="title">Заказчик</h2>
              <slot name="nav" />
            </div>
            <div class="block">
              <div class="point_title" @click="isOpen1 = !isOpen1">
                <h2 :class="{ title: isOpen1 }">Описание</h2>
                <svg-icon
                  width="12"
                  name="confirmation-down-white"
                  :class="{ reverse: isOpen1 }"
                ></svg-icon>
              </div>
              <slot v-if="isOpen1" name="nav1" />
            </div>
            <div class="block">
              <div class="point_title" @click="isOpen2 = !isOpen2">
                <h2 :class="{ title: isOpen2 }">О задаче</h2>
                <svg-icon
                  width="12"
                  name="confirmation-down-white"
                  :class="{ reverse: isOpen2 }"
                ></svg-icon>
              </div>
              <slot v-if="isOpen2" name="nav2" />
            </div>
            <div class="container__btn">
              <slot name="btn" />
            </div>
          </div>
        </div>
        <div class="container__body">
          <div class="container__body-content">
            <slot name="body" />
          </div>
        </div>
        <div class="container__btn-footer">
          <slot name="btn" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "ContainerSplitted",
  components: { SvgIcon },
  props: {
    fullBody: Boolean,
    noNav: Boolean,
  },
  data() {
    return {
      isOpen1: true,
      isOpen2: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoad: "content/isLoad",
    }),
    classes() {
      return {
        "container__full-body": this.fullBody,
        "container__no-nav": this.noNav,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
@keyframes pulse-bg {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #d0d0d0;
  }
  100% {
    background-color: #ddd;
  }
}
.skeleton {
  animation: pulse-bg 2s infinite;
  border-radius: 24px;
  height: 400px;
  @include sm {
    height: 200px;
  }
}

.point_title {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  padding-bottom: 20px;
  @include md {
    padding: 10px;
  }
}
.container {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto 10px;
  padding: 0px;
  @include lg {
    width: auto;
    min-width: auto;
  }
  @include md {
    padding: 24px;
  }
  @include sm {
    padding: 0 12px 0 12px;
    margin-bottom: 30px;
  }
  .header {
    padding-left: 40px;
    padding-top: 20px;
    padding-bottom: 30px;
    @include md {
      padding-left: 15px;
      padding-top: 6px;
      padding-bottom: 30px;
    }
    @include sm {
      padding-left: 0;
      text-align: center;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &.nav-show {
    .card .nav {
      @include md {
        display: block;
        max-width: 100%;
      }
    }
  }

  &__btn {
    display: block;
    @include md {
      display: none;
    }
  }
  &__btn-footer {
    display: none;
    @include md {
      display: block;
      :slotted(*) {
        margin-top: 40px;
      }
    }
  }
  .card &__body {
    background-color: #fff;
    border-radius: 24px;
    padding: 40px;
    width: 100%;
    @include md {
      max-width: 100%;
    }
    &__body-content {
      background-color: #fff;
      border-radius: 24px;
      max-width: 680px;
      padding: 40px;
      width: 100%;
      @include md {
        max-width: 100%;
      }
    }
  }
  .reverse {
    transform: rotate(0.5turn);
  }
  .card {
    & h1 {
      @extend %desktop_h1;
    }
    padding: 40px;
    border-radius: 24px;
    display: flex;
    gap: 10px;
    @include md {
      display: grid;
      gap: 0;
      padding: 30px 20px 20px 20px;
    }
    .block_customer {
      background-color: #fff;
      border-radius: 24px;
      padding: 20px 30px;
      margin-bottom: 10px;
      padding-bottom: 0px;
      @include md {
        padding: 10px 30px;
      }
    }
    .block {
      background-color: #fff;
      border-radius: 24px;
      padding: 20px 30px;
      margin-bottom: 10px;
      @include md {
        padding: 20px 30px;
      }
    }
    .nav {
      max-width: 414px;
      width: 100%;
      @include md {
        margin-bottom: 0px;
      }
      &__body {
        width: 100%;
      }
      @include md {
        display: none;
      }
    }
  }
  &__full-body .card,
  &__full-body &__body {
    max-width: 100%;
    width: 100%;
  }

  &__no-nav {
    .nav {
      display: none;
    }
  }
}
</style>
