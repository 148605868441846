<template>
  <div class="rate" :class="{ 'rate_small-margin': smallMargin }">
    <div class="rate__title">
      {{ title }}
    </div>
    <div class="rate__body">
      <div 
        class="rate__item"
        v-for="(item, index) of [1, 2, 3, 4, 5]"
        :key="item + index"
        :class="{ 'rate__item_active': rating === item }"
        @click="() => setRate(item)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RatingInput',
  data() {
    return {
      rating: null
    }
  },
  props: {
    title: {
      type: String
    },
    prevRate: {
      type: Number,
      default: 0
    },
    smallMargin: {
      type: Boolean,
      default: false,
    },
    // Пропсы ниже только для оценок компетенций и специализаций пользователей
    isUpdateFunction: {
      type: Boolean,
      default: false,
    },
    // expertiseId: {
    //   type: Number,
    // },
    // tribeId: {
    //   type: Object,
    // },
    type: {
      type: String,
    },
    /**
     * Полность весь обекст для большего контроля над обработкой
     */
    fullObject: {
      type: Object,
      default: () => ({}),
    }
  },
  created() {
    if (this.prevRate) {
      this.rating = this.prevRate;
    }
  },
  methods: {
    setRate(value) {
      if (!this.isUpdateFunction) {
        this.rating = value;
        this.$emit('update:modelValue',  value);
      } else {
        this.rating = value;
        console.log({...this.fullObject,  ...{rating: value} });
        this.$emit('set-rate', {...this.fullObject,  ...{rating: value} });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';

.rate {
  margin-bottom: 38px;

  &_small-margin {
    margin-bottom: 32px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.01em;
    margin-bottom: 28px;
  }

  &__body {
    display: flex;
    gap: 27px;

    @include sm {
      gap: 10px;
    }
  }

  &__item {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    color: $accent;
    background-color: $bg-light-gray;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &_active {
      color: $white;
      background-color: $green;
      position: relative;
      z-index: 1;

      &::before {
        content: '';
        width: 62px;
        height: 62px;
        position: absolute;
        border-radius: 50%;
        background-color: $green;
        opacity: 0.2;
        z-index: -1;
      }
    }
  }
}
</style>