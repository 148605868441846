import AuthService from '@/services/authService'
import LsUtil from '@/utils/ls'
import { LS_REFRESH_TOKEN, LS_USER_LANG, LS_USER_STATE, LS_PREVIOUS_TOKEN, NOT_ME, LS_MAIL_OK } from '@/utils/ls/keys'
import router from "@/router";
import registrationService from "@/services/registrationService";
import { errorFormat } from "@/utils/helper";

/**
 @typedef userTribes
 @type {Object}
 @property {string} industryName "Гостиничный и ресторанный бизнес"
 @property {string} optionName "ИТ разработка"
 @property {string} optionStatus "active"
 @property {string} id "321"

 */
/**
 @typedef User
 @type {Object}
 @property {string} email "mail@amiga.agency"
 @property {number} id 3
 @property {number} is_corporate 1
 @property {boolean} isVerified "false" Верифицированный ли email
 @property {boolean} isFilledTribes "true" Добавлины ли трайбы
 @property {string} lastName "Иванов"
 @property {string} name "Иван"
 @property {string} patronymic "Иванович"
 @property {string} phone "+79990408893"
 @property {string} photo "/storage/user_photos/HmJ3tGcfhkDlnAkdWYTHg1RcnL5jtY4XmTlwRVAM.png"
 @property {Object[]} work_types {} -
 @property {userTribes[]} userTribes {} -
 */

export default {
    namespaced: true,
    state: {
        // @type {{email:string, id:number, is_corporate:number, name:string, phone:string, work_types:object}}
        /**
         *  @type User
         */
        user: {},
        userState: LsUtil.getItem(LS_USER_STATE),
        ratedUser: {},
        token: LsUtil.getItem(LS_REFRESH_TOKEN),
        lang: LsUtil.getItem(LS_USER_LANG),
        assets: '',
        previousToken: LsUtil.getItem(LS_PREVIOUS_TOKEN),
        notMeState: LsUtil.getItem(NOT_ME),
        mail: LsUtil.getItem(LS_MAIL_OK),
        error: null,
        socials: null,
        userSearch: [],
        rates: [],
        userInvite: [],
        userInviteLink: '',
        autoIncrement: 1,
        communities: [],
    },
    actions: {
        async getUserData({ commit }, data = null) {
            try {
                const response = await AuthService.getUserData(data);
                if (data) {
                    commit('SET_RATED_USER', response.data.data)
                } else {
                    commit('SET_USER', response.data.data)
                    commit('SET_MAIL', response.data.data.isVerified)

                }
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getUserSearch({ commit }, data) {
            try {
                const response = await AuthService.getUserSearch(data);
                commit('SET_USER_SEARCH', response.data.data)
            } catch (e) {
                throw e;
            }
        },
        async getRates({ commit }, data) {
            try {
                const response = await AuthService.getRates(data);
                commit('SET_RATES', response.data)
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async postMultiRate({ commit }, data) {
            try {
                await AuthService.postMultiRate(data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getUserSearchEmail({ commit }, data) {
            try {
                const response = await AuthService.getUserSearch(data, false);
                commit('SET_USER_SEARCH', response.data.data)
            } catch (e) {
                throw e;
            }
        },
        async postUserInvite({ commit, getters }, data) {
            try {
                const response = await AuthService.postUserInvite(data);
                console.log(response.data);
                commit('SET_USER_INVITE', response.data);
                commit('SET_USER_INVITE_LINK', response.data.link)
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async signIn({ commit }, data) {
            commit('SET_ERROR', null);
            try {
                const response = await AuthService.auth(data);
                LsUtil.setItem(LS_REFRESH_TOKEN, response.data.token)
                commit('SET_AUTH', response.data.token)
                commit('SET_USERSTATE', 'customer')
            } catch (e) {
                throw e;
            }
        },
        async signInImpersonate({ commit }, data) {
            commit('SET_ERROR', null);
            try {
                commit('SET_IMP_AUTH', data)
                commit('SET_NOT_ME', true)
            } catch (e) {
                throw e;
            }
        },
        async signOutImpersonate({ commit }) {
            commit('SET_ERROR', null);
            try {
                commit('SET_IMP_OUT')
                commit('SET_NOT_ME', false)
            } catch (e) {
                throw e;
            }
        },
        async signOut({ commit }) {
            LsUtil.removeItem(LS_REFRESH_TOKEN)
            commit('SET_AUTH', null)
            commit('SET_USER', {})
            commit('SET_USERSTATE', null)
            router.replace({ name: 'StartPage' })
        },
        async forgotPassword({ commit }, data) {
            commit('SET_ERROR', null)
            try {
                const response = await AuthService.forgotPassword(data);
            } catch (e) {
                commit('SET_ERROR', errorFormat(e.response.data))
            }
        },
        async resetPassword({ commit }, data) {
            commit('SET_ERROR', null)
            try {
                const response = await AuthService.resetPassword(data);
            } catch (e) {
                commit('SET_ERROR', e.response.data)
            }
        },
        async social({ commit }, data) {
            try {
                const response = await AuthService.social(data);
                commit('SET_SOCIALS', response.data.data)
            } catch (e) { }
        },
        async communities({ commit }, data) {
            try {
                const response = await AuthService.communities(data);
                commit('SET_COMMUNITIES', response.data.data)
            } catch (e) { }
        },
    },
    mutations: {
        SET_AUTH(state, value) {
            state.token = value
        },
        SET_IMP_AUTH(state, value) {
            if (!state.notMeState) {
                state.previousToken = state.token
                LsUtil.setItem(LS_PREVIOUS_TOKEN, state.token)
            }
            state.token = value
            LsUtil.setItem(LS_REFRESH_TOKEN, value)
        },
        SET_IMP_OUT(state) {
            state.token = state.previousToken
            LsUtil.setItem(LS_REFRESH_TOKEN, state.token)
            LsUtil.removeItem(LS_PREVIOUS_TOKEN)
        },
        SET_NOT_ME(state, value) {
            state.notMeState = value
            LsUtil.setItem(NOT_ME, value)
        },
        SET_RATES(state, value) {
            state.rates = value
        },
        SET_USERSTATE(state, value) {
            state.userState = value
            LsUtil.setItem(LS_USER_STATE, value)
        },
        SET_MAIL(state, value) {
            state.mail = value
            LsUtil.setItem(LS_MAIL_OK, value ? 1 : 0)
        },
        SET_USER(state, value) {
            state.user = value
        },
        SET_RATED_USER(state, value) {
            state.ratedUser = value
        },
        SET_ERROR(state, value) {
            state.error = value
        },
        SET_SOCIALS(state, value) {
            state.socials = value
        },
        SET_USER_SEARCH(state, value) {
            state.userSearch = value
        },
        SET_USER_INVITE(state, value) {
            state.userInvite = value;
        },
        SET_USER_INVITE_LINK(state, value) {
            state.userInviteLink = value;
        },
        ADD_AUTO_INCREMENT(state) {
            state.autoIncrement++;
        },
        SET_LANG(state, value) {
            state.lang = value;
            LsUtil.setItem(LS_USER_LANG, value)
        },
        SET_ASSETS(state, value) {
            state.assets = value;
        },
        SET_COMMUNITIES(state, value) {
            state.communities = value;
        },
    },
    getters: {
        isUserState(state) {
            if (state.userState === null) {
                return 'customer'
            } else return state.userState
        },
        isAuth(state) {
            return !!state.token
        },
        isMail(state) {
            return state.mail
        },
        rates(state) {
            return state.rates
        },
        getUserState(state) {
            return state.userState
        },
        getNotMeState(state) {
            return state.notMeState
        },
        lang(state) {
            if (state.lang == null)
                return 'ru';
            return state.assets
        },
        assets(state) {
            return 'https://' + config.VUE_APP_ASSETS_BUCKET + '.storage.yandexcloud.net/' + config.VUE_APP_ASSETS_DIR + '/'
        },
        user(state) {
            return state.user;
        },
        ratedUser(state) {
            return state.ratedUser;
        },
        userSearch(state) {
            return state.userSearch;
        },
        userInvite(state) {
            return state.userInvite;
        },
        userInviteLink(state) {
            return state.userInviteLink;
        },
        error(state) {
            return state.error
        },
        autoIncrement: (state) => {
            return state.autoIncrement
        },
        /**
         * Ссылки для соц сетей
         * @param state
         * @returns {{name: string, link: string, icon: string, callbackLink: string}[]|null}
         */
        socials(state) {
            return state.socials
        },
        communities(state) {
            return state.communities
        }
    },
}