<template>
  <div class="container">
    <div :class="{ active: customer }">Заказчик</div>
    <div>
      <span
        class="toggle-wrapper"
        role="checkbox"
        :aria-checked="customer.toString()"
        tabindex="0"
        @click="toggle"
      >
        <span class="toggle-background" :class="backgroundStyles" />
        <span class="toggle-indicator" :style="indicatorStyles" />
      </span>
    </div>
    <div :class="{ active: !customer }">Эксперт</div>
  </div>

  <customer v-if="!customer"></customer>
  <expert v-else></expert>
  <alert-modal ref="alert"> Вернуться на главную </alert-modal>
</template>

<script>
import AlertModal from "@/components/modals/AlertModal";
import Customer from "@/views/startpage/Customer";
import Expert from "@/views/startpage/Expert";

import LsUtil from "@/utils/ls";
import { LS_REFRESH_TOKEN, LS_SOCIAL_URL, LS_SOC_REG } from "@/utils/ls/keys";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "StartPage",
  data() {
    return {
      customer: true,
    };
  },
  components: { AlertModal, Customer, Expert },
  computed: {
    ...mapGetters({
      isAuth: "auth/isAuth",
      userState: "auth/isUserState",
      user: "auth/user",
    }),
    backgroundStyles() {
      return {
        "state-on": this.customer,
        "state-off": !this.customer,
      };
    },
    indicatorStyles() {
      return {
        transform: this.customer ? "translateX(29px)" : "translateX(3px)",
        "background-color": this.customer ? "#4b4c57" : "#ffffff",
      };
    },
  },
  watch: {
    customer(val) {
      val ? this.setExecutor() : this.setCustomer();
      console.log(this.userState);
      val
        ? document.documentElement.style.setProperty("--main-color", "#FFF")
        : document.documentElement.style.setProperty("--main-color", "#EEE");
    },
  },
  unmounted() {
    document.documentElement.style.setProperty("--main-color", "#EEE");
  },
  mounted() {
    if (!this.userState) this.$store.commit("auth/SET_USERSTATE", "customer");
    this.customer = this.userState != "customer";
    this.customer
      ? document.documentElement.style.setProperty("--main-color", "#FFF")
      : document.documentElement.style.setProperty("--main-color", "#EEE");
  },
  created() {
    if (this.$route.query.code != null && this.isAuth === false) {
      let url = LsUtil.getItem(LS_SOCIAL_URL);
      let props =
        "?" +
        Object.keys(this.$route.query)
          .map((key) => {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(this.$route.query[key])
            );
          })
          .join("&");

      this.apiAuth(url + props);
    }

    if (this.$route.hash != null) {
      this.$router.push({ hash: this.$route.hash });
    }
  },

  methods: {
    ...mapMutations({
      setAuth: "auth/SET_AUTH",
    }),
    setCustomer() {
      this.$store.commit("auth/SET_USERSTATE", "customer");
    },
    setExecutor() {
      this.$store.commit("auth/SET_USERSTATE", "executor");
    },
    toggle() {
      this.customer = !this.customer;
    },
    async apiAuth(url) {
      try {
        let data = await axios.get(url);
        this.setAuth(data.data.token);
        LsUtil.setItem(LS_REFRESH_TOKEN, data.data.token);
        await this.$store.dispatch("auth/getUserData");
        if (data.data.status === "register") {
          LsUtil.setItem(
            LS_SOC_REG,
            JSON.stringify({ ...this.user, ...{ token: data.data.token } })
          );
          this.$store.reset();
          //this.$store.dispatch("auth/signOut");
          this.$router.push({ name: "RegistrationPage" });
        }
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e.response.data.error, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.container {
  @include desktop_standart;
  max-width: 1440px;
  width: 100%;
  margin: 60px auto 0px;
  padding: 0 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  @include md {
    margin: 0px auto 0px;
    padding: 30px;
    justify-content: center;
  }
}
.active {
  color: $text-gray;
}
.state-on {
  background-color: #ffffff;
}

.state-off {
  background-color: #4b4c57;
}

.toggle-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  border: 2px solid #eee;
  width: 60px;
  height: 34px;

  border-radius: 30px;
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-background {
  display: inline-block;
  border-radius: 9999px;
  height: 100%;
  width: 100%;
  transition: background-color 0.4s ease;
}

.toggle-indicator {
  position: absolute;
  height: 26px;
  width: 26px;
  left: 0px;
  bottom: 2px;
  background-color: 0.4s ease;
  border-radius: 25px;
  transition: transform 0.4s ease;
}
</style>
