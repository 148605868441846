<template>
  <div class="blocks">
    <points-card
      v-for="(item, idx) in points"
      :key="idx"
      :task="item.task"
      :solution="item.solution"
      :img="item.img"
      :is-big="isBig"
    ></points-card>
  </div>
</template>
<script>
import PointsCard from "@/views/startpage/cards/PointsCard";
export default {
  name: "PointsBlock",
  components: { PointsCard },
  props: {
    points: {
      type: Array,
      default: [],
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.blocks {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin-bottom: 20px;

  @include md {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;
  }

  @include sm {
    flex-direction: column;
    justify-content: flex-start;
  }
}
</style>
