<template>
  <div
    v-if="isOpen"
    class="modal-wrapper"
    :class="{ 'modal-wrapper-full': isFullWidth }"
    :style="cssProps"
  >
    <div class="modal-overlay" @click="closeModal"></div>
    <div ref="modal" class="dialog-content__group">
      <div class="dialog-content" :class="classes">
        <span v-if="!isPermanent" @click="closeModal" class="dialog-content__close-btn">
          <svg-icon name="close" :height="16" :width="16" />
        </span>
        <slot />
      </div>
      <div v-if="$slots.body1" class="dialog-content" :class="classes">
        <slot name="body1" />
      </div>
      <div v-if="$slots.btn" class="dialog-content" :class="classes">
        <slot name="btn" />
      </div>
      <template v-if="isFullWidth">
        <br />
      </template>
    </div>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon";
import BaseButton from "./BaseButton";

export default {
  name: "BaseModal",
  components: {
    SvgIcon,
    BaseButton,
  },
  props: {
    /** Сделать модальное окно большого размера */
    big: Boolean,
    size: {
      type: [String, Number],
      default: 550,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isPermanent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFullWidth: false,
    };
  },
  watch: {
    isOpen(currentVal, nextVal) {
      const body = document.querySelector("html");
      this.$nextTick(() => {
        this.fullWidth();
      });
      if (currentVal) {
        body.classList.add("no-scroll");
      } else {
        let count = document.getElementsByClassName("modal-wrapper").length;
        if (count <= 1) body.classList.remove("no-scroll");
      }
    },
  },
  computed: {
    cssProps() {
      return {
        "--base-modal-size": this.size + "px",
      };
    },
    classes() {
      return {
        // 'dialog--default': this.hasBg,
        "dialog-content--big": this.big,
      };
    },
    hasBg() {
      return !this.text && !this.outlined;
    },
  },
  methods: {
    updateSize() {
      this.$nextTick(() => {
        this.fullWidth();
      });
    },
    fullWidth() {
      if (this.$refs.modal !== null) {
        this.isFullWidth = false;
        this.$nextTick(() => {
          let modalHeight = this.$refs.modal.clientHeight,
            windowHeight = window.innerHeight;
          this.isFullWidth = modalHeight + 50 > windowHeight;
        });
      }
    },
    closeModal() {
      if (this.isPermanent) return;
      const body = document.querySelector("html");
      body.classList.remove("no-scroll");
      this.$emit("close");
    },
  },
  mounted() {
    window.onpopstate = function (event) {
      const body = document.querySelector("html");
      body.classList.remove("no-scroll");
    };
  },
  unmounted() {
    const body = document.querySelector("html");
    body.classList.remove("no-scroll");
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.modal-wrapper {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  max-height: 100vh;
  min-height: 100vh;
  align-content: center;
  justify-content: space-around;
  overflow-x: auto;
}
.modal-wrapper-full {
  display: flex;
}
.modal-wrapper-full .dialog-content__group {
  padding-top: 20px;

  @include md {
    margin-top: 40px;
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.4);
  @include sm {
    z-index: -1;
  }
}

.dialog-content {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    & {
      padding: 30px;
      margin: 0;
    }
  }

  position: relative;
  background-color: $white;
  padding: 30px;
  margin: 12px;
  border-radius: 24px;
  min-width: 350px;
  width: var(--base-modal-size);
  max-width: var(--base-modal-size);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  @include sm {
    width: auto;
    margin: 0 12px;
    padding: 30px 20px;
  }

  &__group {
    display: grid;
    gap: 6px;
  }
  &--big {
    max-width: none;
    min-width: auto;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      & {
        max-width: 1280px;
        min-width: 840px;
      }
    }
  }

  &__close-btn {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      & {
        right: -56px;
        top: 0;
      }
    }
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    top: -56px;
    right: 0;
    z-index: 10;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
