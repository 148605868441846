<template>
  <div class="response-item">
    <span class="response-item__remove" v-if="isDel">
      <svg-icon name="profile-tribe-dell" :width="16" :height="16" @click.stop="delItem" />
    </span>
    <div class="response-item__title">
      <p class="response-item__new" v-if="isUnseen">●</p>
      <p>{{ !short ? title : specialTitle }}</p>
    </div>
    <p class="response-item__description">{{ description }}</p>
    <template v-if="!short">
      <div class="response-item__meta" v-if="!progress">
        <dev v-if="!hideBudget">
          <p class="response-item__price">
            {{ budget.toLocaleString() }} <span class="ruble">е</span>
          </p>
        </dev>
        <p v-if="labelText" class="response-item__status">
          {{ labelText }}
        </p>
      </div>
    </template>

    <slot name="btn" />
  </div>
</template>

<script>
import ProgressBar from "@/components/ui/ProgressBar";
import SvgIcon from "@/components/base/SvgIcon";
import { declOfNum } from "@/utils/helper";

export default {
  name: "ResponseItem",
  components: { SvgIcon, ProgressBar },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    budget: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    isFinished: {
      type: Boolean,
      default: false,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
    days: {
      type: Number,
      default: null,
    },
    daysStart: {
      type: Number,
      default: null,
    },
    isDel: {
      type: Boolean,
      default: null,
    },
    short: {
      type: Boolean,
      default: false,
    },
    hideBudget: {
      type: Boolean,
      default: false,
    },
    isUnseen: {
      type: Boolean,
      default: false,
    },
    titleComposition: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    labelText() {
      if (this.label) return this.label;
      //if (this.isFinished & !this.isExpired) return "Проект завершен";
      if (this.isExpired) return "Срок поиска истёк";
      return "";
    },
    daysString() {
      if (this.days) {
        return `${this.days} ${declOfNum(this.days, [
          "день",
          "дня",
          "дней",
        ])} до конца поиска`;
      } else {
        return "";
      }
    },
    progressWidth() {
      if (this.status === "active") {
        return 100;
      }
      if (this.status === "moderation") {
        return 0;
      }
      return this.days > 0 && this.days && this.daysStart
        ? Math.round((1 - this.days / this.daysStart) * 100)
        : 100;
    },
    statusString() {
      switch (this.status) {
        case "active":
          return "";
        case "moderation":
          return "На модерации";
        default:
          return "";
      }
    },
    specialTitle() {
      let first = "";
      switch (this.titleComposition.type) {
        case "tribe":
          first = "Оценка специализации";
          break;
        case "expertise":
          first = "Оценка компетенции";
          break;
        case "service":
          first = "Оценка услуги";
          break;
        default:
          first = "Оценка услуги";
          break;
      }

      return `${first ? first + " -" : ""} ${this.titleComposition?.name} ${
        this.titleComposition?.lastName
      }`;
    },
  },
  methods: {
    delItem() {
      this.$emit("delete");
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.response-item {
  position: relative;
  padding: 20px;
  border: 1px solid $line-light-gray;
  border-radius: 12px;

  &:hover {
    border-color: $text-gray;
  }

  &__title {
    @include desktop_h2;
    display: flex;
    align-items: baseline;
    margin-bottom: 8px;
    word-break: break-word;
    max-width: 90%;
  }

  &__new {
    color: $green;
    margin-right: 4px;
  }

  &__description {
    @include desktop_additional;
    color: $text-gray;
  }

  &__remove {
    position: absolute;
    right: 18px;
    top: 17px;
    cursor: pointer;
  }

  &.special {
    .response-item {
      &__meta {
        flex-wrap: wrap;
        margin-top: 20px;

        &-block {
          display: flex;
          align-items: center;

          @include sm {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
          }
        }
      }
    }
  }

  &__meta {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 24px;
    gap: 10px;

    @include sm {
      margin-top: 20px;
    }

    &-block {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__price {
    @include desktop_h2;
  }

  &__status {
    @include desktop_additional;
    color: $text-gray;
    // margin-left: 16px;
  }
}

.progress-bar {
  margin-bottom: 10px;
  width: 100%;

  &__line {
    display: block;
    height: 4px;
    background-color: #fdc04b;
    transition: 0.5s;

    &.status {
      background-color: $green;
    }

    &-wrapper {
      background-color: $line-light-gray;
      width: 100%;
    }
  }
}
</style>
