<template>
  <container>
    <template #body>
      123
    </template>
  </container>
</template>

<script>
import Container from "@/components/layout/Container";
export default {
  name: "ProfileProfilePage",
  components: {Container}
}
</script>

<style scoped>

</style>
