<template>
  <container>
    <template v-slot:header>
      <router-link :to="`/complete-project/${this.$route.params.id}`" class="back-button">
        <svg-icon name="back-btn" :height="24" :width="24" />
      </router-link>
      <h1 class="project-name">
        {{ projectData?.demandInfo?.name }}
      </h1>
    </template>
    <template #nav>
      <div class="info">
        <svg-icon name="crown" width="36" height="36" />
        <p class="info__text">
          Оцени услугу в целом и если хочешь поставь оценки исполнителям
        </p>
      </div>
      <base-button @click="completeRating"> Завершить оценку </base-button>
    </template>
    <template #body>
      <div v-if="activeService" class="team">
        <h1 v-if="!isExpert" class="team__title">Оценка услуги и команды</h1>
        <h1 v-else class="team__title">Оценка услуги и исполнителя</h1>
        <p v-if="!isExpert" class="team__subtitle">
          Оцените услугу в целом и участников команды, с которыми удалось поработать
        </p>
        <p v-else class="team__subtitle">Оцените исполнителя и услугу в целом</p>
        <service-task
          class="team__service-rate mb-24"
          :rating="activeService.rating"
          :industry="activeService.name"
          :like="!activeService.is_rated ? 'Оцените услугу в целом' : ''"
          :user-counter="0"
          hover
          same-on-mobile
          @click="openRateService"
        />
        <div class="team__members-wrapper">
          <div
            class="team__member"
            :class="{ team__member_full: isExpert }"
            v-for="member of activeService.users"
            :key="member.id + member.name"
            @click="openRateMember(member)"
          >
            <avatar
              :fullname="member.name"
              :image="member.photo ? getPhoto(member.photo) : ''"
              :is-checked="isMemberRated(member)"
              size="70"
              class="complete-project__content__head__avatar"
            />
            <div>
              <h2>{{ member.name }}</h2>
              <p class="team__member__desc">{{ member?.company?.name }}</p>
            </div>
          </div>
        </div>
        <base-button
          class="complete-button_mobile"
          :disabled="!isRequestRated"
          @click="completeRating"
        >
          Завершить оценку
        </base-button>
        <rate-service-modal
          :title="activeService.name"
          :is-open="isRateServiceOpen"
          :is-rated="activeService.is_rated"
          :props-rate="activeService.rating"
          :props-comment="activeService.rating_comment"
          @close="closeRateService"
          @submit="rateService"
          @delete="deleteServiceRating"
        />
        <rate-member-modal
          :is-open="isRateMemberOpen"
          :props-expertises="activeMember?.expertises"
          :is-rated="isMemberRated(activeMember)"
          :name="activeMember?.name"
          :image="activeMember?.photo ? getPhoto(activeMember.photo) : ''"
          @close="closeRateMember"
          @submit="(rated, comment) => rateUser(rated, comment, activeMember?.id)"
          @delete="deleteUserRating"
        />
      </div>
    </template>
  </container>
  <alert-modal ref="alert"></alert-modal>
</template>

<script>
import Container from "@/components/layout/Container";
import SvgIcon from "@/components/base/SvgIcon";
import BaseButton from "@/components/base/BaseButton";
import ServiceTask from "@/components/ui/ServiceTask";
import RateServiceModal from "@/components/modals/RateServiceModal";
import RateMemberModal from "@/components/modals/RateMemberModal";
import Avatar from "@/components/ui/Avatar";
import AlertModal from "@/components/modals/AlertModal";
import { mapGetters } from "vuex";

export default {
  name: "CompleteProjectRatePage",
  components: {
    Container,
    SvgIcon,
    BaseButton,
    ServiceTask,
    RateServiceModal,
    RateMemberModal,
    Avatar,
    AlertModal,
  },
  data() {
    return {
      isRateServiceOpen: false,
      isRateMemberOpen: false,
      activeService: null,
      activeMember: null,
      isRequestRated: false,
    };
  },
  computed: {
    ...mapGetters({
      projectData: "request/requestFinishingData",
    }),
    isExpert() {
      return this.activeService?.users?.length <= 1;
    },
  },
  created() {
    this.getProjectData();
  },
  watch: {
    projectData(val) {
      if (Object.keys(val)?.length) {
        let activeService;

        for (let respond of val.responds) {
          let isCorrectService = false;

          for (let service of respond.services) {
            if (service.id == this.$route.params.serviceId) {
              activeService = service;
              isCorrectService = true;
              break;
            }
          }

          if (isCorrectService) {
            break;
          }
        }

        if (activeService) {
          this.activeService = activeService;
        } else {
          this.showError("Проверьте ссылку и перезагрузите страницу");
        }
      }
    },
    activeService(val) {
      if (val?.is_rated) {
        this.isRequestRated = true;
      }
    },
  },
  methods: {
    closeRateService() {
      this.isRateServiceOpen = false;
    },
    closeRateMember() {
      this.isRateMemberOpen = false;
    },
    openRateService() {
      this.isRateServiceOpen = true;
    },
    openRateMember(val) {
      this.activeMember = null;
      this.activeMember = val;
      this.isRateMemberOpen = true;
    },
    async getProjectData() {
      await this.$store.dispatch(
        "request/getRequestFinishingData",
        this.$route.params.id
      );
      this.$forceUpdate();
    },
    getPhoto(photo) {
      return photo;
    },
    isMemberRated(member) {
      if (member) {
        let isRated;

        for (let exprertise of member.expertises) {
          if (exprertise?.is_rated) {
            isRated = true;
            break;
          }
        }

        if (!this.isRequestRated && isRated) {
          this.isRequestRated = true;
        }

        return isRated;
      } else {
        return false;
      }
    },
    showError(err) {
      this.$refs.alert.show(this.$t("alert.error"), err, {
        type: "error",
        nameButton: "Ок",
      });
    },
    async rateService(rate, comment) {
      try {
        const formData = {
          type: "service",
          demand_id: +this.$route.params.id,
          user_id: +this.$route.params.userId,
          rating: rate,
          comment,
          service_id: +this.$route.params.serviceId,
        };

        await this.$store.dispatch("request/rateRequestServiceOrUser", formData);

        this.getProjectData();
      } catch (e) {
        this.showError(e);
      }
    },
    async deleteServiceRating() {
      try {
        await this.$store.dispatch("request/deleteRequestRating", {
          ratings: [this.activeService.rating_id],
        });
        await this.getProjectData();
      } catch (e) {
        this.showError(e);
      }
    },
    async rateUser(rated, comment, userId) {
      console.log(rated);
      try {
        const formData = {
          type: "user",
          demand_id: +this.$route.params.id,
          user_id: userId,
          comment,
          expertises: rated
            .filter((v) => v.typeValue === "expertises")
            .map((v) => ({
              id: v.id,
              rating: v.rating,
            })),
          tribes: rated
            .filter((v) => v.typeValue === "tribes")
            .map((v) => ({
              industry_id: v.industry_id,
              option_id: v.option_id,
              rating: v.rating,
            })),
        };
        await this.$store.dispatch("request/rateRequestServiceOrUser", formData);
        await this.getProjectData();
        this.closeRateMember();
      } catch (e) {
        this.showError(e);
      }
    },
    async deleteUserRating(ratingIdArray) {
      try {
        await this.$store.dispatch("request/deleteRequestRating", {
          ratings: ratingIdArray
            .filter((item) => typeof item !== "undefined")
            .map((v) => v.rating_id),
        });
        await this.getProjectData();
        this.closeRateMember();
      } catch (e) {
        this.showError(e);
      }
    },
    completeRating() {
      if (this.isRequestRated) {
        this.$router.push({
          path: `/complete-project/${this.$route.params.id}/respond/${this.$route.params.respondId}`,
        });
      } else {
        this.$router.push({ path: `/complete-project/${this.$route.params.id}` });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.project-name {
  font-size: 32px !important;

  @include sm {
    text-align: left;
    padding-left: 8px;
  }
}

.back-button {
  background-color: $white;
  border-radius: 7px;
  width: 56px;
  height: 56px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.info {
  width: 100%;
  height: max-content;
  border: 1px solid $line-light-gray;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 40px;

  &__text {
    color: $accent;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.006em;
  }
}

.team {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 32px;
    margin-bottom: 12px;
  }

  &__subtitle {
    color: $text-gray;
    margin-bottom: 30px;
    max-width: 530px;
  }

  &__service-rate {
    cursor: pointer;
  }

  &__members-wrapper {
    width: 100%;
    height: max-content;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }

  &__member {
    width: 328px;
    height: max-content;
    padding: 18px 30px 18px 20px;
    display: flex;
    gap: 21px;
    border: 1px solid $line-light-gray;
    border-radius: 12px;
    align-items: center;
    cursor: pointer;

    &_full {
      width: 100%;
    }

    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 8px;
    }

    &__desc {
      color: $text-gray;
      font-weight: 400;
      font-size: 16px;
      line-height: 124%;
    }

    &__avatar {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }

    &:hover {
      border-color: $text-gray;
    }

    @include sm {
      width: 100%;
    }
  }
}

.complete-button_mobile {
  display: none;
  margin-top: 40px;

  @include md {
    display: block;
  }
}
</style>
