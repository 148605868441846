<template>
  <base-modal :is-open="isOpen" @close="closeModal" size="550">
    <div class="desired-clients">
      <h2 class="desired-clients__header">{{ header }}</h2>
      <div v-if="subtitle !== ''" class="subtitle">
        <span> {{ subtitle }}</span>
      </div>
      <div class="body">
        <base-input
            placeholder="Найти компанию"
            class="desired-clients__input"
            v-model="searchText"
            search
        />
        <div v-if="newValue" class="item-add-custom">
          <p>Таких компаний не найдено. <span>Добавить новую?</span></p>
        </div>
        <div class="group">
          <perfect-scrollbar>
            <div class="list">
              <checkbox
                  text
                  full
                  class="item-dialog"
                  v-for="(option, idx) in matchingOptions" :key="option.autoIncrement"
                  :model-value="option.value"
                  @update:model-value="(v) => select(v, option.autoIncrement)"
                  :columnReverse="true"
              >
                <slot name="option" v-bind="{option,idx}">
                  {{ getOptionDescription(option) }}
                </slot>
                <template v-slot:postfix v-if="option.status !== 'active'">
                  <span class="item-dialog__postfix">На модерации</span>
                </template>
              </checkbox>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
      <base-button :disabled="isSelect()" @click="submit">{{ newValue ? 'Добавить' : 'Сохранить' }}</base-button>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseInput from "@/components/base/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import VueMultiSelectRows from "@/components/ui/VueMultiSelectRows";
import Checkbox from "@/components/base/VueTelInput/Checkbox";

export default {
  name: "DesiredClientsModal",
  components: {
    Checkbox,
    BaseButton,
    BaseInput,
    BaseModal,
    VueMultiSelectRows
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Дополнительный контент, отображаемый под заголовком
     */
    subtitle: {
      type: String,
      default: ''
    },
    /**
     * Текст заголовка
     */
    header: {
      type: String,
      default: ''
    },
    /**
     * Текст заголовка
     */
    placeholder: {
      type: String,
      default: ''
    },
    getOptionDescription: {
      type: Function,
      default(option) {
        return option['name'];
      }
    },

  },
  data() {
    return {
      autoIncrement: 1,
      nameButton: '',
      verificationCode: '',
      searchText: '',
    }
  },
  methods: {
    select(v, index) {
      let model = this.model;
      let findIndex = model.findIndex(v => v.autoIncrement === index)
      if (findIndex !== -1) {
        model[findIndex].value = v;
        this.model = model;
      }
    },
    isSelect() {
      if (this.newValue)
        return false;
      if (this.model != null)
        return this.model
            .filter(v => v.value === true).length <= 0
    },
    submit() {
      if (this.newValue) {
        let model = JSON.parse(JSON.stringify(this.model));
        model.push({
          name:this.searchText,
          autoIncrement: this.autoIncrement++,
          value: false,
          newValue: true,
        })
        this.model = model;
        console.log(this.model)
      } else {
        if (this.isSelect())
          return false;
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit('close')
      this.searchText = '';
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue.map(v => ({
          ...v,
          ...{autoIncrement: this.autoIncrement++,}
        }));
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    matchingOptions() {
      if (this.model === null)
        return [];
      return this.model
        .filter(option =>
          option['name']
            .toString()
            .toLowerCase()
            .includes(this.searchText.trim().toString().toLowerCase())
        );
    },
    newValue() {
      if (this.searchText === null)
        return false;
      if (this.searchText === "")
        return false;
      if (this.matchingOptions == null)
        return false;
      return this.matchingOptions.filter(v => v.name.toLowerCase() === this.searchText.trim().toLowerCase()).length <= 0;
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
.desired-clients {
  &__header {
    @include desktop_h1;
    text-align: left;
  }
  &__input {
    margin-bottom: 24px;
  }
}
.item-dialog {
  width: 100%;
  &__postfix {
    color: $text-gray;
    margin-right: 16px;
  }
}
.item-add-custom {
  margin-top: -14px;
  color: $text-gray;
}
.group {
  margin: 24px -18px 16px -18px;
  padding-left: 18px;
  .ps {
    max-height: 40vh;
  }
  .list {
    row-gap: 14px;
    display: grid;
    padding-right: 18px;
  }
}

.body {
  margin-top: 24px;
  margin-bottom: 16px;
  .subtitle {
    @include desktop_additional;
    margin-top: 12px;
  }
  &__nav {
    margin-bottom: 30px;
  }
  &__body {
    margin-bottom: 30px;
  }
  &__item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
