import { contactService } from "@/services";

export default {
    namespaced: true,
    state: {
        error: null,
        isLoad: false,
        footer: null,
    },
    actions: {
        async getFooter({ commit }) {
            try {
                const data = await contactService.getFooter();
                commit('SET_FOOTER', data.data.data);
            } catch (e) {
                console.log(e)
            }
        },
        async isLoading({ commit }, data) {
            try {
                commit('SET_LOAD', data)
            } catch (e) {
                throw e;
            }
        },
    },
    mutations: {
        SET_ERROR(state, value) {
            state.error = value
        },
        SET_LOAD(state, value) {
            state.isLoad = value
        },
        SET_FOOTER(state, value) {
            state.footer = value
        },
    },
    getters: {
        isLoad(state) {
            return state.isLoad
        },
        error(state) {
            return state.error
        },
        footer(state) {
            return state.footer
        },
    },
}