import { contactRequest } from "@/services";

export default {
    namespaced: true,
    state: {
        error: null,
    },
    actions: {
        async postContactRequest({ commit }, data) {
            try {
                const response = await contactRequest.sendContactRequest(data);
            } catch (e) {
                if (e.response != null)
                    throw errorFormat(e.response.data);
                console.warn(e);
            }
        },
    },
    mutations: {
        SET_ERROR(state, value) {
            state.error = value
        },
    },
    getters: {
        error(state) {
            return state.error
        },
    },
}