<template>
  <div class="projects-request">
    <container :full-body="isFullBody" :no-nav="isFullBody">
      <template #nav>
        <v-tabs direction="vertical" v-model="tabs">
          <v-tab value="search" href="#search" @click="updateTime('customer_search')"
            >Поиск<base-signal-chip
              v-if="responseStatus.customer.search?.type"
              :title="responseStatus.customer.search.title"
              :type="responseStatus.customer.search.type"
          /></v-tab>
          <v-tab value="active" href="#active" @click="updateTime('customer_active')"
            >Активные<base-signal-chip
              v-if="responseStatus.customer.active?.type"
              :title="responseStatus.customer.active.title"
              :type="responseStatus.customer.active.type"
          /></v-tab>
          <v-tab
            value="completed"
            href="#completed"
            @click="updateTime('customer_completed')"
            >Завершенные<base-signal-chip
              v-if="responseStatus.customer.completed?.type"
              :title="responseStatus.customer.completed.title"
              :type="responseStatus.customer.completed.type"
          /></v-tab>
          <v-tab value="expired" href="#expired" @click="updateTime('customer_expired')"
            >Неактивные<base-signal-chip
              v-if="responseStatus.customer.expired?.type"
              :title="responseStatus.customer.expired.title"
              :type="responseStatus.customer.expired.type"
          /></v-tab>
        </v-tabs>
      </template>
      <template #btn>
        <base-button
          class="project-request__create-btn"
          :class="{ 'project-request__create-btn_mobile': isFullBody }"
          href="/request-add"
          >Создать заявку</base-button
        >
        <!-- <info-block-small class="info-block-small"></info-block-small>-->
      </template>
      <template #body>
        <v-item-group v-model="tabs" class="text-center scrolling-wrapper">
          <v-item
            v-for="n in tabsList"
            :key="`btn-${n}`"
            v-slot="{ isSelected, toggle }"
            :value="n.value"
          >
            <div
              :class="isSelected ? 'active-chip-mobile' : 'chip-mobile'"
              @click="updateTime('customer_' + n.value, toggle, isSelected)"
            >
              {{ n.title }}
            </div>
            <base-signal-chip
              class="mobile-chip"
              v-if="getMenuChip(n.value).type"
              :title="getMenuChip(n.value).title"
              :type="getMenuChip(n.value).type"
            />
          </v-item>
        </v-item-group>
        <template v-if="!isFullBody">
          <div>
            <v-window v-model="tabs">
              <v-window-item value="search">
                <div v-if="isSearching.length">
                  <h2 class="block-header">Поиск</h2>
                  <div class="projects-response__list">
                    <response-item
                      v-for="item in paginatedSlice(isSearching, 'search')"
                      :key="`response_${item.status}_${item.id}`"
                      class="special pointer"
                      :title="item.name"
                      :description="item.service_type"
                      :budget="item.budget"
                      :status="item.status"
                      :days="daysEnd(item.search_finish)"
                      :days-start="daysStart(item.created_at, item.search_finish)"
                      progress
                      is-del
                      @click.prevent="goRequest(item)"
                      @delete="tryDeleteItem(item)"
                    >
                      <template #btn>
                        <div
                          class="response-item__control-double"
                          v-if="item.status === 'searching'"
                        >
                          <base-button light @click.stop="submitStop(item)"
                            >Приостановить поиск</base-button
                          >
                          <base-button @click="goRequest(item)">
                            <base-signal-chip
                              class="button-chip"
                              :title="getUnseen(item)"
                              v-if="getUnseen(item) > 0"
                            />
                            <base-signal-chip
                              class="button-chip singalUnseen"
                              :title="getSeen(item)"
                              v-if="getSeen(item) > 0"
                            />
                            Выбрать исполнителя</base-button
                          >
                        </div>
                        <div v-if="item.status === 'moderation'" class="inModeration">
                          На модерации
                        </div>
                      </template>
                    </response-item>
                    <v-pagination
                      v-if="isSearching.length > requestsPerPage"
                      v-model="currentPage['search']"
                      :length="Math.ceil(isSearching.length / requestsPerPage)"
                    ></v-pagination>
                  </div>
                </div>
                <not-item v-else title="У вас еще нет проектов"></not-item>
              </v-window-item>

              <v-window-item value="active">
                <div v-if="isActive.length">
                  <h2 class="block-header">Активные</h2>
                  <div class="projects-response__list">
                    <response-item
                      v-for="item in paginatedSlice(isActive, 'active')"
                      :key="`response_${item.status}_${item.id}`"
                      class="special"
                      :title="item.name"
                      :description="item.service_type"
                      :budget="item.budget"
                      :status="item.status"
                      :days="0"
                      :days-start="0"
                      is-del
                      @delete="tryDeleteItem(item)"
                    >
                      <template #btn>
                        <!--<base-button :href="`/complete-project/${item.id}`"
                      >Оценить и завершить</base-button
                    >-->
                        <base-button @click="completeProject(item.id)"
                          >Завершить</base-button
                        >
                      </template>
                    </response-item>
                    <v-pagination
                      v-if="isActive.length > requestsPerPage"
                      v-model="currentPage['active']"
                      :length="Math.ceil(isActive.length / requestsPerPage)"
                    ></v-pagination>
                  </div>
                </div>
                <not-item v-else title="У вас еще нет активных проектов"></not-item>
              </v-window-item>
              <v-window-item value="completed">
                <div v-if="isCompleted.length">
                  <h2 class="block-header">Завершенные</h2>
                  <div class="projects-response__list">
                    <div
                      v-for="(item, index) in paginatedSlice(isCompleted, 'completed')"
                      :key="`response_${item.status}_${item.id}`"
                    >
                      <response-item
                        class="special"
                        :title="item.name"
                        :description="item.service_type"
                        :budget="item.budget"
                        :status="item.status"
                        :is-expired="item.is_expired"
                        is-finished
                        v-if="index === 0 || showHidden"
                      >
                      </response-item>
                    </div>
                    <v-pagination
                      v-if="isCompleted.length > requestsPerPage"
                      v-model="currentPage['completed']"
                      :length="Math.ceil(isCompleted.length / requestsPerPage)"
                    ></v-pagination>
                  </div>
                </div>
                <not-item v-else title="У вас нет завершенных проектов"></not-item>
              </v-window-item>
              <v-window-item value="expired">
                <div v-if="isExpired.length">
                  <h2 class="block-header">Неактивные</h2>
                  <div class="projects-response__list">
                    <response-item
                      v-for="item in paginatedSlice(isExpired, 'expired')"
                      :key="`response_${item.status}_${item.id}`"
                      class="special"
                      :title="item.name"
                      :description="item.service_type"
                      :budget="item.budget"
                      :status="item.status"
                      :days="daysEnd(item.search_finish)"
                      :days-start="daysStart(item.created_at, item.search_finish)"
                      is-del
                      @delete="tryDeleteItem(item)"
                    >
                      <template #btn>
                        <div class="response-item__control-double">
                          <base-button light @click="submitExtend(item.id, 'возоблена')"
                            >Возобновить поиск</base-button
                          >
                          <base-button @click="goRequest(item)"
                            >Посмотреть отклики</base-button
                          >
                        </div>
                      </template>
                    </response-item>
                    <v-pagination
                      v-if="isExpired.length > requestsPerPage"
                      v-model="currentPage['expired']"
                      :length="Math.ceil(isExpired.length / requestsPerPage)"
                    ></v-pagination>
                  </div>
                </div>
                <not-item v-else title="У вас нет неактивных проектов"></not-item>
              </v-window-item>
            </v-window>
          </div>
        </template>
        <template v-else>
          <not-item
            title="У вас ещё нет заявок"
            description="Начните поиск первых исполнителей для решения ваших задач"
            btn-name="Заполнить заявку"
            @btnEvent="toCreateRequest"
          ></not-item>
        </template>
      </template>
    </container>

    <extend-term-modal
      :is-open="isExtendModal"
      @close="closeModal"
      @closeMess="closeModalInfo"
      :demand-id="demandId"
      :title-type="titleType"
    />
    <alert-modal ref="alert" />
    <alert-modal ref="dell">
      <template v-slot:button>
        <base-button @click="cancelRemove" class="button--light modal__btn"
          >Отмена</base-button
        >
      </template>
    </alert-modal>
  </div>
</template>

<script>
import InfoBlockSmall from "@/components/ui/InfoBlockSmall";
import Container from "@/components/layout/Container";
import SvgIcon from "@/components/base/SvgIcon";
import { mapGetters } from "vuex";
import AlertModal from "@/components/modals/AlertModal";
import ResponseItem from "@/components/component/ResponseItem";
import BaseButton from "@/components/base/BaseButton";
import ExtendTermModal from "@/components/modals/ExtendTermModal";
import NotItem from "@/components/ui/notItem";
import BaseSignalChip from "@/components/base/BaseSignalChip";
export default {
  name: "ProjectsRequestPage",
  components: {
    NotItem,
    ExtendTermModal,
    BaseButton,
    ResponseItem,
    AlertModal,
    Container,
    BaseSignalChip,
    SvgIcon,
    InfoBlockSmall,
  },
  data() {
    return {
      tabsList: [
        {
          title: "Поиск",
          value: "search",
        },
        {
          title: "Активные",
          value: "active",
        },
        {
          title: "Завершенные",
          value: "completed",
        },
        {
          title: "Неактивные",
          value: "expired",
        },
      ],
      tabs: null,
      tabsSearch: false,
      tabsActive: false,
      tabsExpired: false,
      tabsCompleted: false,
      isExtendModal: false,
      demandId: null,
      titleType: null,
      showHidden: true,
      currentPage: {
        search: 1,
        active: 1,
        completed: 1,
        expired: 1,
      },
      requestsPerPage: 3,
    };
  },

  computed: {
    ...mapGetters({
      requestList: "request/requestList",
      responseStatus: "status/responseStatus",
      visitTime: "status/visitTime",
      pageNumber: "pages/pageNumber",
    }),
    mounted() {
      this.updateTime("customer_search");
    },
    mobile() {
      return (
        this.$vuetify.display.md || this.$vuetify.display.sm || this.$vuetify.display.xs
      );
    },
    isFullBody() {
      if (
        this.requestList?.searching?.length ||
        this.requestList?.active?.length ||
        this.requestList?.completed?.length ||
        this.requestList?.search_expired?.length
      ) {
        return false;
      }

      return true;
    },
    isSearching() {
      if (this.requestList?.searching?.length) {
        return this.requestList.searching.filter(
          (item) => item.status === "searching" || item.status === "moderation"
        );
      }
      return [];
    },
    isActive() {
      if (this.requestList?.active?.length) {
        return this.requestList.active;
      }
      return [];
    },
    isExpired() {
      if (this.requestList?.search_expired?.length) {
        return this.requestList.search_expired;
      }
      return [];
    },
    isCompleted() {
      if (this.requestList?.completed?.length) {
        return this.requestList.completed;
      }
      return [];
    },
  },
  created() {
    this.currentPage.search = this.pageNumber.search;
    this.getRequestList();
    this.$route.hash == "#expired" ? (this.tabs = "expired") :
    (this.$route.hash=="#active") ? (this.tabs = "active") : (this.tabs = "search");
    // this.$route.hash == "#expired" ? (this.tabs = "expired") : (this.tabs = "search");
  },
  watch: {
    "currentPage.search"(currentVal, nextVal) {
      this.$store.dispatch("pages/setPageNumberByKey", { search: currentVal });
    },
  },
  methods: {
    async getRequestList() {
      try {
        this.$store.dispatch("content/isLoading", true);
        await this.$store.dispatch("request/getRequestList");
        this.$store.dispatch("content/isLoading", false);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async updateTime(key, toggle, isSelected) {
      if (toggle && !isSelected) toggle();
      this.$store.dispatch("status/updateVisitTimes", key);
    },
    getMenuChip(key) {
      return this.responseStatus.customer[key];
    },
    paginatedSlice(items, type) {
      let items_paginated = JSON.parse(JSON.stringify(items));
      if (items_paginated.length > this.requestsPerPage) {
        const startIndex = (this.currentPage[type] - 1) * this.requestsPerPage;
        const endIndex = startIndex + this.requestsPerPage;
        return items_paginated.slice(startIndex, endIndex);
      }
      return items_paginated;
    },
    daysEnd(date) {
      if (date) {
        return parseInt((new Date(date) - new Date()) / 1000 / 60 / 60 / 24 + 1);
      } else {
        return 0;
      }
    },
    daysStart(start, finish) {
      if (start && finish) {
        return parseInt((new Date(finish) - new Date(start)) / 1000 / 60 / 60 / 24);
      } else {
        return 14;
      }
    },
    tryDeleteItem(item) {
      this.$refs.dell.show(
        "Удалить заявку",
        `Вы уверены, что хотите удалить заявку ${item.name}?`,
        {
          type: "dell",
          nameButton: "Удалить",
          error: true,
        },
        () => {
          this.deleteItem(item.id);
        }
      );
    },
    async deleteItem(id) {
      try {
        await this.$store.dispatch("request/deleteItem", id);
        this.$refs.alert.show("Заявка удалена", "", {
          type: "success",
          nameButton: "Ок",
        });
        await this.getRequestList();
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },

    getUnseen(item) {
      let res = item.responds.filter((el) => !el.is_viewed).length;
      if (res > 0) {
        return "+" + res;
      }
      console.log("item.responds", item.responds, "res", res);
    },
    getSeen(item) {
      let res = item.responds.filter((el) => !el.is_viewed).length;
      if (res === 0) {
        return item.responds.length;
      }
    },
    selectSearch(e) {
      if (e) {
        this.tabs = "search";
      }
      this.tabsSent = e;
    },
    selectActive(e) {
      if (e && !this.tabsSent) {
        this.tabs = "active";
      }
      this.tabsActive = e;
    },
    selectExpired(e) {
      if (e) {
        this.tabs = "expired";
      }
      this.tabsSent = e;
    },
    selectCompleted(e) {
      if (e && !this.tabsActive) {
        this.tabs = "completed";
      }
      this.tabsCompleted = e;
    },
    async submitExtend(id, t) {
      const payload = {};
      payload.id = id;

      try {
        await this.$store.dispatch("request/sendRestart", payload);
        await this.$store.dispatch("request/getRequestList");
        await this.getRequestList();
        this.$refs.alert.show("Успешно!", `Поиск по заявке продлен`, {
          type: "success",
          nameButton: "Ок",
        });
      } catch (e) {
        console.log("error");
      }
    },

    async stopSearch(id) {
      try {
        await this.$store.dispatch("request/sendStop", id);
      } catch (e) {
        console.log("error");
      }
    },

    submitStop(item) {
      this.$refs.dell.show(
        "Приостановить поиск исполнителей?",
        `После приостановки поиска заявка перейдет в статус “Неактивные”. В этом статусе вы можете просмотреть уже имеющиеся отклики и возобновить поиск экспертов в любой момент.`,
        {
          type: "danger",
          nameButton: "Приостановить",
          error: true,
        },
        () => {
          this.stopSearch({ id: item.id });
          this.getRequestList();
        }
      );
    },
    goRequest(item) {
      this.$router.push(`/request-list/${item.id}`);
    },
    goProjects() {
      this.$router.push({ path: "/projects" });
    },
    completeProject(id) {
      try {
        this.$refs.alert.show(
          this.$t("alert.notification"),
          "Уверены, что хотите завершить проект?",
          {
            type: "success",
            nameButton: "Завершить",
          },
          () => {
            this.$store.dispatch("request/completeRequest", {
              demand_id: id,
            });
            this.getRequestList();
            this.$router.push({ path: "/projects" });
          }
        );
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    closeModal() {
      this.isExtendModal = false;
    },
    cancelRemove() {
      this.$refs.dell.closeModal();
    },
    async closeModalInfo(e) {
      if (e === "success") {
        this.isExtendModal = false;
        this.$refs.alert.show(this.$t("alert.notification"), "Поиск успешно продлен", {
          type: "success",
          nameButton: "Ок",
        });
        await this.getRequestList();
      } else {
        this.isExtendModal = false;
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    toCreateRequest() {
      this.$router.push("/request-add");
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.inModeration {
  color: #a0a0a0;
  text-align: right;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
}
.singalUnseen {
  background-color: #a0a0a0 !important;
  color: #4b4c57 !important;
}
.pointer {
  cursor: pointer;
}
.info-block-small {
  display: block;
  @include sm {
    display: none;
  }
}

.project-request {
  &__create-btn {
    margin-top: 40px;

    &_mobile {
      @include md {
        display: none;
      }
    }
  }
}

.projects-response {
  &__list {
    display: grid;
    gap: 24px;
  }

  &__no-list {
    @include desktop_standart;
  }

  &__block {
    margin-bottom: 60px;

    @include sm {
      margin-bottom: 48px;
    }

    &:last-child {
      margin-bottom: 0;

      @include sm {
        margin-bottom: 0;
      }
    }
  }
}

.block-header {
  @include desktop_h1;
  margin-bottom: 30px;
}

.response-item {
  &__control-double {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @include sm {
      display: flex;
      flex-direction: column;

      .button:last-child {
        margin-top: unset;
      }
    }
  }

  .button {
    margin-top: 22px;
  }
}

.hidden {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  &-btn {
    width: auto;
    height: 49px;
    transition: transform 150ms ease;

    &:focus {
      background-color: $bg-light-gray;
    }
  }

  &-btn-activate {
    transform: rotate(-180deg);
  }

  .block-header {
    margin-bottom: 0px;
  }
}

.button--light {
  &:hover {
    background-color: $bg-light-gray;
  }

  &:not(:hover) {
    background-color: transparent;
  }
}

.mobile-chip {
  max-height: 20px;
  position: relative;
  right: 20px;
  bottom: 20px;
}
.active-chip-mobile {
  display: inline-block;
  border-radius: 24px;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 19.84px */
  color: #a0a0a0 !important;
  padding: 10px 15px;
  overflow: visible !important;
  background-color: #f6f6f6;
  color: black !important;
}
.chip-mobile {
  display: inline-block;
  border-radius: 24px;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 19.84px */
  color: #a0a0a0 !important;
  padding: 10px 15px;
  overflow: visible !important;

  &:first-child {
    margin-left: 0;
  }
  &:hover {
    color: black !important;
  }
}
.scrolling-wrapper {
  display: none;
  @include md {
    width: 80vw;
    display: inline-block;
    overflow-x: scroll;
    overflow-y: auto;
    white-space: nowrap;
    flex-wrap: wrap;
    padding-top: 10px;
    gap: 10px;
    justify-content: left;
    padding-right: auto;
    padding-bottom: 20px;
    vertical-align: bottom;
  }
}

.button-chip {
  position: relative;
  bottom: 4px;
  text-align: right !important;
}
.mobile {
  display: inline-block;
  border-radius: 24px;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 19.84px */
  padding-bottom: 11px;
  color: #a0a0a0 !important;
  padding-left: 20px;
  padding-right: 30px;
  overflow: visible !important;
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    color: black !important;
  }
}
.act {
  background-color: #f6f6f6;
  color: black !important;
}
.modal__btn {
  margin-left: 16px;
}
</style>
