<template>
  <div class="service-add">
    <container>
      <template v-slot:header>
        <h1 v-if="!id">Новая услуга</h1>
        <h1 v-else>{{ service.name }}</h1>
      </template>
      <template v-slot:nav>
        <div>
          <v-tabs direction="vertical" class="service-add__navigation" v-model="tabs">
            <v-tab href="#people" value="people">Описание</v-tab>
            <template v-if="isCompany || isPeople">
              <v-tab href="#industry-function" v-if="!quickView" value="industry-function"
                >Индустрия и функция</v-tab
              >
              <template v-if="isCompany && !quickView">
                <v-tab href="#company-list" value="company-list">Команда</v-tab>
              </template>
              <v-tab
                href="#specialization-list"
                v-if="quickView && service?.industries?.length > 0"
                value="specialization-list"
                >Специализации</v-tab
              >
              <v-tab href="#expertises-list" value="expertises-list">Компетенции</v-tab>
              <template v-if="isCompany && quickView">
                <v-tab href="#company-list" value="company-list">Команда</v-tab>
              </template>
              <v-tab href="#other-options" value="other-options">Другие параметры</v-tab>
            </template>
          </v-tabs>
          <progress-bar class="mb-6" :progress="percentService()" v-if="!quickView" />
        </div>
      </template>
      <template v-slot:btn> </template>
      <template v-slot:body class="body">
        <div
          v-intersect="{
            handler: selectPeople,
            options: {
              threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            },
          }"
        >
          <h1 id="people" class="mt-0">Описание</h1>
          <div class="service-add__row" v-if="quickView">
            <div class="service-add__profile-block" v-if="service?.service_type">
              <div class="service-add__profile-block-title">Какой формат услуги?</div>
              <div class="service-add__profile-content-info">
                {{ service.service_type }}
              </div>
            </div>
            <div class="service-add__profile-block" v-if="service?.performer_type">
              <div class="service-add__profile-block-title">
                Как будете оказывать услугу?
              </div>
              <div class="service-add__profile-content-info">
                {{ performerType(service.performer_type) }}
              </div>
            </div>
          </div>
          <v-row v-if="!quickView">
            <v-col cols="12">
              <base-input
                ref="v-name"
                description="Название услуги"
                placeholder="Например, «Разработка стратегии» или «Оптимизация бизнес-процессов»"
                v-model="name"
                :class="{ error: v$.name.$error }"
                :is-error="v$.name.$error"
                :error="[
                  {
                    text: 'Название услуги обязательно для заполнения',
                    status: v$.name.required.$invalid,
                  },
                  {
                    text: 'Максимальное количество символов 100 [' + name.length + ']',
                    status: v$.name.maxLength.$invalid,
                  },
                ]"
              ></base-input>
            </v-col>
          </v-row>
          <v-row v-if="!quickView">
            <v-col md="6" cols="12">
              <base-dropdown
                ref="v-teamAndPeople"
                title="Как будете оказывать услугу?"
                placeholder="Выберите из списка"
                :model-value="teamAndPeople"
                :is-error="vCompany$.teamAndPeople.$error"
                @update:model-value="updateTeamAndPeople"
                :list="[
                  { name: 'От команды', id: 'company' },
                  { name: 'От себя', id: 'individual' },
                ]"
                :error="[
                  {
                    text: 'Тип услуги обязателен для заполнения',
                    status: vCompany$.teamAndPeople.required.$invalid,
                  },
                ]"
              ></base-dropdown>
            </v-col>
            <v-col md="6" cols="12">
              <base-dropdown
                ref="v-type"
                class="type-of-service"
                title="Какой формат услуги?"
                placeholder="Выберите вид услуги"
                v-model="type"
                :list="serviceTypeList"
                :is-error="v$.type.$error"
                :error="[
                  {
                    text: 'Вид услуги обязателен для заполнения',
                    status: v$.type.required.$invalid,
                  },
                ]"
              ></base-dropdown>
            </v-col>
          </v-row>
        </div>
        <template v-if="isCompany || isPeople">
          <template v-if="!quickView">
            <div
              v-intersect="{
                handler: selectIndustry,
                options: {
                  threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                },
              }"
            >
              <h1 id="industry-function">Для кого будет актуальна ваша услуга?</h1>
              <vue-multi-select-check-box
                ref="v-industryArray"
                class="mb-5"
                optionLabel="name"
                optionKey="id"
                placeholder="Выберите индустрии"
                description="Выберите индустрии"
                v-model="industryValue"
                :options="industriesList"
                sub-description="Можно выбрать до 10 индустрий"
                :is-error="isIndustryValue"
                :error="[
                  {
                    text: 'Превышен лимит индустрий',
                    status: isIndustryValue,
                  },
                ]"
              ></vue-multi-select-check-box>

              <base-call-modal
                header="Выберите функции"
                :title="currentSpecTitle"
                placeholder="Выберите специализацию"
                @event-click="openSpecializationModal()"
                :is-error="this.v$.optionsArray.$error"
                :error="[
                  {
                    text: `Функции обязательны для заполнения`,
                    status: this.v$.optionsArray.arrayValid.$invalid,
                  },
                ]"
              />
            </div>
          </template>

          <template v-if="isCompany && !quickView">
            <team-list
              ref="v-userList"
              id="company-list"
              v-model="userList"
              v-intersect="{
                handler: selectCommand,
                options: {
                  threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                },
              }"
              :is-error="vOtherOptions$.userList.$error"
              :error="[
                {
                  text: 'Максимальное количество сотрудников 10',
                  status: vOtherOptions$.userList.validLength.$invalid,
                },
              ]"
            />
          </template>
          <!-- </template> -->

          <div
            class="service-specialization"
            v-if="!quickView || (quickView && addSpecializationList?.length)"
          >
            <div class="service-specialization__header">
              <h2 class="service-specialization__caption" id="specialization-list">
                Какой у вас опыт в рамках данной услуги?
              </h2>
              <add-button
                :class="{ btn_disabled: !addSpecializationList?.length }"
                @click="openSpecialization"
                v-if="!quickView"
                >Добавить</add-button
              >
            </div>
            <div
              class="service-specialization__description"
              v-if="addSpecializationList?.length && !quickView"
            ></div>
            <div class="service-specialization__description" v-else></div>
            <info-box-add
              v-if="!addSpecializationList?.length"
              icon="like"
              class="temp-width btn-width"
            >
              <template v-slot:title> Укажите ваш опыт </template>
              <template v-slot:description>
                Релевантный опыт с высоким рейтингом <br />
                повысит привлекательность вашей услуги
              </template>
              <template v-slot:action>
                <base-button class="action-info" @click="openSpecialization"
                  >Добавить опыт</base-button
                >
              </template>
            </info-box-add>
            <template v-else>
              <work-block
                v-for="(item, index) in addSpecializationListGrouped"
                class="services__task"
                id="specialization-list"
                :rating="item.rating"
                :tribe="item.tribe"
                :function_name="item.function_name"
                :years="item.years"
                :roles="item.roles ? item.roles : []"
                :companies="item.companies"
                :key="`${index}_${item.tribe}`"
                :like="item.rating_count"
                small
                hidden-show
                hide-rating
              >
                <template #action>
                  <svg-icon
                    width="23"
                    @click.stop="del(item)"
                    name="service-dell"
                    v-if="!quickView"
                  ></svg-icon>
                </template>
              </work-block>
            </template>
          </div>

          <expertises-list
            v-if="!quickView || (quickView && expertisesList?.length)"
            ref="v-expertisesList"
            :user-list="userList"
            id="expertises-list"
            v-model="expertisesList"
            :quickView="quickView"
            v-intersect="{
              handler: selectCompetence,
              options: {
                threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
              },
            }"
            :is-error="vOtherOptions$.expertisesList.$error"
            :error="[
              {
                text: 'Максимальное количество компетенции 10',
                status: vOtherOptions$.expertisesList.validLength.$invalid,
              },
            ]"
          >
            <template v-slot:description>
              <p v-if="isCompany"></p>
              <p v-else-if="expertisesList?.length">
                Прикрепите компетенции из вашего профиля
              </p>
            </template>
          </expertises-list>

          <template v-if="quickView && userList.length">
            <team-list
              ref="v-userList"
              id="company-list"
              v-model="userList"
              :quickView="quickView"
              v-intersect="{
                handler: selectCommand,
                options: {
                  threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                },
              }"
              :is-error="vOtherOptions$.userList.$error"
              :error="[
                {
                  text: 'Максимальное количество сотрудников 10',
                  status: vOtherOptions$.userList.validLength.$invalid,
                },
              ]"
            />
          </template>
          <template v-if="teamAndPeople !== null">
            <template v-if="quickView">
              <h1 id="other-options">Другие параметры</h1>
              <div v-if="!isCompany">
                <div class="service-add__row" v-if="quickView">
                  <div class="service-add__profile-block" v-if="service?.income">
                    <div class="service-add__profile-block-title">Желаемый доход от</div>
                    <div class="service-add__profile-content-info">
                      {{ numberWithSpaces(service.income) }} Р
                    </div>
                  </div>
                  <div class="service-add__profile-block" v-if="service?.pay_format">
                    <div class="service-add__profile-block-title">Период</div>
                    <div class="service-add__profile-content-info">
                      {{ paymentQuickView }}
                    </div>
                  </div>
                </div>

                <div class="service-add__row" v-if="quickView">
                  <div class="service-add__profile-block" v-if="service?.hours">
                    <div class="service-add__profile-block-title">
                      Количество часов в {{ hoursCounter }}
                    </div>
                    <div class="service-add__profile-content-info">
                      {{ service.hours }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="service-add__profile-block" v-else-if="service?.budget">
                <div class="service-add__profile-block-title">Бюджет</div>
                <div class="service-add__profile-content-info">
                  {{ numberWithSpaces(service.budget) }} Р
                </div>
              </div>
              <div
                class="service-add__profile-block service-add__profile-block_fullsize"
                v-if="service?.companies.length"
              >
                <div class="service-add__profile-block-title">
                  Для каких компаний вы уже оказывали подобные услуги?
                </div>
                <div class="service-add__profile-content-info">{{ companiesFormat }}</div>
              </div>
              <div
                class="service-add__profile-block service-add__profile-block_fullsize"
                v-if="service?.description"
              >
                <div class="service-add__profile-block-title">Описание</div>
                <div class="service-add__profile-content-info">
                  {{ service.description }}
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-intersect="{
                  handler: selectOther,
                  options: {
                    threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                  },
                }"
              >
                <h1 id="other-options">Другие параметры</h1>
                <div v-if="isCompany" class="service-add__performer_type">
                  <checkbox text-size="16" text class="mb-4" v-model="performer_type">
                    Опубликовать услугу от лица компании
                  </checkbox>
                </div>
                <div class="form-pay">
                  <v-row>
                    <v-col md="6" cols="12" v-if="isPeople">
                      <base-dropdown
                        v-model="pay_format"
                        class="mt-5"
                        placeholder="Выберите из списка"
                        title="Выберите формат оплаты"
                        :list="[
                          { name: 'Сумма в месяц', id: 'per_month' },
                          { name: 'Сумма в час', id: 'per_hour' },
                        ]"
                        :defaultValue="service.pay_format"
                      ></base-dropdown>
                    </v-col>
                    <v-col :md="isCompany ? 12 : 6" cols="12">
                      <base-input
                        v-if="isCompany"
                        class="mt-4"
                        ref="v-budget"
                        description="От какой суммы вы готовы оказывать эту услугу?"
                        placeholder="Введите бюджет"
                        :range-max="getRangePrice.max"
                        :range-min="getRangePrice.min"
                        :range-step="getRangePrice.step"
                        :is-error="vOtherOptions$.budget.$error"
                        :error="[
                          {
                            text: 'Бюджет  обязателен для заполнения',
                            status: vOtherOptions$.budget.required.$invalid,
                          },
                          {
                            text: `Находится вне диапозона от ${numberWithSpaces(
                              getRangePrice.min
                            )} до ${numberWithSpaces(getRangePrice.max)}`,
                            status: vOtherOptions$.budget.validPrice.$invalid,
                          },
                        ]"
                        v-model="formattedBudget"
                      />
                      <base-input
                        v-if="isPeople"
                        ref="v-income"
                        class="income-from"
                        :description="payment"
                        placeholder="Введите желаемый доход"
                        :is-error="vOtherOptions$.income.$error"
                        :range-max="getRangePrice.max"
                        :range-min="getRangePrice.min"
                        :range-step="getRangePrice.step"
                        v-model="formattedIncome"
                        :error="[
                          {
                            text: 'Доход обязателен для заполнения',
                            status: vOtherOptions$.income.required.$invalid,
                          },
                          {
                            text: `Находится вне диапозона от ${numberWithSpaces(
                              getRangePrice.min
                            )} до ${numberWithSpaces(getRangePrice.max)}`,
                            status: vOtherOptions$.income.validPrice.$invalid,
                          },
                        ]"
                      />
                    </v-col>
                  </v-row>
                  <base-input
                    range
                    ref="v-hours"
                    :range-max="getRangeHorse.max"
                    :range-min="getRangeHorse.min"
                    :range-step="1"
                    v-model="hours"
                    class="special-range"
                    :description="`Какое количество часов в ${getStringValue} доступно для загрузки?`"
                    placeholder="Введите количество часов"
                    v-if="isPeople"
                    :is-error="vOtherOptions$.hours.$error"
                    :error="[
                      {
                        text: 'Количество часов обязательно для заполнения',
                        status: vOtherOptions$.hours.required.$invalid,
                      },
                      {
                        text: `Находится вне диапозона от ${getRangeHorse.min} до ${getRangeHorse.max}`,
                        status: vOtherOptions$.hours.validHours.$invalid,
                      },
                    ]"
                  ></base-input>
                </div>
                <vue-single-select-rows
                  ref="v-companies"
                  description="Для каких компаний вы уже оказывали подобные услуги?"
                  placeholder="Выберите из списка"
                  class="desired-clients"
                  :items="companiesList"
                  v-model="companies"
                  :new-value="true"
                  :is-scroll="true"
                  :is-error="vOtherOptions$.companies.$error"
                  multi
                  isService
                  searchEmptyText="Добавить новую компанию"
                  :error="[
                    {
                      text: 'Максимальное количество клиентов 10',
                      status: vOtherOptions$.companies.validLength.$invalid,
                    },
                  ]"
                ></vue-single-select-rows>
                <base-input
                  ref="v-description"
                  class="mt-7"
                  description="Описание услуги"
                  placeholder="1. Расскажите, какие задачи вы решаете
2. Опишите опыт реализации услуги
3. Расскажите о Ваших результатах подобных проектов"
                  type="textarea"
                  v-model="description"
                  :is-error="vOtherOptions$.description.$error"
                  :error="[
                    {
                      text: 'Описание услуги обязательно для заполнения',
                      status: vOtherOptions$.description.required.$invalid,
                    },
                    {
                      text: 'Максимальное количество символов 1000',
                      status: vOtherOptions$.description.maxLength.$invalid,
                    },
                  ]"
                ></base-input>
              </div>
            </template>
          </template>
        </template>
        <base-button @click="submit" class="information__save" v-if="!quickView">
          {{ btnName }}
        </base-button>
        <base-button @click="goBack" class="information__save" v-else>
          Вернуться назад
        </base-button>
      </template>
    </container>
    <alert-modal ref="teamAndPeopleModal">
      <template v-slot:button>
        <base-button
          @click="() => $refs.teamAndPeopleModal.closeModal()"
          class="button--light modal__btn"
          >Отмена</base-button
        >
      </template>
    </alert-modal>
    <alert-modal ref="alert"></alert-modal>
    <alert-modal ref="register" grid>
      <template v-slot:button>
        <base-button @click="login()" class="button--light mt-3"
          >Авторизоваться</base-button
        >
      </template>
    </alert-modal>
    <add-competence-choice-modal
      ref="add-competence-choice"
      :is-open="isOpenSpecialization"
      @close="closeSpecialization"
      :items="specialization"
      v-model="addSpecializationList"
    />
    <add-specialization-modal />
    <add-specialization-modal-work
      ref="add-specialization"
      :is-open="specializationModal"
      :functions-list="optionsList"
      @update-data="addSpecialization"
      @close="closeSpecializationModal"
      title="Выберите специализации"
      :v-model="currentSpec"
      :with-industry="false"
      btn-name="Добавить"
    />
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import ProgressBar from "@/components/ui/ProgressBar";
import BaseInput from "@/components/base/BaseInput";
import BaseDropdown from "@/components/base/BaseDropdown";
import AlertModal from "@/components/modals/AlertModal";
import { email, maxLength, required, sameAs } from "@vuelidate/validators";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import Checkbox from "@/components/base/VueTelInput/Checkbox";
import BaseRadioGroup from "@/components/base/BaseRadioGroup";
import InfoBox from "@/components/ui/InfoBox";
import { mapGetters } from "vuex";
import SelectIndustry from "@/components/ui/SelectIndustry";
import AddButton from "@/components/ui/button/AddButton";
import BaseRadioInput from "@/components/base/BaseRadioInput";
import VueMultiSelectCheckBox from "@/components/ui/VueMultiSelectCheckBox";
import SelectFunction from "@/components/ui/SelectFunction";
import AddPeopleTeamModal from "@/components/modals/AddPeopleTeamModal";
import TeamList from "@/components/component/TeamList";
import VueMultiSelectRows from "@/components/ui/VueMultiSelectRows";
import ExpertisesList from "@/components/component/ExpertisesList";
import Container from "@/components/layout/Container";
import useOtherOptions from "@/hooks/service/useOtherOptions";
import useIndustryAndFunctions from "@/hooks/service/useIndustryAndFunctions";
import oneRequest from "@/hooks/oneReqest";
import BaseErrorText from "@/components/base/BaseErrorText";
import SvgIcon from "@/components/base/SvgIcon";
import ServiceTask from "@/components/ui/ServiceTask";
import ExperienceBlock from "@/components/ui/ExperienceBlock";
import MemberList from "@/components/component/MemberList";
import AddCompetenceChoiceModal from "@/components/modals/AddCompetenceChoiceModal";
import InfoBoxAdd from "@/components/ui/InfoBoxAdd";
import AddSpecializationModal from "@/components/modals/AddSpecializationModal";
import VueSingleSelectRows from "@/components/ui/VueSingleSelectRows";
import BaseCallModal from "@/components/base/BaseCallModal";
import AddSpecializationModalWork from "@/components/modals/AddSpecializationModalWork";
import WorkBlock from "@/components/ui/WorkBlock";
export default {
  name: "ServicesPage",
  components: {
    VueSingleSelectRows,
    AddSpecializationModal,
    InfoBoxAdd,
    AddCompetenceChoiceModal,
    MemberList,
    ExperienceBlock,
    ServiceTask,
    SvgIcon,
    BaseErrorText,
    Container,
    ExpertisesList,
    VueMultiSelectRows,
    TeamList,
    AddPeopleTeamModal,
    SelectFunction,
    VueMultiSelectCheckBox,
    BaseRadioInput,
    AddButton,
    SelectIndustry,
    InfoBox,
    BaseRadioGroup,
    Checkbox,
    AlertModal,
    BaseDropdown,
    BaseInput,
    ProgressBar,
    BaseButton,
    BaseCallModal,
    AddSpecializationModalWork,
    WorkBlock,
  },
  setup: (props) => {
    const { requestFn } = oneRequest();
    const v$ = useVuelidate({ $scope: false });
    const otherOptions = useOtherOptions({ lendDescription: 1000 });
    return {
      requestFn,
      v$,
      ...otherOptions,
    };
  },
  computed: {
    ...mapGetters({
      industriesList: "registration/industriesList",
      optionsList: "registration/optionsList",
      isAuth: "auth/isAuth",
      user: "auth/user",
      serviceTypes: "service/serviceTypes",
      service: "service/service",
      companiesList: "company/companies",
      serviceSpecializations: "service/serviceSpecializations",
      specialization: "service/specialization",
      paymentType: "per_month",
    }),
    addSpecializationListGrouped() {
      return this.groupByTribeSecondElement(this.addSpecializationList);
    },
    isIndustryValue() {
      return this.industryValue.length >= 10;
    },
    btnName() {
      return this.id ? "Сохранить изменения" : "Создать услугу";
    },

    payment() {
      return this.pay_format === "per_month" ? "Сумма в месяц от" : "Сумма в час от";
    },
    paymentQuickView() {
      this.pay_format = this.service.pay_format | "per_month";
      return this.pay_format === "per_month" ? "Сумма в месяц" : "Сумма в час";
    },
    workloadFormat() {
      return this.workload_format === "per_day"
        ? "По дням"
        : this.workload_format === "per_week"
        ? "По неделям"
        : "По месяцам";
    },
    hoursCounter() {
      return this.workload_format === "per_day"
        ? "день"
        : this.workload_format === "per_week"
        ? "неделю"
        : "месяц";
    },
    companiesFormat() {
      return this.companies
        .map((item) => {
          return item.name;
        })
        .join(", ");
    },
    serviceTypeList() {
      if (this.serviceTypes.length > 0) {
        if (this.isCompany)
          return this.serviceTypes.filter((v) => v.type !== "individual");
        else if (this.isPeople)
          return this.serviceTypes.filter((v) => v.type !== "company");
        else return [];
      }
      return [];
    },
    serviceSpecialisationList() {
      if (this.serviceSpecializations || this.specialization) {
        return this.$route.params.id != null
          ? this.serviceSpecializations
          : this.specialization;
      }
      return [];
    },
  },
  created() {
    this.getOptions();
    this.getIndustries();
    this.getServiceTypes();
    this.serviceEdit();
    this.getCompany();
    this.getSpecialization();
  },
  mounted() {
    if (!this.isAuth) {
      this.$refs.register.show(
        "Внимание!",
        "Введенные данные не будут сохранены. " +
          "Необходимо зарегистрироваться или авторизоваться.",
        {
          type: "warning",
          nameButton: "Зарегистрироваться",
        },
        () => {
          this.$router.push({ path: "/sign-up" });
        }
      );
    }

    if (this.$route.query.teamAndPeople) {
      if (this.isAuth) this.updateTeamAndPeople(this.$route.query.teamAndPeople);
    }

    if (
      this.$route.fullPath
        .toString()
        .toLowerCase()
        .includes("quick-view".toString().toLowerCase())
    ) {
      this.quickView = true;
    }

    this.back = this.$router;
    this.workload_format = "per_week";
  },
  watch: {
    serviceTypeList(val) {
      if (val?.length) {
        if (this.$route.query.type) {
          this.type = +this.$route.query.type;
        }
      }
    },
    formattedBudget(val) {
      this.formattedBudget = this.numberWithSpaces(val);
      this.budget = Number(this.formattedBudget.replace(/\s/g, ""));
    },
    formattedIncome(val) {
      this.formattedIncome = this.numberWithSpaces(val);
      this.income = Number(this.formattedIncome.replace(/\s/g, ""));
    },
    userList: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        let str = "";
        if (newValue.length) {
          str = "?";
          newValue.forEach((item, idx) => {
            if (item.type !== "invited" && item.id !== this.user.id) {
              str += idx === 0 ? `users[]=${item.id}` : `&users[]=${item.id}`;
            }
          });
        }
        this.getSpecialization(str);
      },
    },
  },
  data() {
    return {
      id: null,
      edit: false,
      quickView: false,
      industryValue: [],
      performer_type: false,
      autoIncrement: 1,
      industryArray: [{ autoIncrement: 0 }],
      name: "",
      formattedBudget: "1 000",
      formattedIncome: "1 000",
      type: null,
      tabs: null,
      tabsPeople: false,
      tabsIndustry: false,
      tabsCommand: false,
      tabsCompetence: false,
      isOpenSpecialization: false,
      addSpecializationList: [],
      back: "",
      currentSpec: [],
      currentSpecTitle: "",
      specializationModal: false,
    };
  },
  methods: {
    async submitValue() {
      let industries = [];
      let userList = [];
      let expertisesList = [];
      let functions = [];
      let maturity = [0];
      let specializations = [];

      if (this.industryValue != null)
        industries = this.industryValue.map((value) => value.id);

      if (this.currentSpec) {
        for (let i = 0; i < this.currentSpec.length; i++) {
          let value = this.currentSpec[i];
          if (value.options.id <= -10) {
            let response = await this.addOption(value.options.name);
            functions.push(response);
          } else {
            functions.push(value.options.id);
          }
        }
      }
      if (this.userList != null)
        userList = this.userList.map((value) => ({ id: value.id, type: value.type }));
      if (this.expertisesList != null)
        expertisesList = this.expertisesList
          .filter((v) => v.value)
          .map((value) => value.id);
      let company = {
        users: userList,
        budget: this.budget,
        performer_type: this.performer_type ? "company" : "individual",
      };
      let people = {
        income: this.income,
        hours: this.hours,
        workload_format: this.workload_format,
        pay_format: this.pay_format,
      };
      this.paymentType = people.pay_format;
      if (this.addSpecializationList.length) {
        this.addSpecializationList.forEach((item) => {
          specializations.push({
            industry_id: item.industry_id,
            option_id: item.option_id,
          });
        });
      }

      let payload = {
        name: this.name,
        format: this.teamAndPeople,
        description: this.description,
        maturity: maturity,
        type: this.type,
        industries: industries,
        options: functions.filter((v) => v != null),
        expertises: expertisesList,
        specializations,
        companies: this.companies
          .filter((v) => v.newValue === false && v.value === true)
          .map((value) => value.id),
        new_companies: this.companies
          .filter((v) => v.newValue === true && v.value === true)
          .map((value) => {
            return {
              name: value.name,
            };
          }),
        ...(this.isCompany ? company : people),
      };

      if (this.industryArray.length) {
        let subFunction = [];
        this.industryArray.map((item) => {
          if (item.options != null) {
            item.options.forEach((el) => {
              let obj = {};
              if (el.newValue) {
                obj.name = el.name;
                obj.parent_id = item.id;
                subFunction.push(obj);
              } else {
                payload.options.push(el.id);
              }
            });
          }
          return item;
        });

        if (subFunction.length) {
          payload.new_options = subFunction;
        }
      }
      return payload;
    },
    async addOption(name) {
      let response = await this.$store.dispatch("registration/addOptions", {
        name: name,
      });
      return response.id;
    },
    performerType(str) {
      if (str === "individual") {
        return "От себя";
      } else return "От команды";
    },
    showModalInfo() {
      this.$refs.alert.show(
        "Уровень зрелости функции",
        `1 – только начинает разбираться
          2 – имеет базовые представления
          3 – разбирается на среднем уровне
          4 – уверенно реализует
          5 – разбирается на уровне лучших практик`,
        {
          type: "star",
          nameButton: "Понятно",
          parseString: true,
        }
      );
    },
    del(item) {
      const to_del = [];
      for (let x in item.base_indeces) {
        to_del.push(this.addSpecializationList[x]);
      }
      this.$refs["add-competence-choice"].delItem(to_del);
    },
    async serviceEdit() {
      if (this.$route.params.id != null) {
        await this.getServiceById(this.$route.params.id);
        this.setDataEdit();
        this.edit = true;
        this.id = this.$route.params.id;
      }
    },
    checkParentId(arr) {
      this.currentSpec = [];
      this.currentSpecTitle = "";
      for (let i = 0; i < arr.length; i++) {
        let elem = {
          title: "",
          years: null,
          industries: -1,
          works: null,
        };
        elem.id = i;
        elem.options = arr[i];
        this.currentSpec.push(elem);
        this.currentSpecTitle = this.currentSpecTitle + arr[i].name + ", ";
      }
      this.currentSpecTitle = this.currentSpecTitle.slice(0, -2);
    },
    setDataEdit() {
      let users = [];
      let maturity = [0];
      let companies = [];
      let industries = [];
      let expertises = [];
      let options = [{ autoIncrement: 0 }];
      if (this.service.industries != null) {
        industries = this.service.industries.map((v) => ({
          ...v,
          ...{ newValue: false },
        }));
      }
      if (this.service.companies != null)
        companies = this.service.companies.map((v) => ({
          ...v,
          ...{ value: true, newValue: false },
        }));

      if (this.service.users != null)
        this.service.users.forEach((v) => {
          let company = v.company != null ? v.company.name : null;
          users.push({ ...v, ...{ company: company, value: true, newValue: false } });
        });
      if (this.service.expertises != null)
        this.service.expertises.forEach((v) => {
          expertises.push({ ...v, ...{ like: "--", value: true, newValue: false } });
        });

      this.checkParentId(this.service.options);

      if (this.service?.abilities?.length) {
        let service = JSON.parse(JSON.stringify(this.service.abilities));
        this.addSpecializationList = service.map((item) => {
          item.value = true;
          item.newValue = false;
          item.id = `${item.industry_id}_${item.option_id}`;
          return item;
        });
      }

      this.teamAndPeople = this.service.format;
      this.name = this.service.name;
      this.budget = this.service.budget;
      this.income = this.service.income;
      this.performer_type = this.service.performer_type === "company";
      this.description = this.service.description;
      this.type = this.service.service_type_id;
      this.industryValue = industries;
      this.companies = companies;
      this.maturity = maturity;
      this.userList = users;
      this.expertisesList = expertises;
      this.hours = this.service.hours;
      if (!!this.service.budget) {
        this.formattedBudget = this.numberWithSpaces(this.service.budget);
      }
      if (!!this.service.income) {
        this.formattedIncome = this.numberWithSpaces(this.service.income);
      }
    },
    percentService() {
      let step = 0;
      if (
        this.v$.name.$invalid === false &&
        this.vCompany$.teamAndPeople.$invalid === false &&
        this.v$.type.$invalid === false
      )
        step = step + 1;
      if (this.industryArray.length > 0 && this.industryArray[0].id) step = step + 1;
      if (this.isCompany && this.userList.length > 0) step = step + 1;
      if (this.expertisesList.length > 0) step = step + 1;
      if (this.completeOtherOptions) step = step + 1;
      return step * (this.isCompany ? 20 : 25);
    },
    login() {
      this.$router.replace({ name: "AuthPage" });
    },
    numberWithSpaces(x) {
      return x
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    async getOptions() {
      await this.$store.dispatch("registration/getOptions");
    },
    async getServiceTypes() {
      await this.$store.dispatch("service/getServiceTypes");
    },
    async getIndustries() {
      await this.$store.dispatch("registration/getIndustries");
    },
    async getServiceById(id) {
      await this.$store.dispatch("service/getServiceById", id);
    },
    async getCompany() {
      await this.$store.dispatch("company/getCompany", null);
    },
    addIndustry() {
      if (this.industryArray.length < 5)
        this.industryArray.push({
          autoIncrement: ++this.autoIncrement,
        });
    },
    dellIndustry(value) {
      this.industryArray = this.industryArray.filter((item) => item !== value);
    },
    async setService() {
      const data = await this.submitValue();
      try {
        if (this.edit)
          await this.$store.dispatch("service/postServiceId", {
            id: this.id,
            data: data,
          });
        else await this.$store.dispatch("service/postService", data);

        this.$router.replace({ name: "ServicesPage" });
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
        throw e;
      }
    },
    removeDuplicates(array) {
      return Array.from(new Set(array));
    },
    trueYears(companies, years) {
      console.log(companies, years);
      var dict = {};
      for (var i = 0; i < companies.length; i++) {
        dict[companies[i]] = parseInt(years[i]) || 0;
      }
      return Object.values(dict).reduce((acc, curr) => acc + curr, 0);
    },
    groupByTribeSecondElement(objects) {
      console.log(objects);
      //ВОТ ЭТО НАДО УНЕСТИ НА БЭК
      let counter = 0;
      const groupedObj = objects.reduce((acc, obj) => {
        const [, secondPart] = obj.tribe.split(" - ");
        if (!acc[secondPart]) {
          acc[secondPart] = {
            function_name: secondPart,
            tribe: [],
            users: [],
            companies: [],
            roles: [],
            years: [],
            rating_count: 0,
            r_counter: 0,
            rating: 0,
            industry_id: [],
            option_id: [],
            history: [],
            base_indeces: [], //Индексы исходного массива для рекомендаций
          };
        }
        const group = acc[secondPart];
        group.tribe.push(obj.tribe.split(" - ")[0]);
        group.users.push(...obj.users);
        group.companies.push(...obj.companies);
        group.roles.push(...obj.roles.filter((role) => role));
        group.years.push(obj.years);
        group.r_counter += obj.rating ? 1 : 0;
        group.rating += obj.rating;
        group.rating_count += obj.rating_count;
        group.industry_id.push(obj.industry_id);
        group.option_id.push(obj.option_id);
        group.history.push(...obj.history);
        group.base_indeces.push(counter);
        counter += 1;
        return acc;
      }, {});

      let result = Object.values(groupedObj).map((group) => ({
        ...group,
        tribe: this.removeDuplicates(group.tribe),
        users: this.removeDuplicates(group.users),
        companies: this.removeDuplicates(group.companies),
        roles: this.removeDuplicates(group.roles),
        years: group.years.reduce((a, b) => a + b, 0) | 0,
        rating: group.rating ? Math.round(group.rating / group.r_counter) : group.rating,
        industry_id: this.removeDuplicates(group.industry_id),
        option_id: this.removeDuplicates(group.option_id),
        history: this.removeDuplicates(group.history),
      }));
      return result;
    },
    async submit() {
      const result = await this.v$.$validate();
      const resultCompany = await this.vCompany$.$validate();
      const resultOtherOptions = await this.vOtherOptions$.$validate();
      const isIndustryValue = this.isIndustryValue;
      if (!result || !resultCompany || !resultOtherOptions || isIndustryValue) {
        if (!this.autoFocus(this.v$))
          if (!this.autoFocus(this.vCompany$))
            if (!this.autoFocus(this.vOtherOptions$))
              console.warn("Ошибка в обработки ошибок");
        return;
      }
      await this.setService();
      this.$router.push({ path: "/services" });
    },
    autoFocus(validate) {
      if (validate.$invalid) {
        for (let key in Object.keys(validate)) {
          const input = Object.keys(validate)[key];
          if (!input.includes("$")) {
            if (validate[input].$error) {
              try {
                this.$refs[`v-${input}`].focus();
                return true;
              } catch (e) {
                console.log(`v-${input}`);
                throw e;
              }
            }
          }
        }
      }
      return false;
    },
    updateTeamAndPeople(e) {
      if (this.teamAndPeople != null) {
        this.$refs.teamAndPeopleModal.show(
          "Изменить тип услуги",
          "Вы уверены, что хотите изменить тип услуги? Все раннее введеные " +
            "данные будут утеряны.",
          {
            nameButton: "Изменить",
            type: "edit",
          },
          () => {
            this.teamAndPeople = e;
            this.type = null;
            console.log(this.type);
          }
        );
      } else {
        this.teamAndPeople = e;
      }
    },
    selectPeople(e) {
      if (e) {
        // this.$router.push({hash: '#people'})
        this.tabs = "people";
      }
      this.tabsPeople = e;
    },
    goBack() {
      console.log(this.$router);
      this.$router.push({
        path: this.back.options.history.state.back,
        query: this.$route.query,
      });
    },
    addSpecialization(e) {
      let options = e.options.filter((el) => el.options !== -1);
      this.currentSpec = [];
      this.currentSpecTitle = "";
      for (let i = 0; i < options.length; i++) {
        let elem = {
          title: "",
          years: null,
          industries: -1,
          works: null,
        };
        elem.id = i;
        elem.options = options[i];
        this.currentSpec.push(elem);
        this.currentSpecTitle = this.currentSpecTitle + options[i].name + ", ";
      }
      this.currentSpecTitle = this.currentSpecTitle.slice(0, -2);
      this.$refs["add-specialization"].hideModal();
    },
    closeSpecializationModal() {
      this.$refs["add-specialization"].hideModal();
    },
    openSpecializationModal() {
      if (this.currentSpec.length) {
        this.$refs["add-specialization"].showModal(this.currentSpec);
      } else {
        this.$refs["add-specialization"].showModal([
          {
            title: "",
            years: null,
            id: 1,
            industries: -1,
            options: { options: -1, id: -1 },
            works: null,
          },
        ]);
      }
    },
    selectIndustry(e) {
      if (e && !this.tabsPeople) {
        // this.$router.push({hash: '#industry-function'})
        this.tabs = "industry-function";
      }
      this.tabsIndustry = e;
    },
    selectCommand(e) {
      if (e && !this.tabsIndustry) {
        // this.$router.push({hash: '#company-list'})
        this.tabs = "company-list";
      }
      this.tabsCommand = e;
    },
    selectCompetence(e) {
      if (this.isCompany) {
        if (e && !this.tabsCommand) {
          // this.$router.push({hash: '#expertises-list'})
          this.tabs = "expertises-list";
        }
      } else {
        if (e && !this.tabsIndustry) {
          // this.$router.push({hash: '#expertises-list'})
          this.tabs = "expertises-list";
        }
      }
      this.tabsCompetence = e;
    },
    selectSpecialization(e) {
      if (this.isCompany) {
        if (e && !this.tabsCommand) {
          // this.$router.push({hash: '#expertises-list'})
          this.tabs = "specialization-list";
        }
      } else {
        if (e && !this.tabsIndustry) {
          // this.$router.push({hash: '#expertises-list'})
          this.tabs = "specialization-list";
        }
      }
      this.tabsCompetence = e;
    },
    selectOther(e) {
      if (e && !this.tabsCompetence) {
        // this.$router.push({hash: '#other-options'})
        this.tabs = "other-options";
      }
    },
    openSpecialization() {
      this.isOpenSpecialization = true;
      console.log("addSpec", this.addSpecializationList);
    },
    closeSpecialization() {
      this.isOpenSpecialization = false;
    },
    arrayValid() {
      return this.currentSpec.length;
    },
    async getSpecializationsList() {
      try {
        await this.$store.dispatch(
          "service/getSpecializationsList",
          this.$route.params.id
        );

        if (this.serviceSpecializations?.length) {
          this.addSpecializationList = this.serviceSpecializations;
        }
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getSpecialization(payload = "") {
      await this.$store.dispatch("service/getSpecialization", payload);
    },
  },

  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(100),
      },
      type: {
        required,
      },
      optionsArray: {
        arrayValid: this.arrayValid,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

//#industry-function {
//  margin-top: 60px;
//}
//#other-options {
//  margin-top: 60px;
//  margin-bottom: 30px;
//}
.action-info {
  width: auto;

  @include sm {
    width: 100%;
  }
}

.information {
  &__save {
    margin-top: 50px;
  }
}

.service-add {
  &__row {
    display: flex;
    gap: 24px;
  }

  &__profile {
    &-block {
      padding: 24px 0;
      width: 50%;

      &_fullsize {
        width: 100%;
      }

      &-title {
        margin-bottom: 12px;
        color: $text-gray;
        @include desktop_description;
      }
    }

    &-content-info {
      @include desktop_standart;

      &.budget {
        @include desktop_h1;
      }
    }
  }
}

.service-specialization {
  &__header {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include sm {
      flex-wrap: wrap;
      margin-top: 48px;
    }

    .add-button {
      width: fit-content;

      @include sm {
        margin: 24px 0 12px;
        width: 100%;
      }
    }
  }

  &__description {
    margin-bottom: 30px;

    @include sm {
      @include desktop_additional;
    }
  }

  &__caption {
    @include desktop_h1;
  }
}

.modal__btn {
  margin-left: 16px;
}

.type-of-service {
  margin-bottom: -10px;
}

.income-from {
  margin-top: 20px;

  @include sm {
    margin-top: -5px;
  }
}

.involvement {
  margin-top: 32px;

  @include sm {
    margin-top: 16px;
  }
}

.form-pay {
  @include md {
    margin-top: -23px;
  }
}

.btn_disabled {
  display: none;
}

.service-add {
  .desired-clients {
    margin-top: 16px;

    @include md {
      margin-top: 6px;
    }
  }

  .container__body,
  .body {
    h1 {
      margin-top: 60px;
      margin-bottom: 30px;

      @include md {
        margin-bottom: 24px;
        margin-top: 48px;
      }
    }
  }

  &__navigation {
    margin-bottom: 40px;
  }

  &__performer_type {
    margin-bottom: 30px;

    @include md {
      margin-bottom: 24px;
    }
  }

  &__add-function {
    width: 50%;

    @include sm {
      width: 100%;
    }
  }

  &__task {
    margin-bottom: 16px;
  }

  &__task {
    margin-bottom: 16px;
  }
}
</style>
