<template>
  <div class="item-block" :class="{ cursor: cursor }">
    <div class="top">
      <div v-if="rating" class="rating" :style="{ background: colorRange(rating) }">
        {{ rating }}
      </div>
      <div v-else class="title" :style="{ 'max-width': '300px' }">{{ title }}</div>
      <div class="actions" :style="{ width: rating ? '350px' : '80px' }">
        <!--   <div v-if="actionButton & !mobile" class="action-button" @click="actionClick">
          <svg-icon name="plus-round" :width="20" :height="20"></svg-icon>
          <p>{{ actionName }}</p>
        </div>-->

        <div v-if="watchButton" class="act" @click="watchClick">
          <svg-icon
            name="eye"
            :width="mobile ? 20 : 13"
            :height="mobile ? 20 : 13"
          ></svg-icon>
        </div>

        <div v-if="editButton" class="act" @click="editClick">
          <svg-icon
            name="mm-project-d"
            :width="mobile ? 20 : 13"
            :height="mobile ? 20 : 13"
          ></svg-icon>
        </div>
        <div v-if="dellButton" class="act" @click="dellClick">
          <svg-icon
            name="close-mobile-menu"
            :width="mobile ? 20 : 13"
            :height="mobile ? 20 : 13"
          ></svg-icon>
        </div>
        <base-radio
          v-if="checkButton"
          type="checkbox"
          v-model="checkboxValue"
        ></base-radio>
      </div>
    </div>
    <div class="head" v-if="rating || nRating">
      <div v-if="rating" class="title test">
        {{ title }}
      </div>
      <div v-if="nRating" class="subtitle">{{ nRatingString }}</div>
    </div>
    <div class="content-font">
      <div><slot name="content"></slot></div>
      <div style="color: #a0a0a0">
        <slot name="footer"></slot>
      </div>
    </div>
    <div v-if="actionButton" class="action-button" @click="actionClick">
      <svg-icon name="plus-block-mobile" :width="20" :height="20"></svg-icon>
      <p>{{ actionName }}</p>
    </div>
    <div v-if="subMenuOpen" class="confirm-menu" v-click-outside="outside">
      <template v-for="(name, idx) in Object.keys(this.subMenu)" :key="name">
        <a class="services__task-action" @click="subClick(this.subMenu[name])">{{
          name
        }}</a>
      </template>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import Rating from "@/components/ui/Rating";
import AlertModal from "@/components/modals/AlertModal";
import BaseButton from "@/components/base/BaseButton";
import BaseRadio from "@/components/base/BaseRadio";
import { declOfNum } from "@/utils/helper";
export default {
  name: "BaseBlock",
  components: { BaseRadio, BaseButton, AlertModal, Rating, SvgIcon },
  data() {
    return {
      checkboxValue: false,
      subMenuOpen: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    rating: {
      type: Number,
      default: 0,
    },
    nRating: {
      type: Number,
      default: 0,
    },
    actionName: {
      type: String,
      default: "Рекомендация",
    },
    actionButton: {
      type: Boolean,
      default: false,
    },
    dellButton: {
      type: Boolean,
      default: false,
    },
    editButton: {
      type: Boolean,
      default: false,
    },
    checkButton: {
      type: Boolean,
      default: false,
    },
    watchButton: {
      type: Boolean,
      default: false,
    },
    cursor: {
      type: Boolean,
      default: false,
    },
    baseValue: {
      type: Boolean,
      default: false,
    },
    subMenu: {
      type: Object,
      default: {},
    },
  },
  methods: {
    checkClick() {
      this.checkboxValue = !this.checkboxValue;
    },
    actionClick() {
      if (!Object.keys(this.subMenu).length) this.$emit("action");
      else this.subMenuOpen = true;
    },
    subClick(val) {
      this.$emit("subclick", val);
      this.subMenuOpen = false;
    },
    outside() {
      if (this.subMenuOpen) this.subMenuOpen = false;
    },
    dellClick() {
      this.$emit("dell");
    },
    editClick() {
      this.$emit("edit");
    },
    watchClick() {
      this.$emit("watch");
    },
    colorRange(value) {
      if (!this.isDay) {
        if (value >= 4) {
          return "#7FC666";
        } else if (value >= 3) {
          return "#FDC04B";
        } else if (value > 0) {
          return "#EF693F";
        } else {
          return "#ccc";
        }
      } else {
        if (value >= 7) {
          return "#7FC666";
        } else {
          return "#FDC04B";
        }
      }
    },
  },

  mounted() {
    this.checkboxValue = this.baseValue;
  },
  computed: {
    nRatingString() {
      let str = declOfNum(this.nRating, ["оценка", "оценки", "оценок"]);
      return `${this.nRating} ${str}`;
    },
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
  watch: {
    checkboxValue() {
      this.$emit("select", {
        select: this.checkboxValue,
      });
    },
    baseValue() {
      this.checkboxValue = this.baseValue;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.item-block {
  display: flex;
  flex-direction: column;
  width: 334px;
  padding: 20px 20px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 18px;
  border: 1px solid #e6e6e6;
  background: #fff;
  margin-bottom: 12px;
  &:hover {
    border: 1px solid #a0a0a0;
  }
  @include sm {
    flex-direction: column;
    max-width: 264px;
    min-width: 264px;
    padding: 20px;
    background: #f6f6f6;
    border: 0px solid #a0a0a0;
    min-height: 100%;
    margin-bottom: 0px;
    min-height: 85px;
    &:hover {
      border: 0px solid #a0a0a0;
    }
  }
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  @include sm {
    max-width: 200px;
    flex-direction: row;
    gap: 0px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
  @include sm {
    gap: 8px;
    flex-direction: column-reverse;
    align-items: flex-end;
    position: absolute;
    right: 25px;
    top: 20px;
  }
}
.act {
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: rgba(246, 246, 246, 0.4);
  @include sm {
    width: 20px;
    height: 20px;
    margin-right: 3px;
    background: none;
  }
}
.cursor {
  cursor: pointer;
}
.action-button {
  cursor: pointer;
  display: flex;
  padding: 10px 10px;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  background: #f6f6f6;
  font-family: SFProDisplay;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  margin-top: auto;
  @include sm {
    background: rgb(255, 255, 255);
    display: flex;
    height: 32px;
    padding: 5px 14px 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 8px;
    background: #e6e6e6;
    color: #42434e;
    font-size: 15px;
    line-height: 14px;
    margin-top: auto;
  }
}
.rating {
  display: flex;
  width: 64px;
  height: 40px;
  padding: 5px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 20px;

  color: #fff;
  text-align: center;
  font-family: Muller;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  @include sm {
    top: 0px;
    position: absolute;
    width: 56px;
    height: 40px;
    font-size: 18px;
    border-radius: 0px 0px 8px 8px;
  }
}
.wrapper {
  display: block;
}
.head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.title {
  display: flex;
  color: #000;
  font-family: Muller;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 110% */
  word-break: break-word;
  @include sm {
    font-size: 16px !important;
    line-height: 20px;
    text-align: left;
  }
}

.subtitle {
  color: #a0a0a0;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 124%; /* 19.84px */
  @include sm {
    font-size: 15px;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.26px;
  }
}
.content-font {
  gap: 12px;
  text-align: left;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  margin-top: auto;
  @include sm {
    font-size: 15px;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.26px;
  }
}
.confirm-menu {
  position: relative;
  z-index: 100;
  font-family: SFProDisplay;
  background-color: #f6f6f6;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 24px;
  font-size: 15px;
  width: 100%;
  padding: 24px 20px;
  gap: 14px;

  @include sm {
    background-color: white;
    font-size: 15px;
  }
}

.services__task-action {
  cursor: pointer;

  &:hover {
    color: $green;
  }
}

.test {
  @include sm {
    padding-top: 20px;
  }
}
</style>
