<template>
  <base-modal
    :is-permanent="isPermanent"
    :size="size"
    :is-open="isOpen"
    @close="closeModalEvent"
  >
    <div class="alert">
      <div class="icon-center">
        <svg-icon :name="type" :height="48" :width="48" />
      </div>
      <h2 class="alert__header" :class="{ 'mb-24': !subtitle }">{{ header }}</h2>
      <div
        v-if="subtitle !== ''"
        class="subtitle"
        :class="{ subtitle_align: parseString }"
      >
        <span v-if="!parseString"> {{ subtitle }}</span>
        <span v-else v-for="(item, index) in parseDescription" :key="index"
          >{{ item }}<br
        /></span>
      </div>
      <div
        v-if="!isPermanent"
        class="action"
        :class="{
          'action-grid': grid,
        }"
      >
        <base-button
          :style="{ backgroundColor: color }"
          :error="error"
          @click="successModal"
        >
          <template v-if="!nameButton">
            <slot />
          </template>
          <template v-else>
            {{ nameButton }}
          </template>
        </base-button>
        <slot v-if="!isPermanent" name="button" />
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseInput from "@/components/base/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";

export default {
  name: "AlertModal",
  components: {
    BaseButton,
    BaseInput,
    BaseModal,
    SvgIcon,
  },
  props: {
    grid: Boolean,
    size: {
      type: [String, Number],
      default: 440,
    },
    isPermanent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      fullCallback: null,
      header: "",
      subtitle: "",
      verificationCode: "",
      callback: null,
      type: "success",
      nameButton: "",
      redirectPath: "",
      color: null,
      error: false,
      parseString: false,
    };
  },
  computed: {
    parseDescription() {
      return this.subtitle.split("\n");
    },
  },
  methods: {
    /**
     * Вызвать уведомления
     * @param header - Текст заголовка
     * @param subtitle - Дополнительный контент, отображаемый под заголовком
     * @param option Доп порамитры
     * @param option.type - Тип уведомления по простому влияет только на иконку (success, error)
     * @param option.nameButton - Текст кнопки
     * @param option.redirectPath - Текст кнопки
     * @param option.fullCallback - События колбека выполняется в любом случае
     * @param option.parseString
     * @param callback - Функция обратного вызова
     * @param option.error - Вывод красной кнопки 

     */
    show(header, subtitle, option, callback = null) {
      if (header != null) this.header = header;
      if (subtitle != null) this.subtitle = subtitle;
      if (option != null) {
        if (option.type != null) this.type = option.type;
        if (option.nameButton != null) this.nameButton = option.nameButton;
        if (option.redirectPath != null) this.redirectPath = option.redirectPath;
        if (option.fullCallback != null) this.fullCallback = option.fullCallback;
        if (option.error != null) this.error = option.error;
        if (option.parseString != false) {
          this.parseString = option.parseString;
        }
      } else {
        this.type = "success";
      }
      if (callback != null) {
        this.callback = callback;
      }
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.$emit("close");
      if (this.redirectPath.length) this.$router.push({ path: this.redirectPath });
    },
    closeModalEvent() {
      if (this.fullCallback) this.successModal();
      this.closeModal();
    },
    successModal() {
      this.closeModal();
      if (this.callback != null) {
        this.callback();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.alert {
  &__header {
    @include desktop_h1;
  }
}

.action {
  display: flex;
}

.action-grid {
  display: grid;
}

.icon-center {
  text-align: center;
  margin-bottom: 24px;
}

h2 {
  margin-bottom: 12px;
  text-align: center;
}

span {
  text-align: center;
  font-size: 18px;
  word-break: break-word;
}

.subtitle {
  text-align: center;
  margin-bottom: 24px;
  line-height: 22px;

  &_align {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  span {
    font-size: 18px;
    line-height: 22px;
    word-break: break-word;
  }
}
</style>
