<template>
  <div class="projects-response">
    <container :full-body="isFullBody" :no-nav="isFullBody">
      <template #nav>
        <v-tabs direction="vertical" style="overflow: hidden !important" v-model="tabs">
          <v-tab value="invite" href="#invite" @click="updateTime('executor_invite')"
            >Новые приглашения
            <base-signal-chip
              :title="responseStatus.executor.invite.title"
              :type="responseStatus.executor.invite.type"
              v-if="responseStatus.executor.invite?.type"
          /></v-tab>
          <v-tab value="sent" href="#sent" @click="updateTime('executor_sent')"
            >Отправленные
            <base-signal-chip
              :title="responseStatus.executor.sent.title"
              :type="responseStatus.executor.sent.type"
              v-if="responseStatus.executor.sent?.type"
          /></v-tab>
          <v-tab value="active" href="#active" @click="updateTime('executor_active')"
            >Активные
            <base-signal-chip
              :title="responseStatus.executor.active.title"
              :type="responseStatus.executor.active.type"
              v-if="responseStatus.executor.active?.type"
          /></v-tab>
          <v-tab
            value="completed"
            href="#completed"
            @click="updateTime('executor_completed')"
            >Завершенные<base-signal-chip
              :title="responseStatus.executor.completed.title"
              :type="responseStatus.executor.completed.type"
              v-if="responseStatus.executor.completed?.type"
          /></v-tab>
          <v-tab
            value="rejected"
            href="#rejected"
            @click="updateTime('executor_rejected')"
            >Отклоненные<base-signal-chip
              :title="responseStatus.executor.rejected.title"
              :type="responseStatus.executor.rejected.type"
              v-if="responseStatus.executor.rejected?.type"
          /></v-tab>
        </v-tabs>
      </template>
      <!-- <template #btn>
        <info-block-small class="info-block-small"></info-block-small
      ></template> -->
      <template #body>
        <div class="bodyflex">
          <v-item-group v-model="tabs" class="text-center scrolling-wrapper">
            <v-item
              v-for="n in tabsList"
              :key="`btn-${n}`"
              v-slot="{ isSelected, toggle }"
              :value="n.value"
            >
              <div
                :class="isSelected ? 'active-chip-mobile' : 'chip-mobile'"
                @click="updateTime('executor_' + n.value, toggle, isSelected)"
              >
                {{ n.title }}
              </div>
              <base-signal-chip
                class="mobile-chip"
                v-if="responseStatus.executor[n.value].type"
                :title="responseStatus.executor[n.value].title"
                :type="responseStatus.executor[n.value].type"
              />
            </v-item>
          </v-item-group>
        </div>
        <template v-if="!isFullBody">
          <div>
            <v-window v-model="tabs">
              <v-window-item value="invite">
                <div v-if="responseAll.request.length">
                  <h2 class="mt-0 block-header">Новые приглашения</h2>
                  <div class="projects-response__list">
                    <response-item
                      v-for="item in paginatedSlice(responseAll.request, 'invite')"
                      :key="`response_${item.status}_${item.id}`"
                      :title="item.demand_name"
                      :description="item.demand_service_type"
                      :status="item.status"
                      :is-finished="item.demand_is_finished"
                      :is-unseen="!item.is_viewed"
                      :hide-budget="true"
                      class="pointer"
                      @click="toDemand(item.demand_id, item.id, true)"
                    >
                      <template #btn>
                        <div class="response-item__control-double">
                          <div>
                            <base-button
                              v-if="notInterested !== item.id"
                              @click.stop="notInterested = item.id"
                              light
                              class="noInterest"
                              >Не интересно</base-button
                            >
                            <div v-else class="response-item__control-double-unequal">
                              <base-button error
                                @click.stop="rejectRedirect(item.demand_id)"
                                >Удалить</base-button
                              ><base-button light class="cross" @click.stop="notInterested = -1"
                                ><svg-icon name="grey-close" class="cross-notint"></svg-icon></base-button
                              >
                            </div>
                          </div>

                          <base-button @click="toDemand(item.demand_id, item.id, true)" :class="notInterested === item.id ? 'faded' : ''"
                            >Подробнее</base-button
                          >
                        </div>
                      </template>
                    </response-item>
                    <v-pagination
                      v-if="responseAll.request.length > requestsPerPage"
                      v-model="currentPage['invite']"
                      :length="Math.ceil(responseAll.request.length / requestsPerPage)"
                    ></v-pagination>
                  </div>
                </div>
                <not-item
                  v-else
                  title="У вас еще нет подходящих проектов"
                  description="Добавьте в профиле индустрии и функции, которые соответствуют вашей экспертизе"
                ></not-item>
              </v-window-item>
              <v-window-item value="sent">
                <div v-if="sentAndModeration.length > 0">
                  <h2 class="mt-0 block-header">Отправленные</h2>
                  <div class="projects-response__list">
                    <response-item
                      v-for="item in sentAndModeration"
                      :key="`response_${item.status}_${item.id}`"
                      :title="item.demand_name"
                      :description="item.demand_service_type"
                      :budget="trueBudget(item)"
                      :label="
                        item.viewed_by_customer === null ? 'Отправлено' : 'Просмотрено'
                      "
                      :status="item.status"
                      :is-finished="item.demand_is_finished"
                    />
                  </div>
                  <v-pagination
                    v-if="this.responseAll.sent.length > requestsPerPage"
                    v-model="currentPage['sent']"
                    :length="Math.ceil(this.responseAll.sent.length  / requestsPerPage)"
                  ></v-pagination>
                </div>
                <not-item
                  v-else
                  title="У вас ещё нет отправленных откликов"
                  description="Откройте новые приглашения и откликнитесь на интересные проекты"
                ></not-item>
              </v-window-item>

              <v-window-item value="active">
                <div v-if="responseAll.active">
                  <h2 class="mt-0 block-header">Активные</h2>

                  <div class="projects-response__list">
                    <response-item
                      v-for="item in paginatedSlice(responseAll.active, 'active')"
                      :key="`response_${item.status}_${item.id}`"
                      :title="item.demand_name"
                      :description="item.demand_service_type"
                      :budget="trueBudget(item)"
                      :status="item.status"
                      :is-finished="item.demand_is_finished"
                    />
                  </div>
                  <v-pagination
                    v-if="responseAll.active.length > requestsPerPage"
                    v-model="currentPage['active']"
                    :length="Math.ceil(responseAll.active.length / requestsPerPage)"
                  ></v-pagination>
                </div>
                <not-item
                  v-else
                  title="У вас ещё нет активных проектов"
                  description="Проект станет активным, когда заказчик выберет вас в качестве эксперта"
                ></not-item>
              </v-window-item>

              <v-window-item value="completed">
                <div v-if="responseAll.completed">
                  <h2 class="mt-0 block-header">Завершенные</h2>

                  <div class="projects-response__list">
                    <response-item
                      v-for="item in paginatedSlice(responseAll.completed, 'completed')"
                      :key="`response_${item.status}_${item.id}`"
                      :title="item.demand_name"
                      :description="item.demand_service_type"
                      :budget="trueBudget(item)"
                      :status="item.status"
                      :is-finished="item.demand_is_finished"
                    />
                  </div>
                  <v-pagination
                    v-if="responseAll.completed.length > requestsPerPage"
                    v-model="currentPage['completed']"
                    :length="Math.ceil(responseAll.completed.length / requestsPerPage)"
                  ></v-pagination>
                </div>
                <not-item v-else title="У вас нет завершенных проектов"></not-item>
              </v-window-item>
              <v-window-item value="rejected">
                <div v-if="responseAll.rejected">
                  <h2 class="mt-0 block-header">Отклоненные</h2>
                  <div class="projects-response__list">
                    <response-item
                      v-for="item in paginatedSlice(responseAll.rejected, 'rejected')"
                      :key="`response_${item.status}_${item.id}`"
                      :title="item.demand_name"
                      :description="item.demand_service_type"
                      :budget="trueBudget(item)"
                      :status="item.status"
                      :is-finished="item.demand_is_finished"
                      label="Выбран другой исполнитель"
                    />
                  </div>
                  <v-pagination
                    v-if="responseAll.rejected.length > requestsPerPage"
                    v-model="currentPage['rejected']"
                    :length="Math.ceil(responseAll.rejected.length / requestsPerPage)"
                  ></v-pagination>
                </div>
                <not-item
                  v-else
                  title="У вас нет отклоненных проектов"
                  description="Проект становится отклоненным, если заказчик выбирает другого эксперта"
                ></not-item>
              </v-window-item>
            </v-window>
          </div>
        </template>
        <template v-else>
          <!--<not-item
            title="У вас ещё нет откликов"
            description="Создайте услугу и предлагайте решения
            для бизнеса"
            btn-name="Создать услугу"
            @btnEvent="toPage('/service-add')"
          />-->
          <not-item
            title="У вас еще нет подходящих проектов"
            description="Добавьте в профиле индустрии и функции, которые соответствуют вашей экспертизе"
          />
        </template>
      </template>
    </container>
    <alert-modal ref="alert" />
  </div>
</template>

<script>
import InfoBlockSmall from "@/components/ui/InfoBlockSmall";
import Container from "@/components/layout/Container";
import ResponseItem from "@/components/component/ResponseItem";
import { mapGetters } from "vuex";
import { sortByKey } from "@/utils/helper";
import AlertModal from "@/components/modals/AlertModal";
import NotItem from "@/components/ui/notItem";
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";
import BaseSignalChip from "@/components/base/BaseSignalChip";
export default {
  name: "ProjectsResponsePage",
  components: {
    NotItem,
    AlertModal,
    ResponseItem,
    Container,
    BaseButton,
    BaseSignalChip,
    NotItem,
    InfoBlockSmall,
    SvgIcon,
  },
  data() {
    return {
      notInterested: -1,
      isActive: false,
      tabsList: [
        {
          title: "Новые приглашения",
          value: "invite",
        },
        {
          title: "Отправленные",
          value: "sent",
        },
        {
          title: "Активные",
          value: "active",
        },
        {
          title: "Завершенные",
          value: "completed",
        },
        {
          title: "Отклоненные",
          value: "rejected",
        },
      ],
      tabs: null,
      tabsInvite: false,
      tabsSent: false,
      tabsActive: false,
      tabsCompleted: false,
      tabsRejected: false,
      currentPage: {
        invite: 1,
        sent: 1,
        active: 1,
        completed: 1,
        rejected: 1,
      },
      requestsPerPage: 3,
      preCheckedId: -1,
    };
  },
  created() {
    this.currentPage.invite = this.pageNumber.invite;
    this.getAllRespond();
    this.getServiceTypes();
  },
  beforeRouteEnter(to, from, next) {
    const callback = (component) => {
      this.getAllRespond();
      this.getServiceTypes();
      let request_id = +from.path.split("/")[2];
      component.returnPage(request_id);
    };
    next(callback);
  },
  mounted() {
    this.updateTime("executor_invite");
    this.getAllRespond();
    this.getServiceTypes();
  },
  computed: {
    ...mapGetters({
      responseAll: "request/responseAll",
      responseStatus: "status/responseStatus",
      serviceTypes: "service/serviceTypes",
      pageNumber: "pages/pageNumber",
    }),
    sentAndModeration() {
      return this.paginatedSlice(
        [
          ...(this.responseAll.sent ? this.responseAll.sent : []),
          ...(this.responseAll.moderation ? this.responseAll.moderation : []),
        ],
        "sent"
      );
    },
    mobile() {
      return (
        this.$vuetify.display.md || this.$vuetify.display.sm || this.$vuetify.display.xs
      );
    },
    isFullBody() {
      if (
        this.responseAll?.request?.length ||
        this.responseAll?.sent?.length ||
        this.responseAll?.moderation?.length ||
        this.responseAll?.active?.length ||
        this.responseAll?.completed?.length ||
        this.responseAll?.rejected?.length
      ) {
        return false;
      }

      return true;
    },
  },
  watch: {
    "currentPage.invite"(currentVal, nextVal) {
      this.$store.dispatch("pages/setPageNumberByKey", { invite: currentVal });
    },
  },
  methods: {
    async getAllRespond() {
      try {
        this.$store.dispatch("content/isLoading", true);
        await this.$store.dispatch("request/getAllRespond");
        const self = this;
        if (this.preCheckedId >= 0) {
          const idx = this.responseAll.request.findIndex(
            (obj) => obj.demand_id === self.preCheckedId
          );
          this.currentPage["invite"] = Math.ceil((idx + 1) / this.requestsPerPage);
        }
        this.$store.dispatch("content/isLoading", false);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    returnPage(e) {
      if (e > 0) {
        this.preCheckedId = e;
      }
    },
    getMenuChip(key) {
      return this.responseStatus.executor[key];
    },
    async updateTime(key, toggle, isSelected) {
      if (toggle && !isSelected) toggle();
      this.$store.dispatch("status/updateVisitTimes", key);
    },
    async getServiceTypes() {
      await this.$store.dispatch("service/getServiceTypes");
    },
    trueBudget(item) {
      let service = this.serviceTypes.find(
        (type) => type.name === item.demand_service_type
      );
      const round_method = service.is_rounded_up ? Math.ceil : Math.floor;
      return (
        round_method(item.demand_budget / (1 + service.fee) / service.round_base) *
        service.round_base
      );
    },

    inviteSort(array) {
      return array.sort((a, b) => {
        if (a.is_viewed === b.is_viewed) {
          // Сортировка по created_at в обратном порядке
          return new Date(b.created_at) - new Date(a.created_at);
        }
        // Сортировка по is_viewed
        return (a.is_viewed === true) - (b.is_viewed === true);
      });
    },

    paginatedSlice(items, type) {
      let items_paginated = JSON.parse(JSON.stringify(items));
      items_paginated = sortByKey(items_paginated, "created_at");
      ///console.log(items_paginated) ЧЕКНУТЬ ПРАВИЛЬНОСТЬ
      if (type === "invite") {
        items_paginated = this.inviteSort(items_paginated);
      }

      if (items_paginated.length > this.requestsPerPage) {
        const startIndex = (this.currentPage[type] - 1) * this.requestsPerPage;
        const endIndex = startIndex + this.requestsPerPage;
        return items_paginated.slice(startIndex, endIndex);
      }
      return items_paginated;
    },
    async dropFlag(id) {
      await this.$store.dispatch("request/dropIsNewFlagDemandsServices", id);
    },
    toDemand(demand_id, item_id, confirm) {
      this.dropFlag(item_id);
      this.$router.push({
        path: `/response-request/${demand_id}`,
        query: { respond: confirm },
      });
    },
    selectInvite(e) {
      if (e) {
        this.tabs = "invite";
      }
      this.tabsInvite = e;
    },
    selectSent(e) {
      if (e) {
        this.tabs = "sent";
      }
      this.tabsSent = e;
    },
    selectActive(e) {
      if (e && !this.tabsSent) {
        this.tabs = "active";
      }
      this.tabsActive = e;
    },
    selectCompleted(e) {
      if (e && !this.tabsActive) {
        this.tabs = "completed";
      }
      this.tabsCompleted = e;
    },
    selectRejected(e) {
      if (e && !this.tabsCompleted) {
        this.tabs = "rejected";
      }
      this.tabsRejected = e;
    },
    toPage(path) {
      this.$router.push(path);
    },
    async rejectRedirect(demand_id) {
      const formData = new FormData();
      formData.append("demand_id", demand_id);
      await this.$store.dispatch("request/rejectResponse", formData);
      this.getAllRespond();
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.info-block-small {
  display: block;
  @include sm {
    display: none;
  }
}
.tabs {
  @include desktop_h1;
}
.project-request {
  &__create-btn {
    margin-top: 40px;

    &_mobile {
      @include md {
        display: none;
      }
    }
  }
}
.active-chip-mobile {
  display: inline-block;
  border-radius: 24px;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 19.84px */
  color: #a0a0a0 !important;
  padding: 10px 15px;
  overflow: visible !important;
  background-color: #f6f6f6;
  color: black !important;
}
.chip-mobile {
  display: inline-block;
  border-radius: 24px;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 19.84px */
  color: #a0a0a0 !important;
  padding: 10px 15px;
  overflow: visible !important;

  &:first-child {
    margin-left: 0;
  }
  &:hover {
    color: black !important;
  }
}

.scrolling-wrapper {
  display: none;
  @include sm {
    width: 80vw;
    display: block;
    position: relative;
    overflow-x: scroll;
    overflow-y: auto;
    white-space: nowrap;
    flex-wrap: wrap;
    padding-top: 10px;
    justify-content: left;
    padding-bottom: 20px;
    vertical-align: bottom;
  }
}

.menu-item {
  color: #000;
  /* Desktop/Standart */
  font-family: SFProDisplay !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 40 !important;
  line-height: 22px !important; /* 122.222% */
  letter-spacing: 0.108px !important;
}
.v-slide-group-item--active {
  color: #000 !important;
  font-family: SFProDisplay !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important; /* 122.222% */
  letter-spacing: 0.108px !important;
}
.pointer {
  cursor: pointer;
}
.block-header {
  @include desktop_h1;
  margin-bottom: 30px;
  margin-top: 45px;

  @include sm() {
    margin-bottom: 24px;
    margin-top: 48px;
  }
}

.response-item {
  &__control-double {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @include sm {
      display: flex;
      flex-direction: column-reverse;

      .button:last-child {
        margin-top: unset;
      }
    }
  }
  &__control-double-unequal {
    display: grid;
    grid-template-columns: 5fr 1fr;
    gap: 8px;

    @include sm {
      // display: flex;
      // flex-direction: column;

      .button:last-child {
        margin-top: unset;
      }
    }
  }
}
.cross-notint{
width: 16.972px;
height: 16.971px;
flex-shrink: 0;
}
.noInterest{
  border: 2px solid !important;
  border-color: #f6f6f6 !important;
}
.faded{
  background-color: #d2d3d5;
  border: none;
  @include sm {
    background-color: #4b4c57;
    border: 2px solid;
  border-color: #4b4c57;
  &:hover {
    background-color: #d2d3d5 !important;
    border: none;
  }
  }
  

&:hover {
    background-color: #d2d3d5 !important;
    border: none;
  }
}
.mobile-chip {
  max-height: 20px;
  position: relative;
  right: 20px;
  bottom: 20px;
}
.mobile-menu {
  display: none;
  @include md {
    display: flex;
    position: relative;
    max-width: v-bind(currentWidth);
    overflow-x: scroll;
    overflow-wrap: normal;
    margin-bottom: 20px;
    padding-right: 10px;
  }
}

.v-slide-group {
  display: flex !important;
  overflow-x: scroll !important;
  max-width: 500px !important;
}

.v-carousel-item {
  height: auto;
}
.projects-response {
  &__list {
    max-width: 100%;
    display: grid;
    gap: 24px;
  }

  &__no-list {
    @include desktop_standart;
  }

  &__block {
    margin-bottom: 60px;

    @include sm {
      margin-bottom: 48px;
    }

    &:last-child {
      margin-bottom: 0;

      @include sm {
        margin-bottom: 0;
      }
    }
  }
}
</style>
