<template>
  <div class="information">
    <container>
      <template v-slot:nav>
        <v-tabs direction="vertical" class="" v-model="tabs">
          <v-tab href="#privacy" value="privacy">Персональные данные</v-tab>
        </v-tabs>
      </template>
      <template v-slot:body>
        <div
          v-intersect="{
            handler: selectPrivacy,
            options: {
              threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            },
          }"
        >
          <div>
            <h2 class="information__header" id="privacy">Смена пароля</h2>
            <div class="double-block double-block__old-pass">
              <div class="double-block__col">
                <base-input
                  v-model="oldPassword"
                  type="password"
                  description="Старый пароль"
                  placeholder="Старый пароль"
                />
              </div>
            </div>
            <a class="forget-pass" @click="resetPassword">Забыли пароль?</a>
            <div class="double-block">
              <div class="double-block__col">
                <base-input
                  v-model="newPassword"
                  type="password"
                  description="Новый пароль"
                  placeholder="Новый пароль"
                  :is-error="v$.newPassword.$error"
                  :error="[
                    {
                      text: 'Поле обязательно для заполнения',
                      status: v$.newPassword.required.$invalid,
                    },
                  ]"
                />
                <base-input
                  v-model="repeatPassword"
                  type="password"
                  description="Повторите пароль"
                  placeholder="Повторите пароль"
                  :is-error="v$.repeatPassword.$error"
                  :error="[
                    {
                      text: $t('validate.repeat-password'),
                      status: v$.repeatPassword.sameAs.$invalid,
                    },
                    {
                      text: 'Поле обязательно для заполнения',
                      status: v$.repeatPassword.required.$invalid,
                    },
                  ]"
                />
              </div>
              <div class="double-block__col">
                <validation-pass-block
                  :required="{
                    status: v$.newPassword.required.$invalid,
                    isShow: true,
                  }"
                  :length="{
                    status: v$.newPassword.minLength.$invalid,
                    isShow: true,
                  }"
                  :is-upper-case="{
                    status: v$.newPassword.containsUppercase.$invalid,
                    isShow: true,
                  }"
                  :latin="{
                    status: v$.newPassword.latin.$invalid,
                    isShow: true,
                  }"
                />
              </div>
            </div>

            <div class="double-block double-block__change-pass">
              <div class="double-block__col">
                <base-button @click="submit">Изменить пароль</base-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </container>
    <contact-support-modal ref="supportModal" />
    <reset-password-modal
      no-register
      ref="resetPasswordModal"
      go-back
    ></reset-password-modal>
    <alert-modal ref="alert" />
  </div>
</template>

<script>
import Container from "@/components/layout/Container";
import BaseInput from "@/components/base/BaseInput";
import ValidationPassBlock from "@/components/ui/ValidationPassBlock";
import useVuelidate from "@vuelidate/core";
import { minLength, required, sameAs } from "@vuelidate/validators";
import BaseButton from "@/components/base/BaseButton";
import ContactSupportModal from "@/components/modals/ContactSupportModal";
import AlertModal from "@/components/modals/AlertModal";
import QuestionAnswer from "@/components/ui/QuestionAnswer";
import ResetPasswordModal from "@/components/modals/ResetPasswordModal";
export default {
  name: "ProfileSettingsPage",
  components: {
    ResetPasswordModal,
    AlertModal,
    ContactSupportModal,
    BaseButton,
    ValidationPassBlock,
    BaseInput,
    Container,
    QuestionAnswer,
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  data() {
    return {
      tab: null,
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
      tabs: null,
      tabsPrivacy: false,
    };
  },
  created() {
    this.getSupportInfo();
  },
  methods: {
    resetPassword() {
      this.$refs.resetPasswordModal.isResetPassword = true;
    },
    containsUppercase(value) {
      return value && /[A-Z]/.test(value);
    },
    latin(value) {
      return value && /^[a-zA-Z_*@!#0-9]+$/.test(value);
    },
    async submit() {
      const result = await this.v$.$validate();
      console.log(this.v$);
      if (!result) {
        return;
      }

      const data = {
        old_password: this.oldPassword,
        password_confirmation: this.repeatPassword,
        password: this.newPassword,
      };

      try {
        await this.$store.dispatch("profile/updatePassword", data);
        this.$refs.alert.show("Пароль успешно изменен!", "", {
          type: "success",
          nameButton: "Закрыть",
        });
        this.clearFields();
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    openContactModal() {
      this.$refs.supportModal.showModal();
    },
    async getSupportInfo() {
      try {
        await this.$store.dispatch("profile/getSupportInfo");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    selectPrivacy(e) {
      if (e) {
        this.tabs = "privacy";
      }
      this.tabsPrivacy = e;
    },
    selectQuestions(e) {
      if (e && !this.tabsPrivacy) {
        this.tabs = "questions";
      }
    },
    clearFields() {
      this.v$.$reset();
      this.oldPassword = "";
      this.newPassword = "";
      this.repeatPassword = "";
    },
  },
  computed: {
    isActive() {
      if (this.oldPassword && this.newPassword && this.repeatPassword) {
        return false;
      }

      return true;
    },
  },
  validations() {
    return {
      newPassword: {
        required,
        minLength: minLength(8),
        containsUppercase: this.containsUppercase,
        latin: this.latin,
      },
      repeatPassword: {
        required,
        sameAs: sameAs(this.newPassword),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.forget-pass {
  display: inline-flex;
  margin-top: 10px;
  margin-bottom: 21px;
  cursor: pointer;
  &:hover {
    color: $green;
  }
}
.s-question {
  margin-top: 60px;
  @include md {
    margin-top: 48px;
  }
}
.information {
  &__content {
    display: grid;
  }

  &__header {
    @include sm() {
      font-size: 29px;
      margin-bottom: 23px !important;
    }
  }

  &__success {
    min-width: 20px;
    min-height: 20px;
    margin-left: 20px;
  }

  &__send {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__save {
    margin-top: 50px;
  }

  &__social-btn {
    margin-bottom: 12px;
    width: 330px;
    @include md {
      width: 310px;
    }
  }

  &__header {
    margin-bottom: 30px;
  }
}

.double-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @include sm() {
    grid-template-columns: 100%;
  }

  &.double-block__old-pass {
    .input-group {
      margin-bottom: 0;
    }
  }

  .input-group:not(:last-child) {
    margin-bottom: 24px;
  }

  .input-group:last-child {
    margin-bottom: 0px;
  }

  &__change-pass {
    margin-top: 30px;
    @include md {
      margin-top: 24px;
    }
  }

  .pass-validation {
    margin-top: 30px;

    @include sm() {
      margin-top: 0;
    }
  }
}
.container-double-body__right {
  position: absolute;
  right: -300px;
  top: 0;
  max-width: 270px;
  @include lg {
    position: relative;
    max-width: initial;
    right: auto;
  }
}
.container-double-body {
  position: relative;
  @include md() {
    grid-template-columns: 100%;
  }
}

.write-chat {
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  padding: 20px;

  &__title {
    font-size: 16px;
    line-height: 20px;
    color: #4b4c57;
    margin-bottom: 24px;
  }
}
</style>
