<template>
  <div v-if="title" class="chip" :class="[type]">
    {{ title }}
  </div>
  <div v-else class="chip-small" :class="[type]">
    <div>●</div>
  </div>
</template>

<script>
export default {
  name: "BaseSignalChip",
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "success",
    },
    linkStyle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.chip-small {
  font-size: 10px;
  margin: 2px 7px;
  @include md {
    margin: 0px 5px;
  }
  &.success {
    color: $green;
  }
  &.primary {
    color: #606f7b;
  }
}

.chip {
  display: inline-block;
  padding: 4px 6px;
  height: 18px;
  font-size: 12px;
  line-height: 10px;
  border-radius: 25px;
  color: white;
  margin-left: 5px;
  @include md {
    font-size: 10px;
  }

  &.success {
    background-color: $green;
  }
  &.primary {
    background-color: #606f7b;
  }
  &:hover {
    color: #ffffff;
  }
}
</style>
