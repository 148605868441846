import axios from 'axios'
import { VueAxios } from './setup'
import httpAdapter from 'axios/lib/adapters/http'

import LsUtil from '@/utils/ls'
import { LS_REFRESH_TOKEN, LS_USER_LANG } from '@/utils/ls/keys'

const handleError = (error) => {
    // if (isAccessTokenExpired(error)) {
    //   return refreshAccessToken(error)
    // }
    if (error.response.status === 401) {
        if (!error.response.data.authorization_failed) {
            const refreshToken = LsUtil.getItem(LS_REFRESH_TOKEN)
            if (refreshToken != null) {
                LsUtil.setItem(LS_REFRESH_TOKEN, null)
                window.location.reload();
            }
        }
    }
    return Promise.reject(error)
}
let params = {}
let timeout = 36000
console.log(process.env)
if (process.env.VUE_APP_PHP_STORM === 'true') {

    timeout = 30000
    params = {
        XDEBUG_SESSION: 'PHPSTORM'
    }
}

const serviceMain = axios.create({
    baseURL: config.VUE_APP_API_URL,
    params: params,
    adapter: httpAdapter,
    timeout: timeout,
    headers: {
        'Content-Type': 'application/json',
    },
})


serviceMain.interceptors.request.use((config) => {
    if (config.requiresAuth) {
        const refreshToken = LsUtil.getItem(LS_REFRESH_TOKEN)
        if (refreshToken) {
            config.headers['Authorization'] = `Bearer ${refreshToken}`
        }
    }
    const refreshLang = LsUtil.getItem(LS_USER_LANG)
    config.headers['Content-Language'] = refreshLang == null ? 'ru' : refreshLang
    return config
}, handleError)

serviceMain.interceptors.response.use((response) => response, handleError)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, serviceMain)
    },
}

export { installer as VueAxios, serviceMain as axiosMain }