import registrationService from "@/services/registrationService";
import LsUtil from "@/utils/ls";
import { LS_REFRESH_TOKEN } from "@/utils/ls/keys";
import { errorFormat } from "@/utils/helper";

export default {
    namespaced: true,
    state: {
        workTypeList: [],
        industriesList: [],
        industriesByIdList: [],
        optionsList: [],
        newOptionsList: [],
        error: null,
        verifyCode: null,
        userProfile: null,
    },
    actions: {
        async getWorkType({ commit }) {
            try {
                const data = await registrationService.getWorkType();
                commit('SET_WORK_TYPE', data);
            } catch (e) {
                console.log(e)
            }
        },
        async getIndustries({ commit }) {
            try {
                const data = await registrationService.getIndustries();
                commit('SET_INDUSTRIES', data);
            } catch (e) {
                console.log(e)
            }
        },
        async getOptions({ commit }) {
            try {
                const data = await registrationService.getOptions();
                commit('SET_OPTIONS', data);
            } catch (e) {
                console.log(e)
            }
        },
        async addOptions({ commit }, payload) {
            try {
                const response = await registrationService.addOptions(payload);
                const data = await registrationService.getOptions();
                commit('SET_OPTIONS', data);
                return response.data.data
            } catch (e) {
                console.log(e)
            }
        },
        async getIndustriesById({ commit }, data) {
            try {
                const response = await registrationService.getIndustriesById(data.id);
                commit('SET_INDUSTRIES_BY_ID', response);
            } catch (e) {
                console.log(e)
            }
        },
        async registrationMain({ commit, rootState, context }, payload) {
            try {
                commit('SET_ERROR', null) //TODO Придумать как делать очистку перед каждым запроссом
                const response = await registrationService.sendRegistrationData(payload);
                LsUtil.setItem(LS_REFRESH_TOKEN, response.data.token)
                commit('auth/SET_AUTH', response.data.token, { root: true })
            } catch (e) {
                throw e;
            }
        },
        async registrationMainSocial({ commit, rootState, context }, payload) {
            try {
                commit('SET_ERROR', null) //TODO Придумать как делать очистку перед каждым запроссом
                const response = await registrationService.sendRegistrationDataSocial(payload);
                LsUtil.setItem(LS_REFRESH_TOKEN, response.data.token)
                commit('auth/SET_AUTH', response.data.token, { root: true })
            } catch (e) {
                throw e;
            }
        },
        async sendVerifyCode({ commit }, payload) {
            try {
                commit('SET_ERROR', null) //TODO Придумать как делать очистку перед каждым запроссом
                commit('VERIFY_CODE', null)
                const data = await registrationService.sendVerifyCode(payload);
                console.log(data.data);
                if (data.data.error)
                    commit('SET_ERROR', data.data.error)
                else
                    commit('VERIFY_CODE', data.data.message)
            } catch (e) {
                console.log(e)
                commit('SET_ERROR', e.response.data)
            }
        },
        async sendUserProfile({ commit }, payload) {
            try {
                commit('SET_ERROR', null) //TODO Придумать как делать очистку перед каждым запроссом
                const data = await registrationService.sendUserProfile(payload);
                if (data.data.error)
                    commit('SET_ERROR', data.data.error)
                else
                    commit('SET_USER_PROFILE', data.data.message)
                return data;
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },

        async sendUserStatus({ commit }, payload) {
            try {
                commit('SET_ERROR', null) //TODO Придумать как делать очистку перед каждым запроссом
                const data = await registrationService.sendUserStatus(payload);
                if (data.data.error)
                    commit('SET_ERROR', data.data.error)
                return data;
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },

        async sendUserProfileFile({ commit }, payload) {
            try {
                commit('SET_ERROR', null) //TODO Придумать как делать очистку перед каждым запроссом
                const data = await registrationService.sendUserProfileFile(payload);
                console.log(data.data);
                if (data.data.error)
                    commit('SET_ERROR', data.data.error)
                else {

                }
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getCodeAgain({ commit }) {
            try {
                await registrationService.getCodeAgain();
            } catch (e) {
                console.log(e)
            }
        }
    },
    mutations: {
        SET_ERROR(state, value) {
            state.error = value
        },
        VERIFY_CODE(state, value) {
            state.verifyCode = value
        },
        SET_WORK_TYPE(state, value) {
            state.workTypeList = value.data.data
        },
        SET_INDUSTRIES(state, value) {
            state.industriesList = value.data.data
        },
        SET_OPTIONS(state, value) {
            state.optionsList = value.data.data
        },
        SET_NEW_OPTIONS(state, value) {
            state.newOptionsList.push(value)
        },
        DROP_NEW_OPTIONS(state, value) {
            state.newOptionsList = []
        },
        SET_INDUSTRIES_BY_ID(state, value) {
            state.industriesByIdList = value.data.data
        },
        SET_USER_PROFILE(state, value) {
            state.userProfile = value
        }
    },
    getters: {
        error(state) {
            return state.error
        },
        verifyCode(state) {
            return state.verifyCode
        },
        workTypeList(state) {
            return state.workTypeList
        },
        optionsList(state) {
            return state.newOptionsList.concat(state.optionsList)
        },
        newOptionsList(state) {
            return state.newOptionsList
        },
        industriesList(state) {
            return state.industriesList
        },
        industriesByIdList(state) {
            return state.industriesByIdList
        },
        userProfile(state) {
            return state.userProfile
        },
    },
}