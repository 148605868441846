<template>
  <v-row :class="{'industry-full': full}" class="select-function">
    <v-col cols="12">
      <p class="mb-2 select-function__label">{{ $t('component.industry.select-function') }}</p>
      <v-row class="select-function__card" style="margin: 0">
        <v-col class="flex-grow-1 flex-shrink-1 pa-0">
          <base-dropdown
              :placeholder="$t('component.industry.select-function')"
              :list="mainFunctionList"
              v-model="functionItem"
              class="select-function__select"
              :sub-description="$t('component.industry.add-function-description')"
          />
        </v-col>
        <v-col class="flex-grow-0 flex-shrink-0 pa-0 select-function__icon-btn">
          <slot/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="align-end pa-3 sub-function">
      <p class="mb-2 mt-1 select-function__label">{{ $t('component.industry.select-sub-function') }}</p>
      <vue-multi-select-check-box
        :full="full"
        optionLabel="name"
        optionKey="id"
        :not-value-to-full-select="notValueSelectFull"
        :new-value="true"
        :placeholder="$t('component.industry.select-sub-function')"
        v-model="subFunction"
        :options="listSubFunction"
        :sub-description="descriptionFunction"
      ></vue-multi-select-check-box>
    </v-col>
  </v-row>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import BaseDropdown from "@/components/base/BaseDropdown";
import VueMultiSelect from "@/components/ui/VueMultiSelect";
import SizedBox from "@/components/ui/SizedBox";
import VueMultiSelectCheckBox from "@/components/ui/VueMultiSelectCheckBox";
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "SelectFunction",
  components: {BaseButton, VueMultiSelectCheckBox, SvgIcon, BaseDropdown, VueMultiSelect, SizedBox},
  props: {
    full: Boolean,
    functionsList: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Object,
    },
    descriptionFunction: String,
    notValueSelectFull: {
      type: Boolean,
      default: true
    }
  },
  created() {
    if (this.modelValue != null) {
      if (this.modelValue.id != null)
        this.listSubFunction = this.functionsList.filter(item => item.parent_id === this.modelValue.id);
      this.subFunction = this.modelValue.options;
    }
  },
  data() {
    return {
      functionItem: this.modelValue != null ? this.modelValue.id : null,
      subFunction: this.modelValue != null ? this.modelValue.options : null,
      listSubFunction: []
    }
  },
  computed: {
    mainFunctionList() {
      return this.functionsList.filter(item => item.parent_id === null)
    },
  },
  methods: {
    input(v) {
      console.log(v);
    },
    setModelData() {
      let modelData = {
        id: this.functionItem,
        options: this.subFunction === null ? [] : this.subFunction
      };
      this.$emit('update:modelValue', modelData)
    },
  },
  watch: {
    functionItem(val) {
      this.setModelData();
    },
    subFunction(val) {
      this.setModelData();
    }
  },
}
</script>

<style lang="scss" scoped>
  .sub-function {
    margin-top: -10px;
  }
  .select-function {
    &__icon-btn {
      margin-left: 20px;
    }
    &__label {
      font-size: 18px;
    }
    &__card {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 auto;
    }
    &__select {
      flex-basis: 0;
      flex-shrink: 1;
    }
    &__btn-dell {
      padding-bottom: 16.5px;
      padding-top: 15px;
      flex-basis: 0;
      flex-shrink: 0;
    }
  }
</style>
