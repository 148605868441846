import ApiUtil from '@/utils/api'

export default {
    auth: (data) =>
        ApiUtil({
            url: '/api/login',
            method: 'post',
            data,
            // requiresAuth: true,
        }),
    authImpersonate: (data) =>
        ApiUtil({
            url: `/api/impersonate`,
            method: 'post',
            data,
        }),
    forgotPassword: (data) =>
        ApiUtil({
            url: '/api/forgot-password',
            method: 'post',
            data,
            // requiresAuth: true,
        }),
    resetPassword: (data) =>
        ApiUtil({
            url: '/api/reset-password',
            method: 'post',
            data,
            // requiresAuth: true,
        }),
    social: (data) =>
        ApiUtil({
            url: '/api/auth/social?redirect_url=' + encodeURI(location.origin),
            method: 'get',
            data,
            // requiresAuth: true,
        }),
    getUserData: (id = null) =>
        ApiUtil({
            url: id ? `/api/user/${id}` : '/api/user',
            method: 'get',
            requiresAuth: true,
        }, ),
    getUserSearch: (data, emailOnly = false) =>
        ApiUtil({
            url: `/api/user/search${data != null ? '?search=' + data : ''}` + (emailOnly ? '&email-only=true' : ''),
            method: 'get',
            requiresAuth: true,
        }, ),
    getRates: (data) =>
        ApiUtil({
            url: '/api/user/rates?hash=' + encodeURI(data),
            method: 'get',
            requiresAuth: true,
        }, ),
    postMultiRate: (data) =>
        ApiUtil({
            url: '/api/user/multi-rate',
            method: 'post',
            requiresAuth: true,
            data
        }, ),
    postUserInvite: (data) =>
        ApiUtil({
            url: '/api/invite',
            method: 'post',
            requiresAuth: true,
            data
        }, ),
    communities: (data) =>
        ApiUtil({
            url: `/api/user/communities?type=${data.type}&page=${data.page}&per_page=${data.per_page}${data.search ? '&search=' + data.search : ''}`,
            data,
            method: 'get',
            requiresAuth: true,
        }, ),
}