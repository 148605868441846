import {computed, ref} from "vue";
import {required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default function useCompany() {
  const teamAndPeople = ref()
  const isPeople = computed(() => teamAndPeople.value === 'individual');
  const isCompany = computed(() => teamAndPeople.value === 'company');
  const rules = computed(() => ({
    teamAndPeople: {
      required,
    }
  }));
  const vCompany$ = useVuelidate(rules, {
    teamAndPeople
  },{$scope: false});
  return {
    teamAndPeople,
    isPeople,
    isCompany,
    vCompany$
  }
};