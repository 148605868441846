<template>
  <div class="base-list-info">
    <div class="base-list-info__header">
      {{ header }}
    </div>
    <div class="base-list-info__body">
      {{ body }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseListInfo",
  props: {
    header: '',
    body: ''
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
.base-list-info {
  &__header {
    @include desktop_description;
    color: $text-gray;
    margin-bottom: 12px;
  }
  &__body {
    @include desktop_standart_bold;
  }
}

</style>