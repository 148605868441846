<template>
  <div class="experience-block">
    <div
      class="experience-block__confirmation-btn"
      @click.stop="isDropdown = !isDropdown"
    >
      {{ item.confirmation.length }} {{ nameString(item.confirmation.length) }}
      <div class="experience-block__icon ml-2" :class="{ rotate: !isDropdown }">
        <svg-icon :width="mobile ? 9 : 12" name="confirmation-down-white" />
      </div>
    </div>
    <div class="experience-block__confirmation-list" v-if="isDropdown">
      <div v-for="(confirmation, idx) in item.confirmation" :key="`confirmation-${idx}`">
        <div
          :title="confirmation.name"
          v-if="confirmation.name"
          class="button--light service__company-success experience-block__confirmation-item"
        >
          {{ confirmation.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import { declOfNum } from "@/utils/helper";
export default {
  name: "ExperienceBlockConfirmation",
  components: { SvgIcon },
  props: {
    item: Object,
  },
  data() {
    return {
      isDropdown: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
  methods: {
    nameString(count) {
      return declOfNum(count, ["подтверждение", "подтверждения", "подтверждений"]);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.experience-block {
  &__confirmation-btn {
    @include desktop_standart;
    display: flex;
    align-items: center;
    color: $text-gray;
    cursor: pointer;
    @include sm(){
      font-size: 15px !important;
    }
  }
  &__icon {
    margin-top: 5px;
    transform: rotate(-180deg);
    transition: 0.3s;
    &.rotate {
      transform: rotate(0deg);
      transition: 0.3s;
    }
  }
  &__confirmation-list {
    margin-top: 8px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
  &__confirmation-item {
    @include desktop_standart;
    flex-direction: row;
    align-items: center;
    padding: 7px 14px;
    gap: 10px;
    background: $bg-light-gray;
    border-radius: 16px;
    width: auto;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px !important;
    @include sm {
      background: white;
      font-size: 15px !important;
      padding: 4px 11px;
    }
  }
}
</style>
