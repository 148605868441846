<template>
  <div v-if="true" class="modal-wrapper">
    <div ref="modal" class="dialog-content__group">
      <div class="dialog-content" :class="classes">
        <slot />
      </div>
      <div v-if="$slots.body1" class="dialog-content" :class="classes">
        <slot name="body1" />
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon";
import BaseButton from "./BaseButton";

export default {
  name: "BaseModal",
  components: {
    SvgIcon,
    BaseButton,
  },
  props: {
    /** Сделать модальное окно большого размера */
    big: Boolean,
    size: {
      type: [String, Number],
      default: 550,
    },
  },
  data() {
    return {
      isFullWidth: false,
    };
  },
  computed: {
    hasBg() {
      return !this.text && !this.outlined;
    },
  },
  methods: {},

  mounted() {
    window.onpopstate = function (event) {
      const body = document.querySelector("html");
      body.classList.remove("no-scroll");
    };
  },
  unmounted() {
    const body = document.querySelector("html");
    body.classList.remove("no-scroll");
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.modal-wrapper {
  display: block;
  position: fixed;
  bottom: 0;
  z-index: 10;
  padding-bottom: 0px;
  height: 90px;
  align-content: center;
  justify-content: space-around;
  width: 100%;
}
.modal-wrapper-full {
  display: flex;
}
.modal-wrapper-full .dialog-content__group {
  padding-top: 0px;
  padding-bottom: 0px;
  @include md {
    margin-top: 0px;
  }
}

.dialog-content {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    & {
      padding: 0px;
      margin: 0;
    }
  }

  position: relative;
  background-color: $white;
  padding: 0px;
  margin: auto;
  height: 90px;
  @include sm {
    width: auto;
    margin-bottom: 0;
    margin-top: 0;
    display: inline-table;
  }
}
</style>
