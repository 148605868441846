import {workService} from "@/services";
import {errorFormat} from "@/utils/helper";

export default {
  namespaced: true,
  state: {
    workType: null,
  },
  actions: {
    async getWorkType({ commit }, data) {
      try {
        const response = await workService.getWorkType(data);
        commit('SET_WORK_TYPE', response.data.data);
      } catch (e) {
        if (e.response != null)
          throw errorFormat(e.response.data);
        console.warn(e);
      }
    },
  },
  mutations: {
    SET_WORK_TYPE(state, value){
      state.workType = value;
    }
  },
  getters: {
    workType(state) {
      return state.workType
    }
  },
}