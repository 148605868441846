<template>
  <v-container>
    <form class="auth-form" @submit.prevent="submit">
      <div class="title">Добро пожаловать на Gigoo</div>
      <div>
        <base-input
          :is-white="mobile"
          v-model="form.email"
          :placeholder="$t('name.e-mail')"
          type="email"
          :is-error="v$.form.email.$error"
          :class="{ error: v$.form.email.$error }"
          :error="[
            {
              text: 'E-mail обязателен',
              status: v$.form.email.required.$invalid,
            },
            {
              text: 'некорректный E-mail',
              status: v$.form.email.email.$invalid,
            },
          ]"
        />
      </div>
      <div>
        <base-input
          :is-white="mobile"
          v-model="form.password"
          :placeholder="$t('name.password')"
          type="password"
          :is-error="v$.form.password.$error || signInError != null"
          :error="[
            {
              text: 'пароль обязателен',
              status: v$.form.password.required.$invalid,
            },
            {
              text: signInError != null ? signInError : 'неизвестная ошибка',
              status: signInError != null && v$.form.password.$error === false,
            },
          ]"
        />
      </div>
      <div class="auth-form__top">
        <a @click="resetPassword" class="auth-form__cant">{{
          $t("router.reset-password")
        }}</a>
      </div>
      <div class="buttons">
        <base-button type="submit">{{ $t("btn.login") }}</base-button>
        <base-button href="/sign-up" class="button--outlined reg" outlined>
          Зарегистрироваться
        </base-button>
      </div>
    </form>
  </v-container>
  <reset-password-modal ref="resetPasswordModal"></reset-password-modal>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/BaseInput";
import VerificationModal from "@/components/modals/VerificationModal";
import AlertModal from "@/components/modals/AlertModal";
import { mapGetters } from "vuex";
import SizedBox from "@/components/ui/SizedBox";
import LsUtil from "@/utils/ls";
import { LS_SOCIAL_URL } from "@/utils/ls/keys";
import { email, maxLength, minLength, required, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import ResetPasswordModal from "@/components/modals/ResetPasswordModal";
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "AuthPage",
  components: {
    ResetPasswordModal,
    SizedBox,
    AlertModal,
    BaseInput,
    BaseButton,
    VerificationModal,
    SvgIcon,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isResetPassword: false,
      isAlert: false,
      signInError: null,
      form: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      error: "auth/error",
      socials: "auth/socials",
      isAuth: "auth/isAuth",
      user: "auth/user",
      getUserState: "auth/getUserState",
    }),
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
  created() {
    if (this.isAuth) {
      this.$router.replace({ name: "ProfilePage" });
    }
    this.getSocial();
  },
  methods: {
    async getSocial() {
      await this.$store.dispatch("auth/social");
    },
    resetPassword() {
      this.$refs.resetPasswordModal.isResetPassword = true;
    },
    redirectUrl(link, callbackLink) {
      LsUtil.setItem(LS_SOCIAL_URL, callbackLink);
      window.open(link, "_blank");
    },
    async submit() {
      this.v$.form.email.$touch();
      this.v$.form.password.$touch();
      const result = !this.v$.form.email.$invalid || !this.v$.form.password.$invalid;
      if (!result) {
        return;
      }
      try {
        this.signInError = null;
        await this.$store.dispatch("auth/signIn", this.form);
      } catch (e) {
        console.log(e.response.data);
        this.signInError = e.response.data.message;
      }
      if (this.signInError == null) {
        await this.$store.dispatch("auth/getUserData");
        if (this.user.isVerified) {
          this.$router.replace({ name: "ProfilePage" });
        } else {
          this.$router.replace({ name: "VerifyMock" });
        }
      }
    },
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.soc-button {
  cursor: pointer;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  gap: 20px;
  padding-top: 0 10px;
}
.title {
  @include desktop_h2_norm;
  font-size: 20px;
  display: inline-flex;
  text-align: center;
  padding: 30px 0px 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
  @include sm {
    text-align: center;
    padding-bottom: 24px;
  }
}
.reg {
  margin: 10px 0px 10px 0px;
  align-content: center;
  @include sm {
    margin: 12px 0px 24px 0px;
  }
}
.line {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid #e1e1e1;
  text-align: center;
  vertical-align: center;
  position: relative;
}
.line-text {
  background-color: white;
  color: #a0a0a0;
  text-align: right;
  font-family: SFProDisplay;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  top: 5px;
  padding: 0 10px;
  position: relative;
  @include sm() {
    background-color: #eee;
  }
}

.auth-form {
  max-width: 395px;
  width: 100%;
  background-color: #fff;
  margin: 40px auto 25px auto;
  border-radius: 24px;
  padding: 30px;
  @include sm() {
    background-color: #eee;
  }
  &__social-box {
    margin-top: 18px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
    @include sm() {
      margin-top: 24px;
    }
  }
  &__send {
    font-family: "SFProDisplay";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    @include sm() {
      margin-bottom: 12px;
    }
  }

  &__cant {
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    margin-left: auto;
    color: #a0a0a0;
    text-align: right;
    font-family: SFProDisplay;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.buttons {
  margin-top: 0px;
  @include sm() {
    margin-top: 0px;
  }
}
</style>
