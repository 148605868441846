const LS_REFRESH_TOKEN = 'refresh_token'
const LS_PREVIOUS_TOKEN = 'previous_token'
const NOT_ME = false
const LS_MAIL_OK = 0
const LS_USER_LANG = 'user_lang'
const LS_SOCIAL_URL = 'social_url'
const LS_USER_STATE = 'customer'
const LS_STATUS = 'status_time'
const LS_SOC_REG = 'soc_reg'
const LS_PAGE = 'pages'

export { LS_PAGE, LS_REFRESH_TOKEN, LS_SOCIAL_URL, LS_USER_LANG, LS_USER_STATE, LS_PREVIOUS_TOKEN, LS_MAIL_OK, NOT_ME, LS_STATUS, LS_SOC_REG }