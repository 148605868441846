<template>
  <div class="container">
    <main-block
      :images="main_images"
      :cards="taskcards_main"
      :title="main_title"
      :sub-title="main_subtitle"
      :button-title="button_title"
      :with-experts-subtitle="false"
      is-task
    ></main-block>
  </div>

  <!--<div class="company">
    <company-scroller :images="company_logos" />
  </div>-->

  <div class="block-points" id="whoweare">
    <div class="container">
      <h1 class="title">Простое и удобное сотрудничество</h1>
      <points-block :points="points" is-big></points-block>
    </div>
  </div>

  <div class="block-task">
    <div class="container">
      <h1 class="title">Примеры действующих проектов</h1>
      <task-block-expert :cards="taskcards" />
      <div class="action">
        <h1>И еще сотни задач ждут вас после регистрации</h1>
        <base-button
          class="button--success main-button"
          @click="this.$router.push({ name: 'RegistrationPage' })"
          >Стать экспертом</base-button
        >
      </div>
    </div>
  </div>
  <div class="block-progress-background">
    <div class="block-progress">
      <div class="container">
        <progress-block
          :title="progress_title"
          :sub-title="progress_subTitle"
          :points="progress_points"
        ></progress-block>
      </div>
    </div>
  </div>
  <div class="container" id="industries">
    <h1 class="title">Ищем экспертов по сотням направлений</h1>
    <competence-block :cards="competence" />
  </div>

  <div class="block-green">
    <div class="container">
      <pre-footer-block-expert></pre-footer-block-expert>
    </div>
  </div>
</template>

<script>
import TagsBlock from "@/views/startpage/blocks/TagsBlock";
import TaskBlockExpert from "@/views/startpage/blocks/TaskBlockExpert";
import ProgressBlock from "@/views/startpage/blocks/ProgressBlock";
import PreFooterBlockExpert from "@/views/startpage/blocks/PreFooterBlockExpert";
import PointsBlock from "@/views/startpage/blocks/PointsBlock";
import ExpertBlock from "@/views/startpage/blocks/ExpertBlock";
import CompetenceBlock from "@/views/startpage/blocks/CompetenceBlock";
import CompanyScroller from "@/views/startpage/components/CompanyScroller";
import MainBlock from "@/views/startpage/blocks/MainBlock";
import SolarSystem from "@/views/startpage/components/SolarSystem";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "StartPage",
  components: {
    TagsBlock,
    TaskBlockExpert,
    ProgressBlock,
    PointsBlock,
    CompanyScroller,
    SolarSystem,
    MainBlock,
    PreFooterBlockExpert,
    ExpertBlock,
    BaseButton,
    CompetenceBlock,
  },
  props: {
    main_title: {
      type: String,
      default: "Реализуйте свою экспертизу и опыт с теми, кому это нужно",
    },
    main_subtitle: {
      type: String,
      default: "Более 2000 задач по направлениям",
    },
    button_title: {
      type: String,
      default: "Стать экспертом",
    },
    company_logos: {
      type: Array,
      default: [
        "companies/company_logo1.png",
        "companies/company_logo2.png",
        "companies/company_logo3.png",
        "companies/company_logo4.png",
        "companies/company_logo5.png",
        "companies/company_logo6.png",
        "companies/company_logo7.png",
        "companies/company_logo8.png",
        "companies/company_logo9.png",
        "companies/company_logo10.png",
        "companies/company_logo11.png",
        "companies/company_logo12.png",
        "companies/company_logo13.png",
        "companies/company_logo14.png",
        "companies/company_logo15.png",
      ],
    },
    points: {
      type: Array,
      default: [
        {
          task: "Только релевантные проекты",
          solution:
            "Получайте задачи под свои компетенции, выбирайте интересные проекты и клиентов",
          img: "point6",
        },

        {
          task: "Гибкий формат работы",
          solution: "Выбирайте, где, когда и сколько вы работаете",
          img: "point7",
        },
        {
          task: "Гарантия оплаты",
          solution:
            "Заказчик платит за работу в самом начале, деньги поступают на ваш счет по окончании проекта",
          img: "point5",
        },
      ],
    },
    progress_title: {
      type: String,
      default: "Реализуйте проект на Gigoo",
    },
    progress_subTitle: {
      type: String,
      default: "в 3 простых шага",
    },
    progress_points: {
      type: Array,
      default: [
        {
          title: "Зарегистрируйтесь",
          subtitle:
            "Выберите свою индустрию и специализацию на этапе регистрации – Gigoo начнет подбор проектов в этот же момент",
        },
        {
          title: "Откликнитесь на интересные проекты",
          subtitle: "Загрузите резюме и расскажите о своем релевантном опыте и кейсах",
        },
        {
          title: "Обсудите детали с клиентом и начинайте работу",
          subtitle:
            "Согласуйте параметры задачи на звонке с клиентом, выполняйте работу и получайте гарантированную оплату по ходу выполнения проекта",
        },
      ],
    },

    taskcards_main: {
      type: Array,
      default: [
        {
          task: "Повышение организационной эффективности крупного холдинга",
          description: "Клиент Gigoo",
          name: "Валерия Ш.",
          period: "4 месяца",
          photo: "experts/exp13.jpg",
          payment: "13 500 000 ₽",
          chips: ["E-commerce", "Retail"],
          isMain: true,
          position: 0,
          isActive: true,
        },
        {
          task: "Консультация по операционной модели для краудфандингового фонда",
          description: "Клиент Gigoo",
          name: "Наталья С.",
          period: "до 5 консультаций",
          photo: "experts/exp14.jpg",
          payment: "7000 ₽ / час",
          chips: ["Инвестиционная деятельность"],
          isMain: true,
          position: 1,
          isActive: false,
        },
        {
          task: "Реализация и развертывание системы ценообразования",
          description: "Клиент Gigoo",
          name: "Владимир Г.",
          period: "6 месяцев",
          photo: "experts/exp15.jpg",
          payment: "25 000 000 ₽",
          chips: ["Фармацевтика"],
          isMain: true,
          position: 2,
          isActive: false,
        },
        {
          task: "Внедрение методологии учета затрат в 1C: ERP для агро-предприятия",
          description: "Клиент Gigoo",
          name: "Александра М.",
          period: "3 месяца",
          photo: "experts/exp16.jpg",
          payment: "2 500 000 ₽",
          chips: ["Сельское хозяйство", "Производство потребительских товаров"],
          isMain: true,
          position: 3,
          isActive: false,
        },
        {
          task: "Менеджер проектов в ритэйл-проект для улучшения операционных процессов ",
          description: "Клиент Gigoo",
          name: "Роман Т.",
          period: "2 месяца",
          photo: "experts/exp17.jpg",
          payment: "450 000 ₽",
          chips: ["Retail", "E-commerce"],
          isMain: true,
          position: 4,
          isActive: false,
        },
      ],
    },

    taskcards: {
      type: Array,
      default: [
        {
          task: "Консультация по улучшению CVP и омниканального клиентского опыта",
          description: "Клиент Gigoo",
          name: "Артем Р.",
          period: "до 12 консультаций",
          photo: "experts/exp6.jpg",
          payment: "5 000 ₽/ час",
          chips: ["E-commerce", "Розничная торговля"],
          isMain: true,
          position: 0,
          isActive: true,
        },
        {
          task: "Разработка финансовой модели для крупного ритейлера",
          description: "Клиент Gigoo",
          name: "Артур П.",
          period: "1,5 месяца",
          photo: "experts/exp7.jpg",
          payment: "2 500 000 ₽",
          chips: ["Розничная торговля", "E-commerce"],
          isMain: true,
          position: 1,
          isActive: false,
        },
        {
          task: "Менеджер проектов в фарма-проект по запуску БАДов",
          description: "Клиент Gigoo",
          name: "Полина К.",
          period: "1 месяц",
          photo: "experts/exp9.jpg",
          payment: "450 000 ₽",
          chips: ["Фармацевтика", "Медицина"],
          isMain: true,
          position: 2,
          isActive: false,
        },
        {
          task:
            "Повышение операционной эффективности органического производства продукции",
          description: "Клиент Gigoo",
          name: "Анна С.",
          period: "1,5 месяца",
          photo: "experts/exp12.jpg",
          payment: "4 000 000 ₽",
          chips: ["Сельское хозяйство"],
          isMain: true,
          position: 3,
          isActive: false,
        },
        {
          task: "Анализ IT инфраструктуры и автоматизация отчетности",
          description: "Клиент Gigoo",
          name: "Екатерина Ц.",
          period: "1 месяц",
          photo: "experts/exp10.jpg",
          payment: "1 050 000 ₽",
          chips: ["Производство потребительских товаров"],
          isMain: true,
          position: 4,
          isActive: false,
        },
        {
          task: "Разработка стратегии для компании в сегменте fashion-ритэйл",
          description: "Клиент Gigoo",
          name: "Михаил Г.",
          period: "2 месяца",
          photo: "experts/exp11.jpg",
          payment: "2 100 000 ₽",
          chips: ["Розничная торговля", "Услуги для бизнеса"],
          isMain: true,
          position: 5,
          isActive: false,
        },
      ],
    },
    competence: {
      type: Array,
      default: [
        {
          icon_name: "comp4",
          name: "Стратегия, Развитие бизнеса, M&A",
          chips: [
            "Корпоративная стратегия",
            "Корпоративная структура",
            "Рыночная аналитика и исследования",
            "Развитие ценностного предложения (CVP)",
            "Управление проектами",
            "Управление инновациями и инновационное развитие",
            "Управление изменениями",
            "Due diligence",
            "Оценка бизнеса",
            "Сопровождение M&A",
          ],
        },
        {
          icon_name: "comp11",
          name: "Клиентский опыт и лояльность",
          chips: [
            "Сегментация клиентов",
            "Развитие клиентского опыта (CJM)",
            "Клиентская аналитика",
            "Лояльность и Customer Value Management",
          ],
        },
        {
          icon_name: "comp7",
          name: "Продажи",
          chips: [
            "Стратегия продаж",
            "Построение системы продаж B2C",
            "Построение системы продаж B2B",
            "Построение системы продаж B2G",
            "Управление доходностью (Revenue management)",
          ],
        },
        {
          icon_name: "comp10",
          name: "Коммерция",
          chips: [
            "Коммерческая стратегия",
            "Коммерческие закупки",
            "Некоммерческие закупки",
            "Управление пулом поставщиков",
            "Управление ассортиментом",
            "Управление ценообразованием",
            "Управление промо",
          ],
        },
        {
          icon_name: "comp3",
          name: "Маркетинг",
          chips: [
            "Маркетинговая стратегия",
            "Эффективность маркетинга",
            "Цифровой маркетинг",
            "Бренд маркетинг",
            "Trade маркетинг",
            "PR и коммуникации",
          ],
        },
        {
          icon_name: "comp8",
          name: "Операционная эффективность",
          chips: [
            "Операционная эффективность",
            "Хронометражи и нормирование операций",
            "Эффективность бизнес-процессов",
            "Техническое обслуживание и ремонты",
          ],
        },
        {
          icon_name: "comp6",
          name: "Цепочка поставок",
          chips: [
            "Стратегия цепочки поставок",
            "Развитие логистической инфраструктуры",
            "Транспортная логистика",
            "Складская логистика",
            "Управление товарными запасами",
            "Внешнеэкономическая деятельность (ВЭД)",
          ],
        },
        {
          icon_name: "comp2",
          name: "Управление персоналом",
          chips: [
            "Организационное развитие",
            "Оптимизация системы управления",
            "Оптимизация фонда оплаты труда",
            "Оценка персонала",
            "Разработка КПЭ и мотивации",
            "Рекрутмент",
            "Развитие и обучение персонала",
            "Развитие корпоративной культуры",
            "Кадровое делопроизводство",
          ],
        },
        {
          icon_name: "comp12",
          name: "Планирование и бюджетирование",
          chips: [
            "Бизнес планирование",
            "Ассортиментное планирование",
            "Планирование продаж, закупок и запасов",
            "Планирование производства",
            "Интегрированное планирование (S&OP, IBP)",
            "Планирование персонала",
            "Планирование операционных расходов",
            "Планирование инвестиционных проектов",
          ],
        },
        {
          icon_name: "comp5",
          name: "Финансы, налоги, риски",
          chips: [
            "Финансовое моделирование",
            "Финансовая и управленческая отчетность",
            "Финансовый и управленческий контроллинг",
            "Оптимизация рабочего капитала",
            "Налоговое планирование",
            "Оптимизация налогообложения",
            "Бухгалтерский учет и аудит",
            "Внутренний аудит",
            "Управление рисками",
          ],
        },
        {
          icon_name: "comp1",
          name: "Цифровизация",
          chips: [
            "Стратегия цифровизации",
            "Развитие ИТ инфраструктуры",
            "Развитие ИТ архитектуры",
            "Развитие ИТ продуктов и систем",
            "Управление продуктами",
            "Продуктовая разработка",
            "Продуктовая аналитика",
            "Управление большими данными",
            "Реализация data driven продуктов",
          ],
        },

        {
          icon_name: "comp9",
          name: "Юридическое сопровождение",
          chips: [
            "Договорная работа",
            "Исковая - претензионная работа",
            "Корпоративные вопросы",
            "Налоговое консультирование",
            "Вопросы связанные с административным правом",
            "Интеллектуальная собственность",
            "Юридическая поддержка бизнеса",
          ],
        },
      ],
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0px auto 0px;
  padding: 30px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include md {
    margin: 0px;
    width: 100%;
    padding: 0 30px;
    padding-bottom: 30px;
  }
}

.company {
  width: 100%;
  position: relative;
  display: flex;
  padding-top: 60px;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: center;
  @include md {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.block-points {
  background-color: #ffffff;
  border-radius: 40px 40px 0px 0px;
}

.block-progress {
  background: #3d3e49;
  border-radius: 40px;
  @include sm {
    margin-bottom: 30px;
  }
}

.block-task {
  background: #eee;
  border-radius: 40px 40px 0px 0px;
}
.block-progress-background {
  background: #eee;
  border-radius: 0px 0px 40px 40px;
}

.block-green {
  background: linear-gradient(90deg, #6fb057 0%, #86cd6d 68.92%);
  border-radius: 40px;
}

.action {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 30px;
  padding-top: 60px;
  @include sm {
    flex-direction: column;
    color: var(--text, #000);
    text-align: center;
    font-family: Muller;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 100% */
    letter-spacing: -0.52px;
    padding-bottom: 20px;
    padding-top: 40px;
  }
}

.main-button {
  width: 285px;
  @include sm {
    margin-top: 30px;
    width: 100%;
  }
}

.block-green-background {
  background: #3d3e49;
  border-radius: 0px 0px 40px 40px;
}

.title {
  font-family: Muller;
  color: var(--text, #000);
  font-weight: 500;
  font-size: 50px;
  text-align: center;
  margin: 30px 0px;
  margin-bottom: 60px;
  width: 80%;
  &.titleMobile {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  @include md {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-style: normal;
    line-height: 30px; /* 100% */
    letter-spacing: -0.6px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0px;
  }
}
</style>
