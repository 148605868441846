<template>
  <alert-modal ref="alert"> ок </alert-modal>
</template>

<script>
import AlertModal from "@/components/modals/AlertModal";
import { mapGetters } from "vuex";
export default {
  name: "BaseStatusPixel",
  components: { AlertModal },
  data() {},
  mounted() {
    this.fillStatus();
  },
  computed: {
    ...mapGetters({
      responseStatus: "status/responseStatus",
      visitTime: "status/visitTime",
      responseAll: "request/responseAll",
      requestList: "request/requestList",
    }),
  },
  watch: {
    $route(to, from) {
      if (from.fullPath === "/request-add");
      this.fillStatus();
    },
  },
  methods: {
    async fillStatus() {
      await this.initTimes();
      await this.getRequestList();
      await this.getAllRespond();
      await this.setStatusResponse();
    },
    async initTimes() {
      await this.$store.dispatch("status/initVisitTimes");
    },

    async getRequestList() {
      try {
        await this.$store.dispatch("request/getRequestList");
      } catch (e) {
        console.log("getRequestList ERROR");
      }
    },

    async getAllRespond() {
      try {
        await this.$store.dispatch("request/getAllRespond");
      } catch (e) {
        console.log("getAllRespond ERROR");
      }
    },
    counter(d, key, last_time, as_bool, color) {
      if (!(key in d)) return { title: key, type: "" };
      const res = d[key].filter(
        (el) =>
          (el.status_updated_at == null ? last_time : el.status_updated_at) > last_time
      ).length;
      return { title: as_bool ? "new" : "+" + res, type: res > 0 ? color : "" };
    },

    counterRequests(d, key, as_bool, color) {
      if (!(key in d)) return { title: key, type: "" };
      const res = d[key].filter((el) => !el.is_viewed).length;
      return { title: as_bool ? "new" : "+" + res, type: res > 0 ? color : "" };
    },

    counterSearching(d, key, last_time, as_bool, color) {
      if (!(key in d)) return { title: key, type: "" };
      let list = d[key].map((el) => el.responds);
      if (!list.length) return { title: key, type: "" };
      let res = Array.prototype.concat.apply([], list).filter((el) => !el.is_viewed)
        .length;
      return { title: as_bool ? "new" : "+" + res, type: res > 0 ? color : "" };
    },

    marge(a, b) {
      let array = [b.type, a.type];
      array.sort(function (a, b) {
        return b.length - a.length;
      });
      return { title: a.title, type: array[0] };
    },
    async setStatusResponse() {
      try {
        //success,primary
        await this.$store.dispatch("status/initStatusResponse", {
          customer: {
            search: this.counterSearching(
              this.requestList,
              "searching",
              this.visitTime.customer.search,
              false,
              "success"
            ),
            active: this.counter(
              this.requestList,
              "active",
              this.visitTime.customer.active,
              true,
              "primary"
            ),
            completed: this.counter(
              this.requestList,
              "completed",
              this.visitTime.customer.active,
              true,
              "primary"
            ),
            expired: this.counter(
              this.requestList,
              "search_expired",
              this.visitTime.customer.expired,
              true,
              "primary"
            ),
          },
          executor: {
            invite: this.counterRequests(this.responseAll, "request", false, "success"),
            sent: this.marge(
              this.counter(
                this.responseAll,
                "sent",
                this.visitTime.executor.sent,
                true,
                "primary"
              ),
              this.counter(
                this.responseAll,
                "moderation",
                this.visitTime.executor.sent,
                true,
                "primary"
              )
            ),
            active: this.counter(
              this.responseAll,
              "active",
              this.visitTime.executor.active,
              true,
              "primary"
            ),
            completed: this.counter(
              this.responseAll,
              "completed",
              this.visitTime.executor.completed,
              true,
              "primary"
            ),
            rejected: this.counter(
              this.responseAll,
              "rejected",
              this.visitTime.executor.rejected,
              true,
              "primary"
            ),
          },
        });
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
  },
};
</script>
