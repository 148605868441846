import { serviceService } from "@/services";
import { errorFormat } from "@/utils/helper";

/**
 @typedef Services
 @type {Object}
 @property {number} id
 @property {string} name
 @property {number} user_id
 @property {string} user_name
 @property {string} format
 @property {string} maturity
 @property {string} status
 @property {string} performer_type Тип услуги от себя/от компании
 @property {null} rating
 @property {null} industries
 @property {Object} options
 */
export default {
    namespaced: true,
    state: {
        /**
         *  @type {Services[]}
         */
        service: [],
        services: [],
        expertises: [],
        serviceTypes: [],
        works: [],
        cases: [],
        specialization: [],
        workRoles: [],
        competence: [],
        serviceSpecializations: [],
        someOneCases: []
    },
    actions: {
        async getServices({ commit }, activeOnly) {
            try {
                const response = await serviceService.getServices(activeOnly);
                commit('SET_SERVICES', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getServicesExpertises({ commit }, { users: users, service: service }) {
            try {
                let listUser = '';
                if (users != null)
                    listUser = users.filter(v => v.type === 'user').map((v) => 'users[]=' + v.id)
                        .join("&")
                if (service != null) {
                    if (service.id != null)
                        listUser += listUser != '' ? '&service_id=' + service.id : 'service_id=' + service.id
                }
                const response = await serviceService.getServicesExpertises(listUser);
                commit('SET_EXPERTISES', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async postService({ commit }, data) {
            try {
                const response = await serviceService.postService(data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async postServiceId({ commit }, data) {
            try {
                await serviceService.postServiceId(data.id, data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async dellServices({ commit }, payload) {
            try {
                await serviceService.dellServices(payload);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getServiceById({ commit }, payload) {
            try {
                const data = await serviceService.getServiceById(payload);
                commit('SET_SERVICE', data.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async hideServices({ commit }, payload) {
            try {
                await serviceService.hideServices(payload);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getServiceTypes({ commit }, payload) {
            try {
                const data = await serviceService.getServiceTypes(payload);
                commit('SET_SERVICE_TYPES', data.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async postServicesParticipate({ commit }, payload) {
            try {
                await serviceService.postServicesParticipate(payload);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getWorksList({ commit }) {
            try {
                const response = await serviceService.getWorksList();
                commit('SET_WORKS', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getCasesList({ commit }) {
            try {
                const response = await serviceService.getCasesList();
                commit('SET_CASES', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getSomeoneCasesList({ commit }, id) {
            try {
                const response = await serviceService.getSomeoneCasesList(id);
                commit('SET_OTHER_CASES', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },

        async dellWorkItem({ commit }, id) {
            try {
                await serviceService.deleteWorkItem(id);
                const response = await serviceService.getWorksList();
                commit('SET_WORKS', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },

        async deleteCase({ commit }, id) {
            try {
                await serviceService.deleteCase(id);
                const response = await serviceService.getCasesList();
                commit('SET_CASES', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },

        async getSpecialization({ commit }, payload = '') {
            try {
                const response = await serviceService.getSpecialization(payload);
                commit('SET_SPECIALIZATION', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getWorkRoles({ commit }) {
            try {
                const response = await serviceService.getWorkRoles();
                commit('SET_WORK_ROLES', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async getSpecializationsList({ commit }, id) {
            try {
                const response = await serviceService.getSpecializationsList(id);
                commit('SET_SERVICE_SPECIALISATIONS', response.data.data);
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
    },
    mutations: {
        SET_SERVICE(state, value) {
            state.service = value
        },
        SET_SERVICES(state, value) {
            state.services = value
        },
        SET_SERVICE_TYPES(state, value) {
            state.serviceTypes = value
        },
        SET_EXPERTISES(state, value) {
            state.expertises = value
        },
        SET_WORKS(state, value) {
            state.works = value
        },
        SET_OTHER_CASES(state, value) {
            state.someOneCases = value
        },
        SET_CASES(state, value) {
            state.cases = value
        },
        SET_SPECIALIZATION(state, value) {
            state.specialization = value
        },
        SET_WORK_ROLES(state, value) {
            state.workRoles = value
        },
        SET_SERVICE_SPECIALISATIONS(state, value) {
            state.serviceSpecializations = value
        },
    },
    getters: {
        service(state) {
            return state.service
        },
        services(state) {
            return state.services
        },
        serviceTypes(state) {
            return state.serviceTypes
        },
        someOneCases(state) {
            return state.someOneCases
        },
        expertises(state) {
            return state.expertises
        },
        works(state) {
            return state.works
        },
        cases(state) {
            return state.cases
        },
        specialization(state) {
            // return state.specialization
            let services = JSON.parse(JSON.stringify(state.specialization));
            return services.map(item => {
                item.id = `${item.industry_id}_${item.option_id}`;
                return item;
            })
        },
        workRoles(state) {
            return state.workRoles
        },
        competence(state) {
            return state.competence
        },
        serviceSpecializations(state) {
            return state.serviceSpecializations;
        },

    },
}