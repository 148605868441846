import ApiUtil from '@/utils/api'
import { debounce } from "@/utils/helper";
export default {
  getCompany: (data) =>
    ApiUtil(
      {
        url: `/api/companies${data != null ? '?name=' + data : ''}`,
        method: 'get',
        requiresAuth: false,
      }
    ),
  addCompany: (data) =>
    ApiUtil(
      {
        url: `/api/companies`,
        method: 'post',
        data,
        requiresAuth: true,
      }
    )
}
