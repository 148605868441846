<template>
  <two-cols-grig is-equal-main is-first>
    <template v-slot:title>
      <div class="title">{{ title }}</div>
      <experts-show v-if="withExpertsSubtitle" :images="images" class="experts" />
      <div v-else class="subtitle">
        <div class="experts-title">с гибкой загрузкой от 1 до 40 часов в неделю</div>
        <div class="experts-subtitle">в 800 областях компетенций</div>
      </div>
      <base-button
        @click="this.$router.push({ name: 'RegistrationPage' })"
        class="button--success main-button is-mobile"
      >
        {{ buttonTitle }}</base-button
      >
    </template>
    <template v-slot:body>
      <card-slider :cards="cards" class="is-mobile" :is-task="isTask"></card-slider>
      <div class="is-no-mobile" v-if="mobile">
        <card-slider-mobile :cards="cards" :is-task="isTask"></card-slider-mobile>
      </div>
    </template>
  </two-cols-grig>
</template>
<script>
import TwoColsGrig from "@/views/startpage/components/TwoColsGrig";
import ExpertsShow from "@/views/startpage/components/ExpertsShow";
import BaseButton from "@/components/base/BaseButton";
import CardSlider from "@/views/startpage/components/CardSlider";
import CardSliderMobile from "@/views/startpage/components/CardSliderMobile";
import Avatar from "@/components/ui/Avatar";
import ExpertCard from "@/views/startpage/cards/ExpertCard";
export default {
  name: "MainBlock",
  components: {
    TwoColsGrig,
    BaseButton,
    CardSlider,
    ExpertsShow,
    Avatar,
    ExpertCard,
    CardSliderMobile,
  },
  computed: {
    mobile() {
      return (
        this.$vuetify.display.md || this.$vuetify.display.sm || this.$vuetify.display.xs
      );
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    images: {
      type: Array,
      default: [],
    },
    cards: {
      type: Array,
      default: [],
    },
    withExpertsSubtitle: {
      type: Boolean,
      default: true,
    },
    isTask: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.title {
  font-size: 50px;
  font-family: Muller;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: -1.2px;
  color: $black;
  padding-bottom: 20px;
  @include md {
    width: 100%;
    font-size: 40px;
    line-height: 42px;
    text-align: center;
  }
}
.subtitle {
  @include desktop_standart;
  color: #a0a0a0;
  @include md {
    width: 100%;
    text-align: center;
  }
  @include sm {
    padding-bottom: 20px;
  }
}

.main-button {
  width: 285px;
  margin-top: 60px;
}

.is-no-mobile {
  display: none;

  @include md {
    display: flex;
    align-items: center;
    width: 100%;
    height: 350px;
    padding: 10px;
  }
  @include sm {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
  }
}
.experts {
  @include md {
    padding-bottom: 30px;
  }
}
.is-mobile {
  display: inline-block;

  @include sm {
    display: none;
  }

  @include md {
    display: none;
  }
}

.experts-title {
  padding-top: 16px;
  font-size: 24px;
  font-family: Muller;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: $black;
}

.experts-subtitle {
  @include desktop_standart;
  font-size: 20px;
  color: #a0a0a0;
}
</style>
