<template>
  <div class="question-answer">
    <base-input
        placeholder="Поиск по вопросам"
        class="question-answer__search"
        v-model="searchText"
        search
    />
    <div class="questions-block">
      <div class="questions-block-item" v-for="(question, index) in questionsFilter" :key="'questionBlock' + index">
        <div class="question"
             v-on:click="currentQuestion === index ? currentQuestion = null : currentQuestion = index">
          <span>{{ question.question }}</span>
          <svg-icon
            width="19"
            height="19"
            name="arrow-down"
            :class="currentQuestion === index ? 'arrow-flipover' : 'arrow-flipover-before'"
          ></svg-icon>
        </div>
        <transition name="fade">
          <div class="answer mt-3" v-if="currentQuestion === index">
            {{ question.answer }}
          </div>
        </transition>
      </div>
      <div class="note-search" v-if="questionsFilter.length <= 0">
        <div class="icon">
          <svg-icon name="info"></svg-icon>
        </div>
        <div class="text">
          Ничего не найдено. <br>
          Обратитесь в службу технической поддержки
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import BaseInput from "@/components/base/BaseInput";
import {mapGetters} from "vuex";
// import {mapGetters} from "vuex";

export default {
  name: "QuestionAnswer",
  components: {
    SvgIcon,
    BaseInput
  },
  data: () => ({
    currentQuestion: null,
    searchText: ''
    // items: []
  }),
  created() {
    this.getQuestionsAnswers();
  },
  computed: {
    ...mapGetters({
      questions: "questions/questions",
    }),
    questionsFilter() {
      if (this.searchText == null || this.searchText === '')
        return this.questions;
      return this.questions.filter(item => item.question
          .toString()
          .toLowerCase()
          .includes(this.searchText.toString().toLowerCase()) || item.answer
          .toString()
          .toLowerCase()
          .includes(this.searchText.toString().toLowerCase())
      );
    }
  },
  methods: {
    showModalHelp() {
      this.$emit('showModalHelp')
    },
    async getQuestionsAnswers() {
      try {
        await this.$store.dispatch('questions/getQuestions');
      } catch (e) {
        console.log(e);
      }

    }
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';
.question-answer {
  &__search {
    margin-top: 30px;
    margin-bottom: 24px;
    @include md {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  -webkit-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.questions-block {
  .note-search {
    display: flex;
    margin-bottom: 20px;
    .icon {
      background: $line-light-gray;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }
    .text {
      @include desktop_additional;
      color: $accent;
      span {
        color: $green;
        cursor: pointer;
      }
    }
  }
}
.questions-block-item {
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 12px;
  padding: 10px 10px 10px 18px;
  margin-bottom: 12px;
  @include md {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 40px;
    }
  }
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.006em;
  color: #000000;
  cursor: pointer;
}

.answer {
  font-weight: 400;
  font-size: 16px;
  line-height: 124%;
  color: #4B4C57;
}

.arrow-flipover-before {
  -webkit-transform: rotate(0deg);
  flex-shrink: 0;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  -o-transition: transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}

.arrow-flipover {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  flex-shrink: 0;
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  -o-transition: transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}
</style>