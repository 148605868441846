<template>
  <div v-if="isToggle" class="container">
    <base-input
      v-model="user_status"
      placeholder="Введите статус"
      type="textarea"
      :is-error="v$.user_status.$error"
      :error="[
        {
          text: 'Максимальная длина - 300 символов',
          status: v$.user_status.maxLength.$invalid,
        },
      ]"
    />
    <base-button @click="save()" small class="button--light"> Сохранить </base-button>
  </div>
  <div v-if="!isToggle" class="container">
    <p v-if="!isSomeOne" @click="toggle()" class="description">
      {{ status }}
    </p>

    <div v-if="isSomeOne && status.length" class="description" style="cursor: unset">
      {{ status }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgIcon from "@/components/base/SvgIcon";
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/BaseInput";
import useVuelidate from "@vuelidate/core";
import { maxLength } from "@vuelidate/validators";
export default {
  name: "StatusBlock",
  components: { BaseButton, BaseInput, SvgIcon },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isToggle: false,
      user_status: "",
    };
  },
  created() {
    this.user_status = !!this.$route.params.id ? this.user.status : this.someUser.status;
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      user_status: {
        maxLength: maxLength(300),
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      someUser: "profile/someUser",
    }),
    isSomeOne() {
      return !!this.$route.params.id;
    },
    status() {
      let text = !!this.$route.params.id
        ? this.someUser.status
          ? this.someUser.status
          : ""
        : this.user.status
        ? this.user.status
        : "Добавить статус";
      let splitText = text.split("\n");
      return splitText.length > 4 ? splitText.slice(0, 4).join("\n") + "\n..." : text;
    },
  },
  methods: {
    toggle() {
      this.isToggle = !this.isToggle;
      this.user_status = this.user.status;
    },

    async save() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      this.isToggle = !this.isToggle;
      this.$emit("update:modelValue", this.user_status);
      this.sendStatus(this.user_status);
    },
    async sendStatus(v) {
      try {
        await this.$store.dispatch("registration/sendUserStatus", { text: v });
        this.$store.dispatch("auth/getUserData");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.container {
  padding: 14px 0;
  margin-right: auto;
}

.description {
  cursor: pointer;
  @extend %text-standart;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 500;
  color: $input-label;
  @include sm {
    color: white;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 106.667% */
  }
}
.status {
  cursor: text;
}

.someone_status {
  white-space: pre-wrap;
  overflow: hidden;
  padding: 10px 20px;
  background-color: $dark-gray;
  font-size: 18px !important;
  padding-left: 2px !important;
  text-align: left !important;
  display: block;

  @include md {
    text-align: center !important;
  }

  @include sm {
    text-align: center !important;
  }
}
</style>
