<template>
  <div class="input-container__block">
    <p v-if="header" class="input-container__header">{{ header }}</p>

    <div class="input-container__body">
      <div
        class="input-container"
        :class="[
          {
            'input-wrapper__error': isError,
          },
          isWhite ? 'white' : 'grey',
        ]"
        @click="eventClick"
      >
        <div class="input-container__title" :class="{ placeholder: !title }">
          {{ title ? title : placeholder }}
        </div>
        <div class="btn">
          <svg-icon width="22" height="22" name="arrow-down" />
        </div>
      </div>
      <div
        v-if="(remove && title) || isShow"
        class="input-container__remove"
        @click="handleRemove"
      >
        <svg-icon :width="21" :height="21" name="dell" />
      </div>
    </div>

    <div v-if="isError" class="input-error">{{ errorString }}</div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "BaseCallModal",
  components: { SvgIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Плейсхолдер",
    },
    remove: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    order: {
      type: Number,
      default: null,
    },
  },
  methods: {
    handleRemove() {
      this.$emit("onRemove");
    },
    eventClick() {
      this.$emit("eventClick");
    },
  },
  computed: {
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
    isShow() {
      if (this.order > 0) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.input-error {
  color: #ef693f;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}

.grey {
  background: $bg-light-gray;
}
.input-wrapper__error {
  border-radius: 12px;
  border: 1px solid $error;
}
.white {
  background: white;
}
.input-container {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 20px;
  border-radius: 12px;
  flex-grow: 1;
  min-height: 56px;
  height: auto;
  overflow: hidden;

  &__body {
    display: flex;

    &.error {
      border: 1px solid #ef693f;
      border-radius: 8px;
    }
  }

  &__title {
    @extend %text-standart;
    max-height: 100%;
    overflow: hidden;
    @include sm {
      font-size: 15px;
    }

    &.placeholder {
      @extend %desktop_standart;
      opacity: 0.5;
      color: $text-gray;
      @include sm() {
        font-family: SFProDisplay;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 123.077% */
        letter-spacing: 0.26px;
      }
    }
  }

  &__header {
    @include desktop_description;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    background-color: $bg-light-gray;
    border-radius: 12px;
    margin-left: 16px;
    cursor: pointer;
    flex-shrink: 0;
  }

  .btn {
    transform: rotate(-90deg);
    margin-left: auto;
  }
}
</style>
