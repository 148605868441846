<template>
  <div class="case-experience">
    <div v-if="cases.length | !isSomeOne" class="title">
      <h2 class="information__header">
        {{ title }}
        <p v-if="isError" class="input-error">{{ errorString }}</p>
      </h2>
      <add-button
        v-if="cases.length && !isSomeOne"
        class="desktop button--big-left"
        @click.native="openCaseModal"
        >Добавить</add-button
      >
    </div>
    <div class="body">
      <info-box-add v-if="!cases.length && !isSomeOne" icon="aim" class="btn-width">
        <template v-slot:title>Добавьте ваши реализованные проекты</template>
        <template v-slot:description>
          Кейсы характеризуют вас как профессионала и являются важными критериями выбора
          для заказчика
        </template>
        <template v-slot:action>
          <base-button class="action-info" @click="openCaseModal"
            >Добавить кейс</base-button
          >
        </template>
      </info-box-add>

      <block-list
        :items="cases"
        @dell="dell"
        @edit="changeCase"
        @watch="changeCase"
        @check="getChecked"
        :edit-button="isEdit"
        :dell-button="isDell"
        :check-button="isCheck"
        :watch-button="isWatch"
        with-pin
      ></block-list>

      <add-button
        v-if="cases.length && !isSomeOne"
        class="mobile button--big-left"
        @click.native="openCaseModal"
        >Добавить</add-button
      >

      <alert-modal ref="dell">
        <template v-slot:button>
          <base-button
            @click="() => $refs.dell.closeModal()"
            class="button--light modal__btn"
            >Отмена</base-button
          >
        </template>
      </alert-modal>
      <alert-modal ref="alert"></alert-modal>

      <add-case-modal
        ref="case-modal"
        @close="hideAddCaseModal"
        @add-case="addCase"
        :only-show="isSomeOne"
      />
    </div>
  </div>
</template>

<script>
import InfoBoxAdd from "@/components/ui/InfoBoxAdd";
import BaseButton from "@/components/base/BaseButton";
import AddButton from "@/components/ui/button/AddButton";
import SvgIcon from "@/components/base/SvgIcon";
import AlertModal from "@/components/modals/AlertModal";
import { mapGetters } from "vuex";
import AddCaseModal from "@/components/modals/AddCaseModal";
import BlockList from "@/components/component/BlockList";
export default {
  name: "CaseList",
  components: {
    AddCaseModal,
    AlertModal,
    SvgIcon,
    AddButton,
    BaseButton,
    InfoBoxAdd,
    BlockList,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    cases: {
      type: Array,
      default: [],
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEdit: false,
      isDell: false,
      isCheck: false,
      isWatch: false,
      checked_ids: [],
    };
  },
  mounted() {
    if (this.$route.name == "ResponseRequestPage") {
      this.isCheck = true;
      this.isEdit = true;
    }
    if (this.$route.path == "/profile/information") {
      this.isEdit = true;
      this.isDell = true;
    }
    if (this.$route.path.endsWith("expert") || this.$route.name == "RequestListPage") {
      this.isWatch = true;
    }
  },
  computed: {
    ...mapGetters({
      isAuth: "auth/isAuth",
      caseItem: "profile/caseChangeItem",
    }),

    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(", ");
    },

    isSomeOne() {
      return this.$route.path.endsWith("expert") || this.$route.name == "RequestListPage";
    },
  },

  methods: {
    getChecked(v) {
      if (this.cases.length) {
        let id = this.cases[v["idx"]].id;
        this.checked_ids = this.checked_ids.filter((el) => el != id);
        if (v["select"]) this.checked_ids.push(id);
        this.$emit("update:modelValue", this.checked_ids);
      }
    },
    dell(v) {
      if (this.cases.length) {
        let item = this.cases[v["idx"]];
        this.$refs.dell.show(
          "Удалить кейс",
          `Вы уверены, что хотите удалить кейс (${item.name})?`,
          {
            type: "dell",
            nameButton: "Удалить",
            error: true,
          },
          () => {
            this.deleteCase(item.id);
          }
        );
      }
    },
    async deleteCase(id) {
      try {
        await this.$store.dispatch("service/deleteCase", id);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },

    openCaseModal(e) {
      this.$refs["case-modal"].showModal(e);
    },
    hideAddCaseModal() {
      this.$refs["case-modal"].hideModal();
    },
    async changeCase(v) {
      let item = this.cases[v["idx"]];
      try {
        this.$refs["case-modal"].showModal(item);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async addCase(e) {
      await this.$store.dispatch("profile/sendCase", e);
      await this.getCasesList();
      this.$refs["case-modal"].hideModal();
    },
    async getCasesList() {
      try {
        await this.$store.dispatch("service/getCasesList");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.information__header {
  margin-top: 24px;
  @include sm {
    margin-bottom: 0px;
  }
}

.case-experience {
  &__list {
    margin-top: 0px;
    margin-bottom: 44px;

    @include sm() {
      margin-bottom: 16px;
    }
  }
}

.btn-width {
  .button.button--default {
    width: 270px;

    @include md() {
      width: auto;
    }
  }
}

.button--light:hover {
  background-color: $bg-light-gray;
}

.button--light {
  background-color: transparent;
}

.modal__btn {
  margin-left: 16px;
}

.action-info {
  width: auto;

  @include sm {
    min-width: 256px;
    max-width: 256px;
  }
}

.body {
  display: grid;
  grid-template-columns: 1fr;
}
.case {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 4px;
  @include md {
    grid-template-columns: 1fr;
  }
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  @include sm {
    display: grid;
    padding-bottom: 0px;
    margin-bottom: 24px;
  }
}

.desktop {
  display: block;
  width: auto;
  @include sm {
    display: none;
  }
}

.mobile {
  display: none;
  @include sm {
    margin-top: 24px;
    display: block;
  }
}

.item:hover .dell {
  opacity: 1;
}

.dell {
  opacity: 0;

  @include md {
    opacity: 1;
  }

  transition: opacity 300ms;
  cursor: pointer;
}

.input-error {
  color: #ef693f;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}
</style>
