<template>
  <div v-if="isOpen & isLocalOpen" class="info-block">
    <div class="title">
      <div>
        <svg-icon name="tg_ad_block" class="tg-block" />
        <p class="title-text">Получайте {{ whichPage }} в Telegram</p>
      </div>
    </div>
    <div class="line">
      <div class="main-text">
        Получайте {{ whichPage }} на проекты через чат-бот в Telegram
      </div>
      <div class="arrow-mobile">
        <svg-icon name="arrow-big-right-white" class="mobile" />
      </div>
    </div>
    <div class="actions">
      <div class="button">Перейти</div>
      <!-- <p class="channel-name">@{{ link.split("/").slice(-1)[0] }}</p> -->
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import BaseButton from "@/components/base/BaseButton";
import { mapGetters } from "vuex";
export default {
  name: "InfoBlock",
  components: { SvgIcon, BaseButton },
  data() {
    return {
      isLocalOpen: true,
    };
  },
  computed: {
    ...mapGetters({
      footer: "content/footer",
      isAuth: "auth/isAuth",
      token: "auth/token",
      visitTime: "status/visitTime",
    }),
    whichPage() {
      if (this.$route.path == "/projects/request") {
        let whichPage = "заявки";
        return whichPage;
      }
      let whichPage = "отклики";
      return whichPage;
    },
    link() {
      if (this.footer) {
        return this.footer.tg_popup.url;
      }
      return "";
    },
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
    isOpen() {
      //console.log((Date.now() / 1000 - this.visitTime.tgpopup.close / 1000) / 60)
      return (
        (Date.now() / 1000 - this.visitTime.tgpopup.close / 1000) / 60 >
          config.VUE_TG_POPUP_EVERY_MIN && this.visitTime.tgpopup.action == 0
      );
    },
  },
  mounted() {
    this.initTimes();
  },
  methods: {
    async close(evevt_name) {
      this.isLocalOpen = false;
      let payload = {
        evevt_name: evevt_name,
      };
      const key = "tgpopup_" + evevt_name.split("_")[2];
      this.$store.dispatch("status/updateVisitTimes", key);
      this.$store.dispatch("status/dumpEvent", { data: payload });
      if (evevt_name == "tg_popup_action") {
        window.open(config["VUE_APP_API_URL"] + "/api/redirect/tg", "_blank");
      }
      await this.getTimes();
    },
    async getTimes() {
      await this.$store.dispatch("status/getVisitTimes");
    },
    async initTimes() {
      await this.$store.dispatch("status/initVisitTimes");
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.info-block {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  background: #f6f6f6;
  color: black;
  margin-top: 24px;

  @include sm {
    transform: rotate(0.128deg);
    padding: 17px 17px 15px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 18px;
    background: linear-gradient(100deg, #538cd1 9.58%, #496387 35.71%, #42434e 70.38%);
  }
}

.title {
  display: grid;
  justify-content: space-between;
  font-family: Muller;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 124%;
  width: 100%;

  @include sm {
    display: flex;
    padding-bottom: 8px;
    font-size: 15px;
    color: white;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0px;

  @include sm {
    padding-bottom: 0px;
  }
}

.title-text {
  display: none;
  @include sm() {
    display: inline-block;
    color: #fff;
    font-family: Muller;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%; /* 19.84px */
  }
}

.main-text {
  color: #1a1a1a;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.108px;
  font-family: SFProDisplay;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  padding-bottom: 15px;
  margin-top: 15px;

  @include sm() {
    word-wrap: break-word;
    white-space: normal;
    flex-wrap:wrap;
    display: block;
    padding-bottom: 0px;
    margin-top: 0px;
    color: #fff;
    font-size: 13px;
    line-height: 15px; /* 115.385% */
    letter-spacing: 0.39px;
  }
}
.line {
  @include sm() {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
}
.tg-block {
  display: inline;
  cursor: pointer;
  width: 35px;
  height: 35px;
  filter: invert(60%) sepia(33%) saturate(2866%) hue-rotate(188deg) brightness(89%)
    contrast(83%);

  @include sm() {
    display: inline-block;
    filter: brightness(0) invert(1);
    width: 21px;
    height: 21px;
    flex-shrink: 0;
    margin-right: 8px;
  }
}

.button {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 56px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: #548dd1;
  color: white;
  text-align: center;
  font-family: Muller;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;

  //line-height: 20px;
  @include sm() {
    display: none;
  }
}

.arrow-mobile {
  display: none;

  @include sm() {
    cursor: pointer;
    display: flex;
    min-width: 30px;
    height: 30px;
    border-radius: 10px;
    background: #ffffff33;
  }
}

.mobile {
  @include sm() {
    width: 30px;
    height: 30px;
    padding: 7px;
  }
}
</style>
