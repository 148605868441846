<template>
  <div class="base-copy-block">
    <a :href="getPrefix()" class="base-copy-block__title">{{ title }}</a>
    <p class="base-copy-block__btn" :class="{ active: isCopy }" @click="copyInfo">{{
        isCopy ? afterCopy : beforeCopy
      }}</p>
  </div>
</template>

<script>
export default {
  name: "BaseCopyBlock",
  props: {
    title: {
      type: String,
      default: ''
    },
    beforeCopy: {
      title: String,
      default: ''
    },
    afterCopy: {
      title: String,
      default: ''
    },
    phone: Boolean,
    email: Boolean,
  },
  data() {
    return {
      isCopy: false
    }
  },
  methods: {
    getPrefix() {
      if (this.phone)
        return 'tel:' + this.title.replace(/[^+\d]/g, '')
      if (this.email)
        return 'mailto:' + this.title;
    },
    copyInfo() {
      window.navigator.clipboard.writeText(this.title);
      this.isCopy = true;
      setTimeout(() => {
        this.isCopy = false;
      }, 5000)
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';

.base-copy-block {
  display: flex;
  align-items: center;
  padding-left: 4px;
  @extend %text-standart;

  @include sm() {
    display: grid;
  }

  &__btn {
    cursor: pointer;
    margin-left: auto;
    font-size: 16px;
    font-weight: 500;
    color: $green;

    @include sm() {
      margin-left: 0;
      margin-top: 12px;
    }

    &.active {
      color: $text-gray;
    }
  }
}
</style>
