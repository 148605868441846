<template>
  <div>
    <community-block title="Индустрии и функции" type="tribe" />
  </div>
</template>

<script>
import CommunityBlock from "@/components/component/CommunityBlock";
export default {
  name: "CommunityIndustryPage",
  components: {CommunityBlock},
}
</script>

<style scoped lang="scss">
</style>
