<template>
  <v-row :class="{'industry-full': full}" class="select-function">
    <v-col cols="12">
      <p class="mb-2 select-function__label">{{ $t('component.industry.select-function') }}</p>
      <v-row class="select-function__card" style="margin: 0">
        <v-col class="flex-grow-1 flex-shrink-1 pa-0">
          <base-dropdown
              :placeholder="$t('component.industry.select-function')"
              :list="mainFunctionList"
              v-model="functionItem"
              class="select-function__select"
              @update:model-value="updateSubList"
              :is-error="isError"
              :error="error"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="align-end pa-3 sub-function">
      <p class="mb-2 mt-1 select-function__label">{{ $t('component.industry.select-sub-function') }}</p>
      <base-dropdown
          :disabled="listSubFunction.length <= 0"
          :placeholder="'Выберите подфункцию'"
          :list="listSubFunction"
          v-model="subFunction"
          :is-error="isErrorTwo"
          :error="errorTwo"
          class="select-function__select"
      />
    </v-col>
  </v-row>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import BaseDropdown from "@/components/base/BaseDropdown";
import VueMultiSelect from "@/components/ui/VueMultiSelect";
import SizedBox from "@/components/ui/SizedBox";
import VueMultiSelectCheckBox from "@/components/ui/VueMultiSelectCheckBox";
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "SelectFunctionDropdown",
  components: {BaseButton, VueMultiSelectCheckBox, SvgIcon, BaseDropdown, VueMultiSelect, SizedBox},
  props: {
    full: Boolean,
    functionsList: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Object,
    },
    descriptionFunction: String,
    error: {
      type: Array,
      default() {
        return [{
          status: false,
          text: '',
        }]
      },
    },
    errorTwo: {
      type: Array,
      default() {
        return [{
          status: false,
          text: '',
        }]
      },
    },
    isError: {
      type: Boolean,
      default: false
    },
    isErrorTwo: {
      type: Boolean,
      default: false
    },
  },
  created() {
    if (this.modelValue != null) {
      if (this.modelValue.id != null) {
        this.listSubFunction = this.functionsList.filter(item => item.parent_id === this.modelValue.id);
      }
      if (this.modelValue && this.modelValue.options) {
        this.subFunction = this.modelValue.options;
      } else {
        this.subFunction = null
      }
    }
  },
  data() {
    return {
      functionItem: this.modelValue != null ? this.modelValue.id : null,
      subFunction: this.modelValue != null ? this.modelValue.options : null,
      listSubFunction: []
    }
  },
  computed: {
    mainFunctionList() {
      return this.functionsList.filter(item => item.parent_id === null)
    }
  },
  methods: {
    input(v) {
      console.log(v);
    },
    setModelData() {
      let modelData = {
        id: this.functionItem,
        options: this.subFunction ? this.subFunction : null,
      };
      console.log(modelData)
      this.$emit('update:modelValue', modelData)
    },
    updateSubList() {
      if (this.functionItem != null) {
        this.listSubFunction = this.functionsList.filter(item => item.parent_id === this.functionItem);
        this.subFunction = null
      }
    }
  },
  watch: {
    functionItem(val) {
      this.setModelData();
    },
    subFunction(val) {
      this.setModelData();
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';

.sub-function {
  margin-top: -10px;
}
.select-function {
  &__label {
    font-size: 18px;
  }
  &__card {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }
  &__select {
    flex-basis: 0;
    flex-shrink: 1;
  }
  &__btn-dell {
    padding-bottom: 16.5px;
    padding-top: 15px;
    flex-basis: 0;
    flex-shrink: 0;
  }

  &__input-error {
    color: $error;
    margin-top: 8px;
  }
}
</style>
