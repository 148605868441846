<template>
  <two-cols-grig is-reverse ref="progressSection">
    <template v-slot:title>
      <div class="title">Вы эксперт?</div>
      <div class="subtitle">
        Присоединяйтесь к Gigoo и начните реализовывать свою экспертизу
      </div>
      <base-button @click="regExecutor" class="button--demo but is-mobile">
        Стать экспертом</base-button
      >
    </template>
    <template v-slot:body>
      <div class="block progress">
        <div class="point">
          <svg-icon name="hand-coins-currency" width="48" height="48" />
          <div class="pointtext">Гибкий формат работы</div>
        </div>
        <div class="point">
          <svg-icon name="li_users" width="48" height="48" />
          <div class="pointtext">Гарантия оплаты проекта</div>
        </div>
        <div class="point last">
          <svg-icon name="clock-time-coins-deposit" width="48" height="48" />
          <div class="pointtext">Только релевантные проекты</div>
        </div>
      </div>
      <div class="block button">
        <base-button @click="regExecutor" class="button--demo but is-no-mobile">
          Стать экспертом</base-button
        >
      </div>
    </template>
  </two-cols-grig>
</template>
<script>
import TwoColsGrig from "@/views/startpage/components/TwoColsGrig";
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "PreFooterBlock",
  components: {
    TwoColsGrig,
    BaseButton,
    SvgIcon,
  },

  props: {},
  data() {
    return {};
  },

  methods: {
    regExecutor() {
      this.$store.commit("auth/SET_USERSTATE", "executor");
      this.$router.push({ name: "RegistrationPage" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.title {
  color: #fff;
  /* Muller 50px */
  font-size: 50px;
  font-family: Muller;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: -1px;
  padding-bottom: 10px;
  @include md {
    color: #fff;
    font-family: Muller;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px; /* 100% */
    letter-spacing: -0.34px;
    padding-top: 30px;
  }
}

.is-mobile {
  display: block;

  @include sm {
    display: none;
  }

  @include md {
    display: none;
  }
}

.is-no-mobile {
  display: none;

  @include sm {
    width: 90%;
    display: block;
    align-items: center;
  }

  @include md {
    width: 90%;
    display: block;
    align-items: center;
  }
}

.subtitle {
  color: #fff;
  /* Muller 40px */
  font-size: 40px;
  font-family: Muller;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: -0.8px;
  opacity: 0.7599999904632568;
  padding-bottom: 60px;

  @include md {
    padding-bottom: 30px;
    color: #fff;
    /* Muller 24px */
    font-family: Muller;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 108.333% */
    letter-spacing: -0.48px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e6e6e6;
  }
}

.pointtext {
  color: var(--background, #fff);
  /* SF Pro Display Medium */
  font-size: 20px;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.2px;
  width: 100%;
  @include md {
    color: var(--background, #fff);
    font-family: SFProDisplay;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.16px;
  }
}

.progress {
  display: flex;
  border-left: 1px solid #e6e6e6;
  height: 100%;
  @include md {
    border-left: 0px;
  }
}

.but {
  max-width: 315px;

  @include md {
    margin-top: 30px;
    max-width: 100%;
  }
}

.block {
  justify-content: flex-start;
  display: flex;
  padding-left: 30px;
  flex-direction: column;
  gap: 50px;
  @include md {
    padding-left: 0px;
    margin-top: 30px;
    gap: 15px;
    align-content: left;
  }
}
.button {
  @include md {
    width: 100%;
    padding-left: 0px;
    align-items: center;
  }
}
.point {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @include md {
    padding-bottom: 40px;
  }
  &.last {
    padding-bottom: 0px;
  }
}
</style>
