<template>
  <div class="community-page">
    <div class="community-page__header">
      <h1 class="community-page__h1">Сообщества</h1>
      <div class="community-page__menu">
        <!--Раскрыть если понадобится -->
        <div class="scrolling-wrapper profile-tabs__control">
          <router-link
            class="profile-tabs__control-item card"
            to="/community/industry-functions"
          >
            Индустрии и функции
          </router-link>
          <router-link class="profile-tabs__control-item card" to="/community/functions">
            Функции
          </router-link>
          <router-link class="profile-tabs__control-item card" to="/community/alumni">
            Образование и карьера
          </router-link>
        </div>
      </div>
    </div>

    <div class="profile-tabs__content white-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import InfoBox from "@/components/ui/InfoBox";
import Container from "@/components/layout/Container";
import ProgressBar from "@/components/ui/ProgressBar";
import BaseInput from "@/components/base/BaseInput";
import { mapGetters } from "vuex";

export default {
  name: "CommunityPage",
  components: {
    SvgIcon,
    InfoBox,
    Container,
    ProgressBar,
    BaseInput,
  },
  data: () => ({
    searchText: "",
    tabsList: [
      {
        title: "Специализации",
        value: "industry-functions",
      },
      {
        title: "Функции",
        value: "functions",
      },
      {
        title: "Образование и карьера",
        value: "alumni",
      },
    ],
    tabs: null,
    tabsIndustry: false,
    tabsFunctions: false,
    tabsAlumni: false,
  }),
  created() {
    this.getCommunities();
  },
  computed: {
    ...mapGetters({
      communities: "auth/communities",
    }),
    communitiesFilter() {
      let communities = {};
      if (this.searchText == null || this.searchText === "") return this.communities;
      for (const [key, value] of Object.entries(this.communities)) {
        let filterValue = value.filter((item) =>
          item.name
            .toString()
            .toLowerCase()
            .includes(this.searchText.toString().toLowerCase())
        );
        if (filterValue.length > 0) communities[key] = filterValue;
      }
      return communities;
    },
  },
  methods: {
    async getCommunities() {
      try {
        await this.$store.dispatch("auth/communities");
      } catch (e) {
        console.log(e);
      }
    },
    selectIndustry(e) {
      if (e) {
        this.tabs = "industry-functions";
      }
      this.tabsIndustry = e;
    },
    selectFunctions(e) {
      if (e && !this.tabsIndustry) {
        this.tabs = "functions";
      }
      this.tabsFunctions = e;
    },
    selectAlumni(e) {
      if (e && !this.tabsFunctions) {
        this.tabs = "alumni";
      }
      this.tabsAlumni = e;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.community-page {
  a:hover {
    color: #000000;
  }

  &__header {
    max-width: 1320px;
    margin: 40px auto 0 auto;
    @include lg {
      padding: 0 60px;
    }
    @include md {
      margin-top: 30px;
      padding: 0 24px;
    }
    @include sm {
      margin-top: 40px;
      margin-bottom: 20px;
      padding: 0 12px;
    }
    &__h1 {
      @include mobile_h1;
    }
  }

  &__menu {
    padding-top: 40px;
    @include md {
      padding-top: 30px;
    }
    @include sm {
      padding-top: 40px;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.006em;
    color: #000000;
  }

  .custom-height {
    height: 90px;
    padding-top: 20px;
  }

  .container .card .nav {
    max-width: 277px;
    width: 100%;
  }

  .info-box__description {
    color: #a0a0a0;
  }

  .svg-shrink {
    flex-shrink: 0;
  }

  .community-item {
    .info-box {
      &__title {
        margin-bottom: 8px;
      }
    }
    &.disabled {
      .info-box {
        &__title {
          color: $text-gray;
        }

        svg {
          opacity: 0.6;
        }
      }
    }
  }
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: left;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  @include md {
    justify-content: center;
  }
  & .card {
    &:first-child {
      margin-left: 0;
    }
    display: inline-block;
  }
}
.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  .card {
    flex: 0 0 auto;
    margin-right: 3px;
  }
}
.scrolling-wrapper,
.scrolling-wrapper-flexbox {
  //flex: 1 1 auto;
  //-webkit-overflow-scrolling: touch;
  width: 100%;
  @include sm {
    width: auto;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.profile-tabs {
  @include desktop_description;
  &__control {
    &-item {
      padding-bottom: 11px;
      border-radius: 0;
      border-bottom: 2px solid transparent;
      color: $text-gray;
      padding-left: 20px;
      padding-right: 20px;
      &:hover {
        color: $text-gray;
      }
      &.router-link-active {
        border-color: $accent;
        color: $accent;
        @include md {
          padding: 10px 20px;
          border-radius: 24px;
          border-color: initial;
          border-bottom: none;
          background: $white;
        }
      }
    }
  }
}
</style>
