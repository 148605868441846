<template>
  <hidden :pcBig="true" :pc="true" >
    <slot/>
  </hidden>
</template>

<script>
import Hidden from "@/components/base/GridResponsive/Hidden";
export default {
  name: "IsPc",
  components: {Hidden}
}
</script>

<style scoped>

</style>