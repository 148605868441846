<template>
  <div
    class="service-task"
    :class="{
      small: small,
      'not-position-action': notPositionAction,
      pointer: checkboxAction,
    }"
    @click="eventClick"
  >
    <div class="service-task__header">
      <rating :small="small" :range="rating" range-max="5" range-min="0"></rating>
    </div>
    <div class="service-task__body">
      <p class="service-task__body-h1">{{ function_name }}</p>
      <template v-if="!hideRating">
        <p v-if="like != null || hiddenShow" class="service-task__body-rating">
          {{ likeString }}
        </p>
      </template>
      <p class="service-task__body-subheader">Должность</p>
      <p class="service-task__body-sub" v-if="roles && roles[0] !== null">
        <span v-for="(role, idx) in uniqueCompanies(roles)" :key="`role-${role?.id}`"
          >{{ role?.name.split("/ ")[0]
          }}{{ idx < uniqueCompanies(roles)?.length - 1 ? ", " : "" }}</span
        >
      </p>
      <p class="service-task__body-subheader">Опыт работы</p>
      <p class="service-task__body-sub">
        {{ yearsString }}:
        <span
          v-for="(company, idx) in uniqueCompanies(companies)"
          :key="`company-${company.id}`"
          :class="{ disabled: company.hide }"
          >{{ company.hide ? "Компания на модерации" : company.name
          }}{{ idx < uniqueCompanies(companies).length - 1 ? ", " : "" }}</span
        >
      </p>
      <p class="service-task__body-subheader">Индустрии</p>
      <p class="service-task__body-sub">
        <span v-for="(t, idx) in tribe" :key="`tribe-${t}`"
          >{{ t }}{{ idx < tribe?.length - 1 ? ", " : "" }}</span
        >
      </p>
      <div v-if="isCompetence" class="service-task__body-action">
        <slot name="action"></slot>
      </div>
      <slot name="confirmation"></slot>
    </div>
    <div
      class="service-task__action"
      @click="eventClick"
      :class="{ 'desktop-only': isCompetence }"
    >
      <div class="desktop"><slot name="action"></slot></div>

      <base-radio
        v-if="checkboxAction"
        type="checkbox"
        v-model="checkboxValue"
      ></base-radio>
    </div>

    <div class="mobile">
      <div class="mobile-rating" :style="'background:' + colorRange(rating)">
        <div>
          <p>{{ rating }}</p>
        </div>
      </div>
      <div @click="eventClick" class="mobile-rec">
        <div>
          <slot name="action"></slot>
        </div>
      </div>
    </div>

    <div class="service-task__extra-block">
      <slot name="extra-body"></slot>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import Rating from "@/components/ui/Rating";
import AlertModal from "@/components/modals/AlertModal";
import BaseButton from "@/components/base/BaseButton";
import BaseRadio from "@/components/base/BaseRadio";
import { declOfNum } from "@/utils/helper";
export default {
  name: "WorkBlock",
  components: { BaseRadio, BaseButton, AlertModal, Rating, SvgIcon },
  data() {
    return {
      checkboxValue: false,
    };
  },
  props: {
    hiddenShow: {
      type: Boolean,
      default: false,
    },
    baseValue: {
      type: Boolean,
      default: false,
    },
    rating: {
      type: [Number, String],
      default: 0,
    },
    function_name: {
      type: [String],
      default: "",
    },
    tribe: {
      type: Array,
      default: () => [],
    },
    years: {
      type: [Number, String],
      default: "",
    },
    roles: {
      type: Array,
      default: () => [],
    },
    like: {
      type: [Number, String],
      default: "",
    },
    companies: {
      type: Array,
      default: () => [],
    },
    /**
     * Показывать выбор в поле
     */
    checkboxAction: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      default: null,
    },
    /**
     * Задать маленький размер
     */
    small: Boolean,
    /**
     * Эфект выделения
     */
    hover: Boolean,
    /**
     * Отменить позиционирования крестика сверху
     */
    notPositionAction: Boolean,
    /**
     * Другой вариант верстки для компетенции
     */
    isCompetence: Boolean,
    hideRating: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    eventClick() {
      this.checkboxValue = !this.checkboxValue;
    },
    uniqueCompanies(companies) {
      return [...new Map(companies.map((item) => [item["name"], item])).values()];
    },
    colorRange(value) {
      if (!this.noColor) {
        if (!this.isDay) {
          if (value >= 4) {
            return "#7FC666";
          } else if (value >= 3) {
            return "#FDC04B";
          } else if (value > 0) {
            return "#EF693F";
          } else {
            return "#ccc";
          }
        } else {
          if (value >= 7) {
            return "#7FC666";
          } else {
            return "#FDC04B";
          }
        }
      }
      return "#7FC666";
    },
  },

  mounted() {
    this.checkboxValue = this.baseValue;
  },
  computed: {
    yearsString() {
      let str = declOfNum(this.years, ["год", "года", "лет"]);
      return `${this.years} ${str}`;
    },
    likeString() {
      if (this.hiddenShow) return "На модерации";
      let str = declOfNum(this.like, ["оценка", "оценки", "оценок"]);
      return `${this.like} ${str}`;
    },
  },
  watch: {
    checkboxValue() {
      this.$emit("update:modelValue", {
        ...this.modelValue,
        ...{
          select: this.checkboxValue,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.desktop {
  display: flex;
  @include sm {
    display: none;
  }
}
/* demo */
.service-task {
  position: relative;
  display: flex;
  align-items: start;
  padding: 15px 30px;
  border-radius: 12px;
  margin-bottom: 16px;
  border: 1px solid $line-light-gray;

  @include sm {
    border-radius: 12px;
    border: 1px solid var(--Dark-Gray, #eee);
    background: #f6f6f6;
    flex-direction: column;
    align-items: flex-start;
    min-width: 229px;
    padding: 20px 20px 20px 16px;
  }
  &:hover {
    border: 1px solid #a0a0a0;
  }
  &:hover &__action {
    opacity: 1;
  }

  &__extra-block {
    display: none;
  }

  &.small.service-task--grid,
  &.service-task--grid {
    display: grid;
    grid-template-columns: 60px 1fr 25px;
    padding: 20px 30px;

    @include sm {
      display: block;
    }

    .service-task__extra-block {
      grid-column: span 3;
      padding-left: 80px;
      display: block;

      @include sm {
        padding-left: 0;
      }
    }
  }

  &__header {
    display: flex;
    align-self: flex-start;

    @include sm {
      margin: 18px 0 18px 0;
    }
  }

  &__body {
    margin: 0 20px;
    flex-grow: 1;

    @include sm {
      margin-left: 4px;
    }
  }

  &__body-h1 {
    font-family: "Muller";
    font-weight: 500;
    line-height: 22px;
    font-size: 20px;
  }

  &__body-sub {
    font-size: 18px;
    line-height: 22px;

    span.disabled {
      color: #a0a0a0;
    }
  }

  &__body-rating {
    color: $black;
  }

  &__body-subheader {
    @extend %text_small_plus;
    color: $black;
    margin-top: 8px;
  }

  &__body-action {
    display: none;

    @include sm {
      display: block;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    transition: opacity 0.5s;
    gap: 15px;

    //opacity: 0;
    * {
      cursor: pointer;
    }

    svg-icon {
      margin-right: 20px;
    }

    @include sm {
      //display: none;
      top: 20px;
      right: 20px;
      position: absolute;
      gap: 8px;
    }

    :slotted(*) {
      cursor: pointer;
    }
  }

  &.small {
    padding: 14px;

    @include sm {
      position: relative;
      display: flex;
      padding: 18px 30px 18px 26px;
    }
  }

  &.small &__body {
    @include sm {
      display: grid;
      padding-left: 0;
      //height: 100%;
      margin: 0;
      align-content: space-between;
    }
  }

  &.small &__header {
    display: flex;

    @include sm {
      display: none;
    }
  }

  &.small &__body-subheader {
    font-size: 16px;
    line-height: 20px;
    color: $text-gray;
  }

  &.hover &__action {
    opacity: 0;

    @include md {
      opacity: 1;
      position: static;
      margin-top: 20px;

      ::v-deep .svg-icon--service-dell {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  }

  &.hover:hover &__action {
    opacity: 1;
  }

  .hidden-show &__body-subheader {
    color: $text-gray;
  }

  .hidden-show &__header {
    opacity: 0.5;
  }

  .not-position-action &__action {
    @include md {
      top: auto;
      right: auto;
      position: static;
    }
  }
}

.mobile {
  display: flex;
  justify-content: row;
  gap: 0px;
  align-items: center;
}
.mobile-rating {
  display: none;
  @include sm {
    @include desktop_h2;
    color: #fff;
    text-align: center;
    font-family: Muller;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    padding: 5px 20px;
    color: white;
    border-radius: 8px;
  }
}
.mobile-rec {
  display: none;
  @include sm {
    @include desktop_standart;
    display: flex;
    padding: 5px 10px;
    padding-left: 0px;
    cursor: pointer;
  }
}
.pointer {
  cursor: pointer;
}
.desktop-only {
  @include sm {
    display: none;
  }
}
</style>
