<template>
  <div class="base-file__wrapper" :class="{ error: isError }">
    <div class="title">
      <p class="base-file__title" v-if="title">{{ title }}</p>
      <p class="base-file__description" v-if="isOptional">необязательно</p>
    </div>
    <div
      class="base-file"
      :class="[{ 'is-file': fileCounter }, isWhite ? 'white' : 'grey']"
    >
      <label class="base-file__label" :for="ref_name">
        <input
          :id="ref_name"
          :ref="ref_name"
          :multiple="multiple"
          :accept="fileType"
          type="file"
          class="base-file__input"
          @change="checkFile"
        />
        <div class="base-file__file-list" v-if="fileCounter">
          <div
            v-for="(item, idx) in fileList"
            :key="`file_${idx}`"
            class="base-file__item"
            @click="onlyClick"
          >
            <a v-if="item.url" :href="item.url" target="_blank">{{ item.name }} </a>
            <div v-else>{{ item.name }}</div>
            <span class="base-file__item-del">
              <svg-icon
                name="service-dell"
                :width="15"
                :height="15"
                @click="delItem(idx)"
              />
            </span>
          </div>
        </div>
        <template v-if="!fileCounter">
          <span class="base-file__btn">Загрузите вложения</span>
          <svg-icon class="base-file__btn-icon" :width="45" :height="45" name="btn-add" />
        </template>
        <div v-else class="base-file__remove">
          <svg-icon :width="21" :height="21" name="plus-round" />
        </div>
      </label>
    </div>
    <div class="base-file__description" v-if="description && !isError">
      {{ description }}
    </div>
    <div v-if="isError" class="input-error">{{ errorString }}</div>
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";

export default {
  name: "BaseMultiFile",
  components: { SvgIcon, BaseButton },
  props: {
    fileType: {
      type: String,
      default: ".jpg,.png,.pdf,.docx,.doc,.rtf,.pptx,.ppt,.msg",
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    tmpFiles: {
      type: Array,
      default() {
        return [];
      },
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    tmp: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    description: {
      type: String,
      default: "",
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileCounter: "",
      fileName: "",
      file: "",
      storage: null,
      fileList: [],
    };
  },
  created() {
    if (this.tmpFiles) {
      this.fileList = this.tmpFiles;
    }
  },

  computed: {
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
    ref_name() {
      return "BaseMultiFile" + this.tmp;
    },
    fileCounter() {
      return this.fileList ? this.fileList.length : false;
    },
  },
  methods: {
    checkFile(event) {
      this.fileList.push(...event.target.files);
    },
    onlyClick(e) {
      if (!e.srcElement.href) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    delItem(e) {
      this.fileList.splice(e, 1);
    },
  },
  watch: {
    fileList: {
      deep: true,
      immediate: true,
      handler() {
        if (this.fileList.length) {
          this.$emit("file", this.fileList);
        } else {
          this.$emit("file", []);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grey {
  background: $bg-light-gray;
}

.white {
  background: white;
}

.base-file {
  border-radius: 10px;

  &__title {
    @include desktop_description;
    margin-bottom: 10px;
    @include sm {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 106.667% */
    }
  }

  &__description {
    @include desktop_additional;
    margin: 5px 0px;
    color: $text-gray;
    @include sm() {
      color: #a0a0a0;
      font-family: SFProDisplay;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
      letter-spacing: 0.26px;
    }
  }

  &__item {
    display: flex;
    background: $white;
    @include mobile_text;
    border-radius: 8px;
    height: 48px;
    align-items: center;
    margin-top: 4px;
    margin-right: 4px;
    padding: 14px 15px;

    &-del {
      flex-shrink: 0;
      margin-left: 10px;
    }
  }

  &__file-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -4px;
  }

  &__label {
    display: flex;
    align-items: center;
    padding: 6px 6px 6px 18px;
    width: 100%;
    cursor: pointer;
    height: 52px;
    overflow: hidden;
    margin: 0;
  }

  &__plus {
    display: block;
    width: 44px;
    height: 44px;
    background-color: #f6f6f6;
  }

  &__title {
    @extend %text-standart;
    max-height: 40px;
    overflow: hidden;
  }

  &__input {
    display: none;
  }

  &__btn {
    font-family: SFProDisplay, Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.006em;
    opacity: 0.2;
    @include sm() {
      font-family: SFProDisplay;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
      letter-spacing: 0.26px;
    }
    &-icon {
      flex-shrink: 0;
      margin-left: auto;
    }
  }

  &__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background-color: $bg-light-gray;
    border-radius: 12px;
    margin-left: auto;
    cursor: pointer;
    flex-shrink: 0;
  }

  &.is-file {
    background: #f6f6f6;
    border-color: #f6f6f6;
    padding: 2px;

    .base-file {
      &__label {
        padding: 0;
        height: auto;
      }

      &__title {
        display: block;
        background: #fff;
        margin: 0;
      }
    }
  }

  &__wrapper {
    &.error {
      .base-file {
        border: 1px solid #ef693f;
      }

      .input-error {
        color: #ef693f;
        font-size: 16px;
        line-height: 20px;
        margin-top: 8px;
      }
    }
  }
}
</style>
