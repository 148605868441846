<template>
  <input
    type="range"
    class="styled-slider slider-progress"
    :style="{width: '100%', ...getCss}"
    @input="inputRange"
    v-model="inputVal"
    :max="max"
    :min="min"
    :step="step"
  />
</template>

<script>
export default {
  name: "BaseRadioInput",
  props: {
    min: {
      default: 0
    },
    max: {
      default: 100
    },
    step: {
      default: 1
    },
    value: {
      default: 0
    }
  },
  data() {
    return {
      modelValue: {
        type: [String, Number],
        default: null
      },
    }
  },
  methods: {
    inputRange(e) {
      this.$emit('update:modelValue', this.value)
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    getCss() {
      return {
        '--value': this.value,
        '--min': this.min,
        '--max': this.max
      }
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue != null)
          this.value = newValue;
        else
          this.value = 0;
      }
    },
    value() {
      console.log(this.value);
    }
  }
}
</script>

<style scoped lang="scss">
$height: 2px;
$roundSize: 16px;

input[type=range].styled-slider {
  height: 22px;
  -webkit-appearance: none;
}

/*progress support*/
input[type=range].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 20px + var(--ratio) * (100% - 20px));
}

input[type=range].styled-slider:focus {
  outline: none;
}

/*webkit*/
input[type=range].styled-slider::-webkit-slider-thumb {
  width: $roundSize;
  height: $roundSize;
  border-radius: 50%;
  background: $accent;
  box-shadow:  0 0 0 6px rgba($accent, .2);
  -webkit-appearance: none;
  margin-top: calc($height * 0.5 - $roundSize * 0.5);
}

input[type=range].styled-slider::-webkit-slider-runnable-track {
  height: $height;
  border: none;
  border-radius: 0;
  background: #000000;
  box-shadow: none;
}
input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient($accent,$accent) 0/var(--sx) 100% no-repeat, transparent;
}

/*mozilla*/
input[type=range].styled-slider::-moz-range-thumb {
  @extend %text-standart;
  width: $roundSize;
  height: $roundSize;
  border-radius: 50%;
  background: $accent;
  border: none;
  box-shadow: none;
}

input[type=range].styled-slider::-moz-range-track {
  height: $height;
  border: none;
  border-radius: 0;
  background: #000000;
  box-shadow: none;
}

input[type=range].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient($accent,$accent) 0/var(--sx) 100% no-repeat, transparent;
}

/*ms*/
input[type=range].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $accent;
  box-shadow: 0 0 0 6px rgba($accent, .2);
  border: none;
  margin-top: 0;
  box-sizing: border-box;
}

input[type=range].styled-slider::-ms-track {
  height: $height;
  border-radius: 0;
  background: #000000;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
}

input[type=range].styled-slider.slider-progress::-ms-fill-lower {
  height: $height;
  border-radius: 0px 0 0 0px;
  margin: -undefined 0 -undefined -undefined;
  background: $accent;
  box-shadow: 0 0 0 6px rgba($accent, .2);
  border: none;
  border-right-width: 0;
}
</style>