<template>
  <div class="block-main">
    <h1 class="title">
      Скоро здесь появится страница о корпоративном сервисе
      <h1 class="subtitle">
        Но уже сейчас вы можете скачать презентацию для компаний, в котором мы
        рассказываем о подписке на услуги нашей платформы!
      </h1>
    </h1>
    <a
      class="but2"
      :href="
        getPolicy(
          '/docs/Gigoo%20%D0%9A%D0%BE%D1%80%D0%BF%D0%BE%D1%80%D0%B0%D1%82%D0%B8%D0%B2%D0%BD%D0%B0%D1%8F%20%D0%BF%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F.pdf'
        )
      "
    >
      <base-button class="but1"> Скачать презентацию</base-button></a
    >
    <div class="but" @click="this.$router.push({ path: '/' })">Вернуться на главную</div>
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "MockPage",
  components: { BaseButton },
  methods: {
    getPolicy(href) {
      return (
        "https://" +
        config.VUE_APP_ASSETS_BUCKET +
        ".storage.yandexcloud.net/" +
        config.VUE_APP_ASSETS_DIR +
        href
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.block-main {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding: 10% 30%;

  @include md {
    padding: 100px 30%;
  }
}

.title {
  color: var(--text, #000);
  text-align: center;
  /* Muller 50px */
  font-family: Muller;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 32px */
}

.subtitle {
  padding-top: 30px;
  color: var(--text, #000);
  text-align: center;
  /* Desktop/Standart */
  font-family: SFProDisplay;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.108px;
}

.but1 {
  width: 335px;
  margin-top: 30px;
}

.but2 {
  color: white;
}
.but {
  margin-top: 30px;
  cursor: pointer;
  color: #a0a0a0;
}
</style>
