<template>
  <div class="block">
    <div class="inner">
      <div class="point">
        <div class="svg"><svg-icon :name="iconName" width="48" height="48" /></div>
        <h1 class="title">{{ name }}</h1>
      </div>
      <div class="chips-group">
        <div v-for="(item, index) in chips.slice(0, 4)" :key="item + index" class="chip">
          {{ item }}
        </div>
        <div
          v-if="!isToggle & (chips.length > 4)"
          class="extra"
          @click="isToggle = !isToggle"
        >
          Показать еще <svg-icon name="arrow-down" width="14" height="14" />
        </div>
        <div v-if="isToggle" class="chips-group">
          <div v-for="(item, index) in chips.slice(4)" :key="item + index" class="chip">
            {{ item }}
          </div>
        </div>
        <div v-if="isToggle" class="extra" @click="isToggle = !isToggle">
          Cкрыть <svg-icon name="arrow-up" width="14" height="14" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "TaskCard",
  components: {
    SvgIcon,
  },
  data() {
    return {
      isToggle: false,
    };
  },
  computed: {},
  props: {
    iconName: {
      type: String,
      default: "comp",
    },
    name: {
      type: String,
      default: "",
    },
    chips: {
      type: Array,
      default: [],
    },
  },
  methods: {
    getPhoto() {
      if (this.fullPath) {
        return this.photo;
      } else {
        if (this.photo == null || this.photo === "") return null;
        return this.photo;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.block {
  width: 100%;
  display: block;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  @include sm {
    padding: 10px;
    margin: 0px 10px;
    border: 0px solid rgba(0, 0, 0, 0.05);
  }
}
.inner {
  width: 100%;
  padding: 30px;
  @include md {
    margin: 0px;
  }
  @include sm {
    padding: 10px;
  }
}
.svg {
  height: 46px;
  width: 46px;
}
.point {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 30px;
  gap: 15px;
}
.title {
  font-size: 26px;
  font-family: Muller;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.52px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;

  @include md {
    color: var(--text, #000);
    /* Desktop/H2 */
    font-family: Muller;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 110% */
  }

  @include sm {
  }
}

.chips-group {
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
  @media screen and (max-width: 1400px) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
  }
}
.extra {
  @extend %desktop_additional;
  display: flex;
  cursor: pointer;
  font-family: SFProDisplay;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  justify-content: space-between;
  padding: 10px 0px;
  align-items: center;

  flex-wrap: wrap;
  line-height: 10px;
  white-space: nowrap;
}
.chip {
  @extend %desktop_additional;
  display: flex;

  font-family: SFProDisplay;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid #eee;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
  border-radius: 30px;
  background-color: #ffffff;

  flex-wrap: wrap;
  line-height: 10px;
  white-space: nowrap;
}
</style>
