<template>
  <div class="block">
    <div class="title">
      <div>
        <div class="chips-group">
          <div v-for="(item, index) in shortchips" :key="item + index" class="chip">
            {{ item }}
          </div>
        </div>
        <h1 class="expert">{{ expertName }}</h1>
        <div class="position">{{ expertPosition }}</div>
      </div>
      <div>
        <div class="description">{{ description }}</div>
      </div>
    </div>
    <div class="photo_expert">
      <img
        loading="lazy"
        class="img"
        :src="$store.getters['auth/assets'] + 'img/' + photo"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import Avatar from "@/components/ui/Avatar";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "TaskCard",
  components: {
    Avatar,
    BaseButton,
  },
  computed: {
    shortchips() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs
        ? this.chips.slice(1, 2)
        : this.chips;
    },
  },
  props: {
    expertName: {
      type: String,
      default: "Юрий Макаров",
    },
    expertPosition: {
      type: String,
      default: "CEO of Яндекс",
    },
    description: {
      type: String,
      default:
        "Равным образом консультация с широким активом требуют определения и уточнения модели развития. Товарищи!",
    },
    photo: {
      type: String,
      default: "expertphoto.png",
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Array,
      default: ["E-commerce", "FMCG", "12 лет опыта"],
    },
  },
  methods: {
    getPhoto() {
      if (this.fullPath) {
        return this.photo;
      } else {
        if (this.photo == null || this.photo === "") return null;
        return this.photo;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.block {
  background: #ffffff;
  box-shadow: 5px 5px 3px#b8b8b82c;
  border-radius: 20px;
  display: block;
  justify-content: center;
  align-items: top;
  flex-direction: row;
  gap: 10px;
  width: 590px;
  height: 250px;
  @include md {
    min-width: 0px;
    position: relative;
    box-shadow: 0px 0px 0px#b8b8b82c;
  }
}

.title {
  display: flex;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  background: #ffffff;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-content: left;
  text-align: left;
  width: 100%;
  margin-bottom: 0px;
  @include md {
    padding: 20px;
    padding-bottom: 0px;
  }
}

.img {
  object-fit: fit;
  max-height: 250px;
  display: flex;
  position: relative;
  border-radius: 20px;

  @include md {
    border-radius: 16px;
    object-position: 0% 10%;
    max-height: 200px;
    display: flex;
    object-fit: cover;
    align-items: center;
    width: 100%;
    margin: 0px;
    z-index: 0;
  }
}

.photo_expert {
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: right;
  padding: 10px;
  @include md {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 10px;
    width: 100%;
    z-index: 0;
  }
}

.expert {
  font-size: 26px;
  font-family: Muller;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.52px;

  @include md {
    color: var(--background, #fff);
    font-family: Muller;
    font-size: 17.599px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 22.879px */
    letter-spacing: -0.176px;
    padding-top: 60px;

    position: relative;
    z-index: 3;
  }
}

.description {
  @extend %desktop_standart;
  font-size: max(1vw, 16px);
  @include md {
    color: var(--text, #000);
    /* Mobile/Text_mdall */
    font-family: SFProDisplay;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    position: relative;
    top: 20px;
    padding: 30px 0px;
  }
}
.position {
  @extend %desktop_standart;
  font-size: max(1vw, 16px);
  @include md {
    color: var(--background, #fff);
    font-family: SFProDisplay;
    font-size: 9.777px;
    font-style: normal;
    font-weight: 400;
    line-height: 128%; /* 12.515px */
    letter-spacing: -0.098px;
    position: relative;
    z-index: 3;
  }
}

.chips-group {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
  gap: 10px;
  overflow: hidden;
  @include md {
    margin-top: 0px;
    position: relative;
    z-index: 3;
    margin-bottom: 40px;
  }
}

.chip {
  @extend %desktop_additional;
  display: flex;

  font-family: SFProDisplay;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border-radius: 30px;
  background-color: #f6f6f6;
  color: #4b4c57;
  flex-wrap: wrap;
  line-height: 10px;
  white-space: nowrap;
  @include md {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px; /* 100% */
    letter-spacing: -0.1px;
  }
}
</style>
