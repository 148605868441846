<template>
  <div class="services">
    <container :full-body="services.length === 0">
      <template v-slot:header>
        <h1>
          {{ $t('pages.services.header') }}
        </h1>
      </template>

      <template v-slot:nav>
        <v-tabs direction="vertical" class="services__navigation" v-model="tab">
          <v-tab v-if="this.getServices('individual').length > 0 || isShowNav" href="#people" value="people">От
            себя</v-tab>
          <v-tab v-if="this.getServices('company').length > 0 || isShowNav" href="#company" value="company">От
            компании</v-tab>
          <v-tab v-if="isHidden" href="#hidden" value="hidden">Скрытые</v-tab>
        </v-tabs>
      </template>
      <template v-slot:btn>
        <base-button href="/service-add" class="services__save" :class="{
          'services__save-hidden': isServiceNull()
        }">
          Добавить услугу
        </base-button>
      </template>
      <template v-slot:body>
        <not-item v-if="services.length === 0" @btnEvent="toPage('/service-add')" btn-name="Добавить услугу" title="Создайте услуги, которые готовы оказывать самостоятельно,
или командой" description="Вы будете получать только те заявки от заказчиков, 
которые будут соответствовать вашим услугам"></not-item>
        <div v-else class="services__body">
          <template v-if="this.getServices('individual').length > 0">
            <div v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0.5, 1.0]
              }
            }">
              <h1 class="services__h1" id="people">От себя</h1>
              <service-task v-for="service in getServices('individual')" @click="editServicePage(service.id)"
                class="services__task" :industry="service.name" :rating="service.rating"
                :user-counter="service.users_count" :like="getLike(service)" :key="service.id" hover>


                <template v-slot:action>
                  <base-button small round class="button--light service__company-success" icon-prefix="plus-round"
                    icon-postfix-size="20" @click.stop @click="openEstimate(service)">Оценка</base-button>
                  <svg-icon width="30" @click.stop @click="hidden(service)" name="service-hidden"></svg-icon>
                  <svg-icon width="23" @click.stop @click="dell(service)" name="service-dell"></svg-icon>
                </template>
              </service-task>
            </div>
          </template>
          <template v-if="this.getServices('company').length > 0">
            <div v-intersect="{
              handler: onAboutCompany,
              options: {
                threshold: [0.5, 1],
                rootMargin: '0px 0px 0px 0px'
              }
            }">
              <h1 class="services__h1" id="company">От компании</h1>
              <service-task v-for="service in getServices('company')" @click="editServicePage(service.id)"
                class="services__task" :rating="service.rating" :industry="service.name"
                :functionName="getCompanyAndIndividualName(service)" :like="getLike(service)" :key="service.id"
                :user-counter="service.users_count" not-position-action hover>


                <template v-slot:action>
                  <div class="company-check" v-if="service.need_approve">
                    <base-button @click.stop @click="clickParticipate(service, true)" small round
                      class="button--light service__company-success" icon-prefix="input-check"
                      icon-postfix-size="21">Принять</base-button>
                    <svg-icon @click.stop @click="clickParticipate(service, false)" width="23" name="service-dell"
                      class="service-dell service__company-dell"></svg-icon>
                  </div>
                  <div class="company-check-active" v-else>
                    <svg-icon v-if="service.user_id === user.id" width="30" @click.stop="hidden(service)"
                      name="service-hidden"></svg-icon>
                    <svg-icon width="23" @click.stop="dell(service)" name="service-dell"></svg-icon>
                  </div>
                </template>
              </service-task>
            </div>
          </template>
          <div class="hidden services__h1" v-if="isHidden" v-intersect="{
            handler: onHidden,
            options: {
              threshold: [0.2, 0.4, 0.6, 0.8, 1],

            }
          }">
            <h1 id="hidden">Скрытые</h1>
            <base-button class="button--light hidden-btn" icon :icon-postfix-size="20" icon-prefix="service-hidden-btn"
              @click="showHidden = !showHidden" :class="{
                'hidden-btn-activate': showHidden
              }" />
          </div>
          <service-task v-for="service in getServicesHidden()" @click="editServicePage(service.id)" class="services__task"
            :rating="service.rating" :industry="service.name" :functionName="getCompanyAndIndividualName(service)"
            :like="getLike(service)" :user-counter="service.users_count" :key="service.id" hidden-show hover>
            <template v-slot:action>
              <svg-icon width="30" @click.stop="hidden(service)" name="service-show"></svg-icon>
              <svg-icon width="23" @click.stop="dell(service)" name="service-dell"></svg-icon>
            </template>
          </service-task>
        </div>
      </template>
    </container>
    <alert-modal ref="dell">
      <template v-slot:button>
        <base-button @click="() => $refs.dell.closeModal()" class="button--light modal__btn">Отмена</base-button>
      </template>
    </alert-modal>
    <alert-modal ref="alert"></alert-modal>

    <request-recommendation-array-modal
      :info-array="[{ 'header': 'Услуга', 'body': currentService?.name, 'data': [{ 'id': currentService?.id, 'type': 'service' }] }]"
      :is-open="isEstimate" @close="isEstimate = false" :is-estimate="true" @successRequest="successRequest" />

  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import ServiceTask from "@/components/ui/ServiceTask";
import AlertModal from "@/components/modals/AlertModal";
import { mapGetters } from "vuex";
import NotItem from "@/components/ui/notItem";
import SvgIcon from "@/components/base/SvgIcon";
import Container from "@/components/layout/Container";
import RequestEstimateModal from "@/components/modals/RequestEstimateModal";
import RequestRecommendationArrayModal from "@/components/modals/RequestRecommendationArrayModal";
import { declOfNum } from "@/utils/helper";
export default {
  name: "ServicesPage",
  components: {
    RequestRecommendationArrayModal, RequestEstimateModal, Container, SvgIcon, NotItem, AlertModal, ServiceTask, BaseButton
  },
  data() {
    return {
      showHidden: true,
      tab: null,
      company: false,
      isEstimate: false,
      currentService: null,
      quickView: false
    }
  },
  created() {
    if (this.isAuth) {
      this.getServicesApi();
    }
  },
  computed: {
    ...mapGetters({
      isAuth: "auth/isAuth",
      services: "service/services",
      user: "auth/user"
    }),
    isHidden() {
      if (this.isAuth == false)
        return false;
      if (this.getServicesHidden().length <= 0)
        return false;
      return true
    },
    isShowNav() {
      if (!this.isAuth)
        return true;
      if (this.services.length <= 0)
        return true;
      return false;
    }
  },
  methods: {
    editServicePage(id) {
      console.log(id);
      this.$router.push('/service-add/' + id);
    },
    getLike(service) {
      if (service.status === "moderation")
        return 'На модерации';
      if (service.rating_count) {
        return `${service.rating_count} ${declOfNum(service.rating_count, ['рекомендация', 'рекомендации', 'рекомендаций'])}`
      }
      return ''
    },
    dell(item) {
      this.$refs.dell.show(
        'Удалить услугу',
        `Вы уверены, что хотите удалить услугу (${item.name})?`,
        {
          type: 'dell',
          nameButton: 'Удалить',
          error: true
        },
        () => {
          this.dellServices(item.id)
        }
      )
    },
    refuse(item) {
      this.$refs.dell.show(
        'Отменить участие в услуге',
        'Вы уверены, что хотите отказаться от участия в  услуге? Владельцу услуги придет уведомление с вашим отказом.',
        {
          type: 'danger',
          nameButton: 'Удалить',
          error: true
        },
        () => {
          this.dellServices(item.id)
        }
      )
    },
    successRequest() {
      this.$refs.alert.show(
        'Оценка запрошена',
        'Мы успешно отправили запрос на оценку выбранным пользователям',
        {
          type: 'success',
          nameButton: 'Хорошо',
          redirectPath: '/services',
        },
      );
    },
    async hidden(item) {
      try {
        await this.$store.dispatch('service/hideServices', item.id)
        await this.getServicesApi();
      } catch (e) {
        this.$refs.alert.show(
          this.$t('alert.error'),
          e,
          {
            type: 'error',
            nameButton: 'Ок'
          },
        );
      }
    },
    clickParticipate(service, participate) {
      let data = {
        service_id: service.id,
        isParticipate: participate
      };
      if (participate === false) {
        this.$refs.dell.show(
          'Отменить участие в услуге',
          'Вы уверены, что хотите отказаться от участия в  услуге? Владельцу услуги придет уведомление с вашим отказом.',
          {
            type: 'danger',
            nameButton: 'Отказаться',
            error: true
          },
          () => {
            this.postServicesParticipate(data)
          }
        )
      } else {
        this.postServicesParticipate(data)
      }

    },
    getServicesHidden() {
      if (this.services != null) {
        if (!this.showHidden) {
          let show = [];
          if (this.services.length > 0) {
            show = this.services.filter(v => v.status === 'not active').sort(function (a, b) {
              return new Date(b.update_at) - new Date(a.update_at)
            });
          }
          return show.length > 0 ? [show[0]] : [];
        } else
          return this.services.filter(v => v.status === 'not active')
      }
      else
        return [];
    },
    getServices(type) {
      if (this.services != null)
        return this.services.filter(v => v.format === type && v.status !== 'not active')
      else
        return [];
    },
    getCompanyAndIndividualName(service) {
      if (service.format == 'company') {
        if (service.company && service.performer_type != 'individual')
          return service.company.name;
        else
          return service.user_name
      } else
        return null
    },
    isServiceNull() {
      if (this.services == null)
        return true;
      return this.services.length <= 0;
    },
    async getServicesApi() {
      try {
        await this.$store.dispatch('service/getServices')
      } catch (e) {
        this.$refs.alert.show(
          this.$t('alert.error'),
          e,
          {
            type: 'error',
            nameButton: 'Ок'
          },
        );
      }
    },
    async postServicesParticipate(data) {
      try {
        await this.$store.dispatch('service/postServicesParticipate', data)
        await this.getServicesApi();
      } catch (e) {
        this.$refs.alert.show(
          this.$t('alert.error'),
          e,
          {
            type: 'error',
            nameButton: 'Ок'
          },
        );
      }
    },
    toPage(path) {
      this.$router.push(path);
    },
    async dellServices(id) {
      try {
        await this.$store.dispatch('service/dellServices', id)
        await this.getServicesApi();
      } catch (e) {
        this.$refs.alert.show(
          this.$t('alert.error'),
          e,
          {
            type: 'error',
            nameButton: 'Ок'
          },
        );
      }
    },
    onIntersect(entries, observer) {
      if (entries) {
        this.$router.push({ hash: '#people' })
        this.tab = "people"
      }
    },
    onAboutCompany(entries) {
      if (entries) {
        this.$router.push({ hash: '#company' })
        this.tab = "company"
      }
      this.company = entries;
    },
    onHidden(entries) {
      if (entries && !this.company) {
        this.$router.push({ hash: '#hidden' })
        this.tab = "hidden"
      }
      console.log("hi", entries)
    },
    estimate(item) {
      console.log(item)

    },
    openEstimate(service) {
      this.currentService = service;
      this.isEstimate = true
    },
    closeEstimate() {
      this.isEstimate = false
    }
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler() {

      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';

.v-slide-group--vertical {
  ::v-deep .v-slide-group__prev {
    display: none;
  }

  ::v-deep .v-slide-group__next {
    display: none;
  }
}

.services__body svg {
  flex-shrink: 0;
}

.modal__btn {
  margin-left: 16px;
}

.services {
  &__h1 {
    margin: 60px 0 30px 0;

    @include md {
      margin: 48px 0 24px 0;
    }
  }

  &__body {
    margin-top: -60px;

    @include md {
      margin-top: -48px;
    }
  }

  .company-check {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .hidden {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-btn {
      width: auto;
      height: 49px;
      transition: transform 150ms ease;
    }

    &-btn-activate {
      transform: rotate(-180deg);
    }
  }

  ::v-deep .not-item__description {
    padding: 0 77px;
    margin: 24px 0 40px 0;
  }

  .button--light:hover {
    background-color: $bg-light-gray;
  }

  .button--light {
    background-color: transparent;
  }

  .service-hidden {
    min-width: 30px;
  }

  .service-dell {
    min-width: 23px;
  }

  .service__company-success {
    @include sm {
      margin-top: 20px;
    }

  }

  .company-check-active {
    gap: 15px;
    display: flex;

    @include sm {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .service__company-dell {
    @include sm {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  &__save {
    display: flex;
    justify-content: center;

    &-hidden {
      @include md {
        display: none;
      }
    }
  }

  &__navigation {
    margin-bottom: 40px;
  }

  &__task {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
