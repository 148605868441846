<template>
  <div v-click-outside="handleFocusOut" class="base-dropdown__wrapper" :class="classes">
    <div class="base-dropdown">
      <div class="base-dropdown__block" @click.stop="isDropdown = !isDropdown">
        <p
          class="base-dropdown__home"
          v-if="!hideFullName"
          :style="textColorFn"
          :class="{ 'base-dropdown__is-active': $route.name === 'ProfilePage' }"
        >
          {{ home }}
        </p>
        <slot name="postfix"></slot>
        <div
          class="base-dropdown__btn ml-2"
          :class="{ rotate: !isDropdown, 'base-dropdown__arrow': !hideArrow }"
        >
          <svg-icon
            width="12"
            :name="ArrowWhite ? 'profile-arrow-down-white' : 'profile-arrow-down'"
          />
        </div>
      </div>
      <div
        v-if="isDropdown"
        class="base-dropdown__list"
        :class="{
          'base-dropdown__list_competence': isCompetence,
          'base-dropdown__right': hideArrow,
        }"
        @click.stop="isDropdown = false"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import IsPc from "@/components/ui/IsPc";

export default {
  name: "BaseDropdownMenu",
  components: { IsPc, SvgIcon },
  props: {
    /**
     * Текст или в дальнейшем главная ссылка
     */
    home: {
      type: String,
      default: "",
    },
    /** Скрывать вход/регу если авторизован */
    isAuth: {
      type: Boolean,
      default: false,
    },
    /** Раскрыть меню с верху */
    top: Boolean,
    /** Скрывать на телефоне */
    hidMobile: Boolean,
    /** Скрывать имя в редизайне */
    hideFullName: Boolean,
    /** Скрывать стрелку */
    hideArrow: {
      type: Boolean,
      default: false,
    },
    /** Цвет текста */
    textColor: [String],
    ArrowWhite: {
      type: Boolean,
      default: false,
    },
    isCompetence: Boolean,
  },
  data() {
    return {
      searchText: "",
      isDropdown: false,
    };
  },
  mounted() {
    document.addEventListener("click", () => {
      this.isDropdown = false;
    });
  },
  computed: {
    classes() {
      return {
        "base-dropdown__top": this.top,
        "base-dropdown__hidMobile": this.hidMobile,
      };
    },
    isHome() {
      return this.$route.name === "RequestAddPage" || this.$route.name === "ProfilePage";
    },
    textColorFn() {
      if (this.textColor)
        return {
          color: this.textColor,
        };
    },
  },
  methods: {
    handleFocusOut() {
      this.isDropdown = false;
    },
    changeActive(val) {
      this.searchText = val.name;
      this.$emit("update:modelValue", val.id);
    },
    checkOpen() {
      if (!this.isDropdown) {
        this.isDropdown = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.base-dropdown {
  position: relative;
  &__list {
    position: absolute;
    top: 45px;
    z-index: 5;
    right: 0;
    border-radius: 12px;
    overflow: hidden;
    max-height: 245px;
    overflow-y: auto;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
    min-width: 300px;
    padding: 12px 0;
    background-color: $white;
    @include sm {
      min-width: 215px;
    }
    :slotted(*) {
      font-size: $font-size-3;
      background-color: $white;
      cursor: pointer;
      padding: 8px 20px;
      display: block;
      text-align: left;
      transition: 0.3s;

      &:hover {
        color: $green;
      }
    }

    &_competence {
      @include sm {
        left: 50%;
      }
    }
  }

  &__arrow {
    @include md {
      display: none;
    }
  }

  &__wrapper.narrow {
    .base-dropdown__list {
      width: auto;
      min-width: auto;

      @include sm() {
        transform: none;
        width: 45px;
      }
    }
  }

  &__btn {
    transform: rotate(-180deg);
    transition: 0.3s;

    &.rotate {
      transform: rotate(0deg);
      transition: 0.3s;
    }
  }
  &__top &__list {
    top: auto;
    bottom: 40px;
  }
  &__field {
    @extend %text-standart;
    background-color: transparent;
    flex-grow: 1;
    padding: 0;

    &::placeholder {
      color: $text-gray;
    }
  }
  &__home {
    @extend %text-standart;

    @include md {
      font-size: 14px;
      line-height: 22px;
      margin-right: 5px;
    }
  }
  &__is-active {
    background-color: $bg-light-gray;
    padding: 5px 34px 5px 14px;
    border-radius: 41px;
    margin-right: -20px;
  }
  &__block {
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    align-items: center;
    justify-content: flex-end;
  }
  &__hidMobile &__home {
    @include sm {
      display: none;
    }
  }

  &__right {
    right: -60px;
  }
}

.experience-item__dropdown {
  .base-dropdown__block {
    padding: 10px 20px 10px 20px;
    background-color: $bg-light-gray;
    border-radius: 20px;

    @include md() {
      padding: 10px 20px 10px 20px;
    }
  }
}
</style>
