import { ref } from "vue";
import { debounce } from "@/utils/helper";

/**
 *
 * @param selectOne Выберать только 1 значения
 * @param inDebounce Задержки перед запросами, для строки поиска 300 мс
 * @param newValue
 * @param localSearch
 * @param checkUnic
 */
export default function useSelectController({
    selectOne = false,
    inDebounce = true,
    newValue = false,
    localSearch = true,
    checkUnic = null
}) {
    console.groupCollapsed(`[useSelectController] Истерфейс поиска`);
    /**
     * Масств значений полученных с сервер
     * @type {Ref<UnwrapRef<*[]>>}
     */
    const listServer = ref([]);
    /**
     * Массив значений выбраных пользователем
     * @type {Ref<UnwrapRef<*[]>>}
     */
    const list = ref([]);
    const searchSelectText = ref('');
    const autoIncrement = ref(1);
    const newValueList = ref([]);
    let searchEvent = () => {
        console.warn('Функция поиска не определенна')
    };

    const addSearchEvent = (event) => {
        if (typeof event === 'function') {
            if (inDebounce) {
                searchEvent = debounce(event, 600);
            } else {
                searchEvent = event;
            }

        } else
            console.warn('[addSearchEvent] Передать возможно только функцию')
    }
    const addSelect = () => {
        newValueList.value.push({
            name: searchSelectText.value.trim(),
            value: false,
            newValue: true,
            status: 'moderation',
            autoIncrement: autoIncrement.value++
        })
    }
    const addListSelect = (list) => {
        if (!Array.isArray(list))
            throw new Error('Возможно передать только массив');
        listServer.value = list.map(v => {
            if (v == null)
                v = { name: 'Пустой объект!!!!' };
            v.value = false;
            v.newValue = false;
            if (!v.autoIncrement)
                v.autoIncrement = autoIncrement.value++;
            return v;
        });

    }

    const selectValue = (selectValueItem, modelValue = false) => {
        if (selectOne) {
            list.value = [selectValueItem];
        } else {
            selectValueItem.forEach(v => {
                let index = null;
                if (v.newValue)
                    index = list.value.findIndex(vFilter => vFilter.name === v.name);
                else {
                    if (checkUnic !== null) {
                        index = list.value.findIndex(vFilter => checkUnic(vFilter, v));
                    } else {
                        index = list.value.findIndex(vFilter => vFilter.id === v.id);
                    }
                }
                if (index !== -1) {
                    if (!modelValue)
                        list.value.splice(index, 1);
                } else
                    list.value.push(v);
            })
        }
    }
    const removeSelect = (selectValueItem) => {
            if (!selectOne)
                selectValueItem.forEach(v => {
                    let index = null;
                    if (v.newValue)
                        index = list.value.findIndex(vFilter => vFilter.name === v.name);
                    else
                        index = list.value.findIndex(vFilter => vFilter.id === v.id);
                    if (index !== -1) {
                        list.value.splice(index, 1);
                    }
                })
        }
        /**
         * Если равно -1 то было созданно новое значения или условия поиска не выполенны.
         * В противном случаи вернет масив значений.
         * @returns {number|*}
         */
    const submitSelect = (allValue = false) => {
        if (isNewValue()) {
            if (!(newValue === false)) {
                addSelect();
                return -1;
            }
        }
        searchSelectText.value = null;
        if (allValue)
            return [...listAll().filter(v => v.value), ...getListServer(true)];
        return listAll().filter(v => v.value);
    }
    const isNewValue = () => {
        if (searchSelectText.value == null)
            return false;
        if (searchSelectText.value === "")
            return false;
        if (listAll() == null)
            return false;
        return listAll().filter(v => v.name === searchSelectText.value.trim()).length <= 0;
    }
    const isSelectValue = () => {
            return getSelectValue().filter(v => v.value === true).length > 0
        }
        /**
         * Получить массива значений сохраненных локально
         * @returns {*[]}
         */
    const getListServer = (uniqid = false) => {
        let array = [...list.value, ...newValueList.value];
        console.log('ddd', array);
        if (array == null)
            return [];
        if (uniqid === true)
            return array.filter(v => {
                if (v.newValue) {
                    return listServer.value.filter(listItem => listItem.name === v.name).length <= 0;
                } else {
                    return listServer.value.filter(listItem => listItem.id === v.id).length <= 0;
                }
            });
        return array;
    }
    const getSelectValue = () => {
        let array = [...listServer.value, ...newValueList.value];
        if (array == null)
            return [];
        array.map(v => {
            if (list.value.length > 0) {
                if (v.newValue) {
                    v.value = list.value.filter(listItem => listItem.name === v.name).length > 0;
                } else {
                    v.value = list.value.filter(listItem => listItem.id === v.id).length > 0;
                }
                return v;
            } else {
                v.value = false;
                return v;
            }
        })
        return array;
    };
    const listAll = () => {
        if (searchSelectText.value === "" || searchSelectText.value == null)
            return getSelectValue();
        if (localSearch) {
            return getSelectValue().filter(option => option.name
                .toString()
                .toLowerCase()
                .includes(searchSelectText.value.toString().toLowerCase()));
        } else {
            return getSelectValue()
        }
    }
    const searchSelect = (text) => {
        searchSelectText.value = text.trim();
        searchEvent(text);
    }
    console.groupEnd();
    return {
        removeSelect,
        submitSelect,
        addListSelect,
        listAll,
        searchSelect,
        addSearchEvent,
        selectValue,
        isNewValue,
        isSelectValue,
        getListServer
    }
}