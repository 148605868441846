import ApiUtil from '@/utils/api'
import {debounce} from "@/utils/helper";
export default {
 getWorkType: (data) =>
   ApiUtil(
     {
       url: `/api/work_type${data != null ? '?name=' + data : '' }`,
       method: 'get',
       requiresAuth: true,
     }
   )
}
