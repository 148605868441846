<template>
  <base-modal size="550" :is-open="isOpen" @close="closeModal">
    <div class="support-modal">
      <div class="support-modal__header">
        <h2 class="information__header">Обратный звонок</h2>
        <p class="information__subdescription">
          Или оставьте свои данные, и мы ответим на все ваши вопросы.
        </p>
      </div>
      <div class="support-modal__body">
        <div class="registration-form">
          <form class="registration-form__first-form">
            <base-input
              ref="m-name"
              :placeholder="$t('input.placeholder', { name: $t('name.name') })"
              v-model="name"
              name="name"
              class="registration-form__double mb-6"
              :description="`${$t('name.name-u')}`"
              :class="{ error: v$.name.$error }"
              :is-error="v$.name.$error"
              :error="[
                {
                  text: $t('pages.register.validate.name.required'),
                  status: v$.name.required.$invalid,
                },
                {
                  text: $t('validate.valid-name'),
                  status: v$.name.validName.$invalid,
                },
              ]"
            />
            <v-row class="mb-6">
              <v-col>
                <base-input
                  ref="m-phone"
                  type="phone"
                  v-model="user_phone"
                  @validPhone="validPhoneEvent"
                  :placeholder="$t('pages.register.phone-placeholder')"
                  :class="{ error: v$.user_phone.$error }"
                  :is-error="v$.user_phone.$error"
                  :error="[
                    {
                      text: $t('pages.register.validate.phone.required'),
                      status: v$.user_phone.required.$invalid,
                    },
                    {
                      text: $t('pages.register.validate.phone.valid-phone'),
                      status: v$.user_phone.validPhone.$invalid,
                    },
                  ]"
                ></base-input>
              </v-col>
            </v-row>
            <base-button button-type="button" :one-click-event="submitRegister"
              >Запросить обратный звонок</base-button
            >
          </form>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/BaseInput";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "ContactSupportModalHelp",
  components: { BaseButton, BaseModal, BaseInput },
  setup: (props) => {
    const v$ = useVuelidate({ $scope: false });
    return {
      v$,
    };
  },
  data() {
    return {
      isOpen: false,
      name: "",
      user_phone: "",
      inputValidPhone: false,
    };
  },

  methods: {
    showModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.name = "";
      this.user_phone = "";
      this.v$.$reset();
    },

    autoFocus(validate) {
      if (validate.$invalid) {
        for (let key in Object.keys(validate)) {
          const input = Object.keys(validate)[key];
          if (!input.includes("$")) {
            if (validate[input].$error) {
              try {
                this.$refs[`m-${input}`].focus();
                return true;
              } catch (e) {
                console.log(`m-${input}`);
                throw e;
              }
            }
          }
        }
      }
      return false;
    },

    validPhoneEvent(v) {
      this.inputValidPhone = v;
    },
    async submitRegister() {
      const result = await this.v$.$validate();
      if (!result) {
        this.autoFocus(this.v$);
        return;
      }
      const formData = {
        name: this.name,
        phone: this.user_phone,
      };
      try {
        await this.$store.dispatch("contact/postContactRequest", formData);
        this.closeModal();
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e.response.data.message);
      }
    },
    validName(value) {
      value = value.trim();
      if (value === "") return true;
      return /^[а-яёА-ЯЁa-zA-Z\-]+$/.test(value);
    },
    validPhone(value) {
      value = value.trim();
      if (value === "") return true;
      return this.inputValidPhone;
    },
    /**
     * @param {EventVerificationModalData} event
     */
  },
  validations() {
    return {
      name: {
        required,
        validName: this.validName,
      },
      user_phone: {
        required,
        validPhone: this.validPhone,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.support-modal {
  &__header {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 20px;

    .information {
      &__header {
        margin-bottom: 10px;
      }

      &__subdescription {
        line-height: 22px;
      }
    }
  }

  &__body {
    padding-top: 25px;
  }

  &__copy {
    display: grid;
    gap: 26px;
  }

  &__link {
    display: grid;
    gap: 16px;
    margin: 30px 0;

    &-item {
      padding: 14px 20px;
      border: 2px solid #eeeeee;
      border-radius: 10px;
      @extend %text-standart;
      font-weight: 500;
      color: $accent;

      svg {
        margin-right: 22px;
      }
    }
  }
}
</style>
