<template>
  <div class="request-list">
    <div class="container">
      <h1 class="request-list__header" :class="{ 'mobile-show': !showMobile }">
        {{ requestManageInfo.name }}
      </h1>
      <div class="request-list__wrapper">
        <div
          class="request-list__aside request-list__col"
          :class="{ 'mobile-show': !showMobile }"
        >
          <div class="request-list__tabs">
            <div class="request-list__tabs-control">
              <div
                v-if="peopleList?.length"
                class="request-list__tabs-control-item"
                :class="{ active: tabId === 1 }"
                @click="changeTab(1)"
              >
                Отклики
              </div>
              <div
                class="request-list__tabs-control-item"
                :class="{ active: tabId === 2 }"
                @click="changeTab(2)"
              >
                О проекте
              </div>
              <div>
                <base-button
                  light
                  small
                  round
                  class="mb-3 go-back"
                  v-if="!tablet"
                  @click="
                    $router.push({
                      path: '/projects/request',
                      hash: this.isInactive ? '#expired' : '#search',
                    })
                  "
                >
                  <svg-icon
                    class="arrow-left-near"
                    name="arrow-big-right"
                    :height="15"
                    :width="15"
                  />Вернуться назад</base-button
                >
              </div>
            </div>

            <div class="request-list__tabs-content">
              <div
                class="request-list__tabs-content-item"
                :class="{ active: tabId === 1 }"
              >
                <information-block-dynamic
                  v-if="!this.isInactive"
                  :icon="requestManageInfo.is_paid ? 'checkbox' : 'star'"
                  class="light-color mt-3"
                  :text="
                    requestManageInfo.is_paid
                      ? 'Выберите эксперта, с которым хотите вести проект, и нажмите “Начать проект”. Проект перейдет в статус “Активные”, а эксперту придет оповещение, что его выбрали на проект'
                      : 'Подпишите договор, чтобы получить возможность выбрать эксперта и начать проект'
                  "
                />
                <information-block-dynamic
                  v-if="this.isInactive"
                  :icon="requestManageInfo.is_paid ? 'checkbox' : 'star'"
                  class="light-color mt-3"
                  :text="'Если вы хотите продолжить поиск по заявке или выбрать эксперта,  нажмите “Возобновить поиск”'"
                />
                <div class="request-list__tabs-content-list">
                  <perfect-scrollbar>
                    <template v-if="onChecked?.length">
                      <item-people
                        v-for="item in onChecked"
                        :key="`request_checked_${item.id}`"
                        :id="item.id"
                        :name="item.user_name"
                        :company="item.user_company?.name_ru"
                        :photo="item.photo"
                        @click.stop="urlUser(item.id)"
                        @out-click="addItem(item.id)"
                        out-no-click
                        full-path
                      >
                        <template #action>
                          <base-radio
                            v-if="requestManageInfo.is_paid"
                            type="checkbox"
                            @change="updateIncrement(item)"
                            v-model="item.select"
                          ></base-radio>
                        </template>
                      </item-people>
                    </template>
                    <template v-if="noChecked?.length">
                      <item-people
                        v-for="item in noChecked"
                        :key="`request_${item.id}`"
                        :id="item.id"
                        :name="item.user_name"
                        :company="
                          item.is_viewed ? item.user_company?.name_ru : 'Не просмотрено'
                        "
                        :photo="item.photo"
                        @click.stop="urlUser(item.id)"
                        @out-click="addItem(item.id)"
                        out-no-click
                        full-path
                        :class="item.is_viewed ? '' : 'unChecked'"
                      >
                        <template #action>
                          <base-radio
                            v-if="requestManageInfo.is_paid"
                            type="checkbox"
                            @change="updateIncrement(item)"
                            v-model="item.select"
                          ></base-radio>
                        </template>
                      </item-people>
                    </template>
                  </perfect-scrollbar>
                  <div class="not-active">
                    <base-button
                      v-if="!this.isInactive"
                      :disabled="!requestManageInfo.is_paid || !this.onChecked.length"
                      :class="requestManageInfo.is_paid ? 'now-active' : ''"
                      @click="sendRequest"
                      >Начать проект</base-button
                    >
                    <base-button v-if="this.isInactive" @click="openExtend()"
                      >Возобновить поиск</base-button
                    >
                    <span
                      v-if="!this.isInactive"
                      :class="requestManageInfo.is_paid ? 'none' : 'prompt-text'"
                      >Возможность выбрать эксперта появится после подписания
                      договора</span
                    >
                  </div>
                </div>
                <div
                  class="request-list__tabs-content-empty"
                  v-if="!requestManageResponds.length"
                >
                  <not-item
                    title="Исполнители еще не найдены"
                    description="Мы отправили вашу заявку подходящим исполнителям. Нужно немного подождать, прежде чем они откликнутся на запрос."
                  ></not-item>
                </div>
              </div>
              <div
                class="request-list__tabs-content-item request-list__tabs-content-general"
                :class="{ active: tabId === 2 }"
              >
                <div class="request-list__project-meta" v-if="tabId === 2">
                  <div class="prolong">
                    <base-button v-if="!this.isInactive" @click="submitStop"
                      >Остановить поиск</base-button
                    >
                    <base-button v-if="this.isInactive" @click="openExtend()"
                      >Возобновить поиск</base-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="tabId === 1"
          class="request-list__content request-list__col"
          :class="{ 'mobile-show': showMobile }"
        >
          <template v-if="!showProfile">
            <not-item
              v-if="!requestManageResponds.length"
              title="Исполнители еще не найдены"
              description="Мы отправили вашу заявку подходящим исполнителям. Нужно немного подождать, прежде чем они откликнутся на запрос."
            ></not-item>
            <div v-if="requestManageResponds.length" class="no-item">
              <h1 class="mb-4">У вас есть отклики на проект!</h1>
              <ol class="textlist">
                <div class="item title">
                  <div class="num">1</div>
                  <div class="title">Изучите опыт экспертов</div>
                </div>

                <div class="item">
                  <div class="num">2</div>
                  <div>
                    <div class="title">Подпишите договор</div>
                  </div>
                </div>

                <div class="item">
                  <div class="num">3</div>
                  <div>
                    <div class="title">
                      Поговорите с экспертами и выберите финального исполнителя
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="num">4</div>
                  <div class="title">
                    Внесите обеспечительный платеж* за конкретного эксперта и начинайте
                    проект
                  </div>
                </div>
              </ol>
              *Обеспечительный платеж за проект – 30% от бюджета проекта. Обеспечительный
              платеж за часовую консультацию – 100% от ставки эксперта.
            </div>
          </template>
          <template v-else>
            <div class="request-list__profile-block">
              <div class="request-list__profile-header">
                <avatar
                  :fullname="showProfile.user_name"
                  :image="showAvatar"
                  size="140"
                />
                <div class="request-list__profile-meta">
                  <div class="request-list__profile-meta-line">
                    <li class="request-list__profile-name">
                      {{ showProfile.user_name }}
                    </li>
                    <base-button
                      light
                      small
                      round
                      width-auto
                      icon-postfix="arrow-big-right"
                      icon-postfix-size="15"
                      @click="toProfile(showProfile?.user_id, $route.params.id)"
                      >Смотреть профиль</base-button
                    >
                  </div>
                  <div class="contact">
                    <div v-if="getContact(showProfile?.user_id).phone">
                      <div class="new-contact-box">
                        <div class="contact-box number">
                          <div class="small">Телефон</div>
                          <div class="contact-row">
                            <p class="mr-2">
                              {{ getContact(showProfile?.user_id).phone }}
                            </p>
                            <svg-icon
                              class="pointer"
                              name="copy"
                              :width="20"
                              :height="20"
                              @click="copyInfo(getContact(showProfile?.user_id).phone)"
                            />
                          </div>
                        </div>
                        <div class="contact-box">
                          <div class="small">Почта</div>
                          <div class="contact-row">
                            <p class="mr-2">
                              {{ getContact(showProfile?.user_id).email }}
                            </p>
                            <svg-icon
                              class="pointer"
                              name="copy"
                              :width="20"
                              :height="20"
                              @click="copyInfo(getContact(showProfile?.user_id).email)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="small">
                      Здесь появятся контакты эксперта после подписания договора
                    </div>
                  </div>
                </div>
              </div>
              <div class="request-list__profile-content">
                <div class="request-list__request-content" v-if="!isRequestEmpty">
                  <base-button
                    @click="toProfile(showProfile?.user_id, $route.params.id)"
                    light
                    small
                    round
                    width-auto
                    icon-postfix="arrow-big-right"
                    icon-postfix-size="15"
                    class="request-list__profile-mobile-show"
                    >Смотреть профиль</base-button
                  >
                  <div
                    class="request-list__profile-block"
                    v-if="showProfile?.comment.length"
                  >
                    <div class="request-list__profile-block-title">Ключевой опыт</div>
                    <div class="request-list__profile-content-info">
                      <p style="white-space: pre-line">
                        {{ showProfile.comment }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="request-list__profile-block"
                    v-if="showProfile?.companies.length"
                  >
                    <div class="request-list__profile-block-title">Прошлые компании</div>
                    <div class="request-list__profile-content-info">
                      <p style="white-space: pre-line">
                        {{ showProfile.companies.map((el) => el.name).toString() }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="request-list__profile-block"
                    v-if="showProfile?.files?.length"
                  >
                    <div class="request-list__profile-block-title">Вложения</div>
                    <div class="request-list__profile-attachment">
                      <a
                        target="_blank"
                        :href="item.filepath"
                        class="request-list__profile-attachment-item"
                        v-for="item in showProfile.files"
                        :key="`attach_${item.id}`"
                        >{{ item.name }}</a
                      >
                    </div>
                  </div>
                  <div class="request-list__profile-block" v-if="showProfile?.salary">
                    <div class="request-list__profile-block-title">
                      Ставка эксперта с учетом комиссии Gigoo
                    </div>
                    <div class="request-list__profile-content-info budget">
                      {{
                        (showProfile?.salary * showProfile?.tax.percent).toLocaleString()
                      }}
                      <span class="ruble">е</span>
                    </div>
                    <div class="request-list__profile-block mt-6">
                      <case-list title="Релевантные кейсы" :cases="showProfile?.cases" />
                    </div>
                    <div class="request-list__profile-block">
                      <work-experience-list
                        title="Релевантный опыт"
                        :works="someUser.work"
                        :specialization="abilitiesReq"
                      />
                    </div>

                    <div class="request-list__profile-block">
                      <competence-list
                        ref="competenceList"
                        title="Компетенции"
                        :competence="expertisesReq"
                      />
                    </div>
                    <div class="request-list__profile-block">
                      <item-people
                        v-for="option in showProfile?.users"
                        :key="option.autoIncrement"
                        class="item"
                        :name="option.name"
                        :company="
                          option.user_company != null ? option.user_company.name : null
                        "
                        :rating="option.rating"
                        :photo="option.photo"
                        :invate="option.type === 'invited'"
                        can-disabled
                      >
                      </item-people>
                    </div>
                  </div>
                </div>
                <div class="request-list__choose">
                  <base-button
                    class="btn-back"
                    light
                    icon
                    icon-prefix="back-btn"
                    icon-postfix-size="21"
                    @click="mobileBack"
                  ></base-button>
                  <base-button
                    v-if="requestManageInfo.is_paid"
                    @click="addItem(showProfile.id)"
                    >{{
                      checkBtn ? "Отменить выбор" : "Выбрать исполнителя"
                    }}</base-button
                  >
                </div>
              </div>
            </div>
          </template>
        </div>
        <div
          v-else
          class="request-list__aside request-list__col"
          :class="{ 'mobile-show': !showMobile }"
        >
          <customer-block
            :showUser="false"
            :showLine="false"
            :forExpert="false"
            :info="
              filterDict(requestInfo, [
                'execution_period',
                'industries',
                'options',
                'sub_options',
                'service_type',
                'description',
                'files',
                'budget',
                'duration_id',
              ])
            "
          />
        </div>
      </div>
    </div>
    <alert-modal ref="dell" />
    <alert-modal ref="alert" />
    <alert-modal ref="alert_ok" />
    <extend-term-modal
      :is-open="isExtendModal"
      @close="closeModal"
      @closeMess="closeModalInfo"
    />
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import { mapGetters } from "vuex";
import ItemPeople from "@/components/ui/ItemPeople";
import Avatar from "@/components/ui/Avatar";
import ServiceTask from "@/components/ui/ServiceTask";
import CustomerBlock from "@/components/component/CustomerBlock";
import BaseRadio from "@/components/base/BaseRadio";
import Rating from "@/components/ui/Rating";
import NotItem from "@/components/ui/notItem";
import ExtendTermModal from "@/components/modals/ExtendTermModal";
import AlertModal from "@/components/modals/AlertModal";
import InformationBlockDynamic from "@/components/ui/InformationBlockDynamic";
import SvgIcon from "@/components/base/SvgIcon";
import { format } from "date-fns";
import ruLocale from "date-fns/locale/ru";
import { sortByKey, declOfNum } from "@/utils/helper";
import LsUtil from "@/utils/ls";
import WorkExperienceList from "@/components/component/WorkExperienceList";
import CompetenceList from "@/components/component/CompetenceList";
import CaseList from "@/components/component/CaseList";
import ContactSupportModalHelp from "@/components/modals/ContactSupportModalHelp.vue";
import { companyService } from "@/services";

export default {
  name: "RequestListPage",
  components: {
    AlertModal,
    SvgIcon,
    InformationBlockDynamic,
    ExtendTermModal,
    NotItem,
    Rating,
    BaseRadio,
    ServiceTask,
    Avatar,
    ItemPeople,
    BaseButton,
    CustomerBlock,
    WorkExperienceList,
    CompetenceList,
    CaseList,
  },
  data() {
    return {
      tabId: 1,
      profileId: null, // id анкеты отображаемой в правом столбце
      showMobile: false,
      peopleList: [],
      isExtendModal: false,
      autoIncrement: 1,
      isInactive: false,
    };
  },
  created() {
    this.getRequestList();
    this.getRequestManage();
    this.getRequestInfo();
    this.getRequestInfoContats();
  },
  watch: {
    someUser: {
      deep: true,
      immediate: true,
      handler(val, oldValue) {
        if (val.resume != null) this.resume = val.resume;
        if (val.phone != null) this.userData.phone = val.phone;
        if (val.email != null) this.userData.email = val.email;
        if (val.corp_email != null) this.userData.corp_email = val.corp_email;
        if (val.industries != null) this.industries_proj = val.industries;
        if (val.options != null) this.options_proj = val.options;
      },
    },
  },
  computed: {
    ...mapGetters({
      requestManageInfo: "request/requestManageInfo",
      requestManageResponds: "request/requestManageResponds",
      requestInfo: "request/requestInfo",
      requestContacts: "request/requestContacts",
      someUser: "profile/someUser",
      requestList: "request/requestList",
    }),
    abilitiesReq() {
      if (this.someUser.abilities) {
        let t = this.someUser.abilities;
        let in_t = this.showProfile.options.map((el) => el.id);
        return t.filter((el) => in_t.includes(el.option_id));
      }
      return [];
    },

    expertisesReq() {
      if (this.someUser.expertises) {
        let t = this.someUser.expertises.expertises;
        let in_t = this.showProfile.expertises.map((el) => el.id);

        t = t.filter((el) => in_t.includes(el.expertise_id)) || [];
        t = t.map((v) => ({
          ...v,
          ...{ name: v.expertise_name },
        }));
        return { expertises: t };
      }
      return [];
    },
    showProfile() {
      if (this.profileId && this.requestManageResponds) {
        const profile = this.requestManageResponds.filter((item) => {
          return item.id === this.profileId;
        });
        return profile[0];
      } else if (this.$route.query && this.requestManageResponds) {
        const profile = this.requestManageResponds.filter((item) => {
          if (item.id == this.$route.query.userId) {
            this.showMobile = true;
            this.profileId = item.id;
            return item.id == this.$route.query.userId;
          }
        });
        return profile[0];
      }
      return false;
    },
    showAvatar() {
      if (this.profileId && this.requestManageResponds) {
        const profile = this.requestManageResponds.filter((item) => {
          return item.id === this.profileId;
        });

        if (profile[0].photo) {
          return profile[0].photo;
        }
        return "";
      }

      return false;
    },
    noChecked() {
      this.isInactive = this.requestList.search_expired?.find(
        (el) => el.id == this.$route.params.id
      );
      let key = this.isInactive ? "search_expired" : "searching";
      let before = this.peopleList.filter((item) => !item.select);
      let responds = this.requestList;
      if (responds.hasOwnProperty(key)) {
        responds = responds[key].filter((el) => el.id == this.$route.params.id)[0]
          .responds;
      } else {
        responds = [];
      }
      responds = responds == undefined ? [] : responds;
      const mergeById = (a1, a2) =>
        a1.map((itm) => ({
          ...a2.find((item) => item.id === itm.id && item),
          ...itm,
        }));
      before = mergeById(before, responds);
      let result = before.sort((a, b) => {
        const aScore = a.is_favorite + a.is_viewed;
        const bScore = b.is_favorite + b.is_viewed;
        return bScore - aScore;
      });
      return result;
    },
    onChecked() {
      let before = this.peopleList.filter((item) => item.select);
      let result = sortByKey(before, "autoIncrement");
      result.reverse();
      return result;
    },
    finishDate() {
      if (this.requestManageInfo?.execution_period) {
        return format(new Date(this.requestManageInfo.execution_period), "d MMMM yyyy", {
          locale: ruLocale,
        });
      }
      return "";
    },
    dayForFinish() {
      if (this.requestManageInfo?.search_finish) {
        const diff =
          parseInt(
            (new Date(this.requestManageInfo?.search_finish) - new Date()) /
              1000 /
              60 /
              60 /
              24
          ) + 1;
        return diff > 0 ? diff : 0;
      }
      return 1;
    },
    finishSearch() {
      if (this.requestManageInfo?.search_finish && this.requestManageInfo?.created_at) {
        const diff =
          parseInt(
            (new Date(this.requestManageInfo.search_finish) -
              new Date(this.requestManageInfo.created_at)) /
              1000 /
              60 /
              60 /
              24
          ) + 1;

        return diff > 0 ? diff : 0;
      }
      return 1;
    },
    allPeriod() {
      if (this.requestManageInfo?.search_finish) {
        console.log(this.requestManageInfo);
        return parseInt(
          (new Date(this.requestManageInfo?.search_finish) - new Date()) /
            1000 /
            60 /
            60 /
            24
        );
      }
      return 1;
    },
    formDay() {
      if (this.dayForFinish) {
        return declOfNum(this.dayForFinish, ["день", "дня", "дней"]);
      }
      return "день";
    },
    formUse() {
      if (this.requestManageResponds.length) {
        return declOfNum(this.requestManageResponds.length, [
          "исполнитель",
          "исполнителя",
          "исполнителей",
        ]);
      }
      return "";
    },
    checkBtn() {
      return this.onChecked.findIndex((item) => item.id === this.showProfile.id) !== -1;
    },
    isRequestEmpty() {
      return (
        !this.showProfile.comment &&
        !this.showProfile.salary &&
        !this.showProfile.files.length === true
      );
    },
  },
  methods: {
    async toProfile(a, b) {
      await this.getSomeUserInfo(a);
      this.$router.push(`/profile/${a}/expert#${b}`);
    },
    filterDict(raw, allowed) {
      return Object.keys(raw)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {});
    },
    openServicePage(option) {
      this.$router.push({
        path: "/service-add/quick-view/" + option,
        query: this.$route.query,
      });
    },
    getNoRespondsText() {
      const respondsCount = this.requestManageResponds.length;
      const respondedStr = declOfNum(respondsCount, [
        "откликнулся",
        "откликнулось",
        "откликнулось",
      ]);

      return `На вашу заявку ${respondedStr} ${respondsCount} ${this.formUse}. Изучите их компетенции и выберите подходящих.`;
    },
    updateModelValue(v, index) {
      this.peopleList[index] = v;
    },
    changeTab(id) {
      this.tabId = id;
    },
    async getRequestInfo() {
      try {
        await this.$store.dispatch("request/getRequestInfoShort", this.$route.params.id);
      } catch (e) {
        if (e === 403) {
          this.$refs.alert.show(this.$t("alert.error"), e, {
            type: "error",
            nameButton: "Ок",
          });
        }
      }
    },
    async getRequestInfoContats() {
      try {
        await await this.$store.dispatch(
          "request/getRequestInfoContats",
          this.$route.params.id
        );
        console.log("--");
        console.log(this.requestContacts);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getRequestManage() {
      try {
        await this.$store.dispatch("request/getRequestManage", this.$route.params.id);
        this.peopleList = JSON.parse(JSON.stringify(this.requestManageResponds));

        if (!this.peopleList?.length) {
          this.tabId = 2;
        }

        if (this.requestManageInfo?.is_completed) {
          this.$router.push("/");
        }
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async urlUser(id) {
      this.showMobile = true;
      this.profileId = id;
      await this.getSomeUserInfo(this.showProfile?.user_id);
      this.$route.query = {
        userId: id,
      };
      this.getRequestInfoContats(id);
      this.viewRequest(id);
    },
    mobileBack() {
      this.showMobile = false;
    },
    openExtend() {
      this.submitExtend();
    },

    async getSomeUserInfo(id) {
      try {
        await this.$store.dispatch("profile/getSomeUserInfo", id);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },

    async viewRequest(id) {
      try {
        await this.$store.dispatch("request/viewRequest", {
          ids: [id],
          model: "respond",
        });
        await this.getRequestList();
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async submitExtend() {
      const payload = {};
      if (this.demandId) {
        payload.id = this.demandId;
      } else {
        payload.id = this.$route.params.id;
      }

      try {
        await this.$store.dispatch("request/sendRestart", payload);
        await this.$store.dispatch("request/getRequestList");
        await this.getRequestList();
        this.$refs.alert.show(
          "Успешно!",
          `Поиск по заявке продлен`,
          {
            type: "success",
            nameButton: "Ок",
            redirectPath: "/projects",
          },
          () => {
            this.getRequestList();
            this.$router.push({ path: "/projects" });
          }
        );
      } catch (e) {
        console.log("error");
      }
    },

    async stopSearch(id) {
      try {
        await this.$store.dispatch("request/sendStop", id);
      } catch (e) {
        console.log("error");
      }
    },
    async getRequestList() {
      try {
        await this.$store.dispatch("request/getRequestList");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    submitStop() {
      this.$refs.dell.show(
        "Остановить поиск исполнителей?",
        `Вы уверены, что хотите остановить поиск исполнителей?`,
        {
          type: "danger",
          nameButton: "Остановить",
          error: true,
        },
        () => {
          let id = -1;
          if (this.demandId) {
            id = this.demandId;
          } else {
            id = this.$route.params.id;
          }
          this.stopSearch({ id: id });
          this.getRequestList();
          this.$router.push({ path: "/projects" });
        }
      );
    },
    closeModal() {
      this.isExtendModal = false;
    },
    async eventClick(v, selected) {
      if (!this.requestManageInfo?.is_paid && v.is_viewed) {
        let payload = {
          demand_id: this.$route.params.id,
          respond_id: v.id,
          remove: !selected,
        };
        await this.$store.dispatch("request/toFavorite", payload);
        this.peopleList.map((el) => {
          if (payload.respond_id === el.id) {
            el.is_favorite = selected;
          }
          return el;
        });
      }
      return;
    },
    updateIncrement(item) {
      this.peopleList.map((el) => {
        if (item.id === el.id) {
          el.autoIncrement = this.autoIncrement;
          this.autoIncrement++;
        }
        return el;
      });

      this.updateLocalStore();
    },
    async sendRequest() {
      const payload = {};
      payload.demand_id = this.$route.params.id;
      payload.responds = this.onChecked.map((item) => {
        return item.id;
      });
      this.$store.dispatch("request/sendRequest", payload);
      this.$refs.alert.show(
        this.$t("alert.notification"),
        "Поздравляем, вы выбрали эксперта! Для старта проекта вам необходимо заполнить спецификацию и внести обеспечительный платеж. В ближайшее время с вами свяжется менеджер и поможет начать проект.",
        {
          type: "success",
          nameButton: "Понятно",
        },
        () => {
          try {
            this.$router.push({
              path: "/projects/request",
              hash: "#active",
            });
          } catch (e) {
            console.log(e);
          }
        }
      );
    },
    async askForPayment() {
      let crp = this.requestInfo.is_payment_by_url;
      try {
        this.$refs.alert.show(
          this.$t("alert.notification"),
          "Поздравляем, вы выбрали эксперта! Для старта проекта вам необходимо заполнить спецификацию и внести обеспечительный платеж. В ближайшее время с вами свяжется менеджер и поможет начать проект.",
          {
            type: "success",
            nameButton: "Понятно",
          },
          () => {
            this.sendRequest();
          }
        );
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e.message, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async copyInfo(text) {
      await navigator.clipboard.writeText(text);
    },
    getContact(id) {
      return this.requestContacts[id];
    },
    async getRequestInfoContats() {
      try {
        await await this.$store.dispatch(
          "request/getRequestInfoContats",
          this.$route.params.id
        );
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    addItem(id) {
      this.peopleList.map((item) => {
        if (item.id === id) {
          item.select = item.select ? !item.select : true;
        }
        return item;
      });

      this.updateLocalStore();
    },
    async closeModalInfo(e) {
      if (e === "success") {
        this.isExtendModal = false;
        this.$refs.alert.show(this.$t("alert.notification"), "Поиск успешно продлен", {
          type: "success",
          nameButton: "Ок",
        });
        await this.getRequestManage();
      } else {
        this.isExtendModal = false;
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    updateLocalStore() {
      let before = this.peopleList.filter((el) => el.select === true);
      let storeList = LsUtil.getItem("request") || {};

      storeList[this.$route.params.id] = before;

      LsUtil.setItem("request", storeList);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.unChecked {
  background-color: #f6f6f6;
}
.not-active {
  margin-top: 24px;
  position: relative;
  display: block;
  padding-right: 15px;
  margin-bottom: 20px;
  @include sm {
    margin-bottom: 90px;
  }
}
.not-active .prompt-text {
  padding: 6px 8px;

  visibility: hidden;
  width: 254px;
  background-color: #4b4c57;
  color: #ffffff;
  text-align: left;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  top: 110%;
  left: 17%;
  margin-left: -60px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  font-family: SFProDisplay;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 17.36px */
  letter-spacing: 0.084px;
  @include sm {
    box-shadow: none;
    margin-top: 12px;
    margin-left: 0px;
    left: 0%;
    right: 4%;
    visibility: visible;
    color: black;
    padding: 20px;
    gap: 8px;
    border: 1px solid $line-light-gray;
    border-radius: 12px;
    background-color: #f6f6f6;
    width: auto;
    font-size: 16px;
    letter-spacing: 0.096px;
    line-height: 22px; /* 137.5% */
  }
}
.not-active .prompt-text::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #4b4c57 transparent;
  @include sm {
    border-color: transparent transparent transparent transparent;
  }
}
.not-active:hover .prompt-text {
  visibility: visible;
}
.none {
  display: none;
}
.now-active {
  border-radius: 10px;
  background: #7fc666;
  border-color: #7fc666;
  margin-bottom: -30px;
  &:hover {
    background-color: #7fc666;
    border-color: #7fc666;
  }

  &:focus {
    background-color: #7fc666;
    border-color: #7fc666;
  }

  &:active {
    background-color: #7fc666;
    border-color: #7fc666;
  }
}

.go-back {
  margin-top: 23px;
}
.arrow-left-near {
  transform: rotate(-180deg);
  margin-bottom: 5px;
  margin-right: 5px;
}

.request-list {
  &__header {
    margin: 40px 0 30px;

    @include md {
      display: none;
    }

    &.mobile-show {
      @include md {
        display: block;
      }
    }
  }

  &__tabs {
    width: 100%;

    &-content-item {
      display: none;

      &.active {
        display: block;
      }
    }

    &-content-general {
      &.active {
        @include md {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 24px;

          .request-list__project-meta {
            padding-top: 20px;
          }

          .request-list__project {
            &-block {
              border-bottom: none;
              padding: 12px 0;
            }
          }
        }

        @include sm {
          grid-template-columns: 100%;
          gap: 0;

          .request-list__project-meta {
            padding: 0;
          }
        }
      }
    }

    &-content-list {
      padding-top: 40px;
      width: calc(100% + 15px);

      .ps {
        display: grid;
        gap: 16px;
        max-height: 90vh;
        padding-right: 15px;
      }
    }

    &-content-empty {
      display: none;

      @include md {
        display: block;
      }

      .not-item__description {
        margin-bottom: 0;
      }
    }

    &-btn {
      margin: 4px 0 24px;
    }

    &-control {
      &-item {
        display: inline-block;
        @include desktop_standart;
        margin-right: 24px;
        cursor: pointer;
        color: $text-gray;
        margin-bottom: 13px;

        &.active {
          color: $accent;
          position: relative;

          &:after {
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            bottom: -12px;
            border-bottom: 2px solid $accent;
            content: "";
          }
        }

        &.hide {
          display: none;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__choose {
    display: grid;
    margin-top: 40px;

    @include md {
      grid-template-columns: 56px 1fr;
      gap: 10px;
    }

    .btn-back {
      display: none;

      @include md {
        display: block;
      }
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 414px 1fr;
    gap: 16px;

    @include md {
      grid-template-columns: 100%;
      padding: 0;
    }

    &::v-deep .not-item__content {
      width: 100%;
    }
  }

  &__col {
    background-color: $white;
    border-radius: 24px;
    padding: 40px;

    @include md {
      display: none;
      padding: 30px 20px;
    }

    &.mobile-show {
      display: block;
    }

    &.request-list__content {
      @include md {
        background-color: transparent;
        padding: 0;
      }
    }
  }

  &__aside {
    align-self: flex-start;
  }

  &__project {
    padding-top: 16px;

    &-block {
      padding: 24px 0;
      border-bottom: 1px solid $dark-gray;

      &:last-child {
        border-bottom: none;
      }
    }

    &-term {
      width: 100%;
      display: flex;
      margin: 14px 0 30px;

      &-day {
        @include desktop_standart;
        margin-bottom: 8px;
      }

      &-finish {
        @include desktop_additional;
        color: $accent;
      }

      &-info {
        padding-left: 20px;
      }

      .ep-legend--value {
        height: 24px;
      }
    }

    &-title {
      color: $text-gray;
      @include desktop_additional;
      margin-bottom: 12px;
    }

    &-content {
      @include desktop_standart;

      &.budget {
        @include desktop_h1;
      }
    }
  }

  &__request-content {
    padding-bottom: 30px;
  }

  &__profile {
    &-header {
      display: flex;
      padding-bottom: 30px;
      border-bottom: 1px solid $line-light-gray;

      @include md {
        border-bottom: none;
      }

      @include sm {
        padding-top: 30px;
        flex-direction: column;
        align-items: center;
      }

      .avatar {
        flex-shrink: 0;
      }
    }

    &-meta {
      align-items: flex-start;
      padding-left: 40px;
      padding-top: 25px;
      width: 100%;
      box-sizing: border-box;

      &-line {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      }

      .button {
        @include sm {
          display: none;
        }
      }

      @include sm {
        padding-left: 0;
      }
    }

    &-name {
      @include desktop_h1;
      margin-right: 40px;
      width: fit-content;

      @include sm {
        width: 100%;
        margin-right: 0;
        text-align: center;
      }
    }

    &-tribe {
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;
      gap: 12px;

      &-item {
        background-color: $bg-light-gray;
        @include desktop_additional;
        padding: 10px 15px;
        border-radius: 8px;
      }
    }

    &-block {
      margin-bottom: 24px;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

      @include md {
        width: 100%;
      }

      &-title {
        color: $text-gray;
        @include desktop_standart;
        margin-bottom: 12px;
      }
    }

    &-attachment {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      &-item {
        background-color: $bg-light-gray;
        padding: 14px 15px;
        color: #000;
        border-radius: 8px;
        @include mobile_text;
      }
    }

    &-service {
      &-list {
        display: grid;
        gap: 16px;
      }
    }

    &-content {
      padding-left: 20px;
      padding-top: 30px;

      @include md {
        padding: 30px 20px;
        background-color: $white;
        border-radius: 24px;
      }

      h2 {
        @include desktop_h1;
        margin-bottom: 30px;
      }

      &-info {
        word-break: break-word;
        @include desktop_standart;

        &.budget {
          @include desktop_h1;
        }
      }
    }

    &-mobile-show {
      display: none;

      @include sm {
        display: block;
        margin-bottom: 30px;
      }
    }
  }
}

.customer-block {
  display: flex;
  flex-wrap: wrap;

  &:not(.special) {
    .customer-block__item {
      @include md {
        padding: 12px 0;
      }

      &.type {
        @include md {
          width: 50%;
          order: 2;
        }

        @include sm {
          width: 100%;
        }
      }
    }
  }

  &__item {
    width: 100%;
    padding-bottom: 15px;
  }

  &__title {
    @include desktop_additional;
    color: $text-gray;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &__content {
    p {
      @include desktop_standart;
      margin-bottom: 3px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.pointer {
  cursor: pointer;
  &:active {
    border-radius: 8px;
    animation: blink 0.1s 20 alternate;
  }

  @keyframes blink {
    from {
      background-color: #f6f6f6;
    }
    to {
      background-color: $green;
    }
  }
}
.contact-row {
  display: flex;
  flex-direction: row;
}
.contact {
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  padding: 17px 20px;
  background-color: #f6f6f6;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  font-size: 18px;
}
.contact-box {
  display: inline-block;
}
.number {
  margin-right: 20px;
}

.small {
  @include desktop_additional;
  color: $text-gray;
  font-size: 18px;
  margin-bottom: 8px;
}

.prolong {
  margin-top: 10px;
}
.text {
  @extend %text-standart;
  padding: 10px 0px;
  list-style-type: decimal;
}
li {
  list-style-type: none;
}

.item {
  border: 2px solid #eeeeee;
  border-radius: 12px;
  margin-bottom: 10px;
  color: #4b4c57;
  /* Desktop/Standart */
  font-family: SFProDisplay;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.108px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.title {
  color: #000;

  font-family: Muller;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 110% */
}

.num {
  padding: 5px 0;
  text-align: center;
  height: 30px;
  min-width: 30px;
  color: #ffffff;
  background-color: $green;
  border-radius: 50%;
  font-family: Muller;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 110% */
}
.no-item {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
}
.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto 10px;
  padding: 20px;

  @include lg {
    width: auto;
    min-width: auto;
  }

  @include md {
    padding: 24px;
  }

  @include sm {
    padding: 0 12px 0 12px;
    margin-bottom: 30px;
  }
}
</style>
