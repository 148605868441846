<template>
  <div
    v-if="show"
    class="information-block"
    :class="{ 'mobile-special-form': mobileSpecialForm }"
  >
    <div class="header mb-3">
      <svg-icon v-if="icon" :name="icon" :width="20" :height="20" class="gray" />
      <svg-icon
        class="pointer"
        name="cross"
        :width="20"
        :height="20"
        @click="show = !show"
      />
    </div>
    <p class="special-text">{{ text }}</p>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "InformationBlock",
  components: { SvgIcon },
  props: {
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    specialForm: {
      type: Boolean,
      default: false,
    },
    mobileSpecialForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.information-block {
  text-align: left;
  padding: 22px 14px;
  border: 1px solid $line-light-gray;
  border-radius: 12px;
  background-color: #f6f6f6;
  &.mobile-special-form {
    display: none;
    margin-bottom: 36px;

    svg {
      min-width: 27px;
    }

    p {
      @include mobile-text;
    }

    @include md {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }

  &.padding-equal {
    padding: 22px 14px;

    svg {
      margin-bottom: 16px;
    }
  }
  .gray {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  }
  p {
    @extend %text-standart;
    color: $input-label;

    &.special-text {
      font-family: SFProDisplay;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 122.222% */
      letter-spacing: 0.108px;
      color: $accent;
    }
  }
  .pointer {
    cursor: pointer;
  }
  svg {
    margin-bottom: 22px;
  }
  .header {
    display: flex;
    flex-direction: columns;
    justify-content: space-between;
  }
  &.light-color {
    padding: 19px;

    p {
      color: $accent;
      @include desktop_standart;
    }

    svg {
      margin-bottom: 15px;
    }
  }
}
</style>
