<template>
  <div class="main">
    <div class="title">Есть запрос на подбор экспертизы?</div>
    <div class="subtitle">Более 1000 экспертов готовы решить вашу задачу</div>
    <div class="block">
      <div class="point">
        <svg-icon name="clock-time-coins-deposit" width="48" height="48" />
        <div class="pointtext">Быстрый поиск</div>
      </div>
      <div class="point">
        <svg-icon name="hand-coins-currency" width="48" height="48" />
        <div class="pointtext">Единый договор</div>
      </div>
      <div class="point last">
        <svg-icon name="li_users" width="48" height="48" />
        <div class="pointtext">Опытные эксперты</div>
      </div>
    </div>

    <base-button @click="regCustomer" class="button--demo but is-mobile"
      >Оставить заявку</base-button
    >
    <div class="image_container">
      <img :src="$store.getters['auth/assets'] + 'img/experts.webp'" alt="" class="img" />
      <base-button @click="regCustomer" class="button--demo but is-no-mobile"
        >Оставить заявку</base-button
      >
    </div>
  </div>
</template>
<script>
import TwoColsGrig from "@/views/startpage/components/TwoColsGrig";
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "PreFooterBlockExpert",
  components: {
    TwoColsGrig,
    BaseButton,
    SvgIcon,
  },

  props: {},
  data() {
    return {};
  },

  methods: {
    regCustomer() {
      this.$store.commit("auth/SET_USERSTATE", "customer");
      this.$router.push({ name: "RegistrationPage" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.title {
  width: 80%;
  color: #fff;
  /* Muller 50px */
  font-size: 50px;
  font-family: Muller;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: -1px;
  padding-bottom: 15px;
  @include md {
    width: 100%;
    color: #fff;
    font-family: Muller;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px; /* 100% */
    letter-spacing: -0.34px;
    padding-top: 10px;
  }
}
.main {
  z-index: 6;
  display: block;
  padding: 0px 0px;
  width: 100%;
  @include sm {
    position: relative;
    display: inline-block;
    padding-top: 30px;
  }
}
.is-mobile {
  display: block;

  @include sm {
    display: none;
  }

  @include md {
    display: none;
  }
}
.image_container {
  position: relative;
  display: flex;
  height: 100%;
  margin: 0px;
  justify-content: center;
  align-items: flex-end;
  z-index: -1;
}
.img {
  object-fit: fit;
  position: absolute;
  height: 450px;
  bottom: -30px;
  right: 0;
  @include md {
    right: initial;
    clear: right;
    height: 270px;
    margin-left: 0px;
    bottom: -30px;
  }
}

.is-no-mobile {
  display: none;

  @include md {
    display: block;
    align-items: center;
  }
}

.subtitle {
  color: #fff;
  /* Muller 40px */
  font-family: Muller;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.56px;
  opacity: 0.800000011920929;
  padding-bottom: 30px;

  @include md {
    width: 100%;
    padding-bottom: 30px;
    color: #fff;
    /* Muller 24px */
    font-family: Muller;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 108.333% */
    letter-spacing: -0.48px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e6e6e6;
  }
}

.pointtext {
  color: var(--background, #fff);
  /* SF Pro Display Medium */
  font-size: 20px;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.2px;
  @include md {
    color: var(--background, #fff);
    font-family: SFProDisplay;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.16px;
  }
}

.but {
  max-width: 315px;
  margin-top: 30px;
  @include md {
    position: relative;
    margin-top: 150px;
    margin-bottom: 30px;
  }
}

.block {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include md {
    padding-top: 30px;
  }
}

.point {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
  gap: 10px;

  &.last {
    padding-bottom: 0px;
  }
}
</style>
