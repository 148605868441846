<template>
  <div class="blocks">
    <competence-card
      v-for="(c, idx) in cards"
      :key="idx"
      :icon-name="c.icon_name"
      :name="c.name"
      :chips="c.chips"
      class="block"
    ></competence-card>
  </div>
</template>
<script>
import CompetenceCard from "@/views/startpage/cards/CompetenceCard";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "TaskBlock",
  components: { CompetenceCard, BaseButton },
  props: {
    cards: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      isToggle: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
  methods: {
    toggle() {
      this.isToggle = !this.isToggle;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.blocks {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  @include sm {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

.block {
  display: flex;
  flex-direction: column;
  color: #000000;
  align-items: center;

  @include sm {
    width: 100%;
    flex-direction: column;
    text-align: left;
    border: 0px;
  }
}

.block:nth-child(-n + 3) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.block:nth-child(3n + 1) {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  @include sm {
    border-right: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.block:nth-child(3n) {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  @include sm {
    border-left: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.block:nth-child(n + 7) {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.is-no-mobile {
  display: none;

  @include sm {
    width: 100%;
    display: block;
    align-items: center;
    padding: 1px;
  }
}
</style>
