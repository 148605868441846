import auth from './auth';
import registration from './registration';
import content from "@/store/modules/content";
import service from "@/store/modules/service";
import company from "@/store/modules/company";
import profile from "@/store/modules/profile";
import work from "@/store/modules/work";
import questions from "@/store/modules/questions";
import request from "@/store/modules/request";
import contact from "@/store/modules/contact";
import status from "@/store/modules/status";
import pages from "@/store/modules/pages";

export default { auth, registration, content, service, company, work, profile, questions, request, contact, status, pages }