<template>
  <div class="input-group">
    <p v-if="description" class="description">{{ description }} <span v-if="descriptionStatus" class="description-status">{{ descriptionStatus }}</span></p>
    <div class="input-wrapper" :class="{ 'input-wrapper__error': isError }">
      <input
          v-imask="mask"
          class="input"
          @input="handleInput"
          :type="type"
          :value="modelValue"
          :placeholder="placeholder"
      />
      <svg-icon
          :width="iconPostfixSize"
          :height="iconPostfixSize"
          class="input-group__postfix"
          v-if="iconPostfix"
          :name="iconPostfix"
      ></svg-icon>
      <template v-if="showClear() && modelValue">
        <svg-icon name="cross" class="clear-input" @click="clearInput()"></svg-icon>
      </template>
      <span v-if="clear" class="input-clear">
        <svg-icon name="cross" />
      </span>
    </div>
    <div v-if="isError" class="input-error">{{ errorString }}</div>
    <div v-if="subDescription && !isError" :style="{
      '--input-group--sub-description--margin-button': getMarginButton + 'px'
    }" class="sub-description">{{ subDescription }}</div>
  </div>
</template>

<script>
import {IMaskDirective} from "vue-imask";
import SvgIcon from "@/components/base/SvgIcon";

export default {
  name: "BaseInputMask",
  components: {SvgIcon},
  props: {
    isError: {
      type: Boolean,
      default: false
    },
    /**
     * Иконка сначала
     */
    iconPostfix: {
      type: String,
      default: null
    },
    /**
     * Кнопка с прокруткой
     */
    range: {
      type: Boolean,
      default: false
    },    /**
     * Кнопка с прокруткой
     */
    search: {
      type: Boolean,
      default: false
    },
    /**
     * Размер иконки с конца
     */
    rangeMin: {
      type: [Number,String]
    },
    rangeMax: {
      type: [Number,String]
    },
    rangeStep: {
      type: [Number,String]
    },
    iconPostfixSize: {
      type: [Number, String],
      default: 24
    },
    modelValue: {
      type: [String, Number],
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    error: {
      type: Array,
      default() {
        return [{
          status: false,
          text: '',
        }]
      },
    },
    clear: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    },
    subDescription: {
      type: String
    },
    descriptionStatus: {
      type: String
    },
    maxTextarea: {
      type: [String, Number],
      default: 300
    },
  },
  computed: {
    getMarginButton() {
      if (this.range)
        return 16;
      return 10;
    },
    errorString() {
      return this.error
          .filter((error) => error.status)
          .map((error) => error.text)
          .join(". ")
    }
  },
  data() {
    return {
      value: '',
      rangeValue: 0,
      mask: {
        mask: Date,
        lazy: true
      },
    };
  },
  watch: {
    modelValue(v) {
      this.value = v;
    },
    value() {
      this.$emit("update:modelValue", this.value);
    },
    rangeValue(v) {
      this.$emit("update:modelValue", v);
    }
  },
  methods: {
    showClear() {
      if (this.type == 'textarea')
        return false;
      if (this.iconPostfix != null)
        return false;
      return true;
    },
    clearInput() {
      this.value = '';
      this.$emit("update:modelValue", '');
      this.$emit("clearClick")
      this.rangeValue = 0;
    },
    validPhone(v) {
      let valid = false;
      if (v.valid != null)
        valid = v.valid;
      this.$emit("validPhone", valid);
    },
    handleInput(e) {
      this.$emit("update:modelValue", e.target.value);
    }
  },
  directives: {
    imask: IMaskDirective
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';
.clear-input {
  margin-right: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.special-range.input-group .description,
.monthly-income-from.input-group .description {
  margin-bottom: 12px;
}

.special-range.input-group {
  margin-top: 32px;

  @include sm() {
    margin-top: 16px;
  }
}


.input-group {
  margin-bottom: 15px;
  &__search {
    margin-left: 23px;
    flex-shrink: 0;
  }
  &__count {
    position: absolute;
    bottom: 15px;
    right: 20px;
    font-size: 12px;
    opacity: 0.3;
  }
  &__range {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    padding: 0 20px;
  }
  .description {
    display: flex;
    @extend %text-standart;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 500;
    color: $input-label;
  }

  .description-status {
    margin-left: auto;
    font-size: 16px;
    color: $text-gray;
    font-weight: 400;
  }

  .sub-description {
    @extend %text-small;
    color: $text-gray;
    margin-top: var(--input-group--sub-description--margin-button);
  }

  .input-wrapper__error {
    border: 1px solid $error;
  }
  .input-clear {
    padding-left: 20px;
  }
  .input-error {
    color: #EF693F;
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  background: $bg-light-gray;
  border-radius: 12px;
  position: relative;
  .input {
    @include desktop_standart;
    width: 100%;
    padding: 17px 20px 17px 20px;
    border-radius: 12px;
    &.error {
      border-color: $error;
    }
  }
  .input-group__postfix {
    position: absolute;
    right: 8px;
  }
}

.no-resize textarea {
  resize: none;
}

</style>
