<template>
  <verification-modal
    header="Восстановление доступа"
    description="Укажите почту, введённую при регистрации"
    :is-open="isResetPassword"
    @close="isResetPassword = false"
    :placeholder="$t('component.verification-modal.input')"
    @submit="sendResetPassword"
    :size="440"
    class="reset-verification narrow"
  >
    <template v-slot:input>
      <base-input
        placeholder="E-mail"
        v-model="isResetPasswordEmail"
        :class="{ error: v$.isResetPasswordEmail.$error }"
        :is-error="v$.isResetPasswordEmail.$error"
        :error="[
          {
            text: 'E-mail обязателен',
            status: v$.isResetPasswordEmail.required.$invalid,
          },
          {
            text: 'некорректный E-mail',
            status: v$.isResetPasswordEmail.email.$invalid,
          },
        ]"
      />
    </template>
  </verification-modal>
  <alert-modal ref="alert">{{ noRegister ? "Ок" : "Закрыть" }}</alert-modal>
</template>

<script>
import BaseInput from "@/components/base/BaseInput";
import VerificationModal from "@/components/modals/VerificationModal";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import AlertModal from "@/components/modals/AlertModal";

export default {
  name: "ResetPasswordModal",
  setup: () => ({ v$: useVuelidate() }),
  components: { AlertModal, VerificationModal, BaseInput },
  data() {
    return {
      isResetPassword: false,
      isResetPasswordEmail: null,
    };
  },
  props: {
    noRegister: {
      type: Boolean,
      default: false,
    },
    // вернуться в предыдущее модальное окно
    goBack: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({ error: "auth/error" }),
  },
  methods: {
    /**
     * @param {EventVerificationModalData} event
     */
    async sendResetPassword(event) {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      await this.$store.dispatch("auth/forgotPassword", {
        email: this.isResetPasswordEmail,
      });
      this.isResetPassword = false;
      if (this.error != null) {
        if (this.goBack) {
          this.$refs.alert.show(
            this.$t("alert.error"),
            this.error,
            {
              type: "error",
            },
            () => {
              this.$refs.alert.closeModal();
              this.isResetPassword = true;
            }
          );
        } else {
          if (this.noRegister) {
            this.$refs.alert.show(
              this.$t("alert.error"),
              this.error,
              {
                type: "error",
              },
              () => {
                this.$refs.alert.closeModal();
              }
            );
          } else {
            this.$refs.alert.show(
              this.$t("alert.error"),
              this.error,
              {
                type: "error",
              },
              () => {
                //this.$router.push({ path: "/sign-up" });
                this.$refs.alert.closeModal();
              }
            );
          }
        }
      } else {
        this.$router.replace({
          name: "ResetPassword",
          query: { email: this.isResetPasswordEmail },
        });
        this.isAlert = true;
      }
    },
  },
  validations() {
    return {
      isResetPasswordEmail: {
        required,
        email,
      },
    };
  },
};
</script>

<style scoped lang="scss"></style>
