<template>
  <div class="block">
    <div class="tabs">
      <template v-for="(c, idx) in cases" :key="idx">
        <div @click="changeActive(idx)">
          <user
            class="tab"
            :is-flat="false"
            :name="c.name"
            :photo="c.photo_tab"
            :description="c.description"
            :is-active="tabs[idx]"
          />
        </div>
      </template>
    </div>
    <div class="expert">
      <template v-for="(c, idx) in cases" :key="idx">
        <expert-card-big
          :images="images"
          :chips="c.chips"
          :photo="c.photo"
          :task-name="c.taskName"
          :solution="c.solution"
          :lazy="idx > 1"
          v-if="tabs[idx]"
        ></expert-card-big>
      </template>
    </div>
  </div>
</template>

<script>
import User from "@/views/startpage/components/User";
import ExpertCardBig from "@/views/startpage/cards/ExpertCardBig";
export default {
  name: "ExpertBlock",
  components: {
    ExpertCardBig,
    User,
  },
  props: {
    cases: {
      type: Array,
      default: [],
    },
    images: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      tabs: {
        0: true,
        1: false,
        2: false,
        3: false,
      },
      active: 0,
    };
  },

  methods: {
    changeActive(n) {
      this.tabs[this.active] = false;
      this.active = n;
      this.tabs[this.active] = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.block {
  max-width: 1440px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  max-height: 100%;
  margin-bottom: 10px;
  width: 100%;
  @include md {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 30px 0px;
    padding-bottom: 0px;
  }
}

.expert {
  @include md {
    padding: 0px;
    width: 100%;
  }
}

.tabs {
  width: 100%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 10px;
  @include md {
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    gap: 10px;
  }

  @include sm {
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
  }
}

.tab {
  display: flex;
  text-align: left;
  justify-content: left;
  position: relative;
  @include md {
    justify-content: left;
    flex-wrap: nowrap;
    min-width: 100%;
    margin-right: 30px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
  }

  @include sm {
    flex-wrap: nowrap;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}
</style>
