<template></template>
<script>
import AuthService from '@/services/authService'
export default {
  name: "ImpersonatePage",
  components: {},
  data() {
    return {
      impersonateCreds: '',
    }
  },
  created() {
    this.getRequestInfo();
  },
  methods: {
    async getRequestInfo() {
      try {
        this.impersonateCreds = this.$route.params.id
        let signAnother = {}
        signAnother['id'] = this.impersonateCreds.split('-')[0]
        signAnother['hash'] = this.impersonateCreds.split('-')[1]
        const response = await AuthService.authImpersonate(signAnother); 
        //const response = await AuthService.auth({ email: 'a.saleev@amiga.agency', password: 'password' });
        await this.$store.dispatch('auth/signInImpersonate', response.data.token);
        this.$router.push({ name: 'StartPage' }).then(() => { this.$router.go() })
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>