<template>
  <base-modal size="440" :is-open="isOpen" @close="closeModal">
    <rate-member-component
      v-bind="$props"
      @submit="(...args) => $emit('submit', ...args)"
      @delete="(...args) => $emit('delete', ...args)"
      @changeItem="(...args) => $emit('changeItem', ...args)"
    ></rate-member-component>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import RateMemberComponent from "@/components/component/RateMemberComponent";

export default {
  name: 'RateMemberModal',
  components: {
    RateMemberComponent,
    BaseModal
  },
  props: {
    isOpen: {
      type: Boolean
    },
    isRated: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number
    },
    name: {
      type: String,
    },
    image: {
      type: String,
    },
    propsExpertises: {
      type: Array,
      default: () => [],
    },
    propsTribes: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  },
  watch: {
    propsExpertises() {
      console.log('propsExpertises');
      this.$forceUpdate();
    }
  }
}
</script>