<template>
  <div class="progress-bar">
    <div class="progress-bar__top" v-if="showText">
      <div class="progress-bar__percent">
        {{ progressResults }}{{ $t("pages.register.loading") }}
      </div>
      <div v-if="time != null" class="progress-bar__time">
        {{ $t("pages.register.time", { time: time }) }}
      </div>
    </div>
    <div class="progress-bar__line-wrapper">
      <div class="progress-bar__line" :style="{ width: progressResults + '%' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    time: {
      type: Number,
      default: null,
    },
    progress: {
      type: Number,
      default: 50,
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    progressResults() {
      if (this.progress > 100) {
        return 100;
      }
      return this.progress;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  margin-bottom: 10px;

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__line {
    display: block;
    height: 2px;
    background-color: $green;
    transition: 0.5s;

    &-wrapper {
      background-color: $line-light-gray;
    }
  }

  &__percent {
    @extend %text-standart;
  }

  &__time {
    @extend %text-standart;
    color: $text-gray;
  }
}
</style>
