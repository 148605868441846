<template>
  <base-modal class="industry-dialog" :is-open="isOpen" size="840" @close="closeModal">
    <div class="industry-modal overflow-y-auto">
      <div class="registration-industry">
        <div class="d-flex justify-space-between ">
          <h2>{{ $t('component.industry.title') }}</h2>
        </div>
        <base-error-text
            :class="{ error: industryValidate }"
            :error="[{
            text: this.$t('component.industry.industry-validate'),
            status: industryValidate,
          }]"
        />
        <div>
          <template v-for="(industry, idx) in industryArray" :key="industry.autoIncrement">
            <select-industry
                full
                class="mb-5"
                v-model="industryArray[industryArray.indexOf(industry)]"
                :industry-list="industriesList"
                :functions-list="optionsList"
                @addFunction="scrollToDown"
            >
              <base-button class="button--light registration-form__dell-industry" @click="dellIndustry(industry)"
                           icon icon-prefix="dell-item"></base-button>
            </select-industry>
          </template>
        </div>
        <add-button @click="addIndustry" class="registration-form__add-industry">{{
            $t('component.industry.btn')
          }}
        </add-button>
      </div>
    </div>
    <v-row>
      <v-col>
        <base-button @click="saveIndustry" class="mt-2">{{ $t('btn.save') }}</base-button>
      </v-col>
    </v-row>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";
import SelectIndustry from "@/components/ui/SelectIndustry";
import {mapGetters} from "vuex";
import SvgIcon from "@/components/base/SvgIcon";
import AddButton from "@/components/ui/button/AddButton";
import BaseErrorText from "@/components/base/BaseErrorText";

export default {
  name: "IndustryModal",
  components: {
    BaseErrorText,
    AddButton,
    SvgIcon,
    SelectIndustry,
    BaseButton,
    BaseModal,
  },
  data() {
    return {
      isOpen: false,
      isLoad: false,
      autoIncrement: 1,
      industryArray: [{autoIncrement: 0}],
      industryValidate: false,
    }
  },
  created() {
    this.getDataToStep();
  },
  computed: {
    ...mapGetters({
      industriesList: 'registration/industriesList',
      optionsList: 'registration/optionsList',
      isAuth: 'auth/isAuth',
    }),
    industryArrayForm() {
      let newIndustryArray = [];
      if (this.industryArray != null && this.industryArray.length > 0) {
        this.industryArray.forEach(industry => {
          let newOptions = [];
          let newSubFunction = [];
          if (industry.options != null) {
            if (industry.options.length > 0) {
              industry.options.forEach(vOptions => {
                let optionsOptions = [];
                if (vOptions.options != null) {
                  optionsOptions = vOptions.options.filter(v => v.newValue === false).map(v => v.id);
                  newSubFunction = vOptions.options.filter(v => v.newValue === true).map(v => {
                    return {
                      parent_id: vOptions.id,
                      name: v.name,
                    }
                  });
                }
                newIndustryArray.push({
                  id: industry.id,
                  options: optionsOptions,
                  new_options: newSubFunction
                })
              })
            }
          }
        });
      }
      return {
        "industries": newIndustryArray
      }
    }
  },
  methods: {
    industryValidateCheck() {
      let check = false;
      if (this.industryArray == null)
        check = false
      else if (this.industryArray.length <= 0)
        check = true
      else {
        this.industryArray.forEach(v => {
          if (v.options == null)
            check = true;
          else if (v.options.length <= 0)
            check = true;
          else if (v.options[0].id === null)
            check = true;
        })
      }
      this.industryValidate = check
      return check;
    },
    async saveIndustry() {
      if (this.isLoad)
        return;
      setTimeout(() => {
        this.isLoad = false
      }, 5000)
      this.isLoad = true;
      if (this.industryValidateCheck() === true) {
        this.isLoad = false;
        return;
      }
      await this.$store.dispatch('registration/sendUserProfile', this.industryArrayForm)
      console.log(this.error);
      if (this.error != null) {
        this.$refs.alert.show(
            this.$t('alert.error'),
            this.error,
            {
              type: 'error'
            }
        );
        this.isLoad = false
      } else {
        this.isOpen = false;
        this.isLoad = false
        await this.$store.dispatch('auth/getUserData')
      }
    },
    getDataToStep() {
      if (this.isAuth) {
        this.getIndustries();
        this.getOptions();
      }
    },
    async getIndustries() {
      await this.$store.dispatch('registration/getIndustries')
    },
    async getOptions() {
      await this.$store.dispatch('registration/getOptions')
    },
    dellIndustry(index) {
      this.industryArray = this.industryArray.filter(item => item !== index);
    },
    addIndustry() {
      this.industryArray.push({
        autoIncrement: ++this.autoIncrement
      });

      this.scrollToDown();
    },
    show() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
    scrollToDown() {
      this.$nextTick(() => {
        const container = this.$el.querySelector('.industry-modal');
        container.scrollTop = container.scrollHeight;
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';

.dell-industry {
  padding-bottom: 16.5px;
  padding-top: 15px;
}

.registration-form {
  &__add-industry {
    width: 100%;
  }
}

.industry-modal {
  min-height: 71vh;
  max-height: 71vh;
  padding: 12px;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    & {
      min-height: 50vh;
      max-height: 50vh;
    }
  }

}
</style>
