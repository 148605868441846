<template>
  <component
    :is="isComponent"
    :type="buttonType"
    :to="simpleLink === false ? href : null"
    :href="href ? href : null"
    :target="simpleLink ? '_blank' : null"
    class="button"
    :class="classes"
    @click="ClickEvent"
  >
    <div class="button--body">
      <div class="prefix-slot" v-if="iconPrefix">
        <svg-icon
          :width="iconPostfixSize"
          :height="iconPostfixSize"
          class="prefix"
          :name="iconPrefix"
        ></svg-icon>
      </div>
      <div v-else class="prefix"></div>
      <div class="button--slot">
        <slot />
      </div>
      <template v-if="$slots.postfix">
        <slot name="postfix"></slot>
      </template>
      <template v-else>
        <svg-icon
          :width="iconPostfixSize"
          :height="iconPostfixSize"
          class="postfix"
          v-if="iconPostfix"
          :name="iconPostfix"
          @click="clickPostfix()"
        ></svg-icon>
        <div v-else class="postfix"></div>
      </template>
    </div>
  </component>
</template>

<script>
import SvgIcon from "./SvgIcon";
import oneRequest from "@/hooks/oneReqest";

/**
 * @displayName BaseButton
 */
export default {
  name: "BaseButton",
  components: { SvgIcon },
  props: {
    oneClickEvent: null,
    options: {
      type: Object,
      default: {},
    },
    /** Кнопка в вие иконки */
    icon: Boolean,
    /** Кнопка в вие иконки */
    positionLeft: Boolean,
    /** Уменьшенная кнопка */
    text: Boolean,
    /** Уменьшенная кнопка */
    small: Boolean,
    /** Уменьшенная кнопка 12px */
    extraSmall: Boolean,
    /** Уменьшенная кнопка */
    midle: Boolean,
    /** Редизайн кнопки */
    redesign: Boolean,
    /** Минимальное закругления */
    cube: Boolean,

    /** Аутентификации стиль */
    auth: Boolean,

    /** Максимальное закругления */
    round: Boolean,
    /** Для статуса */
    status: Boolean,
    /** Кнопки нужной высоты для соц-сетей */
    social: Boolean,
    /** Тип кнопки (button, submit и т.д.) */
    buttonType: {
      type: String,
      default: null,
    },
    /** Ссылка */
    href: {
      type: String,
      default: null,
    },
    /** Ссылка обычная */
    simpleLink: {
      type: Boolean,
      default: false,
    },
    /**
     * Кнопка выключена?
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Иконка сначала
     */
    iconPrefix: {
      type: String,
      default: null,
    },
    /**
     * Иконка сначала
     */
    iconPostfix: {
      type: String,
      default: null,
    },
    /**
     * Размер иконки с начала
     */
    iconPostfixSize: {
      type: [Number, String],
      default: 24,
    },
    /**
     * Дополнительный класс который вешается на иконку
     */
    iconClass: {
      type: String,
      default: null,
    },
    /**
     * Ширина иконки в кнопке
     */
    iconWidth: {
      type: Number,
      default: 15,
    },
    /**
     * Высота иконки в кнопке
     */
    iconHeight: {
      type: Number,
      default: 15,
    },
    /**
     * кнопки наследуют свои границы от TODO текущего примененного цвета.
     */
    outlined: Boolean,
    outlined2: Boolean,
    middleBig: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    widthAuto: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { requestFn } = oneRequest();
    return {
      requestFn,
    };
  },
  data() {
    return {
      optionsData: {
        ...{
          add: false,
          test: false,
        },
        ...this.options,
      },
    };
  },
  created() {
    if (this.optionsData.add === true) {
    }
  },
  methods: {
    ClickEvent() {
      if (this.oneClickEvent != null) {
        this.requestFn(this.oneClickEvent, { one: true });
      }
    },
    clickPostfix() {
      this.$emit("postfix");
    },
  },
  computed: {
    isComponent() {
      return this.href ? (this.simpleLink ? "a" : "router-link") : "button";
    },
    classes() {
      return {
        "button--default": this.hasBg,
        "button--auth": this.auth,
        "button--light": this.light,
        "button--error": this.error,
        "button--text": this.text,
        "button--status": this.status,
        "button--outlined": this.outlined,
        "button--outlined-sec": this.outlined2,
        "button--disabled": this.disabled,
        "button--small": this.small,
        "button--extra-small": this.extraSmall,
        "button--midle": this.midle,
        "button--middle-big": this.middleBig,
        "button--icon": this.icon,
        "button--redesign": this.redesign,
        "button--cube": this.cube,
        "button--round": this.round,
        "button--social": this.social,
        "button--position-left": this.positionLeft,
        "button--width-auto": this.widthAuto,
      };
    },
    hasBg() {
      return !this.text && !this.outlined && !this.outlined2 && !this.redesign;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
$color: #4b4c57;

.button {
  @include mobile_h3;
  text-align: center;
  padding: 18px 25px;
  border-radius: 12px;
  border: none;
  width: 100%;
  outline: none;
  display: block;
  @include sm {
    display: flex;
    justify-content: space-around;
    align-items: center;
    //max-height: 40px;
    //padding: 4px 8px;
  }
  .prefix {
    flex-shrink: 0;
    margin-right: auto;

    &-slot {
      margin-right: auto;
    }
  }

  .postfix {
    margin-left: auto;
    flex-shrink: 0;
  }

  .prefix-slot {
    padding-right: 8px;
  }

  &--slot {
    display: block;
    width: 100%;
  }
  &--outlined-sec {
    background-color: $bg-light-gray;
    color: $color;
    display: block;
    transition: 0.3s;

    &:hover {
      background-color: #6fb057;
      color: $white;
    }

    &:focus {
      background-color: #6fb057;
    }

    &:active {
      background-color: #6fb057;
    }
  }

  &--outlined {
    color: $color;
    display: block;
    border: 1px solid #42434e55;
    @include sm {
      justify-content: space-between;
      flex-direction: row;
      align-content: middle;
      align-items: center;
      vertical-align: middle;
    }
    &:hover {
      background-color: $bg-light-gray;
      border: 1px solid $line-light-gray;
    }

    &:focus {
      background-color: $white;
      border: 1px solid $text-gray;
    }

    &:active {
      background-color: $dark-gray;
      border: 1px solid $line-light-gray;
    }
  }

  &--default {
    color: #fff;
    background-color: $color;
    border: 2px solid $color;
    padding-top: 18px;
    padding-bottom: 18px;
    transition: 0.3s;

    &:hover {
      background-color: $header-text;
      border: 2px solid $header-text;
    }

    &:focus {
      background-color: $accent;
      border: 2px solid $black;
    }

    &:active {
      background-color: $btn-active;
      border: 2px solid $btn-active;
    }
  }

  &--default.button--disabled {
    background: $line-light-gray;
    border: 0px solid transparent;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  &--demo {
    color: $bg-light-gray;

    &:hover {
      background-color: $bg-light-gray;
      border: none;
    }

    &:focus {
      background-color: $white;
      border: none;
    }

    &:active {
      background-color: $dark-gray;
      border: none;
    }
  }

  &--success {
    color: $bg-light-gray;

    &:hover {
      background-color: #6fb057;
      border: none;
    }

    &:focus {
      background-color: #6fb057;
      border: none;
    }

    &:active {
      background-color: $dark-gray;
      border: none;
    }
  }

  &--auth {
    color: $bg-light-gray;

    &:hover {
      background-color: $white;
      border: none;
    }
    &:focus {
      background-color: $bg-light-gray;
      border: none;
    }
    &:active {
      background-color: $bg-light-gray;
      border: none;
    }
  }

  &--light {
    color: $bg-light-gray;

    &:hover {
      background-color: $bg-light-gray;
      border: none;
    }

    &:focus {
      background-color: $white;
      border: none;
    }

    &:active {
      background-color: $dark-gray;
      border: none;
    }
  }

  &--error {
    background-color: $error;
    border-color: $error;

    &:hover {
      background-color: $error;
      border-color: $error;
      opacity: 0.8;
    }

    &:focus {
      background-color: $error;
      border-color: $error;
      opacity: 0.8;
    }

    &:active {
      background-color: $error;
      border-color: $error;
      opacity: 0.7;
    }
  }

  &--body {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &--redesign {
    padding: 18px 24px;
    border-radius: 12px;
    font-family: "SFProDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    width: auto;
    align-items: center;
    text-align: center;
    color: #42434e;
    border: none;
    transition: 0.1s all linear;
    background: #ffffff;
    letter-spacing: 0.5px;

    &:active {
      background: #cecece;
      border: none;
      transition: 0.2s all linear;
    }

    @include md {
      padding: 7px 14px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      background: #ffffff;
    }
  }

  &--cube {
    border-radius: 6px;
  }

  &--status {
    cursor: text;
    white-space: pre-wrap;
    overflow: hidden;
    padding: 10px 20px;
    color: $color;
    background-color: $dark-gray;
    font-size: 18px !important;
    padding-left: 2px !important;
    text-align: left !important;
    border: 0px solid lighten($color, 62%);
    display: block;

    @include md {
      text-align: center !important;
    }

    @include sm {
      text-align: center !important;
    }

    &:hover {
      background-color: $bg-light-gray;
      border: 0px solid lighten($color, 62%);
    }

    &:focus {
      background-color: $white;
    }

    &:active {
      background-color: $dark-gray;
    }
  }

  &--round {
    border-radius: 30px;
  }

  &--icon {
    padding: 14px;

    .button--body {
      display: block;
    }
  }

  slot {
    display: flex;
    margin: 1px;
    padding: 5px;
    background: #aaa;
  }

  &--midle {
    @include desktop_additional;
    padding: 10px 16px;
  }

  &--middle-big {
    @include desktop_additional;
    padding: 10px 16px;
    font-size: 18px;
  }

  &--small {
    @include mobile_text;
    padding: 10px 20px;
  }

  &--extra-small {
    @include mobile_text_small;
    padding: 4px 14px;
  }

  &--width-auto {
    width: auto;

    .postfix {
      margin-left: 12px;
    }
  }

  &--text {
    background: inherit;
    color: $accent;
    padding: 0;

    .prefix {
      margin-right: 8px;
    }
  }

  &--demo {
    color: #7fc666;
    background-color: #f6f6f6;
    border: none;
  }

  &--success {
    color: #f6f6f6;
    background-color: #7fc666;
    border: none;
    &:active {
      background: #6fb057;
      border: none;
      transition: 0.2s all linear;
    }
  }

  &--auth {
    color: #42434e;
    border-radius: 12px;
    background: var(--Tiles, #f6f6f6);
    display: block;
    width: 151px;
    height: 50px;
    padding: 17px 24px;
    border: 0px;
  }

  &--light {
    color: $header-text;
    background-color: #f6f6f6;
    border: none;
  }

  &--social {
    padding: 18px 24px;
    @include sm {
      padding: 18px 8px;
    }
  }

  &--disabled {
    background: $line-light-gray;
    color: $text-gray;
    pointer-events: none;
  }

  &--icon .prefix {
    &-slot {
      padding-right: 0;
    }
  }

  &--position-left .prefix {
    margin-right: inherit;
  }

  &--position-left &--body {
    justify-content: flex-start;
  }

  &--big-left {
    .postfix {
      padding-left: 0;
    }

    .button--slot {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
    }
  }
}
</style>
