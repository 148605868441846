<template>
  <base-modal ref="modal" size="550" :is-open="isOpen" @close="closeModal">
    <div class="request-recommendation">
      <h2 class="request-recommendation__header">
        {{ "Запрос " + (isEstimate ? "оценки " : "рекомендации") }}
      </h2>
      <div class="request-recommendation__body">
        <div class="request-recommendation__list-info-group">
          <base-list-info
            v-for="item in infoArray"
            :header="item.body?.length ? item.header : ''"
            :body="item.body"
            class="request-recommendation__list-info"
          />

          <div v-if="type === 'industry'" class="request-recommendation__hr" />
          <vue-single-select-rows
            v-if="type === 'industry'"
            header="Выбрать роль"
            subtitle="Выберите роль, которую вы исполняли в компании"
            description="Добавить роль"
            placeholder="Выберите роль"
            class="request-recommendation__roles-select-rows"
            description-status="необязательно"
            :items="rolesProps"
            v-model="works"
            :new-value="false"
            searchEmptyText="Ничего не найдено. Измените условия поиска"
          ></vue-single-select-rows>
          <vue-single-select-rows
            v-if="type === 'industry'"
            :new-value="true"
            header="Выбрать компанию"
            description="Компания"
            placeholder="Выберите компанию"
            description-status="необязательно"
            :items="companiesProps"
            v-model="companies"
            class="request-recommendation__companies-select-rows"
            searchEmptyText="Ничего не найдено. Измените условия поиска"
          ></vue-single-select-rows>
        </div>
      </div>
    </div>
    <template v-slot:body1>
      <perfect-scrollbar>
        <template v-if="newUser">
          <perfect-scrollbar>
            <div class="add-people-team__people">
              <base-input
                class="request-recommendation__base-input"
                v-model="user.email"
                description="Электронная почта"
                :class="{ error: v$.user.email.$error }"
                :is-error="v$.user.email.$error"
                :error="[
                  {
                    text: $t('pages.register.email-validate'),
                    status: v$.user.email.required.$invalid,
                  },
                  {
                    text: $t('validate.email'),
                    status: v$.user.email.email.$invalid,
                  },
                ]"
              />
              <base-input
                class="request-recommendation__base-input"
                v-model="user.lastName"
                description="Фамилия"
                :is-error="v$.user.lastName.$error"
                :class="{ error: v$.user.lastName.$error }"
                :error="[
                  {
                    text: $t('validate.required-2', { name: $t('name.last-name-u') }),
                    status: v$.user.lastName.required.$invalid,
                  },
                  {
                    text: 'Максимальная длина - 30 символов',
                    status: v$.user.lastName.maxValue.$invalid,
                  },
                  {
                    text: $t('validate.valid-name'),
                    status: v$.user.lastName.validName.$invalid,
                  },
                ]"
              />
              <base-input
                class="request-recommendation__base-input"
                v-model="user.name"
                description="Имя"
                :class="{ error: v$.user.name.$error }"
                :is-error="v$.user.name.$error"
                :error="[
                  {
                    text: $t('pages.register.validate.name.required'),
                    status: v$.user.name.required.$invalid,
                  },
                  {
                    text: 'Максимальная длина - 30 символов',
                    status: v$.user.name.maxValue.$invalid,
                  },
                  {
                    text: $t('validate.valid-name'),
                    status: v$.user.name.validName.$invalid,
                  },
                ]"
              />
            </div>
          </perfect-scrollbar>
        </template>
        <template v-else>
          <div class="request-recommendation_multy">
            <people-search
              v-model="userList"
              :searth="searchText"
              :multyInput="true"
              @checkNewUser="checkNewUser"
              @searchTextEvent="searchTextEvent"
            />
          </div>
        </template>
      </perfect-scrollbar>
      <div class="request-recommendation__action-btn">
        <base-button
          v-if="newUser"
          icon
          icon-prefix="back-btn"
          class="button--light request-recommendation__back-btn"
          @click="backUserList"
        ></base-button>
        <base-button :disabled="disableBtn()" @click="submit">{{ btnName }}</base-button>
      </div>
    </template>
    <alert-modal ref="alert">Ок</alert-modal>
  </base-modal>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";
import BaseListInfo from "@/components/base/BaseListInfo";
import PeopleSearch from "@/components/component/PeopleSearch";
import ItemPeople from "@/components/ui/ItemPeople";
import { email, maxLength, required } from "@vuelidate/validators";
import BaseInput from "@/components/base/BaseInput";
import BaseDropdown from "@/components/base/BaseDropdown";
import VueSingleSelectRows from "@/components/ui/VueSingleSelectRows";
import { mapGetters } from "vuex";
import { debounce } from "@/utils/helper";
import AlertModal from "@/components/modals/AlertModal";
import useSelectController from "@/hooks/useSelectController";

export default {
  name: "RequestRecommendationArrayModal",
  components: {
    AlertModal,
    VueSingleSelectRows,
    BaseDropdown,
    BaseInput,
    PeopleSearch,
    BaseListInfo,
    BaseButton,
    BaseModal,
    ItemPeople,
  },
  props: {
    rolesProps: [], // Роль
    companiesProps: [], // Компании
    expertise: {
      type: Array,
    }, // Компетенция
    industryId: null,
    optionId: null,
    isOpen: {
      type: Boolean,
      default: false,
    },
    isEstimate: {
      type: Boolean,
      default: false,
    },
    infoArray: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup(props) {
    const unic = (vFilter, v) => {
      return vFilter.id === v.id && vFilter.type === v.type;
    };

    const {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      addSearchEvent,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect,
      getListServer,
    } = useSelectController({
      selectOne: false,
      newValue: false,
      localSearch: false,
      checkUnic: unic,
    });
    addSearchEvent(props.searchEvent);
    return {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect,
      getListServer,
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      searchText: "",
      open: false,
      vCheckNewUser: true,
      newUser: false,
      works: [],
      companies: [],
      searchWorkTypeDebounce: null,
      searchCompanyDebounce: null,
      userList: [],
      user: {
        name: "",
        lastName: "",
        email: "",
        phone: "not mentioned",
      },
    };
  },
  mounted() {
    this.getCompany();
    this.getWork();
  },
  created() {
    this.searchCompanyDebounce = debounce(this.getCompany, 400);
    this.searchWorkTypeDebounce = debounce(this.getWork, 400);
  },
  computed: {
    ...mapGetters({
      workTypeList: "work/workType",
      companiesList: "company/companies",
      userInvite: "auth/userInvite",
      userId: "auth/user",
    }),
    btnName() {
      if (this.newUser) return "Добавить рекомендателя";
      else if (this.vCheckNewUser) return "Добавить рекомендателя";
      else return "Отправить";
    },
    getValueUser() {
      let user = [];
      if (this.userList.length > 0) {
        user = this.userList.filter((v) => v.select);
      }
      return user;
    },
    getValueListExpertise() {
      return {
        confirm_type: "recommendation",

        rater_user_id: this.getValueUser,
      };
    },
  },
  methods: {
    peopleTeamFormat({ id, type, name, rating, company, photo, need_approve }) {
      return {
        name: name != null ? name : "Имя ОШИБКА!!!", //TODO: Заменить данные
        company: company != null ? company.name : "",
        rating: rating != null ? rating : 4.8, //TODO: Заменить данные
        id: id,
        type: type,
        photo: photo,
        need_approve: need_approve,
      };
    },
    updateSearch(e) {
      console.log("e", e);
      this.searchSelect(e);
    },
    getValueListIndustry() {
      let payload = [],
        unitedPayload = [];
      const errorText = "[getValueListIndustry] ОШИБКА!!!";
      if (this.infoArray[1]?.data.length > 0) {
        if (this.infoArray[1]?.data[0].type !== "service") {
          unitedPayload = this.infoArray[1].data.map((item) => {
            return {
              type: "expertise",
              expertise_id: item.id == null ? errorText : item.id,
            };
          });
        } else {
          unitedPayload = this.infoArray[1].data.map((item) => {
            return {
              type: "service",
              service_id: item.id == null ? errorText : item.id,
            };
          });
        }
      }
      if (this.infoArray[0]?.data.length > 0) {
        unitedPayload = unitedPayload.concat(
          this.infoArray[0].data.map((item, index) => {
            return {
              type: "tribe",
              industry_id: item.industry_id == null ? errorText : item.industry_id,
              option_id: item.option_id == null ? errorText : item.option_id,
            };
          })
        );
      }

      this.getValueUser.forEach((item, index) => {
        unitedPayload.forEach((val, index) => {
          let tmp = JSON.parse(JSON.stringify(val));
          if (item.type == "invited") {
            tmp.rater_user_id = undefined;
            tmp.invite_rate_user = item.id;
            tmp.is_invited = true;
          } else {
            tmp.rater_user_id = item.id;
            tmp.invite_rate_user = undefined;
            tmp.is_invited = false;
          }
          payload.push(tmp);
        });
      });
      return payload;
    },
    searchTextEvent(v) {
      this.searchText = v;
    },
    searchEventWorkType(v) {
      this.$store.dispatch("work/getWorkType", v);
    },
    searchEventCompany(v) {
      this.$store.dispatch("company/getCompany", v);
    },
    getWork() {
      this.$store.dispatch("work/getWorkType", null);
    },
    async getCompany(val) {
      await this.$store.dispatch("company/getCompany", val);
    },
    updateWorkTypeSearch(e) {
      this.searchWorkTypeDebounce(e);
    },
    updateCompanySearch(e) {
      this.searchCompanyDebounce(e);
    },
    backUserList() {
      this.newUser = false;
      this.$refs.modal.updateSize();
      this.searchText = this.user.email;
      this.user = {
        name: "",
        lastName: "",
        phone: "not mentioned",
        email: "",
      };
    },
    validName(value) {
      if (value === "") return true;
      return /^[а-яёА-ЯЁa-zA-Z\-]+$/.test(value);
    },
    checkNewUser(v) {
      this.vCheckNewUser = v;
    },
    closeModal() {
      this.newUser = false;
      this.getValueUser = null;
      this.works = null;
      this.companies = null;
      this.searchText = "";
      this.userList = [];
      this.v$.$reset();
      this.$emit("close");
    },
    addUserInvite(id) {
      this.$emit("showModalLink", this.userInvite.email);
      this.$store.commit("auth/SET_USER_INVITE", null);
    },
    async request() {
      try {
        await this.$store.dispatch(
          "profile/postProfileWorksInviteRates",
          this.getValueListIndustry()
        );
        this.$emit("successRequest");
      } catch (e) {
        throw e;
      }
    },
    disableBtn() {
      if (this.newUser) {
        return false;
      } else {
        let picked = this.userList.filter((item) => item.select == true);
        if (this.vCheckNewUser) return false;
        else if (picked.length === 0) return true;
        else return false;
      }
    },
    async submit() {
      if (this.newUser) {
        const result = await this.v$.$validate();
        if (!result) {
          console.log(this.v$);
          return;
        }
        try {
          await this.$store.dispatch("auth/postUserInvite", this.user);
          this.addUserInvite();

          this.backUserList();
        } catch (e) {
          this.$refs.alert.show(this.$t("alert.error"), e, {
            type: "error",
            nameButton: "Ок",
          });
        }
      } else if (this.vCheckNewUser) {
        this.newUser = true;
        if (this.searchText.toString().toLowerCase().includes("@"))
          this.user.email = this.searchText;
        else this.user.email = "";
        this.searchText = "";
        this.$refs.modal.updateSize();
      } else {
        if (this.getValueUser == null) {
          this.$refs.alert.show("Ошибка", "Выберите пользователя", {
            type: "error",
          });
          return 0;
        }
        await this.request();
        this.closeModal();
      }
    },
  },
  watch: {
    isOpen(newValue, oldValue) {
      this.open = newValue;
    },
  },
  validations() {
    return {
      user: {
        name: {
          required,
          validName: this.validName,
          maxValue: maxLength(30),
        },
        lastName: {
          required,
          validName: this.validName,
          maxValue: maxLength(30),
        },
        email: {
          required,
          email,
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

.request-recommendation {
  &__base-input {
    margin-bottom: 23px;
  }

  &__header {
    @include desktop_h1;
    text-align: left;
    margin-bottom: 24px;

    @include sm {
      margin-bottom: 0px;
    }
  }

  &__action-btn {
    display: flex;
    grid-template-columns: 1fr;
  }

  &__back-btn {
    width: auto;
    margin-right: 8px;
  }

  &__body {
    max-height: 52vh;
  }

  &__list-info-group {
    @include desktop_description;
  }

  &__roles-select-rows {
    margin-bottom: 22px;
  }

  &__companies-select-rows {
    margin-bottom: -12px;
  }

  &__hr {
    margin: 24px 0;
    border-top: 1px solid $dark-gray;
  }

  .ps {
    max-height: 50vh;
  }

  .base-list-info {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.request-recommendation_multy {
  .ps {
    max-height: 50vh;
  }
}
</style>
