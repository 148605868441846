<template>
  <base-modal size="550" :is-open="isOpen" @close="closeModal">
    <h2 class="information__header">
      {{ type === "link" ? "Добавить ссылку" : "Добавить вложение" }}
    </h2>
    <template v-if="type === 'file'">
      <base-file
        id="publications"
        ref="publications"
        class="validate-competence__base-file"
        @file="updateData"
        file-type=".jpg,.png,.pdf,.docx,.doc,.rtf,.pptx,.ppt,.msg"
        :is-error="v$.file.$error"
        :error="[
          // {
          //   text: 'Неверный тип файла',
          //   status: v$.file.checkType.$invalid,
          // },
          {
            text: 'Загрузите вложение',
            status: v$.file.required.$invalid,
          },
          {
            text: 'Максимальный размер файла - 50мб',
            status: v$.file.checkSize.$invalid,
          },
          {
            text: 'Добавьте не более 5 файлов',
            status: v$.file.maxLength.$invalid,
          },
        ]"
      />
    </template>
    <template v-else>
      <base-input
        placeholder="Ваша ссылка"
        v-model="link"
        description="Вставьте ссылку"
        :is-error="v$.link.$error"
        :error="[
          {
            text: 'Поле обязательно для заполнения',
            status: v$.link.required.$invalid,
          },
          {
            text: 'Введите корректную ссылку',
            status: v$.link.isLink.$invalid,
          },
        ]"
      />
    </template>

    <base-input
      class="publications__textarea no-resize"
      type="textarea"
      :description="`Введите описание ${type === 'file' ? 'вложения' : 'ссылки'}`"
      :placeholder="`Опишите ${type === 'file' ? 'вложение' : 'ссылку'}`"
      :sub-description="myDescription"
      v-model="description"
      :is-error="v$.description.$error"
      :max-textarea="200"
      :error="[
        {
          text: 'Максимальная длина - 200 символов',
          status: v$.description.maxLength.$invalid,
        },
      ]"
    />
    <base-button @click="submit">Добавить</base-button>
    <alert-modal ref="alert" />
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseInput from "@/components/base/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import BaseFile from "@/components/base/BaseFile";
import useVuelidate from "@vuelidate/core";
import { maxLength, required } from "@vuelidate/validators";
import AlertModal from "@/components/modals/AlertModal";
import BaseErrorText from "@/components/base/BaseErrorText";

export default {
  name: "PublicationsModal",
  components: { BaseErrorText, AlertModal, BaseFile, BaseButton, BaseInput, BaseModal },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  data() {
    return {
      isOpen: false,
      type: "link",
      link: "",
      description: "",
      file: "",
      clicked: false,
    };
  },
  computed: {
    showAdd() {
      if (this.type === "link") {
        return this.link.length >= 10 ? true : false;
      }
      if (this.type === "file") {
        return !!this.file;
      }
      return false;
    },
    myDescription() {
      if (this.type === "link") {
        return "Придумайте и напишите подробнее о вашей ссылке.";
      }
      if (this.type === "file") {
        return "Придумайте и напишите подробнее о вашем вложении. Не забудьте указать ссылки, привести примеры.";
      }
      return "";
    },
  },
  methods: {
    closeModal() {
      this.isOpen = false;
    },
    showModal(e) {
      this.v$.$reset();
      this.clicked = false;
      this.type = e;
      this.description = "";
      this.link = "";
      this.file = "";
      this.isOpen = true;
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }

      this.clicked = true;

      // let data;
      let data = new FormData();
      if (this.type === "link") {
        data.append("type", this.type);
        data.append("link", this.link);
        data.append("description", this.description);
      }

      if (this.type === "file") {
        data.append("publication_file", this.file);
        // data = this.file;
        data.append("type", this.type);
        data.append("description", this.description);
      }

      try {
        await this.$store.dispatch("profile/sendPublications", data);
        this.$emit("success");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
        this.clicked = false;
      }

      console.log("valid");
    },
    isLink(link) {
      if (link) {
        try {
          return Boolean(new URL(link));
        } catch (e) {
          return false;
        }
      }
      return true;
    },
    async updateData(e) {
      this.file = e;
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
    },
    checkSize(e) {
      if (e !== null) {
        return e.size < 50 * 1024 * 1024;
      } else {
        return true;
      }
    },
  },
  validations() {
    if (this.type === "link") {
      return {
        link: {
          required,
          isLink: this.isLink,
        },
        description: {
          maxLength: maxLength(200),
        },
      };
    }
    if (this.type === "file") {
      return {
        description: {
          maxLength: maxLength(200),
        },
        file: {
          checkSize: this.checkSize,
          maxLength: maxLength(1),
          required,
        },
      };
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.information__header {
  @include desktop_h2_norm;
  margin-bottom: 10px;
}

.button {
  margin-top: 12px;
}

.base-file__wrapper {
  margin-bottom: 24px;
}
</style>
