import LsUtil from '@/utils/ls';
import { LS_PAGE } from '@/utils/ls/keys'
export default {
  namespaced: true,
  state: {
    pageNumber: {
      invite: 1,
      search: 1,
      active: 1,
      completed: 1,
      expired: 1,
    },
  },
  actions: {
    async setPageNumberByKey({ commit }, payload) {
      console.log("KEY VALUE", payload);
      let response = LsUtil.getItem(LS_PAGE);
      console.log("RESPONSE", response);
      if (response === null) {
        commit('INIT_PAGENUMBER');
        response = LsUtil.getItem(LS_PAGE);
      }
      response = { ...response, ...payload }
      commit('SET_PAGENUMBER', response);
    },
  },

  mutations: {
    INIT_PAGENUMBER(state) {
      LsUtil.setItem(LS_PAGE, state.pageNumber)
    },
    SET_PAGENUMBER(state, value) {
      LsUtil.setItem(LS_PAGE, value)
    },
  },
  getters: {
    pageNumber(state) {
      return LsUtil.getItem(LS_PAGE)

    },
  }
}
