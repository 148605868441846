<template>
  <div class="select_language">
    <base-dropdown-menu class="narrow" top :home="currentLocale.toUpperCase()">
      <p v-for="item in supportLocales" @click="() => currentLocale = item">{{ item.toUpperCase() }}</p>
    </base-dropdown-menu>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import {useI18n} from "vue-i18n";
import {ref, watch} from "vue";
import {loadLocaleMessages, setI18nLanguage, SUPPORT_LOCALES} from "@/i18n";
import BaseDropdownMenu from "@/components/base/BaseDropdownMenu";
import {useStore} from "vuex";
export default {
  name: "SelectLanguage",
  components: {BaseDropdownMenu, SvgIcon},
  setup() {
    const store = useStore();
    const i18n = useI18n()
    const currentLocale = ref(i18n.locale.value)
    watch(currentLocale, async (val) => {
      if (!i18n.availableLocales.includes(val)) {
        await loadLocaleMessages(i18n, val);
        await setI18nLanguage(i18n, val);
      } else {
        await setI18nLanguage(i18n, val);
      }
      store.commit("auth/SET_LANG", val);
    })
    return { currentLocale, supportLocales: SUPPORT_LOCALES }
  },
}
</script>

<style scoped lang="scss">
  .select_language {
    color: $header-text;
    margin-left: 10px;
    display: flex;
    cursor: pointer;
    svg-icon {
      margin-left: 5px;
    }

    .base-dropdown__list {
      width: auto;
      min-width: auto;
    }
  }
</style>
