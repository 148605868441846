<template>
  <container>
    <template v-slot:nav>
      <v-tabs direction="vertical" v-model="tabsOutcome">
        <v-tab href="#tribe" value="tribe">Специализации</v-tab>
        <v-tab href="#expertise" value="expertise">Компетенции</v-tab>
        <v-tab href="#service" value="service">Услуги</v-tab>
      </v-tabs>
    </template>
    <template v-slot:body>
      <div
        id="tribe"
        v-intersect="{
          handler: onTribe,
          options: {
            threshold: [0.7],
          },
        }"
      >
        <h1 class="block-header">Специализации</h1>
        <div class="recommendation-list">
          <p v-if="!currentLength['tribe']" class="empty_text">
            У вас пока нет рекомендаций
          </p>
          <response-recommendation
            v-for="item in paginatedSlice(userRecommendation, 'tribe')"
            :key="`recommendation_${item.id}`"
            :title-composition="item"
            :description="item.email"
            :name="item.title"
            :status="item.status"
          >
            <template v-if="item.status === 'sent'" #btn>
              <base-button
                v-if="checkDelta(item.remind_available_at)"
                class="button--light"
                width-auto
                small
                @click="remindRecommendation(item.id)"
                >Напомнить</base-button
              >
              <p class="reminder_text" v-else>{{ minDelta(item.remind_available_at) }}</p>
            </template>
          </response-recommendation>
        </div>
        <v-pagination
          v-if="currentLength['tribe'] > recommendationsPerPage"
          v-model="currentPage['tribe']"
          :length="Math.ceil(currentLength['tribe'] / recommendationsPerPage)"
        ></v-pagination>
      </div>
      <div
        id="expertise"
        v-intersect="{
          handler: onExpertise,
          options: {
            threshold: [0.7],
          },
        }"
      >
        <h1 class="block-header-second">Компетенции</h1>
        <div class="recommendation-list">
          <p v-if="!currentLength['expertise']" class="empty_text">
            У вас пока нет рекомендаций
          </p>
          <response-recommendation
            v-for="item in paginatedSlice(userRecommendation, 'expertise')"
            :key="`recommendation_${item.id}`"
            :title-composition="item"
            :description="item.email"
            :name="item.title"
            :status="item.status"
          >
            <template v-if="item.status === 'sent'" #btn>
              <base-button
                v-if="checkDelta(item.remind_available_at)"
                class="button--light"
                width-auto
                small
                @click="remindRecommendation(item.id)"
                >Напомнить</base-button
              >
              <p v-else class="reminder_text">{{ minDelta(item.remind_available_at) }}</p>
            </template>
          </response-recommendation>
        </div>
        <v-pagination
          v-if="currentLength['expertise'] > recommendationsPerPage"
          v-model="currentPage['expertise']"
          :length="Math.ceil(currentLength['expertise'] / recommendationsPerPage)"
        ></v-pagination>
      </div>
      <div
        id="service"
        v-intersect="{
          handler: onService,
          options: {
            threshold: [0.7],
          },
        }"
      >
        <h1 class="block-header-second">Услуги</h1>
        <div class="recommendation-list">
          <p v-if="!currentLength['service']" class="empty_text">
            У вас пока нет рекомендаций
          </p>
          <response-recommendation
            v-for="item in paginatedSlice(userRecommendation, 'service')"
            :key="`recommendation_${item.id}`"
            :title-composition="item"
            :description="item.email"
            :name="item.title"
            :status="item.status"
          >
            <template v-if="item.status === 'sent'" #btn>
              <base-button
                v-if="checkDelta(item.remind_available_at)"
                class="button--light"
                width-auto
                small
                @click="remindRecommendation(item.id)"
                >Напомнить</base-button
              >
              <p v-else class="reminder_text">{{ minDelta(item.remind_available_at) }}</p>
            </template>
          </response-recommendation>
        </div>
        <v-pagination
          v-if="currentLength['service'] > recommendationsPerPage"
          v-model="currentPage['service']"
          :length="Math.ceil(currentLength['service'] / recommendationsPerPage)"
        ></v-pagination>
      </div>
    </template>
  </container>
  <alert-modal ref="alert"></alert-modal>
</template>

<script>
import Container from "@/components/layout/Container";
import BaseButton from "@/components/base/BaseButton";
import { mapGetters } from "vuex";
import AlertModal from "@/components/modals/AlertModal";
import ResponseRecommendation from "@/components/component/ResponseRecommendation";
export default {
  name: "ProfileRecommendationRequestPage",
  components: { ResponseRecommendation, AlertModal, Container, BaseButton },
  data() {
    return {
      tabsOutcome: "tribe",
      currentPage: {
        tribe: 1,
        expertise: 1,
        service: 1,
      },
      currentLength: {
        tribe: 1,
        expertise: 1,
        service: 1,
      },
      recommendationsPerPage: 7,
    };
  },
  computed: {
    ...mapGetters({
      userRecommendation: "profile/userRecommendation",
    }),
  },
  created() {
    this.getUserRecommendation();
    this.tabsOutcome = "tribe";
  },
  methods: {
    async getUserRecommendation() {
      try {
        this.$store.dispatch("content/isLoading", true);
        await this.$store.dispatch("profile/getUserRecommendation");
        this.$store.dispatch("content/isLoading", false);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async remindRecommendation(id) {
      try {
        await this.$store.dispatch("profile/remindRecommendation", { id: id });
        this.successRequest();
        await this.getUserRecommendation();
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },

    successRequest() {
      this.$refs.alert.show(
        "Рекомендации запрошены",
        "Мы повторно отправили запрос на рекомендации",
        {
          type: "success",
          nameButton: "Хорошо",
        }
      );
    },
    hourDelta(date) {
      let currDate = new Date();
      let nextDate = new Date(date);
      let delta = currDate - nextDate;
      return delta;
    },

    minDelta(date) {
      let currDate = new Date();
      let nextDate = new Date(date);
      let deltaMin = Math.floor((nextDate - currDate) / 1000 / 60);
      let deltaHour = Math.floor(deltaMin / 60);
      if (deltaMin < 60) {
        return "Повторное напоминание через " + deltaMin + " минут";
      } else {
        return "Повторное напоминание через " + deltaHour + " часов";
      }
    },

    checkDelta(date) {
      let delta = Math.floor(this.hourDelta(date) / 1000 / 60 / 60);
      return delta >= 0;
    },
    onTribe(entries) {
      if (entries) {
        this.$router.push({ hash: "#tribe" });
        this.tabsOutcome = "tribe";
      }
    },
    onExpertise(entries) {
      if (entries) {
        this.$router.push({ hash: "#expertise" });
        this.tabsOutcome = "expertise";
      }
    },
    onService(entries) {
      if (entries) {
        this.$router.push({ hash: "#service" });
        this.tabsOutcome = "service";
      }
    },
    paginatedSlice(items, type) {
      const statusOrder = ["sent", "approve", "declined"];

      const filteredItems = items.filter((item) => item.type === type);
      const sortedItems = filteredItems.sort((a, b) => {
        const statusA = statusOrder.indexOf(a.status);
        const statusB = statusOrder.indexOf(b.status);
        return statusA - statusB;
      });

      this.currentLength[type] = sortedItems.length;

      const startIndex = (this.currentPage[type] - 1) * this.recommendationsPerPage;
      const endIndex = startIndex + this.recommendationsPerPage;

      return sortedItems.slice(startIndex, endIndex);
    },
  },
};
</script>

<style scoped lang="scss">
.block-header {
  margin-bottom: 30px;
}

.block-header-second {
  margin-top: 30px;
  margin-bottom: 30px;
}

.recommendation-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.empty_text {
  color: $text-gray;
  font-size: 18px;
}

.reminder_text {
  color: $text-gray;
  font-size: 12px;
}
</style>
