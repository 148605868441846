<template>
  <div v-if="isMain" class="block">
    <div class="group-task">
      <div class="subtitle">Задача</div>
      <div class="title">{{ task }}</div>
    </div>
    <div class="group-solution">
      <div class="subtitle">Решение</div>
      <div class="solution">{{ solution }}</div>
    </div>
    <div class="solution">
      <user :name="name" :description="description" :photo="photo" class="user"></user>
    </div>
  </div>

  <div v-else class="block main img_container">
    <div>
      <h1 class="title">Решим вашу задачу</h1>
      <div class="solution-demo">
        Подберем лучших экспертов, которые помогут быстро закрыть вашу потребность на
        гибких условиях.
      </div>
    </div>
    <img
      :src="
        $store.getters['auth/assets'] +
        'img/bussiness-people-working-in-team-in-an-office.png'
      "
      alt=""
      class="img"
      loading="lazy"
    />
    <div class="solve-button">
      <base-button
        @click="this.$router.push({ name: 'RegistrationPage' })"
        class="button--demo but"
        >Оставить заявку</base-button
      >
    </div>
  </div>
</template>
<script>
import Avatar from "@/components/ui/Avatar";
import BaseButton from "@/components/base/BaseButton";
import User from "@/views/startpage/components/User";
export default {
  name: "TaskCard",
  components: {
    Avatar,
    BaseButton,
    User,
  },
  computed: {},
  props: {
    task: {
      type: String,
      default: "",
    },
    solution: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    photo: {
      type: String,
      default: "",
    },
    isMain: {
      type: Boolean,
      default: true,
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.block {
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 30px;
  background: #f6f6f6;
  border-radius: 20px;
  flex-direction: column;
  justify-content: baseline;
  overflow: hidden;

  margin-bottom: 10px;

  &.main {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(90deg, #6fb057 0%, #86cd6d 68.92%);
  }

  @include md {
    padding: 20px;
    margin: 0px 10px;
  }
}
.but {
  position: relative;
  bottom: 0px;
}
.group-task {
  height: 50%;
}

.group-solution {
  height: 30%;
}
.img_container {
  display: block;
  z-index: 0;
  position: relative;
}

.img {
  position: absolute;
  right: 0px;
  bottom: -10px;
  z-index: -1;
}

.title {
  position: relative;
  display: flex;
  font-size: 26px;
  font-family: Muller;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.52px;
  margin-bottom: 25px;
  margin-top: 12px;
  @include md {
    margin-bottom: 0px;
    font-family: Muller;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.solution {
  font-family: SFProDisplay;
  padding-top: 10px;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.18px;

  &-demo {
    font-size: 16px;
  }
  @include md {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: -0.096px;
  }
}

.solve-button {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;
  font-size: 14px;

  &__status {
    color: $text-gray;
  }
}

.subtitle {
  font-family: SFProDisplay;
  padding-top: 10px;
  color: $text-gray;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.18px;
  @include md {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: -0.096px;
  }
}
.user {
  @include md {
    display: none;
  }
}
</style>
