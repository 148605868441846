<template>
  <div>
    <div class="header" :class="{ 'header_special': specialForm }">
      <avatar class="avatar-desktop" :fullname="name" :image="getPhoto == null ? '' : getPhoto" size="140" />
      <avatar class="avatar-mobile" :fullname="name" :image="getPhoto == null ? '' : getPhoto" size="60" />
      <h1 :class="{ 'name-special': specialForm }">
        {{ name }}
      </h1>
    </div>
    <information-block v-if="formInput" icon="mm-crown" class="padding-equal" :text='helpText' :special-form="specialForm"
      :mobile-special-form="specialForm" />
    <h1 class="title" :class="{ 'title-special': specialForm }" v-if="propsExpertises.length">
      Услуги
    </h1>
    <rating-input v-once v-for="(service, index) of mapDataToType(this.propsServices, 'service')"
      :key="service.id + service.name" :title="service.name" :prev-rate="service?.is_rated ? service.rating : 0"
      @set-rate="setRate" :full-object="service" is-update-function small-margin />
    <h1 class="title" :class="{ 'title-special': specialForm }" v-if="propsExpertises.length">
      Компетенции
    </h1>
    <rating-input v-once v-for="(competence, index) of mapDataToType(this.propsExpertises, 'expertises')"
      :key="competence.id + competence.name" :title="competence.name"
      :prev-rate="competence?.is_rated ? competence.rating : 0" @set-rate="setRate" :full-object="competence"
      is-update-function small-margin />
    <h1 class="title" :class="{ 'title-special': specialForm }" v-if="propsTribes.length">
      Специализации
    </h1>
    <rating-input v-once v-for="(tribe, index) of mapDataToType(this.propsTribes, 'tribes')"
      :key="tribe.id + tribe.industryName" :title="`${tribe.industryName} — ${tribe.optionName}`"
      :prev-rate="tribe?.is_rated ? tribe.rating : 0" @set-rate="setRate" :full-object="tribe" is-update-function
      small-margin />
    <base-input v-model="comment" class="no-resize comment" description="Ваш комментарий" placeholder='Укажите, в рамках какого проекта или компании вы имели опыт взаимодействия. 
Ваш комментарий повысит доверие других пользователей к данному специалисту' type="textarea" :special-form="specialForm"
      :class="{ 'comment-special': specialForm }" :is-error="v$.comment.$error" :error="[
        {
          text: 'Комментарий обязателен',
          status: v$.comment.required.$invalid,
        },
        {
          text: 'Максимальная длина - 300 символов',
          status: v$.comment.maxLength.$invalid,
        },
      ]" />
    <div class="buttons">
      <base-button v-if="!formInput" :disabled="isSubmitDisabled" @click="submit">
        Сохранить оценку
      </base-button>
      <base-button v-if="isRated" class="btn-delete button--light" icon icon-prefix="dell-item" @click="deleteRate" />
    </div>
  </div>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import RatingInput from "@/components/ui/RatingInput";
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/BaseInput";
import Avatar from "@/components/ui/Avatar";
import useVuelidate from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import InformationBlock from "@/components/ui/InformationBlock";


export default {
  name: "RateMemberComponent",
  components: {
    BaseModal,
    RatingInput,
    BaseButton,
    BaseInput,
    Avatar,
    InformationBlock
  },
  data() {
    return {
      comment: '',
      rated: [],
    }
  },
  setup() {
    let autoIncrement = 1;
    return {
      autoIncrement,
      v$: useVuelidate()
    }
  },
  props: {
    isOpen: {
      type: Boolean
    },
    /**
     * Определяет вид формы. Для модалки или только блок с оценкой
     */
    formInput: {
      type: Boolean,
      default: false,
    },
    isRated: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    image: {
      type: String,
    },
    propsExpertises: {
      type: Array,
      default: () => [],
    },
    propsTribes: {
      type: Array,
      default: () => [],
    },
    propsServices: {
      type: Array,
      default: () => [],
    },
    specialForm: {
      type: Boolean,
      default: false
    },
    rateError: {
      type: String,
      default: null
    }
  },
  computed: {
    isSubmitDisabled() {
      return !this.rated.length;
    },
    getPhoto() {
      if (this.image == null)
        return null;
      if (this.image === '')
        return null;
      return this.image;
    },
    helpText() {
      return `Оценивая компетенции, вы подтверждаете, что у вас был ` +
        `опыт взаимодействия со специалистом ${this.name}, в рамках которого эти компетенции были проявлены в явном виде.`;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    mapDataToType(data, type) {
      let listValue = data.map(value => ({
        ...value, ...{
          typeValue: type,
          autoIncrement: this.autoIncrement++
        }
      }));
      listValue.forEach(v => {
        if (v.is_rated)
          this.setRate(v);
      })
      return listValue;
    },
    setRate(value) {
      const isRated = this.rated.find(item => item.autoIncrement === value.autoIncrement);
      if (isRated)
        isRated.rating = value.rating;
      else
        this.rated.push(value)
      this.$emit('changeItem', this.rated, this.comment);
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) {
        console.log(this.v$);
        return;
      }
      this.$emit('submit', this.rated, this.comment);
      this.closeModal();
    },
    deleteRate() {
      this.comment = '';
      this.$emit('delete', this.rated);
      this.rated = [];
      this.closeModal();
    },
  },
  validations() {
    return {
      comment: {
        required,
        maxLength: maxLength(300),
      },
    }
  },
  watch: {
    comment() {
      this.$emit('changeItem', this.rated, this.comment);
    },
  }
}
</script>


<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';


.avatar {
  &-mobile {
    display: none;

    @include md {
      display: unset;
    }
  }

  &-desktop {
    display: unset;

    @include md {
      display: none;
    }
  }
}

.header {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  h1 {
    font-size: 32px;
  }

  &_special {
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin-bottom: 60px;

    @include md {
      gap: 15.5px;
    }
  }
}

.title {
  font-size: 32px;
  margin-bottom: 24px;

  &-special {
    margin-top: 60px;

    @include md {
      margin-top: 32px;
    }
  }
}

.comment {
  margin-bottom: 40px;

  &-special {
    margin-top: 60px;
  }
}

.buttons {
  display: flex;
  gap: 8px;
}

.btn-delete {
  width: 56px;
  height: 56px;
}

.container .card .title-special {
  @include md {
    font-size: 26px;
  }
}

.container .card .name-special {
  @include md {
    font-size: 26px;
  }
}



.information-block {
  margin-bottom: 36px;
}
</style>