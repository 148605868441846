<template>
  <div
    class="item-people"
    :class="{
      disabled: needApprove && canDisabled,
      'item-people__active': checkboxAction,
      'item-people__info': isInfo,
    }"
  >
    <div class="item-people__header">
      <avatar
        :rating="4.8"
        size="50"
        :image="getPhoto == null ? '' : getPhoto"
        :fullname="getName(name)"
      />
    </div>
    <div class="item-people__body">
      <p class="item-people__body-h1 company person-name">{{ name }}</p>
      <p class="item-people__body-rating company">{{ getBodyRating() }}</p>
      <p class="item-people__body-rating_mob">{{ shortBodyRating(getBodyRating()) }}</p>
      <!-- :class="{ company: company }" -->
    </div>
    <div class="item-people__action" v-if="!outNoClick" @click.stop>
      <slot name="action"></slot>
      <base-radio
        v-if="checkboxAction"
        :type="checkboxRadio ? 'radio' : 'checkbox'"
        :model-value="checkboxValueComputed"
        @update:model-value="eventClick()"
      ></base-radio>
    </div>
    <div class="item-people__action" v-else @click.stop @click="updateRadio()">
      <slot name="action"></slot>
      <base-radio
        v-if="checkboxAction"
        :type="checkboxRadio ? 'radio' : 'checkbox'"
        :model-value="checkboxValueComputed"
        @update:model-value="eventClick()"
      ></base-radio>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/ui/Avatar";
import BaseRadio from "@/components/base/BaseRadio";
export default {
  name: "ItemPeople",
  components: { BaseRadio, Avatar },
  data() {
    return {
      checkboxValue: false,
    };
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    photo: {
      type: String,
      default: null,
    },
    company: {
      type: String,
      default: null,
    },
    rating: {
      type: Number,
      default: null,
    },
    isInfo: {
      type: Boolean,
      default: false,
    },
    /**
     * Показывать выбор в поле
     */
    checkboxAction: {
      type: Boolean,
      default: false,
    },
    /**
     * сделать кнопку RadioBtn
     */
    checkboxRadio: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      default: null,
    },
    needApprove: {
      type: Boolean,
      default: true,
    },
    canDisabled: {
      type: Boolean,
      default: false,
    },
    /** приглошонный */
    invate: {
      type: Boolean,
      default: false,
    },
    outNoClick: {
      type: Boolean,
      default: false,
    },
    fullPath: {
      type: Boolean,
      default: false,
    },
    nameValue: {
      type: String,
      default: "select",
    },
  },
  computed: {
    checkboxValueComputed: {
      get() {
        if (this.modelValue != null) return this.modelValue[this.nameValue];
        else return this.checkboxValue;
      },
      set(v) {
        console.log(v);
      },
    },
    getPhoto() {
      if (this.fullPath) {
        return this.photo;
      } else {
        if (this.photo == null || this.photo === "") return null;
        return this.photo;
      }
    },
  },
  methods: {
    shortBodyRating(str) {
      if (str != null) return str.length > 30 ? str.slice(0, 20) + "..." : str;
    },
    getBodyRating() {
      if (this.needApprove && this.canDisabled) {
        if (this.invate) return "";
        else return "ожидает подтверждения";
      } else {
        return this.company;
      }
    },
    getName(name) {
      if (name == null) return "AB";
      return name.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, "");
    },
    eventClick(e) {
      if (e === "out" && this.outNoClick) {
        this.checkboxValue = !this.checkboxValue;
        this.checkboxValueUpdate(this.checkboxValue);
        this.$emit("out-click", this.id);
      } else {
        if (this.checkboxRadio) {
          this.$emit("deactivateAll");
          this.checkboxValue = true;
        } else {
          this.checkboxValue = !this.checkboxValue;
        }
        this.checkboxValueUpdate(this.checkboxValue);
      }
    },
    updateRadio(e) {
      if (this.checkboxValue) {
        this.checkboxValue = !this.checkboxValue;
      }
      this.$emit("out-click", this.id);
    },
    checkboxValueUpdate(v) {
      this.$emit("update:modelValue", {
        ...this.modelValue,
        ...{
          select: v,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

/* demo */

.person-name {
  display: inline;
  &:hover {
    cursor: pointer;
  }
}
.item-people {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 18px 14px 25px;
  border-radius: 12px;
  border: 1px solid $line-light-gray;

  &__info {
    padding: 0px;
    border: 0px solid $line-light-gray;
    pointer-events: none;
  }

  @include sm {
    padding: 13px 14px 14px 13px;
  }

  &__active {
    cursor: pointer;
  }

  &.disabled .item-people {
    &__header {
      opacity: 0.3;

      .avatar__rating {
        opacity: 0.3;
      }
    }

    &__body-rating,
    &__body-h1 {
      color: $text-gray;
    }
  }

  &:hover {
    border: 1px solid $text-gray;
  }

  &__header {
  }

  &__range {
    font-size: 20px;
  }

  &__body {
    margin: 0 20px;
    flex-grow: 1;
  }

  &__body-h1 {
    font-family: "Muller";
    font-weight: 600;
    font-size: 20px;

    &.company {
      @include desktop_h2;
    }
  }

  &__body-sub {
    font-size: 12px;
    line-height: 16px;
    color: $text-gray;
  }

  &__body-rating {
    font-size: 12px;
    margin-top: 8px;

    &_mob {
      display: none;
      @include desktop_additional;
      color: $text-gray;
      margin-top: 8px;
      word-break: break-all;

      @include sm {
        display: block;
      }
    }

    &.company {
      @include desktop_additional;
      color: $text-gray;

      @include sm {
        display: none;
        word-break: break-all;
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;
    gap: 15px;

    * {
      cursor: pointer;
    }
  }
}
</style>
