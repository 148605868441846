<template>
  <div v-if="load" class="loader">
    <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
  </div>
  <div class="multi-rate-page" v-else>
    <base-alert-no-modal type="error" :header="errorText" v-if="errorText">
      <template #button>
        <base-button @click="goHome">Вернуться на главную</base-button>
      </template>
    </base-alert-no-modal>
    <container v-else>
      <template v-slot:header>
        <h1>Оценка компетенций</h1>
      </template>
      <template v-slot:nav>
        <div>
          <information-block
            icon="mm-crown"
            class="padding-equal"
            :text="helpText"
            :special-form="true"
          />
        </div>
      </template>
      <template v-slot:btn>
        <base-button @click="submit"> Отправить </base-button>
      </template>
      <template v-slot:body>
        <div class="multi-rate-page__body" v-if="rates != null">
          <rate-member-component
            form-input
            v-once
            :propsServices="
              rates.services?.map((v) => ({ id: v.id, name: v.service_name }))
            "
            :props-tribes="
              rates.tribes?.map((v) => ({
                id: v.id,
                industry_id: v.industry_id,
                option_id: v.option_id,
                industryName: v.industry_name,
                optionName: v.option_name,
              }))
            "
            :props-expertises="
              rates.expertises?.map((v) => ({
                id: v.id,
                name: v.expertise_name,
              }))
            "
            @changeItem="changeItem"
            :name="getName"
            :image="getImage"
            :special-form="true"
          ></rate-member-component>
        </div>
      </template>
    </container>
    <alert-modal ref="register" grid>
      <template v-slot:button>
        <base-button @click="login()" class="button--light mt-3"
          >Авторизоваться</base-button
        >
      </template>
    </alert-modal>
    <alert-modal ref="alert"></alert-modal>
  </div>
</template>

<script>
import Container from "@/components/layout/Container";
import BaseButton from "@/components/base/BaseButton";
import InformationBlock from "@/components/ui/InformationBlock";
import { maxLength, minLength, required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import AlertModal from "@/components/modals/AlertModal";
import InfoBox from "@/components/ui/InfoBox";
import SvgIcon from "@/components/base/SvgIcon";
import { isValid } from "date-fns";
import RateMemberComponent from "@/components/component/RateMemberComponent";
import BaseAlertNoModal from "@/components/base/BaseAlertNoModal";
import useVuelidate from "@vuelidate/core";

export default {
  name: "MultiRatePage",
  components: {
    BaseAlertNoModal,
    RateMemberComponent,
    SvgIcon,
    InfoBox,
    AlertModal,
    InformationBlock,
    BaseButton,
    Container,
  },
  data() {
    return {
      load: true,
      errorText: null,
      comment: undefined,
      listSelect: [],
      rated: [],
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mounted() {
    if (!this.isAuth) {
      this.$refs.register.show(
        "Внимание!",
        "Введенные данные не будут сохранены. " +
          "Необходимо зарегистрироваться или авторизоваться.",
        {
          type: "warning",
          nameButton: "Зарегистрироваться",
        },
        () => {
          this.$router.push({ path: "/sign-up" });
        }
      );
    }
    this.getData();
  },
  computed: {
    ...mapGetters({
      rates: "auth/rates",
      isAuth: "auth/isAuth",
      getUserState: "auth/getUserState",
    }),
    getAllRate() {
      return this.rated.map((value) => ({ rate_id: value.id, rating: value.rating }));
    },
    checkAllRate() {
      if (!this.rated) {
        return false;
      }
      const { expertises, tribes, services } = this.rates;
      const totalLength =
        (expertises ? expertises.length : 0) +
        (tribes ? tribes.length : 0) +
        (services ? services.length : 0);
      return this.rated.length === totalLength;
    },
    helpText() {
      return (
        `Оценивая компетенции, вы подтверждаете, что у вас был ` +
        `опыт взаимодействия со специалистом ${this.getName}, в рамках которого эти компетенции были проявлены в явном виде.`
      );
    },
    getName() {
      if (this.rates.user != null) {
        return this.rates.user.name;
      } else return "";
    },
    getImage() {
      if (this.rates.user != null) {
        return this.rates.user.photo;
      } else return null;
    },
  },
  methods: {
    goHome() {
      this.$router.replace({
        name: this.getUserState == "executor" ? "ProfilePage" : "RequestAddPage",
      });
    },
    async getData() {
      try {
        await this.$store.dispatch("auth/getRates", this.$route.query.hash);
        this.errorText = null;
      } catch (e) {
        this.errorText = e;
      } finally {
        this.load = false;
      }
    },
    login() {
      this.$router.replace({ name: "AuthPage" });
    },
    changeItem(...args) {
      console.log(args);
      const [rated, comment] = args;
      this.rated = rated == null ? [] : rated;
      this.comment = comment;
    },

    async submit() {
      if (this.checkAllRate !== true) {
        this.$refs.alert.show(
          "Оцените все компетенции",
          "Оставьте оценку по каждой запрошенной компетенции. Ваша оценка повысит доверие других пользователей к данному специалисту",
          {
            type: "error",
            nameButton: "Ок",
          }
        );
        return;
      }
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      try {
        await this.$store.dispatch("auth/postMultiRate", {
          items: this.getAllRate,
          comment: this.comment,
        });
        this.$refs.alert.show(
          "Спасибо за вашу оценку",
          "",
          {
            type: "success",
            nameButton: "Вернуться на главную",
            fullCallback: true,
          },
          () => {
            this.$router.replace({ name: "StartPage" });
          }
        );
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
  },
  validations() {
    return {
      comment: {
        required,
        maxLength: maxLength(300),
      },
      getAllRate: {
        required,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "~vuetify/lib/styles/settings/variables";
@import "src/assets/scss/vuetify_variables";

.loader {
  display: flex;
  width: 100%;
  height: 500px;
  align-items: center;
  justify-content: center;
}

.information-block {
  margin-bottom: 40px;
}

.multi-rate-page {
  &__block {
    margin-bottom: 10px;

    @include md() {
      margin-bottom: 28px;
    }
  }

  &__block--no-margin {
    margin-top: -9px;
  }

  &__block--no-padding {
    .v-row,
    [class*="v-col"] {
      @include md() {
        padding-top: 0;
        padding-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        padding: 0;
        margin: 0;
      }
    }
  }

  &__block--last {
    .input-group {
      @include sm() {
        margin-bottom: 8px;
      }
    }

    .v-row {
      @include md() {
        margin-top: 0;
      }
    }
  }

  &__body {
    max-width: 380px;
  }

  .input-radio.switch-description {
    @include sm() {
      padding: 22px 0 12px;
    }
  }

  .input-group {
    @include sm() {
      margin-bottom: 24px;
    }
  }

  .base-radio-group {
    @include sm() {
      margin-bottom: 30px;
    }

    &__description {
      @include sm() {
        margin-bottom: 8px;
      }
    }
  }

  .form-pay {
    @include md() {
      padding-top: 2px;
      margin-bottom: 28px;
    }

    @media screen and (max-width: 767px) {
      padding-top: 8px;
      margin-bottom: 16px;
    }

    .input-group {
      @include sm() {
        margin-bottom: 8px;
      }
    }
  }
}

.container__btn-footer .button {
  @include md() {
    margin-top: 0;
  }
}

.block-header {
  @include desktop_h1;
  margin-bottom: 30px;
  margin-top: 60px;

  @include sm() {
    margin-bottom: 24px;
    margin-top: 48px;
  }
}

.form-pay {
  .input-group {
    margin-top: 16px;
  }
}

.block-header_top-margin {
  @include sm() {
    margin-bottom: 36px;
  }
}

.add-button_short {
  @media (max-width: 768px) {
    width: 100%;
  }

  width: 50%;
}

.text-color_darkgray {
  color: #1a1a1a;
}
</style>
