<template>
  <div class="request-add">
    <temp-info-block class="infoblock" />
    <container>
      <template v-slot:header v-if="!mobile">
        <h1>Новая заявка</h1>
      </template>
      <template v-slot:nav>
        <div>
          <information-block
            icon="information-block-1"
            class="padding-equal"
            text="Теперь вы можете выбрать ТОП - экспертов. Для вашего проекта недостаточно Middle +, Senior? Выберите специалиста из сегмента Premium"
          />
        </div>
      </template>
      <template v-slot:body>
        <h1>Описание</h1>
        <div>
          <base-input
            ref="v-name"
            description="Как называется проект"
            placeholder="Повышение организационной эффективности"
            class="request-add__block"
            v-model="name"
            :class="{ error: this.v$.name.$error }"
            :is-error="this.v$.name.$error"
            :error="[
              {
                text: 'Название обязательно для заполнения',
                status: this.v$.name.required.$invalid,
              },
              {
                text: 'Максимальное количество символов 100 [' + name.length + ']',
                status: this.v$.name.maxLength.$invalid,
              },
            ]"
          ></base-input>

          <base-dropdown
            ref="v-type"
            title="Ищу эксперта"
            placeholder="Выберите из списка"
            class="request-add__block"
            v-model="type"
            :list="serviceTypes"
            :is-error="this.v$.type.$error"
            :error="[
              {
                text: 'Обязательно для заполнения',
                status: this.v$.type.required.$invalid,
              },
            ]"
          ></base-dropdown>
          <pretty-switch
            ref="v-expert"
            class="request-add__block"
            description="Выберите уровень эксперта"
            v-model="expert"
            :is-error="this.v$.expert.$error"
            :error="[
              {
                text: `Уровень эксперта обязателен для заполнения`,
                status: this.v$.expert.required.$invalid,
              },
            ]"
          />
          <base-input
            ref="vOtherOptions-description"
            class="request-add__block"
            description="Расскажите подробнее о задаче и формате работы"
            placeholder="Опишите задачу: контекст,  что хотите решить, в какой стране, что для вас важно при выборе исполнителя и т.д."
            type="textarea"
            :maxTextarea="2000"
            v-model="description"
            :is-error="vOtherOptions$.description.$error"
            :error="[
              {
                text: 'Описание услуги обязательно для заполнения',
                status: vOtherOptions$.description.required.$invalid,
              },
              {
                text: 'Максимальное количество символов 2000',
                status: vOtherOptions$.description.maxLength.$invalid,
              },
            ]"
          ></base-input>
          <base-multi-file
            id="11"
            tmp="3"
            class="request-add__block"
            title="Прикрепите ТЗ или бриф проекта"
            description="Добавьте не более 5 файлов весом до 50мб"
            @file="updateData"
            file-type=".jpg,.png,.pdf,.docx,.doc,.rtf,.pptx,.ppt,.msg"
            is-optional
            :is-error="this.v$.file.$error"
            :error="[
              {
                text: 'Неверный тип файла',
                status: this.v$.file.checkType.$invalid,
              },
              {
                text: 'Максимальный размер файла - 50мб',
                status: this.v$.file.checkSize.$invalid,
              },
              {
                text: 'Добавьте не более 5 файлов',
                status: this.v$.file.maxLength.$invalid,
              },
            ]"
          />
          <vue-multi-select-check-box
            ref="v-industryArray"
            style="margin-bottom: 12px"
            optionLabel="name"
            optionKey="id"
            placeholder="Выберите из списка"
            description="Какая экспертиза вам нужна?"
            v-model="industryValue"
            :options="industriesList"
            :is-error="isIndustryValue || this.v$.industryValue.$error"
            :error="[
              {
                text: 'Превышен лимит индустрий',
                status: isIndustryValue,
              },
              {
                text: 'Индустрии обязательны для заполнения',
                status: this.v$.industryValue.$invalid,
              },
            ]"
          ></vue-multi-select-check-box>
          <base-call-modal
            class="request-add__block"
            header=""
            :title="currentSpecTitle"
            placeholder="Выберите специализацию"
            @event-click="openSpecialization()"
            :is-error="this.v$.optionsArray.$error"
            :error="[
              {
                text: `Функции обязательны для заполнения`,
                status: this.v$.optionsArray.arrayValid.$invalid,
              },
            ]"
          />
          <div class="pair request-add__block">
            <div>
              <base-input
                ref="vOtherOptions-budget"
                second-description="до"
                placeholder="Введите бюджет"
                :description="subMenu['budget_title']"
                :is-error="vOtherOptions$.budget.$error"
                :error="[
                  {
                    text: 'Бюджет  обязателен для заполнения',
                    status: vOtherOptions$.budget.required.$invalid,
                  },
                  {
                    text: `Находится вне диапозона от ${numberWithSpaces(
                      getRangePrice.min
                    )} до ${numberWithSpaces(getRangePrice.max)}`,
                    status: vOtherOptions$.budget.validPrice.$invalid,
                  },
                ]"
                v-model="formattedBudgetFrom"
              />
              <div class="commision">
                С учетом комиссии сервиса {{ getFee(type) * 100 + "%" }}
              </div>
            </div>
            <base-dropdown
              v-model="duration_id"
              :title="subMenu['duration_title']"
              :list="getDuration(type)"
            />
          </div>
          <div class="pair request-add__block">
            <div>
              <base-calendar
                ref="v-taskDate"
                class="mb-3"
                title="Дедлайн поиска эксперта"
                placeholder="Укажите дату"
                :v-model="taskDate"
                :is-error="this.v$.taskDate.$error"
                :error="[
                  {
                    text: 'Дата обязательна для заполнения',
                    status: this.v$.taskDate.required.$invalid,
                  },
                  {
                    text: 'Проверьте дату решения задачи',
                    status: this.v$.taskDate.dateValid.$invalid,
                  },
                ]"
                @update:model-value="dateFormat"
              />
            </div>
          </div>
          <div class="pair request-add__block">
            <base-button @click="submit" class="mt-4"> Создать заявку </base-button>
          </div>
        </div>
      </template>
    </container>
    <alert-modal ref="register" grid>
      <template v-slot:button>
        <base-button @click="login()" class="button--light">Авторизоваться</base-button>
      </template>
    </alert-modal>
    <alert-modal ref="alert"></alert-modal>
    <add-specialization-modal-work
      ref="add-specialization"
      :is-open="specializationModal"
      :functions-list="optionsList"
      @update-data="addSpecialization"
      @close="closeSpecializationModal"
      title="Выберите специализации"
      :v-model="currentSpec"
      :with-industry="false"
      btn-name="Добавить"
    />
  </div>
</template>

<script>
import Container from "@/components/layout/Container";
import BaseButton from "@/components/base/BaseButton";
import InformationBlock from "@/components/ui/InformationBlock";
import PrettySwitch from "@/components/ui/PrettySwitch";
import BaseDropdown from "@/components/base/BaseDropdown";
import BaseCallModal from "@/components/base/BaseCallModal";
import AddSpecializationModalWork from "@/components/modals/AddSpecializationModalWork";
import BaseCalendar from "@/components/base/BaseCalendar";
import BaseInput from "@/components/base/BaseInput";
import useVuelidate from "@vuelidate/core";
import { maxLength, minLength, required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import VueMultiSelectCheckBox from "@/components/ui/VueMultiSelectCheckBox";
import Checkbox from "@/components/base/VueTelInput/Checkbox";
import BaseErrorText from "@/components/base/BaseErrorText";
import useIndustryAndFunctions from "@/hooks/service/useIndustryAndFunctions";
import SelectFunction from "@/components/ui/SelectFunction";
import AddButton from "@/components/ui/button/AddButton";
import BaseRadioGroup from "@/components/base/BaseRadioGroup";
import useOtherOptions from "@/hooks/service/useOtherOptions";
import AlertModal from "@/components/modals/AlertModal";
import InfoBox from "@/components/ui/InfoBox";
import SvgIcon from "@/components/base/SvgIcon";
import BaseInputMask from "@/components/base/BaseInputMask";
import BaseRadio from "@/components/base/BaseRadio";
import BaseMultiFile from "@/components/base/BaseMultiFile";
import { isValid } from "date-fns";
import { typeFile } from "@/utils/helper";
import TempInfoBlock from "@/components/ui/TempInfoBlock";

export default {
  name: "RequestAddPage",
  components: {
    BaseRadio,
    BaseInputMask,
    BaseCalendar,
    SvgIcon,
    InfoBox,
    AlertModal,
    BaseCallModal,
    AddSpecializationModalWork,
    BaseRadioGroup,
    AddButton,
    SelectFunction,
    BaseErrorText,
    VueMultiSelectCheckBox,
    Checkbox,
    BaseInput,
    BaseDropdown,
    InformationBlock,
    BaseButton,
    Container,
    BaseMultiFile,
    PrettySwitch,
    TempInfoBlock,
  },
  setup: (props) => {
    const v$ = useVuelidate({ $scope: false });
    const { industryValidate } = useIndustryAndFunctions();
    const otherOptions = useOtherOptions({ lendDescription: 2000 });

    return {
      industryValidate,
      v$,
      ...otherOptions,
    };
  },
  data() {
    return {
      name: "",
      type: 0,
      duration_id: -1,
      industryValue: [],
      autoIncrement: 1,
      subMenu: {
        "На консультацию": {
          budget_title: "Бюджет на 1 час консультации",
          duration_title: "Количество консультаций",
        },
        "На проект": {
          budget_title: "Бюджет проекта",
          duration_title: "Примерная длительность",
        },
      },
      mask: {
        mask: Date,
        lazy: true,
      },
      taskDate: "",
      budget: 30000,
      formattedBudgetFrom: "30 000",
      need_team: false,
      file: [],
      isSubmitClicked: false,
      currentSpec: [],
      currentSpecTitle: "",
      specializationModal: false,
      expert: "",
    };
  },
  watch: {
    serviceTypes(val) {
      if (val?.length) {
        if (this.$route.query.type) {
          this.type = +this.$route.query.type;
        }
      }
    },
    formattedBudgetFrom(val) {
      this.formattedBudgetFrom = this.numberWithSpaces(val);
      this.budget = Number(this.formattedBudgetFrom.replace(/\s/g, ""));
    },
    type(val) {
      let name = this.serviceTypes.find((type) => type.id === val)["name"];
      if (name === "На проект") {
        this.budget = 30000;
        this.formattedBudgetFrom = "30 000";
        this.duration_id = this.getDuration(val)[0]["id"];
        this.subMenu = {
          budget_title: "Бюджет проекта",
          duration_title: "Примерная длительность",
        };
      }
      if (name === "На консультацию") {
        this.budget = 9000;
        this.formattedBudgetFrom = "9 000";
        this.duration_id = this.getDuration(val)[0]["id"];
        this.subMenu = {
          budget_title: "Бюджет на 1 час консультации",
          duration_title: "Количество консультаций",
        };
      }
    },
  },
  computed: {
    ...mapGetters({
      serviceTypes: "service/serviceTypes",
      industriesList: "registration/industriesList",
      optionsList: "registration/optionsList",
      companiesList: "company/companies",
      isAuth: "auth/isAuth",
      user: "auth/user",
      userState: "auth/isUserState",
      isMail: "auth/isMail",
    }),
    isIndustryValue() {
      return this.industryValue.length >= 11;
    },
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
  created() {
    this.getServiceTypes();
    this.getOptions();
    this.getIndustries();
    this.getCompany();
  },
  async mounted() {
    await this.$store.dispatch("auth/getUserData");
    if (!this.isAuth) {
      this.$refs.register.show(
        "Внимание!",
        "Введенные данные не будут сохранены. " +
          "Необходимо зарегистрироваться или авторизоваться.",
        {
          type: "warning",
          nameButton: "Зарегистрироваться",
        },
        () => {
          this.$router.push({ path: "/sign-up" });
        }
      );
    }
    if (!this.isMail) this.$router.replace({ name: "VerifyMock" });
  },
  methods: {
    autoFocus(validate, type = "v") {
      console.log(validate.$invalid);
      if (validate.$invalid) {
        for (let key in Object.keys(validate)) {
          const input = Object.keys(validate)[key];
          if (!input.includes("$")) {
            if (validate[input].$error) {
              try {
                this.$refs[`${type}-${input}`].focus();
                return true;
              } catch (e) {
                console.log(`${type}-${input}`);
                throw e;
              }
            }
          }
        }
      }
      return false;
    },
    getDuration(id) {
      if (this.serviceTypes.length)
        return this.serviceTypes.find((type) => type.id === id)["durations"];
      return [];
    },
    getFee(id) {
      if (this.serviceTypes.length)
        return this.serviceTypes.find((type) => type.id === id)["fee"];
      return 0;
    },
    async getUserData() {
      await this.$store.dispatch("auth/getUserData");
    },
    async submitValue() {
      let industries = [];
      let userList = [];
      let functions = [];
      let date = this.dateFormat(this.taskDate);
      let new_options = [];
      if (this.industryValue != null)
        industries = this.industryValue.map((value) => value.id);
      if (this.currentSpec) {
        for (let i = 0; i < this.currentSpec.length; i++) {
          let value = this.currentSpec[i];
          if (value.options.id <= -10) {
            let resp = await this.addOption(value.options.name);
            functions.push(resp);
          } else {
            functions.push(value.options.id);
          }
        }
      }

      if (this.userList != null)
        userList = this.userList.map((value) => ({ id: value.id, type: value.type }));

      this.budget = Number(this.formattedBudgetFrom.replace(/\s/g, ""));
      const payload = {
        name: this.name,
        description: this.description,
        service_type: this.type,
        budget: this.budget,
        industries: industries,
        options: functions,
        execution_period: date,
        duration_id: this.duration_id,
        expert_type: this.expert,
        need_team: this.need_team | 0,
      };
      if (this.file) {
        payload.files = [...this.file];
      }

      const formData = new FormData();

      if (new_options.length) {
        payload.new_options = new_options;
      }
      for (let key in payload) {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((item, idx) => {
            if (typeof item === "object" && key !== "files") {
              for (let el in item) {
                formData.append(`${key}[${idx}][${el}]`, item[el]);
              }
            } else {
              formData.append(`${key}[${idx}]`, item);
            }
          });
        } else {
          formData.append(`${key}`, payload[key]);
        }
      }

      return formData;
    },
    async addOption(name) {
      let response = await this.$store.dispatch("registration/addOptions", {
        name: name,
      });
      return response.id;
    },
    openSpecialization() {
      if (this.currentSpec.length) {
        this.$refs["add-specialization"].showModal(this.currentSpec);
      } else {
        this.$refs["add-specialization"].showModal([
          {
            title: "",
            years: null,
            id: 1,
            industries: -1,
            options: { options: -1, id: -1 },
            works: null,
          },
        ]);
      }
    },
    closeSpecializationModal() {
      this.$refs["add-specialization"].hideModal();
    },
    addSpecialization(e) {
      let options = e.options.filter((el) => el.options !== -1);
      this.currentSpec = [];
      this.currentSpecTitle = "";
      for (let i = 0; i < options.length; i++) {
        let elem = {
          title: "",
          years: null,
          industries: -1,
          works: null,
        };
        elem.id = i;
        elem.options = options[i];
        this.currentSpec.push(elem);
        this.currentSpecTitle = this.currentSpecTitle + options[i].name + ", ";
      }
      this.currentSpecTitle = this.currentSpecTitle.slice(0, -2);
      this.$refs["add-specialization"].hideModal();
    },
    login() {
      this.$router.replace({ name: "AuthPage" });
    },
    async updateData(e) {
      this.file = e;
      const result = await this.v$.file.$validate();
      if (!result) {
        this.autoFocus(this.v$);
        return;
      }
    },
    async getServiceTypes() {
      await this.$store.dispatch("service/getServiceTypes");
      this.type = this.serviceTypes[0].id;
      this.duration_id = this.getDuration(this.type)[0]["id"];
    },
    async getOptions() {
      await this.$store.dispatch("registration/getOptions");
    },
    async getIndustries() {
      await this.$store.dispatch("registration/getIndustries");
    },
    async getCompany() {
      await this.$store.dispatch("company/getCompany", null);
    },
    numberWithSpaces(x) {
      return x
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    async submit() {
      const result = await this.v$.$validate();
      const resultOtherOptions = await this.vOtherOptions$.$validate();
      const isIndustryValue = this.isIndustryValue;
      if (!result || !resultOtherOptions || isIndustryValue) {
        this.autoFocus(this.v$);
        this.autoFocus(this.vOtherOptions$, "vOtherOptions");
        return;
      }
      await this.createRequest();
    },
    async createRequest() {
      if (!this.isSubmitClicked) {
        try {
          this.isSubmitClicked = true;
          let subVal = await this.submitValue();
          await this.$store.dispatch("request/createRequest", subVal);

          this.$refs.alert.show(
            "Ваша заявка уже в работе",
            "Cкоро с вами свяжется наш менеджер, чтобы уточнить детали заявки и подробнее рассказать о правилах работы платформы",
            {
              type: "success",
              nameButton: "Закрыть",
              fullCallback: true,
            },
            () => {
              this.$router.push({ path: "/projects/request" });
            }
          );
        } catch (e) {
          this.isSubmitClicked = false;
          this.$refs.alert.show(this.$t("alert.error"), e, {
            type: "error",
            nameButton: "Ок",
          });
        }
      }
    },
    dateFormat(e) {
      this.taskDate = e;
      if (this.taskDate.length > 9) {
        let arr = this.taskDate.split(".");
        return `${arr[2]}-${arr[1]}-${arr[0]}`;
      }
      return "";
    },
    validPriceFrom(value) {
      if (value === null || value === "") {
        return true;
      }
      return value >= 0 && value <= 2000000000;
    },
    validPriceTo(value) {
      if (value === null || value === "") {
        return true;
      }
      return value >= 0 && value <= 2000000000;
    },
    dateValid(e) {
      let arr = e.toString().split(".");
      if (e.length > 9) {
        let result = isValid(new Date(`${arr[1]}/${arr[0]}/${arr[2]}`));
        if (result) {
          let currDate = new Date();
          let date = new Date(`${arr[1]}/${arr[0]}/${arr[2]}`);
          let arrDay = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

          if (+arr[0] > arrDay[+arr[1] - 1]) {
            return false;
          }

          if (arr[2] > 2029) {
            return false;
          }

          if (currDate > date) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    arrayValid() {
      return this.currentSpec.length;
    },
    checkType(e) {
      if (e.length) {
        let counter = 0;
        e.forEach((item) => {
          if (typeFile.includes(item.type)) counter++;
        });
        return e.length == counter;
      } else {
        return true;
      }
    },
    checkSize(e) {
      if (e.length) {
        let counter = 0;
        e.forEach((item) => {
          if (item.size < 50 * 1024 * 1024) {
            counter++;
          }
        });
        return e.length == counter;
      } else {
        return true;
      }
    },
  },
  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(100),
      },
      type: {
        required,
      },
      expert: {
        required,
      },
      optionsArray: {
        arrayValid: this.arrayValid,
      },
      budget: {
        validPriceFrom: this.validPriceFrom,
      },
      taskDate: {
        required,
        minLength: minLength(10),
        dateValid: this.dateValid,
      },
      industryValue: {
        required: required,
      },
      file: {
        checkType: this.checkType,
        checkSize: this.checkSize,
        maxLength: maxLength(5),
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "~vuetify/lib/styles/settings/variables";
@import "src/assets/scss/vuetify_variables";
.request-add {
  &__block {
    margin-bottom: 30px;
    &:first-child {
      margin-top: 30px;
    }
    @include sm() {
      background-color: white;
      margin-bottom: 12px;
      &:first-child {
        margin-top: 12px;
      }
    }
  }

  .request-add__block--no-padding {
    .v-row,
    [class*="v-col"] {
      @include md() {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    @include sm() {
      background-color: white;
      margin-bottom: 1px;
      padding-bottom: 0px;
    }
  }
}
.commision {
  color: #a0a0a0;
  /* Desktop/Additional */
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 19.84px */
  @include md() {
    padding-bottom: 24px;
  }
}

.pair {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  @include md() {
    grid-template-columns: 1fr;
  }
  @include sm() {
    grid-template-columns: 1fr;
  }
}

.infoblock {
  display: none;
  @include sm() {
    display: block;
  }
}
</style>
