<template>
  <h2 class="add-people-team__header">Выберите компетенции</h2>
  <div class="subtitle">
    <span>Выберите компетенции, на которые вы бы хотели запросить оценку</span>
  </div>

  <div class="body">
    <div class="list">
      <service-task
        v-for="(option, idx) in listAll()"
        :key="`test_personal/expert_${idx}`"
        class="item"
        :rating="option.rating"
        :industry="option.name"
        :like="counterLick(option.rating_count)"
        :model-value="option.value"
        :base-value="prechecked.includes(idx)"
        @update:model-value="(v) => select([option])"
        checkbox-action
        small
      />
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";
import ServiceTask from "@/components/ui/ServiceTask";
import useSelectController from "@/hooks/useSelectController";
import { declOfNum } from "@/utils/helper";
/**
 * @property {string} text - Текст с поля для ввода
 */
class EventVerificationModalData {
  text = "";
}

export default {
  name: "AddSpecExpertModal",
  components: {
    ServiceTask,
    BaseButton,
    BaseModal,
  },
  setup(props) {
    const {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect,
    } = useSelectController({ selectOne: false, newValue: false });
    return {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect,
    };
  },
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
    prechecked: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      inputValidPhone: false,
      nameButton: "",
      verificationCode: "",
      dataOptions: [],
      isOpenModalDesiredClients: false,
    };
  },
  methods: {
    counterLick(count) {
      if (count != 0) {
        return `${count == null ? 0 : count} ${declOfNum(count, [
          "оценка",
          "оценки",
          "оценок",
        ])}`;
      }
      return false;
    },
    select(v) {
      this.selectValue(v);
      let submitSelect = this.submitSelect();
      if (submitSelect != -1) {
        this.$emit("update:modelValue", submitSelect);
      }
    },
    deleteItem(item) {
      this.removeSelect(item);
      let submitSelect = this.submitSelect();
      if (submitSelect != -1) {
        this.$emit("update:modelValue", submitSelect);
      }
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          if (newValue.length > 0) {
            this.selectValue(newValue, true);
          }
        }
      },
    },
    items: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          this.addListSelect(JSON.parse(JSON.stringify(newValue)));
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.add-people-team {
  &__header {
    @include desktop_h2_norm;
    text-align: left;
    margin-bottom: 16px;
  }
  .body {
    margin-bottom: 30px;
    max-height: 52vh;
    margin: 24px -18px 16px -18px;
    padding-left: 18px;
    width: 100%;
    .ps {
      max-height: 50vh;
    }
    .list {
    }
    .item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.subtitle {
  @include desktop_additional;
  text-align: left;
  margin-bottom: 16px;
}
</style>
