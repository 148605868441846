<template>
  <base-modal size="550" :is-open="isOpen" @close="closeModal">
    <div class="add-people-team">
      <perfect-scrollbar>
        <add-request-specialization-modal
          v-if="specialization.length"
          :items="specialization"
          :prechecked="precheckedSpec"
          v-model="specializationList"
        ></add-request-specialization-modal>
        <add-request-expertises-modal
          v-if="expertises.length"
          :items="expertises"
          :prechecked="precheckedExp"
          v-model="expertisesList"
        ></add-request-expertises-modal>
      </perfect-scrollbar>
      <base-button :disabled="!isNotEmpty" @click="openModalRecommendationModal">{{
        nameButton !== "" ? nameButton : "Продолжить"
      }}</base-button>
    </div>
  </base-modal>
  <alert-modal ref="alert"> ок </alert-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";
import { mapGetters } from "vuex";
import AddRequestExpertisesModal from "@/components/modals/AddRequestExpertisesModal";
import AddRequestSpecializationModal from "@/components/modals/AddRequestSpecializationModal";
import AlertModal from "@/components/modals/AlertModal";

export default {
  name: "RequestRecommendationFirst",
  components: {
    BaseButton,
    BaseModal,
    AddRequestExpertisesModal,
    AddRequestSpecializationModal,
    AlertModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    expertises: {
      type: Array,
      default() {
        return [];
      },
    },
    specialization: {
      type: Array,
      default() {
        return [];
      },
    },
    precheckedSpec: {
      type: Array,
    },
    precheckedExp: {
      type: Array,
    },
  },
  data() {
    return {
      nameButton: "",
      open: false,
      specializationList: [],
      expertisesList: [],
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      user: "auth/user",
      autoIncrement: "auth/autoIncrement",
    }),
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
    isNotEmpty() {
      return this.expertisesList.length
        ? true
        : this.specializationList.length
        ? true
        : false;
    },
  },
  methods: {
    openModalRecommendationModal() {
      let headerRecommendation = [
        {
          header: "Специализации",
          body: this.specializationList.map((item) => item.tribe).join(", "),
          data: this.specializationList,
        },
        {
          header: "Компетенции",
          body: this.expertisesList.map((item) => item.name).join(", "),
          data: this.expertisesList,
        },
      ];
      if (this.expertisesList.length || this.specializationList.length) {
        this.$emit("submitData", {
          headerRecommendation: headerRecommendation,
        });
        this.specializationList = [];
        this.expertisesList = [];
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    submit() {
      this.closeModal();
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.add-people-team {
  max-height: 80vh;
  .ps {
    max-height: 74vh;
    max-width: 100%;
  }
  &__header {
    @include desktop_h1;
    text-align: left;
    margin-bottom: 16px;
  }
  .body {
    margin-bottom: 30px;
    max-height: 52vh;
    margin: 24px -18px 16px -18px;
    padding-left: 18px;
    .ps {
      max-height: 50vh;
    }
    .list {
      padding-right: 18px;
    }
    .item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
