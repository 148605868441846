<template>
  <div
    class="service-task"
    :class="{
      small: small,
      hover: hover,
      'hidden-show': hiddenShow,
      'not-position-action': notPositionAction,
      'no-grid-mobile': sameOnMobile,
      'extra-grid': extraList,
      checked: checked,
    }"
    @click="eventClick"
  >
    <div v-if="rating" class="rating" :style="{ background: colorRange(rating) }">
      {{ rating }}
    </div>
    <div class="service-task__body">
      <p class="service-task__body-h1">{{ industry }}</p>
      <p v-if="functionName != ''" class="service-task__body-sub">{{ functionName }}</p>
      <p class="service-task__body-recommendation" v-if="recommendation">
        {{ recommendationString }}
      </p>
      <p class="service-task__body-rating" v-else>{{ like }}</p>
      <p class="service-task__extra-counter" v-if="userCounter">{{ counterPeople }}</p>
    </div>
    <div @click="eventClick" class="service-task__action">
      <slot name="action"></slot>
      <base-radio
        v-if="checkboxAction"
        type="checkbox"
        v-model="checkboxValue"
      ></base-radio>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import Rating from "@/components/ui/Rating";
import AlertModal from "@/components/modals/AlertModal";
import BaseButton from "@/components/base/BaseButton";
import BaseRadio from "@/components/base/BaseRadio";
import { declOfNum } from "@/utils/helper";
export default {
  name: "ServiceTask",
  components: { BaseRadio, BaseButton, AlertModal, Rating, SvgIcon },
  data() {
    return {
      checkboxValue: false,
    };
  },
  props: {
    hiddenShow: {
      type: Boolean,
      default: false,
    },
    baseValue: {
      type: Boolean,
      default: false,
    },
    rating: {
      type: [Number, String],
      default: "-",
    },
    industry: {
      type: [String],
      default: "",
    },
    functionName: {
      type: [String],
      default: "",
    },
    like: {
      type: [String],
      default: "",
    },
    /**
     * Показывать выбор в поле
     */
    checkboxAction: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      default: null,
    },
    checked: Boolean,
    /**
     * Задать маленький размер
     */
    small: Boolean,
    /**
     * Эфект выделения
     */
    hover: Boolean,
    /**
     * Отменить позиционирования крестика сверху
     */
    notPositionAction: Boolean,
    recommendation: {
      type: [String, Number],
      default: null,
    },
    sameOnMobile: {
      type: Boolean,
      default: false,
    },
    extraList: {
      type: Boolean,
      default: false,
    },
    userCounter: {
      type: Number,
      default: null,
    },
  },
  computed: {
    recommendationString() {
      if (this.recommendation) {
        return `${this.recommendation} ${declOfNum(this.recommendation, [
          "рекомендация",
          "рекомендации",
          "рекомендаций",
        ])}`;
      }
      return false;
    },
    counterPeople() {
      if (this.userCounter != 0) {
        return `${this.userCounter} ${declOfNum(this.userCounter, [
          "участник",
          "участника",
          "участников",
        ])}`;
      }

      return false;
    },
  },
  mounted() {
    this.checkboxValue = this.baseValue;
  },
  methods: {
    eventClick() {
      this.checkboxValue = !this.checkboxValue;
    },
    colorRange(value) {
      if (!this.isDay) {
        if (value >= 4) {
          return "#7FC666";
        } else if (value >= 3) {
          return "#FDC04B";
        } else if (value > 0) {
          return "#EF693F";
        } else {
          return "#ccc";
        }
      } else {
        if (value >= 7) {
          return "#7FC666";
        } else {
          return "#FDC04B";
        }
      }
    },
  },
  watch: {
    checkboxValue() {
      this.$emit("update:modelValue", {
        ...this.modelValue,
        ...{
          select: this.checkboxValue,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";

/* demo */
.service-task {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #a0a0a0;
  padding: 15px 30px;
  border-radius: 12px;
  cursor: pointer;

  @include sm {
    display: grid;
    padding: 20px 20px 20px 16px;
  }

  &:hover &__action {
    opacity: 1;
  }

  &__header {
    display: flex;

    @include sm {
      margin: 18px 0 18px 0;
    }
  }

  &__body {
    margin: 0 20px;
    flex-grow: 1;

    @include sm {
      margin-left: 4px;
    }
  }

  &__body-h1 {
    font-family: "Muller";
    font-weight: 500;
    line-height: 22px;
    font-size: 20px;

    @include sm {
      font-size: 16px;
      width: 80%;
    }
  }

  &__body-sub {
    font-size: 12px;
    line-height: 16px;
    color: $text-gray;
  }

  &__body-rating {
    @extend %text_small_plus;
    color: $black;
    margin-top: 8px;
  }

  &__body-recommendation {
    font-size: 12px;
    line-height: 16px;
    margin-top: 6px;
  }

  &__action {
    display: flex;
    align-items: center;
    transition: opacity 0.5s;
    gap: 15px;

    //opacity: 0;
    * {
      cursor: pointer;
    }

    svg-icon {
      margin-right: 20px;
    }

    @include sm {
      top: 20px;
      right: 20px;
      position: absolute;
    }

    :slotted(*) {
      cursor: pointer;
    }
  }
  &.checked {
    margin: 5px;
  }
  &.small {
    padding: 14px;

    @include sm {
      position: relative;
      display: flex;
      padding: 9px 15px 9px 13px;

      .service-task__action {
        top: 50%;
        right: 25px;
        transform: translateY(-50%);

        svg {
          flex-shrink: 0;
        }

        .input-radio {
          margin: 0;
          padding: 5px;
        }
      }
    }
  }

  &.small &__body {
    @include sm {
      display: grid;
      padding-left: 20px;
      height: 100%;
      margin: 0;
      align-content: space-between;
    }
  }

  &.small &__header {
    display: flex;

    @include sm {
      padding: 5px 0;
      margin: 0;
    }
  }

  &.small &__body-rating {
    font-size: 16px;
    line-height: 20px;
    color: $text-gray;

    @include sm {
      font-size: 14px;
    }
  }

  &.hover {
    border: 1px solid $line-light-gray;
  }

  &.hover &__action {
    opacity: 0;

    @include md {
      opacity: 1;
    }
  }

  &.hover:hover {
    border: 1px solid #a0a0a0;
  }

  &.hover:hover &__action {
    opacity: 1;
  }

  .hidden-show &__body-rating {
    color: $text-gray;
  }

  .hidden-show &__body-h1 {
    color: $text-gray;
  }

  .hidden-show &__header {
    opacity: 0.5;
  }

  .not-position-action &__action {
    @include md {
      top: auto;
      right: auto;
      position: static;
    }
  }

  &__extra-counter {
    @include desktop_additional;
    color: $text-gray;
  }

  @include sm {
    &.no-grid-mobile {
      display: flex;
      gap: 20px;
      padding: 20px;

      &__header {
        margin: unset;
      }
    }
  }

  //&.extra-grid {
  //  display: grid;
  //  grid-template-columns: 60px 1fr 34px;
  //
  //  .service-task__extra {
  //    grid-column: span 3;
  //  }
  //}
}
.rating {
  display: flex;
  width: 54px;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 20px;

  color: #fff;
  text-align: center;
  font-family: Muller;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  @include sm {
    width: 46px;
    height: 30px;
    font-size: 18px;
  }
}
</style>
