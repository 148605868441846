<template>
  <div class="competence-experience">
    <div class="title" v-if="!isSomeOne || (isSomeOne && competence.expertises.length)">
      <h2 class="information__header">
        {{ title }}
        <p v-if="isError" class="input-error">{{ errorString }}</p>
      </h2>
      <add-button
        v-if="competence.expertises.length && !isSomeOne"
        class="desktop button--big-left"
        @click.native="openCompetenceModal"
        >Добавить</add-button
      >
    </div>
    <div>
      <div v-if="!competence.expertises.length">
        <info-box-add v-if="!isSomeOne" icon="star" class="temp-width btn-width">
          <template v-slot:title> Обозначьте ваши наиболее сильные компетенции </template>
          <template v-slot:description>
            Компетенции являются основой для формирования ваших личных или командных
            услуг, а их оценка и рейтинг – то, что будет отличать вас от других
            профессионалов
          </template>
          <template v-if="!isSomeOne" v-slot:action>
            <base-button class="action-info btn_fix-w" @click="openCompetenceModal"
              >Добавить компетенции</base-button
            >
          </template>
        </info-box-add>
      </div>
      <div class="experience-block-list" v-if="competence.expertises.length">
        <block-list
          :items="competence.expertises"
          @dell="dell"
          :edit-button="isEdit"
          :dell-button="isDell"
          :check-button="isCheck"
          :action-button="isAction"
          :per-page="2"
          @action="doConfirm"
          @menu="menuAction"
          @check="getChecked"
          action-name="Подтвердить"
          :sub-menu="{
            'Запросить рекомендацию': 1,
            'Подтвердить учебным заведением': 2,
            'Подтвердить документом или ссылкой': 3,
          }"
        >
        </block-list>
      </div>

      <div class="competence__list" v-if="!isSomeOne && competence.institutions">
        <work-experience-item
          v-for="item in competence.institutions"
          :key="item.name"
          :title="item.name"
          :date-to="item.study_finish"
          class="single"
        >
          <template v-slot:action>
            <svg-icon
              width="18"
              @click.stop
              name="close-mobile-menu"
              @click="delInstitution(item.id)"
            ></svg-icon>
          </template>
        </work-experience-item>
      </div>
      <add-button
        v-if="competence.expertises.length && !isSomeOne"
        class="mobile button--big-left"
        @click.native="openCompetenceModal"
        >Добавить</add-button
      >

      <div class="competence__list" v-if="isSomeOne && competence.institutions?.length">
        <work-experience-item
          v-for="item in competence.institutions"
          :key="item.name"
          :title="item.name"
          :date-to="item.study_finish"
          class="single"
        />
      </div>
      <alert-modal ref="alert"></alert-modal>
      <alert-modal ref="dell">
        <template v-slot:button>
          <base-button
            @click="() => $refs.dell.closeModal()"
            class="button--light modal__btn"
            >Отмена</base-button
          >
        </template>
      </alert-modal>
      <add-competence-modal
        :is-open="isAddCompetenceModal"
        @add-competence="addNewCompetence"
        @close="closeCompetenceModal"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InfoBoxAdd from "@/components/ui/InfoBoxAdd";
import BaseButton from "@/components/base/BaseButton";
import AddButton from "@/components/ui/button/AddButton";
import AlertModal from "@/components/modals/AlertModal";
import AddCompetenceModal from "@/components/modals/AddCompetenceModal";
import WorkExperienceItem from "@/components/ui/WorkExperienceItem";
import ExperienceBlock from "@/components/ui/ExperienceBlock";
import ServiceTask from "@/components/ui/ServiceTask";
import SvgIcon from "@/components/base/SvgIcon";
import BaseDropdownMenu from "@/components/base/BaseDropdownMenu";
import ExperienceBlockConfirmation from "@/components/ui/ExperienceBlockConfirmation";
import AddCompetenceChoiceModal from "@/components/modals/AddCompetenceChoiceModal";
import ExpertisesList from "@/components/component/ExpertisesList";
import RequestRecommendationFirst from "../modals/RequestRecommendationFirst";
import BlockList from "@/components/component/BlockList";

export default {
  name: "CompetenceList",
  components: {
    ExperienceBlockConfirmation,
    BaseDropdownMenu,
    SvgIcon,
    ServiceTask,
    ExperienceBlock,
    WorkExperienceItem,
    AddCompetenceModal,
    AlertModal,
    AddButton,
    BaseButton,
    InfoBoxAdd,
    ExpertisesList,
    AddCompetenceChoiceModal,
    RequestRecommendationFirst,
    BlockList,
  },
  data() {
    return {
      isAddCompetenceModal: false,
      addSpecializationList: [],
      currentExpertisesPage: 1,
      expertisesPerPage: 5,
      isCheck: false,
      isAction: false,
      isDell: false,
      item: {},
      confirm: false,
      checked_ids: [],
    };
  },
  props: {
    competence: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      default: "",
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      works: "service/works",
      specialization: "service/specialization",
      user: "auth/user",
    }),
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(", ");
    },
    isSomeOne() {
      return this.$route.path.endsWith("expert") || this.$route.name == "RequestListPage";
    },
  },
  created() {
    if (!this.isSomeOne) {
      this.getCompetenceList();
      this.getServicesExpertises();
    }
  },
  mounted() {
    if (this.$route.name == "ResponseRequestPage") {
      this.isCheck = true;
    }
    if (this.$route.path == "/profile/information") {
      this.isAction = true;
      this.isDell = true;
    }
  },
  watch: {
    userExpertiseExpertises(newVal, oldVal) {
      const maxPage = Math.ceil(newVal.length / this.currentExpertisesPage);
      if (this.currentExpertisesPage > maxPage) {
        this.currentExpertisesPage = maxPage;
      }
    },
  },
  methods: {
    openSpecialization(item, index) {
      this.$emit("openRecommendationFirst", item, index);
    },
    methodUpdate() {
      this.getCompetenceList();
      this.getUserExpertise();
      this.getServicesExpertises();
    },
    getChecked(v) {
      if (this.competence.expertises) {
        let id = this.competence.expertises[v["idx"]].id;
        this.checked_ids = this.checked_ids.filter((el) => el != id);
        if (v["select"]) this.checked_ids.push(id);
        this.$emit("update:modelValue", this.checked_ids);
      }
    },

    doConfirm(v) {
      this.confirm = !this.confirm;
    },
    menuAction(v) {
      let item = this.competence.expertises[v["idx"]];
      if (v.val == 1) this.openRecommendationFirst(v);
      if (v.val == 2) this.open(item, "institution");
      if (v.val == 3) this.open(item, "publication");
    },
    openRecommendationFirst(v) {
      this.$emit("openRecommendationFirst", {
        idx: v["idx"],
      });
    },
    open(item, type) {
      if (type !== "recommendation") this.$emit("openModal", { item: item, type: type });
      else this.openSpecialization(item, v["idx"]);
    },

    async openCompetenceModal() {
      await this.$store.dispatch("profile/updateCompetence");
      this.isAddCompetenceModal = true;
    },
    closeCompetenceModal() {
      this.isAddCompetenceModal = false;
    },
    async addNewCompetence(e) {
      await this.$store.dispatch("profile/sendCompetence", { expertises: e });
      this.methodUpdate();
      this.closeCompetenceModal();
      this.currentExpertisesPage = 1;
    },

    async getServicesExpertises() {
      try {
        await this.$store.dispatch("service/getServicesExpertises", { user: this.user });
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getCompetenceList() {
      try {
        await this.$store.dispatch("profile/getCompetenceFlat");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async getUserExpertise() {
      try {
        await this.$store.dispatch("profile/getUserExpertise");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async dell(v) {
      let item = this.competence.expertises[v["idx"]];
      this.$refs.dell.show(
        "Удаление компетенции",
        `Вы уверены, что хотите удалить  компетенцию (${item.expertise_name})?`,
        {
          type: "dell",
          nameButton: "Удалить",
          error: true,
        },
        () => {
          this.dellCompetence(item.expertise_id);
        }
      );
    },
    async dellCompetence(id) {
      try {
        await this.$store.dispatch("profile/deleteExpertisesItem", id);
        await this.getUserExpertise();
        await this.getServicesExpertises();
        await this.getCompetenceList();
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    async delInstitution(id) {
      try {
        await this.$store.dispatch("profile/delInstitution", id);
        await this.getUserExpertise();
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.competence-experience {
  &__list {
    margin-bottom: 44px;
    margin-top: -12px;

    @include sm() {
      margin-bottom: 16px;
    }
  }

  .header {
    margin-bottom: 10px;
  }

  .info-box {
    margin-bottom: 0;
  }
}
.information__header {
  @include sm {
    margin-bottom: 0px;
  }
}

.btn-width {
  .button.button--default {
    width: 270px;

    @include md() {
      width: auto;
    }
  }
}

.action-info {
  width: auto;

  @include sm {
    width: 100%;
  }
}

.description {
  @extend %desktop_additional;
  text-align: left;
  margin-bottom: 30px;
  width: 50%;

  @include md {
    margin-bottom: 24px;
  }

  color: $input-label;

  @include sm {
    margin-bottom: 30px;
    width: 100%;
  }
}

.body {
  display: grid;
  grid-template-columns: 1fr;

  //::v-deep .info-box {
  //  margin-bottom: 16px;
  //}
}

.button--light:hover {
  background-color: $bg-light-gray;
}

.button--light {
  background-color: transparent;
}

.modal__btn {
  margin-left: 16px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  @include sm {
    display: grid;
  }
}
.add {
  width: auto;

  @include sm {
    margin: 25px 0 7px 0;
  }
}

.item:hover .dell {
  opacity: 1;
}

.dell {
  opacity: 0;

  @include md {
    opacity: 1;
  }

  transition: opacity 300ms;
  cursor: pointer;
}

.input-error {
  color: #ef693f;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}

.experience-item__delete {
  @include sm {
    position: absolute;
    top: 18px;
    right: 18px;
  }
}

.btn_fix-w {
  min-width: 256px;
  max-width: 256px;
}

.experience-item__dropdown {
  @include sm {
    width: max-content;
  }
}

.desktop {
  display: block;
  width: auto;
  @include sm {
    display: none;
  }
}

.mobile {
  display: none;
  @include sm {
    margin-top: 24px;
    display: block;
  }
}

.confirm-menu {
  position: absolute;
  font-family: SFProDisplay;
  background-color: #f6f6f6;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 24px;
  max-width: 334px;
  font-size: 15px;
  gap: 15px;
  right: 50px;
  top: 5px;
  @include sm {
    max-width: 264px;
    min-width: 264px;
    font-size: 13px;
  }
}
</style>
