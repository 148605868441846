<template>
  <div class="response-item" v-if="recommendationType == 'my'">
    <div class="response-item__user">
      <p class="response-item__title">{{ name }}</p>
      <slot name="btn" />
    </div>
    <div class="response-item__user">
      <div class="response-item__special">
        <p class="response-item__subtitle">{{ `${this.titleComposition?.name} ${this.titleComposition?.lastName}` }}</p>
        <p class="response-item__description">{{ description }}</p>
      </div>
    </div>

    <p class="response-item__status" :class="status"> <svg-icon v-if="status == 'approve'" name="checkedgreen" :width="12"
        :height="12" />{{ "Рекомендация " + statusString(status) }}</p>
  </div>

  <div class="response-item" v-if="recommendationType == 'request'">
    <div class="response-item__user-request">
      <div class="response-item__title">
        <p class="response-item__new" v-if="isUnseen">●</p>
        <p>{{ `${this.titleComposition?.name} ${this.titleComposition?.lastName}` }}</p>

      </div>
      <p class="response-item__description">{{ description }}</p>
    </div>
    <p class="response-item__subtitle">{{ name }}</p>
    <slot name="btn" />
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";

export default {
  name: "ResponseRecommendation",
  components: { SvgIcon },
  props: {
    name: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    recommendationType: {
      type: String,
      default: 'my'
    },
    isUnseen: {
      type: Boolean,
      default: true
    },
    titleComposition: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
  },
  methods: {
    statusString(status) {
      if (status === 'approve') {
        return 'оставлена'
      }
      if (status === 'declined') {
        return 'отклонена'
      }
      return 'запрошена'
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';

.svg-icon {
  margin-right: 4px;
}

.response-item {
  position: relative;
  padding: 20px;
  border: 1px solid $line-light-gray;
  border-radius: 12px;

  &:hover {
    border-color: $text-gray;
  }


  &__user {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__user-request {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__title {
    @include desktop_h2;
    display: flex;
    align-items: baseline;
    margin-bottom: 8px;
    word-break: break-word;
    max-width: 90%;
  }

  &__new {
    color: $green;
    margin-right: 4px;
  }

  &__subtitle {
    @include desktop_standart;
    margin-bottom: 8px;
    word-break: break-word;
    max-width: 90%;
  }

  &__description {
    @include desktop_standart;
    margin-bottom: 4px;
    color: $text-gray;
  }

  &__remove {
    position: absolute;
    right: 18px;
    top: 17px;
    cursor: pointer;
  }

  &__special {
    display: flex;
    align-items: baseline;
    gap: 24px;
    justify-content: space-between;

    @include sm {
      flex-direction: column;
      align-items: flex-start;
      gap: 0px;
    }

  }

  &__meta {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 24px;


    @include sm {
      margin-top: 20px;
    }

    &-block {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }


  &__status {
    @include desktop_additional;
    justify-content: space-between;
    color: $text-gray;

    &.approve {
      color: $green;
    }

    &.declined {
      color: $error;
    }
  }
}
</style>
