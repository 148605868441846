<template>
  <div class="input-group">
    <div class="title">
      <p v-if="description" class="description">
        {{ description }}

        <span v-if="descriptionStatus" class="description-status">{{
          descriptionStatus
        }}</span>
      </p>

      <p class="optional" v-if="isOptional">необязательно</p>
      <span v-if="tooltip.length" class="sphere-roles__header-tooltip">
        <svg-icon name="question" :width="9" :height="14" />
        <v-tooltip max-width="300" activator="parent" location="end">
          <p style="white-space: pre-line">{{ tooltip }}</p>
        </v-tooltip>
      </span>
    </div>

    <div
      class="input-wrapper"
      :class="[
        {
          'input-wrapper__error': isError,
        },
        isWhite ? 'white' : 'grey',
      ]"
    >
      <div>
        <p v-if="secondDescription" class="sub-prepend">{{ secondDescription }}</p>
      </div>
      <svg-icon v-if="search" name="search" class="input-group__search" />
      <vue-tel-input
        ref="inputPhone"
        v-if="type === 'phone'"
        @validate="validPhone"
        class="input"
        :type="type"
        v-model="value"
        default-country="ru"
        :valid-characters-only="true"
        :auto-default-country="false"
        :input-options="{
          placeholder: placeholder,
          showDialCode: true,
        }"
      ></vue-tel-input>
      <template v-else-if="type === 'textarea'">
        <textarea
          ref="inputTextarea"
          rows="8"
          class="input"
          :class="{ 'special-input': specialForm }"
          @input="handleInput"
          :value="modelValue"
          :placeholder="placeholder"
        ></textarea>
        <div class="input-group__count">
          {{ modelValue != null ? modelValue.length : 0 }} / {{ maxTextarea }}
        </div>
      </template>

      <input
        ref="inputText"
        v-else-if="type !== 'password'"
        class="input"
        @input="handleInput"
        :type="type"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
      />
      <input
        ref="inputText"
        v-else
        class="input"
        @input="handleInput"
        :type="showPassword ? 'text' : 'password'"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
      />
      <base-radio-input
        v-model="rangeValue"
        v-if="range"
        class="input-group__range"
        :max="rangeMax"
        :min="rangeMin"
        :step="rangeStep"
        :value="modelValue"
      />

      <template v-if="type === 'password' && modelValue && !disabled">
        <svg-icon
          name="eye"
          class="clear-input"
          @click="togglePasswordVisibility"
          :style="showPassword ? 'opacity: 0.6' : 'opacity: 0.2'"
        ></svg-icon>
      </template>

      <template v-if="type !== 'password' && showClear() && modelValue && !disabled">
        <svg-icon name="cross" class="clear-input" @click="clearInput()"></svg-icon>
      </template>
      <span v-if="clear" class="input-clear">
        <svg-icon name="cross" />
      </span>
    </div>
    <div v-if="isError" class="input-error">{{ errorString }}</div>
    <div
      v-if="subDescription && !isError"
      :style="{
        '--input-group--sub-description--margin-button': getMarginButton + 'px',
      }"
      class="sub-description"
    >
      {{ subDescription }}
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import VueTelInput from "@/components/base/BasePhoneInput/components/vue-tel-input";
import BaseRadioInput from "@/components/base/BaseRadioInput";
export default {
  name: "BaseInput",
  components: { BaseRadioInput, VueTelInput, SvgIcon },
  props: {
    isError: {
      type: Boolean,
      default: false,
    },

    isWhite: {
      type: Boolean,
      default: false,
    },
    /**
     * Кнопка с прокруткой
     */
    range: {
      type: Boolean,
      default: false,
    },
    /**
     * Кнопка с прокруткой
     */ search: {
      type: Boolean,
      default: false,
    },
    /**
     * Размер иконки с конца
     */
    rangeMin: {
      type: [Number, String],
    },
    rangeMax: {
      type: [Number, String],
    },
    rangeStep: {
      type: [Number, String],
    },
    iconPostfixSize: {
      type: [Number, String],
      default: 24,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    tooltip: {
      type: String,
      default: "",
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: "",
          },
        ];
      },
    },
    clear: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    subDescription: {
      type: String,
      default: "",
    },
    secondDescription: {
      type: String,
    },
    descriptionStatus: {
      type: String,
    },
    maxTextarea: {
      type: [String, Number],
      default: 300,
    },
    specialForm: {
      type: Boolean,
      default: false,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getMarginButton() {
      if (this.range) return 16;
      return 10;
    },
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join(". ");
    },
  },
  data() {
    return {
      value: "",
      rangeValue: 0,
      showPassword: false,
    };
  },
  watch: {
    modelValue(v) {
      this.value = v;
    },
    value() {
      this.$emit("update:modelValue", this.value);
    },
    rangeValue(v) {
      this.$emit("update:modelValue", v);
    },
  },
  methods: {
    focus() {
      try {
        if (this.type === "textarea") this.$refs.inputTextarea.focus();
        if (this.type === "phone") this.$refs.inputPhone.focus();
        else this.$refs.inputText.focus();
      } catch (e) {
        console.log(this.type);
        throw e;
      }
    },
    showClear() {
      if (this.type == "textarea") return false;
      return true;
    },
    clearInput() {
      this.value = "";
      this.$emit("update:modelValue", "");
      if (this.range) {
        this.value = "1";
        this.rangeValue = "1";
      }
      this.rangeValue = "";
      this.$emit("clearClick");
    },
    validPhone(v) {
      let valid = false;
      if (v.valid != null) valid = v.valid;
      this.$emit("validPhone", valid);
    },
    handleInput(e) {
      this.$emit("update:modelValue", e.target.value);
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

input::placeholder {
  @extend %desktop_standart;
  color: $text-gray;
  @include sm() {
    color: #a0a0a0;
    font-family: SFProDisplay;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.26px;
  }
}

textarea::placeholder {
  @extend %desktop_standart;
  color: $text-gray;
  @include sm() {
    color: #a0a0a0;
    font-family: SFProDisplay;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.26px;
  }
}

.clear-input {
  margin-right: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.special-range.input-group .description,
.monthly-income-from.input-group .description {
  margin-bottom: 12px;
}

.special-range.input-group {
  margin-top: 32px;

  @include sm() {
    margin-top: 16px;
  }
}

.input-group {
  margin-bottom: 12px;
  @include sm() {
    margin-bottom: 12px;
  }
  &__search {
    margin-left: 23px;
    flex-shrink: 0;
  }
  &__count {
    position: absolute;
    color: $text-gray;
    bottom: 10px;
    right: 20px;
    font-size: 12px;
    padding-top: 0px;
    border-radius: 3px;

    @include sm() {
      color: #a0a0a0;
      font-family: SFProDisplay;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
      letter-spacing: 0.26px;
    }
  }
  &__range {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    padding: 0 20px;
  }
  .description {
    display: flex;
    @extend %text-standart;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 500;
    color: $input-label;
    @include sm {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 106.667% */
    }
  }

  .description-status {
    margin-left: auto;
    font-size: 16px;
    color: $text-gray;
    font-weight: 400;
  }

  .sub-description {
    @extend %desktop_standart;
    color: $text-gray;
    @include sm() {
      color: #a0a0a0;
      font-family: SFProDisplay;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
      letter-spacing: 0.26px;
    }
    margin-top: var(--input-group--sub-description--margin-button);
  }
  .sub-prepend {
    @extend %text-standart;
    color: $text-gray;
    margin-top: var(--input-group--sub-description--margin-button);
    margin-left: 10px;
  }
  .input-wrapper__error {
    border: 1px solid $error;
  }
  .input-clear {
    padding-left: 20px;
  }
  .input-error {
    color: #ef693f;
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }
}
.grey {
  background: $bg-light-gray;
}

.white {
  background: white;
}
.input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 12px;
  position: relative;

  .input {
    @include desktop_standart;
    width: 100%;
    padding: 17px 20px 17px 20px;
    border-radius: 12px;
    &.special-input {
      height: 252px;
    }
    &.error {
      border-color: $error;
    }
  }
  .input-group__postfix {
    position: absolute;
    right: 8px;
  }
}

.no-resize textarea {
  resize: none;
}

.optional {
  @include desktop_additional;
  margin-bottom: 10px;
  color: $text-gray;
  @include sm() {
    color: #a0a0a0;
    font-family: SFProDisplay;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.26px;
  }
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sphere-roles {
  &__description {
    @extend %text-standart;
    max-width: 315px;
    padding-bottom: 10px;
  }

  .multi-select-rows {
    margin-bottom: 12px;
  }

  &__header {
    position: relative;
    margin-bottom: 20px;

    .information__header {
      margin-right: 10px;
      margin-bottom: 20px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &-tooltip {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-left: auto;
      margin-bottom: 10px;
      cursor: pointer;
      top: 3px;
      right: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #e6e6e6;
    }
  }
}
</style>
