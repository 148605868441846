<template>
  <base-modal size="440" :is-open="isOpen" @close="closeModal">
    <h1 class="title">
      {{ title }}
    </h1>
    <rating-input 
      v-model="rate" 
      title="Общая оценка оказаной услуги"
      :prev-rate="isRated ? rate : 0"
      :key="updateRate"
    />
    <base-input
      v-model="comment"
      class="no-resize comment"
      description="Ваш комментарий"
      placeholder="Придумайте подробное описание"
      type="textarea"
      :is-error="v$.comment.$error"
      :error="[
                        {
                          text: 'Комментарий обязателен',
                          status: v$.comment.required.$invalid,
                        },
                        {
                          text: 'Максимальная длина - 300 символов',
                          status: v$.comment.maxLength.$invalid,
                        },
                      ]"
    />
    <div class="buttons">
      <base-button 
        :disabled="!rate" 
        @click="submit"
      >
        Сохранить оценку
      </base-button>
      <base-button
        v-if="isRated"
        class="btn-delete button--light"
        icon icon-prefix="dell-item"
        @click="deleteRate"
      />
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import RatingInput from "@/components/ui/RatingInput";
import BaseButton from "@/components/base/BaseButton";
import useVuelidate from "@vuelidate/core";
import {email, required, minValue, maxValue, maxLength} from "@vuelidate/validators";
import BaseInput from "@/components/base/BaseInput";

export default {
  name: 'RateServiceModal',
  components: { 
    BaseModal, 
    RatingInput,
    BaseButton,
    BaseInput,
  },
  setup(){
    return { v$: useVuelidate() }
  },
  data() {
    return {
      rate: null,
      comment: '',
      updateRate: 0,
    }
  },
  props: {
    isOpen: {
      type: Boolean
    },
    title: {
      type: String
    },
    isRated: {
      type: Boolean,
      default: false,
    },
    propsRate: {
      type: Number
    },
    propsComment: {
      type: String
    },
  },
  created() {
    if (this.propsRate) {
      this.rate = this.propsRate;
    }
    if (this.propsComment) {
      this.comment = this.propsComment;
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) {
          console.log(this.v$);
          return;
        }
      this.$emit('submit', this.rate, this.comment);
      this.closeModal();
    },
    deleteRate() {
      this.rate = null;
      this.comment = null;
      this.updateRate += 1;
      this.$emit('delete');
    },
   
  },
  validations() {
    return {
     
        comment: {
          required,
          maxLength: maxLength(300),
        },
       
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';

.title {
  font-size: 32px;
  margin-bottom: 30px;
}

.comment {
  margin-bottom: 40px;
}

.buttons {
  display: flex;
  gap: 8px;
}

.btn-delete {
  width: 56px;
  height: 56px;
}
</style>