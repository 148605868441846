<template>
  <div class="publication-block">
    <div class="header">
      <div class="header__title">
        <h2 class="information__header">Дополнительная информация</h2>
        <p class="header__description">
          Дополните свой профиль ссылками на примеры проектов, сертификатами, дипломами и
          публикациями. Это сделает ваш профиль более привлекательным.
        </p>
      </div>
      <div class="header__control" v-if="!isSomeOne">
        <add-button class="button--big-left" @click="showModalLink('link')"
          >Добавить ссылку</add-button
        >
        <add-button class="button--big-left" @click="showModalLink('file')"
          >Добавить вложение</add-button
        >
      </div>
    </div>
    <div v-if="publications.length > 0" class="publication-block__body">
      <div class="publications-list">
        <publications-item
          v-for="item in publications"
          :key="`publication-${item.id}`"
          :info="item"
          class="big-info-block"
          @del-item="delPub(item.id)"
          :remove="!isSomeOne"
        />
      </div>
    </div>

    <publications-modal ref="publications" @success="updateData" />
    <alert-modal ref="alert" />
  </div>
</template>

<script>
import AddButton from "@/components/ui/button/AddButton";
import InfoBox from "@/components/ui/InfoBox";
import SvgIcon from "@/components/base/SvgIcon";
import { mapGetters } from "vuex";
import PublicationsItem from "@/components/component/PublicationsItem";
import PublicationsModal from "@/components/modals/PublicationsModal";
import AlertModal from "@/components/modals/AlertModal";
import { debounce } from "@/utils/helper";
export default {
  name: "PublicationBlock",
  components: {
    AlertModal,
    PublicationsModal,
    PublicationsItem,
    SvgIcon,
    InfoBox,
    AddButton,
  },
  created() {
    this.getPublicationList();
    this.deleteDebounce = debounce(this.deletePublications, 400);
  },
  computed: {
    ...mapGetters({
      publications: "profile/publications",
    }),
  },
  props: {
    isSomeOne: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteDebounce: null,
    };
  },
  methods: {
    methodUpdate() {
      this.getPublicationList();
    },
    async getPublicationList() {
      try {
        await this.$store.dispatch("profile/getPublicationList");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
    showModalLink(e) {
      this.$refs.publications.showModal(e);
    },
    async updateData() {
      await this.getPublicationList();
      this.$refs.publications.closeModal();
    },
    delPub(e) {
      this.deleteDebounce(e);
    },
    async deletePublications(id) {
      try {
        await this.$store.dispatch("profile/deletePublications", id);
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }

      await this.getPublicationList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";
.publication-block {
  &__body {
    margin-top: 30px;
    @include sm() {
      margin-top: 24px;
    }
  }
}
.information__header {
  @include desktop_h2_norm;
  margin-bottom: 24px;
  @include sm {
    margin-bottom: 24px;
  }
}
.header {
  &__control {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    @include sm() {
      grid-template-columns: 1fr;
    }
  }

  &__description {
    @include desktop_standart;
    color: $accent;
  }

  &__title {
    margin-bottom: 30px;

    @include sm() {
      margin-bottom: 24px;
    }
  }
}

.publications-list {
  display: grid;
  gap: 8px;
}
</style>
