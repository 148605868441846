<template>
  <div ref="select" class="base-radio-group">
    {{ list.length <= 0 ? 'Обязательно для заполнения props list' : null }}
    <p v-if="description" class="base-radio-group__description">{{ description }}</p>
    <div class="base-radio-group__body">
      <div
          class="base-radio-group__item"
          v-for="item in lestOption"
          @click="setValue(item)"
          :class="{'base-radio-group__active': isSelect(item)}"
      >
        {{ item.name }}
      </div>
    </div>
    <div v-if="isError" class="base-radio-group__input-error">{{ errorString }}</div>
  </div>
</template>

<script>
export default {
  name: "BaseRadioGroup",
  props: {
    modelValue: {
      default: null
    },
    list: {
      type: [Array],
      default() {
        return []
      }
    },
    description: {
      type: String,
      default: ''
    },
    subDescription: {
      type: String
    },
    error: {
      type: Array,
      default() {
        return [{
          status: false,
          text: '',
        }]
      },
    },
    isError: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      value: []
    }
  },
  computed: {
    errorString() {
      return this.error
          .filter((error) => error.status)
          .map((error) => error.text)
          .join(". ")
    },
    lestOption: {
      get() {
        let newArray = [];
        if (this.modelValue != null) {
          if (this.modelValue.length <= 0)
            this.$emit('update:modelValue', null);
          this.list.filter(listValue => {
            let listIndex = this.modelValue.findIndex(v => v.id === listValue.id)
            if (listIndex !== -1)
              newArray.push({...listValue, ...this.modelValue[listIndex]});
            else
              newArray.push(listValue);
          })
          this.value = newArray;
        } else {
          this.value = this.list.map(v => ({...v, ...{select: false}}));
        }
        return this.value;
      },
      set(value) {
        if (this.value.length > 0) {
          if (value.filter(v => v.select).length <= 0)
            this.$emit('update:modelValue', null)
          else
            this.$emit('update:modelValue',  value)
        }
      },
    }
  },
  methods: {
    focus() {
      let element = this.$refs.select;
      let top = element.offsetTop;
      window.scrollTo(0, top - 20);
    },
    isSelect(item) {
      if (this.lestOption == null)
        return false;
      let selectIndex = this.lestOption.findIndex(v => v.id === item.id);
      if (selectIndex === -1)
        return false;
      else
        return this.lestOption[selectIndex].select
    },
    setValue(item) {
      let newValue = this.lestOption;
      let selectIndex = newValue.findIndex(v => v.id === item.id);
      if (selectIndex !== -1) {
        newValue[selectIndex].select = !newValue[selectIndex].select;
        this.lestOption = newValue;
      }
    }
  },
}
</script>

<style scoped lang="scss">
  .base-radio-group {
    &__input-error {
      color: $error;
      margin-top: 8px;
    }
    &__body {
      display: flex;
      gap: 16px;
    }
    &__item {
      cursor: pointer;
      background-color: $bg-light-gray;
      color: $accent;
      font-family: 'Muller';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      text-align: center;
      line-height: 54px;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    &__active {
      box-shadow: 0 0 0 6px rgba($green, .2);
      background-color: $green;
      color: $white;
    }
    &__description {
      @extend %text-standart;
      text-align: left;
      margin-bottom: 20px;
      font-weight: 500;
      color: $input-label;
    }
  }
</style>