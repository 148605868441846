<template>
  <base-modal size="550" :is-open="isOpen" @close="closeModal">
    <div class="add-people-team">
      <h2 class="add-people-team__header">Добавить компетенции</h2>
      <div class="subtitle">
        <span
          >Прикрепите компетенции из вашего профиля. Если список пуст — добавьте
          компетенции в ваш профиль и повторите операцию</span
        >
      </div>
      <div class="body">
        <perfect-scrollbar>
          <div class="list">
            <service-task
              v-for="(option, idx) in listAll()"
              class="item"
              :rating="option.rating"
              :industry="option.name"
              :like="counterLick(option.rating_count)"
              :model-value="option.value"
              @update:model-value="(v) => select([option])"
              checkbox-action
              small
            />
          </div>
        </perfect-scrollbar>
      </div>

      <base-button outlined @click="openCompetenceModal" class="add"
        >Добавить компетенцию</base-button
      >

      <base-button @click="submit">{{
        nameButton !== "" ? nameButton : "Сохранить"
      }}</base-button>
      <!-- <base-button @click="submit">{{ nameButton !== '' ? nameButton : 'Сохранить' }}</base-button> -->
    </div>
  </base-modal>
  <add-competence-modal
    :is-open="isAddCompetenceModal"
    @add-competence="addNewCompetence"
    @close="closeCompetenceModal"
  />
</template>

<script>
import useVuelidate from "@vuelidate/core";
import BaseModal from "@/components/base/BaseModal";
import BaseButton from "@/components/base/BaseButton";
import ServiceTask from "@/components/ui/ServiceTask";
import useSelectController from "@/hooks/useSelectController";
import AddCompetenceModal from "@/components/modals/AddCompetenceModal";
import { declOfNum } from "@/utils/helper";
/**
 * @property {string} text - Текст с поля для ввода
 */
class EventVerificationModalData {
  text = "";
}

export default {
  name: "AddExpertisesModal",
  components: {
    ServiceTask,
    BaseButton,
    BaseModal,
    AddCompetenceModal,
  },
  setup(props) {
    const {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect,
    } = useSelectController({ selectOne: false, newValue: false });
    return {
      submitSelect,
      addListSelect,
      listAll,
      searchSelect,
      selectValue,
      isNewValue,
      isSelectValue,
      removeSelect,
    };
  },
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      inputValidPhone: false,
      nameButton: "",
      verificationCode: "",
      dataOptions: [],
      isOpenModalDesiredClients: false,
      isAddCompetenceModal: false,
    };
  },
  methods: {
    addSpecialisation() {
      this.closeModal();
      this.$router.push("/profile/information#experience");
    },
    counterLick(count) {
      if (count != 0) {
        return `${count == null ? 0 : count} ${declOfNum(count, [
          "оценка",
          "оценки",
          "оценок",
        ])}`;
      }
      return false;
    },
    select(v) {
      this.selectValue(v);
      let submitSelect = this.submitSelect();

      if (submitSelect != -1) {
        this.$emit("update:modelValue", submitSelect);
      }
    },
    closeModal() {
      this.$emit("close");
    },
    submit() {
      this.closeModal();
    },
    deleteItem(item) {
      this.removeSelect(item);
      let submitSelect = this.submitSelect();
      if (submitSelect != -1) {
        this.$emit("update:modelValue", submitSelect);
      }
    },
    async openCompetenceModal() {
      await this.$store.dispatch("profile/updateCompetence");
      this.isAddCompetenceModal = true;
    },
    closeCompetenceModal() {
      this.isAddCompetenceModal = false;
    },
    async addNewCompetence(e) {
      await this.$store.dispatch("profile/sendCompetence", { expertises: e });
      await this.$store.dispatch("profile/getUserExpertise");
      this.closeCompetenceModal();
      await this.getCompetenceList();
    },
    async getCompetenceList() {
      try {
        await this.$store.dispatch("profile/getCompetenceFlat");
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          type: "error",
          nameButton: "Ок",
        });
      }
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null)
          if (newValue.length > 0) {
            this.selectValue(newValue, true);
          }
      },
    },
    items: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null) {
          this.addListSelect(JSON.parse(JSON.stringify(newValue)));
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.add-people-team {
  &__header {
    @include desktop_h1;
    text-align: left;
    margin-bottom: 16px;
  }
  .body {
    margin-bottom: 30px;
    max-height: 400px;
    margin: 24px -18px 16px -18px;
    padding-left: 18px;
    .ps {
      max-height: 400px;
    }
    .list {
      padding-right: 18px;
    }
    .item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.subtitle {
  @include desktop_additional;
  text-align: left;
  margin-bottom: 16px;
}

.add {
  margin-bottom: 16px;
}
</style>
