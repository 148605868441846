<template>
  <div class="resume-container">
    <div class="action-section top">
      <div class="icon">
        <svg-icon :name="'resume/' + steps[active_step]['icon']"></svg-icon>
      </div>
      <div class="title">{{ steps[active_step]["text"] }}</div>
    </div>
    <div class="action-section">
      <div class="infotext" style="padding-bottom: 16px" v-if="active_step != 2">
        {{ steps[active_step]["info"] }}
      </div>
      <slot name="container" v-if="active_step != 4"></slot>
      <base-button @click="close()" v-else>Понятно</base-button>
      <div v-if="active_step == 2" style="padding-top: 17px; width: 100%">
        <base-button @click="save(1)" class="button--success">Заполнить</base-button>
        <base-button
          @click="save(0)"
          class="button--light"
          v-if="mobile"
          style="margin-top: 8px; background: #e6e6e6"
          >Заполню сам</base-button
        >
        <div
          v-else
          class="infotext"
          style="
            text-align: center;
            padding-top: 8px;
            cursor: pointer;
            text-decoration: underline;
          "
          @click="save(0)"
        >
          хочу заполнить самостоятельно
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "BaseResume",
  components: { SvgIcon, BaseButton },
  props: {
    active_step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      steps: {
        1: {
          icon: "step_1",
          text: "Загрузите резюме и мы предзаполним профиль",
          info: "Пока что мы можем обработать резюме только один раз",
        },
        2: {
          icon: "step_2",
          text: "Мы можем предзаполнить профиль информацией из резюме",
          info: "",
        },
        3: {
          icon: "step_3",
          text: "Мы обрабатываем ваше резюме",
          info: "Мы пришлем вам на почту подтверждение об успешности операции",
        },
        4: {
          icon: "step_4",
          text: "Мы предзаполнили ваш профиль!",
          info: "Вы можете редактировать информацию в профиле",
        },
      },
    };
  },
  methods: {
    async save(type) {
      this.$emit("save", { type: type });
    },
    close() {
      this.$store.dispatch("status/updateVisitTimes", "parse_close");
      this.$emit("save", { type: -1 });
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.resume-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  border-radius: 20px;
  background: #f6f6f6;
  @include sm() {
    display: flex;
  }
}
.title {
  color: #42434e;
  font-family: Muller;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.18px;
  @include sm {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%; /* 19.84px */
  }
}
.icon {
  display: flex;
  width: 56px;
  height: 56px;
  padding: 0px 20px;
  margin-bottom: 17px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  @include sm {
    background: none;
    width: 15px;
    height: 15px;
    padding: 0px 8px;
    margin-bottom: 0px;
  }
}

.infotext {
  color: #a0a0a0;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.096px;
  @include sm {
    font-size: 15px;
  }
}
.action-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  &.top {
    @include sm {
      flex-direction: row-reverse;
    }
  }
}
</style>
