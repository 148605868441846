<template>
  <nav class="mobile-menu">
    <div
      v-if="userState === 'customer'"
      v-for="item in menuCustomer"
      :key="item.id"
      class="mobile-menu__item"
      @click="toRout(item)"
    >
      <div
        class="mobile-menu__item-icon"
        :class="{ 'router-link-active': isLinkActive(item) }"
      >
        <svg-icon
          :name="item.icon"
          :width="item.width ? item.width : 24"
          class="icon-base"
        />
        <svg-icon
          :name="item.iconActive"
          :width="item.width ? item.width : 24"
          class="icon-active"
        />
      </div>
      <span
        class="mobile-menu__item-title"
        :class="{ 'mobile-menu__item-title_active': isLinkActive(item) }"
        >{{ item.title }}</span
      >
    </div>

    <div
      v-else
      v-for="item in menuExecutor"
      :key="item.id + userState"
      @click="toRout(item)"
      class="mobile-menu__item"
    >
      <div
        class="mobile-menu__item-icon"
        :class="{ 'router-link-active': isLinkActive(item) }"
      >
        <svg-icon
          :name="item.icon"
          :width="item.width ? item.width : 24"
          class="icon-base"
        />
        <svg-icon
          :name="item.iconActive"
          :width="item.width ? item.width : 24"
          class="icon-active"
        />
      </div>
      <span
        class="mobile-menu__item-title"
        :class="{ 'mobile-menu__item-title_active': isLinkActive(item) }"
        >{{ item.title }}</span
      >
    </div>
  </nav>

  <verification-modal
    header="Войдите или зарегистрируйтесь"
    subtitle="Войдите или создайте новый аккаунт, чтобы получить полный доступ ко всем услугам"
    :is-open="isRegisterAndLogin"
    @close="isRegisterAndLogin = false"
    ref="registerAndLoginModal"
    @submit="toRegister"
  >
    <template v-slot:input>
      <base-button @click="toLogin" class="mb-4 button--light">Войти</base-button>
    </template>
  </verification-modal>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import VerificationModal from "@/components/modals/VerificationModal";
import { mapGetters } from "vuex";
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "BaseMenuMobile",
  components: { SvgIcon, VerificationModal, BaseButton },
  props: {
    userState: {
      type: String,
      default: "customer",
    },
  },
  computed: {
    ...mapGetters({ isAuth: "auth/isAuth" }),
  },
  data() {
    return {
      menuExecutor: [
        {
          id: "mm-2",
          title: "Запросы",
          icon: "mm-projects",
          iconActive: "mm-projects-d",
          link: "/projects/response",
          width: 17,
        },
        {
          id: "mm-3",
          title: "Сообщества",
          icon: "mm-community",
          iconActive: "mm-community-d",
          link: "/community",
          width: 17,
        },
        {
          id: "mm-4",
          title: "Профиль",
          icon: "mm-profile",
          iconActive: "mm-profile-d",
          link: "/profile",
          width: 17,
        },
      ],
      menuCustomer: [
        {
          id: "mm-1",
          title: "Новая заявка",
          icon: "mm-service",
          iconActive: "mm-service-d",
          link: "/request-add",
          width: 17,
        },
        {
          id: "mm-2",
          title: "Мои проекты",
          icon: "mm-projects",
          iconActive: "mm-projects-d",
          link: "/projects/request",
          width: 17,
        },
        {
          id: "mm-3",
          title: "Сообщества",
          icon: "mm-community",
          iconActive: "mm-community-d",
          link: "/community",
          width: 17,
        },
        {
          id: "mm-4",
          title: "Профиль",
          icon: "mm-profile",
          iconActive: "mm-profile-d",
          link: "/profile",
          width: 17,
        },
      ],
      menu: [
        {
          id: "mm-1",
          title: "Главная",
          icon: "mm-main",
          iconActive: "mm-main-d",
          link: "/",
          width: 18,
        },
        {
          id: "mm-2",
          title: "Проекты",
          icon: "mm-project",
          iconActive: "mm-project-d",
          link: "/projects",
          width: 18,
        },
        {
          id: "mm-3",
          title: "Услуги",
          icon: "mm-service",
          iconActive: "mm-service-d",
          link: "/services",
          width: 18,
        },
        {
          id: "mm-4",
          title: "Сообщества",
          icon: "mm-community",
          iconActive: "mm-community-d",
          link: "/community",
          width: 18,
        },
        {
          id: "mm-5",
          title: "Профиль",
          icon: "mm-profile",
          iconActive: "mm-profile-d",
          link: "/profile",
          width: 18,
        },
      ],
      isRegisterAndLogin: false,
    };
  },
  methods: {
    isLinkActive(item) {
      if (this.$route.fullPath.toString().toLowerCase().includes(item.link)) return true;
    },
    toRegister() {
      this.$router.replace({ name: "RegistrationPage" });
      this.isRegisterAndLogin = false;
    },
    toLogin() {
      this.$router.replace({ name: "AuthPage" });
      this.isRegisterAndLogin = false;
    },
    toRout(to) {
      if (
        !this.isAuth &&
        (to.title.toString().toLowerCase().includes("запросы".toString().toLowerCase()) ||
          to.title
            .toString()
            .toLowerCase()
            .includes("проекты".toString().toLowerCase()) ||
          to.title
            .toString()
            .toLowerCase()
            .includes("сообщества".toString().toLowerCase()))
      )
        this.showRegisterAndLoginModal();
      else this.$router.push({ path: to.link });
    },
    showRegisterAndLoginModal() {
      console.log("тут");
      this.$refs.registerAndLoginModal.show({
        nameButton: this.$t("btn.register"),
      });
      this.isRegisterAndLogin = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-menu {
  padding-left: auto;
  position: fixed;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: $white;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  gap: 28px;
  border-radius: 18px 18px 0 0;

  &__icon {
    display: flex;
    justify-content: center;
    width: 24px;
    height: 21px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 0;

    &:hover {
      cursor: pointer;
    }

    &.router-link-active {
      svg path {
        fill: red;
      }
    }

    &-title {
      margin-top: 8px;
      color: #a0a0a0;
      text-align: center;
      font-family: SFProDisplay;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 109.091% */
      letter-spacing: 0.22px;
    }
  }
}

.icon-base {
  display: block;
}

.icon-active {
  display: none;
}

.mobile-menu__item-title_active {
  color: #42434e;
}

.router-link-active {
  .mobile-menu__item-title {
    color: #42434e;
  }

  .icon-base {
    display: none;
  }

  .icon-active {
    display: block;
  }
}
</style>

<style lang="scss">
@import "src/assets/scss/vuetify_variables";

.v-container {
  &.footer {
    @include lg {
      padding-bottom: 100px;
    }
  }
}
</style>
