<template>
  <base-modal :size="size" :is-open="isOpen" @close="closeModal">
    <div class="verification">
      <h2 class="verification__header">{{ header }}</h2>
      <p class="verification__description">{{ description }}</p>
      <div v-if="subtitle !== ''" class="subtitle">
        <span> {{ subtitle }}</span>
      </div>
      <slot name="input" />
      <base-input
        v-if="!$slots.input"
        :placeholder="placeholder"
        v-model="verificationCode"
      />
      <base-button @click="sendVerify">{{
        nameButton !== "" ? nameButton : $t("btn.submit")
      }}</base-button>
      <slot name="footer" />
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import BaseInput from "@/components/base/BaseInput";
import BaseButton from "@/components/base/BaseButton";
/**
 * @property {string} text - Текст с поля для ввода
 */
class EventVerificationModalData {
  text = "";
}

export default {
  name: "VerificationModal",
  components: {
    BaseButton,
    BaseInput,
    BaseModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    /**
     * Дополнительный контент, отображаемый под заголовком
     */
    subtitle: {
      type: String,
      default: "",
    },
    /**
     * Текст заголовка
     */
    header: {
      type: String,
      default: "",
    },
    /**
     * Текст заголовка
     */
    placeholder: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    size: {
      type: [String, Number],
      default: 550,
    },
  },
  data() {
    return {
      nameButton: "",
      verificationCode: "",
    };
  },
  methods: {
    /**
     * Вызвать уведомления
     * @param option Доп порамитры
     * @param option.type - Тип уведомления по простому влияет только на иконку (success, error)
     * @param option.nameButton - Текст кнопки
     * @param option.value - значения для инпута
     * @param callback - Функция обратного вызова
     */
    show(option, callback = null) {
      if (option != null) {
        if (option.type != null) this.type = option.type;
        if (option.nameButton != null) this.nameButton = option.nameButton;
        if (option.value != null) this.verificationCode = option.value;
      }
    },
    closeModal() {
      this.$emit("close");
    },
    sendVerify() {
      let eventData = new EventVerificationModalData();
      eventData.text = this.verificationCode;
      this.verificationCode = "";
      this.$emit("submit", eventData);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify_variables";
.verification {
  &__header {
    @include desktop_h2_norm;
    text-align: left;
    margin-bottom: 24px;
    @include sm {
      margin-bottom: 12px;
    }
  }

  &__description {
    @extend %desktop_standart;
    position: relative;
    top: -16px;
    margin-bottom: 8px;
    text-align: center;
    @include sm {
      font-size: 15px;
    }
  }
}
.subtitle {
  text-align: left;
  margin-bottom: 16px;
  font-size: 18px;
  color: $accent;
  @include sm {
    font-size: 15px;
  }
}

.narrow {
  .verification__header {
    padding: 0 20px;
  }
}

.reset-verification {
  .input-group {
    margin-bottom: 24px;
  }

  .verification {
    &__header {
      margin-bottom: 12px;
      text-align: center;
    }

    &__description {
      top: 0;
      margin-bottom: 24px;
    }
  }
}
</style>
