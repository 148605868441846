<template>
  <div class="choose-form" v-if="!choose">
    <div class="title-small"><h2>Выберите статус</h2></div>
    <div>
      <base-button
        class="button"
        outlined2
        @mouseover="handleHover(true, 1)"
        @mouseleave="handleHover(false, 1)"
        @click="choose_position(1)"
        >Ищу эксперта</base-button
      >
      <base-button
        class="button"
        outlined2
        @mouseover="handleHover(true, 2)"
        @mouseleave="handleHover(false, 2)"
        @click="choose_position(2)"
        >Стать экспертом</base-button
      >
    </div>
    <div class="line-text">
      {{ help_text }}
    </div>
  </div>

  <v-container v-else>
    <div class="registration-page">
      <div class="registration-form">
        <div class="registration-form__progress">
          <div class="global-scroll-for-contend"></div>
        </div>
        <div class="registration-form__content">
          <template v-if="getStatus === 0">
            <div class="title">
              <div v-if="mobile">
                <p class="mobile-counter">{{ mobile_step }}/{{ mobile_n_step }}</p>
                <h2 class="mobile-header">
                  {{
                    mobile_step == mobile_n_step
                      ? mobile_step_title[3]
                      : mobile_step_title[mobile_step]
                  }}
                </h2>
              </div>
              <h2 v-else>Kонтактные данные</h2>
            </div>

            <form class="registration-form__first-form">
              <div v-if="!mobile | (mobile_step == 1)">
                <v-row>
                  <v-col cols="12" md="6" class="mb-0">
                    <base-input
                      :is-white="mobile"
                      :disabled="pre_reg"
                      ref="v-lastName"
                      v-model="lastName"
                      :class="{ error: v$.lastName.$error }"
                      :is-error="v$.lastName.$error"
                      class="registration-form__double mb-0"
                      :placeholder="$t('pages.register.last-name-placeholder')"
                      :error="[
                        {
                          text: $t('validate.required-2', {
                            name: $t('name.last-name-u'),
                          }),
                          status: v$.lastName.required.$invalid,
                        },
                        {
                          text: $t('validate.valid-name'),
                          status: v$.lastName.validName.$invalid,
                        },
                      ]"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="mb-0">
                    <base-input
                      :is-white="mobile"
                      :disabled="pre_reg"
                      ref="v-name"
                      :placeholder="$t('pages.register.name-placeholder')"
                      v-model="name"
                      name="name"
                      class="registration-form__double mb-6"
                      :class="{ error: v$.name.$error }"
                      :is-error="v$.name.$error"
                      :error="[
                        {
                          text: $t('pages.register.validate.name.required'),
                          status: v$.name.required.$invalid,
                        },
                        {
                          text: $t('validate.valid-name'),
                          status: v$.name.validName.$invalid,
                        },
                      ]"
                    />
                  </v-col>
                </v-row>
                <base-input
                  :is-white="mobile"
                  :disabled="pre_reg"
                  ref="v-email"
                  v-model="email"
                  class="registration-form__double mb-6"
                  :class="{ error: v$.email.$error }"
                  :is-error="v$.email.$error"
                  type="email"
                  :placeholder="$t('pages.register.email-placeholder')"
                  :error="[
                    {
                      text: $t('pages.register.email-validate'),
                      status: v$.email.required.$invalid,
                    },
                    {
                      text: $t('validate.email'),
                      status: v$.email.email.$invalid,
                    },
                  ]"
                />

                <base-input
                  :is-white="mobile"
                  ref="v-phone"
                  class="registration-form__double phone-field"
                  type="phone"
                  v-model="phone"
                  @validPhone="validPhoneEvent"
                  :placeholder="$t('pages.register.phone-placeholder')"
                  :class="{ error: v$.phone.$error }"
                  :is-error="v$.phone.$error"
                  :error="[
                    {
                      text: $t('pages.register.validate.phone.required'),
                      status: v$.phone.required.$invalid,
                    },
                    {
                      text: $t('pages.register.validate.phone.valid-phone'),
                      status: v$.phone.validPhone.$invalid,
                    },
                  ]"
                ></base-input>
              </div>
              <div
                class="mt-6 mb-0"
                style="display: flex; justify-content: space-between"
                v-if="(pos == 'customer') & !mobile"
                @mouseover="handleHoverHelper(true)"
                @mouseleave="handleHoverHelper(false)"
              >
                <base-radio
                  v-model="t"
                  is-switch
                  type="checkbox"
                  title="Хотите получать проекты в роли эксперта?"
                />
              </div>
              <div v-if="getUserState == 'executor'">
                <div v-if="!mobile | (mobile_step == 2)">
                  <v-row class="mt-4 mb-0">
                    <v-col>
                      <vue-multi-select-check-box
                        :is-white="mobile"
                        @mouseover="handleHoverHelper2(true)"
                        @mouseleave="handleHoverHelper2(false)"
                        ref="v-industryArray"
                        class="mb-0"
                        optionLabel="name"
                        optionKey="id"
                        placeholder="Выберите из списка"
                        description="В каких индустриях?"
                        v-model="industryValue"
                        :options="industriesList"
                        :is-error="isIndustryValue | v$.industryValue.$error"
                        :error="[
                          {
                            text: 'Превышен лимит индустрий',
                            status: isIndustryValue,
                          },
                          {
                            text: 'Индустрии обязательны для заполнения',
                            status: v$.industryValue.$invalid,
                          },
                        ]"
                      ></vue-multi-select-check-box>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-0">
                    <v-col>
                      <base-call-modal
                        :is-white="mobile"
                        @mouseover="handleHoverHelper3(true)"
                        @mouseleave="handleHoverHelper3(false)"
                        header="В каких специализациях?"
                        :title="currentSpecTitle"
                        placeholder="Выберите из списка"
                        @event-click="openSpecialization()"
                        :is-error="v$.optionsArray.$error"
                        :error="[
                          {
                            text: `Функции обязательны для заполнения`,
                            status: this.v$.optionsArray.arrayValid.$invalid,
                          },
                        ]"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-0">
                    <v-col>
                      <base-file
                        :is-white="mobile"
                        @file="updateData"
                        file-type=".pdf,.docx,.doc"
                        title="Резюме"
                        is-optional
                        placeholder="Прикрепите резюме"
                        :is-error="v$.file.$error"
                        :error="[
                          {
                            text: 'Неверный тип файла',
                            status: v$.file.checkType.$invalid,
                          },
                          {
                            text: 'Максимальный размер файла - 50мб',
                            status: v$.file.checkSize.$invalid,
                          },
                          {
                            text: 'Добавьте не более 5 файлов',
                            status: v$.file.maxLength.$invalid,
                          },
                        ]"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="line" v-if="!pre_reg"></div>
              <div v-if="(!mobile | (mobile_step == mobile_n_step)) & !pre_reg">
                <v-row class="mt-3 mb-0">
                  <v-col>
                    <base-input
                      :is-white="mobile"
                      ref="v-password"
                      v-model="password"
                      :is-error="v$.password.$error"
                      type="password"
                      :placeholder="$t('pages.register.password-placeholder')"
                    />
                    <validation-pass-block
                      class="validation-block"
                      v-if="v$.password.$error"
                      :required="{
                        status: v$.password.required.$invalid,
                        isShow: true,
                      }"
                      :length="{
                        status: v$.password.minLength.$invalid,
                        isShow: true,
                      }"
                      :is-upper-case="{
                        status: v$.password.containsUppercase.$invalid,
                        isShow: true,
                      }"
                      :latin="{
                        status: v$.password.latin.$invalid,
                        isShow: true,
                      }"
                    />
                  </v-col>
                </v-row>
              </div>
            </form>
            <div
              v-if="mobile"
              class="multibutton"
              :class="{ executor: getUserState == 'executor' }"
            >
              <base-button
                button-type="button"
                class="button"
                @click="next_mobile()"
                v-if="mobile & (mobile_step < mobile_n_step)"
                >Далее
              </base-button>

              <!--<base-button
                :disabled="progres_val < 100"
                button-type="button"
                class="button"
                light
                @click="submitRegisterRedirect(false)"
                v-if="!mobile | ((mobile_step == mobile_n_step) & (pos == 'customer'))"
                >{{ pre_reg ? "Сохранить" : "Зарегистрироваться" }}
              </base-button>-->

              <base-button
                v-if="mobile & (mobile_step == mobile_n_step) & (pos == 'customer')"
                :disabled="progres_val < 100"
                class="button--success"
                style="min-width: 300px"
                @click="submitRegisterRedirect(true)"
                >{{ "Сохранить  и создать заявку" }}</base-button
              >
              <base-button
                v-if="mobile & (mobile_step == mobile_n_step) & (pos == 'executor')"
                :disabled="progres_val < 100"
                class="button--success"
                style="min-width: 300px"
                @click="submitRegisterRedirect(false)"
                >{{ "Начать работу" }}</base-button
              >
              <!--<p class="addittional">
                Или нажмите “Сохранить и создать заявку”, чтобы сразу приступить к поиску
                эксперта
              </p>-->
            </div>
            <div v-if="!mobile">
              <base-button
                v-if="pos == 'customer'"
                class="button--success"
                style="min-width: 100% !important"
                @click="submitRegisterRedirect(true)"
                >{{ "Сохранить  и создать заявку" }}</base-button
              >
              <base-button
                v-if="pos == 'customer'"
                class="button--white"
                outlined
                style="
                  min-width: 100% !important;
                  margin-bottom: 0px !important;
                  border: 0px !important;
                "
                @click="submitRegisterRedirect(false)"
                >{{ pre_reg ? "Сохранить" : "Зарегистрироваться" }}
              </base-button>
              <base-button
                v-if="pos == 'executor'"
                class="button--success"
                style="min-width: 100% !important; margin-bottom: 0px !important"
                @click="submitRegisterRedirect(false)"
                >{{ "Начать работу" }}</base-button
              >
            </div>
          </template>
        </div>
      </div>

      <div class="additional" v-if="!mobile | (mobile_step == mobile_n_step)">
        <div class="tmp">
          <div style="padding-right: 24px">
            <base-radio type="checkbox" v-model="subscribe"></base-radio>
          </div>
          <p class="tmp-text">Хочу получать информацию о сервисе и его улучшениях</p>
        </div>
      </div>
      <div v-if="!mobile | (mobile_step == mobile_n_step)">
        <div class="registration-form__form-checkbox">
          <div class="registration-form__content">
            <div>
              {{
                "Нажимая на кнопку «" +
                (pre_reg ? "Сохранить" : "Зарегистрироваться") +
                "», я соглашаюсь с"
              }}
              <a
                target="_blank"
                :href="
                  getPolicy(
                    '/docs/%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5.pdf'
                  )
                "
                >{{ $t("router.rules") }}</a
              >
              {{ "и" }}
              <a
                target="_blank"
                :href="
                  getPolicy(
                    '/docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%B8%20%D0%B7%D0%B0%D1%89%D0%B8%D1%82%D1%8B%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf'
                  )
                "
                >{{ $t("router.privacy-policy") }}</a
              >{{ "." }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
  <base-modal :is-open="ask_modal & mobile" is-permanent size="500">
    <slot name="body1">
      <div style="text-align: center">
        <h1>Хотите получать проекты в роли эксперта?</h1>
        <p class="helper-content">
          Заполните информацию о вашей экспертизе: индустрии и специализации
        </p>
        <div style="display: inline-flex; gap: 20px; height: 100%; padding-top: 30px">
          <base-button @click="toggle_mobile">{{ "Заполнить" }}</base-button>
          <base-button class="button--outlined reg" outlined @click="ask_modal = false"
            >Пропустить
          </base-button>
        </div>
      </div>
    </slot>
  </base-modal>
  <base-modal-helper v-if="choose & !mobile">
    <slot name="body1">
      <progress-bar :progress="progres_val" :show-text="false"></progress-bar>
      <v-container>
        <div class="helper-content">
          <div style="display: flex; gap: 5px; padding-top: 20px">
            <svg-icon name="help1" style="margin-right: 20px" />
            <p>
              {{ help_text_reg }}
            </p>
          </div>
        </div>
      </v-container>
    </slot>
  </base-modal-helper>
  <add-specialization-modal-work
    ref="add-specialization"
    :is-open="specializationModal"
    :functions-list="optionsList"
    @update-data="addSpecialization"
    @close="closeSpecializationModal"
    title="Выберите специализации"
    :v-model="currentSpec"
    :with-industry="false"
    btn-name="Добавить"
  />
  <alert-modal ref="alert_error"> Вернуться на главную </alert-modal>
  <alert-modal is-permanent ref="alert"></alert-modal>
</template>

<script>
import BaseInput from "@/components/base/BaseInput";
import ProgressBar from "@/components/ui/ProgressBar";
import ValidationPassBlock from "@/components/ui/ValidationPassBlock";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, maxLength } from "@vuelidate/validators";
import BaseRadio from "@/components/base/BaseRadio";
import BaseButton from "@/components/base/BaseButton";
import { mapGetters, mapMutations } from "vuex";
import BaseMultiCheckbox from "@/components/base/VueTelInput/BaseMultiCheckbox";
import BaseModal from "@/components/base/BaseModal";
import BaseModalHelper from "@/components/base/BaseModalHelper";
import BaseDropdown from "@/components/base/BaseDropdown";
import SvgIcon from "@/components/base/SvgIcon";
import AlertModal from "@/components/modals/AlertModal";
import SelectIndustry from "@/components/ui/SelectIndustry";
import IsPc from "@/components/ui/IsPc";
import Checkbox from "@/components/base/VueTelInput/Checkbox";
import BaseErrorText from "@/components/base/BaseErrorText";
import AddSpecializationModalWork from "@/components/modals/AddSpecializationModalWork";
import VueMultiSelectCheckBox from "@/components/ui/VueMultiSelectCheckBox";
import BaseCallModal from "@/components/base/BaseCallModal";
import BaseFile from "@/components/base/BaseFile";
import { typeFile } from "@/utils/helper";
import LsUtil from "@/utils/ls";
import { LS_SOC_REG, LS_REFRESH_TOKEN } from "@/utils/ls/keys";

export default {
  name: "RegistrationPage",
  components: {
    BaseModalHelper,
    BaseErrorText,
    Checkbox,
    IsPc,
    SelectIndustry,
    AlertModal,
    SvgIcon,
    BaseDropdown,
    BaseModal,
    BaseMultiCheckbox,
    BaseButton,
    BaseRadio,
    ValidationPassBlock,
    ProgressBar,
    BaseInput,
    VueMultiSelectCheckBox,
    BaseCallModal,
    AddSpecializationModalWork,
    BaseFile,
  },
  setup: (props) => {
    const v$ = useVuelidate({ $scope: false });
    return {
      v$,
    };
  },
  data() {
    return {
      invite: false,
      invite_id: null,
      is_invited: null,
      inputValidPhone: false,
      industryValidate: false,
      workValidate: false,
      autoIncrement: 1,
      industryArray: [{ autoIncrement: 0 }],
      name: "",
      lastName: "",
      isLoad: false,
      email: "",
      phone: "",
      password: "",
      selectList: [],
      modalVerification: false,
      showValidate: false,
      errorPass: false,
      currentSpec: [],
      currentSpecTitle: "",
      industryValue: [],
      specializationModal: false,
      file: null,
      is_demand: false,
      executor: false,
      subscribe: false,
      help_text: "На платформе вы сможете совмещать роли эксперта и заказчика",
      choose: false,
      pos: null,
      hoverOnSwitch: false,
      hoverOnIndustry: false,
      hoverOnSpec: false,
      mobile_step: 1,
      mobile_n_step: 2,
      ask_modal: false,
      mobile_step_title: {
        1: "Заполните данные",
        2: "В чем вы эксперт?",
        3: "Создайте пароль",
      },
      pre_reg: false,
      t: false,
    };
  },
  created() {
    let r = LsUtil.getItem(LS_SOC_REG);
    if (r) {
      this.pre_reg = JSON.parse(LsUtil.getItem(LS_SOC_REG));
      this.name = this.pre_reg.name;
      this.lastName = this.pre_reg.lastName;
      this.email = this.pre_reg.email;
    }
    if (this.isAuth) {
      if (!this.userState) this.$store.commit("auth/SET_USERSTATE", "executor");
      this.$router.replace({
        name: this.getUserState == "executor" ? "ProfilePage" : "RequestAddPage",
      });
    }
    this.executor = this.getUserState == "executor";
    this.getOptions();
    this.getIndustries();
    if (this.$route.query.hash != null) this.redHash(this.$route.query.hash);
  },
  watch: {
    t() {
      this.changeSwitch();
    },
  },
  computed: {
    ...mapGetters({
      isAuth: "auth/isAuth",
      error: "registration/error",
      user: "auth/user",
      verifyCode: "registration/verifyCode",
      workTypeList: "registration/workTypeList",
      industriesList: "registration/industriesList",
      optionsList: "registration/optionsList",
      getUserState: "auth/getUserState",
      userState: "auth/isUserState",
    }),
    mobile() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
    progres_val() {
      if (this.pre_reg)
        if (this.executor)
          return (
            ((+(this.phone.length > 5) +
              +(this.currentSpec.length > 0) +
              +(this.industryValue.length > 0)) /
              3) *
            100
          );
        else return +(this.phone.length > 5) * 100;
      if (this.executor)
        return (
          ((+(this.name.length > 0) +
            +(this.lastName.length > 0) +
            +(this.email.length > 0) +
            +(this.phone.length > 5) +
            +(this.password.length > 0) +
            +(this.currentSpec.length > 0) +
            +(this.industryValue.length > 0)) /
            7) *
          100
        );
      else
        return (
          ((+(this.name.length > 0) +
            +(this.lastName.length > 0) +
            +(this.email.length > 0) +
            +(this.phone.length > 5) +
            +(this.password.length > 0)) /
            5) *
          100
        );
    },
    help_text_reg() {
      if (this.hoverOnIndustry)
        return "Выберите индустрии, в которых вы обладаете экспертизой и хотите брать новые проекты";
      if (this.hoverOnSpec)
        return "Выберите, чем именно вы хотите заниматься на проектах. На основе ваших индустрий и специализаций вы будете получать приглашения";
      if (this.executor & (this.pos == "customer"))
        return "Заполните краткую информацию о себе и начинайте пользоваться преимуществами Gigoo!";
      if (this.hoverOnSwitch)
        return "Заполните информацию о своей экспертизе и получайте приглашения на участие в проектах";
      if (this.pos == "customer")
        return "Заполните краткую информацию о себе и приступайте к созданию заявки";
      if (this.pos == "executor")
        return "Заполните информацию о своей экспертизе и получайте приглашения на участие в проектах";
    },
    progress() {
      return this.industryValue.length >= 11;
    },
    isIndustryValue() {
      return this.industryValue.length >= 11;
    },
    reqIndustryValue() {
      return this.industryValue.length == 0;
    },
    reqOptionsValue() {
      return this.currentSpecTitle.length == 0;
    },

    reqOptionsValue() {
      return this.currentSpecTitle.length == 0;
    },
    checkType(e) {
      if (e !== null) {
        return typeFile.includes(e.type);
      } else {
        return true;
      }
    },
    checkSize(e) {
      if (e !== null) {
        return e.size < 50 * 1024 * 1024;
      } else {
        return true;
      }
    },

    getStatus() {
      if (this.isAuth) {
        if (this.user == null) return 1;
        if (this.user.isVerified) return 2;
        else return 1;
      }
      return 0;
    },

    backgroundStyles() {
      return {
        "state-on": this.executor,
        "state-off": !this.executor,
      };
    },

    validations() {
      return {
        name: {
          required,
          validName: this.validName,
        },
        lastName: {
          required,
          validName: this.validName,
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
          validPhone: this.validPhone,
        },
        password: {
          required,
          minLength: minLength(8),
          containsUppercase: this.containsUppercase,
          latin: this.latin,
        },
        optionsArray: {
          arrayValid: (this.getUserState = "executor" ? this.arrayValid : true),
        },
        industryValue: {
          required: (this.getUserState = "executor" ? required : true),
        },
        file: {
          checkType: this.checkType,
          checkSize: this.checkSize,
          maxLength: maxLength(1),
        },
      };
    },
    indicatorStyles() {
      return {
        transform: this.executor ? "translateX(29px)" : "translateX(4px)",
        "background-color": this.executor ? "#FFFFFF" : "#FFFFFF",
      };
    },
  },
  methods: {
    getPolicy(href) {
      return (
        "https://" +
        config.VUE_APP_ASSETS_BUCKET +
        ".storage.yandexcloud.net/" +
        config.VUE_APP_ASSETS_DIR +
        href
      );
    },
    goHome() {
      this.$router.push({ path: "/" });
    },
    autoFocus(validate) {
      console.log(validate.$invalid);
      if (validate.$invalid) {
        for (let key in Object.keys(validate)) {
          const input = Object.keys(validate)[key];
          if (!input.includes("$")) {
            if (validate[input].$error) {
              try {
                this.$refs[`v-${input}`].focus();
                return true;
              } catch (e) {
                console.log(`v-${input}`);
                throw e;
              }
            }
          }
        }
      }
      return false;
    },

    autoFocusKey(validate, keys) {
      for (let key in Object.keys(validate)) {
        const input = Object.keys(validate)[key];
        if (!input.includes("$")) {
          if (validate[input].$error) {
            try {
              if (keys.includes(input)) {
                this.$refs[`v-${input}`].focus();
                return false;
              }
            } catch (e) {
              console.log(`v-${input}`);
              throw e;
            }
          }
        }
      }
      return true;
    },

    resetValidation() {
      this.v$.$reset();
    },
    openSpecialization() {
      if (this.currentSpec.length) {
        this.$refs["add-specialization"].showModal(this.currentSpec);
      } else {
        this.$refs["add-specialization"].showModal([
          {
            title: "",
            years: null,
            id: 1,
            industries: -1,
            options: { options: -1, id: -1 },
            works: null,
          },
        ]);
      }
    },
    addSpecialization(e) {
      let options = e.options.filter((el) => el.options !== -1);
      this.currentSpec = [];
      this.currentSpecTitle = "";
      for (let i = 0; i < options.length; i++) {
        let elem = {
          title: "",
          years: null,
          industries: -1,
          works: null,
        };
        elem.id = i;
        elem.options = options[i];
        this.currentSpec.push(elem);
        this.currentSpecTitle = this.currentSpecTitle + options[i].name + ", ";
      }
      this.currentSpecTitle = this.currentSpecTitle.slice(0, -2);
      this.$refs["add-specialization"].hideModal();
    },
    closeSpecializationModal() {
      this.$refs["add-specialization"].hideModal();
    },

    handleHoverHelper(flag) {
      this.hoverOnSwitch = flag;
    },
    handleHoverHelper2(flag) {
      this.hoverOnIndustry = flag;
    },
    handleHoverHelper3(flag) {
      this.hoverOnSpec = flag;
    },
    handleHover(flag, n) {
      if (!flag) {
        this.help_text = "На платформе вы сможете совмещать роли эксперта и заказчика";
        return;
      }
      if (n == 1) {
        this.help_text = "Формируйте заявки на проекты и привлекайте лучших экспертов";
        return;
      }
      this.help_text = "Заполняйте профиль и монетизируйте свою экспертизу";
    },
    choose_position(pos) {
      this.pos = pos == 1 ? "customer" : "executor";
      this.mobile_n_step = pos == 1 ? 2 : 3;
      this.executor = pos == 2;
      pos == 1
        ? this.$store.commit("auth/SET_USERSTATE", "customer")
        : this.$store.commit("auth/SET_USERSTATE", "executor");

      this.choose = true;
    },
    redHash(hash) {
      let user = {};
      const encoded = decodeURIComponent(encodeURI(atob(hash)));
      let searchParams = new URLSearchParams(encoded);
      let phone = "";
      for (let [key, v] of searchParams) {
        if (key !== "phone") this[key] = v;
        else phone = v;
      }
      setTimeout(() => {
        this.phone = phone;
      }, 200);
      this.invite = true;
    },
    validPhoneEvent(v) {
      this.inputValidPhone = v;
    },
    workValidateCheck(value) {
      if (value.length) {
        return value.findIndex((v) => v.value === true) !== -1;
      }
      return false;
    },
    async next_mobile() {
      const result = await this.v$.$validate();
      if (this.mobile_step == 1) {
        const result_page = this.autoFocusKey(this.v$, [
          "name",
          "lastName",
          "email",
          "phone",
        ]);
        if (!result & !result_page) {
          return;
        }
      }

      if (this.mobile_step == 2) {
        let to_check = ["optionsArray", "industryValue"];
        if (this.file) {
          to_check = to_check.concat(["file"]);
        }
        const result_page = this.autoFocusKey(this.v$, to_check);
        if (!result & !result_page) {
          return;
        }
      }

      if ((this.mobile_step + 1 == 2) & (this.pos == "customer")) this.ask_modal = true;
      this.mobile_step = this.mobile_step + 1;
      if (this.mobile_step == this.mobile_n_step + 1) {
        this.submitRegisterRedirect(false);
      }
      this.resetValidation();
    },
    toLogin() {
      this.$router.replace({ name: "StartPage" });
    },
    async submitRegisterRedirect(v) {
      let name = "";
      if (v) {
        name = "RequestAddPage";
      } else {
        name = "ProfilePage";
      }
      const result = await this.v$.$validate();
      if (!this.pre_reg) {
        if (!result) {
          this.autoFocus(this.v$);
          return;
        }
      } else {
        let valid_list = [];
        for (let key in Object.keys(this.v$)) {
          const input = Object.keys(this.v$)[key];
          if (!input.includes("$")) {
            if (this.v$[input].$error) {
              valid_list.push(input);
            }
          }
        }
        if (!(valid_list.length <= 1 && valid_list[0] == "password")) return;
      }

      this.is_demand = v;
      await this.submitRegister();
      if (this.pre_reg) {
        this.$router.replace({ name: name });
        return;
      }
      let messages = {
        0: {
          title: "Спасибо за регистрацию!",
          subtitle:
            "Мы рады, что вы стали частью Gigoo. Перейдите, пожалуйста, по ссылке на почте " +
            this.email +
            " и подтвердите аккаунт.",
        },
        1: {
          title: "Спасибо за регистрацию!",
          subtitle:
            "Перейдите, пожалуйста, по ссылке на почте " +
            this.email +
            " и подтвердите аккаунт. После подтверждения вы сможете оформить заявку на проект и найти того самого эксперта!",
        },
      };

      let m = this.is_demand ? messages[1] : messages[0];
      this.$refs.alert.show(
        m.title,
        m.subtitle,
        {
          type: "success",
          nameButton: "Ок",
        },
        () => {
          this.$router.replace({ name: name });
        }
      );
    },

    changeSwitch(e) {
      this.executor = !this.executor;
      this.executor
        ? this.$store.commit("auth/SET_USERSTATE", "executor")
        : this.$store.commit("auth/SET_USERSTATE", "customer");
    },
    toggle_mobile() {
      this.mobile_n_step = 3;
      this.mobile_step = 2;
      this.changeSwitch();
      this.ask_modal = false;
    },
    async submitRegister() {
      const invite = {
        invite_id: this.invite_id,
        is_invited: this.is_invited,
      };
      const formData = {
        is_subscribe: +this.subscribe,
        name: this.name.trim(),
        lastName: this.lastName.trim(),
        email: this.email,
        password: this.password,
        phone: this.phone,
        is_demand: +this.is_demand,
        is_expert: +(this.pos == "executor"),

        ...(this.invite ? invite : {}),
      };
      var bodyFormData = new FormData();
      if (this.getUserState == "executor") {
        let functions = [];
        if (this.currentSpec) {
          for (let i = 0; i < this.currentSpec.length; i++) {
            let value = this.currentSpec[i];
            if (value.options.id <= -10) {
              let response = await this.addOption(value.options.name);
              functions.push(response);
            } else {
              functions.push(value.options.id);
            }
          }
        }
        if (this.file) formData.resume = this.file;
        this.industryValue
          .map((el) => el.id)
          .forEach((v, idx) => {
            bodyFormData.append(`industry[${idx}]`, v);
          });

        functions.forEach((v, idx) => {
          bodyFormData.append(`option[${idx}]`, v);
        });
      }

      for (var key in formData) {
        bodyFormData.append(key, formData[key]);
      }

      let endpoint = "registration/registrationMain";
      if (this.pre_reg) {
        LsUtil.removeItem(LS_SOC_REG);
        endpoint = "registration/registrationMainSocial";
        bodyFormData.delete("name");
        bodyFormData.delete("lastName");
        bodyFormData.delete("email");
        bodyFormData.delete("password");
        bodyFormData.append("token", this.pre_reg.token);
      }
      try {
        await this.$store.dispatch(endpoint, bodyFormData);
      } catch (e) {
        this.$refs.alert_error.show(
          this.$t("alert.error"),
          e.response.data.message,
          {
            type: "error",
            nameButton: e.response.data.userExistError ? "Авторизоваться" : "Ок",
          },
          () => {
            this.$router.push({ name: "AuthPage" });
          }
        );
      }
      await this.$store.dispatch("auth/getUserData");
    },
    async addOption(name) {
      let response = await this.$store.dispatch("registration/addOptions", {
        name: name,
      });
      return response.id;
    },
    boolValidation(value) {
      return value && value === true;
    },
    containsUppercase(value) {
      return value && /[A-Z]/.test(value);
    },
    validName(value) {
      value = value.trim();
      if (value === "") return true;
      return /^[а-яёА-ЯЁa-zA-Z\-]+$/.test(value);
    },
    validPhone(value) {
      if (value === "") return true;
      return this.inputValidPhone;
    },
    latin(value) {
      return value && /^[a-zA-Z_*@!#0-9]+$/.test(value);
    },

    async updateData(e) {
      this.file = e;
    },
    arrayValid() {
      return this.currentSpec.length > 0;
    },
    async getIndustries() {
      await this.$store.dispatch("registration/getIndustries");
    },
    async getOptions() {
      await this.$store.dispatch("registration/getOptions");
    },
    /**
     * @param {EventVerificationModalData} event
     */
  },
  validations() {
    return {
      name: {
        required,
        validName: this.validName,
      },
      lastName: {
        required,
        validName: this.validName,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        validPhone: this.validPhone,
      },
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: this.containsUppercase,
        latin: this.latin,
      },
      optionsArray: {
        arrayValid: this.userState == "executor" ? this.arrayValid : false,
      },
      industryValue: {
        required: this.userState == "executor" ? required : false,
      },
      file: {
        checkType: this.checkType,
        checkSize: this.checkSize,
        maxLength: maxLength(1),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuetify_variables";

.registration-page {
  h1 {
    text-align: center;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: start;
    margin-bottom: 20px;

    &-btn {
      margin-top: 30px;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        & {
          margin-top: 0;
        }
      }

      grid-row-start: 2;
    }

    &-h1 {
      width: 100%;
      grid-row-start: 1;

      h1 {
        line-height: 52px;
        padding-right: 52px;
        @include mobile_h1;

        @include sm() {
          padding-right: 0;
        }
      }
    }

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      display: flex;
      margin-bottom: 40px;
    }
  }
}
.title {
  width: 100%;
  text-align: center;
  font-family: Muller;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
.additional {
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  margin: 24px auto 0px auto;
  border-radius: 24px;
  padding: 20px;
  @include lg {
    display: block;
    padding: 20px;
  }
  @include sm {
    display: flex;
    align-items: center;
    background-color: #eee;
    margin: 40px auto 0px auto;
    padding: 0px 0px;
  }
}
.registration-form {
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  margin: 40px auto 0px auto;
  border-radius: 24px;
  padding: 40px 20px;
  padding-bottom: 10px;
  @include lg {
    display: block;
    padding: 20px;
  }
  @include sm {
    background-color: #eee;
    margin: 40px auto 0px auto;
    padding: 0px 0px;
  }

  &__content {
    h2 {
      @include desktop_h1;
      margin-bottom: 30px;

      @include md {
        margin-bottom: 24px;
      }
    }

    a {
      color: $green;
    }
  }

  .line {
    width: 100%;
    height: 20px;
    border-bottom: 1px solid #e1e1e1;
    text-align: center;
    vertical-align: center;
    position: relative;
    @include sm {
      display: none;
    }
  }
  .validation-block {
    margin-top: 5px;
  }
  .multibutton {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    padding-bottom: 30px;
    &.executor {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }
    @include sm {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding-bottom: 0px;
    }
  }

  &__first-form {
    gap: 25px;
    @include sm {
      padding-bottom: 20px;
    }
  }

  &__form-checkbox {
    max-width: 600px;
    margin: 0px auto 25px auto;
    border-radius: 24px;
    padding: 0px 20px;
    text-align: center;
    margin-top: 10px;
    color: #a0a0a0;
    color: var(--text-gray, #a0a0a0);
    font-family: SFProDisplay;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%;
    letter-spacing: 0.084px;
    @include sm {
      text-align: left;
      padding: 20px 0px 0px 0px;
      margin: 0px auto 0px auto;
    }
  }

  &__description {
    @extend %text-standart;
    max-width: 410px;
    margin-bottom: 24px;
  }
}
.button {
  margin-top: 0px !important;
  margin-bottom: 12px !important;
  max-width: 400px;
  @include sm {
    max-width: 100%;
  }
}
.state-on {
  background-color: #7fc666;
}

.state-off {
  background-color: #d9d9d9;
}

.addittional {
  margin-top: 10px;
  color: #a0a0a0;
  font-family: SFProDisplay;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 17.36px */
  letter-spacing: 0.084px;
  @include sm {
    display: none;
  }
}

.toggle-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 56px;
  height: 29px;
  border-radius: 10px;
}

.toggle-wrapper:focus {
  outline: 0;
}
.toggle-background {
  display: inline-block;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  transition: background-color 0.4s ease;
}

.toggle-indicator {
  position: absolute;
  height: 23px;
  width: 23px;
  left: 0px;
  bottom: 3px;
  background-color: 0.4s ease;
  border-radius: 7px;
  transition: transform 0.4s ease;
}
.tmp {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 30px;
  @include sm {
    display: flex;
    gap: 0px;
  }
}

.tmp-text {
  max-width: 100%;
  @include sm {
    line-height: 100%;
    max-width: auto;
  }
}
.title-small {
  display: inline-flex;
  padding: 30px 0px 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
  @include sm {
    color: #000;
    font-family: Muller;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}
.choose-form {
  max-width: 395px;
  background-color: #fff;
  margin: 40px auto 25px auto;
  border-radius: 24px;
  padding: 30px 20px;
  margin-bottom: 200px;
  @include sm {
    background-color: #eee;
    margin: 40px auto 0px auto;
    padding: 30px 10px;
    font-family: SFProDisplay;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 200px;
  }
  .line {
    width: 100%;
    height: 20px;
    border-bottom: 1px solid #e1e1e1;
    text-align: center;
    vertical-align: center;
    position: relative;
  }
  .title {
    display: inline-flex;
    padding: 20px 0px 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 24px;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    & {
      padding: 30px 20px;
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    & {
      padding: 30px;
    }
  }
  .button {
    margin: 10px 0px;
  }
  .line-text {
    width: 100%;
    text-align: center;
    background-color: white;
    color: #a0a0a0;
    font-family: SFProDisplay;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 24px;
    padding-top: 12px;
    border-top: 1px solid #e1e1e1;

    @include sm {
      background-color: #eee;
      height: 40px;
    }
  }
}
.mobile-counter {
  color: #a0a0a0;
  text-align: center;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.3px;
}
.mobile-header {
  color: #000;
  font-family: Muller;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 116.667% */
}
.helper-content {
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  color: #42434e;
  font-family: SFProDisplay;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 22.32px */
  letter-spacing: 0.36px;
}
</style>
